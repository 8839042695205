import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import Moment from 'moment';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);

export interface LocationState {
    isLoading: boolean,
    Locations: Location[]
}

export interface Location {
    [x: string]: any;
    Id: string;
    Name: string;
    Description: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestContactAction { type: 'REQUEST_LOCATION',  Locations: Location[] }
export interface ReciveContactAction { type: 'RECEIVE_LOCATION', Locations: Location[] }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestContactAction | ReciveContactAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestLocation: (keyword: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
       const appState = getState();
        if (appState && appState.location) {
            if (keyword.trim() == "") {
                keyword = "-"
            } else {
                keyword = keyword;
            }
            axios.get('/autocomplete/searchlocation?keyword=' + keyword)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_LOCATION', Locations: result });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_LOCATION' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: LocationState = {
    Locations: [],
    isLoading: false
};
export const reducer: Reducer<LocationState> = (state: LocationState | undefined, incomingAction: Action): LocationState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LOCATION':
            return {
                Locations: state.Locations,
                isLoading: true
            };
        case 'RECEIVE_LOCATION':
            return {
                Locations: action.Locations,
                isLoading: false
            };
        default:
            return state;
    }
};
