import jsPDF from 'jspdf';
import * as React from 'react';

export default class Template extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public componentWillUpdate() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderTemplate()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {

    }
    downloadForm = () => {
        //this.props.formLists.forEach((value: any) => {
        //    console.log(value);
        //})
        //window.jsPDF = window.jspdf.jsPDF;

        var doc: any = new jsPDF();
        var elementHTML = document.querySelector("#content");
        doc.html(elementHTML, {
            callback: function (doc:any) {
                doc.save('sample-document.pdf');
            },
            x: 15,
            y: 15,
            width: 170,
            windowWidth: 650
        });
    }
    public renderTemplate() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <button type="button" className="btn btn-lg btn-primary" onClick={() => this.downloadForm()}>Download</button>
                            <div className="card">
                                <div className="card-body">
                                    <div className="main-wrapper">
                                        <div className="temp-10" id="content">
                                        <img
                                            className="beige-natural-botanical-skinca4"
                                            alt=""
                                            src="template/public/headerimage.png"
                                        />
                                            <div className="frame-parent10">
                                                <div className="frame-parent11">
                                                <div className="aahar-parent">
                                                    <div className="aahar">AAHAR</div>
                                                    <b className="lifestyle-recommendations"
                                                    >Herbal and supplement recommendation</b
                                                    >
                                                </div>
                                                <div className="clip-path-group-wrapper4">
                                                    <div className="clip-path-group8">
                                                        <img className="group-icon9" alt="" src="template/public/group7.png" />

                                                        <div className="aahar-refers-to-the-food-and-d-parent">
                                                            <div className="aahar-refers-to">
                                                                Aahar refers to the food and drink we consume, and Ayurveda
                                                                recommends consuming  fresh, whole, and natural foods that
                                                                suit our Dosha. It stresses mindful eating and proper
                                                                preparation, which involves enhancing food's nutritional value
                                                                and digestibility.
                                                            </div>
                                                            <img className="frame-child3" alt="" src="template/public/group-87.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-parent4">
                                                <b className="recommendation6">Recommendation</b>
                                                <div className="increase-fiber-intake-container">
                                                    <ul className="increase-fiber-intake-include">
                                                        <li className="increase-fiber-intake">
                                                            Increase fiber intake: Include more fruits, vegetables, whole
                                                            grains, and legumes in your diet.
                                                        </li>
                                                        <li className="increase-fiber-intake">
                                                            Increase water intake: Drinking plenty of water can help soften
                                                            the stool and make it easier to pass. Aim for at least 8-10
                                                            glasses of water per day.
                                                        </li>
                                                        <li>
                                                            Avoid processed foods, fried and greasy foods, and dairy
                                                            products as they can cause constipation.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-parent13">
                                                <div className="aahar-parent">
                                                    <div className="aahar">Aachar</div>
                                                    <b className="lifestyle-recommendations">Lifestyle Recommendations</b>
                                                </div>
                                                <div className="clip-path-group-wrapper5">
                                                    <div className="clip-path-group9">
                                                        <img className="group-icon10" alt="" src="template/public/group8.png" />

                                                        <div className="aachar-refers-to-our-daily-rou-parent">
                                                            <div className="aachar-refers-to">
                                                                Aachar refers to our daily routines and Ayurveda recommends
                                                                following healthy habits that align with the diurnal and
                                                                seasonal rhythms of nature. This includes waking up and going
                                                                to bed at consistent times, maintaining a regular meal
                                                                schedule, practicing good hygiene, and engaging in regular
                                                                physical exercise to support the body's natural rhythms and
                                                                promote balance of mind, body, and spirit.
                                                            </div>
                                                            <img className="frame-child4" alt="" src="template/public/group-94.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-parent4">
                                                <b className="recommendation6">Recommendation</b>
                                                <div className="increase-fiber-intake-container">
                                                    <ul className="increase-fiber-intake-include">
                                                        <li className="increase-fiber-intake">
                                                            Try to establish a regular time for bowel movements, such as
                                                            after breakfast or dinner. This can help train the body to have
                                                            regular bowel movements. to  promote regular bowel movements
                                                        </li>
                                                        <li className="increase-fiber-intake">
                                                            Practice relaxation techniques, such as deep breathing exercises
                                                            and meditation, to reduce stress and promote relaxation.
                                                        </li>
                                                        <li>
                                                            Avoid sitting or standing for long periods of time. Regular
                                                            movement and exercise can help prevent constipation.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-parent7">
                                                <div className="vichar-parent">
                                                    <div className="vichar">Vichar</div>
                                                    <b className="mindset-recommendations">Mindset Recommendations</b>
                                                </div>
                                                <div className="clip-path-group-wrapper2">
                                                    <div className="clip-path-group5">
                                                        <img className="group-icon7" alt="" src="template/public/group7.png" />

                                                        <div className="group-parent1">
                                                            <img className="frame-child2" alt="" src="template/public/group-93.png" />

                                                            <div className="vichar-refers-to">
                                                                Vichar refers to our thought patterns and mental attitudes.
                                                                Ayurveda  encourages  cultivating a positive mindset and
                                                                healthy emotional states, such as love, compassion, gratitude,
                                                                and contentment that can help  in better social relationships
                                                                and interactions and  maintain balance and harmony in our mind
                                                                and body.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-parent2">
                                                <b className="recommendation4">Recommendation</b>
                                                <div className="practice-mindful-eating-container">
                                                    <ul className="practice-mindful-eating-payin">
                                                        <li className="practice-mindful-eating">
                                                            Practice mindful eating -paying attention to what we eat,
                                                            eating slowly, chewing our food properly, and avoiding
                                                            overeating can improve digestion.
                                                        </li>
                                                        <li>
                                                            Build awareness of bodily sensations and be attentive to the
                                                            signals of hunger, thirst, and fullness.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-parent9">
                                                <div className="vichar-parent">
                                                    <div className="vichar">Vihar</div>
                                                    <b className="behavioral-recommendations">Behavioral Recommendations</b>
                                                </div>
                                                <div className="clip-path-group-wrapper3">
                                                    <div className="clip-path-group6">
                                                        <img className="group-icon8" alt="" src="template/public/group8.png" />

                                                        <div className="vihar-involves-our-social-and-parent">
                                                            <div className="vihar-involves-our">
                                                                Vihar involves our social and environmental interactions,
                                                                including creative and leisure activities that promote
                                                                relaxation, positive social connections, and well-being.
                                                                Ayurveda emphasizes the importance of maintaining healthy
                                                                social interactions, engaging in enjoyable activities, and
                                                                fostering a connection with our environment. By nurturing
                                                                positive relationships and engaging in meaningful activities,
                                                                we can promote our overall well-being.
                                                            </div>
                                                            <img
                                                                className="clip-path-group7"
                                                                alt=""
                                                                src="template/public/clip-path-group1@2x.png"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-parent2">
                                                <b className="recommendation4">Recommendation</b>
                                                <div className="practice-mindful-eating-container">
                                                    <ul className="practice-mindful-eating-payin">
                                                        <li className="practice-mindful-eating">
                                                            Get enough sleep to reduce stress and aid in digestion.
                                                        </li>
                                                        <li>
                                                            Take a walk or engage in light exercise after a meal to promote
                                                            bowel movements.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-parent3">
                                                <div className="oushadhi-parent">
                                                    <div className="oushadhi">Oushadhi</div>
                                                    <b className="herbal-and-supplement">Herbal and Supplement Recommendations</b>
                                                </div>
                                                <div className="clip-path-group-frame">
                                                    <div className="clip-path-group3">
                                                        <img className="group-icon5" alt="" src="template/public/group5.png" />

                                                        <div className="group-container">
                                                            <img className="frame-child1" alt="" src="template/public/group-92.png" />

                                                            <div className="aushadhi-in-ayurveda">
                                                                Aushadhi in Ayurveda  refers to the use of herbal remedies and
                                                                other natural medicines  to prevent and treat illness. These
                                                                remedies are tailored to an individual's constitution and are
                                                                believed to bring balance and harmony to the body and mind. By
                                                                using natural medicines, Ayurveda emphasizes the importance of
                                                                addressing the root cause of an illness rather than just
                                                                treating its symptoms.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-container">
                                                <b className="recommendation2">Recommendation</b>
                                                <div className="take-1-2-teaspoons-container">
                                                    <ul className="take-1-2-teaspoons-of-triphala">
                                                        <li className="take-1-2-teaspoons">
                                                            Take 1-2 teaspoons of Triphala powder in warm water or milk
                                                            before bed.
                                                        </li>
                                                        <li className="take-1-2-teaspoons">
                                                            Mix 1-2 teaspoons of haritaki powder in a glass of warm water
                                                            and drink it before bedtimeto relieve constipation.
                                                        </li>
                                                        <li>
                                                            Drink a cup of ginger tea (made with fresh ginger root or ginger
                                                            tea bags) before meals to improve digestion
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-parent5">
                                                <div className="oushadhi-parent">
                                                    <div className="oushadhi">Kriya</div>
                                                    <b className="herbal-and-supplement"
                                                    >Purification and Detoxification Practices</b
                                                    >
                                                </div>
                                                <div className="clip-path-group-wrapper1">
                                                    <div className="clip-path-group4">
                                                        <img className="group-icon6" alt="" src="template/public/group6.png" />

                                                        <div className="kriya-practices-in-ayurveda-ai-parent">
                                                            <div className="kriya-practices-in-container">
                                                                <p className="kriya-practices-in">
                                                                    Kriya practices in Ayurveda aim to support the body's
                                                                    natural functions of breathing, digestion, and elimination.
                                                                </p>
                                                                <p className="kriya-practices-in">
                                                                    Incorporating Kriya practices such as cleansing rituals and
                                                                    breathing exercises can promote the healthy functioning of
                                                                    channels responsible for elimination. These practices
                                                                    support physical, mental, and emotional balance, making them
                                                                    an essential aspect of Ayurvedic self-care.
                                                                </p>
                                                            </div>
                                                            <img className="frame-icon" alt="" src="template/public/frame-92.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-container">
                                                <b className="recommendation2">Recommendation</b>
                                                <div className="take-1-2-teaspoons-container">
                                                    <ul className="take-1-2-teaspoons-of-triphala">
                                                        Practice yoga asanas that   stimulate  digestive system and aiding
                                                        in bowel movements -  Paschimottanasana  and Pavanamuktasana
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-container">
                                                <div className="karma-parent">
                                                    <div className="karma">Karma</div>
                                                    <b className="behaviour-modification">Behaviour Modification</b>
                                                </div>
                                                <div className="clip-path-group-wrapper">
                                                    <div className="clip-path-group1">
                                                        <img className="group-icon3" alt="" src="template/public/group3.png" />

                                                        <div className="group-parent">
                                                            <img className="frame-item" alt="" src="template/public/group-95.png" />

                                                            <div className="karma-in-ayurveda">
                                                                Karma in Ayurveda relates to the concept of cause and effect,
                                                                where our actions have a direct impact on our health and
                                                                wellbeing. It emphasizes the importance of conscious choices
                                                                and intentional actions that align with our values and
                                                                purpose.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-parent">
                                                <b className="recommendation">Recommendation</b>
                                                <div className="keep-a-food-container">
                                                    <ul className="keep-a-food-and-bowel-movement">
                                                        <li className="keep-a-food">
                                                            Keep a food and bowel movement diary to track what foods and
                                                            behaviours may be contributing to constipation.
                                                        </li>
                                                        <li>
                                                            Avoid holding in bowel movements: When you feel the urge to have
                                                            a bowel movement, try to go as soon as possible. Holding it in
                                                            can lead to constipation.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="frame-parent1">
                                                <div className="karma-parent">
                                                    <div className="karma">Vyayama</div>
                                                    <b className="behaviour-modification"
                                                    >Physical Exercise Recommendations</b
                                                    >
                                                </div>
                                                <div className="clip-path-group-container">
                                                    <div className="clip-path-group2">
                                                        <img className="group-icon4" alt="" src="template/public/group4.png" />

                                                        <div className="vyayam-the-ayurvedic-concept-parent">
                                                            <div className="vyayam-the-ayurvedic">
                                                                Vyayam, the Ayurvedic concept of physical exercise, emphasizes
                                                                the importance of regular physical activity such as yoga,
                                                                walking, or other forms of exercise. Incorporating regular
                                                                physical exercise into our daily routine can help improve
                                                                flexibility, strength, balance, coordination, cardiovascular
                                                                health, and boost the immune system.
                                                            </div>
                                                            <img className="frame-inner" alt="" src="template/public/group-91.png" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="recommendation-parent">
                                                <b className="recommendation">Recommendation</b>
                                                <div className="keep-a-food-container">
                                                    <ul className="keep-a-food-and-bowel-movement">
                                                        <li className="keep-a-food">
                                                            Incorporate physical exercise, such as walking or jogging, into
                                                            your daily routine to promote regular bowel movements.
                                                        </li>
                                                        <li>
                                                            Abdominal massage in a clockwise direction can help to stimulate
                                                            the muscles of the digestive tract and promote regular bowel
                                                            movements.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <img
                                            className="beige-natural-botanical-skinca4-bottom"
                                            alt=""
                                            src="template/public/footerimage.png"
                                        />
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
