import * as React from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import { ApplicationState } from '../../../../store/index';
type Props = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & RouteComponentProps;
class FtrTheraque extends React.PureComponent<Props> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        //this.props.requestSiteDetail();
    }
    public renderFooter() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="footer-bottom">
                        <div className="container-fluid">
                            <div className="copyright">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="copyright-text">
                                            <h4 className="mb-0 text-center text-white">Theraquehealth &copy; 2022 </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.siteDetail }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators }) // Selects which action creators are merged into the component's props
)(FtrTheraque as any);

