import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
export interface PharmacyStateListState {
    isLoading: boolean,
    pharmacyStateLists: PharmacyStateList[]
}
export interface PharmacyStateList {
    Id: string;
    ExtRefId: string;
    Name: string;
    Address1: string;
    Address2: string;
    State: string;
    City: string;
    Zipcode: string;
    PhoneNo: string;
    Coordinates: string;
    TotalRecords: string;
}
export interface RequestPharmacyStateListAction { type: 'REQUEST_PHARMACYSTATE_LIST', pharmacyStateLists: PharmacyStateList[] }
export interface RecivePharmacyStateListAction { type: 'RECEIVE_PHARMACYSTATE_LIST', pharmacyStateLists: PharmacyStateList[] }
export type KnownAction = RequestPharmacyStateListAction | RecivePharmacyStateListAction;
export const actionCreators = {
    requestPharmacyStateList: (doctorid:any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.pharmacyStateLists) {
            axios.get('/pharmacy/GetPharmacyState/' + doctorid)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_PHARMACYSTATE_LIST', pharmacyStateLists: result, isLoading:false });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_PHARMACYSTATE_LIST' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: PharmacyStateListState = {
    pharmacyStateLists: [],
    isLoading: false
};
export const reducer: Reducer<PharmacyStateListState> = (state: PharmacyStateListState | undefined, incomingAction: Action): PharmacyStateListState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PHARMACYSTATE_LIST':
            return {
                pharmacyStateLists: state.pharmacyStateLists,
                isLoading: true
            };
        case 'RECEIVE_PHARMACYSTATE_LIST':
            return {
                pharmacyStateLists: action.pharmacyStateLists,
                isLoading: false
            };
        default:
            return state;
    }
};
