import axios from 'axios';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { email } from '../../helper/formvalidation';
import { Stream } from 'stream';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { ReactFragment } from 'react';
import { dateUTCFormat, dateFormat } from '../../helper/formvalidation';
import { actionCreators as _paymentAction } from '../patient/Transaction';
import { actionCreators as _encounterModuleAction } from '../appointment/encounterModule';
//import Moment from 'moment';
//import { actionCreators as _flexiAction } from '../../library/flexiform/FlexiForm';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
import { actionCreators as _NavDefault } from '../../store/menu/NavDefault';

export interface PrintDetailState {      
    Print: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestPrintDetailAction { type: 'REQUEST_PRINT_DETAIL',Print: string }
export interface RecivePrintDetailAction { type: 'RECEIVE_PRINT_DETAIL', Print: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestPrintDetailAction | RecivePrintDetailAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
   
    getprescriptionprint: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var prescriptionid: any = value;        
        var accountid: any = localStorage.getItem("AccountId");
        axios.get('/Appointment/GetPrescriptionPrint/' + prescriptionid + "/" + accountid + "/" + "39")
            .then(function (response) {
                const result = response.data;
                dispatch({ type: 'RECEIVE_PRINT_DETAIL', Print: result });
                if (result == null) {
                    localStorage.setItem("prescriptionprint", result);                    
                }
                else
                    localStorage.setItem("prescriptionprint", result);
            })
            .catch(error => {
                //if (error.response.data.statusCode == 400) {
                //    var errorObject = JSON.parse(error.response.data.errorMessage);
                //    toastr.error('', errorObject.error_description);
                //} else {
                //    errorHandling(error.response.data.statusCode);
                //}
            })
            .then(function () {
            });
        dispatch({ type: 'REQUEST_PRINT_DETAIL', visitDetailLoading: true });
    },
    requestPrintDetail: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        var prescriptionid = value;

        var accountid: any = localStorage.getItem("AccountId");

        axios.get('/appointment/GetPrintDetails/' + prescriptionid + "/" + accountid)
            .then(function (response) {
                const result = response.data;

                var doc = new jsPDF("p", "mm", "a4");

                const primaryColor = getComputedStyle(document.documentElement)
                    .getPropertyValue('--primary-color');

                doc.setFontSize(22);



                //doc.text(35, 25, "Octonyan loves jsPDF");
                //doc.addImage(imgData, 'JPEG', 15, 40, 180, 180);
                if (result.SiteLogo !== null) {
                    doc.addImage(result.SiteLogo, 'JPEG', 10, 5, 50, 10);
                }
                //doc.addImage(result.SiteLogo, 'JPEG', 10, 5, 50, 10);
                doc.setFontSize(17)

                doc.text("Prescription", 100, 10, { align: "center" });
                doc.setFontSize(10)
                var xaxis = 10;
                var yaxis = 25;
                var yaxisR = 25;
                var lMargin = 30; //left margin in mm
                var rMargin = 15; //right margin in mm
                var pdfInMM = 85;  // width of A4 in mm
                doc.text("Patient Name", xaxis, yaxis);
                if (result.Patient.PatientName != null && result.Patient.PatientName != "") {
                    doc.text(result.Patient.PatientName, xaxis + 40, yaxis);
                }

                doc.text("Prescribed By ", xaxis + 120, yaxis);
                if (result.Prescription.DoctorName != null && result.Prescription.DoctorName != "") {
                    // result.Prescription.DoctorName="fuif ihgf ig odg dsgjd fodgdfog gkd"
                    let lines = doc.splitTextToSize(result.Prescription.DoctorName, (pdfInMM - lMargin - rMargin));
                    //doc.text(30, 20, lines);
                    /*doc.splitTextToSize(result.Prescription.DoctorName, 7.5)*/
                    doc.text(lines, xaxis + 190, yaxisR, { align: "right" });
                    if (result.Prescription.DoctorName.trim().length > 25) {
                        yaxisR = pdfInMM - lMargin - rMargin
                        yaxisR = yaxisR - 5
                    }
                    else {
                        yaxisR = yaxisR + 5
                    }
                }
                yaxis = 30

                doc.text("Patient No", xaxis, yaxis);
                if (result.Patient.PatientNo != null && result.Patient.PatientNo != "") {
                    doc.text(result.Patient.PatientNo, xaxis + 40, yaxis);
                }


                doc.text("Prescribed On ", xaxis + 120, yaxisR);
                if (result.Prescription.PrescribedOn != null && result.Prescription.PrescribedOn != "") {
                    doc.text(dateFormat(result.Prescription.PrescribedOn) + " " + Moment(result.Prescription.PrescribedOn).format('LT'), xaxis + 190, yaxisR, { align: "right" });
                }
                yaxis = 35
                yaxisR = yaxisR + 5
                doc.text("Age", xaxis, yaxis);
                if (result.Patient.PatientAge != null && result.Patient.PatientAge != "") {
                    doc.text(result.Patient.PatientAge, xaxis + 40, yaxis);
                }

                doc.text("Prescription No ", xaxis + 120, yaxisR);
                if (result.Prescription.RxNo != null && result.Prescription.RxNo != "") {
                    doc.text(result.Prescription.RxNo, xaxis + 190, yaxisR, { align: "right" });
                }

                yaxis = 40
                yaxisR = yaxisR + 5
                doc.text("Gender", xaxis, yaxis);
                if (result.Patient.PatientGender != null && result.Patient.PatientGender != "") {
                    doc.text(result.Patient.PatientGender, xaxis + 40, yaxis);
                }


                if (result.Facility.Address1 != null && result.Facility.Address1 != "") {
                    doc.text("Provider Address ", xaxis + 120, yaxisR);
                    let lines = doc.splitTextToSize(result.Facility.Address1, (pdfInMM - lMargin - rMargin));
                    doc.text(lines, xaxis + 190, yaxisR, { align: "right" });
                    // doc.text(result.Facility.Address1, xaxis + 190, yaxisR, { align: "right" });
                    // doc.text("dsjfsdh sdf", xaxis + 190, yaxis, { align: "right" });
                    if (result.Facility.Address1.trim().length <= 21) {
                        yaxisR = yaxisR + 10

                    }
                    else if (result.Facility.Address1.trim().length > 21 && result.Facility.Address1.trim().length < 45) {
                        yaxisR = yaxisR + 15
                        /*yaxisR = yaxisR + 3*/
                    }
                    else if (result.Facility.Address1.trim().length > 45 && result.Facility.Address1.trim().length < 65) {
                        yaxisR = yaxisR + 15
                        yaxisR = yaxisR + 3

                    }
                    else if (result.Facility.Address1.trim().length > 65) {
                        yaxisR = yaxisR + 15
                        yaxisR = yaxisR + 3
                        yaxisR = yaxisR + 3
                    }
                    else {
                        yaxisR = yaxisR + 5
                    }

                }

                yaxis = 45

                if (result.Patient.Address1 != null && result.Patient.Address1 != "") {
                    doc.text("Patient Address", xaxis, yaxis);
                    doc.text(result.Patient.Address1, xaxis + 40, yaxis);
                    //doc.text("sfjk", xaxis + 40, yaxis);
                    yaxis = 50
                }
                /*  if (result.Facility.Address2 != null && result.Facility.Address2 != "") {
                      doc.text(result.Facility.Address2, xaxis + 190, yaxisR, { align: "right" });
                      yaxisR = 50
                  }
  
  
                  if (result.Patient.Address2 != null && result.Patient.Address2 != "") {
                      
                      doc.text(result.Patient.Address2, xaxis + 40, yaxis);
                      yaxis = 55
                  }
                  if (result.Facility.City != null && result.Facility.City != "") {
                      doc.text(result.Facility.City, xaxis + 190, yaxis, { align: "right" });
                      yaxisR=55
                  }
                  if (result.Patient.City != null && result.Patient.City != "") {
                      
                      doc.text(result.Patient.City, xaxis + 40, yaxis);
                      yaxis = 60
                  }*/
                yaxisR = yaxisR - 5;
                if (result.Facility.CellPhone != null && result.Facility.CellPhone != "") {
                    doc.text("Phone No ", xaxis + 120, yaxisR);
                    doc.text(result.Facility.CellPhone, xaxis + 190, yaxisR, { align: "right" });
                    //doc.text("0987654321", xaxis + 190, yaxisR, { align: "right" });
                    yaxisR = yaxisR + 5
                }
                if (result.Patient.CellPhone != null && result.Patient.CellPhone != "") {
                    doc.text("Phone No", xaxis, yaxis);
                    doc.text(result.Patient.CellPhone, xaxis + 40, yaxis);
                    yaxis = 55;
                }
                if (result.Facility.Email != null && result.Facility.Email != "") {
                    doc.text("Email ", xaxis + 120, yaxisR);
                    let lines = doc.splitTextToSize(result.Facility.Email, (pdfInMM - lMargin - rMargin));
                    doc.text(lines, xaxis + 190, yaxisR, { align: "right" });
                    if (result.Facility.Email.trim().length > 20) {
                        yaxisR = pdfInMM - lMargin - rMargin
                        yaxisR = yaxisR + 10
                    }
                    else {
                        yaxisR = yaxisR + 5
                    }
                    //doc.text(result.Facility.Email, xaxis + 190, yaxisR, { align: "right" });
                    // doc.text("0987654321", xaxis + 190, yaxisR, { align: "right" });
                }
                if (result.Patient.Email != null && result.Patient.Email != "") {
                    doc.text("Email ", xaxis, yaxis);
                    doc.text(result.Patient.Email, xaxis + 40, yaxis);
                    // doc.text("ttttt@gmail.com", xaxis + 40, yaxis + 5);
                }

                /*doc.text("Provider Address ", xaxis + 130, yaxis);
                doc.text(dateFormat(result.Prescription.PrescribedOn) + " " + Moment(result.Prescription.PrescribedOn).format('LT'), xaxis + 200, yaxis, { align: "right" });
                */
                var header: any = ['S.No.', 'Drug Name', 'Days', 'Qty', 'Route of Adminstration'];
                var drugTableData: any = [];
                var footerData: any = []
                var colSpan: any = header.length;

                result.DrugDetails.forEach((drug: any, index: number) => {
                    drugTableData.push([index + 1, drug.DrugName + "\n(" + drug.SIG + ")", drug.Days, drug.Quantity, drug.RouteOfAdministration != null && drug.RouteOfAdministration != "" ? drug.RouteOfAdministration : "-"])


                })

                if (drugTableData.length == 0 && result.Prescription.Note !== "") {
                    header = ["Note", '', '', '', '', '']
                    footerData = [`${result.Prescription.Note}`]
                    colSpan = 5

                    console.log('in')
                } else if (drugTableData.length == 0 && result.Prescription.Note == "") {
                    header = []
                    footerData = [[]]
                } else if (drugTableData.length > 0 && result.Prescription.Note == "") {
                    footerData = []
                } else if (drugTableData.length > 0 && result.Prescription.Note != "") {
                    footerData = [`Note: ${result.Prescription.Note}`]
                }


                //console.log(footerData, colSpan)

                const hasFooter: any = footerData.length > 0;
                colSpan = header.length

                //autoTable(doc, {
                //    head: [header],
                //    body: drugTableData,                      
                //    startY: 70,
                //    tableWidth: "auto",
                //    styles: {
                //        fontSize: 12,
                //    },                      
                //    headStyles: { fillColor: primaryColor },
                //    alternateRowStyles: { fillColor: [248, 248, 248] },
                //    tableLineWidth: 0.1
                //});

                autoTable(doc, {
                    head: [header],
                    body: drugTableData,
                    startY: 70,
                    tableWidth: 'auto',
                    styles: {
                        fontSize: 12,
                    },
                    headStyles: { fillColor: primaryColor },
                    alternateRowStyles: { fillColor: [248, 248, 248] },
                    foot: hasFooter ? [[{ content: footerData.join("\n"), colSpan: colSpan }]] : [],
                    footStyles: hasFooter ? { fillColor: [248, 248, 248], fontStyle: 'normal', textColor: [90, 90, 90] } : undefined,
                    tableLineWidth: 0.1,
                });


                doc.setFontSize(12)
                let finalY = (doc as any).lastAutoTable.finalY;
                yaxis = 130
                doc.text("Note: This prescription is generated on a teleconsultation", xaxis, finalY + 20);
                doc.autoPrint();
                doc.output('dataurlnewwindow');
            })
            .catch(error => {
                if (error.response && error.response.data) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                } else {
                    console.log(error)
                }
            })
            .then(function () {
                // always executed
            });
        //dispatch({ type: 'REQUEST_VISIT_DETAIL', visitDetailLoading: true });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: PrintDetailState = {    
    Print: "",
    
};
export const reducer: Reducer<PrintDetailState> = (state: PrintDetailState | undefined, incomingAction: Action): PrintDetailState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PRINT_DETAIL':
            return {
                
                Print: state.Print,
                
            };
        case 'RECEIVE_PRINT_DETAIL':
            return {
                
                Print: action.Print,
                
            };

        default:
            return state;
    }
};

