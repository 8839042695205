import * as React from 'react';
import { NavLink, Link, RouteComponentProps } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar'
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../store/index';
import * as LoginStore from '../../../../store/account/Login';
import * as ContactStore from '../../../../store/patient/Contact';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import * as AccountStore from '../../../../store/siteaccount/Account';
import * as EncounterTypeStore from '../../../../store/patient/EncounterType';
import * as FlexFromStore from '../../../../store/library/flexiform/FlexiForm';
import * as SiteMenusStore from '../../../../store/siteaccount/siteMenu';
import * as MenuHideStore from '../../../../store/menuHide';
import * as LanguageResourcesStore from '../../../../store/LanguageResoures';
import MobLanguageList from '../MobLanguageList';
import LanguageList from '../LanguageList';
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);
var authLocalStroage: any = localStorage.getItem('isAuth');
var languagecontent: any = "";
type NavDefaultStoreProps = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & LoginStore.LoginState & AccountStore.AccountState & AccountStore.Settings & ContactStore.ContactState & EncounterTypeStore.EncounterTypeState & SiteMenusStore.SiteMenuState & FlexFromStore.FlexiListsState & MenuHideStore.MenuHideState & LanguageResourcesStore.LanguageResouresState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LoginStore.actionCreators & typeof AccountStore.actionCreators & typeof ContactStore.actionCreators & typeof EncounterTypeStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof FlexFromStore.actionCreators & typeof MenuHideStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps;
class NavABC extends React.PureComponent<NavDefaultStoreProps> {
    public componentDidMount() {
        this.ensureDataFetched();
    }
    componentWillMount() {
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        this.ensureDataFetched();

    }
    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        /*this.props.requestSiteDetail();*/
        this.props.getDefaultAccount();
        this.props.requestEncounterType();
        this.props.getSiteMenu();
        if (localStorage.getItem("isAuth") == "true") {
            //this.props.getFormModule();
        }
    }
    public logOut() {
        this.props.requestLogout();
    }
    public login() {
        //localStorage.setItem("isAuth", "false");
        //console.log("ttt")
        return <NavLink to="/login" />;
    }
    public signup() {

        return <NavLink to="/signup" />;
    }
    goOurDoctor = () => {
        this.props.goNextPage();
    }
    public renderDoctorList() {
        languagecontent = this.props.LanguageResoures;
        var formArray: any = [];
        formArray = this.props.moduleLists.filter(function (el: any) {
            return ((el.id == null) && (el.showOnce == true) && (el.moduleId != 71) && (el.moduleId != 86) && (el.moduleId != 41) && (el.moduleId != 59));
        });
        var publicMenus: any = [];
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "1");
        var data = window.location.pathname;
        const _array = data.split('/');
        return (
            <React.Fragment>
                <header className="header">
                    <LoadingBar style={{ backgroundColor: '#15558d', height: '10px' }} className="loading" updateTime={100} maxProgress={95} progressIncrease={10} />
                    <nav  className="navbar navbar-expand-lg header-nav">
                        <div className="navbar-header f25">
                            <a id="mobile_btn">
                                <span className="bar-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </a>
                            {
                                this.props.siteDetail != undefined ?
                                    <a href="/" className="navbar-brand logo customlogo">
                                        {
                                            this.props.siteDetail.LogoPath == "" || this.props.siteDetail.LogoPath == undefined || this.props.siteDetail.LogoPath == null ? this.props.siteDetail.Name : <img src={this.props.siteDetail.LogoPath} alt="Logo" />
                                        }
                                    </a> : ""
                            }
                        </div>

                        <div className="main-menu-wrapper">
                            <div className='d-flex flex-column'>
                                <div className="menu-header">
                                    {
                                        this.props.siteDetail != undefined ?
                                            <a href="/" className="menu-logo customlogo slidelogo">
                                                {
                                                    this.props.siteDetail.LogoPath == "" || this.props.siteDetail.LogoPath == undefined || this.props.siteDetail.LogoPath == null ? this.props.siteDetail.Name : <img src={this.props.siteDetail.LogoPath} alt="Logo" />
                                                }
                                            </a> : ""
                                    }
                                    <a id="menu_close" className="menu-close">
                                        <i className="fas fa-times"></i>
                                    </a>
                                </div>
                                <div style={{ height: '50px' }} className='row d-flex menu-header d-lg-none'>
                                    <div className='col d-flex justify-content-between align-items-center'>
                                        <div className='d-flex'>
                                            
                                            {
                                                this.props.navisLoading == true ? "" : <NavLink  hidden={this.props.isShow || this.props.navDefault.isAuth} onClick={() => this.signup()} className=" dropdown-item p-1 m-0 w-auto text-md-nowrap" style={{ fontSize: '14px' }} to="/signup">{languagecontent['sign_up']}</NavLink>
                                            }


                                            {
                                                this.props.navisLoading == true ? "" : <span className={this.props.isShow || this.props.navDefault.isAuth ? 'd-none' : 'my-auto mx-0 p-1'}>|</span>
                                            }

                                            {
                                                this.props.navisLoading == true ? "" : <NavLink  hidden={this.props.isShow || this.props.navDefault.isAuth} onClick={() => this.login()} className="dropdown-item p-1 pr-0 m-0 w-auto text-md-nowrap" style={{ fontSize: '14px' }} to="/login">{languagecontent['sign_in']}</NavLink>
                                            }                                           
                                        </div>
                                        <MobLanguageList />
                                    </div>
                                </div>
                            </div>
                            <ul className="main-nav">
                                <li className="login-link" style={{ color: "#000000" }}>
                                    <React.Fragment>
                                        {
                                            publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                                <React.Fragment>
                                                    {
                                                        sitemenu.Src == "/ourdoctors" ? <React.Fragment>{formArray.length == 0 ? <a className=" dropdown-item" onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : ""}</React.Fragment> : sitemenu.Src == "/centralized" ? <a className=" dropdown-item" onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : <a className=" dropdown-item" href={sitemenu.Src}>{sitemenu.Title}</a>
                                                    }
                                                </React.Fragment>
                                            )
                                        }
                                    </React.Fragment>
                                {/*<li className="login-link">*/}
                                    {
                                        this.props.navDefault.isAuth == true ?
                                            <React.Fragment>
                                                {_array[1] == "initialpageforms" ? "" : <NavLink className="dropdown-item" to="/dashboard">{languagecontent['dashboardmenu']}</NavLink>}
                                                <NavLink className={this.props.isSettingsLoading ? "dropdown-item disabled" : "dropdown-item"} to="/login" onClick={() => this.logOut()}>{languagecontent['logoutmenu']}</NavLink>
                                            </React.Fragment> : ""
                                            //<React.Fragment>
                                            //    {
                                            //        publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                            //            <li>
                                            //                {
                                            //                    sitemenu.Src == "/ourdoctors" ? <React.Fragment>{formArray.length == 0 ? <a className=" dropdown-item" onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : ""}</React.Fragment> : sitemenu.Src == "/centralized" ? <a className=" dropdown-item" onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : <a className=" dropdown-item" href={sitemenu.Src}>{sitemenu.Title}</a>
                                            //                }
                                            //            </li>
                                            //        )
                                            //    }                                              
                                            //</React.Fragment>
                                    }                               
                                </li>
                            </ul>

                        </div>
                        <div className='row d-none d-lg-flex flex-column '>
                            <div className='col d-flex justify-content-end align-items-center'>
                        <ul className="nav header-navbar-rht">
                            
                            {
                                this.props.navDefault.isAuth == false || authLocalStroage == false ?
                                            <li className='pr-0'>                                      
                                                <div className='d-flex align-items-center'>                                                 
                                                    {
                                                        this.props.navisLoading == true ? "" : <NavLink hidden={this.props.isShow} onClick={() => this.signup()} className="mr-1 p-0" style={{ fontSize: '14px' }} to="/signup">{languagecontent['sign_up']}</NavLink>
                                                    }
                                                    {
                                                        this.props.navisLoading == true ? "" : <p className={this.props.isShow ? 'd-none' : 'm-0  p-0'}>|</p>
                                                    }
                                                    {
                                                        this.props.navisLoading == true ? "" : <NavLink hidden={this.props.isShow} onClick={() => this.login()} className="ml-1 p-0" style={{ fontSize: '14px' }} to="/login">{languagecontent['sign_in']}</NavLink>
                                                    }                                                
                                                </div>
                                    </li>
                                    :
                                            <li style={{ height: '40px' }} className="nav-item dropdown has-arrow logged-item pr-0">
                                        <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                            <span className="user-img">
                                                <img className="rounded-circle" src={this.props.contact.photo == "" || this.props.contact.photo == undefined ? "client/default/img/blankimg.jpg" : this.props.contact.photo} width="31" alt="" />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">{
                                            this.props.patient != undefined ?
                                                <div className="user-header">
                                                    <div className="user-text">
                                                        <h6>{this.props.contact.firstName + " " + this.props.contact.lastName} </h6>
                                                    </div>
                                                </div> : ''
                                        }
                                            {
                                                        formArray.length == 0 ?
                                                             
                                                            _array[1] == "initialpageforms" ? "" : <NavLink className="dropdown-item" style={{ padding: '10px 25px' }} to="/dashboard">{languagecontent['dashboardmenu']}</NavLink>
                                                    : ""
                                            }
                                                    <a className={this.props.isSettingsLoading ? "dropdown-item disabled" : "dropdown-item"} style={{ padding: '10px 25px' }} onClick={() => this.logOut()}>{languagecontent['logoutmenu']}</a>
                                        </div>
                                    </li>
                            }
                                    <div>
                                        <LanguageList />
                                    </div>
                                </ul>
                                </div>                    
                            <div className='col'>
                                <ul className="nav header-navbar-rht">
                                    {
                                        publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                            <li>
                                                {
                                                    sitemenu.Src == "/ourdoctors" ? <React.Fragment>{formArray.length == 0 ? <a onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : ""}</React.Fragment> : sitemenu.Src == "/centralized" ? <a onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : <a href={sitemenu.Src}>{sitemenu.Title}</a>
                                                }
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            </div>
                    </nav>
                    <section>
                        <LoadingBar style={{ backgroundColor: '#15558d', height: '2px' }} className="loading" updateTime={100} maxProgress={90} progressIncrease={10} scope="sectionBar" />
                    </section>
                </header>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.login, ...state.siteDetail, ...state.account, ...state.contact, ...state.encountertypes, ...state.flexiform, ...state.siteMenus, ...state.menuHide, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...LoginStore.actionCreators, ...SiteDetailStore.actionCreators, ...AccountStore.actionCreators, ...ContactStore.actionCreators, ...EncounterTypeStore.actionCreators, ...FlexFromStore.actionCreators, ...SiteMenusStore.actionCreators, ...MenuHideStore.actionCreators, ...LanguageResourcesStore.actionCreators }) // Selects which action creators are merged into the component's props
)(NavABC as any);