import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as PaymentProcessStore from '../../store/payment/PaymentProcess';
import { floatingJS, mobnav, seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import {scrollTop, setTittle } from '../../helper/formvalidation';
// At runtime, Redux will merge together...
type PaymentProcessStoreProps =
	PaymentProcessStore.PaymentProcessState & LanguageResourcesStore.LanguageResouresState // ... state we've requested from the Redux store
	& typeof PaymentProcessStore.actionCreators & typeof LanguageResourcesStore.actionCreators // ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
const urlParams = new URLSearchParams(window.location.search);
const myParam = urlParams.get('type');
if (myParam == "selfpay") {
	localStorage.setItem("selfpayurl", window.location.href);
}
var languagecontent: any = "";
class Success extends React.PureComponent<PaymentProcessStoreProps> {

	constructor(props: any) {
		super(props);
	}
	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.ensureDataFetched();
		scrollTop();
		mobnav();
	}
	componentDidUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
	}
	

	public render() {
		return (
			<React.Fragment>
				{this.renderPaymentDetail()}
			</React.Fragment>
		);
	}
	
	private ensureDataFetched() {
		this.props.getLangResources();
		const urlParams = new URLSearchParams(window.location.search);
		const isemail = urlParams.get('isEmail');
		const memberid = urlParams.get('memberid');
		if (isemail == "true") {
			localStorage.removeItem("selfpayurl");
			this.props.requestPaymentlinkValidation(this.props.match.params, memberid);
		} else {
			this.props.requestPostPaymentProcess(this.props.match.params);
		}
	}
	
	private renderPaymentDetail() {
		languagecontent = this.props.LanguageResoures;
		if (this.props.paymentdetail == undefined) {
			return;
        }
		return (
			<React.Fragment>
				<div className="content">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-lg-6">
								<div className="card success-card">
									<div className="card-body">
									<form name='Payform' action={this.props.paymentdetail.RedirectUrl} method='post'>
										<input type='hidden' name='ClientId' value={this.props.paymentdetail.ClientId} />
										<input type='hidden' name='CustomerName' value={this.props.paymentdetail.CustomerName} />
										<input type='hidden' name='Email' value={this.props.paymentdetail.Email} />
										<input type='hidden' name='OrderID' value={this.props.paymentdetail.OrderID} />
										<input type='hidden' name='Amount' value={this.props.paymentdetail.Amount} />
										<input type='hidden' name='Currency' value={this.props.paymentdetail.Currency} />
										<input type='hidden' name='ReturnUrl' value={this.props.paymentdetail.ReturnUrl} />
										<input type='hidden' name='CancelUrl' value={this.props.paymentdetail.CancelUrl} />
										<input type='hidden' name='CustomerAddress' value={this.props.paymentdetail.CustomerAddress} />
										<input type='hidden' name='Phone' value={this.props.paymentdetail.Phone} />
										<input type='hidden' name='CurrencyDisplayFormat' value={this.props.paymentdetail.CurrencyDisplayFormat} />
										<h3 className="text-center"><i className="fa fa-spinner fa-spin"></i> {languagecontent['pleasewait']}...</h3>
									</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({ ...state.paymentProcess, ...state.langResources }), // Selects which state properties are merged into the component's props
	({ ...PaymentProcessStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(Success as any);
