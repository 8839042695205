import * as React from 'react';
import ReactApexChart from "react-apexcharts";


function PieChart(props:any) {
    /*console.log(JSON.parse(result[0].value))*/

    let data: any = props.result[0].value == '' ? [] : JSON.parse(props.result[0].value)
    let label: any = []
    let score: any = []

    if (data.length) {
        data.reverse().map((list: any) => {
            label.push(list.Type)
            score.push(list.Percentage)
        })
    }
   

    const options :any= {
        chart: {
           width:420,
            height: 420,
            type: 'pie',
        },
        colors: ['#0000CC', '#FF0000', '#009900'],
        labels: label,
        title: {
            text: props.result[0].clientTitle,
           
            align: 'left',
            margin: 5,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
                fontSize: '14px',
                fontWeight: 'normal',
                
            },
        },
        legend: {
            show: true,
            style: {
                margin: 5,
            },
            showForSingleSeries: false,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: 'top',
            horizontalAlign: 'center',
            floating: false,
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            formatter: undefined,
            inverseOrder: false,
            width: undefined,
            height: undefined,
            tooltipHoverFormatter: undefined,
            customLegendItems: [],
            offsetX: 0,
            offsetY: 0,
            labels: {
                colors: undefined,
                useSeriesColors: false
            },
            markers: {
                width: 50,
                height: 15,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 0,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
            itemMargin: {
                horizontal: 5,
                vertical: 5
            },
            onItemClick: {
                toggleDataSeries: true
            },
            onItemHover: {
                highlightDataSeries: true
            },
        },
     
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -30,
                }
            }
        },
       
                dataLabels: {
                    enabled: true,
                    formatter(val: any, opts: any) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [val.toFixed(2) + '%']
                    },
                                     
                },
           
        yaxis: {
            labels: {
                formatter: (value :any) => {
                    return [value.toFixed(2) +'%']
                },
               
            }
        },
     
      
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 350,
                    height:350
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: true,
                    showForZeroSeries: true,
                    position: 'top',
                    horizontalAlign: 'center',
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false
                    },
                    markers: {
                        width: 30,
                        height: 13,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 0,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: true
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                },
                markers: {
                    width: 35,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 0,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },

            }
        }]
    }


    return (
        <>
            {data.length ?
                <ReactApexChart options={options} series={score} type="pie" width={420} height={420}  /> : ''
            }

        </>
    )
}


export default PieChart