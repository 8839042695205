import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import Moment from 'moment';
import { errorHandling, errorMessage } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface GetProviderIdState {
    isProviderIdLoading: boolean;
    providerId: string;
}

export interface RequestProviderIdAction { type: 'REQUEST_PROVIDER_ID', providerId: string, isProviderIdLoading: boolean }
export interface ReciveProviderIdAction { type: 'RECEIVE_PROVIDER_ID', providerId: string, isProviderIdLoading: boolean }
export type KnownAction = RequestProviderIdAction | ReciveProviderIdAction;
export const actionCreators = {
    getProviderId: (datetime: any, offset: any, datetime1: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        const urlParams = new URLSearchParams(window.location.search);
        const specialityid = urlParams.get('specialityid');
        if (appState && appState.getProviderId) {
            var encDetailStroage: any = localStorage.getItem('EncDetail');
            var encDetail = JSON.parse(encDetailStroage);
            if (localStorage.getItem("AppointmentUTCTime"))
                datetime = Moment(localStorage.getItem("AppointmentUTCTime")).format("YYYY-MM-DDTHH:mm:ss");
            else
                datetime = null;
            if (localStorage.getItem("AppointmentUTCTime1"))
                datetime1 = Moment(localStorage.getItem("AppointmentUTCTime1")).format("YYYY-MM-DDTHH:mm:ss");
            //if (localStorage.getItem("isAuth") == "true") {
            //    var patientLocalStroage: any = localStorage.getItem('Patient');
            //    var patient = JSON.parse(patientLocalStroage);
            //    if (patient.timeZone == "") {
            //        var totalMinuteOffset: any = offset;
            //        var d = new Date(datetime);
            //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            //        datetime = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
            //        if (datetime1 != null) {
            //            var d = new Date(datetime1);
            //            d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            //            datetime1 = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
            //        }
            //    } else {
            //        const tzOffset = offset;
            //        const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
            //        var totalMinuteOffset: any = hourOffset * 60 + minuteOffset;
            //        var d = new Date(datetime);
            //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            //        datetime = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
            //        if (datetime1 != null) {
            //            var d = new Date(datetime1);
            //            d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            //            datetime1 = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
            //        }
            //    }
            //}
            //else {
            //    var totalMinuteOffset: any = parseInt(offset);
            //    var d = new Date(datetime);
            //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            //    datetime = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
            //    if (datetime1 != null) {
            //        var d = new Date(datetime1);
            //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
            //        datetime1 = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
            //    }
            //}
            if (datetime) {
                axios.get('/doctor/GetProvider/' + datetime + '/' + encDetail.durationCode + '/' + specialityid + '/' + datetime1)
                    .then(function (response) {
                        localStorage.setItem("ProviderId", response.data)
                        dispatch({ type: 'RECEIVE_PROVIDER_ID', isProviderIdLoading: true, providerId: response.data });
                    })
                    .catch(function (error) {
                        errorMessage(error.response.data);
                    })
                    .then(function () {
                    });
            }
        }
        dispatch({ type: 'REQUEST_PROVIDER_ID', isProviderIdLoading: true });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: GetProviderIdState = {
    providerId: "",
    isProviderIdLoading: false
};
export const reducer: Reducer<GetProviderIdState> = (state: GetProviderIdState | undefined, incomingAction: Action): GetProviderIdState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PROVIDER_ID':
            return {
                providerId: state.providerId,
                isProviderIdLoading: true
            };
        case 'RECEIVE_PROVIDER_ID':
            return {
                providerId: action.providerId,
                isProviderIdLoading: false
            };
        default:
            return state;
    }
};
