import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface TimeZoneState {
    isTimeZoneLoading: boolean;
    TimeZones: TimeZone[]
}

export interface TimeZone {
    Offset: string;
    Name: string;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestTimeZoneAutoCompleteAction { type: 'REQUEST_TIMEZONE_AUTO', TimeZones: TimeZone[], isTimeZoneLoading:true }
export interface ReciveITimeZoneAutoCompleteAction { type: 'RECEIVE_TIMEZONE_AUTO', TimeZones: TimeZone[], isTimeZoneLoading:false }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestTimeZoneAutoCompleteAction | ReciveITimeZoneAutoCompleteAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestTimeZone: (keyword: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.timeZones) {
            axios.get('/autocomplete/SearchTimeZone/' + keyword)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_TIMEZONE_AUTO', TimeZones: result, isTimeZoneLoading:false });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_TIMEZONE_AUTO', isTimeZoneLoading:true});
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: TimeZoneState = {
    isTimeZoneLoading: false,
    TimeZones: [],
};
export const reducer: Reducer<TimeZoneState> = (state: TimeZoneState | undefined, incomingAction: Action): TimeZoneState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_TIMEZONE_AUTO':
            return {
                isTimeZoneLoading: state.isTimeZoneLoading,
                TimeZones: state.TimeZones,
            };
        case 'RECEIVE_TIMEZONE_AUTO':
            return {
                isTimeZoneLoading: action.isTimeZoneLoading,
                TimeZones: action.TimeZones,
            };
        default:
            return state;
    }
};
