import * as React from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import * as SiteDetailStore from '../../../store/SiteDetail';
import * as AccountStore from '../../../store/siteaccount/Account';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';

type LanguageListStoreProps = AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators& RouteComponentProps;
class LanguageList extends React.PureComponent<LanguageListStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
        this.setState({
            Name: localStorage.getItem("DefaultLangCode") == "en-us" ? "English" : "Spanish",
            Code: "",
            Check: true
        });
    }
    state = {
        Name: "",
        Code: "",
        Check: false
    };
   
    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.getLangResourcesValidations();
        this.props.getLangResources();
    }
    changeLanguage = (value: any) => {        
        var languagecode: any = value == "English" ? "en-us" : "es-cr";
        localStorage.setItem("DefaultLangCode", languagecode);       
        this.props.getLangResourcesValidations();
        this.props.getLangResources();
        this.setState({
            Name: value,
            Code: languagecode,
            Check: true
        });
        if (localStorage.getItem('isAuth') == 'true') {
            this.props.prefferedLanguage();
        }
        setTimeout(async function () {
            await window.location.reload(true);
        }, 500);
        
        

    }

    public renderDoctorList() {
        var languagelists: any = []
        var _languagelists: any = []
        languagelists = this.props.Settings.filter((s: any) => s.name == "Language")[0];
        if (languagelists != undefined) {
            languagelists = languagelists.customizeValue.split(';');
            languagelists.forEach((element: any) => {
                var data = element.split('|');
                _languagelists.push({ Name: data[1], Code: data[0] });
            });
        } else {
            languagelists = [];
        }
        if (this.state.Check == false) {
            var defaultlanguage: any = this.props.Settings.filter((s: any) => s.name == "Default Language")[0];
            if (defaultlanguage != undefined) {
                defaultlanguage = defaultlanguage.customizeValue.split('|');
                this.setState({
                    Name: defaultlanguage[1],
                });
                
            } else {
                defaultlanguage = "";
            }
        }
        var langname: any = localStorage.getItem("DefaultLangCode") == "en-us" ? "English" : "Spanish";
        this.setState({
            Name: langname,
            Code: "",
            Check: true
        });
        return (
            <React.Fragment >
                    {
                    _languagelists.length <= 1 ? "" :
                        <li  className="nav-item dropdown has-arrow logged-item ">
                            <a style={{ lineHeight: '40px' }} href="#" className={_languagelists.length == 1 ? "nav-link active" : "dropdown-toggle nav-link"} data-toggle="dropdown">
                                { langname == "English" ? "EN" : "ES"}
                                </a>
                                {
                                    _languagelists.length == 1 ? "" :
                                        <div className="dropdown-menu dropdown-menu-right">
                                            {
                                            _languagelists.map((languagelist: any) =>
                                                <a className={this.state.Name == languagelist.Name ? "dropdown-item active" : "dropdown-item"} onClick={() => this.changeLanguage(languagelist.Name)}>{languagelist.Name == "English" ? "EN-English" : "ES-Español"}</a>
                                                )
                                            }
                                        </div>
                                }

                            </li>
                    }
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators }) // Selects which action creators are merged into the component's props
)(LanguageList as any);

