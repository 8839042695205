import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'reactstrap';
import HeaderDrProfile from '../widget/HeaderDrProfile';
import * as DoctorProfileStore from '../../store/doctor/DoctorProfile';
import { ApplicationState } from '../../store';
import { RouteComponentProps } from 'react-router';
import ContentLoader, { Code } from 'react-content-loader';
import { groupArrayOfObjects, seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import Moment from 'react-moment';
import { setTittle, checkFamilyMember } from '../../helper/formvalidation';
var languagecontent: any = "";
var langdata: any = "";
type DoctorProfileProps =
    DoctorProfileStore.DoctorBioState & DoctorProfileStore.DoctorTimingState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
    & typeof DoctorProfileStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>; // ... plus action creators we've requested

class DoctorProfile extends React.PureComponent<DoctorProfileProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
        checkFamilyMember();

    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorProfile()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.requestDoctorProfile(this.props.match.params);
        //this.props.requestDoctorTiming(this.props.match.params);       
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }
    public renderDoctorProfile() {
        languagecontent = this.props.LanguageResoures;
        const Timeslots = this.props.doctorTimings;
        let groupTimeslots = groupArrayOfObjects(Timeslots, 'day');
       
        function compare(a: any, b: any) {
            var te1 = a.time.split("-");
            var te2 = b.time.split("-");
            let order = new Date('1970/01/01 ' + te1[0]).getTime() - new Date('1970/01/01 ' + te2[0]).getTime();
            return order;
        }
        if ((this.props.doctorTimings == undefined)){
            this.props.requestDoctorTiming(this.props.match.params);

        }
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container">
                        <HeaderDrProfile />
                        <div className="card mb-0">
                            <div className="card-body pt-0">
                                {this.props.isLoading == true ?

                                    window.innerWidth <= 760 ?
                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
                                            <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                                            <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                                            <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100" >
                                            <rect x="0" y="13" rx="4" ry="4" width="190" height="9" />
                                            <rect x="210" y="13" rx="4" ry="4" width="190" height="9" />
                                            <rect x="0" y="29" rx="4" ry="4" width="100" height="8" />
                                            <rect x="0" y="40" rx="4" ry="4" width="400" height="9" />
                                            <rect x="0" y="50" rx="4" ry="4" width="400" height="9" />
                                        </ContentLoader>

                                    :
                                    <div>
                                    <nav className="user-tabs mb-4">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#doc_overview" data-toggle="tab">{languagecontent['overview']}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="#doc_business_hours" data-toggle="tab">{languagecontent['bussinesshours']}</a>
                                            </li>
                                        </ul>
                                    </nav>
                                      <div className="tab-content pt-0">
                                        <div role="tabpanel" id="doc_overview" className="tab-pane fade show active">
                                            <div className="row">

                                                <div className="col-md-12 col-lg-12">
                                                    {
                                                        this.props.doctorBio.bioData ?
                                                            <div className="widget about-widget">
                                                                <h4 className="widget-title">About Me</h4>
                                                                <div dangerouslySetInnerHTML={{ __html: this.props.doctorBio.bioData }} />


                                                            </div> :
                                                            <div>
                                                                <div className="p-4">{languagecontent['norecordsfound']}</div>
                                                            </div>

                                                    }
                                                </div>


                                            </div>
                                        </div>
                                        <div role="tabpanel" id="doc_business_hours" className="tab-pane fade">
                                            <div className="row">
                                                {this.props.doctorTimings == undefined || this.props.doctorTimings.length == 0 ?
                                                    <div>
                                                        <div className="p-4">{languagecontent['norecordsfound']}</div>
                                                    </div> :
                                                    <div className="col-md-6 offset-md-3">
                                                        <div className="widget business-widget">
                                                            <div className="widget-content">
                                                                <div className="listing-hours">
                                                                    <div>{languagecontent['providertimezone']} {this.props.timeZone}</div>
                                                                    <div className="listing-day current">

                                                                        <div className="day">{languagecontent['currentweek']}</div>
                                                                        <div className="time-items">
                                                                            <span className="time"><span className="time"><span className="badge bg-success-light ng-binding">{languagecontent['open']}</span> - <span className="badge bg-danger-light ng-binding">{languagecontent['close']}</span></span></span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        Object.keys(groupTimeslots).map((item, index) =>
                                                                            <div className="listing-day">
                                                                                <div className="day"> {languagecontent[item.toLowerCase()]}</div>
                                                                                {
                                                                                    groupTimeslots[item].length ?
                                                                                        <div className="time-items">
                                                                                            {
                                                                                                groupTimeslots[item].sort(compare).map((day: any, val: any) =>
                                                                                                    <span className="time">{day['time']}</span>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                        :
                                                                                        <div> NO Slots </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        </div>
                                        </div>
                                }
                                
                                  
                                
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.drBioData, ...state.drDoctorTimings, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...DoctorProfileStore.actionCreators, ...LanguageResourcesStore.actionCreators })	// Selects which action creators are merged into the component's props
)(DoctorProfile as any);

