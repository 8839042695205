import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { seo } from '../../../helper/Script';
import { ApplicationState } from '../../../store/index';
import * as NavDefaultStore from '../../../store/menu/NavDefault';
type Props = NavDefaultStore.NavDefaultState & typeof NavDefaultStore.actionCreators & RouteComponentProps;
class DocumentViewClass extends React.PureComponent<Props> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        seo({
            title: 'Dcoument View',
            pageTitle: '',
            description: ''
        })
    }
    componentDidUpdate() {
        seo({
            title: 'Dcoument View',
            pageTitle: '',
            description: ''
        })
    }

    public render() {
        return (
            <React.Fragment>
                {this.DocumentView()}
            </React.Fragment>
        );
    }
    bookNow = () => {
        this.props.goNextPage()
    }
    public DocumentView() {
        const urlParams = new URLSearchParams(window.location.search);
        const filePath: any = urlParams.get('url');
        const contenttype: any = urlParams.get('contenttype');
        return (
            <div>
                <div className="content">
                    <div className="container">
                        {/*<div className="row">*/}
                        {/*    <div className="col-sm-12">*/}
                        {/*        <iframe frameBorder="0" width="100%" height="auto" marginWidth={0} scrolling="no"*/}
                        {/*            sandbox="allow-same-origin allow-scripts allow-forms"*/}
                        {/*            title="PortletIFrameWidget"*/}
                        {/*            src={"patient/FileUrl?filepath=" + filePath + "&contenttype=" + contenttype}*/}
                        {/*            ref={(f:any) => {}}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

        )
    }
}
export default connect(
    (state: ApplicationState) => state.navDefault, // Selects which state properties are merged into the component's props
    NavDefaultStore.actionCreators // Selects which action creators are merged into the component's props
)(DocumentViewClass as any);

