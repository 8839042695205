import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { required, setTittle, scrollTop } from '../../helper/formvalidation';
import { renderField, renderSelectField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as HandleRequest from '../../helper/Request';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as NewMessageStore from '../../store/message/addMessage';
import * as AccountStore from '../../store/siteaccount/Account';
import { floatingJS, mobnav, seo } from '../../helper/Script';
import { TextBoxLoader } from '../../helper/fbloader';
import { toastr } from 'react-redux-toastr';
type AddMessageProps =
    NewMessageStore.AddMessageState & NewMessageStore.ReciptentListState & LanguageResourcesStore.LanguageResouresState & HandleRequest.Request   & AccountStore.AccountState &
    typeof NewMessageStore.actionCreators & typeof LanguageResourcesStore.actionCreators  & typeof AccountStore.actionCreators&
    RouteComponentProps<{}>;
var languagecontent: any = "";
var isGroupChatEnabled: any = '';
class AddMessage extends React.PureComponent<AddMessageProps> {
    constructor(props: any) {
        super(props);
    }
    onSubmit = (formValues: any, dispatch: any, props: any) => {
        
        if (formValues.Subject.trimStart() == "") {
            toastr.error('', languagecontent['entersubject']);
        } else if (formValues.Message.trimStart() == "")
        {
            toastr.error('', languagecontent['entermessage']);
        }
         else {
            this.props.postMessage(formValues)
        }
        
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderAddMessage()}
            </React.Fragment>
        );
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }

    public componentDidMount() {
        floatingJS()
        mobnav()
        isGroupChatEnabled = ''
        this.ensureDataFetched();
        scrollTop();

    }
    //public componentDidMount() {
    //  /*  this.ensureDataFetched();*/
       

    //}
    private ensureDataFetched() {
        this.props.getLangResources()
        this.props.getSiteSetting(null)
        this.props.getReciptentList()
      
          
    }

    public renderAddMessage() {
        languagecontent = this.props.LanguageResoures;
        const reciptent = this.props.reciptentLists        
       const site_setting = this.props.Settings.filter((se: any) => se.name == "Message Group Chat")[0];
     
        if (site_setting != undefined) {       
                isGroupChatEnabled = site_setting.customizeValue;                     
        }

        return (
            <React.Fragment>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <DashboardNavMenu />
                            <div className="col-md-7 col-lg-8 col-xl-9">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6">

                                                    {
                                                  
                                                        languagecontent['groupchat'] == undefined ? "" :
                                                            isGroupChatEnabled == 'Yes' && this.props.isLoading == false ?
                                                            <div className="card">
                                                                <div className="card-body p-16">
                                                                    <ul className="mb-0 chg_pwd_instruction">
                                                                    <li>{languagecontent['groupchat']}</li>
                                                                  
                                                                    </ul>
                                                                </div>
                                                                </div> : ""
                                                    }
                                                    {this.props.isLoading == true ? <TextBoxLoader /> : ""}
                                                   
                                                    {
                                                        languagecontent['recipient'] == undefined ? "" :
                                                        isGroupChatEnabled == 'No' && this.props.isLoading == false ?                                                           
                                                                   <div className={this.props.reciptentLists.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                                                <Field name="reciptent" options={reciptent} select={languagecontent['select']}  component={renderSelectField} validate={[required]} className="form-control" />
                                                                    <label className="focus-label" style={{ background: "none" }}>{languagecontent['recipient']}</label>
                                                                </div> : ""
                                                    }
                                                    <Field name="Subject" component={renderField} label={languagecontent['subject']} maxLength={100} validate={[required]} type="text" />
                                                    <Field name="Message" component={renderField} label={languagecontent['message']} maxLength={1500} validate={[required]} type="text" />
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="submit-section">
                                                        <button type="submit" disabled={this.props.isCPLoading || this.props.isLoading } className="btn btn-primary submit-btn"> {this.props.isCPLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {languagecontent['sendmessage'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['sendmessage']}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = { ...NewMessageStore.actionCreators, ...LanguageResourcesStore.actionCreators,  ...AccountStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => ({ ...state.addMessage, ...state.langResources, ...state.reciptentList, ...state.account })
export default reduxForm({
    form: 'addMessageForm',
})(connect(mapStateToProps, mapDispatchToProps)(AddMessage as any))

