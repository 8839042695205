import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { actionCreators as _navAction } from '../../store/menu/NavDefault';

export interface DashboardNavState {
    isLoading: boolean;
}

interface RequestDashboardNav {
    type: 'REQUEST_DASHBOARD_NAV';
}

interface ReceiveDashboardNav {
    type: 'RECEIVE_DASHBOARD_NAV';
}

type KnownAction = RequestDashboardNav | ReceiveDashboardNav;

export const actionCreators = {
    requestLogout: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        dispatch(showLoading('sectionBar'))
        const appState = getState();
        if (appState && appState.navDefault) {
            dispatch(_navAction.requestLogout());
        }
        dispatch(hideLoading('sectionBar'))
    }
};


const unloadedState: DashboardNavState = {
    isLoading: false
};

export const reducer: Reducer<DashboardNavState> = (state: DashboardNavState | undefined, incomingAction: Action): DashboardNavState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DASHBOARD_NAV':
            return {
                isLoading: true
            };
        case 'RECEIVE_DASHBOARD_NAV':
            return {
                isLoading: true
            };
        default:
            return state;
    }
};
