import * as $ from 'jquery';
import { isNullOrUndefined } from 'util';
import Moment from 'moment';
//import * as SiteDetailStore from '../../../store/SiteDetail';
//import { reset } from 'redux-form';

// Floating Label
export const datepickerJS = () => {
        if ($('.floating').length > 0) {
            $('.floating').on('focus blur', function (e) {
                $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
                if (e.currentTarget.options != undefined) {
                    $(this).parents('.form-focus').addClass('focused', (e.type === 'focus' || this.value.length > 0));
                }
            });
        }
}
export const floatingJS = () => {
    setTimeout(function () {
        if ($('.floating').length > 0) {
            $('.floating').on('focus blur', function (e) {
                $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
                if (e.currentTarget.options != undefined) {
                    $(this).parents('.form-focus').addClass('focused', (e.type === 'focus' || this.value.length > 0));
                }
            }).trigger('blur');
        }
    });
    
    
}

export const floatJS = () => {
        if ($('.floating').length > 0) {
            $('.floating').on('blur keyup keydown focus', function (e) {
                $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
                if (e.currentTarget.options != undefined) {
                    $(this).parents('.form-focus').addClass('focused', (e.type === 'focus' || this.value.length > 0));
                }
            }).trigger('keydown');
        }
}

export const  formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes == 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
}



export const groupArrayOfObjects = (list, key) => {
    if (list != undefined) {
        return list.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }
};

/*export const mobnav = () => {
    $(document).on('click', '.dropdown-item', function () {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('main-wrapper').removeClass('slide-nav');
    });   
}*/
export const mobnav = () => {
    $(document).on('click', '.dropdown-item', function () {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('main-wrapper').removeClass('slide-nav');
    });
}
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export const focus = () => {
    $('.typefield').addClass('focused');
}
export const datePicker = () => {

    var maxBirthdayDate = new Date();
    maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
   
}
export const dobcheck = (date,dobchk) => {
    
    var maxBirthdayDate = new Date();
    maxBirthdayDate.setDate(maxBirthdayDate.getDate() + 1);
    maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear()  - 18);
    function convert(str) {
       /* var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");*/
        return Moment(new Date(str)).format("D MMM YYYY");
    }
   // convert(maxBirthdayDate);
    if (dobchk = "checked") {
        $('.dob').val(date);
    }
   else if ($('.dob').val() == convert(maxBirthdayDate)) {
        
        if (date == "Invalid date") {
            $('.dob').val("");
        }
        else {
            $('.dob').val(date);
           
        }
    } else if (date == null) {
        $('.dob').val("");
    } else if (date == "") {
        $('.dob').val("");
    } 
    else if (date == "undefined") {
        $('.dob').val("");
    } else {
        $('.dob').val(date);
        
    }
}

export const extdobcheck = (date, dobchk) => {

    var maxBirthdayDate = new Date();
    maxBirthdayDate.setDate(maxBirthdayDate.getDate() + 1);
    maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
    function convert(str) {
        /* var date = new Date(str),
             mnth = ("0" + (date.getMonth() + 1)).slice(-2),
             day = ("0" + date.getDate()).slice(-2);
         return [mnth, day, date.getFullYear()].join("/");*/
        return Moment(new Date(str)).format("D MMM YYYY");
    }
    // convert(maxBirthdayDate);
    if (dobchk == "checked") {
        return $('.dob').val(date);
    }
    else if ($('.dob').val() == convert(maxBirthdayDate)) {

        if (date == "Invalid date") {
            return  $('.dob').val("");
        }
        else {
            return $('.dob').val(date);

        }
    } else if (date == null) {
        return $('.dob').val("");
    } else if (date == "") {
        return $('.dob').val("");
    }
    else if (date == "undefined") {
        return $('.dob').val(Moment(new Date(convert(maxBirthdayDate))).format("D MMM YYYY"));
    } else if (date == "Invalid date") {
        return $('.dob').val("");
    } else {
        return $('.dob').val(date);
    }
}

export const ModalClose = () => {
    $("body").keyup(function (event) {
        if (event.keyCode == 27 ) {
            //document.getElementById("document_upload").reset();
           document.getElementById("document_uploadNote").value = "";
            document.getElementById("document_uploadNote").innerHTML = "";
            //$('#document_uploadNote').val('');
            
        }
    });
}
export function displayControl(coll, color) {

    for (var i = 0, len = coll.length; i < len; i++) {
        coll[i].style["display"] = color;
    }
}
export function seo(data = {}) {
    
    if (window.name != "") {
        data.title = window.name + "-" + data.title || localStorage.getItem("siteName");
    }
    else {
        data.title = data.title || window.name;
    }
    
    data.metaDescription = data.metaDescription || 'Default description';

    window.document.title = data.title;
    if (data.pageTitle == "" ) {
      
        document.getElementById('breadcrumb-bar').style.display = "none";
    }
    else {
        document.getElementById('breadcrumb-bar').style.display = "block";
    }
   /* if (data.description)
        document.getElementById('description').innerHTML = data.description;*/
    if (data.pageTitle)
        document.getElementById('breadcrumb-title').innerHTML = data.pageTitle;
    
   // document.querySelector('#breadcrumb-title').innerHTML = data.pageTitle;
    //document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
}

export const handleReset = () => {
    var note = document.querySelector("#document_uploadNote");
    note.value = "";
    $('#dSeleted').prop('selectedIndex', -1);
    document.getElementById("dSeleted").value = "";  
}
export function useQuery() {
    return new URLSearchParams(window.location.search);
}

export const floatJSBack = () => {

    if ($('.floating').length > 0) {
        setTimeout(function () {
            $('.floating').on('focus blur', function (e) {
                
               
                $(this).parents('.form-focus').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
                if (e.currentTarget.options != undefined) {
                    $(this).parents('.form-focus').addClass('focused', (e.type === 'focus' || this.value.length > 0));
                }
            }).trigger('blur');
        });
    }
    else {
        setTimeout(function () {
        $('.floating').on('focus blur', function (e) {
          
                $(this).parents('.form-focus').addClass('focused', (e.type === 'focus' || this.value.length > 0));
            
        }).trigger('blur');
        });
    }
}

export const  loadChat = () => {
    var fc_JS = document.createElement('script');
    fc_JS.type = 'text/javascript';
    fc_JS.src = 'https://wchat.freshchat.com/js/widget.js?t=' + Date.now();
    (document.body ? document.body : document.getElementsByTagName('head')[0]).appendChild(fc_JS);
    window.fcSettings = { token: '30eab18b-748b-49cb-acb0-910fb4fba771', host: 'https://wchat.freshchat.com' };
}

export const menuFunction = () => {
    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function () {
        $('main-wrapper').toggleClass('slide-nav');
        $('.sidebar-overlay').toggleClass('opened');
        $('html').addClass('menu-opened');
        return false;
    });

    //$(document).on('click', '.sidebar-overlay', function () {
    //    $('html').removeClass('menu-opened');
    //    $(this).removeClass('opened');
    //    $('main-wrapper').removeClass('slide-nav');
    //});

    //$(document).on('click', '.sidebar-overlay', function () {
    //    $('html').removeClass('menu-opened');
    //    $(this).removeClass('opened');
    //    $('main-wrapper').removeClass('slide-nav');
    //});
    $(document).on('click', '.sidebar-overlay', function () {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
    });

    $(document).on('click', '#menu_close', function () {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('main-wrapper').removeClass('slide-nav');
    });

    $(document).on('click', '.dropdown-item', function () {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('main-wrapper').removeClass('slide-nav');
    });
}

export function ltrim(str) {
    if (!str) return str;
    //return str.replace(/^\s+/g, '');
    return str.replace(/^\s+|\s+$/g, '');
}