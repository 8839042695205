import * as React from 'react';
import { seo } from '../../helper/Script';

export default class Comingsoon extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public componentDidMount() {
        //seo({
        //    title: 'Coming Soon',
        //    pageTitle: 'Coming Soon',
        //    description: ''
        //});
        this.ensureDataFetched();
    }
    public componentDidUpdate() {
        seo({
            title: 'Coming Soon',
            pageTitle: 'Coming Soon',
            description: ''
        });
       
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderComingSoon()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {

    }
    public renderComingSoon() {
        return (
            <React.Fragment>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="main-wrapper">
                                            <div className="error-box">
                                                <h3 className="h2 mb-3"><i className="fa fa-warning"></i>Coming Soon!</h3>
                                                <a href="/" className="btn btn-lg btn-primary">Back to Home</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
