import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { getParameterByName } from '../../helper/formvalidation';
import moment from 'moment';

export interface SignUpState {
    isSignUpLoading: boolean;
    startDateIndex?: number;
    signup: SignUp;
    signin: SignIn;
}

export interface SignUp {
    MemberId: string;
    FirstName: string;
    LastName: string;
    Email: string;
    Password: string;
    ConfirmPassword: string;
    Checkuser: boolean;
    UserName: string;
}

export interface SignIn {
    Id: string;
    FacilityId: string;
    PatientId: string;
    EncounterTypeId: string;
    Reason: string;
    EncounterOn: string;
    StatusId: string;
    ProviderId: string;
    FromDate: string;
    ToDate: string;
    CallType: string;
    CallRefId: string;
    Device: string;
    AccountId: string;
    PatientName: string;
}



interface RequestSignUp {
    type: 'REQUEST_ADD_USER';
    signup: SignUp;
    signin: SignIn;
}

interface ReceiveSignUp {
    type: 'RECEIVE_ADD_USER';
    signup: SignUp;
    signin: SignIn;
}


type KnownAction = RequestSignUp | ReceiveSignUp;

export const actionCreators = {
    requestSignUpSave: (userObj: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        dispatch(showLoading('sectionBar'))
        if (appState && appState.signup) {
            axios.post('account/signup', userObj)
                .then(function (response) {
                    dispatch(hideLoading('sectionBar'))
                    const data = response.data;
                    localStorage.setItem("InvitationCode",data.invitiationCode)
                    dispatch({ type: 'RECEIVE_ADD_USER', signup: data });
                    dispatch(reset("signUpForm"));
                    var memberid = data.memberId;
                    dispatch(actionCreators.getUserSignIn())
                    //dispatch(actionCreators.EncounterPost())
                    dispatch(actionCreators.getFormModule(memberid))
                    //actionCreators.getUser(memberid, getState);
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.ModelState != undefined) {
                            toastr.error('', errorObject.ModelState[""][0]);
                        } else if (errorObject.Message == "Invalid Invitation Code") {
                            var langdata: any;
                            if (localStorage.getItem("LangResourcesValidations") != null)
                                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

                            toastr.error('', langdata['invalidinvitationcode']);

                        } else if (errorObject.Message == "Email already exists") {
                            var langdata1: any;
                            if (localStorage.getItem("LangResourcesValidations") != null)
                                langdata1 = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            toastr.error('', langdata1['emailalreadyexists']);

                        }
                        else {
                            toastr.error('', errorObject.Message);
                        }

                    }
                    else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ type: 'RECEIVE_ADD_USER', isSignUpLoading: false });
                    dispatch({ ...hideLoading('sectionBar') })
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_ADD_USER' });
        }
    },
    getUser(memberid: any, getState: any) {
        axios.get('/patient/getpatient')
            .then(function (response) {
                const result = response.data;
                const appState = getState();
                var enablefamilymember: any = appState.account.Settings.filter((se: any) => se.name == "Enable Family Member")[0];
                var doctorappointment: any = appState.account.Settings.filter((se: any) => se.name == "Doctor Appointment")[0];
                var requestqueue: any = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
                var anonymous: any = appState.account.Settings.filter((se: any) => se.name == "Anonymous")[0];

                localStorage.setItem("isAuth", "true");
                localStorage.setItem("Patient", JSON.stringify({ id: result.id, memberId: result.memberId, userId: result.userId, Name: result.firstName + " " + result.lastName, FirstName: result.firstName, LastName: result.lastName, Email: result.email, Address1: result.address1, Address2: result.address2, locationName: result.locationName, zipcode: result.zipcode, locationId: result.locationId, dob: result.dob, cellPhone: result.cellPhone, gender: result.cellPhone, timeZone: result.timeZone, offset: result.offset }));
                var url = localStorage.getItem("redirectUrl");
                if (url == null)
                    url = "";
                else
                    url = localStorage.getItem("redirectUrl");

                var newArray: any = "";
                if (appState != undefined) {
                    newArray = appState.account.Settings.filter(function (el: any) {
                        return (el.name == "Intake Forms");
                    });
                }
                var timezonetimesloturl = localStorage.getItem("TimeZoneTimeSlot");
                if (timezonetimesloturl != null) {
                    return window.location.href = "/profilesettings/" + patient.memberId;;
                }
                if (newArray[0].name == "Intake Forms" && newArray[0].customizeValue == "Yes") {
                    var formArray: any = appState.flexiform.formLists.filter(function (el: any) {
                        return ((el.id == null) && (el.showOnce == true));
                    });
                    if (formArray != 0) {
                        if (localStorage.getItem("pathName") == "conditions") {
                            if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                var doctorid = localStorage.getItem("DoctorId");
                                var _url: any = "/contact/" + doctorid + "/" + result.memberId;
                                localStorage.setItem("contactUrl", _url);
                                return window.location.href = "/initialpageforms/" + formArray[0].moduleName + "/" + result.id + "/" + result.id + "/" + null;
                            }
                        } else {
                            return window.location.href = "/initialpageforms/" + formArray[0].moduleName + "/" + result.id + "/" + result.id + "/" + null;
                        }
                    }
                }

                if (localStorage.getItem("pathName") == "conditions") {
                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                        var doctorid = localStorage.getItem("DoctorId");
                        if (localStorage.getItem("Email") != result.email) {
                            if (newArray[0].name == "Intake Forms" && newArray[0].customizeValue == "Yes") {
                                var formArray: any = appState.flexiform.formLists.filter(function (el: any) {
                                    return ((el.id == null) && (el.showOnce == true));
                                });
                                if (formArray != 0) {
                                    var _url: any = "/contact/" + doctorid + "/" + result.memberId;
                                    var time: any = ""
                                    var urltime: any = getParameterByName("time", url);
                                    var accountoffset: any = localStorage.getItem("timeOffset");
                                    if (url != null) {
                                        var urldate = getParameterByName("date", url);
                                        urltime = getParameterByName("time", url);
                                        if (result.offset == parseInt(accountoffset)) {
                                            document.location.href = url + "&patientId=" + result.id;
                                        } else {
                                            var datetime: any = new Date(urldate + " " + urltime);
                                            if (Math.sign(parseInt(accountoffset)) == -1)
                                                accountoffset = Math.abs(parseInt(accountoffset));
                                            else
                                                accountoffset = -Math.abs(parseInt(accountoffset))

                                            var CSToffSet = accountoffset; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
                                            var offset = CSToffSet * 60 * 1000;
                                            var CSTTime = new Date(datetime.getTime() + offset);
                                            CSTTime.setTime(CSTTime.getTime() + (result.offset * 60000));
                                            var date = Moment(CSTTime).format('YYYY-MM-DD');
                                            var setdateurl = updateQueryStringParameter(url, "date", date) + "&patientId=" + result.id;
                                            time = Moment(CSTTime).format('hh:mm A');
                                            document.location.href = updateQueryStringParameter(setdateurl, "time", time);
                                            localStorage.setItem("AppointmentTime", time);
                                        }
                                    }
                                    localStorage.setItem("contactUrl", _url);
                                    return window.location.href = "/initialpageforms/" + formArray[0].moduleName + "/" + result.id + "/" + result.id + "/" + null;
                                }
                                else {
                                    var _url: any = "/contact/" + doctorid + "/" + result.memberId;
                                    var time: any = ""
                                    var urltime: any = getParameterByName("time", url);
                                    var accountoffset: any = localStorage.getItem("timeOffset");
                                    if (url != null) {
                                        var urldate = getParameterByName("date", url);
                                        urltime = getParameterByName("time", url);
                                        if (result.offset == parseInt(accountoffset)) {
                                            document.location.href = url + "&patientId=" + result.id;
                                        } else {
                                            var datetime: any = new Date(urldate + " " + urltime);
                                            if (Math.sign(parseInt(accountoffset)) == -1)
                                                accountoffset = Math.abs(parseInt(accountoffset));
                                            else
                                                accountoffset = -Math.abs(parseInt(accountoffset))

                                            var CSToffSet = accountoffset; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
                                            var offset = CSToffSet * 60 * 1000;
                                            var CSTTime = new Date(datetime.getTime() + offset);
                                            CSTTime.setTime(CSTTime.getTime() + (result.offset * 60000));
                                            var date = Moment(CSTTime).format('YYYY-MM-DD');
                                            var setdateurl = updateQueryStringParameter(url, "date", date) + "&patientId=" + result.id;
                                            time = Moment(CSTTime).format('hh:mm A');
                                            document.location.href = updateQueryStringParameter(setdateurl, "time", time);
                                            localStorage.setItem("AppointmentTime", time);
                                        }
                                    }
                                    localStorage.setItem("contactUrl", _url);
                                    window.location.href = "/contact/" + doctorid + "/" + result.memberId;
                                }
                            }
                            else {
                                var _url: any = "/contact/" + doctorid + "/" + result.memberId;
                                var time: any = ""
                                var urltime: any = getParameterByName("time", url);
                                var accountoffset: any = localStorage.getItem("timeOffset");
                                if (url != null) {
                                    var urldate = getParameterByName("date", url);
                                     urltime = getParameterByName("time", url);
                                    if (result.offset == parseInt(accountoffset)) {
                                        document.location.href = url + "&patientId=" + result.id;
                                    } else {
                                        var datetime: any = new Date(urldate + " " + urltime);
                                        if (Math.sign(parseInt(accountoffset)) == -1)
                                            accountoffset = Math.abs(parseInt(accountoffset));
                                        else
                                            accountoffset = -Math.abs(parseInt(accountoffset))

                                        var CSToffSet = accountoffset; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
                                        var offset = CSToffSet * 60 * 1000;
                                        var CSTTime = new Date(datetime.getTime() + offset);
                                        CSTTime.setTime(CSTTime.getTime() + (result.offset * 60000));
                                        var date = Moment(CSTTime).format('YYYY-MM-DD');
                                        var setdateurl = updateQueryStringParameter(url, "date", date) + "&patientId=" + result.id;
                                         time = Moment(CSTTime).format('hh:mm A');
                                        document.location.href = updateQueryStringParameter(setdateurl, "time", time);
                                        localStorage.setItem("AppointmentTime", time);
                                    }
                                }
                                localStorage.setItem("contactUrl", _url); 
                                window.location.href = "/contact/" + doctorid + "/" + result.memberId;
                            }
                        }
                        else if (localStorage.getItem("pathName") == "conditions") {
                            var accountoffset: any = localStorage.getItem("timeOffset");
                            if (url != null) {
                                var urldate = getParameterByName("date", url);
                                 urltime = getParameterByName("time", url);
                                if (result.offset == parseInt(accountoffset)) {
                                    document.location.href = url + "&patientId=" + result.id;
                                } else {
                                    var datetime: any = new Date(urldate + " " + urltime);
                                    if (Math.sign(parseInt(accountoffset)) == -1)
                                        accountoffset = Math.abs(parseInt(accountoffset));
                                    else
                                        accountoffset = -Math.abs(parseInt(accountoffset))

                                    var CSToffSet = accountoffset; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
                                    var offset = CSToffSet * 60 * 1000;
                                    var CSTTime = new Date(datetime.getTime() + offset);
                                    CSTTime.setTime(CSTTime.getTime() + (result.offset * 60000));
                                    var date = Moment(CSTTime).format('YYYY-MM-DD');
                                    var setdateurl = updateQueryStringParameter(url, "date", date) + "&patientId=" + result.id;
                                    var time:any = Moment(CSTTime).format('hh:mm A');
                                    document.location.href = updateQueryStringParameter(setdateurl, "time", time);
                                }
                            }
                        }
                        else {
                            if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                                window.location.href = "/dashboard";
                            }                           
                            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                                window.location.href = "/consultationrequest?memberid=" + memberid;
                            }
                            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                                var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                if (queueurl == null) {
                                    window.location.href = "/consultationrequest?memberid=" + result.memberId;
                                } else {
                                    if (localStorage.getItem("pathName") == "RequestQueue") {
                                        if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                            window.location.href = "/contact/" + null + "/" + result.memberId;
                                        } else {
                                            var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                            window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                        }
                                    }
                                }
                            } else if ((enablefamilymember.customizeValue == "Yes") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                                var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                                if (queueurl == null) {
                                    window.location.href = "/consultationrequest?memberid=" + result.memberId;
                                } else {
                                    if (localStorage.getItem("pathName") == "RequestQueue") {
                                        if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                            window.location.href = "/contact/" + null + "/" + result.memberId;
                                        } else {
                                            var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment");
                                            window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                        }
                                    }
                                }
                            }
                            else if (enablefamilymember.customizeValue == "Yes") {
                                window.location.href = "/consultationrequest?memberid=" + memberid;
                            }
                            else {
                                window.location.href = "/dashboard";
                            }
                        }
                    } else {
                        if (url != null)
                            window.location.href = url;
                    }
                }
                else if (localStorage.getItem("pathName") == "RequestQueue") {
                    if (localStorage.getItem("speaktovet") == "true") {
                        localStorage.removeItem("speaktovet");
                        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                        window.location.href = queueurl + result.id;
                    }
                    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                        window.location.href = "/dashboard";
                    }                   

                    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                        window.location.href = "/consultationrequest?memberid=" + memberid;
                    }
                    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                        if (queueurl == null) {
                            window.location.href = "/consultationrequest?memberid=" + result.memberId;
                        } else {
                            if (localStorage.getItem("pathName") == "RequestQueue") {
                                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                    window.location.href = "/contact/" + null + "/" + result.memberId;
                                } else {
                                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                    window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                }
                            }
                        }
                    }
                    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                        if (queueurl == null) {
                            window.location.href = "/consultationrequest?memberid=" + result.memberId;
                        } else {
                            if (localStorage.getItem("pathName") == "RequestQueue") {
                                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                    window.location.href = "/contact/" + null + "/" + result.memberId;
                                } else {
                                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                    window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                }
                            }
                        }
                    }
                    else if ((enablefamilymember.customizeValue == "Yes") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                        if (queueurl == null) {
                            window.location.href = "/consultationrequest?memberid=" + result.memberId;
                        } else {
                            if (localStorage.getItem("pathName") == "RequestQueue") {
                                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                    window.location.href = "/contact/" + null + "/" + result.memberId;
                                } else {
                                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                    window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + patient.id + "&serviceid=&type=noappointment";
                                }
                            }
                        }
                    }
                    else if (enablefamilymember.customizeValue == "Yes") {
                        window.location.href = "/consultationrequest?memberid=" + memberid;
                    }
                    else {
                        window.location.href = "/dashboard";
                    }
                }
                else {
                    if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                        window.location.href = "/dashboard";
                    }                   
                    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                        window.location.href = "/consultationrequest?memberid=" + memberid;
                    }
                    else if (enablefamilymember.customizeValue == "Yes") {
                        window.location.href = "/consultationrequest?memberid=" + memberid;
                    }
                    else {
                        window.location.href = "/dashboard";
                    }
                }
                localStorage.removeItem("speaktovet");
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                
            })
            .then(function () {
                // always executed
            });
    },
    //EncounterPost: (data: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
    //    const appState = getState();
    //    /*if (appState && appState.signin) {*/
    //    var data: any = {
    //        FacilityId: data.FacilityId,
    //        PatientId: data.PatientId,
    //        FromDate: moment.utc(),
    //        EncounterOn: moment.utc(),
    //        StatusId: 2,
    //        ModifiedOn: moment.utc(),
    //        Reason: 'NA',
    //        AccountId: data.AccountId,
    //    }
    //    axios.post('account/EncounterPost', data)
    //        .then(function (response) {
    //            const data = response.data;
    //            //console.log(data)
    //            return;
    //            //dispatch({ type: 'RECEIVE_ADD_USER', signin: data });
    //        })
    //        .catch(function (error) {
    //            errorHandling(error.response.data.statusCode);
    //            dispatch(hideLoading('sectionBar'))
    //        })
    //        .then(function () {
    //            // always executed
    //        });

    //},
    getUserSignIn: (): AppThunkAction<KnownAction> =>  (dispatch: any, getState: any) => {
        //var accountid: any = localStorage.getItem("AccountId");
        const appState = getState();
        /*dispatch(actionCreators.EncounterPost())*/
        /*if (appState && appState.signin) {*/
            axios.get('account/GetUserSignIn')
                .then(function (response) {
                    const data = response.data;
                    /*dispatch({ signin: data });*/
                    /*dispatch(actionCreators.EncounterPost(data));*/
                    
                    //console.log(data)
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                    //console.log(error.response.data.statusCode)
                    dispatch(hideLoading('sectionBar'))
                })
            //.then(function () {
            //     always executed
            //});
        
    },
    

    
    getFormModule: (memberid: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var accountid: any = localStorage.getItem("AccountId");
        const appState = getState();
        axios.get('/patient/GetFormModule/' + accountid + "/" + "6")
            .then(function (response) {
                const result = response.data;
                appState.flexiform.formLists = result;
                actionCreators.getUser(memberid, getState);
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                dispatch(hideLoading('sectionBar'))
            })
            .then(function () {
                // always executed
            });
    },
};

const UnloadedState: SignUpState = {
    signup: {
        MemberId: "",
        FirstName: "",
        LastName: "",
        Email: "",
        Password: "",
        ConfirmPassword: "",
        Checkuser: true,
        UserName: "",
    },
    signin: {
        Id: "",
        FacilityId: "",
        PatientId: "",
        EncounterTypeId: "",
        Reason: "",
        EncounterOn: "",
        StatusId: "",
        ProviderId: "",
        FromDate: "",
        ToDate: "",
        CallType: "",
        CallRefId: "",
        Device: "",
        AccountId: "",
        PatientName: "",
},
    isSignUpLoading: false
};

export const reducer: Reducer<SignUpState> = (state: SignUpState | undefined, incomingAction: Action): SignUpState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ADD_USER':
            return {
                signup: state.signup,
                signin: state.signin,
                isSignUpLoading: true
            };
        case 'RECEIVE_ADD_USER':
            return {
                signup: action.signup,
                signin: action.signin,
                isSignUpLoading: false
            };
        default:
            return state;
    }
};
function updateQueryStringParameter(uri: any, key: any, value: any) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}