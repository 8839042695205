import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as  HeaderDrProfileStore from '../../store/widget/HeaderDrProfile';
import * as  BookingStore from '../../store/patient/Booking';
import * as ContactStoreState from '../../store/patient/Contact';
import * as AccountStore from '../../store/siteaccount/Account';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import ContentLoader from "react-content-loader"
import Moment from 'moment';
import { NavLink } from 'react-router-dom';
import { dateFormat, statusColor, dateUTCFormat, dateTimeFormat, dateConversion, timeFormat, timeConversion, checkFamilyMember, getParameterByName } from '../../helper/formvalidation';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { stat } from 'fs';
import { getDiscountPrice } from '../Patient/Booking';
import * as Account from '../../store/siteaccount/Account';


// At runtime, Redux will merge together...
type HeaderDrProfileProps =
    HeaderDrProfileStore.HeaderDrProfileState & BookingStore.BookingState & TimeZoneStore.TimeZoneState & ContactStoreState.ContactState & AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState & Account.AccountState // ... state we've requested from the Redux store
    & typeof HeaderDrProfileStore.actionCreators & typeof BookingStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof ContactStoreState.actionCreators & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps<{ startDateIndex: string }>; // ... plus action creators we've requested
var encDetailStroage: any = localStorage.getItem('EncDetail');
var encDetail: any = JSON.parse(encDetailStroage);
var __currency: any = [];
var typemode: any = "";
var languagecontent: any = "";
var offset: any = "";
var langdata: any = "";
var Currenttimezone: any = "";
var appointmenttime: any = "";
var consultationfree: any = [];
class HeaderDrProfile extends React.PureComponent<HeaderDrProfileProps> {
    // This method is called when the component is first added to the document
    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        this.ensureDataFetched();
        checkFamilyMember();
        var data = window.location.pathname;
        const _array = data.split('/');
       if( _array[1] == "booking")
        localStorage.removeItem("ServiceEncounterTypeId");

    }
   

    public render() {

        return (
            <React.Fragment>
                {this.renderHeaderDrProfile()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        var data = window.location.pathname;
        const _array = data.split('/');
        if (_array[2]) {
            this.props.requestHeaderDrProfile(_array[2]);
        }
        else {
            const queryParams = new URLSearchParams(window.location.search);

            const doctorid = queryParams.get('doctorid');
            this.props.requestHeaderDrProfile(doctorid);
        }


        
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");


    }

    navRoute = (value: string) => {
        //this.props.redirectBooking(value);
    }
    private renderHeaderDrProfile() {
        //console.log('childname')
        //console.log(this.props.time,"tttime")
        var preferredtimeslot: any = this.props.Settings.filter((s: any) => s.name == "Client preferred time slot");
        if (preferredtimeslot.length != 0)
            preferredtimeslot = preferredtimeslot[0].customizeValue;
        else
            preferredtimeslot = "";

        var pickaprovider: any = this.props.Settings.filter((s: any) => s.name == "Auto Provider Selection in PickaProvider");
        if (pickaprovider.length != 0)
            pickaprovider = pickaprovider[0].customizeValue;
        else
            pickaprovider = "";
        var urlParams = new URLSearchParams(window.location.search);
        //var urldate = getParameterByName("date", window.location.search);
        //var urltime = getParameterByName("time", window.location.search);
        //var urldate1 = getParameterByName("date1", window.location.search);
        //var urltime1 = getParameterByName("time1", window.location.search);
        //if (urldate != null) {
        //    localStorage.setItem("AppointmentOn", dateUTCFormat(urldate + " " + urltime));
        //    localStorage.setItem("AppointmentTime", timeFormat(urldate + " " + urltime));
        //}
        //if (urldate1 != null && urldate1 != "null" && urltime1 != null && urltime1 !="null") {
        //    localStorage.setItem("AppointmentOn1", dateUTCFormat(urldate1 + " " + urltime1));
        //    localStorage.setItem("AppointmentTime1", timeFormat(urldate1 + " " + urltime1));
        //}
        var childid = urlParams.get('childid');
        var name = urlParams.get('childname');
        var childname = urlParams.get('childname');
        //var statuses = urlParams.get('statuses')
        var status = urlParams.get('status');
        if (status == "reschedule") {
            name = urlParams.get("childname")
        }
        var specialityid: any = urlParams.get('specialityid');
        var methodid: any = urlParams.get('methodid');
        var enctypemode: any = urlParams.get('type');
        if (enctypemode == null)
            enctypemode = urlParams.get('encountertypemode');
        
        if (enctypemode == null && localStorage.getItem("followup") == "followup") {
            enctypemode= localStorage.getItem("enctypemode");
        }
        languagecontent = this.props.LanguageResoures;
        var data = window.location.pathname;
        const _array = data.split('/');
        var appointmenton: any = localStorage.getItem("AppointmentOn");
        if (preferredtimeslot == "Yes" && pickaprovider == "No") {
            var appointmenton1: any = "";
        if (localStorage.getItem("AppointmentOn1") != null) {
            appointmenton1 = localStorage.getItem("AppointmentOn1");
            var appointmenttime1: any = localStorage.getItem("AppointmentTime1");
        }
        else {
            if (localStorage.getItem("AppointmentTime1"))
            appointmenton1 = localStorage.getItem("AppointmentOn");
        }
        //else
        //    var appointmenton1: any = localStorage.getItem("AppointmentOn");
    }
        appointmenttime = "";
        var encDetailStroage: any = localStorage.getItem('EncDetail');
      var encDetail = JSON.parse(encDetailStroage);
        if (encDetail != null) {
            typemode = encDetail.encounterTypeMode;
        }
        if (status == "reschedule" || status == "followup" || localStorage.getItem("followup") == "followup" ) {
            var modeCode: any
            if (enctypemode == "Teleconsultation" || enctypemode == "Videoconsulta") {
                modeCode = "TEC"
                localStorage.setItem("modecode", modeCode)
            } else if (enctypemode == "In Person" || enctypemode == "En persona") {
                modeCode = "INP"
            }
            else {
                modeCode = "PHN";
            }
            localStorage.setItem("modecode", modeCode)
            //console.log(modeCode, encDetail)
            var appointmenttype: any = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == modeCode);
            if (appointmenttype.length != 0) {
                this.props.headerdrprofile.encounterTypeMode = appointmenttype[0].encounterTypeMode;
                this.props.headerdrprofile.minPrice = appointmenttype[0].price;
            }
            //    console.log(appointmenttype, this.props.headerdrprofile.doctorServiceDetails)
        }
        else {
            var appointmenttype: any = [];
            if (enctypemode == "noappointment") {
                appointmenttype = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == "TEC");
                if (appointmenttype.length != 0)
                    localStorage.setItem("EncDetail", JSON.stringify({ durationCode: appointmenttype[0].durationCode, encounterTypeMode: appointmenttype[0].encounterTypeMode, minPrice: appointmenttype[0].price, encounterTypeModeDurationId: appointmenttype[0].encounterTypeModeDurationId, serviceId: appointmenttype[0].serviceId, modeCode: appointmenttype[0].modeCode }))
            }
            else {
                if (encDetail != null) {
                    appointmenttype = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == encDetail.modeCode);
                }
            }
            if (methodid == "3") {
                appointmenttype = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == "TEC");
                if (appointmenttype.length != 0)
                    localStorage.setItem("EncDetail", JSON.stringify({ durationCode: appointmenttype[0].durationCode, encounterTypeMode: appointmenttype[0].encounterTypeMode, minPrice: appointmenttype[0].price, encounterTypeModeDurationId: appointmenttype[0].encounterTypeModeDurationId, serviceId: appointmenttype[0].serviceId, modeCode: appointmenttype[0].modeCode }))
            }
            else {
                if (encDetail != null) {
                    appointmenttype = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == encDetail.modeCode);
                }
            }
            if (appointmenttype.length != 0) {
                this.props.headerdrprofile.encounterTypeMode = appointmenttype[0].encounterTypeMode;
                this.props.headerdrprofile.minPrice = appointmenttype[0].price;
            }
        }


        if (name == undefined || name == "null") {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patient = JSON.parse(patientLocalStroage);
            if (patient != null)
                name = patient.FirstName + ' ' + patient.LastName;
        }
        var enablefamilymember: any = [];
        enablefamilymember = this.props.Settings.filter((s: any) => s.name == "Enable Family Member");
        var familyenable = "";
        if (enablefamilymember.length == 0)
            familyenable = "";
        else
            familyenable = enablefamilymember[0].customizeValue

        consultationfree = this.props.Settings.filter((s: any) => s.name == "Show the consultation fee as Free");
        var free: any = "";
        if (consultationfree.length != 0)
            free = consultationfree[0].customizeValue
        else
            free = "";

        __currency = this.props.Settings.filter((s: any) => s.name == "Currency");
        var _currency: any = "";
        //var value: any;
        if (__currency.length == 0) {
            _currency = "";
        }
        else {
            if (__currency[0].value == "") {
                _currency = __currency[0].displayCode;
                localStorage.setItem("currency", _currency);
            }
            else {
                _currency = __currency[0].customizeValue;
                localStorage.setItem("currency", _currency);
            }


        }

        var currency: any = _currency;
        var discount: any = localStorage.getItem("Discount");
        var eprescrition: any = "";
        var _eprescrition: any = this.props.Settings.filter((s: any) => s.name == "E-Prescription Provider")[0];
        if (_eprescrition != undefined) {
            eprescrition = _eprescrition.customizeValue;
        } else {
            eprescrition = "";
        }
        if (localStorage.getItem("isAuth") == "true") {
            const patientLocalStroage: any = localStorage.getItem('Patient');
            const patient = JSON.parse(patientLocalStroage);
            if (this.props.TimeZones.length != 0) {
                Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
                if (patient.timeZone == "India Standard Time")
                    offset = 330;
                else
                    offset = Currenttimezone[0].Offset;
            }
            else {
                if (this.props.Settings.length != 0) {
                    var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
                    var _timezone: any = timezone.displayCode.split("|");
                    offset = _timezone[1];
                }
            }
        }
        else {
            if (this.props.Settings.length != 0) {
                var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
                var _timezone: any = timezone.displayCode.split("|");
                offset = _timezone[1];
            }
        }
        if (enctypemode == "noappointment") {
            var curentdate: any = dateConversion(offset);
            var curenttime: any = timeConversion(offset);
            appointmenton = curentdate;
            appointmenttime = curenttime;
            localStorage.setItem("AppointmentOn", curentdate);
            localStorage.setItem("AppointmentTime", curenttime);

        }
        var time: any = urlParams.get("time");
        //console.log(localStorage.getItem("AppointmentTime"));
    //  console.log(this.props.headerdrprofile)
        return (
            <React.Fragment>
                {(_array[1] == 'contact' && methodid == 5) || (_array[1] == 'contact' && appointmenton ==undefined) ? "" :
                    (name != null && familyenable === "Yes") || this.props.headerdrprofile.doctorName != "" || appointmenton != undefined || appointmenton != null || appointmenton1 != undefined || appointmenton1 != null || encDetail != null || this.props.contact.pharmacyName != "" ?
                        <div className="card">
                            <div className="card-body">
                                {this.props.bioIsLoading == true ?
                                    window.innerWidth <= 760 ?
                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}>
                                            <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                            <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                            <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                            {/* Only SVG shapes */}
                                            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                            <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                                            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                        </ContentLoader>

                                    :
                                    <React.Fragment>
                                        <div className="doctor-widget">
                                            {
                                                this.props.headerdrprofile.doctorName == "Not Assigned" ? "" :

                                                    this.props.headerdrprofile.doctorName == ""  || this.props.headerdrprofile.doctorName == null || this.props.headerdrprofile.doctorName == "Not Assigned" ? "" :
                                                        <div className="doc-info-left">
                                                            <div className="doctor-img dc-doctor-img">
                                                                {
                                                                    this.props.headerdrprofile.photo != null ? <img src={"data:image/png;base64, " + this.props.headerdrprofile.photo} className="img-fluid dc-img-fluid" alt="User Image" />
                                                                        : <img src="client/default/img/blankimg.jpg" className="img-fluid" alt="User Image" />
                                                                }
                                                            </div>
                                                            <div className="doc-info-cont mt-2">
                                                                <h4 className="doc-name">{this.props.headerdrprofile.doctorName == "" || null || undefined ? languagecontent['unknownprovider'] : this.props.headerdrprofile.doctorName}</h4>
                                                                {this.props.headerdrprofile.speciality.length != 0 ?
                                                                    <p className="doc-speciality">{this.props.headerdrprofile.speciality.map((spec: HeaderDrProfileStore.Speciality) =>
                                                                        spec.name
                                                                    ).join(', ')}
                                                                    </p> : ''
                                                                }

                                                            </div>
                                                        </div>
                                            }

                                            {
                                                _array[1] == 'doctorprofile' ? <div className="doc-info-right">
                                                    <div className="clinic-booking"><NavLink to={childid == null ? "/booking/" + this.props.headerdrprofile.doctorId + "?specialityid=" + specialityid : "/booking/" + this.props.headerdrprofile.doctorId + "?childid=" + childid + "&childname=" + childname + "&specialityid=" + specialityid} className="btn btn-primary apt-btn" >{languagecontent['booknow']}</NavLink></div>

                                                </div> : ""
                                            }

                                        </div>
                                        {
                                            _array[1] == 'doctorprofile' ? "" :
                                                <div>
                                                    {this.props.headerdrprofile.doctorName == "Not Assigned" ? "" :
                                                        this.props.headerdrprofile.doctorName == "" || this.props.headerdrprofile.doctorName == "Not Assigned" ? "" : familyenable == "Yes" && name != null || name != undefined ? <hr /> : ""
                                                    }
                                                    <div>
                                                    </div>

                                                    <div className="doctor-widget">
                                                        <div>
                                                            {
                                                                familyenable == "Yes" ?
                                                                    <React.Fragment>
                                                                        {
                                                                            name == null || name == undefined ? "" :
                                                                                <p style={{ float: "left" }} className="mb-0">{languagecontent['consultationfor'] == undefined || languagecontent['consultationfor'] == null ? "Consultation for" : languagecontent['consultationfor']}  <span className="text-info">{name}</span></p>
                                                                        }
                                                                    </React.Fragment> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            _array[1] == 'doctorprofile' ? "" :
                                                <div>
                                                    {
                                                        name != null && familyenable === "Yes" ? <hr /> : ""
                                                    }
                                                </div>
                                        }
                                        {
                                            _array[1] == 'doctorprofile' ? "" :
                                                
                                                   
                                                    <div className="row">
                                                        <div className="col-6 ">
                                                            
                                                                {
                                                                    _array[1] == 'doctorprofile' ? "" :
                                                                        <div className="float-left">
                                                                            {
                                                                                preferredtimeslot == "Yes" && pickaprovider == "No" ?
                                                                                    <div>
                                                                                        {appointmenton1 && appointmenttime1 != undefined ?
                                                                                            <div>
                                                                                                <div>
                                                                                                    <i className="fas fa-calendar-alt"></i> {appointmenton != undefined ? methodid == 5 ? dateFormat(appointmenton) : dateUTCFormat(appointmenton) : ''} <span className="time"> {localStorage.getItem("AppointmentTime") ? localStorage.getItem("AppointmentTime") : ''}</span><br />
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        <span className="time1">{appointmenton1 && appointmenttime1 ? < i className="fas fa-calendar-alt"></i> : ""} {appointmenton1 && appointmenttime1 ? dateUTCFormat(appointmenton1) : ''}  {localStorage.getItem("AppointmentTime1") ? localStorage.getItem("AppointmentTime1") : ''}</span>

                                                                                                    }
                                                                                                </div>


                                                                                                {
                                                                                            status == 'reschedule' ?
                                                                                                        <span className={statusColor('ReScheduled')}>{languagecontent['reschedule'] == undefined || languagecontent['reschedule'] == null ? "Reschedule" : languagecontent['reschedule']} </span>
                                                                                                        : ""}</div> :
                                                                                    <div><i className="fas fa-calendar-alt"></i> {appointmenton != undefined ? methodid == 5 ? dateFormat(appointmenton) : dateFormat(appointmenton) : ''} <span className="time"> {localStorage.getItem("AppointmentTime") ? localStorage.getItem("AppointmentTime") : ''}</span>
                                                                                                {
                                                                                                    <span>{appointmenton1 && appointmenttime1 ? < i className="fas fa-calendar-alt"></i> : ""} {appointmenton1 && appointmenttime1 ? dateUTCFormat(appointmenton1) : ''} <span className="time1"> {localStorage.getItem("AppointmentTime1") ? localStorage.getItem("AppointmentTime1") : ''}</span>
                                                                                                    </span>
                                                                                                }


                                                                                                {
                                                                                            status == 'reschedule' ?
                                                                                                        <span className={statusColor('ReScheduled')}>{languagecontent['reschedule'] == undefined || languagecontent['reschedule'] == null ? "Reschedule" : languagecontent['reschedule']} </span>
                                                                                                        : ""}	</div>}																</div>
                                                                                    : <div>
                                                                                        <i className="fas fa-calendar-alt"></i> {appointmenton != undefined ? enctypemode == "noappointment" || methodid == 3 ? (appointmenton) : dateUTCFormat(appointmenton) : ''} <span id="timeshow" className="time"> {'-' + appointmenttime != null && appointmenttime != "" ? appointmenttime : localStorage.getItem("AppointmentTime") ? localStorage.getItem("AppointmentTime") : ''}</span>


                                                                                        {
                                                                                    status == 'reschedule' ?
                                                                                                <span className={statusColor('ReScheduled')}>{languagecontent['reschedule'] == undefined || languagecontent['reschedule'] == null ? "Reschedule" : languagecontent['reschedule']} </span>
                                                                                                : ""}																		</div>

                                                                            }
                                                                            {/*{*/}
                                                                            {/*    !localStorage.getItem("AppointmentTime1")?*/}
                                                                            {/*        <div>*/}
                                                                            {/*            <i className="fas fa-calendar-alt"></i> {appointmenton != undefined ? enctypemode == "noappointment" || methodid == 3 ? (appointmenton) : dateFormat(appointmenton) : ''} <span id="timeshow" className="time"> {'-' + appointmenttime != null && appointmenttime != "" ? appointmenttime : localStorage.getItem("AppointmentTime") ? localStorage.getItem("AppointmentTime") : ''}</span>                                                                    */}


                                                                            {/*            {*/}
                                                                            {/*                status == 'reschedule' ?*/}
                                                                            {/*                    <span className={statusColor('ReScheduled')}>{languagecontent['reschedule'] == undefined || languagecontent['reschedule'] == null ? "Reschedule" : languagecontent['reschedule']} </span>*/}
                                                                            {/*                    : ""}																		</div>*/}
                                                                            {/*        : ''*/}

                                                                            {/*}*/}

                                                                    <div> {
                                                                        encDetail == null ? "" : <span>{this.props.headerdrprofile.encounterTypeMode == undefined ? encDetail.encounterTypeMode : this.props.headerdrprofile.encounterTypeMode}
                                                                        </span>} {(localStorage.getItem("FinalEncounterName") && localStorage.getItem("ServiceEncounterTypeId")) == null || ""  || undefined? "" : <span> | {localStorage.getItem("FinalEncounterName")}</span>
                                                                        }
                                                                        </div>                                                                                                                                      
                                                                        </div>
                                                                }

                                                            
                                                            
                                                        </div>
                                                        <div className="col-6 " >

                                                                <div className="clinic-booking float-right">
                                                                <div className="clini-infos">
                                                                    {
                                                                        _array[1] == 'doctorprofile' ? "" :
                                                                            <div className="clinic-details">
                                                                                {
                                                                                appointmenton ?
                                                                                    <div> <small className="d-flex justify-content-end">{this.props.headerdrprofile.minPrice == 0 || discount == 0 ? free == "Yes" ? languagecontent['fee'] : "" : languagecontent['fee']}</small></div>
                                                                                        : ''

                                                                                }

                                                                                {
                                                                                    encDetail == null ? "" : <div className="doc-location">

                                                                                        {
                                                                                            discount == null || discount == "" ?
                                                                                                <h3>
                                                                                                    {this.props.headerdrprofile.minPrice == 0 ? free == "Yes" ? languagecontent['free'] : "" : currency + getDiscountPrice(this.props.headerdrprofile.minPrice, 0)}

                                                                                                </h3> :
                                                                                                <h3>
                                                                                                    {discount == 0 ? free == "Yes" ? languagecontent['free'] : "" : currency + discount}
                                                                                                </h3>
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                               
                                        }



                                        {
                                            this.props.contact.pharmacyName == "" ? "" : ""
                                        }
                                        {
                                            _array[1] == 'doctorprofile' ? "" :

                                                <div className="hr">
                                                    <div className="float-left bottom">
                                                        <div>

                                                            {
                                                                (eprescrition == "DoseSpot" || eprescrition == "Both") ?
                                                                    <React.Fragment>
                                                                        {
                                                                            this.props.contact.pharmacyName == "" ? "" :
                                                                                <React.Fragment>
                                                                                    <span className="h6">{languagecontent['preferredpharmacy']}: </span>
                                                                                    <small className="small"> {this.props.contact.pharmacyName} </small>
                                                                                    <small className="small"> {this.props.contact.pharmacyAddress == "" ? this.props.contact.pharmacyCity : this.props.contact.pharmacyAddress + ", " + this.props.contact.pharmacyCity} </small>
                                                                                    <small className="small" > {this.props.contact.pharmacyState + "-" + this.props.contact.pharmacyZipCode} </small>
                                                                                </React.Fragment>
                                                                        }
                                                                    </React.Fragment> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div> : ""
                }


            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.headerDrProfile, ...state.booking, ...state.contact, ...state.account, ...state.langResources, ...state.timeZones }), // Selects which state properties are merged into the component's props
    ({ ...HeaderDrProfileStore.actionCreators, ...BookingStore.actionCreators, ...ContactStoreState.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...TimeZoneStore.actionCreators, ...Account.actionCreators, }) // Selects which action creators are merged into the component's props
)(HeaderDrProfile as any);
