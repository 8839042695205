import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { dateFormat, dateFormatSetting, getAge, phoneNumber, required, zipCode } from '../../helper/formvalidation';
import { renderAutoComplete, renderDatePicker, renderField, renderSelectField, renderDob, renderTimeZoneAutoComplete } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import ReactCrop from 'react-image-crop';
import * as ContactStore from '../../store/patient/Contact';
import * as HandleRequest from '../../helper/Request';
import * as LoginStore from '../../store/account/Login';
import * as LocationStore from '../../store/location/location';
import * as AccountStore from '../../store/siteaccount/Account';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as DashboardNavMenuStore from '../../store/menu/DashboardNav';
import * as CategoryStore from '../../store/siteaccount/Category';
import { datepickerJS, floatingJS, floatJS, dobcheck, ltrim } from '../../helper/Script';
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import 'react-image-crop/dist/ReactCrop.css';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { TextBoxLoader } from '../../helper/fbloader';
var patientLocalStroage: any;
var patientdata: any;
var onchangeLocationid: any = "";

type ContactProps =
	ContactStore.ContactState & LoginStore.LoginState & HandleRequest.Request & LoginStore.LoginState & DashboardNavMenuStore.DashboardNavState & LocationStore.LocationState & AccountStore.AccountState & TimeZoneStore.TimeZoneState & CategoryStore.CategoryState & LanguageResourcesStore.LanguageResouresState &
	typeof ContactStore.actionCreators & typeof LoginStore.actionCreators & typeof DashboardNavMenuStore.actionCreators & typeof LocationStore.actionCreators & typeof AccountStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof CategoryStore.actionCreators & typeof LanguageResourcesStore.actionCreators &
	RouteComponentProps<{}>;
var locationid = "";
var timeZone = "";
var reader = new FileReader();
var patient: any = {};
var locationCheck = "";
var dobchk = "";
var _doblimit: any = 0;
var selectedLocations: any = [];
var selectedTimeZone: any = [];
var dateofbirth: any = "";
window.addEventListener('load', (event) => {
	localStorage.removeItem("ProfileData");
	localStorage.removeItem("SelectedLocation");
	localStorage.removeItem("SelectedTimeZone");
});
class ContactForm extends React.PureComponent<ContactProps> {
	inputRef: any = React.createRef<HTMLInputElement>();
	constructor(props: any) {
		super(props);
	}
	state = {
		src: "",
		crop: {
			x: 130,
			y: 50,
			width: 200,
			height: 200,
			aspect: 9 / 9,

		},
		croppedImageUrl: "",
		croppedImageUrlrevert: ""
	};
	onSelectFile = (e: any) => {

		var x = document.getElementById("showCrop");
		if (x != null) {
			x.style.display = "block";
		}


		if (e.target.files && e.target.files.length > 0) {
			const fileSize = e.target.files[0].size / 1024 / 1024;
			const fileType = e.target.files[0].type;

			if (fileSize > 2) {
				var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
				return toastr.error('', langdata['pleaseselectimagesize']);
			}

			if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/gif" && fileType !== "image/jpeg") {
				var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
				return toastr.error('', langdata['pleaseselectfilesize']);
			}

			const reader = new FileReader();
			reader.addEventListener('load', () =>
				this.setState({ src: reader.result })
			);
			reader.readAsDataURL(e.target.files[0]);


		}
	};
	onImageLoaded = (image: any) => {

		var x = document.getElementById("showCrop");
		if ((image.naturalHeight >= 150 && image.naturalWidth >= 150)) {
			if (x != null) {
				if (x.style.display === "none") {
					x.style.display = "block";
				} else {
					x.style.display = "block";
				}
			}
			this.imageRef = image;
		} else {
			var croppedImageUrl = "";
			var src = "";
			var croppedImageUrlrevert = "";
			this.setState({ croppedImageUrl, src });
			if (x != null)
				x.style.display = "none";
			var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['pleaseselectafilewithaspectratio']);
		}
	};
	onCropComplete = (crop: any) => {
		this.makeClientCrop(crop);
	};
	onCropChange = (crop: any, percentCrop: any) => {
		this.setState({ crop });
	};
	imageRef: any;
	async makeClientCrop(crop: any) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				'newFile.jpeg'
			);
			this.setState({ croppedImageUrl });
		}
	}
	getCroppedImg(image: any, crop: any, fileName: any) {
		const canvas = document.createElement('canvas');
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const ctx = canvas.getContext('2d');

		canvas.width = crop.width * pixelRatio * scaleX;
		canvas.height = crop.height * pixelRatio * scaleY;
		if (ctx != null) {
			ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
			ctx.imageSmoothingQuality = 'high';

			ctx.drawImage(
				image,
				crop.x * scaleX,
				crop.y * scaleY,
				crop.width * scaleX,
				crop.height * scaleY,
				0,
				0,
				crop.width * scaleX,
				crop.height * scaleY
			);
		}
		return new Promise((resolve, reject) => {
			var fileUrl = "";
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						//reject(new Error('Canvas is empty'));
						console.error('Canvas is empty');
						return;
					}
					window.URL.revokeObjectURL(fileUrl);
					fileUrl = window.URL.createObjectURL(blob);
					resolve(fileUrl);
					reader.readAsDataURL(blob);
					reader.onload = function () {
						return reader.result;
					};
				},
				'image/png',
				1
			);
		});
	}



	onSubmit = (formValues: any, dispatch: any, props: any) => {
		/*if (locationid == "") {
			return toastr.error('', 'Please select valid city/state');
		}*/
		//console.log(formValues)

		var timezone: any = this.props.TimeZones.filter(x => x.Name == formValues.timeZone);
		var location: any = this.props.Locations.filter(x => x.Location == formValues.locationName);
		//console.log(this.props.Locations)
		var langdata: any;
		if (timezone.length == 0) {
			langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['pleaseselectvalidtimezone']);
		}
		var myForm: any = document.getElementById("dProfile");
		if (myForm.elements.namedItem("timeZone") != null) {
			this.props.contact.timeZone = myForm.elements.namedItem("timeZone").value;
			formValues.timeZone = myForm.elements.namedItem("timeZone").value;
		}

		var age: any = getAge(formValues.dob);
		var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
		var date: any = (formValues.dob);
		const dmyear = date.split("/");
		const year = dmyear[2];
		if (year == ("0000" || "000" || "00" || "0")) {
			var langdata: any;
			langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['invaliddate']);
		}

		if (doblimit.customizeValue > age) {
			var langdata: any;
			if (localStorage.getItem("LangResourcesValidations") != null)
				langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['userdobrange'] + ' ' + doblimit.customizeValue + ' ' + langdata['years']);

		}
		//console.log(this.props.Locations)
		if (formValues.locationName.length > 0) {
			//	const filetypeFilter = this.props.Locations.filter(function (value) {
			//		if (value.Description != null || value.Description == "") {
			//			var test = value.Name + "," + value.Description
			//		}
			//		else {
			//			var test = value.Name
			//		}


			//		return test.replace(/,| /g, '') === formValues.locationName.replace(/,| /g, '')
			//	});
			//	filetypeFilter.forEach(value => {
			//		locationCheck = value.Id;
			//	})
			//}
			//else {
			var array: any = formValues.locationName.split(",")[0];

			this.props.requestLocation(array)
			//this.props.requestLocation(formValues.locationName);
			if (locationid != "") {
				if (locationid == patientdata.locationId) {
					//if (patientdata.locationName == "") {
					//	patientdata.locationName = formValues.locationName
					//}
					if (patientdata.locationName.replace(/,| /g, '') == formValues.locationName.replace(/,| /g, ''))
						locationCheck = locationid;
					else
						locationCheck = "";
				}

				else if (patientdata.locationName.replace(/,| /g, '') == formValues.locationName.replace(/,| /g, '')) {
					locationCheck = patientdata.locationName;
				}

				else if (locationid != patientdata.locationId) {
					locationCheck = locationid;
				}

				else {
					locationCheck = "";
				}


			}
			else {
				locationCheck = "";
			}

		}

		//let dob: HTMLElement = document.getElementsByName('dob')[0] as HTMLElement;
		//dob.style.color = "white";
		if (ltrim(formValues.timeZone) == "") {
			var langdata: any;
			langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['pleaseselectvalidtimezone']);
		}
		//locationid = formValues.locationId;
		if (locationid == "") {
			langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['pleaseselectvalidcity/state']);
		}
		if (locationCheck == "") {
			var langdata: any;
			if (localStorage.getItem("LangResourcesValidations") != null)
				//console.log(age);
				langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			return toastr.error('', langdata['pleaseselectvalidcity/state']);
		}
		else {
			this.props.requestSaveContact(formValues, this.props.contact, locationid);

		}
		//var data = window.location.pathname;
		//const _array = data.split('/');
		//if (_array[1] == 'profilesettings') {
		//	if (selectedTimeZone.length == 0) {
		//		var langdata: any;
		//		langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
		//		return toastr.error('', langdata['pleaseselectvalidtimezone']);
		//	}
		//}
		this.props.requestPatient();
		//setTimeout(function () {
		//	dob.style.color = "#333";
		//}, 1500);		
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderContact()}
			</React.Fragment>
		);
	}
	public _handleSearch = (query: any) => {
		//console.log(query.substring(0, 3),"test=====")
		//this.props.requestLocation(query);
		var array: any = query.split(",")[0];
		this.props.requestLocation(array)

	}

	public _handleTimeZoneSearch = (query: any) => {
		//console.log(query.substring(0, 3),"test=====")
		let trimValue: any = ltrim(query);

		if (trimValue) {

			this.props.requestTimeZone(query);
		}

	}
	public _handleClickSearch = (query: any) => {
		//var string = query.substring(0, 3)
		//console.log(query, "-","test")
		this.props.requestLocation(query);
	}
	public _handleTimeZoneClickSearch = (query: any) => {
		//var string = query.substring(0, 3)
		//console.log(query, "-","test")
		this.props.requestTimeZone(query);
	}
	public _handleOnFocus = (event: Event) => {
		var data: any = event.target;
		this.props.requestLocation(data.defaultValue.substring(0, 4))
	}
	public _handleTimeZoneOnFocus = (event: Event) => {
		var data: any = event.target;
		this.props.requestTimeZone(data.defaultValue.substring(0, 4))
	}
	public _handleOnChange = (selected: any) => {

		selected.map((value: any) => {
			locationid = value.Id;
			onchangeLocationid = value.Id;
			selectedLocations.push(value);
			var array: any = value.Name;
			this.props.requestLocation(array)
			//console.log(value.Name.substring(0, 3),"value.Name")
			//this.props.requestLocation(value.Name);
		})
		//if (selected) {
		//let locationfield: HTMLElement = document.getElementsByClassName('rbt-menu')[0] as HTMLElement;
		//	let zipcode: HTMLElement = document.getElementsByName('zipcode')[0] as HTMLElement;
		//	zipcode.focus();
		//locationfield.style.display = "none";
		//}
	}
	public _handleTimeZoneOnChange = (selected: any) => {
		localStorage.removeItem("SelectedTimeZone");
		/*const [selectedOption, setSelectedOption] = useState(null);*/
		selected.map((value: any) => {
			timeZone = value.Name;
			selectedTimeZone.push(value);
			/*setSelectedOption(selected.value);*/
			//console.log(value.Name.substring(0, 3),"value.Name")
			this.props.requestTimeZone(value.Name.substring(0, 3));
			//console.log(selectedTimeZone);
		})

		if (selected) {
			//let locationfield: HTMLElement = document.getElementsByClassName('rbt-menu')[0] as HTMLElement;
			let zipcode: HTMLElement = document.getElementsByName('zipcode')[0] as HTMLElement;
			zipcode.focus();
			//locationfield.style.display = "none";
		}
	}


	private ensureDataFetched() {
		patientLocalStroage = localStorage.getItem('Patient');
		patientdata = JSON.parse(patientLocalStroage);
		this.props.getLangResources();
		this.props.getLangResourcesValidations();
		this.props.requestPatient();
		/*dobcheck(dateFormat(this.props.contact.dob), dobchk);*/
		this.props.getCategory();
		if (patientdata.locationName != "") {
			var array: any = patientdata.locationName.split(",")[0];
			locationid = patientdata.locationId;
			this.props.requestLocation(array)
		}
		if (patientdata.timeZone != "") {
			var array: any = patientdata.timeZone;
			this.props.requestTimeZone(array);
		}
	}
	componentDidMount() {
		localStorage.removeItem("SelectedTimeZone");
		this.ensureDataFetched();

		patientLocalStroage = localStorage.getItem('Patient');
		patientdata = JSON.parse(patientLocalStroage);
		const script = document.createElement("script");
		script.src = "/Theme/Doccure/admin/assets/js/script.js";
		script.async = true;
		window.document.body.appendChild(script);
		this.props.requestPatient();
		datepickerJS();
		floatingJS();
		dobchk = "";
		/*dobcheck(dateFormat(this.props.contact.dob), dobchk);*/
		//if (patientdata.locationName != "") {
		//	var array: any = patientdata.locationName.split(",")[0];
		//	this.props.requestLocation(array)
		//	//this.props.requestLocation(patientdata.locationName.substring(0, 4))
		//}
	}
	componentDidUpdate() {
		patientLocalStroage = localStorage.getItem('Patient');
		patientdata = JSON.parse(patientLocalStroage);
		floatJS();

		if (patientdata['dob'] != null && dobchk == "") {
			/*dobcheck(dateFormat(this.props.contact.dob), dobchk);*/
			dobchk = "checked";
		}

		//this.props.requestLocation(this.props.contact.locationName);
	}
	onBlockFile() {

		var x = document.getElementById("showCrop");
		if (x != null) {
			x.style.display = "hide";
		}

	}
	onUpdate() {
		var x = document.getElementById("showCrop");
		var croppedImageUrl = "";
		const src = "";
		if (x != null) {

			if (x.style.display === "none") {
				x.style.display = "block";
			} else {
				x.style.display = "none";
			}
		}
		this.props.requestSavePhoto(this.props.contact.id, reader.result);
		this.setState({ src: "" });
		this.inputRef.current.value = null;

	}
	onCancel() {
		var x = document.getElementById("showCrop");
		var croppedImageUrl = "";
		var croppedImageUrlrevert = "test";
		const src = "";
		const crop = "";
		if (x != null) {
			if (x.style.display === "block") {
				x.style.display = "none";
			} else {
				x.style.display = "block";
			}
		}
		this.setState({ croppedImageUrl, croppedImageUrlrevert });
		this.setState({ src: "" });
		this.inputRef.current.value = null;
	}

	onRemove() {
		var result = window.confirm("Are you sure to want delete?");
		if (result) {
			this.props.requestSavePhoto(this.props.contact.id, null);
		}
	}
	//	function =() =>{
	//	var input = document.createElement('INPUT');
	//	input.type = 'text';
	//	document.getElementById('form').appendChild(input);
	//}
	goPharmacyPage = () => {
		var myForm: any = document.getElementById("dProfile");
		this.props.contact.firstName = myForm.elements.namedItem("firstName").value;
		this.props.contact.lastName = myForm.elements.namedItem("lastName").value;
		this.props.contact.gender = myForm.elements.namedItem("gender").value;
		this.props.contact.dob = myForm.elements.namedItem("dob").value;
		this.props.contact.cellPhone = myForm.elements.namedItem("cellPhone").value;
		this.props.contact.address1 = myForm.elements.namedItem("address1").value;
		this.props.contact.address2 = myForm.elements.namedItem("address2").value;
		this.props.contact.locationName = myForm.elements.namedItem("Location").value;
		this.props.contact.locationId = onchangeLocationid;
		this.props.contact.zipcode = myForm.elements.namedItem("zipcode").value;
		this.props.contact.timeZone = myForm.elements.namedItem("timeZone").value;
		this.props.goPharmacyNav(this.props.contact)
		localStorage.setItem("ProfileData", JSON.stringify(this.props.contact));
		if (selectedLocations.length == 0) {
			localStorage.removeItem("SelectedLocation");
		} else {
			localStorage.setItem("SelectedLocation", JSON.stringify(selectedLocations[0]));
		}
		if (selectedTimeZone.length == 0) {
			localStorage.removeItem("SelectedTimeZone");
		} else {
			localStorage.setItem("SelectedTimeZone", JSON.stringify(selectedTimeZone[0]));
		}
	}
	public renderContact() {
		patientLocalStroage = localStorage.getItem('Patient');
		localStorage.setItem("ProfileData", JSON.stringify(this.props.contact));
		patientdata = JSON.parse(patientLocalStroage);
		var languagecontent: any = this.props.LanguageResoures;
		var _profiledata: any = localStorage.getItem("ProfileData");
		var profiledata: any = JSON.parse(_profiledata);
		var _selectedlocationdata: any = localStorage.getItem("SelectedLocation");
		var selectedlocationdata: any = JSON.parse(_selectedlocationdata);
		var _selectedtimezonedata: any = localStorage.getItem("SelectedTimeZone");
		var selectedtimezonedata: any = JSON.parse(_selectedtimezonedata);
		var dateformat: any = localStorage.getItem("DateFormat") == null ? "" : localStorage.getItem("DateFormat");
		if (profiledata != null) {
			this.props.contact.firstName = profiledata.firstName == "" ? this.props.contact.firstName : profiledata.firstName;
			this.props.contact.lastName = profiledata.lastName == "" ? this.props.contact.lastName : profiledata.lastName;
			this.props.contact.gender = profiledata.gender == "" ? this.props.contact.gender : profiledata.gender;
			this.props.contact.dob = profiledata.dob == "" ? this.props.contact.dob : profiledata.dob;
			this.props.contact.cellPhone = profiledata.cellPhone == "" ? this.props.contact.cellPhone : profiledata.cellPhone;
			this.props.contact.address1 = profiledata.address1 == "" ? this.props.contact.address1 : profiledata.address1;
			this.props.contact.address2 = profiledata.address2 == "" ? this.props.contact.address2 : profiledata.address2;
			this.props.contact.locationName = profiledata.locationName == "" ? this.props.contact.locationName : profiledata.locationName;
			this.props.contact.locationId = profiledata.locationId == "" ? this.props.contact.locationId : profiledata.locationId;
			this.props.contact.zipcode = profiledata.zipcode == "" ? this.props.contact.zipcode : profiledata.zipcode;
			this.props.contact.timeZone = this.props.contact.timeZone == "" ? profiledata.timeZone : this.props.contact.timeZone;
			if (selectedlocationdata != null) {
				this.props.Locations.push(selectedlocationdata == "" ? this.props.Locations : selectedlocationdata);
			}
			if (selectedtimezonedata != null) {
				this.props.TimeZones.push(selectedtimezonedata == "" ? this.props.TimeZones : selectedtimezonedata);
			}
		}

		//var langResources = JSON.parse(localStorage.getItem("LangResources") || "");
		//var signup: any = langResources.filter((la: any) => (la.Group == "signup"));
		//var profile: any = langResources.filter((la: any) => (la.Group == "profile"));

		//var lanfirstname: any = signup.filter((la: any) => la.Name == "firstname");
		//var lanlastname: any = signup.filter((la: any) => la.Name == "lastname");
		//var langender: any = profile.filter((la: any) => la.Name == "gender");
		//var landob: any = profile.filter((la: any) => la.Name == "dob");
		//var lanreplaceuploadphoto: any = langResources.filter((la: any) => la.Name == "replaceuploadphoto");

		//var profile_settings: any = langResources.filter((la: any) => (la.Group == "profile_settings"));
		const { crop, croppedImageUrl, src, croppedImageUrlrevert } = this.state;
		var data = window.location.pathname;
		const _array = data.split('/');
		const Locations = this.props.Locations;
		const TimeZone = this.props.TimeZones;
		const isLoading = this.props.isLoading;
		const isTimeZoneLoading = this.props.isTimeZoneLoading;
		const onSearch = this._handleSearch;
		const onTimeZoneSearch = this._handleTimeZoneSearch;
		const onClickMethod = this._handleClickSearch;
		const onChangeMethod = this._handleOnChange;
		const onChangeTimeZoneMethod = this._handleTimeZoneOnChange;
		const onFocusMethod = this._handleOnFocus;
		const bloodgroup = [{ value: '', name: 'Select' }, { value: 'A-', name: 'A-' }, { value: 'A+', name: 'A+' }, { value: 'B-', name: 'B+' }, { value: 'AB-', name: 'AB+' }, { value: 'O-', name: 'O+' },];
		const gender = this.props.categories.filter((ca: any) => ca.group == "Gender");

		//if (this.props.contact.dob == "") {
		//	this.props.contact.dob = "";
		//}
		//else {
		//	var __dateformat: any = dateFormatSetting(this.props.contact.dob);
		//	if (__dateformat == "Invalid date" || __dateformat == "Fecha inválida") {

		//	} else {
		//		this.props.contact.dob = dateFormatSetting(this.props.contact.dob);
		//          }
		//      }

		const localLocation = this.props.contact.locationName == "" || this.props.contact.locationName == null ? patientdata.locationName : this.props.contact.locationName;
		const localTimeZone = this.props.contact.timeZone == "" || this.props.contact.timeZone == null ? patientdata.timeZone : this.props.contact.timeZone;
		/*patient = this.props.contact;*/
		//console.log(patient)
		var eprescrition: any = "";
		var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
		var _eprescrition: any = this.props.Settings.filter((s: any) => s.name == "E-Prescription Provider")[0];
		if (_eprescrition != undefined) {
			eprescrition = _eprescrition.customizeValue;
		} else {
			eprescrition = "";
		}

		if (doblimit != undefined) {
			_doblimit = doblimit.customizeValue;
		} else {
			_doblimit = 0;
		}
		var lang = localStorage.getItem("DefaultLangCode");
		patient = this.props.contact;
		dateofbirth = this.props.contact.dob;
		//console.log(this.props.contact.dob)
		return (
			<React.Fragment>
				<div className="card">
					<div className="card-body">
						{
							_array[1] == "profilesettings" ?
								<div className="row">
									<div className="col-lg-6 col-sm-12" >
										<div className="form-group">
											<div className="change-avatar">
												<div className="profile-img">
													<img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl == "" || croppedImageUrlrevert == "test" ? (this.props.contact.photo == "" || this.props.contact.photo == undefined ? "client/default/img/blankimg.jpg" : this.props.contact.photo) : croppedImageUrl} />
												</div>

												{this.props.contact.photo == "" ? "" : <button type="button" hidden={true} className="btn btn-rounded btn-danger" style={{ top: "-15px", marginRight: "10px", position: "relative" }} onClick={e => this.onRemove()}><i className="fa fa-trash" aria-hidden="true"></i></button>}

												<div className="upload-img">
													<div className="change-photo-btn" onClick={this.onBlockFile}>
														{
															languagecontent['replaceuploadphoto'] == undefined ? <img src="../../../buttonloader.svg" /> :
																<span><i className="fa fa-upload"></i> {languagecontent['replaceuploadphoto']}</span>
														}
														<input type="file" accept="image/*" title={languagecontent['nofilechosen']} ref={this.inputRef} onChange={this.onSelectFile} className="upload" id="file-input" />
													</div>
													<small className="form-text text-muted">{languagecontent['file_size']} 2MB</small>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-sm-12 mb-3"> <p className="floatright d-flex flex-row-reverse bd-highlight"> <strong> {this.props.contact.accountName} </strong> </p> </div>

								</div> : ""
						}
						<div className="row" id="showCrop">
							<div className="col-sm-12">
								{src && (
									<ReactCrop
										src={src}
										crop={crop}
										ruleOfThirds
										onImageLoaded={this.onImageLoaded}
										onComplete={this.onCropComplete}
										onChange={this.onCropChange}
									/>
								)}
								{
									croppedImageUrl == "" && croppedImageUrlrevert == "" && src == "" ? "" :
										<div className="row">
											<div className="col-sm-12"><button className="btn btn-md btn-primary float-left" onClick={e => this.onUpdate()}> {languagecontent['update']} </button>
												<button className="btn btn-md btn-primary float-right" onClick={e => this.onCancel()}> {languagecontent['cancelbutton']} </button>
												<br /><br /> <br /></div>
										</div>
								}
							</div>
						</div>
						<form onSubmit={this.props.handleSubmit(this.onSubmit)} id="dProfile" noValidate autoComplete="off">

							<div className="row form-row">
								{
									_array[1] == "profilesettings" ?
										<React.Fragment>
											<div className="col-12 col-md-6">
												<div className={this.props.contact.firstName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
													<Field name="firstName" component={renderField} label={languagecontent['firstname']} maxLength={50} validate={[required]} type="text" />
												</div>
											</div>
											<div className="col-12 col-md-6">
												<div className={this.props.contact.lastName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
													<Field name="lastName" component={renderField} label={languagecontent['lastname']} maxLength={50} validate={[required]} type="text" />
												</div>
											</div>
										</React.Fragment> : ""

								}

								<div className="col-12 col-md-6">
									{
										languagecontent['gender'] == undefined ? <TextBoxLoader /> :
											<div className={this.props.contact.gender.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
												<Field name="gender" options={gender} select={languagecontent['select']} component={renderSelectField} validate={[required]} className="form-control" />
												<label className="focus-label" style={{ background: "none" }}>{languagecontent['gender']}</label>
											</div>
									}
								</div>

								<div className="col-12 col-md-6">
									<input hidden={true} id="dagelimit" value={_doblimit}></input>
									{
										languagecontent['dob'] == undefined ? <TextBoxLoader /> : ""
									}
									<div className={languagecontent['dob'] == undefined ? "form-group form-focus focused hide" : "form-group form-focus focused"}>
										<div className="cal-icon" style={{ paddingRight: "3px" }}>
											{this.props.contact ?
												<Field name="dob" component={renderDob} label={languagecontent['dob'] == undefined ? "" : languagecontent['dob'] + " " + "(" + dateformat + ")"} maxLength={15} validate={[required]} type="text" />
												: <Field name="dob" component={renderDob} label={languagecontent['dob'] == undefined ? "" : languagecontent['dob']} maxLength={15} validate={[required]} type="text" />
											}
										</div>
									</div>
								</div>
								{/*<div className="col-12 col-md-6">*/}
								{/*	<input hidden={true} id="dagelimit" value={_doblimit}></input>*/}

								{/*	<div className={dateofbirth == "" ? "form-group form-focus focused" : "form-group form-focus focused"}>*/}
								{/*		<div className="cal-icon">*/}
								{/*			{dateofbirth == "" ?*/}
								{/*				<Field name="dob" component={renderDob} label={languagecontent['dob'] == undefined ? "" : languagecontent['dob'] + " " + "(" + dateformat + ")"} maxLength={15} validate={[required]} type="text" />*/}
								{/*				: <Field name="dob" component={renderDob} label={languagecontent['dob'] == undefined ? "" : languagecontent['dob']} maxLength={15} validate={[required]} type="text" />*/}
								{/*			}*/}
								{/*		</div>*/}
								{/*	</div>*/}
								{/*</div>*/}
								<div className="col-12 col-md-6">
									<div className={this.props.contact.cellPhone.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
										<Field name="cellPhone" autoComplete="off" component={renderField} label={languagecontent['cellphone']} validate={[required, phoneNumber]} maxLength={15} minLength={15} type="text" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className={this.props.contact.address1.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
										<Field name="address1" autoComplete="off" component={renderField} label={languagecontent['address1']} validate={[required]} maxLength={35} type="text" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<div className={this.props.contact.address1.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
										<Field name="address2" autoComplete="off" component={renderField} label={languagecontent['address2']} maxLength={35} type="text" />
									</div>
								</div>
								<div className="col-12 col-md-6">
									<Field name="locationName" autoComplete="off" Location={this.props.Locations} autoCompleteData={localLocation} isLoading={isLoading} onSearch={onSearch} onChangeMethod={onChangeMethod} onClickMethod={this._handleClickSearch} component={renderAutoComplete} label={lang == 'en-us' ? <label>Cit&#8203;y, St&#8203;ate, Country</label> : <label>Ciud&#8203;ad, Es&#8203;tado, Pais</label>} validate={[required]} type="search" value=" " />
								</div>

								<div className="col-12 col-md-6">
									<div className={this.props.contact.zipcode.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
										<Field name="zipcode" autoComplete="off" component={renderField} label={languagecontent['postalcode']} maxLength={9} validate={[required]} type="text" />
									</div>
								</div>
								{
									_array[1] == "profilesettings" ?
										<div className="col-12 col-md-6">
											<Field name="timeZone" TimeZone={this.props.TimeZones} autoCompleteData={localTimeZone} isLoading={isTimeZoneLoading} onTimeZoneSearch={onTimeZoneSearch} onChangeTimeZoneMethod={onChangeTimeZoneMethod} onClickMethod={this._handleTimeZoneClickSearch} component={renderTimeZoneAutoComplete} label={languagecontent['timezone']} validate={[required]} type="search" value=" " />
										</div> : ""
								}

								{
									_array[1] == "profilesettings" ?
										<React.Fragment>
											{
												(eprescrition == "DoseSpot" || eprescrition == "Both") ?
													<div className="col-12 col-md-6">
														<div className="form-group focused cp_pharmacy">
															<label>{languagecontent['preferredpharmacy']} <a onClick={() => this.goPharmacyPage()}><i className="fa fa-search" aria-hidden="true"></i></a></label>
															<div>{this.props.contact.pharmacyName}</div>
															<div>{this.props.contact.pharmacyAddress}</div>
															<small>{this.props.contact.pharmacyName == "" ? languagecontent['nopharmacyadded'] : ""} </small>
														</div>
													</div> : ""
											}
										</React.Fragment> : ""
								}
							</div>

							<div className="row">
								<div className="col-sm-12">
									<div className="form-group form-focus submit-section">
										<button className={_array[1] == "profilesettings" ? "btn btn-lg btn-primary submit-btn float-left" : "btn btn-lg submit-btn btn-primary float-right"} type="submit" disabled={this.props.isContactLoading} >
											{
												languagecontent['submitbutton'] == undefined ? <img src="../../../buttonloader.svg" /> :
													<React.Fragment>
														{_array[1] == "profilesettings" ? languagecontent['submitbutton'] : languagecontent['proceedbutton'] == undefined ? "Proceed" : languagecontent['proceedbutton']}
													</React.Fragment>
											}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
const mapDispatchToProps = { ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ContactStore.actionCreators, ...DashboardNavMenuStore.actionCreators, ...LocationStore.actionCreators, ...AccountStore.actionCreators, ...TimeZoneStore.actionCreators, ...CategoryStore.actionCreators, ...LanguageResourcesStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => {

	let values = {
		initialValues: patient,
		...state.contact, ...state.login, ...state.dashboardNav, ...state.dashboardNav, ...state.location, ...state.account, ...state.timeZones, ...state.category, ...state.langResources
	};
	return values;

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'customForm',
	enableReinitialize: true,
})(ContactForm as any))