import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import * as ProfileStore from '../../store/patient/ProfileSettings';
import * as InsuranceAutoCompleStore from '../../store/autoComplete/insurance';
import * as LoginStore from '../../store/account/Login';
import * as HandleRequest from '../../helper/Request';
import * as CategoryStore from '../../store/siteaccount/Category';
import * as AddInsuranceStore from '../../store/insurance/addInsurance';
import * as AccountStore from '../../store/siteaccount/Account';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import ContactForm from '../widget/ContactForm';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { datepickerJS, floatingJS, floatJS, dobcheck, mobnav } from '../../helper/Script';
import { seo } from '../../helper/Script';
import { Field, reduxForm } from 'redux-form';
import { renderDob, renderExpDob, renderField, renderInsuranceautoComplete, renderInsuranceSelectField, renderInsurenceDob, renderSelectField } from '../../helper/renderfield';
import { dateFormat, getAge, required, dateFormatSetting } from '../../helper/formvalidation';
import * as  documentStore from '../../store/library/document/DocumentList';
import * as  documentUploadStore from '../../store/library/document/DocumentUpload';
import DocumentList from '../library/document/DocumentList';
import DocumentUpload from '../library/document/DocumentUpload';
import InsurenceForm from '../Insurence/Form';
import { toastr } from 'react-redux-toastr';
import Moment from 'moment';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
var _insurance: any = {};
type ProfileProps =
	ProfileStore.ProfileState & LoginStore.LoginState & CategoryStore.CategoryState & InsuranceAutoCompleStore.InsuranceAutoCompleteState & documentStore.DocumentListsState & AddInsuranceStore.AddInsuranceState & documentUploadStore.DocumentUploadState & HandleRequest.Request & AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState & typeof ProfileStore.actionCreators & typeof CategoryStore.actionCreators & typeof InsuranceAutoCompleStore.actionCreators & typeof documentStore.actionCreators & typeof AddInsuranceStore.actionCreators & typeof documentUploadStore.actionCreators & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators&
	RouteComponentProps<{}>;
var insuranceid: any = "";
var insurancename: any = "";
var insureddob: any = "";
var expdate: any = "";
var insurancenameChange: any = "";
var languagecontent: any = "";
class AddInsurence extends React.PureComponent<ProfileProps> {
	constructor(props: any) {
		super(props);
	}
	textInput = React.createRef();
	onSubmit = (formValues: any) => {
		/* Validation for Insurance name and Id -- start by Arun */
		var age: any = getAge(formValues.dob);
		var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];

		//console.log(age);
		if (doblimit.customizeValue >= age) {
			return toastr.error('', 'Age should be above ' + doblimit.customizeValue + ' years');
		}
		if (formValues.Id == "") {
			formValues.VerificationStatusId = null;
			formValues.VerificationStatus = null;
			formValues.PlatformType = "CP";
		}
		else {
			formValues.VerificationStatusId = null;
			formValues.VerificationStatus = null;
			formValues.PlatformType = "CP";
        }
		if (formValues.InsuranceId == _insurance.InsuranceId) {
			formValues.InsuranceId = _insurance.InsuranceId == ""  ? insuranceid : _insurance.InsuranceId;
			formValues.DocIds = this.props.docIds;
			//formValues.StatusId = "1";
			this.props.postInsurance(formValues,"","1");
		}
		else {
			this.props.requestInsurance(formValues.InsuranceId)
			const insuranceFilter = this.props.InsuranceAutoCompletes.filter(function (value) {
				return value;
			})
			//console.log(insuranceFilter, "insuranceFilter", formValues.InsuranceId)
			if (insuranceFilter.length > 0) {
		
				formValues.InsuranceId =  insuranceid ;
				formValues.DocIds = this.props.docIds;
				//formValues.StatusId = "1";
				this.props.postInsurance(formValues,"","1");
			}
			else {
				return toastr.error('', 'Please select valid insurance name');
			}
		}
		/* Validation for Insurance name and Id -- end by Arun */
	}
	
	componentDidMount() {
		insurancename = localStorage.getItem("InsuranceName");
		insureddob = localStorage.getItem("InsuredDOB");
		expdate = localStorage.getItem("ExpiryDate");
		const script = document.createElement("script");
		script.src = "/Theme/Doccure/admin/assets/js/script.js";
		script.async = true;
		document.body.appendChild(script);
		floatingJS();
		datepickerJS();
		dobcheck("", "");
		mobnav();
		var dobchk = "";
		seo({
			title: 'Insurance',
			pageTitle: 'Insurance',
			description: ''
		});
		//var dobchk = "";

		//if (insureddob == null)
		//	dobcheck(dobcheck(dateFormat(this.props.addInsurance.InsuredDOB), dobchk));
		//else
		//	dobcheck(dobcheck(dateFormat(insureddob), dobchk));

		//if (expdate == null)
		//	dobcheck(dobcheck(dateFormat(this.props.addInsurance.InsuredDOB), dobchk));
		//else
		//	dobcheck(dobcheck(dateFormat(expdate), dobchk));

		this.ensureDataFetched()
	}

	componentDidUpdate() {
		seo({
			title: 'Insurance',
			pageTitle: 'Insurance',
			description: ''
		});
	}

	public _handleSearch = (query: any) => {
		this.props.requestInsurance(query);
	}
	public _handleOnChange = (selected: any) => {
		//console.log(selected, "selected")
		selected.map((value: any) => {
			insuranceid = value.Id;
		})
	}
	private ensureDataFetched() {
		this.props.getCategory();
		const queryParams = new URLSearchParams(window.location.search);
		var id = queryParams.get('id');
		var memberid = queryParams.get('memberid');
		if (id != null) {
			this.props.getSingleInsurance(id);
			this.props.requestDocumentLists(memberid, "Patient Document");
		}
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderInsurenceList()}
			</React.Fragment>
		);
	}
	goBack = () => {
		this.props.goBackInsurance();
	}
	public renderInsurenceList() {
		//var date: any = document.getElementsByClassName("dval")[0];
		//if (this.props.addInsurance.InsuredDOB != "") {
		//	this.props.addInsurance.InsuredDOB = dateFormat(this.props.addInsurance.InsuredDOB);
		//	if (date != null)
		//		date.value = this.props.addInsurance.InsuredDOB;
		//}
		//var expdate: any = document.getElementsByClassName("expdate")[0];
		//if (this.props.addInsurance.ExpiryDate != "") {
		//	this.props.addInsurance.ExpiryDate = dateFormat(this.props.addInsurance.ExpiryDate);
		//	if (expdate != null)
		//		expdate.value = this.props.addInsurance.ExpiryDate;
		//}
		_insurance = this.props.addInsurance;
		const queryParams = new URLSearchParams(window.location.search);
		var id = queryParams.get('id');
		var memberid = queryParams.get('memberid');
		//if (Moment(this.props.addInsurance.InsuredDOB).format('MM/DD/YYYY') == "Invalid date")
		//	this.props.addInsurance.InsuredDOB = "";
		//else
		/*this.props.addInsurance.InsuredDOB = dateFormatSetting(this.props.addInsurance.InsuredDOB);*/
		//console.log(this.props.addInsurance.InsuredDOB)
		//if (Moment(this.props.addInsurance.ExpiryDate).format('MM/DD/YYYY') == "Invalid date")
		//	this.props.addInsurance.ExpiryDate = "";
		//else
		/*this.props.addInsurance.ExpiryDate = dateFormatSetting(this.props.addInsurance.ExpiryDate);*/

		
		const insurancetype = this.props.categories.filter((ca: any) => ca.group == "Insurance Type");
		if (id) {
			var localLocation = insurancename == "" || insurancename == null ? "" : insurancename;
		}
		else {
			localLocation = "" ;
        }
		
		const insurance = this.props.InsuranceAutoCompletes;
		//console.log(insurance, "insurance")
		const isLoading = this.props.isinsuranceLoading;
		const onSearch = this._handleSearch;
		const onChangeMethod = this._handleOnChange;
		var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
		var _doblimit: any = 0;
		if (doblimit != undefined) {
			_doblimit = doblimit.customizeValue;
		} else {
			_doblimit = 0;
		}
		//console.log(_insurance, "_insurance")
		return (
			<React.Fragment>
				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<DashboardNavMenu />
							<div className="col-md-7 col-lg-8 col-xl-9">
								<InsurenceForm />
								<DocumentUpload />
							</div>
					</div>
			    </div>

				</div>
			</React.Fragment>
		);
	}
}
const mapDispatchToProps = ({ ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ProfileStore.actionCreators, ...CategoryStore.actionCreators, ...InsuranceAutoCompleStore.actionCreators, ...documentStore.actionCreators, ...AddInsuranceStore.actionCreators, ...documentUploadStore.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators});
const mapStateToProps = (state: ApplicationState) => {
	localStorage.setItem("InsuredDOB", (_insurance.InsuredDOB == null) || (_insurance.InsuredDOB == "") ? "" : _insurance.InsuredDOB)
	let values = {
		initialValues: _insurance,
		...state.dashboardNav, ...state.login, ...state.profile, ...state.category, ...state.insuranceAutoCompletes, ...state.documentlist, ...state.addInsurance, ...state.documentupload, ...state.account, ...state.langResources
	};
	return values;

}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'addInsurance',
	enableReinitialize: true,
})(AddInsurence as any))


 const date_Format_Setting = (value: any) => {
	//console.log(value)
	if (value != null) {
		var format: any = localStorage.getItem("DateFormat");
		Moment.locale();
		if (value == "Invalid date" || value == "Fecha inválida") {
			return ""
		}
		var ad = new Date(value);
		var date = Moment(ad).format(format);
		//var date = Moment(value, "YYYY-MM-DDTHH:mm:ss").format(format);
		if (date == "Invalid date" || date == "Fecha inválida") {
			return value;
		} else {
			return date;
		}
	} else {
		return "";
	}

}
