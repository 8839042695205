import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as AccountStore from '../../store/siteaccount/Account';
import * as InsuranceListStore from '../../store/insurance/insuranceList';
import * as AddInsuranceStore from '../../store/insurance/addInsurance';
import * as PaymentProcessStore from '../../store/payment/PaymentProcess';
import { dateFormat, dateTimeFormat, insuranceVerificationStatusColor, scrollTop, setTittle } from '../../helper/formvalidation';
import { seo } from '../../helper/Script';
import HeaderDrProfile from '../widget/HeaderDrProfile';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
// At runtime, Redux will merge together...
type Props =
	FlexiFormStore.FlexiListsState & AccountStore.AccountState & InsuranceListStore.InsuranceListState & AddInsuranceStore.AddInsuranceState & PaymentProcessStore.PaymentProcessState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
	& typeof FlexiFormStore.actionCreators & typeof AccountStore.actionCreators & typeof InsuranceListStore.actionCreators & typeof AddInsuranceStore.actionCreators & typeof PaymentProcessStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var languagecontent: any = "";
class InsuranceList extends React.PureComponent<Props> {
	constructor(props: any) {
		super(props);
	}
	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.ensureDataFetched();
		scrollTop();
		localStorage.removeItem("InsurancePath");

	}
	
	public render() {
		return (
			<React.Fragment>
				{this.renderDoctorList()}
			</React.Fragment>
		);
	}
	public componentDidUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
	}
	private ensureDataFetched() {
		this.props.getLangResources();
		const patientLocalStroage: any = localStorage.getItem('Patient');
		const patient = JSON.parse(patientLocalStroage);
		this.props.getInsuranceList(patient.memberId);
	}
	addInsuranceList = () => {
		//const patientLocalStroage: any = localStorage.getItem('Patient');
		//const patient = JSON.parse(patientLocalStroage);
		//const memberid: any = patient.memberId;
		/*localStorage.setItem('InsurancePath', window.location.href);*/
		this.props.addInsuranceList();
		//window.location.href = "/addinsurance?" + window.location.search + "&memberid=" + memberid;
	}
	InsuranceInActive = (value: any) => {
		var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
		var r = window.confirm(langdata['insurancinactive']);
		if (value.PlatformType == null) {
			value.PlatformType = "CP";
		}
		if (r == true) {
			//value.StatusId = "2";
			localStorage.setItem('InsurancePath', window.location.href);
			this.props.postInsurance(value,"delete","2");
		} else {
			return;
		}
		
	}
	editInsurance = (value: any) => {
		localStorage.setItem("InsuranceName", value.InsuranceName);
		localStorage.setItem("InsuredDOB", value.InsuredDOB);
		localStorage.setItem("ExpiryDate", value.ExpiryDate);
		localStorage.setItem('InsurancePath', window.location.href);
		this.props.editInsuranceList(value);
		
	}
	back = () => {
		this.props.previouspage();		
	}

	submitInsurance = () => {
		this.props.requestInsurancePay();
    }
	private renderDoctorList() {
		languagecontent = this.props.LanguageResoures;
		var activeInsurances: any = this.props.InsuranceLists.filter((se: any) => se.StatusId == "1" && (se.VerificationStatusId == "1" || se.VerificationStatusId == "2" ||se.VerificationStatusId == "4" || se.VerificationStatusId == "0"));
		return (
			<React.Fragment>
			<div className="content">
				<div className="container">
					<HeaderDrProfile />
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<div className="card">
								<div className="card-body">
									<div id="pat_appointments" className="tab-pane fade show active">

										{
											<div>
												{
												/*activeInsurances.length == 0 ? <div> <div className="row mb-2"><div className="col-sm-12"><button className="btn btn-sm btn-primary float-right" onClick={() => this.addInsuranceList()} type="button">{this.props.isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {languagecontent['addinsurance'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['addinsurance']}</button></div></div> <div className="card"><div className="card-body">{languagecontent['norecordsfound']}</div></div></div> :*/
													activeInsurances.length == 0 ? <div> {languagecontent['addinsurance'] == undefined ? "" : <div className="row mb-2"><div className="col-sm-12"><button className="btn btn-sm btn-primary float-right" onClick={() => this.addInsuranceList()} type="button">{ languagecontent['addinsurance'] }</button></div></div>}  <div className="card"><div className="card-body">{languagecontent['norecordsfound']}</div></div></div> :
												
														<div>
															{
																activeInsurances.length == 0 ?
																	"" : <div>
																		
																		<h4 className="widget-title pat-widget-title">{languagecontent['activeinsurance']} {languagecontent['addinsurance'] == undefined ? "" : <button className="btn btn-sm btn-primary float-right" onClick={() => this.addInsuranceList()} type="button">{languagecontent['addinsurance']}</button>} </h4>

																		{activeInsurances.map((insurance: InsuranceListStore.InsuranceList) => 
																			 insurance.VerificationStatus== "Invalid"? "":
																			<div className="card card-table my-2 p-1 p-xl-3">
																				<div className="card-body" style={{ padding: "10px" }}>
																					<div className="row">
																						<div style={{ margin: '10px 5px', }} className={insurance.InsuranceType == "Primary" ? "text-rotate-primary" : insurance.InsuranceType == "Primario" ? "text-rotate-primary" : insurance.InsuranceType == "Secondary" ? "text-rotate-secondary" : insurance.InsuranceType == "Secundario" ? "text-rotate-secondary" : insurance.InsuranceType == "Tertiary" ? "text-rotate-teriorty" : insurance.InsuranceType == "Terciario" ? "text-rotate-teriorty" : ""}>{insurance.InsuranceType}</div>
																						<div className="col-md-4 col-sm-4 ml-4">
																							<h5>{insurance.InsuranceName}</h5>
																							<div>{languagecontent['planname']}: {insurance.PlanName}</div>
																							<div> {languagecontent['insuranceorpolicy']}: {insurance.PolicyNo}</div>
																						</div>
																						<div className="col-md-4 col-sm-4 ml-4">
																							<div><br /></div>
																							<div>{languagecontent['insuredname']}: {insurance.InsuredName}</div>
																							<div>{(insurance.ExpiryDate == "") || (insurance.ExpiryDate == null) ? "" : languagecontent['expirydate'] + ": " + dateFormat(insurance.ExpiryDate)}</div>
																								{/*{insurance.VerificationStatus == "" ? "" : <div className="insurance-ellipsis">{languagecontent['verificationstatus']}: <span className={insuranceVerificationStatusColor(insurance.VerificationStatusId)}>{insurance.VerificationStatus}</span></div>}*/}

																						</div>
																						<div className="col-md-3 col-sm-3 ml-2">
																							<div>
																								<div className="custom-control custom-switch float-right">
																									{insurance.DocCount == 0 ? "" : <i className="fa fa-file mr-5" aria-hidden="true"></i>}
																									<input type="checkbox" className="custom-control-input" checked={insurance.StatusId == "1" ? true : false} id={insurance.Id} onChange={() => this.InsuranceInActive(insurance)} />
																									<label className="custom-control-label" htmlFor={insurance.Id}></label>
																									<a className="edit-link" data-toggle="modal" onClick={() => this.editInsurance(insurance)}><i className="fa fa-edit mr-1"></i></a>

																								</div>
																							</div>
																							<div><br /><br /></div>
																							<div>
																								<small className="float-right text-muted ml-2">{languagecontent['updatedby']} {insurance.ModifiedName} {languagecontent['on']} {dateTimeFormat(insurance.ModifiedOn)}</small>
																							</div>
																						</div>
																					</div>
																				</div>


																			</div>
																		)}

																	</div>
															}
														</div>
												}
											</div>
												
										}
									</div>
									{/*<br />*/}
									{/*<div className="submit-section text-right" hidden={activeInsurances.length == 0 ? true : false}>*/}
									{/*	<button className="btn btn-primary submit-btn" disabled={this.props.isLoading} type="button" onClick={() => this.submitInsurance()} > {this.props.isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['proceed']}</button>*/}
									{/*</div>*/}
								</div>
								
							</div>
						</div>
					</div>
					<div className="submit-section text-right">
						<button className="btn btn-secondary submit-btn" onClick={this.back}>{languagecontent['backbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['backbutton']}</button>
						<button hidden={activeInsurances.length == 0 ? true : false} className="btn btn-primary submit-btn" disabled={this.props.isLoading} type="button" onClick={() => this.submitInsurance()} > {this.props.isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['proceed']}</button>
					</div>
				</div>
				</div>
			</React.Fragment>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({ ...state.flexiform, ...state.account, ...state.insuranceLists, ...state.addInsurance, ...state.paymentProcess, ...state.langResources }), // Selects which state properties are merged into the component's props
	({ ...FlexiFormStore.actionCreators, ...AccountStore.actionCreators, ...InsuranceListStore.actionCreators, ...AddInsuranceStore.actionCreators, ...PaymentProcessStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(InsuranceList as any);
