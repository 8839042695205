import axios from 'axios';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface InsuranceListState {
    isLoading: boolean,
    InsuranceLists: InsuranceList[]
}

export interface InsuranceList {
    Id: string,
    MemberId: string,
    Level: string,
    InsuranceId: string,
    InsuredName: string,
    InsuredDOB: string,
    PolicyNo: string,
    GroupNo: string,
    PlanName: string,
    PlanCode: string,
    ExpiryDate: string,
    StatusId: string,
    InsuranceType: string,
    StatusName: string,
    InsuranceName: string,
    DocCount: any,
    ModifiedName: string,
    ModifiedOn: string
    VerificationStatusId: string;
    VerificationStatus: string;
    PlatformType: string
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestInsuranceListAction { type: 'REQUEST_INSURANCE_LIST', InsuranceLists: InsuranceList[] }
export interface ReciveInsuranceListAction { type: 'RECEIVE_INSURANCE_LIST', InsuranceLists: InsuranceList[] }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestInsuranceListAction | ReciveInsuranceListAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getInsuranceList: (memberid: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        const lan_code = localStorage.getItem('DefaultLangCode')
        if (appState && appState.insuranceLists) {
            axios.get('/Insurance/GetInsuranceList/' + memberid + "/" + lan_code)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_INSURANCE_LIST', InsuranceLists: result });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_INSURANCE_LIST' });
        }
    },
    addInsuranceList: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        setTimeout(function () {
            const patientLocalStroage: any = localStorage.getItem('Patient');
            const patient = JSON.parse(patientLocalStroage);
            const memberid: any = patient.memberId;
            var data: any = window.location.pathname;
            const _array = data.split('/');
            if (_array[1] == "insurancelist") {
                window.location.href = "/addinsurance" + window.location.search + "&memberid=" + memberid;
            } else {
                window.location.href = "/insurance/add?memberid=" + memberid;
            }
        }, 10);
    },
    editInsuranceList: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        setTimeout(function () {
            const queryParams = new URLSearchParams(window.location.search);
            var memberid = queryParams.get('memberid');
            if (memberid == null) {
                const patientLocalStroage: any = localStorage.getItem('Patient');
                const patient = JSON.parse(patientLocalStroage);
                memberid = patient.memberId;
            }
            var data: any = window.location.pathname;
            const _array = data.split('/');
            if (_array[1] == "insurancelist") {
                dispatch(push("/addinsurance" + window.location.search + "&memberid=" + memberid + "&id=" + value.Id));
                //  window.location.href = "/addinsurance" + window.location.search + "&memberid=" + memberid + "&id=" + value.Id;
            } else {
                dispatch(push("/insurance/add?memberid=" + memberid + "&id=" + value.Id))
                //  window.location.href = "/insurance/add?memberid=" + memberid + "&id=" + value.Id;
            }
        }, 10);
    },
    previouspage: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState: any = getState();
        if (appState && appState.account) {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patientstore: any = JSON.parse(patientLocalStroage);
            const urlParams = new URLSearchParams(window.location.search);
            const methodid = urlParams.get('methodid');
            const appointmentid = urlParams.get('appointmentid');
            const doctorid = urlParams.get('doctorid');
            const childid = urlParams.get('childid');
            const childname = urlParams.get('childname');
            var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
            var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
            var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];
            if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
            }
            else
                window.history.go(-1);
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: InsuranceListState = {
    InsuranceLists: [],
    isLoading: false
};
export const reducer: Reducer<InsuranceListState> = (state: InsuranceListState | undefined, incomingAction: Action): InsuranceListState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_INSURANCE_LIST':
            return {
                InsuranceLists: state.InsuranceLists,
                isLoading: true
            };
        case 'RECEIVE_INSURANCE_LIST':
            return {
                InsuranceLists: action.InsuranceLists,
                isLoading: false
            };
        default:
            return state;
    }
};
