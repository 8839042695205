import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as FailStore from '../../store/payment/Failed';
import { seo } from '../../helper/Script';
import { RouteComponentProps, withRouter } from "react-router";
import { ApplicationState } from '../../store';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { setTittle } from '../../helper/formvalidation';
import { BoxLoader } from '../../helper/fbloader';
// At runtime, Redux will merge together...
type Props =
	FailStore.FailState & LanguageResourcesStore.LanguageResouresState // ... state we've requested from the Redux store
	& typeof FailStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var languagecontent: any = "";
class Failed extends React.PureComponent<Props> {
	constructor(props: any) {
		super(props);
	}
	public componentDidMount() {
		this.ensureDataFetched();
    }
	public componentWillUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderDoctorList()}
			</React.Fragment>
		);
	}
	goDashboard = () => {
		window.location.href = "/dashboard";
	}
	private ensureDataFetched(){
		this.props.requestFail(this.props.match.params);
	}
	goRetry = () => {
		var url: any = localStorage.getItem("PaymentRetry");
		this.props.requestSaveAppointment(url);
    }
	private renderDoctorList() {
		languagecontent = this.props.LanguageResoures;
		return (
			<React.Fragment>
			<div className="content">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-lg-6">
							{this.props.isFailLoading == false && languagecontent['appointmentfailed'] ?
								<div className="card success-card">
									<div className="card-body">
											<div className="success-cont">
												<i className="fas fa-exclamation"></i>
												<h3>{languagecontent['appointmentfailed']}</h3>
												<p>{languagecontent['description']}</p>
											</div>
											<div className="row">
												<div className="col-sm-12 text-center">
													<button className="btn btn-lg btn-secondary" onClick={() => this.goRetry()} disabled={this.props.isFailLoading} style={{ marginRight: "10px", width: "165px", marginBottom: "10px" }}> {this.props.isFailLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['retry']}</button>
													<button className="btn btn-lg btn-primary" onClick={() => this.goDashboard()} style={{ marginRight: "10px", width: "165px", marginBottom: "10px" }}><span>{languagecontent['gotodashboard']}</span></button>
												</div>
											</div>
										</div>
									</div> 
							
								: <div className="card success-card">
									<div className="card-body">
											<h3 className="text-center"><i className="fa fa-spinner fa-spin"></i> {languagecontent['pleasewait']}...</h3>	
									</div>
								</div>
							}
						</div>
					</div>
				</div>
		    </div>
			</React.Fragment>
			)
	}
}
export default connect(
	(state: ApplicationState) => ({ ...state.fail, ...state.langResources }), // Selects which state properties are merged into the component's props
	({ ...FailStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(Failed as any);

