import * as React from 'react';
/*import './NavVirtuvets.css';*/
import { NavLink, Link, RouteComponentProps } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar'
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as LoginStore from '../../../../store/account/Login';
import * as ContactStore from '../../../../store/patient/Contact';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import * as AccountStore from '../../../../store/siteaccount/Account';
import * as EncounterTypeStore from '../../../../store/patient/EncounterType';
import * as LanguageResouresStore from '../../../../store/LanguageResoures';
import * as FlexFromStore from '../../../../store/library/flexiform/FlexiForm';
import * as SiteMenusStore from '../../../../store/siteaccount/siteMenu';
import { ApplicationState } from '../../../../store/index';
import * as MenuHideStore from '../../../../store/menuHide';
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);
var authLocalStroage: any = localStorage.getItem('isAuth');
type NavDefaultStoreProps = NavDefaultStore.NavDefaultState & MenuHideStore.MenuHideState & LanguageResouresStore.LanguageResouresState & SiteDetailStore.SiteDetailState & LoginStore.LoginState & AccountStore.AccountState & AccountStore.Settings & ContactStore.ContactState & EncounterTypeStore.EncounterTypeState & SiteMenusStore.SiteMenuState & FlexFromStore.FlexiListsState & typeof NavDefaultStore.actionCreators & typeof LanguageResouresStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LoginStore.actionCreators & typeof AccountStore.actionCreators & typeof ContactStore.actionCreators & typeof EncounterTypeStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof FlexFromStore.actionCreators & typeof MenuHideStore.actionCreators & RouteComponentProps;
class NavVirtuvets extends React.PureComponent<NavDefaultStoreProps> {
    public componentDidMount() {
        this.ensureDataFetched();
    }
    componentWillMount() {
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        this.ensureDataFetched();
       
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.getDefaultAccount();
        this.props.requestEncounterType();
        this.props.getSiteMenu();
        if (localStorage.getItem("isAuth") == "true") {
            //this.props.getFormModule();
        }
    }
    public logOut() {
        this.props.requestLogout();
    }
    public login() {
        return <NavLink to="/login" />;
    }
    goOurDoctor = () => {
        this.props.goNextPage();
    }
    public renderDoctorList() {
        //var data = document.location.pathname;
        //const _array = data.split('/');
        //if (_array[1] == "" || _array[1] == "login") {
        //    setTimeout(() => {
        //        var checkArray = String(this.props.navDefault.isAuth) == String(authLocalStroage);
        //        if (checkArray == false) {
        //            //localStorage.setItem("isAuth", "false");
        //            //window.location.href = "/login";
        //        }
        //    }, 300);
        //}
        //var link: any = document.querySelector("link[rel~='icon']");
        //if (!link) {
        //    link = document.createElement('link');
        //    link.rel = 'icon';
        //    document.getElementsByTagName('head')[0].appendChild(link);
        //}
        //link.href = this.props.siteDetail.FavIconPath == "" ? "client/logo/favicon.png" : this.props.siteDetail.FavIconPath;
        
        var formArray: any = [];
        formArray = this.props.moduleLists.filter(function (el: any) {
            return ((el.id == null) && (el.showOnce == true) && (el.moduleId != 71) && (el.moduleId != 86) && (el.moduleId != 59) && (el.moduleId != 41));
        });
        var publicMenus: any = [];
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "1");
        var data = window.location.pathname;
        const _array = data.split('/');
        return (
            <React.Fragment>
                <header className="header">
                    <LoadingBar style={{ backgroundColor: '#15558d', height: '10px' }} className="loading" updateTime={100} maxProgress={95} progressIncrease={10} />
                    <nav className="navbar navbar-expand-lg header-nav vrheader-nav">
                        <div className="navbar-header f25">
                            <a id="mobile_btn">
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot" ></span>
                                <span className="dot"></span>
                            </a>
                            {
                                this.props.siteDetail != undefined ?
                                    <a href="/" className="navbar-brand logo" style={{ width: "130px", height: "75px" }}>
                                        {
                                            this.props.siteDetail.Logo == "" || this.props.siteDetail.Logo == undefined ? this.props.siteDetail.Name : <a href="https://virtuvets.com/">< img src={"client/logo/VrLogo.png"}   style={{ top: "10px", height: "25px" }} alt="Logo" /></a>
                                        }
                                    </a> : ""
                            }
                        </div>
                        <div className="main-menu-wrapper vrmain-menu-wrapper" style={{ backgroundColor:"#ffffff" }}>
                            <div className="menu-header">
                                <a href="https://www.virtuvets.com/" className="menu-logo" style={{ width: "130px", height: "75px"}}>
                                    <img src="client/logo/VrFavIcon.jpg" alt="Logo" />
                                </a>
                                <a id="menu_close" className="menu-close">
                                    <i className="fas fa-times"></i>
                                </a>
                            </div>
                            <ul className="main-nav">
                                <li className="login-link" style={{ borderBottom:"1px solid #ffffff" }}>
                                    {
                                        this.props.navDefault.isAuth == true ?
                                            <React.Fragment>
                                                {
                                                    publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                                        <a className="dropdown-item" style={{ color: "#787b7c" }} href={sitemenu.Src}>{sitemenu.Title}</a>
                                                    )
                                                }
                                                {
                                                    formArray.length == 0 ?
                                                        <React.Fragment>
                                                            <NavLink className="dropdown-item" style={{ color: "#787b7c" }} to="/ourdoctors">OUR DOCTORS</NavLink>
                                                            {_array[1] == "initialpageforms" ? "" : <NavLink className="dropdown-item" style={{ color: "#787b7c" }} to="/dashboard">DASHBOARD</NavLink>}
                                                        </React.Fragment>
                                                        : ""
                                                }
                                                <NavLink className={this.props.isSettingsLoading ? "dropdown-item disabled" :"dropdown-item" } style={{ color: "#787b7c" }} to="/login" onClick={() => this.logOut()}>LOGOUT</NavLink>
                                            </React.Fragment> :
                                            <React.Fragment>
                                                {
                                                    publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                                        <a className="dropdown-item" style={{ color: "#787b7c" }} href={sitemenu.Src}>{sitemenu.Title}</a>
                                                    )
                                                }
                                                <NavLink hidden={this.props.isShow} className="dropdown-item" onClick={() => this.login()} style={{ color: "#787b7c" , fontSize: "14px" }} to="/login">Sign In / Sign Up</NavLink>
                                            </React.Fragment>

                                    }
                                </li>
                            </ul>
                        </div>
                        <ul className="nav header-navbar-rht vrheader-navbar-rht">
                            {
                                publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                     <li>
                                        <a href={sitemenu.Src}>{sitemenu.Title}</a>
                                    </li>
                                )
                            }
                            {
                                this.props.navDefault.isAuth == false || authLocalStroage == false ?
                                    <li>{
                                        this.props.navisLoading == true ? "" : <NavLink hidden={this.props.isShow} onClick={() => this.login()} className="nav-link header-login vrheader-login nav-top" to="/login" style={{ color: "#ffffff", fontSize: "14px" }}>Sign In / Sign Up</NavLink>
                                    }
                                    </li>
                                    :
                                    <li className="nav-item dropdown has-arrow logged-item">
                                        <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                            <span className="user-img">
                                                <img className="rounded-circle" src={this.props.contact.photo == "" || this.props.contact.photo == undefined ? "client/default/img/blankimg.jpg" : this.props.contact.photo} width="31" alt="" />
                                            </span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">{
                                            this.props.patient != undefined ?
                                                <div className="user-header">
                                                    <div className="user-text" style={{ padding: '5px 10px' }}>
                                                        <h6>{this.props.contact.firstName + " " + this.props.contact.lastName} </h6>
                                                    </div>
                                                </div> : ''
                                        }
                                            {
                                                formArray.length == 0 ? _array[1] == "initialpageforms" ? "" :
                                                    <NavLink className="dropdown-item" style={{ padding: '10px 25px', color: "#272b41" }}  to="/dashboard">Dashboard</NavLink>
                                                    : ""
                                            }
                                            <a className={this.props.isSettingsLoading ? "dropdown-item disabled" : "dropdown-item"} style={{ padding: '10px 25px', color: "#272b41" }}  onClick={() => this.logOut()}>Logout</a>
                                        </div>
                                    </li>
                            }

                        </ul>
                    </nav>
                </header>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.login, ...state.siteDetail, ...state.account, ...state.contact, ...state.encountertypes, ...state.langResources, ...state.siteMenus, ...state.flexiform, ...state.menuHide }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...LoginStore.actionCreators, ...SiteDetailStore.actionCreators, ...AccountStore.actionCreators, ...ContactStore.actionCreators, ...EncounterTypeStore.actionCreators, ...MenuHideStore.actionCreators, ...LanguageResouresStore.actionCreators, ...SiteMenusStore.actionCreators, ...FlexFromStore.actionCreators }) // Selects which action creators are merged into the component's props
)(NavVirtuvets as any);