import * as React from 'react';
import { connect } from 'react-redux';
import { seo } from '../../helper/Script';
import * as HandleRequest from '../../helper/Request';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { ApplicationState } from '../../store';
import HeaderDrProfile from '../widget/HeaderDrProfile';
import * as PharmacyStateList from '../../store/pharmacy/StateList';
import * as PharmacyListState from '../../store/pharmacy/PharmacyList';
import * as ContactStoreState from '../../store/patient/Contact';
import * as PaymentProcessStore from '../../store/payment/PaymentProcess';
import {floatingJS} from '../../helper/Script';
import Pagination from "react-js-pagination";
import ContentLoader from 'react-content-loader';
import { renderField, renderInsuranceSelectField, renderStateSelectField } from '../../helper/renderfield';
import { toastr } from 'react-redux-toastr';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { scrollTop, setTittle } from '../../helper/formvalidation';
import { TextBoxLoader } from '../../helper/fbloader';
type Props = RouteComponentProps & PharmacyStateList.PharmacyStateListState & PharmacyListState.PharmacyListState & ContactStoreState.ContactState & PaymentProcessStore.PaymentProcessState & LanguageResourcesStore.LanguageResouresState & typeof PharmacyStateList.actionCreators & typeof ContactStoreState.actionCreators & typeof PharmacyListState.actionCreators & typeof PaymentProcessStore.actionCreators & typeof LanguageResourcesStore.actionCreators & HandleRequest.Request;
var pharmacyid: any = "";
var languagecontent: any = "";
var _city: any = "-";
var _state: any = "-";
var langdata: any = "";
class PharmacyList extends React.PureComponent<Props> {
    onSubmit = (formValues: any) => {
            this.setState({
                state: "",
                city: "",
                zipcode: "",
                isShow: false,
                ishowPreferred: true
            });
            this.setState({
                isShow: false
            });
            _state = (formValues.State == undefined) || (formValues.State == "") ? "-" : formValues.State;
            _city = (formValues.City == undefined) || (formValues.City == "") ? "-" : formValues.City;
             this.props.requestPharmacyList((formValues.State == undefined) || (formValues.State == "") ? "-" : formValues.State, (formValues.City == undefined) || (formValues.City == "") ? "-" : formValues.City, (formValues.Zipcode == "") || (formValues.Zipcode == undefined) ? "-" : formValues.Zipcode, "15", "0", "");
    }
    constructor(props:any) {
        super(props);
    }
    state = {
        state: "",
        city: "",
        zipcode: "",
        activePage: 1,
        activePagecount: 15,
        currentPagecount: 1,
        isShow: true,
        ishowPreferred: false
    }
    public componentDidMount() {
        this.setState({
            state: "",
            city: "",
            zipcode: "",
            isShow: true,
            ishowPreferred: false
        });
        floatingJS();
        this.ensureDataFetched();
        scrollTop();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    handlePageChange(pageNumber: any) {
        var page = (pageNumber * 15) - 15;
        var pagenumber = page + 1
        this.setState({ activePage: pageNumber, activePagecount: pageNumber * 15, currentPagecount: pagenumber });
        this.props.requestPharmacyList(_state, _city, "-", "15", pageNumber.toString(), "");
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.requestPharmacyStateList("5df899ad-540e-4902-a12b-fd460cc1dd3d");
        /*this.props.requestPharmacyList("-", "-", "-", "15", "0", "");*/
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }
    public render() {
        return (
            <React.Fragment>
                {this.PharmacyList()}
            </React.Fragment>
        );
    }
    selectPharmacyName = (value: any) => {
        pharmacyid = value.Id;
    }
    selectState = (e: any) => {
        this.state.state = e.target.value
        
    }
    procced = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const memberid = urlParams.get('memberid');
        pharmacyid = pharmacyid == "" ? this.props.contact.pharmacyId == "00000000-0000-0000-0000-000000000000" && this.props.contact.pharmacyName == "" ? "" : this.props.contact.pharmacyId : pharmacyid;
          if (pharmacyid == "") {
              toastr.error('', languagecontent['selectpharmacy']);
        } else {
            this.props.requestPharmacyPost(memberid, pharmacyid);
        }
    }
    reset = () => {
        pharmacyid = "";
        this.props.requestReset();
        this.setState({
            state: "",
            city: "",
            zipcode: "",
            isShow: true,
            ishowPreferred: false
        });
    }
    public PharmacyList() {
        languagecontent = this.props.LanguageResoures;
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        var pharmacyLists: any = [];
        pharmacyLists = this.props.pharmacyList.PharmacyModel;
        if (type == "profile")
            var isloading: any = false;
        else
        var isloading: any = this.props.isPaymentProcessLoading ? true : this.props.isLoadingPharmacyList;
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container">
                        {
                            type == 'profile' ? "" : <HeaderDrProfile />
                        }
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="mb-2">{languagecontent['pharmacydescription']}</div>
                                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                            <div className="row form-row">
                                                <div className="col-lg-2 col-md-2">
                                                    {
                                                        languagecontent['state'] == undefined ? <TextBoxLoader /> :
                                                            <div className="form-group form-focus mb-2 focused">
                                                                <Field name="State" options={this.props.pharmacyStateLists} select={languagecontent['select']} component={renderStateSelectField} onChange={(e: any) => this.selectState(e)} className="form-control" />
                                                                <label className="focus-label" style={{ background: "transparent" }}>{languagecontent['state']}</label>
                                                            </div>
                                                    }
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <div className="form-group form-focus mb-2">
                                                        <Field name="City" component={renderField} label={languagecontent['city']} maxLength={150} type="text" />
                                                    </div>
                                                </div>
                                                <span style={{ marginTop: "8px", marginLeft: "8px", marginRight: "8px", marginBottom: "8px" }}>{languagecontent['or']}</span>
                                                <div className="col-lg-2 col-md-2">
                                                    <div className="form-group form-focus mb-2">
                                                        <Field name="Zipcode" component={renderField} label={languagecontent['postalcode']} maxLength={5} type="text" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="form-group form-focus mb-2">
                                                        <button type="submit" className="btn btn-lg btn-primary mr-2">{languagecontent['searchbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['searchbutton']}</button>
                                                        <button type="button" className="btn btn-lg btn-secondary" onClick={() => this.reset()}>{languagecontent['resetbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['resetbutton']}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.contact.pharmacyName == "" ? "" :
                                <div className="row" hidden={this.state.ishowPreferred}>
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                                <React.Fragment>
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead></thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <label className="custom_radio">
                                                                            <div className="cp_visit-width">
                                                                                <input type="radio" name="SelectPharmacyName" checked={true} value={this.props.contact.pharmacyId} /><span className="checkmark"></span> {this.props.contact.pharmacyName}
                                                                            </div>
                                                                        </label>
                                                                    </td>
                                                                    <td>
                                                                        {this.props.contact.pharmacyAddress}
                                                                    </td>
                                                                    <td>
                                                                        {this.props.contact.pharmacyCity}
                                                                    </td>
                                                                    <td>
                                                                        {this.props.contact.pharmacyState}
                                                                    </td>
                                                                    <td>
                                                                        {this.props.contact.pharmacyZipCode}
                                                                    </td>
                                                                    <td>
                                                                        {this.props.contact.pharmacyPhoneNo}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        
                        <div className="row" hidden={this.state.isShow}>
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            isloading == true ?
                                                <React.Fragment>
                                                    <ContentLoader
                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader> <br />
                                                    <ContentLoader
                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader><br />
                                                    <ContentLoader
                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader><br />
                                                    <ContentLoader
                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader><br />
                                                    <ContentLoader
                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader><br />
                                                    <ContentLoader
                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader>
                                                    <br />
                                                    <ContentLoader

                                                        title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                        height={54}
                                                        width={320}
                                                        viewBox="0 0 320 54"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <circle cx="27" cy="27" r="18" />
                                                        <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                        <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                        <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                        <circle cx="305" cy="27" r="8" />
                                                        <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                        <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                    </ContentLoader>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    {
                                                        pharmacyLists.length == 0 ? <p>{languagecontent['norecordfound']}</p> :
                                                            <React.Fragment>
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover table-center mb-0">
                                                                        <thead></thead>
                                                                        <tbody>
                                                                            {pharmacyLists.map((data: PharmacyListState.PharmacyModel) =>
                                                                                <tr>
                                                                                    <td>
                                                                                        <label className="custom_radio">
                                                                                            <div className="cp_visit-width">
                                                                                                <input type="radio" name="PharmacyName" value={data.Pharmacy.Id} onClick={() => this.selectPharmacyName(data.Pharmacy)} /><span className="checkmark"></span> {data.Pharmacy.Name}
                                                                                            </div>
                                                                                        </label>
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.Pharmacy.Address1}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.Pharmacy.City}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.Pharmacy.State}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.Pharmacy.Zipcode}
                                                                                    </td>
                                                                                    <td>
                                                                                        {data.Pharmacy.PhoneNo}
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {
                                                                    pharmacyLists.length >= 15 ?
                                                                        <React.Fragment>
                                                                            <div className="float-right">{this.state.currentPagecount} - {this.state.activePagecount} of {this.props.pharmacyList.TotalRecords}</div>
                                                                            <br /><br />
                                                                            <Pagination
                                                                                activePage={this.state.activePage}
                                                                                itemsCountPerPage={10}
                                                                                totalItemsCount={this.props.pharmacyList.TotalRecords}
                                                                                pageRangeDisplayed={10}
                                                                                onChange={this.handlePageChange.bind(this)}
                                                                            />
                                                                        </React.Fragment> : ""
                                                                }
                                                                 

                                                            </React.Fragment>
                                                    }
                                                </React.Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.props.contact.pharmacyName == "" &&  pharmacyLists.length == 0 ? "" :
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group form-focus submit-section ">
                                            <button className={"btn  btn-primary submit-btn float-right"} disabled={isloading} type="button" onClick={() => this.procced()}>{isloading ? <i className="fa fa-spinner fa-spin"></i> : "" } {type == "profile" ? languagecontent['submit'] : languagecontent['proceedbutton']}</button>
                                        </div>
                                    </div>
                                </div> 
                        }
                    </div>
                </div>
             </React.Fragment>

        )
    }
}
const mapDispatchToProps = ({ ...PharmacyStateList.actionCreators, ...PharmacyListState.actionCreators, ...ContactStoreState.actionCreators, ...PaymentProcessStore.actionCreators, ...LanguageResourcesStore.actionCreators });
const mapStateToProps = (state: ApplicationState) => {
    let values = {
        ...state.pharmacyStateLists, ...state.pharmacyLists, ...state.contact, ...state.paymentProcess, ...state.langResources
    };
    return values;
}
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'searchPharmacyList',
    enableReinitialize: true,
})(PharmacyList as any))


