import * as React from 'react';
import NavMenu from './menu/NavDefault';
import Footer from './footer/FtrDefault';
import BreadCrumbs from '../breadcrumbs/BreadCrumbs';
import NotifyBar from '../layout/topbar/Notify'
import GoogleAnalytics from '../layout/analytics/GoogleAnalytics'
import * as SiteDetailStore from '../../store/SiteDetail';
import * as NotifyBarStore from '../../store/library/topbar/notify';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';

type NavDefaultStoreProps = SiteDetailStore.SiteDetailState & NotifyBarStore.NotifyBarState & typeof SiteDetailStore.actionCreators & typeof NotifyBarStore.actionCreators;
var layoutColor: any = null;
var langdata: any = "";
var isAuthenticated: any = localStorage.getItem("isAuth");
let timer:any, currSeconds:any = 0;
class Layout extends React.PureComponent<NavDefaultStoreProps, { children?: React.ReactNode }> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched()
        layoutColor = localStorage.getItem('siteThemeColor');
        setTimeout(function () {
            if (layoutColor == null) {
                var cookies = document.cookie.split("; ");
                for (var c = 0; c < cookies.length; c++) {
                    var d = window.location.hostname.split(".");
                    while (d.length > 0) {
                        var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
                        var p = window.location.pathname.split('/');
                        document.cookie = cookieBase + '/';
                        while (p.length > 0) {
                            document.cookie = cookieBase + p.join('/');
                            p.pop();
                        };
                        d.shift();
                    }
                }
                window.location.reload(true);
            }

        }, 2000);
        switch (layoutColor) {
            case "NavTheraque":
                require("../../components/layout/menu/client/CPTheraque.css");
                break;
            case "NavVirtuvets":
                addCss1("/client/default/css/NavDefault.css");
                addCss("/client/default/css/NavVirtuvets.css");
                break;
            case "NavPrime":
                addCss1("/client/default/css/NavDefault.css");
                addCss("/client/default/css/NavPrime.css");
                break;
            case "NavSanthigram":
                addCss1("/client/default/css/NavDefault.css");
                addCss("/client/default/css/Santhigram.css");
                break;
            case "NavSmarthealthtec":
                addCss1("/client/default/css/NavDefault.css");
                addCss("/client/default/css/Smarthealthtec.css");
                break;
            case "Nav247DCT":
                addCss1("/client/default/css/NavDefault.css");
                addCss("/client/default/css/Nav247DCT.css");
                break;
            case "NavDefault":
                require("../../components/layout/menu/NavDefault.css");
            case "NavGoodoc":
                require("../../components/layout/menu/NavDefault.css");
            case "NavABC":
                require("../../components/layout/menu/NavDefault.css");
            case "NavWelshdale":
                require("../../components/layout/menu/NavDefault.css");
            case "NavMetaCare":
                require("../../components/layout/menu/NavDefault.css");
            case "NavVirtualCare":
                require("../../components/layout/menu/NavDefault.css");
            case "NavAmarac":
                require("../../components/layout/menu/NavDefault.css");
            
            default:
                return;
        }
        this.ensureDataFetched()


    }

    private ensureDataFetched() {
        this.props.requestSiteDetail();



        /*this.props.getSignalRCredential();*/
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");



    }
    public render() {
        return (
            <React.Fragment>
                {this.renderLayout()}
            </React.Fragment>
        );
    }
    public renderLayout() {
       // console.log("Layout Page");
        return (
            <React.Fragment>
                {
                    layoutColor == null ?
                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 500 300" height={"100%"} width={"100%"}>
                            <rect x="20" y="8" rx="0" ry="0" width="100" height="40" />
                            <circle cx="472" cy="16" r="8" />
                            <rect x="362" y="8" rx="7" ry="7" width="96" height="16" />

                            <rect x="200" y="39" rx="0" ry="0" width="34" height="8" />
                            <rect x="250" y="39" rx="0" ry="0" width="36" height="8" />
                            <rect x="300" y="39" rx="0" ry="0" width="34" height="8" />
                            <rect x="350" y="39" rx="0" ry="0" width="34" height="8" />
                            <rect x="400" y="39" rx="0" ry="0" width="36" height="8" />
                            <rect x="450" y="39" rx="0" ry="0" width="34" height="8" />

                            <rect x="19" y="64" rx="0" ry="0" width="465" height="155" />

                            <rect x="18" y="225" rx="0" ry="0" width="141" height="38" />
                            <rect x="182" y="225" rx="0" ry="0" width="141" height="38" />
                            <rect x="343" y="225" rx="0" ry="0" width="141" height="38" />
                            <rect x="18" y="270" rx="0" ry="0" width="141" height="38" />
                            <rect x="182" y="270" rx="0" ry="0" width="141" height="38" />
                            <rect x="343" y="270" rx="0" ry="0" width="141" height="38" />
                        </ContentLoader>
                        :
                        <React.Fragment>
                            <NotifyBar />
                            <NavMenu />
                            <BreadCrumbs />
                            <GoogleAnalytics/>
                            {this.props.children}
                            <Footer />
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.siteDetail, ...state.notifyBar }),
    ({ ...SiteDetailStore.actionCreators, ...NotifyBarStore.actionCreators })
)(Layout as any);


function addCss(fileName: any) {
    var head = document.head;
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;
    head.appendChild(link);
}
function addCss1(fileName: any) {
    var head = document.head;
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = fileName;
    head.appendChild(link);
}
//function startIdleTimer() {
//    axios.get('account/logout')
//        .then(function (response) {
//            localStorage.setItem("isAuth", "false");
//            localStorage.removeItem("Patient");
//            //localStorage.removeItem("isAuth");
//            localStorage.removeItem("pathName");
//            localStorage.removeItem("redirectUrl");
//            localStorage.removeItem("EmailId");
//            localStorage.removeItem("redirectQequestQueueUrl");
//            localStorage.removeItem("Appointment");
//            localStorage.removeItem("ProfileData");
//            localStorage.removeItem("contactUrl");
//            window.location.href = "/login";
//        })
//        .catch(function (error) {
//            errorHandling(error.response.data.statusCode);
//        })
//        .then(function () {
//        });
//    currSeconds++;
//}
//function resetTimer() {
//    clearInterval(timer);
//    currSeconds = 0;
//    timer =
//        setInterval(startIdleTimer, 2700000);
//}