import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';

export interface ChangePasswordState {
    isCPLoading: boolean;
    changePassword: ChangePassword;
}

export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

interface RequestChangeSignUp {
    type: 'REQUEST_CHANGE_PASSWORD';
    changePassword: ChangePassword;
}

interface ReceiveChangeSignUp {
    type: 'RECEIVE_CHANGE_PASSWORD';
    changePassword: ChangePassword;
}

type KnownAction = RequestChangeSignUp | ReceiveChangeSignUp;

export const actionCreators = {
    requeschangePassword : (userObj: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        dispatch(showLoading('sectionBar'))
        if (appState && appState.changePassword) {
            axios.post('account/changepassword', userObj)
                .then(function (response) {
                    dispatch(hideLoading('sectionBar'))
                    var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    toastr.success('', langdata['changedsuccessfully'])
                    const data = response.data;
                    dispatch({ type: 'RECEIVE_CHANGE_PASSWORD', changePassword: data });
                    dispatch(reset("changePasswordForm"));
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                        if (errorObject.ModelState != undefined) {
                            //if (errorObject.ModelState[""][0] == "Incorrect password.") {
                            //    toastr.error('', langdata['invalidcurrentpassword']);
                            //}
                            //else {
                          //toastr.error('', errorObject.ModelState[""][0]);
                            toastr.error('', langdata['incorrectpassword']);
                        }


                        else {
                            if (errorObject.Message == "Already using this password for the user. Change new password") {
                                toastr.error('', langdata['alreadyusingthispasswordfortheuser']);
                            } else {
                                toastr.error('', errorObject.Message);
                            }
                        }
                    
                } else {
            errorHandling(error.response.data.statusCode);
        }
                    dispatch({ type: 'RECEIVE_CHANGE_PASSWORD', isCPLoading: false });
                    dispatch({ ...hideLoading('sectionBar') })
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_CHANGE_PASSWORD' });
        }
    },
};

const UnloadedState: ChangePasswordState = {
    changePassword: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    },
    isCPLoading: false
};

export const reducer: Reducer<ChangePasswordState> = (state: ChangePasswordState | undefined, incomingAction: Action): ChangePasswordState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CHANGE_PASSWORD':
            return {
                changePassword: state.changePassword,
                isCPLoading: true
            };
        case 'RECEIVE_CHANGE_PASSWORD':
            return {
                changePassword: action.changePassword,
                isCPLoading: false
            };
        default:
            return state;
    }
};
