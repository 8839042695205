import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { actionCreators as _paymentAction } from '../patient/Transaction';
// STATE - This defines the type of data maintained in the Redux store.
export interface EncounterModuleState {
    encounterModules: EncounterModule[];
}
export interface EncounterModule {
    ModuleId: any,
    ModuleName: any,
    ClientTitle: any,
    ParentId: any,
    IsLibrary: any,
    FilePath: any,
    Order: any
}

export interface RequestEncounterModuleAction { type: 'REQUEST_ENCOUNTER_MODULE', encounterModules: EncounterModule[]}
export interface ReciveEncounterModuleAction { type: 'RECEIVE_ENCOUNTER_MODULE', encounterModules: EncounterModule[]}
export type KnownAction = RequestEncounterModuleAction | ReciveEncounterModuleAction;

export const actionCreators = {
    getEncounterModuleList: (patientid:any,encounterid:any, moduleid:any): AppThunkAction<KnownAction> => (dispatch: any, getState:any) => {
        const appState = getState();
        if (appState && appState.encounterModules) {
            axios.get('/Appointment/GetEncounterModule/' + patientid + "/" + encounterid + "/" + moduleid)
                .then(function (response) {
                    const result = response.data;

                    dispatch({ type: 'RECEIVE_ENCOUNTER_MODULE', encounterModules: result });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_ENCOUNTER_MODULE' });
        }
    },
};

const unloadedState: EncounterModuleState = {
    encounterModules:[]
};
export const reducer: Reducer<EncounterModuleState> = (state: EncounterModuleState | undefined, incomingAction: Action): EncounterModuleState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ENCOUNTER_MODULE':
            return {
                encounterModules: state.encounterModules,
            };
        case 'RECEIVE_ENCOUNTER_MODULE':
            return {
                encounterModules: action.encounterModules,
            };

        default:
            return state;
    }
};

