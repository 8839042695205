import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as SiteDetailStore from '../../store/SiteDetail';
type NavDefaultStoreProps = SiteDetailStore.SiteDetailState & typeof SiteDetailStore.actionCreators & RouteComponentProps;
var layout: any = "";
class BreadCrumbs extends React.PureComponent<NavDefaultStoreProps> {
	constructor(props: any) {
		super(props);
	}
	public render() {
		var data = window.location.pathname;
		const _array = data.split('/');
		window.name = localStorage.getItem("siteName") || "Karesmart";
		layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
		
		if (layout == undefined)
			layout = "";
        return (
			<React.Fragment>
				<div className={layout.Value == "NavDefault" ? "breadcrumb-bar" : layout.Value == "NavVirtuvets" ? "breadcrumb-bar vrbreadcrumb-bar" : layout.Value == "NavTheraque" ? "breadcrumb-bar" : layout.Value == "NavPrime" ? "breadcrumb-bar" : layout.Value == "NavGoodoc" ? "breadcrumb-bar" : layout.Value == "NavABC" ? "breadcrumb-bar" : layout.Value == "NavWelshdale" ? "breadcrumb-bar" : layout.Value == "NavMetaCare" ? "breadcrumb-bar" : layout.Value == "NavSanthigram" ? "breadcrumb-bar" : layout.Value == "Nav247DCT" ? "breadcrumb-bar" : layout.Value == "NavSmarthealthtec" ? "breadcrumb-bar" : layout.Value == "NavVirtualCare" ? "breadcrumb-bar" : layout.Value == "NavAmarac" ? "breadcrumb-bar" : ""} id="breadcrumb-bar">
						<div className="container-fluid">
							<div className="row align-items-center">
								<div className="col-md-12 col-12">
									<h2 className="breadcrumb-title" id="breadcrumb-title"></h2>

									<nav aria-label="breadcrumb" className="page-breadcrumb">
										<ol className="breadcrumb">
											<li className="breadcrumb-item active" id="description" aria-current="page"></li>
										</ol>
									</nav>
								</div>
							</div>
						</div>
					</div>
            </React.Fragment>
        );
    }
};

export default connect(
	(state: ApplicationState) => ({ ...state.siteDetail}), // Selects which state properties are merged into the component's props

)(BreadCrumbs as any);
