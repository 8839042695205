import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import * as SiteDetailStore from '../../../store/SiteDetail';
import * as AccountStore from '../../../store/siteaccount/Account';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';
type LanguageListStoreProps = AccountStore.AccountState & LanguageResourcesStore.LanguageResoures & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps;
class MobLanguageList extends React.PureComponent<LanguageListStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
        this.setState({
            Name: localStorage.getItem("DefaultLangCode") == "en-us" ? "English" : "Spanish",
            Code: "",
            Check: true
        });
    }
    state = {
        Name: "",
        Check: false
    };
   
    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
    }
    changeLanguage = (value: any) => {
        var languagecode: any = value == "English" ? "en-us" : "es-cr";
        localStorage.setItem("DefaultLangCode", languagecode);
        this.setState({
            Name: value,
            Code: languagecode,
            Check: true
        });

        if (localStorage.getItem('isAuth') == 'true') {
            this.props.prefferedLanguage();
        }

         setTimeout(async function () {
                        await window.location.reload(true);
                    }, 1000);
    }

    public renderDoctorList() {
        var languagelists: any = []
        var _languagelists: any = []
        languagelists = this.props.Settings.filter((s: any) => s.name == "Language")[0];
        if (languagelists != undefined) {
            languagelists = languagelists.customizeValue.split(';');
            languagelists.forEach((element: any) => {
                var data = element.split('|');
                _languagelists.push({ Name: data[1], Code: data[0] });
            });
        } else {
            languagelists = [];
        }
        if (this.state.Check == false) {
            var defaultlanguage: any = this.props.Settings.filter((s: any) => s.name == "Default Language")[0];
            if (defaultlanguage != undefined) {
                defaultlanguage = defaultlanguage.customizeValue.split('|');
                this.setState({
                    Name: defaultlanguage[1],
                });
            } else {
                defaultlanguage = "";
            }
        }
        var langname: any = localStorage.getItem("DefaultLangCode") == "en-us" ? "English" : "Spanish";
        return (
            <React.Fragment>
                {
                    _languagelists.length <= 1 ? "" :
                        <div className="nav-item dropdown has-arrow logged-item " style={{ marginLeft: "5px" }}>
                            <a style={{ fontSize: "16px", color: "#272b41" }} className={_languagelists.length == 1 ? "nav-link p-0 active" : "dropdown-toggle nav-link p-0"} data-toggle="dropdown">
                                {langname == "English" ? "EN" : "ES"}
                            </a>
                            {
                                _languagelists.length == 1 ? "" :
                                    <div className="dropdown-menu " style={{ position: "absolute", top: '100%', right: "1px", left: 'auto' }}>
                                        {
                                            _languagelists.map((languagelist: any) =>
                                                <a className={this.state.Name == languagelist.Name ? "dropdown-item active" : "dropdown-item"} onClick={() => this.changeLanguage(languagelist.Name)}>{languagelist.Name == "English" ? "EN-English" : "ES-Español"}</a>
                                            )
                                        }
                                    </div>
                            }

                        </div>
                }

            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.account, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators }) // Selects which action creators are merged into the component's props
)(MobLanguageList as any);

