import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import Moment from 'moment';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);

export interface ProfileState {
    isLoading: boolean;
    contact: Contact;
    Locations: Location[]
}

export interface Location {
    Id: string;
    Name: string;
    Description: string;
}

export interface Contact {
    id: string;
    memberId: string;
    firstName: string;
    LastName: string;
    dOB: Date;
    gender: string;
    address1: string;
    address2: string;
    locationId: string;
    zipcode: string;
    cellPhone: string;
    email: string;
    bloodGroup: string;
    isEmail: boolean;
    isSMS: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestContactAction { type: 'REQUEST_PATIENT_PROFILE', Contact: Contact, Locations: Location[] }
export interface ReciveContactAction { type: 'RECEIVE_PATIENT_PROFILE', Contact: Contact, Locations: Location[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestContactAction | ReciveContactAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSaveContact: (data: any, member: any, loactionid: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {

        data.MemberId = member.memberid;
        data.LocationId = loactionid;
        data.FirstName = patient.FirstName;
        data.LastName = patient.LastName;
        data.DOB = Moment(data.DOB).format();
        dispatch(showLoading('sectionBar'));
        axios.post('patient/profileupdate', data)
            .then(response => {
                dispatch(reset("contactForm"));
                dispatch(hideLoading('sectionBar'))
                const result = response.data;
                dispatch({ type: 'RECEIVE_PATIENT_PROFILE', Contact: "" });
                dispatch(push("/dashboard"))
            })
            .catch(error => {
                dispatch(hideLoading('sectionBar'))
                errorHandling(error.response.data.statusCode);
            });
    },
    requestLocation: (keyword: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        axios.get('/autocomplete/searchlocation/' + keyword)
            .then(function (response) {
                const result = response.data;
                dispatch({ type: 'RECEIVE_PATIENT_PROFILE', Locations: result });
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
            })
            .then(function () {
                // always executed
            });
        dispatch({ type: 'REQUEST_PATIENT_PROFILE' });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: ProfileState = {
    contact: {
        id: "",
        memberId: "",
        firstName: "asdsadsadasd",
        LastName: "sdfgsdfgsdfgsd fgsd gds",
        dOB: new Date(),
        gender: "",
        address1: "",
        address2:"",
        locationId: "",
        zipcode: "",
        cellPhone: "",
        email: "",
        bloodGroup: "",
        isEmail: false,
        isSMS: false,
    },
    Locations: [],
    isLoading: false
};
export const reducer: Reducer<ProfileState> = (state: ProfileState | undefined, incomingAction: Action): ProfileState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PATIENT_PROFILE':
            return {
                contact: state.contact,
                Locations: state.Locations,
                isLoading: true
            };
        case 'RECEIVE_PATIENT_PROFILE':
            return {
                contact: action.Contact,
                Locations: action.Locations,
                isLoading: false
            };
        default:
            return state;
    }
};
