import axios from 'axios';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DoctorListsState {
    isLoading: boolean;
    startDateIndex?: number;
    doctorlists: DoctorList[];
}

export interface DoctorList {
    doctorId: string;
    prefix: string;
    suffix: string;
    gender: string;
    doctorName: string;
    accountName: string;
    location: string;
    accountId: string;
    serviceId: string;
    photo: string;
    facilityName: string;
    price: number;
    isPreferred: string;
    speciality: Specialities[];
    flag: boolean;
    minPrice: number;
    doctorServiceDetails: any;
}
export interface Specialities {
    id: string;
    name: string;
}



// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

 interface RequestDoctorListsAction {
    type: 'REQUEST_DOCTORLIST_FORECASTS';
    startDateIndex: number;
}

 interface ReceiveDoctorListsAction {
    type: 'RECEIVE_DOCTORLIST_FORECASTS';
    startDateIndex: number;
    doctorlists: DoctorList[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDoctorListsAction | ReceiveDoctorListsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestDoctorLists: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        const urlParams = new URLSearchParams(window.location.search);
        const specialityid = localStorage.getItem("SpecialityId") != null ? localStorage.getItem("SpecialityId") : urlParams.get('specialityid');
        if (appState && appState.doctorLists && startDateIndex !== appState.doctorLists.startDateIndex && accountid != "") {
            axios.get('/doctor/getdoctor/' + accountid, { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const users = response.data;                                  
                    localStorage.removeItem("AppointmentOn");
                    localStorage.removeItem("AppointmentTime");
                    localStorage.removeItem("AppointmentTime1");
                    localStorage.removeItem("AppointmentOn1");
                    localStorage.removeItem("AppointmentBookedOn");
                    localStorage.removeItem("AppointmentUTCTime")
                    localStorage.removeItem("AppointmentUTCTime1")
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_DOCTORLIST_FORECASTS', startDateIndex: startDateIndex, doctorlists: users});
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTORLIST_FORECASTS', startDateIndex: startDateIndex});
        }
    },

    requestSearchDoctorLists: (formvalues: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var languagecode: any = localStorage.getItem("DefaultLangCode");

        const urlParams = new URLSearchParams(window.location.search);
        var specialityid = localStorage.getItem("SpecialityId") != null ? localStorage.getItem("SpecialityId") : urlParams.get('specialityid');
        //var specialityid = formvalues.specialityid;
        if (specialityid == "")
            specialityid = null
        var Selectdate = formvalues.Selectdate;
        if (Selectdate == undefined)
            Selectdate = null;
        var doctorName = formvalues.doctorName;
        if (doctorName == undefined)
            doctorName = "-";
        var gender = formvalues.gender;
        if (gender == undefined)
            gender = "-";

        if (appState && appState.doctorLists && accountid != "") {
            axios.get('/doctor/GetSearchDoctor/' + accountid + "/" + specialityid + "/" + Selectdate + "/" + doctorName + "/" + gender + "/" + languagecode, { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const users = response.data;                  
                    localStorage.removeItem("AppointmentOn");
                    localStorage.removeItem("AppointmentTime");
                    localStorage.removeItem("AppointmentUTCTime")                    
                    localStorage.removeItem("AppointmentBookedOn");
                    dispatch({ type: 'RECEIVE_DOCTORLIST_FORECASTS', doctorlists: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTORLIST_FORECASTS' });
        }
    },

    NavigationUrl: (id: any): any => (dispatch: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const childid = urlParams.get('childid');
        const name = urlParams.get('name');
        const childname = urlParams.get('childname');
        const specialityid = urlParams.get('specialityid') || urlParams.get('specialityid');
        if (childid == null)
            dispatch(push("/doctorprofile/" + id + "?specialityid=" + specialityid));
        else
            dispatch(push("/doctorprofile/" + id + "?childid=" + childid + "&childname=" + childname + "&specialityid=" + specialityid));
    },
    NavigationUrlBA: (id: any): any => (dispatch: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const childid = urlParams.get('childid');
        const name = urlParams.get('name');
        const childname = urlParams.get('childname');
        const specialityid = localStorage.getItem("SpecialityId") || urlParams.get('specialityid');
        if (childid == null)
            dispatch(push("/booking/" + id + "?specialityid=" + specialityid));
        else
            dispatch(push("/booking/" + id + "?childid=" + childid + "&childname=" + childname + "&specialityid=" + specialityid));
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DoctorListsState = { doctorlists: [], isLoading: false };

export const reducer: Reducer<DoctorListsState> = (state: DoctorListsState | undefined, incomingAction: Action): DoctorListsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DOCTORLIST_FORECASTS':
            return {
                startDateIndex: action.startDateIndex,
                doctorlists: state.doctorlists,
                isLoading: true
            };
        case 'RECEIVE_DOCTORLIST_FORECASTS':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.startDateIndex === state.startDateIndex) {
                return {
                    startDateIndex: action.startDateIndex,
                    doctorlists: action.doctorlists,
                    isLoading: false
                };
            }
            break;
    }

    return state;
};
