import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';
import Moment from 'moment';
export interface PaymentProcessState {
    isLoading: boolean;
    isPaymentProcessLoading: boolean;
    paymentdetail: PaymentDetail;
}

export interface PaymentDetail {
    Amount: string,
    CancelUrl: string,
    ClientId: string,
    Currency: string,
    CustomerAddress: string,
    CustomerName: string,
    Email: string,
    OrderID: string,
    Phone: string,
    RedirectUrl: string,
    ReturnUrl: string,
    CurrencyDisplayFormat: string
}


interface RequestPaymentProccessAction {
    type: 'REQUEST_PAYMENTDETAIL_SUCCESS';
    isLoading: boolean;
    isPaymentProcessLoading: boolean;
}

interface ReceivePaymentProccessAction {
    type: 'RECEIVE_PAYMENTDETAIL_SUCCESS';
    paymentdetail: PaymentDetail;
    isLoading: boolean;
    isPaymentProcessLoading: boolean;
}

type KnownAction = RequestPaymentProccessAction | ReceivePaymentProccessAction;

export const actionCreators = {
    requestPostPaymentProcess: (route: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var currency: any = localStorage.getItem("currency");
        localStorage.removeItem("SpecailityReasonId");
        localStorage.removeItem("SpecailityReasonName")
        if (appState && appState.paymentProcess) {
            axios.get('/payment/KarePay/' + route.doctorid + "/" + route.appointmentid + "/" + route.patientname + "/" + route.email + "/" + currency)
                .then(function (response) {
                    const data = response.data;
                    dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', paymentdetail: data, isLoading: false, isPaymentProcessLoading: false });
                    setTimeout(function () {
                        var doc = document.forms
                        if (doc != null) {
                            localStorage.setItem("PaymentRetry", window.location.href);
                            doc[0].submit();
                        }
                    }, 5000);
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PAYMENTDETAIL_SUCCESS', isLoading: true, isPaymentProcessLoading: true});
        }
    },
    requestPaymentlinkValidation: (route: any, memberid:any): AppThunkAction<KnownAction> => (dispatch:any, getState:any) => {
        const appState = getState();
        if (appState && appState.paymentProcess) {
            var langcode: any = localStorage.getItem("DefaultLangCode");
            axios.get('/payment/PaymentLinkStatus/' + route.appointmentid + '/' + memberid +'/'+ langcode)
                .then(function (response) {
                    const message = response.data;
                   
                    if (message == "Active") {
                        dispatch(actionCreators.requestValidPostPaymentProcess())
                    } else if (message == "Access denied, contact support.") {
                        window.location.href = "/accessdenied"
                    }
                    else {
                        toastr.error('', message)
                        setTimeout(function () {
                            window.location.href = "/dashboard"
                        }, 5000);
                    }
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
        }
    },
    requestValidPostPaymentProcess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var currency: any = localStorage.getItem("currency");
        if (appState && appState.paymentProcess) {
            var array = window.location.pathname.split("/");
            var doctorid = array[2];
            var appointmentid = array[3];
            var patientname = array[4];
            var email = array[5];
            axios.get('/payment/KarePay/' + doctorid + "/" + appointmentid + "/" + patientname + "/" + email + "/" + currency)
                .then(function (response) {
                    const data = response.data;
                    dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', paymentdetail: data, isLoading: false, isPaymentProcessLoading: false});
                    setTimeout(function () {
                        var doc = document.forms
                        if (doc != null) {
                            doc[0].submit();
                        }
                    }, 5000);
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PAYMENTDETAIL_SUCCESS', isLoading: true, isPaymentProcessLoading: true });
        }
    },
    requestInsurancePay: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.paymentProcess) {
            dispatch(actionCreators.requestScheduleUpdate());
        }
    },
    requestScheduleUpdate: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.paymentProcess) {
            const urlParams = new URLSearchParams(window.location.search);
            const appointmentid = urlParams.get('appointmentid');
            const methodid = urlParams.get('methodid');
            var autoapprovel: any = appState.account.Settings.filter((se: any) => se.name == "Appointment Auto Approval")[0];
            var autoproviderselect: any = appState.account.Settings.filter((se: any) => se.name == "Auto Provider Selection in PickaProvider")[0];
            var statusid: any = "";
            if (autoproviderselect.customizeValue == "No") {
                if (methodid == "2") {
                    statusid = "9";
                } else if (methodid == "3") {
                    statusid = "9";
                }
                else if (methodid == "1") {
                    if (autoapprovel.customizeValue == "Yes") {
                        statusid = "1";
                    } else{
                        statusid = "9";
                    }
                }
            } else{
                if (autoapprovel.customizeValue == "Yes") {
                    if (methodid == "3") {
                        statusid = "9";
                    }
                    else if (methodid == "2") {
                        statusid = "1";
                    }
                    else if (methodid == "1"){
                        statusid = "1";
                    }
                } else {
                    statusid = "9";
                }
                if (autoapprovel.customizeValue == "No") {
                    statusid = "9";
                }
            }
            var discountid: any = localStorage.getItem("DiscountId");
            var discountamount: any = localStorage.getItem("DiscountAmount");
            var data: any = {
                Id: appointmentid,
                StatusId: statusid,
                DiscountId: discountid,
                DiscountAmount: discountamount
            }
            axios.post('/appointment/ScheduleUpdate', data)
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', isLoading: false });
                    dispatch(push("/success/" + appointmentid))
                })
                .catch(function (error) {
                    dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', isLoading: false });
                    if (error.response && error.response.data) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.Message == "No Changes") {
                            dispatch(push("/success/" + appointmentid))
                        } else {
                            dispatch(push("/failed/" + appointmentid))
                        }
                    } else {
                        console.log(error)
                        dispatch(push("/failed/" + appointmentid))
                    }
                    //var errorObject = JSON.parse(error.response.data.errorMessage);
                    //if (errorObject.Message == "No Changes") {
                    //    dispatch(push("/success/" + appointmentid))
                    //} else {
                    //    dispatch(push("/failed/" + appointmentid))
                    //}
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PAYMENTDETAIL_SUCCESS', isLoading: true });
        }
    },
    requestScheduleUpdateFree: (appointmentid:any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.paymentProcess) {
            const urlParams = new URLSearchParams(window.location.search);
            var methodid = urlParams.get('methodid');
            if (methodid == "5") {
                methodid = "3";
            } else {
                methodid = methodid;
            }
            var autoapprovel: any = appState.account.Settings.filter((se: any) => se.name == "Appointment Auto Approval")[0];
            var autoproviderselect: any = appState.account.Settings.filter((se: any) => se.name == "Auto Provider Selection in PickaProvider")[0];
            var statusid: any = "";
            if (autoproviderselect.customizeValue == "No") {
                if (methodid == "2") {
                    statusid = "9"
                } else if (methodid == "3") {
                    statusid = "9"
                }
                else if (methodid == "1") {
                    if (autoapprovel.customizeValue == "Yes") {
                        statusid = "1";
                    } else {
                        statusid = "9";
                    }
                }
            } else {
                if (autoapprovel.customizeValue == "Yes") {
                    if (methodid == "3") {
                        statusid = "9";
                    }
                    else if (methodid == "2") {
                        statusid = "1";
                    }
                    else if (methodid == "1") {
                        statusid = "1";
                    }
                } else {
                    statusid = "9"
                }
            }
            var discountid: any = localStorage.getItem("DiscountId");
            var discountamount: any = localStorage.getItem("DiscountAmount");
            var data: any = {
                Id: appointmentid,
                StatusId: statusid,
                DiscountId: discountid,
                DiscountAmount: discountamount
            }
            axios.post('/appointment/ScheduleUpdate', data)
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', isLoading: false, isPaymentProcessLoading: false });
                    dispatch(push("/success/" + appointmentid))
                })
                .catch(function (error) {
                    dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', isLoading: false, isPaymentProcessLoading: false });
                    //var errorObject = JSON.parse(error.response.data.errorMessage);
                    //if (error.response && error.response.data) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.Message == "No Changes") {
                            dispatch(push("/success/" + appointmentid))
                       } 
                    //        else {
                    //        dispatch(push("/failed/" + appointmentid))
                    //    }
                    //} 
                            else {
                        console.log(error)
                            errorHandling(error.response.data.statusCode);
                        }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PAYMENTDETAIL_SUCCESS', isLoading: true, isPaymentProcessLoading:true });
        }
    }
}; 


const UnloadedState: PaymentProcessState = {
    paymentdetail: {
        Amount: "",
        CancelUrl: "",
        ClientId: "",
        Currency: "",
        CustomerAddress: "",
        CustomerName: "",
        Email: "",
        OrderID: "",
        Phone: "",
        RedirectUrl: "",
        ReturnUrl: "",
        CurrencyDisplayFormat:""
    }, isLoading: false, isPaymentProcessLoading: false
};


export const reducer: Reducer<PaymentProcessState> = (state: PaymentProcessState | undefined, incomingAction: Action): PaymentProcessState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PAYMENTDETAIL_SUCCESS':
            return {
                paymentdetail: state.paymentdetail,
                isLoading: true,
                isPaymentProcessLoading: true,
            };
        case 'RECEIVE_PAYMENTDETAIL_SUCCESS':
            return {
                paymentdetail: action.paymentdetail,
                isLoading: false,
                isPaymentProcessLoading:false
            };
        default:
            return state;
    }
};

