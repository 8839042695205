import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';
import Moment from 'moment';

const urlParams = new URLSearchParams(window.location.search);
const childid = urlParams.get('childid');
const childname = urlParams.get('childname');

export interface PayMethodState {
    isPayLoading: boolean;
    paymethod: Paymethod;
}

export interface Paymethod {
}


interface RequestPaymethodAction {
    type: 'REQUEST_PAY_METHOD';
    paymethod: Paymethod;
    isPayLoading: boolean;
}

interface ReceivePaymethodAction {
    type: 'RECEIVE_PAY_METHOD';
    paymethod: Paymethod;
    isPayLoading: boolean;
}

type KnownAction = RequestPaymethodAction | ReceivePaymethodAction;

export const actionCreators = {
    updatePaymethod: (paymethodid: any): AppThunkAction<KnownAction> => (dispatch:any, getState:any) => {
        const appState = getState();
        if (appState && appState.paymethod) {
            if (paymethodid == "1") {
                dispatch(actionCreators.requestScheduleUpdate(paymethodid));
            } else if (paymethodid == "2") {
                dispatch(actionCreators.requestScheduleUpdate(paymethodid));
            } else if (paymethodid == "3") {
                dispatch(actionCreators._requestScheduleUpdate(paymethodid));
            } else {
                return;
            }
        }
    },
    requestScheduleUpdate: (paymethodid:any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.paymentProcess) {
            const urlParams = new URLSearchParams(window.location.search);
            const appointmentid = urlParams.get('appointmentid');
            const doctorid = urlParams.get('doctorid');
            const email = urlParams.get('email');
            const name = urlParams.get('name');
            const methodid = urlParams.get('methodid');
            var data: any = {
                Id: appointmentid,
                PayMethodId: paymethodid
            }
            axios.post('/appointment/ScheduleUpdate', data)
                .then(function (response) {
                    if (paymethodid == "1") {
                        dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false  });
                        dispatch(push("/insurancelist?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + name + "&email=" + email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                    } else if (paymethodid == "2") {
                        dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false });
                        dispatch(push("/paymentprocess/" + doctorid + "/" + appointmentid + "/" + name + "/" + email));
                    } else {
                        dispatch({ type: 'RECEIVE_PAY_METHOD' });
                        return;
                    }
                })
                .catch(function (error) {
                    dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false });
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    if (errorObject.Message == "No Changes") {
                        if (paymethodid == "1") {
                            dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false });
                            dispatch(push("/insurancelist?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + name + "&email=" + email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                        } else if (paymethodid == "2") {
                            dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false });
                            dispatch(push("/paymentprocess/" + doctorid + "/" + appointmentid + "/" + name + "/" + email));
                        } else {
                            dispatch({ type: 'RECEIVE_PAY_METHOD' });
                            return;
                        }
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PAY_METHOD', isPayLoading:true});
        }
    },
    _requestScheduleUpdate: (paymethodid: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.paymentProcess) {
            const urlParams = new URLSearchParams(window.location.search);
            const appointmentid = urlParams.get('appointmentid');
            const methodid = urlParams.get('methodid');
            var autoapprovel: any = appState.account.Settings.filter((se: any) => se.name == "Appointment Auto Approval")[0];
            var autoproviderselect: any = appState.account.Settings.filter((se: any) => se.name == "Auto Provider Selection in PickaProvider")[0];
            var statusid: any = "";
            if (autoproviderselect.customizeValue == "No") {
                if (methodid == "2" || methodid == "1") {
                    statusid = "9"
                } 
            } else {
                if (autoapprovel.customizeValue == "Yes") {
                    if (methodid == "3") {
                        statusid = "9"
                    } else {
                        statusid = "1"
                    }
                } else {
                    statusid = "9"
                }
            }
            
            var data: any = {
                Id: appointmentid,
                StatusId: statusid,
                PayMethodId: paymethodid
            }
            axios.post('/appointment/ScheduleUpdate', data)
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false });
                    dispatch(push("/success/" + appointmentid))
                })
                .catch(function (error) {
                    dispatch({ type: 'RECEIVE_PAY_METHOD', isPayLoading: false });
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    if (errorObject.Message == "No Changes") {
                        dispatch(push("/success/" + appointmentid))
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
        }
        dispatch({ type: 'REQUEST_PAY_METHOD', isPayLoading: true });
    },
    redirectback: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState: any = getState();
        if (appState && appState.account) {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patientstore: any = JSON.parse(patientLocalStroage);
            const urlParams = new URLSearchParams(window.location.search);
            const methodid = urlParams.get('methodid');
            const appointmentid = urlParams.get('appointmentid');
            const doctorid = urlParams.get('doctorid');
            const childid = urlParams.get('childid');
            const childname = urlParams.get('childname');
            var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
            if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                dispatch(push("/pharmacylist?types=appointment&memberid=" + patientstore.memberId + "&appointmentid=" + appointmentid + "&doctorid=" + doctorid + "&type=appointment&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname));
            }
            else
                window.history.go(-1);
        }
    }
};


const UnloadedState: PayMethodState = {
    paymethod: {},
    isPayLoading: false
};


export const reducer: Reducer<PayMethodState> = (state: PayMethodState | undefined, incomingAction: Action): PayMethodState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PAY_METHOD':
            return {
                paymethod: state.paymethod,
                isPayLoading: true
            };
        case 'RECEIVE_PAY_METHOD':
            return {
                paymethod: action.paymethod,
                isPayLoading: false
            };
        default:
            return state;
    }
};
function toDate(date: any) {
    if (date != null) {
        var currentDateTime = new Date(date);
        return currentDateTime
    }
}
function DisplayCurrentTime(datetime: Date) {
    var date = datetime;
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return (hours + ":" + minutes + ":" + seconds);
};
const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}