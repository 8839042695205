import * as WeatherForecasts from './WeatherForecasts';
import * as Counter from './Counter';
import * as DoctorLists from './doctor/OurDoctors';
import * as Contact from './patient/Contact';
import * as  HeaderDrProfile from './widget/HeaderDrProfile';
import * as  NavDefault from './menu/NavDefault';
import * as  DashboardNav from '../store/menu/DashboardNav';
import * as  DoctorProfile from '../store/doctor/DoctorProfile';
import * as  Booking from '../store/patient/Booking';
import * as  Condition from '../store/appointment/Condition';
import * as  Success from '../store/payment/Success';
import * as  Fail from '../store/payment/Failed';
import * as  SignUp from '../store/account/Signup';
import * as  Login from '../store/account/Login';
import * as  ChangePassword from '../store/account/ChangePassword';
import * as  Dashboard from '../store/patient/Dashboard';
import * as  Profile from './patient/ProfileSettings';
import * as  SiteDetail from './SiteDetail';
import * as  ClientData from './ClientData';
import * as  Payment from '../store/payment/Payment';
import * as  PaymentProcess from '../store/payment/PaymentProcess';
import * as  Location from '../store/location/location';
import * as  Account from '../store/siteaccount/Account';
import * as  ForgotPassword from '../store/account/ForgotPassword';
import * as  DocumentUpload from '../store/library/document/DocumentUpload';
import * as  DocumentList from '../store/library/document/DocumentList';
import * as  Category from '../store/siteaccount/Category';
import * as  FlexiForm from '../store/library/flexiform/FlexiForm';
import * as  AppointmentType from '../store/patient/AppointmentType';
import * as  EncounterType from '../store/patient/EncounterType';
import * as  VisitDetail from '../store/appointment/VisitDetail';
import * as  Transactions from '../store/patient/Transaction';
import * as  FamilyMember from '../store/family/familyMember';
import * as  LangResources from '../store/LanguageResoures';
import * as  SiteMenus from '../store/siteaccount/siteMenu';
import * as  InsuranceAutoComplete from '../store/autoComplete/insurance';
import * as  TimeZoneAutoComplete from '../store/autoComplete/timeZone';
import * as  AddInsurance from '../store/insurance/addInsurance';
import * as  InsuranceList from '../store/insurance/insuranceList';
import * as  PayMethod from '../store/payment/PayMethod';
import * as  EncounterModuleList from '../store/appointment/encounterModule';
import * as  PharmacyLists from '../store/pharmacy/PharmacyList';
import * as  PharmacyStateLists from '../store/pharmacy/StateList';
import * as  PublicContent from '../store/PublicContent';
import * as  GetProviderId from '../store/patient/getProviderId';
import * as  AppointmentChildLists from '../store/patient/AppointmentChildList';
import * as  CancelAppointment from '../store/appointment/CancelAppointmentModel';
import * as  specialityList from '../store/menu/OurDoctorsNav';
import * as  MenuHide from '../store/menuHide';
import * as  MemberSpeciality from '../store/menu/speciality';
import * as  Reschedule from '../store/appointment/Reschedule';
import * as  NotifyBar from '../store/library/topbar/notify';
import * as  Message from '../store/patient/Message';
import * as  AddMessage from '../store/message/addMessage';
import * as  EditMesssage from '../store/message/editMessage';
import * as  SpecialityReason from '../store/menu/specialityreason';
import * as  Ftr247DCT from '../store/menu/Ftr247DCT';
import * as  PrintDetail from '../store/appointment/Prescription';
import * as  SpecialityQuestionnaire from '../store/library/questionnaire/SpecialityQuestion';
import * as  SurveyForm from '../store/survey/SurveyForm';

// The top-level state object
export interface ApplicationState {
    flexiform: FlexiForm.FlexiListsState | undefined;
    counter: Counter.CounterState | undefined;
    contact: Contact.ContactState | undefined;
    weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
    doctorLists: DoctorLists.DoctorListsState | undefined;
    headerDrProfile: HeaderDrProfile.HeaderDrProfileState | undefined;
    navDefault: NavDefault.NavDefaultState | undefined;
    drBioData: DoctorProfile.DoctorBioState | undefined;
    drDoctorTimings: DoctorProfile.DoctorTimingState | undefined;
    booking: Booking.BookingState | undefined;
    condition: Condition.ConditionState | undefined;
    success: Success.SuccessState | undefined;
    fail: Fail.FailState | undefined;
    signup: SignUp.SignUpState | undefined;
    login: Login.LoginState | undefined;
    dashboardNav: DashboardNav.DashboardNavState | undefined;
    dashboard: Dashboard.DashboardState | undefined;
    profile: Profile.ProfileState | undefined;
    changePassword: ChangePassword.ChangePasswordState | undefined;
    siteDetail: SiteDetail.SiteDetailState | undefined;
    clientData: ClientData.ClientDataState | undefined;
    Payment: Payment.PaymentState | undefined;
    paymentProcess: PaymentProcess.PaymentProcessState | undefined;
    location: Location.LocationState | undefined;
    account: Account.AccountState | undefined;
    forgotPassword: ForgotPassword.ForgotPasswordState | undefined;
    documentupload: DocumentUpload.DocumentUploadState | undefined;
    documentlist: DocumentList.DocumentListsState | undefined;
    category: Category.CategoryState | undefined;
    appointmenttypes: AppointmentType.AppointmentTypeState | undefined;
    encountertypes: EncounterType.EncounterTypeState | undefined;
    visitDetail: VisitDetail.VisitDetailState | undefined;
    transactionDetail: Transactions.TransactionDetailState | undefined;
    familyMembers: FamilyMember.FamilyMemberState | undefined;
    langResources: LangResources.LanguageResouresState | undefined;
    siteMenus: SiteMenus.SiteMenuState | undefined;
    insuranceAutoCompletes: InsuranceAutoComplete.InsuranceAutoCompleteState | undefined;
    addInsurance: AddInsurance.AddInsuranceState | undefined;
    insuranceLists: InsuranceList.InsuranceListState | undefined;
    paymethod: PayMethod.PayMethodState | undefined;
    encounterModules: EncounterModuleList.EncounterModuleState | undefined;
    pharmacyLists: PharmacyLists.PharmacyListState | undefined;
    pharmacyStateLists: PharmacyStateLists.PharmacyStateListState | undefined;
    timeZones: TimeZoneAutoComplete.TimeZoneState | undefined;
    publicContent: PublicContent.PublicContentState | undefined;
    getProviderId: GetProviderId.GetProviderIdState | undefined;
    appointmentChildLists: AppointmentChildLists.AppointmentChildState | undefined;
    menuHide: MenuHide.MenuHideState | undefined;
    cancelAppointment: CancelAppointment.CancelAppointmentState | undefined;
    speciality: specialityList.SpecialityState | undefined;
    memberSpecialitiesState: specialityList.MemberSpecialitiesState | undefined;
    memberSpeciality: MemberSpeciality.MemberSpecialityState | undefined;
    specialityreason: SpecialityReason.SpecialityReasonState | undefined;
    reschedule: Reschedule.RescheduleState | undefined;
    notifyBar: NotifyBar.NotifyBarState | undefined;
    messages: Message.MessageState | undefined;
    addMessage: AddMessage.AddMessageState | undefined;
    reciptentList: AddMessage.ReciptentListState | undefined;
    editMesssage: EditMesssage.EditMessageState | undefined;
    replyMessage: EditMesssage.ReplyMessageState | undefined;
    ftr247dct: Ftr247DCT.Ftr247DCTState | undefined;
    printDetail: PrintDetail.PrintDetailState | undefined;

    questionnaire: SpecialityQuestionnaire.QuestionnaireListsState | undefined;
    surveyform: SurveyForm.SurveyFormState | undefined;
}


// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    flexiform: FlexiForm.reducer,
    counter: Counter.reducer,
    contact: Contact.reducer,
    weatherForecasts: WeatherForecasts.reducer,
    doctorLists: DoctorLists.reducer,
    headerDrProfile: HeaderDrProfile.reducer,
    navDefault: NavDefault.reducer,
    drBioData: DoctorProfile.bioreducer,
    drDoctorTimings: DoctorProfile.timingreducer,
    booking: Booking.reducer,
    condition: Condition.reducer,
    success: Success.reducer,
    fail: Fail.reducer,
    signup: SignUp.reducer,
    login: Login.reducer,
    dashboardNav: DashboardNav.reducer,
    dashboard: Dashboard.reducer,
    profile: Profile.reducer,
    changePassword: ChangePassword.reducer,
    siteDetail: SiteDetail.reducer,
    clientData: ClientData.reducer,
    payment: Payment.reducer,
    paymentProcess: PaymentProcess.reducer,
    location: Location.reducer,
    account: Account.reducer,
    forgotPassword: ForgotPassword.reducer,
    documentupload: DocumentUpload.reducer,
    documentlist: DocumentList.reducer,
    category: Category.reducer,
    appointmenttypes: AppointmentType.reducer,
    encountertypes: EncounterType.reducer,
    visitDetail: VisitDetail.reducer,
    transactionDetail: Transactions.reducer,
    familyMembers: FamilyMember.reducer,
    langResources: LangResources.reducer,
    siteMenus: SiteMenus.reducer,
    insuranceAutoCompletes: InsuranceAutoComplete.reducer,
    addInsurance: AddInsurance.reducer,
    insuranceLists: InsuranceList.reducer,
    paymethod: PayMethod.reducer,
    encounterModules: EncounterModuleList.reducer,
    pharmacyLists: PharmacyLists.reducer,
    pharmacyStateLists: PharmacyStateLists.reducer,
    timeZones: TimeZoneAutoComplete.reducer,
    publicContent: PublicContent.reducer,
    getProviderId: GetProviderId.reducer,
    appointmentChildLists: AppointmentChildLists.reducer,
    menuHide: MenuHide.reducer,
    cancelAppointment: CancelAppointment.reducer,
    speciality: specialityList.reducer,
    memberSpecialitiesState: specialityList.memberreducer,
    memberSpeciality: MemberSpeciality.reducer,
    specialityreason: SpecialityReason.reducer,
    reschedule: Reschedule.reducer,
    notifyBar: NotifyBar.reducer,
    messages: Message.reducer,
    addMessage: AddMessage.addMessagereducer,
    reciptentList: AddMessage.reducer,
    editMesssage: EditMesssage.listreducer,
    replyMessage: EditMesssage.replyMessagereducer,
    ftr247dct: Ftr247DCT.reducer,    
    questionnaire: SpecialityQuestionnaire.reducer,
    printDetail: PrintDetail.reducer,
    surveyform: SurveyForm.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
