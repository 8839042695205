import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { toastr } from 'react-redux-toastr';

export interface SpecialityReasonState {
    isLoading: boolean;
    specialityReason: SpecialitiesReason[];
    isEmergency: boolean;
    emergencyDescription: string;
}

export interface SpecialitiesReason {
    id: string;
    reason: string;
}
interface RequestReason {
    type: 'REQUEST_REASON';
    
}
interface ReceiveReason {
    type: 'RECEIVE_REASON';
    specialityreason: SpecialitiesReason[];
    emergencyDescription: string;
    isEmergency: boolean;
}
type KnownAction = RequestReason | ReceiveReason;
declare var bootstrap: any;
export const actionCreators = {
    requestspecialityreason: (value: any): AppThunkAction<KnownAction> => async (dispatch: any, getState: any) => {
        const appState = getState();
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        var specialityid: any = value
        if (appState && appState.specialityreason && specialityid != "") {
            axios.get('/doctor/GetSpecialityReason/' + specialityid + "/" + languagecode).then(function (response) {
                const users = response.data;
                dispatch({ type: 'RECEIVE_REASON', specialityreason: users });
                if (users.specialityReason.length == 0) {
                    if (window.document.getElementById('emergencyModalCenter')) {
                        setTimeout(function () {
                            var myModal = new bootstrap.Modal(window.document.getElementById('emergencyModalCenter'), {
                                backdrop: 'static',
                                keyboard: false
                            })
                            myModal.show();
                        }, 100);
                    }
                } else {
                    if (window.document.getElementById('myModal')) {
                        setTimeout(function () {
                            var myModal = new bootstrap.Modal(window.document.getElementById('myModal'), {
                                backdrop: 'static',
                                keyboard: false
                            })
                            myModal.show();
                        }, 100);
                    }
                }
            })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_REASON' });
        }
    },
    requestspecialityreasons: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        var specialityid: any = value
        if (specialityid != "" || specialityid != null) {
            if (appState && appState.specialityreason && specialityid != "") {
                axios.get('/doctor/GetSpecialityReason/' + specialityid + "/" + languagecode).then(function (response) {
                    const users = response.data;
                    dispatch({ type: 'RECEIVE_REASON', specialityreason: users });

                })
                    .catch(error => {
                        if (error.response.data.statusCode == 400) {
                            var errorObject = JSON.parse(error.response.data.errorMessage);
                            toastr.error('', errorObject.error_description);
                        } else {
                            errorHandling(error.response.data.statusCode);
                        }
                    })
                    .then(function () {
                    });
                dispatch({ type: 'REQUEST_REASON' });
            }
        }
    },
}

const unloadedState: SpecialityReasonState = {
    specialityReason: [],
    isLoading: false,
    isEmergency: false,
    emergencyDescription: ""
};

export const reducer: Reducer<SpecialityReasonState> = (state: SpecialityReasonState | undefined, incomingAction: Action): SpecialityReasonState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_REASON':
            return {
                specialityReason: state.specialityReason,
                isLoading: true,
                isEmergency: state.isEmergency,
                emergencyDescription: state.emergencyDescription

            };

        case 'RECEIVE_REASON':
            return {
                specialityReason: action.specialityreason,
                isLoading: false,
                isEmergency: action.isEmergency,
                emergencyDescription: action.emergencyDescription
            };
        default:
            return state;
    }
};