import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import * as ProfileStore from '../../store/patient/ProfileSettings';
import * as LoginStore from '../../store/account/Login';
import * as HandleRequest from '../../helper/Request';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import ContactForm from '../widget/ContactForm';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { seo, floatJSBack, datepickerJS, floatingJS, floatJS} from '../../helper/Script';
import { scrollTop, setTittle } from '../../helper/formvalidation';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as AccountStore from '../../store/siteaccount/Account';

type ProfileProps =
	ProfileStore.ProfileState & LoginStore.LoginState & HandleRequest.Request & LanguageResourcesStore.LanguageResouresState & AccountStore.AccountState & typeof ProfileStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof AccountStore.actionCreators &
	RouteComponentProps<{}>;
var locationid = "";
var contact: ProfileStore.Contact;
var languagecontent: any = "";

class Profile extends React.PureComponent<ProfileProps> {
	constructor(props: any) {
		super(props);
	}
	componentDidMount() {
		this.props.getSiteSetting(null)
		scrollTop();
		floatJSBack();
		datepickerJS();
		floatingJS();
		floatJS();
		//seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
		const script = document.createElement("script");
		script.src = "/Theme/Doccure/admin/assets/js/script.js";
		script.async = true;
		window.document.body.appendChild(script);
		
	}
	
	componentDidUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
		
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderContact()}
			</React.Fragment>
		);
	}
	public renderContact() {
		    languagecontent = this.props.LanguageResoures;
			return (
				<React.Fragment>
					<div className="content">
						<div className="container-fluid">
							<div className="row">
								<DashboardNavMenu />
								<div className="col-md-7 col-lg-8 col-xl-9">
									<ContactForm />
								</div>
							</div>
						</div>

					</div>
				</React.Fragment>
			);
		}
}




export default connect(
	(state: ApplicationState) => ({ ...state.dashboardNav, ...state.login, ...state.profile, ...state.langResources, ...state.account }), // Selects which state properties are merged into the component's props
	({ ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ProfileStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...AccountStore.actionCreators   }) // Selects which action creators are merged into the component's props
)(Profile as any);