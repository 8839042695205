import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface SiteMenuState {
    siteMenus: siteMenu[]
}

export interface siteMenu {
    PageId: string;
    FriendlyUrl: string;
    PageName: string;
    Src: string;
    TypeId: string;
    Title: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestSiteMenuAction { type: 'REQUEST_SITEMENU', siteMenus: siteMenu[] }
export interface ReciveSiteMenuAction { type: 'RECEIVE_SITEMENU', siteMenus: siteMenu[] }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestSiteMenuAction | ReciveSiteMenuAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getSiteMenu: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        if (appState && appState.category) {
            axios.get('/site/GetSiteMenu/' + "2" + '/' + languagecode)
                .then(function (response) {
                    const theme_color = localStorage.getItem('siteThemeColor')
                    const result = response.data;

                    var homepage: any = result.filter((menu: any) => (menu.Title.toLowerCase() == 'home' || menu.PageId == "450c5fe5-2b34-4428-88fd-95f6f94949d2") && menu.TypeId == "1") || []

                    const isExternal = homepage.length > 0 ? homepage[0].Src.startsWith('http://') || homepage[0].Src.startsWith('https://') : '';

                   
                    if (isExternal) {
                        localStorage.setItem('isExternal', 'true')
                    } else {
                        localStorage.setItem('isExternal', 'false')
                    }

                    if (theme_color == 'NavVirtuvets') {
                        /*result.filter((list: any) => list.PageName == 'home')*/
                        result.map((list: any) => {
                            if (list.PageName == 'home') {
                                list.Src = 'https://virtuvets.com/'
                            }
                        })
                    }
                   
                    dispatch({ type: 'RECEIVE_SITEMENU', siteMenus: result });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_SITEMENU' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: SiteMenuState = {
    siteMenus: []
};
export const reducer: Reducer<SiteMenuState> = (state: SiteMenuState | undefined, incomingAction: Action): SiteMenuState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SITEMENU':
            return {
                siteMenus: state.siteMenus,
            };
        case 'RECEIVE_SITEMENU':
            return {
                siteMenus: action.siteMenus,
            };
        default:
            return state;
    }
};
