 import * as React from 'react';
import { NavLink, Link, RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as LoginStore from '../../../store/account/Login';
import * as ClientDataStore from '../../../store/ClientData';
import * as ContactStore from '../../../store/patient/Contact';
import * as SiteDetailStore from '../../../store/SiteDetail';
import * as AccountStore from '../../../store/siteaccount/Account';
import * as EncounterTypeStore from '../../../store/patient/EncounterType';
import * as FlexFromStore from '../../../store/library/flexiform/FlexiForm';
import NavVirtuvets from '../../../components/layout/menu/client/NavVirtuvets';
import NavDefault from '../../../components/layout/menu/client/NavDefault';
import NavTheraque from '../../../components/layout/menu/client/NavTheraque';
import NavPrime from '../../../components/layout/menu/client/NavPrime';
import NavGoodoc from '../../../components/layout/menu/client/NavGoodoc';
import NavABC from '../../../components/layout/menu/client/NavABC';
import NavWelshdale from '../../../components/layout/menu/client/NavWelshdale';
import Nav247DCT from '../../../components/layout/menu/client/Nav247DCT';
import NavVirtualCare from '../../../components/layout/menu/client/NavVirtualCare';
import FlexiForm from '../../library/flexiform/FlexiForm';
import { menuFunction } from '../../../helper/Script';
import NavMetaCare from './client/NavMetaCare';
import NavSanthigram from './client/NavSanthigram';
import NavSmarthealthtec from './client/NavSmarthealthtec';
import NavAmarac from './client/NavAmarac';

//import loading from ".../../../components/layout/menu/client/loading.png"
var loading = "/client/logo/ZZ5H.gif";
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);
var authLocalStroage: any = localStorage.getItem('isAuth');
var layout: any = "";
type NavDefaultStoreProps = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & LoginStore.LoginState & AccountStore.AccountState & AccountStore.Settings & ContactStore.ContactState & EncounterTypeStore.EncounterTypeState & FlexFromStore.FlexiListsState & ClientDataStore.ClientDataState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LoginStore.actionCreators & typeof AccountStore.actionCreators & typeof ContactStore.actionCreators & typeof EncounterTypeStore.actionCreators & typeof FlexFromStore.actionCreators & typeof ClientDataStore.actionCreators & RouteComponentProps;
class NavMenu extends React.PureComponent<NavDefaultStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        menuFunction();
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        this.ensureDataFetched();
     }

    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }
    
     private ensureDataFetched() {
         this.props.requestNavDefault()
        /* this.props.requestSiteDetail();*/
        /* this.props.getDefaultAccount();*/
         this.props.requestEncounterType();
         this.props.requestClientData(this.props.siteDetail.Code, this.props.siteDetail.CPHostName);
     }

     public logOut() {
         this.props.requestLogout();
     }
     public login() {
         return <NavLink to="/login" />;
     }
     goOurDoctor = () => {
         this.props.goNextPage();
     }




 

     public renderDoctorList() {
         var data = document.location.pathname;
         const _array = data.split('/');
         if (_array[1] == "login") {
             setTimeout(() => {
                 //var checkArray = String(this.props.navDefault.isAuth) == String(authLocalStroage);
                 //if (checkArray == false) {
                 //    localStorage.setItem("isAuth", "false");
                 //    window.location.href = "/login";
                 //}
                 var _data1 = String(this.props.navDefault.isAuth);
                 var _data2 = String(authLocalStroage);
                 var checkArray: any = "false";
                 if (_data1 == "true") {
                     checkArray = "true";
                 } else {
                     checkArray = "false";
                 }
                 checkWindow(checkArray);
             }, 1000);
         }
         else if (_array[1] == "") {
             setTimeout(() => {
                 var _data1 = String(this.props.navDefault.isAuth);
                 var _data2 = String(authLocalStroage);
                 var checkArray: any = "false";
                 if (_data1 == "true") {
                     checkArray = "true";
                 } else {
                     checkArray = "false";
                 }
                 checkWindow(checkArray);
             }, 1000);
         }

        


         var link: any = document.querySelector("link[rel~='icon']");      
             if (!link) {
                 link = document.createElement('link');
                 link.rel = 'icon';
                 document.getElementsByTagName('head')[0].appendChild(link);
             }
         if (this.props.siteDetail.FavIconPath != '' || this.props.clientData.FavIcon != '') {
             link.href = loading
             setTimeout(() => {
                 link.href = this.props.clientData.FavIcon ? this.props.clientData.FavIcon : this.props.siteDetail.FavIconPath ? this.props.clientData.FavIcon : loading;
             },1000)
                /* console.log('icon', this.props.siteDetail, this.props.clientData)*/
             }
         //if (this.props.clientData.FavIcon != "") {
         //    link.href = this.props.clientData.FavIcon;       
         //}      
         //else {        
         //            link.href = loading                         
         //}

         
         layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
         if (layout == undefined)
             layout = "";
         var dobformat: any = "";
         var dobformat: any = this.props.Settings.filter((s: any) => s.name == "Date")[0];
         if (dobformat != undefined) {
             dobformat = dobformat.customizeValue.toUpperCase();
             if (dobformat == "DD-MM-YYYY") {
                 dobformat = "DD/MM/YYYY"
             }
             else if (dobformat == "MM-DD-YYYY") {
                 dobformat = "MM/DD/YYYY"
             }
             else {
                 dobformat = dobformat
             }
             localStorage.setItem("DateFormat", dobformat)
         } else {
             dobformat = "";
         }

         if (layout.Value == "NavDefault") {
             return (
                 <React.Fragment>
                     <NavDefault />
                 </React.Fragment>
             );
         } else if (layout.Value == "NavVirtuvets") {
             return (
                 <React.Fragment >
                     <NavVirtuvets />


                 </React.Fragment>
             );
         } else if (layout.Value == "NavTheraque") {
             return (
                 <React.Fragment >
                     <NavTheraque />

                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavPrime") {
             return (
                 <React.Fragment >
                     <NavPrime />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavGoodoc") {
             return (
                 <React.Fragment >
                     <NavGoodoc />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavABC") {
             return (
                 <React.Fragment >
                     <NavABC />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavWelshdale") {
             return (
                 <React.Fragment >
                     <NavWelshdale />
                 </React.Fragment>
             );
         } else if (layout.Value == "NavMetaCare") {
             return (
                 <React.Fragment >
                     <NavMetaCare />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "Nav247DCT") {
             return (
                 <React.Fragment >
                     <Nav247DCT />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavSanthigram") {
             return (
                 <React.Fragment >
                     <NavSanthigram />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavVirtualCare") {
             return (
                 <React.Fragment >
                     <NavVirtualCare />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavSmarthealthtec") {
             return (
                 <React.Fragment >
                     <NavSmarthealthtec />
                 </React.Fragment>
             );
         }
         else if (layout.Value == "NavAmarac") {
             return (
                 <React.Fragment >
                     <NavAmarac />
                 </React.Fragment>
             );
         }
         else {
             return (
                 <React.Fragment>
                 </React.Fragment>
             );
         }
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.login, ...state.siteDetail, ...state.account, ...state.contact, ...state.encountertypes, ...state.flexiform, ...state.clientData }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...LoginStore.actionCreators, ...SiteDetailStore.actionCreators, ...AccountStore.actionCreators, ...ContactStore.actionCreators, ...EncounterTypeStore.actionCreators, ...FlexFromStore.actionCreators, ...ClientDataStore.actionCreators }) // Selects which action creators are merged into the component's props
)(NavMenu as any);

function checkWindow(checkArray:any) {
    if (checkArray == "false") {
       // localStorage.setItem("isAuth", "false");
        console.log("navdefault.tsx")
        /*window.location.href = "/";*/
    } else {
        return;
    }
}