import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { seo } from '../helper/Script';
import { ApplicationState } from '../store';
import * as NavDefaultStore from '../store/menu/NavDefault';
import * as PublicContentStore from '../store/PublicContent';
import ContentLoader from 'react-content-loader';
import * as MenuHideStore from '../store/menuHide';
import * as LanguageResourcesStore from '../store/LanguageResoures';
import * as SiteMenusStore from '../store/siteaccount/siteMenu';

import { setTittle, scrollTop } from '../helper/formvalidation';

var languagecontent: any = "";
var langdata: any = "";

type Props = NavDefaultStore.NavDefaultState & PublicContentStore.PublicContentState & MenuHideStore.MenuHideState & SiteMenusStore.SiteMenuState & LanguageResourcesStore.LanguageResouresState & typeof LanguageResourcesStore.actionCreators & typeof PublicContentStore.actionCreators & typeof NavDefaultStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof MenuHideStore.actionCreators & RouteComponentProps;
class PublicContent extends React.PureComponent<Props> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
        scrollTop()
    }
   

    public componentDidUpdate(prevProps: Props) {
        if (this.props.location !== prevProps.location) {         
            const { pathname } = this.props.location;
            if (pathname.indexOf('/publicpage') === 0) {
                this.ensureDataFetched();
                scrollTop()           
            }
        }
       seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    //public componentDidUpdate() {
    //    seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    //}

    public render() {
        return (
            <React.Fragment>
                {this.publicContent()}
            </React.Fragment>
        );
    }
    bookNow = () => {
        this.props.goNextPage()
    }
    private ensureDataFetched() {
        this.props.requestMenuHide(false);
        this.props.getLangResources()
        const urlParams: any = new URLSearchParams(window.location.search);
        const page = urlParams.get('page');
        if (page != null)
            this.props.requestPublicContent(page);
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
       
    }
    public publicContent() {
        const replacements:any = {
            websitename: this.props.publicContent.SiteName,
            websiteurl: "<a href=" + "https://" + this.props.publicContent.SiteURL + ">" + this.props.publicContent.SiteURL + "</a>",
            sitename: this.props.publicContent.SiteName,
            siteurl: "<a href=" + "https://" + this.props.publicContent.SiteURL + ">" + this.props.publicContent.SiteURL+ "</a>",
        };
        const string = this.props.publicContent.Content.replace(/{\w+}/g, (placeholder:any) =>
            replacements[placeholder.substring(1, placeholder.length - 1)] || placeholder
        );
       
        languagecontent = this.props.LanguageResoures;
       
        return (
            <div>
                <div className="content">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-body">
                                    {
                                            this.props.isLoading == true ?
                                            <React.Fragment >
                                                    <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
                                                    <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                                                    <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                                                    <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                                                </ContentLoader>
                                            </React.Fragment >
                                            :
                                        <div dangerouslySetInnerHTML={{ __html: string }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.publicContent, ...state.menuHide, ...state.langResources, ...state.siteMenus }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...PublicContentStore.actionCreators, ...MenuHideStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...SiteMenusStore.actionCreators })// Selects which action creators are merged into the component's props
)(PublicContent as any);

