import axios from 'axios';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { actionCreators as _paymentProcess } from '../payment/PaymentProcess';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConditionState {
    isreasonLoading: boolean;
    isconditionLoading: boolean;
    appointment: Appointment;
    encounterTypes: EncounterType[];
    reasons: CalendarEvent[];
    discount: Discount;
}
export interface CalendarEvent {
    id: string;
    colorCode: string;
    name: string;
}
export interface ScheduleDetail {
    RecordId: string;
    RecordType: string;
}

export interface EncounterType {
    id: string;
    name: string;
    description: string;
    icon: string;
    parentIcon: string;
    encounterTypeModeId: string;
    accountId: string;
}
export interface Discount {
    value: string;
}

export interface Patient {
    Id: string;
}

export interface Appointment {
    ID: string;
    FacilityId: null;
    AppointmentTypeId: string;
    AppointmentOn: Date;
    Time: string;
    Note: string;
    Title: string;
    Duration: number;
    FromUserId: string;
    StatusId: number;
    AccountId: string
    ScheduleDetails: ScheduleDetail[]
    Patient: Patient
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestContactAction { type: 'REQUEST_APPOINTMENT', appointment: Appointment, encounterTypes: EncounterType[], reasons: CalendarEvent[], discount: Discount }
export interface ReciveContactAction { type: 'RECEIVE_APPOINTMENT', appointment: Appointment, encounterTypes: EncounterType[], reasons: CalendarEvent[], discount: Discount }
export interface RequestReasonAction { type: 'REQUEST_REASON', appointment: Appointment, encounterTypes: EncounterType[], reasons: CalendarEvent[], discount: Discount }
export interface ReciveReasonAction { type: 'RECEIVE_REASON', appointment: Appointment, encounterTypes: EncounterType[], reasons: CalendarEvent[], discount: Discount }
export interface RequestDiscountAction { type: 'REQUEST_DISCOUNT', appointment: Appointment, encounterTypes: EncounterType[], reasons: CalendarEvent[], discount: Discount }
export interface ReciveDiscountAction { type: 'RECEIVE_DISCOUNT', appointment: Appointment, encounterTypes: EncounterType[], reasons: CalendarEvent[], discount: Discount }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestContactAction | ReciveContactAction | RequestReasonAction | ReciveReasonAction | RequestDiscountAction | ReciveDiscountAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSaveAppointment: (formvalue: any, patient: any, doctor: any, status1: any, accountid: any, modulename: any, moduleid: any, noappserviceid: any, noenctypeid: any, _price: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {

        const appState: any = getState();
        const urlParams: any = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        var noappointment = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
        var langdata: any;
        var AppointmentReasonFieldVisibility: any = appState.account.Settings.filter((se: any) => se.name == "Appointment Reason Field Visibility")[0];
        if (appState && appState.condition) {
            const urlParams = new URLSearchParams(window.location.search);
            const childid = urlParams.get('childid');
            const childname = urlParams.get('childname');
            const serviceid = urlParams.get('serviceid');
            const search = window.location.search;
            const params = new URLSearchParams(search);
            dispatch(showLoading('sectionBar'));
            var date = params.get('date');
            var time = params.get('time');
            var date1: any = params.get('date1');
            var time1 = params.get('time1');
            var referrralid = params.get('referrralid');
            var enctypeid = params.get('enctypeid');
            var methodid = params.get('methodid'); //3
            var price = params.get('price');
            var SpecialityReasons: any = "";
            var enctypemode: any = params.get('encountertypemode')
            var status= params.get('status');
            var childpatientid: any = localStorage.getItem("childpatientid");
            if (status == 'followup') {
                localStorage.setItem("enctypemode", enctypemode);
            }
            if (localStorage.getItem("SpecailityReasonId") != null)
                SpecialityReasons = JSON.parse(localStorage.getItem("SpecailityReasonId") || "");
            else
                SpecialityReasons = null;

            var specialityid = localStorage.getItem("SpecialityId") != null ? localStorage.getItem("SpecialityId") : params.get('specialityid');
            var parentid = params.get('followup') == "null" ? "" : params.get('followup');
            if (date != null) {
                if (window.location.hostname == "localhosts") {
                    if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
                        var appointmenton = Moment.utc(new Date()).format('YYYY/MM/DD'); //Current datetime
                        formvalue.AppointmentOn = Moment.utc(toDate(appointmenton + " " + formatAMPM(new Date()))).format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        formvalue.AppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime")).format("YYYY-MM-DDTHH:mm:ss");
                        if (localStorage.getItem("AppointmentUTCTime1"))
                            formvalue.SecondaryAppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime1")).format("YYYY-MM-DDTHH:mm:ss");

                        //var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD'); //Current datetime 

                        //var totalMinuteOffset: any = 0;
                        //if (formvalue.Offset == "") {
                        //    var timezone = appState.account.Settings.filter((se: any) => se.name == "Time Zone")[0]
                        //    var _timezone = timezone.displayCode.split("|");
                        //    totalMinuteOffset = _timezone[1];
                        //    var d = new Date(appointmenton + " " + time);
                        //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                        //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    if (date1 != null && date1 != "null" && time1 != null && time1 != "null") {
                        //        var appointmenton1 = Moment.utc(new Date(date1)).format('YYYY/MM/DD'); //Current datetime 
                        //        var d = new Date(appointmenton1 + " " + time1);
                        //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                        //        formvalue.SecondaryAppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    }
                        //} else {
                        //    var tzOffset: any = formvalue.Offset;
                        //    var [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
                        //    var totalMinuteOffset: any = hourOffset * 60 + minuteOffset;
                        //    var d = new Date(appointmenton + " " + time);
                        //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                        //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    if (date1 != null && date1 != "null" && time1 != null && time1 != "null") {
                        //        var appointmenton1 = Moment.utc(new Date(date1)).format('YYYY/MM/DD'); //Current datetime 
                        //        var d = new Date(appointmenton1 + " " + time1);
                        //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                        //        formvalue.SecondaryAppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    }
                        //}
                    }
                } else {
                    if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
                        var appointmenton = Moment(new Date()).format('YYYY/MM/DD');
                        var datetime: any = toDate(appointmenton + " " + formatAMPM(new Date()));
                        var appointmenttime = DisplayCurrentTime(datetime);
                        appointmenton = Moment(new Date()).format('YYYY-MM-DD');
                        var _date = new Date(appointmenton + "T" + appointmenttime);
                        formvalue.AppointmentOn = Moment.utc(_date).format("YYYY-MM-DDTHH:mm:ss");
                    } else {
                        formvalue.AppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime")).format("YYYY-MM-DDTHH:mm:ss");
                        if (localStorage.getItem("AppointmentUTCTime1"))
                            formvalue.SecondaryAppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime1")).format("YYYY-MM-DDTHH:mm:ss");
                        //var totalMinuteOffset: any = 0;
                        //if (formvalue.Offset == "") {
                        //    var timezone = appState.account.Settings.filter((se: any) => se.name == "Time Zone")[0]
                        //    var _timezone = timezone.displayCode.split("|");
                        //    var offset = _timezone[1];
                        //    var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD');
                        //    var datetime: any = toDate(appointmenton + " " + time);
                        //    var d = new Date(appointmenton + " " + time); // get current date
                        //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(offset)), 0, 0);
                        //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    if (date1 != null && date1 != "null" && time1 != null && time1 != "null") {
                        //        var appointmenton1 = Moment.utc(new Date(date1)).format('YYYY/MM/DD');
                        //        var datetime1: any = toDate(appointmenton1 + " " + time1);
                        //        var d = new Date(appointmenton1 + " " + time1); // get current date
                        //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(offset)), 0, 0);
                        //        formvalue.SecondaryAppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    }
                        //} else {
                        //    var tzOffset: any = formvalue.Offset;
                        //    var [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
                        //    var totalMinuteOffset: any = hourOffset * 60 + minuteOffset;
                        //    var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD');
                        //    var datetime: any = toDate(appointmenton + " " + time);
                        //    var d = new Date(appointmenton + " " + time); // get current date                            
                        //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                        //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    if (date1 != null && date1 != "null" && time1 != null && time1 != "null") {
                        //        var appointmenton1 = Moment.utc(new Date(date1)).format('YYYY/MM/DD');
                        //        var datetime1: any = toDate(appointmenton1 + " " + time1);
                        //        var d = new Date(appointmenton1 + " " + time1); // get current date
                        //        d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                        //        formvalue.SecondaryAppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                        //    }
                        //}
                    }

                }
            }
            localStorage.setItem("doctorid", doctor.doctorid);
            if (childid == "null" || childid == null) {
                formvalue.UserId = patient.userId;
            }
            else {
                formvalue.UserId = childid;
            }
            
            if (childid == "null" || childid == null) {
                formvalue.PatientId = patient.id;
            } else if (childpatientid =="00000000-0000-0000-0000-000000000000") {
                formvalue.PatientId = null;
            } else {
               
                formvalue.PatientId = childpatientid;
            }

            //if (localStorage.getItem("LangResourcesValidations") != null)
            //    langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
            //if (AppointmentReasonFieldVisibility.customizeValue == "Yes") {
            //    formvalue.Reason = formvalue.Reason;
            //}
            //else {
            //    formvalue.Reason = langdata['consultantwilldecide']
            //}
            formvalue.Reason = formvalue.Reason;
            formvalue.Note = formvalue.Note;
            formvalue.IsCustomFormFlow = status;

            formvalue.Reasons = SpecialityReasons;
            //console.log(status,)
            if (referrralid != "null")
                formvalue.ReferredParentId = referrralid;
            else
                formvalue.ReferredParentId = "";
            formvalue.EncounterTypeId = localStorage.getItem("ServiceEncounterTypeId");
            formvalue.AccountId = accountid;
            formvalue.PayConceptId = "2";
            formvalue.ParentId = parentid;
            formvalue.SpecialityId = specialityid;
            if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
                formvalue.ProviderId = "";
                formvalue.MethodId = "3";
                formvalue.ServiceId = noappserviceid;
                formvalue.EncounterTypeModeDurationId = noenctypeid;
                formvalue.Amount = _price.toString();
            } else {
                formvalue.ProviderId = doctor.doctorid;
                formvalue.MethodId = methodid;
                formvalue.ServiceId = serviceid;
                formvalue.EncounterTypeModeDurationId = enctypeid;
                formvalue.Amount = _price == null ? price : _price.toString();
            }
            localStorage.setItem("Appointment", JSON.stringify(formvalue));
            axios.post('appointment', formvalue)
                .then(response => {
                    dispatch(hideLoading('sectionBar'))
                    var result = response.data;
                    dispatch({ type: 'RECEIVE_APPOINTMENT', appointment: result, isconditionLoading: false });
                    dispatch(reset("conditionForm"));
                    localStorage.setItem("childpatientid", result.patientId);
                    localStorage.setItem("AppointmentId", result.id);
                    localStorage.setItem("paidAmount", formvalue.Amount);
                    var paidamount: any = formvalue.Amount;
                    if (status1 == "Yes") {

                        var newArray: any = "";
                        if (appState != undefined) {
                            newArray = appState.account.Settings.filter(function (el: any) {
                                return (el.name == "Intake Forms");
                            });
                        }
                        if (result.customFormModel) {
                            if (result.customFormModel.length != 0) {
                                var module = result.customFormModel[0].moduleName;
                                var moduleids = result.customFormModel[0].id;
                                const childid = urlParams.get('childid');
                                const childname = urlParams.get('childname');
                                return dispatch(push("/specialityquestion/" + doctor.doctorid + "/" + module + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email + "/" + moduleids + "?methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname));
                            }
                            else if (newArray[0].name == "Intake Forms" && newArray[0].customizeValue == "Yes") {
                                const childid = urlParams.get('childid');
                                const childname = urlParams.get('childname');
                                return dispatch(push("/questionnaire/" + doctor.doctorid + "/" + modulename + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email + "/" + moduleid + "?methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname));
                            }
                            else {
                                if ((paidamount == "0") || (paidamount == "0.00") || (paidamount == null)) {
                                    var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                    if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                        dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else {
                                        dispatch(_paymentProcess.requestScheduleUpdateFree(result.id))
                                    }
                                }
                                else {
                                    var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                    if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                        dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else {
                                        var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                                        var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                                        var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];

                                        if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/insurancelist?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                            dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                        } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                            dispatch(_paymentProcess.requestScheduleUpdate())
                                        } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                            dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                        } else {
                                            dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                        }
                                    }

                                }
                            }
                        }
                        else if (newArray[0].name == "Intake Forms" && newArray[0].customizeValue == "Yes") {
                            const childid = urlParams.get('childid');
                            const childname = urlParams.get('childname');
                            return dispatch(push("/questionnaire/" + doctor.doctorid + "/" + modulename + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email + "/" + moduleid + "?methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname));
                        }
                        else {
                            if ((paidamount == "0") || (paidamount == "0.00") || (paidamount == null)) {
                                var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                    dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else {
                                    dispatch(_paymentProcess.requestScheduleUpdateFree(result.id))
                                }
                            }
                            else {
                                var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                    dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else {
                                    var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                                    var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                                    var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];

                                    if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/insurancelist?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                        dispatch(_paymentProcess.requestScheduleUpdate())
                                    } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                        dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                    } else {
                                        dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                    }
                                }

                            }
                        }
                    }
                    else {
                        if (result.customFormModel) {
                            if (result.customFormModel.length != 0) {
                                var module = result.customFormModel[0].moduleName;
                                var moduleids = result.customFormModel[0].id;
                                const childid = urlParams.get('childid');
                                const childname = urlParams.get('childname');
                                return dispatch(push("/specialityquestion/" + doctor.doctorid + "/" + module + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email + "/" + moduleids + "?methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname));
                            }
                            else if ((paidamount == "0") || (paidamount == "0.00") || (paidamount == null)) {
                                var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                    dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else {
                                    dispatch(_paymentProcess.requestScheduleUpdateFree(result.id))
                                }
                            }
                            else {
                                var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                                if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                    dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else {
                                    var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                                    var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                                    var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];

                                    if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/insurancelist?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                        dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                    } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                        dispatch(_paymentProcess.requestScheduleUpdate())
                                    } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                        dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                    } else {
                                        dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                    }
                                }
                            }
                        }
                        else if ((paidamount == "0") || (paidamount == "0.00") || (paidamount == null)) {
                            var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                            if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                            } else {
                                dispatch(_paymentProcess.requestScheduleUpdateFree(result.id))
                            }
                        }
                        else {
                            var eprescription: any = appState.account.Settings.filter((se: any) => se.name == "E-Prescription Provider")[0];
                            if (eprescription.customizeValue == "DoseSpot" || eprescription.customizeValue == "Both") {
                                dispatch(push("/pharmacylist?types=appointment&memberid=" + patient.memberId + "&appointmentid=" + result.id + "&doctorid=" + doctor.doctorid + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                            } else {
                                var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                                var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                                var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];

                                if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                    dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                    dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                    dispatch(push("/insurancelist?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                    dispatch(push("/paymethod?doctorid=" + doctor.doctorid + "&appointmentid=" + result.id + "&name=" + patient.firstName + " " + patient.lastName + "&email=" + patient.email + "&methodid=" + formvalue.MethodId + "&childid=" + childid + "&childname=" + childname))
                                } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                    dispatch(_paymentProcess.requestScheduleUpdate())
                                } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                    dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                } else {
                                    dispatch(push("/paymentprocess/" + doctor.doctorid + "/" + result.id + "/" + patient.firstName + " " + patient.lastName + "/" + patient.email));
                                }
                            }
                        }
                    }
                })
                .catch(error => {

                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.ModelState != undefined) {
                            toastr.error('', errorObject.ModelState[""][0]);
                        }
                        else if (errorObject.Message == "Appointments cannot be scheduled for past dates and time") {
                            var langdata: any;
                            if (localStorage.getItem("LangResourcesValidations") != null)
                                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

                            dispatch({ type: 'RECEIVE_APPOINTMENT', isconditionLoading: false });
                            return toastr.error('', langdata['pastappointment']);

                        }
                        else if (errorObject.Message == "Already exists.") {
                            var langdata: any;
                            if (localStorage.getItem("LangResourcesValidations") != null)
                                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

                            dispatch({ type: 'RECEIVE_APPOINTMENT', isconditionLoading: false });
                            return toastr.error('', langdata['alreadyexists'])

                        }
                        else if (errorObject.Message == "Referral Appointment already created.") {
                            var langdata: any;
                            if (localStorage.getItem("LangResourcesValidations") != null)
                                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            dispatch({ type: 'RECEIVE_APPOINTMENT', isconditionLoading: false });
                            return toastr.error('', langdata['referalappointmentalreadycreated'])

                        }
                        else {
                            toastr.error('', errorObject.Message);

                        }

                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ type: 'RECEIVE_APPOINTMENT', isconditionLoading: false });
                    dispatch({ ...hideLoading('sectionBar') });


                });
        }
        dispatch({ type: 'REQUEST_APPOINTMENT', isconditionLoading: false });
    },
    requestEncounterType: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        axios.get('/appointment/encountertype', { headers: { Authorization: "BasicAuth" } })
            .then(function (response) {
                const result = response.data;
                dispatch({ type: 'RECEIVE_APPOINTMENT', encounterTypes: result });
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
            })
            .then(function () {
                // always executed
            });
        dispatch({ type: 'REQUEST_APPOINTMENT', isconditionLoading: false });
    },
    requestCalendarEvent: (keyword: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        axios.get('/autocomplete/searchreason?keyword=' + keyword)
            .then(function (response) {
                const result = response.data;
                dispatch({ type: 'RECEIVE_REASON', reasons: result, isreasonLoading: false });
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
            })
            .then(function () {
                // always executed
            });
        dispatch({ type: 'REQUEST_REASON', isreasonLoading: true });
    },
    requestDiscountCode: (code: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        var langdata: any = "";
        const urlParams = new URLSearchParams(window.location.search);
        const childid = urlParams.get('childid');
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        var memberid = childid == null ? patient.memberId : childid;

        var accountid: any = localStorage.getItem("AccountId");
        axios.get('/Appointment/GetDiscountCode/' + code + "/" + memberid + "/" + accountid)
            .then(function (response) {
                const result = response.data;
                var discountamount: any = "";
                if (result.Id) {
                    if (result.Type.toLowerCase() == "percentage") {
                        var percentage: any = result.Value;
                        var paidamount: any = localStorage.getItem("Amount");
                        var total: any = (paidamount * percentage) / 100;
                        var totalamount: any = paidamount - total;
                        discountamount = parseFloat(totalamount).toFixed(2);
                        localStorage.setItem("DiscountAmount", parseFloat(total).toFixed(2));
                    }
                    else {
                        var percentage: any = result.Value;
                        var paidamount: any = localStorage.getItem("Amount");
                        var totalamount: any = (paidamount - percentage);
                        discountamount = parseFloat(totalamount).toFixed(2);
                        localStorage.setItem("DiscountAmount", parseFloat(result.Value).toFixed(2));
                    }  
                    localStorage.setItem("DiscountId", result.Id);
                    localStorage.setItem("TotalAmount", discountamount);
                    localStorage.setItem("Discount", discountamount);
                   
                    dispatch({ type: 'RECEIVE_DISCOUNT', discount: "" });
                    setTimeout(() => {
                        dispatch({ type: 'RECEIVE_DISCOUNT', discount: discountamount});
                    }, 1000)
                }
                else {
                    var element: any = document.getElementsByName('Discount')[0] as HTMLElement;
                    element.value = "";
                    localStorage.removeItem("Discount");
                    localStorage.removeItem("DiscountId");
                    localStorage.removeItem("DiscountAmount");
                    localStorage.removeItem("TotalAmount");
                    return toastr.error('', langdata['invalidcode']);
                }


            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
            })
            .then(function () {
                // always executed
            });
        dispatch({ type: 'REQUEST_DISCOUNT' });
    },
    validateSerivceId: (message: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        toastr.error('', message)
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ConditionState = {
    appointment: {
        ID: "",
        FacilityId: null,
        AppointmentTypeId: "",
        AppointmentOn: new Date(),
        Time: "",
        Note: "",
        Duration: 15,
        Title: "",
        FromUserId: "",
        StatusId: 1,
        AccountId: "",
        ScheduleDetails: [{ RecordId: "", RecordType: "" }],
        Patient: { Id: "" },
    },
    encounterTypes: [],
    reasons: [],
    discount: {
        value: "",

    },
    isreasonLoading: false,
    isconditionLoading: false,
};
export const reducer: Reducer<ConditionState> = (state: ConditionState | undefined, incomingAction: Action): ConditionState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT':
            return {
                appointment: state.appointment,
                encounterTypes: state.encounterTypes,
                reasons: state.reasons,
                discount: state.discount,
                isconditionLoading: true,
                isreasonLoading: false,

            };
        case 'RECEIVE_APPOINTMENT':
            return {
                appointment: action.appointment,
                encounterTypes: action.encounterTypes,
                reasons: state.reasons,
                discount: state.discount,
                isreasonLoading: false,
                isconditionLoading: false,
            };
        case 'REQUEST_REASON':
            return {
                appointment: state.appointment,
                encounterTypes: state.encounterTypes,
                reasons: state.reasons,
                discount: state.discount,
                isconditionLoading: false,
                isreasonLoading: true,

            };
        case 'RECEIVE_REASON':
            return {
                appointment: state.appointment,
                encounterTypes: state.encounterTypes,
                reasons: action.reasons,
                discount: state.discount,
                isconditionLoading: false,
                isreasonLoading: false,

            };
        case 'REQUEST_DISCOUNT':
            return {
                appointment: state.appointment,
                encounterTypes: state.encounterTypes,
                reasons: state.reasons,
                discount: state.discount,
                isconditionLoading: false,
                isreasonLoading: false,

            };
        case 'RECEIVE_DISCOUNT':
            return {
                appointment: state.appointment,
                encounterTypes: state.encounterTypes,
                reasons: state.reasons,
                discount: action.discount,
                isconditionLoading: false,
                isreasonLoading: false,

            };
        default:
            return state;
    }
};

function toDate(date: any) {
    if (date != null) {
        var currentDateTime = new Date(date);
        return currentDateTime
    }
}
function DisplayCurrentTime(datetime: Date) {
    var date = datetime;
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return (hours + ":" + minutes + ":" + seconds);
};
const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}
