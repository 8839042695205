import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';

export interface PaymentState {
    isLoading: boolean;
}

interface RequestPayment {
    type: 'REQUEST_PAYMENT_POST';
}

interface ReceivePayment {
    type: 'RECEIVE_PAYMENT_POST';
}


type KnownAction = RequestPayment | ReceivePayment;

export const actionCreators = {
    requestPostPayment: (value:any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState) {
            var discountid: any = localStorage.getItem("DiscountId");
            var discountamount: any = localStorage.getItem("DiscountAmount");
            axios.get('/patient/PaymentResponse/' + value.transactionid + "/" + discountid + "/" + discountamount )
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_PAYMENT_POST' });
                    if (response.data.status == "Applied")
                        dispatch(push("/success/" + response.data.appointmentId));
                    else
                        dispatch(push("/failed/" + response.data.appointmentId));
                })
                .catch(function (error) {
                    dispatch(push("/failed/"));
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PAYMENT_POST' });
        }
    }
};


const PaymentUnloadedState: PaymentState = {
    isLoading: false
};


export const reducer: Reducer<PaymentState> = (state: PaymentState | undefined, incomingAction: Action): PaymentState => {
    if (state === undefined) {
        return PaymentUnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PAYMENT_POST':
            return {
                isLoading: true
            };
        case 'RECEIVE_PAYMENT_POST':
            return {
                isLoading: false
            };
        default:
            return state;
    }
};
