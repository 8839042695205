import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { Patient } from '../appointment/Condition';
import * as HandleRequest from '../../helper/Request';
import { push } from 'connected-react-router';

export interface AppointmentTypeState {
    appointmenttypes: AppointmentType[];
}

export interface AppointmentType {
    siteSettingId: string;
    name: string;
    value: string;
    description: string;
    redirecturl: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAppointmentListAction {
    type: 'REQUEST_APPOINTMENT_TYPE';
    appointmenttypes: AppointmentType[]
}

interface ReceiveAppointmentListAction {
    type: 'RECEIVE_APPOINTMENT_TYPE';
    startDateIndex: number;
    appointmenttypes: AppointmentType[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAppointmentListAction | ReceiveAppointmentListAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAppointmentType: (): AppThunkAction<KnownAction> => async (dispatch: any, getState: () => any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        dispatch(showLoading('sectionBar'));
        if (appState && appState.dashboard) {
            await axios.get('/site/GetAppointmentType/' + accountid)
                .then(function (response) {
                    dispatch(hideLoading('sectionBar'));
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_APPOINTMENT_TYPE', appointmenttypes: result });
                })
                .catch(function (error) {
                    errorHandling(error);
                    dispatch(hideLoading('sectionBar'));
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_APPOINTMENT_TYPE' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AppointmentTypeState = { appointmenttypes: []};

export const reducer: Reducer<AppointmentTypeState> = (state: AppointmentTypeState | undefined, incomingAction: Action): AppointmentTypeState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_TYPE':
            return {
                appointmenttypes: state.appointmenttypes
            };
        case 'RECEIVE_APPOINTMENT_TYPE':
            return {
                appointmenttypes: action.appointmenttypes,
            };
        default:
            return state;
    }
};


