import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
//import { BrowserRouter, Route } from "react-router-dom";
import '../../ClientApp/src/helper/Script';
import Layout from './components/layout/Layout';
import Home from './components/Home';
import Counter from './components/Counter';
import FetchData from './components/FetchData';
import Login from './components/account/Login';
import ChangePassword from './components/account/ChangePassword';
import ForgotPassword from './components/account/ForgotPassword';
import SignUp from './components/account/Signup';
import EmailActivation from './components/account/EmailActivation';
import ResetPassword from './components/account/ResetPassword';
import Message from './components/Patient/Message';
import MyAppointments from './components/Patient/MyAppointments';
import MyReferrals from './components/Patient/MyReferrals';
import Dashboard from './components/Patient/Dashboard';
import ProfileSettings from './components/Patient/ProfileSettings';
import Booking from './components/Patient/Booking';
import OurDoctors from './components/doctor/OurDoctors';
import DashboardNoRecordsFound from './components/Patient/DashboardNoRecordsFound';
import NoRecordsFound from './components/account/NoRecordsFound';
import Policy from './components/layout/footer/Policy';
import TermsAndCondition from './components/layout/footer/TermsAndCondition';
import DoctorProfile from './components/doctor/DoctorProfile';
import Success from './components/payment/Success';
import Conditions from './components/appointment/Conditions';
import Failed from './components/payment/Failed';
import HealthProfile from './components/doctor/HealthProfile';
import Contact from './components/Patient/Contact';
import HeaderProfile from './components/widget/HeaderDrProfile';
import Payment from './components/payment/Payment';
import Calendar from './components/widget/Calendar';
import CustomForm from './components/widget/CustomForm';
import PaymentProcess from './components/payment/PaymentProcess';
import Centralized from './components/centralized/timing';
import Questions from './components/questionnaire/Questions';
import Forms from './components/form/DynamicForms';
import FormsDetail from './components/form/forms';
//import ProtectedRoute from './components/account/ProtectedRoute';
import { PublicRoute, ProtectedRoute } from './components/account/CustomRoute';
import VisitDetail from './components/appointment/VisitDetail';
import Transaction from './components/Patient/Transactions';
import WhatWeDo from './components/doctor/WhatWeDo';
import WhoWeAre from './components/doctor/WhoWeAre';
import ContactUs from './components/doctor/ContactUs';
import FamilyMember from './components/family/familyMember';
import MemberForm from './components/family/memberForm';
import InitialPageForm from './components/form/InitialPageForm';
import InsurenceList from './components/Insurence/List';
import PayMethod from './components/payment/PayMethod';
import InsuranceFlowList from './components/payment/insurancelList';
import AddInsurence from './components/Insurence/Add';
import InsurenceAdd from './components/Insurence/AddInsurance';
import PharmacyList from './components/pharmacy/PharmacyList';
import DocumentView from './components/library/document/DocumentView';
import PublicContent from './components/PublicContent';
import PageNotFound from './components/pagenotfound/404'
import Page404Error from './components/pagenotfound/Page404Error'
import ComingSoon from './components/comingsoon/comingSoon'
import Logout from './components/account/Logout'
import SpeakToVet from './components/doctor/SpeakToVet'
import ScheduleCall from './components/doctor/ScheduleCall'
import AccessDenied from './components/accessdenied/AccessDenied';
import MemberSpeciality from './components/layout/menu/MemberSpeciality';
import AddMessage from './components/message/AddMessag';
import EditMessage from './components/message/EditMessage';
import SpecialityQuestion from './components/library/questionnaire/Questionnaire';
import SurveyForm from './components/survey/SurveyForm';
import Template from './components/template';
export default () => (
    <Route>
        <Switch>
            <Route path='/404pagenotfound' component={Page404Error} />
            <Route path='/accessdenied' component={AccessDenied} />
            <Route path={'/santhigramtemplate'} component={Template} />
            <Route path='/logout' component={Logout} />
            
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/counter' component={Counter} />
                    <Route path='/fetch-data/:startDateIndex?' component={FetchData} />
                    <PublicRoute path='/login' component={Login} />
                    <ProtectedRoute path='/changepassword' component={ChangePassword} />
                    <PublicRoute path='/forgotpassword' component={ForgotPassword} />
                    <PublicRoute path='/signup' component={SignUp} />
                    <PublicRoute path='/emailactivation' component={EmailActivation} />
                    <PublicRoute path='/account/passwordretrieve/:userid' component={ResetPassword} />
                    <ProtectedRoute path='/profilesettings/:memberid' component={ProfileSettings} />
                    <ProtectedRoute path='/message' component={Message} />
                    <ProtectedRoute path='/myappointments' component={MyAppointments} />
                    <Route path='/myreferrals' component={MyReferrals} />
                    <ProtectedRoute path='/dashboard' component={Dashboard} />
                    <Route path='/booking/:doctorid' component={Booking} />
                    <Route path='/ourdoctors' component={OurDoctors} />
                    <ProtectedRoute path='/dashboardnorecordsfound' component={DashboardNoRecordsFound} />
                    <Route path='/norecordsfound' component={NoRecordsFound} />
                    <Route path='/policy' component={Policy} />
                    <Route path='/termsandcondition' component={TermsAndCondition} />
                    <Route path='/doctorprofile/:doctorid' component={DoctorProfile} />
                    <ProtectedRoute path='/contact/:doctorid/:memberid' component={Contact} />
                    <ProtectedRoute path='/success/:appointmentid' component={Success} />
                    <ProtectedRoute path='/conditions/:doctorid/:appointmentid' component={Conditions} />
                    <ProtectedRoute path='/failed' component={Failed} />
                    <Route path='/healthprofile' component={HealthProfile} />
                    <ProtectedRoute path='/payment/:transactionid' component={Payment} />
                    <Route path='/headerprofile/:doctorid' component={HeaderProfile} />
                    <Route path='/calendar' component={Calendar} />
                    <Route path='/customform' component={CustomForm} />
                    <ProtectedRoute path='/paymentprocess/:doctorid/:appointmentid/:patientname/:email' component={PaymentProcess} />
                    <Route path='/centralized' component={Centralized} />
                    <ProtectedRoute path='/questionnaire' component={Questions} />
                    <ProtectedRoute path='/formlist' component={Forms} />
                    <ProtectedRoute path='/forms' component={FormsDetail} />
                    <ProtectedRoute path='/initialpageforms' component={InitialPageForm} />
                    <ProtectedRoute path='/visitdetail/:appointmentid' component={VisitDetail} />
                    <ProtectedRoute path='/transactions/:memberid' component={Transaction} />
                    <ProtectedRoute path='/memberform' component={MemberForm} />
                    <ProtectedRoute path='/insurance/list' component={InsurenceList} />
                    <ProtectedRoute path='/insurance/add' component={AddInsurence} />
                    <ProtectedRoute path='/paymethod' component={PayMethod} />
                    <ProtectedRoute path='/insurancelist' component={InsuranceFlowList} />
                    <ProtectedRoute path='/newmessage' component={AddMessage} />
                    <ProtectedRoute path='/replymessage' component={EditMessage} />
                    <ProtectedRoute path='/addinsurance' component={InsurenceAdd} />
                    <Route path='/whatwedo' component={WhatWeDo} />
                    <Route path='/whoweare' component={WhoWeAre} />
                    <Route path='/publicpage' component={PublicContent} />
                    <PublicRoute path='/contactus' component={ContactUs} />
                    <Route path='/consultationrequest' component={FamilyMember} />
                    <ProtectedRoute path='/pharmacylist' component={PharmacyList} />
                    <ProtectedRoute path='/documentview' component={DocumentView} />
                    <Route path='/memberspeciality' component={MemberSpeciality} />
                    <Route key='testdata' path='404' exact={true} />
                    <Route path='/comingsoon' component={ComingSoon} />
                    <Route path='/speaktovet' component={SpeakToVet} />
                    <Route path='/schedulecall' component={ScheduleCall} />
                    <ProtectedRoute path='/specialityquestion' component={SpecialityQuestion} />
                    <Route path='/surveyform' component={SurveyForm} />
                    <Redirect to={'/404pagenotfound'} />
                    
                   
                </Switch>
            </Layout>
        </Switch>
    </Route>
);
