import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { email, password, setTittle } from '../../helper/formvalidation';
import {renderField} from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as LoginStore from '../../store/account/Login';
import * as NavDefaultStore from '../../store/menu/NavDefault';
import * as HandleRequest from '../../helper/Request';
import * as SiteDetailStore from '../../store/SiteDetail';
import { NavLink } from 'react-router-dom';
import { floatingJS, mobnav } from '../../helper/Script';
import { seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as MenuHideStore from '../../store/menuHide';

interface State {
    passwordVisible: boolean;
    loader: boolean;
}
type LoginProps =
    LoginStore.LoginState & NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & HandleRequest.Request & LanguageResourcesStore.LanguageResouresState & MenuHideStore.MenuHideState &
    typeof LoginStore.actionCreators & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof MenuHideStore.actionCreators &
    RouteComponentProps<{}>;
var languagecontent: any = "";
var layout: any = "";
var langdata: any;
var required_val: any = "";
var authentication: any = "";



class Login extends React.PureComponent<LoginProps, State> {
    constructor(props: any) {
        super(props);
    }
        state = {
            passwordVisible: false,
            loader: false
        };
    

    handleToggle = () => {
        this.setState((prevState) => ({
            passwordVisible: !prevState.passwordVisible
        }));
    };


    onSubmit = (formValues: any, dispatch: any, props: any) => {
        //this.setState({loader:true})
            this.props.requestLoginSave(formValues);
            this.props.requestNavDefault();     
       
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderLogin()}
            </React.Fragment>
        );
    }
   
    public componentDidMount() {
        authentication = localStorage.getItem("isAuth");
    
        if (authentication == "true"){
            window.location.href = "/dashboard"
        }
        //mobnav();
        //floatingJS();
        this.ensureDataFetched();
        /*seo({
            title: 'DrCare247-Login',
            pageTitle: 'Log in',
            description: 'Use your registered username and password to login here.'

        });*/
        //const myElement: any = document.getElementById("dloginsignup");
        //if (myElement != null) {
        //    if (window.location.pathname.split('/')[1] == "login")
        //        myElement.style.display = "none";
        //    else
        //        myElement.style.display = "block";
        //}
        var data_current = window.location.pathname;
        const _array_current = data_current.split('/');
        if (_array_current[1] == "") {
            window.location.reload();
        }
      
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
        var defaultLangCode = localStorage.getItem("DefaultLangCode")

        required_val = defaultLangCode == 'en-us' ? 'Required' : 'Requerido'
 
    }
    private ensureDataFetched() {
        this.props.requestMenuHide(true);
        this.props.getLangResources();
        var defaultLangCode = localStorage.getItem("DefaultLangCode")

        required_val = defaultLangCode == 'es-cr' ? 'Requerido' : 'Required'

   
    }
    required = (value: string) => {
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        return value || typeof value === 'number' ? undefined : required_val;
    };

  

    public renderLogin() {
        languagecontent = this.props.LanguageResoures;
        layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
        if (layout == undefined)
            layout = "";
        else
            layout = layout.Value;

        const  passwordVisible:any = this.state.passwordVisible;
        var localLayout: any = localStorage.getItem("siteThemeColor");
        var data_current = window.location.pathname;
        const _array_current = data_current.split('/');
       
        return (
            <React.Fragment>
                <div  className="content loginbackgroundimage">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="account-content">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 login-right">
                                            <div className="login-header">
                                                <h3> </h3>
                                            </div>
                                            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                                <Field name="UserName" component={renderField} label={languagecontent['email']} validate={[this.required, email]} type="email" />
                                                <div className='position-relative'>
                                                   
                                                    <Field name="Password" component={renderField} label={languagecontent['password']} validate={[this.required, password]} type={passwordVisible ? "text":"password"} />

                                                    <i onClick={this.handleToggle} className={passwordVisible ? "password-icon fas fa-eye-slash" : "password-icon fas fa-eye"}></i>
                                                </div>                                                      
                                                <div className="text-right">
                                                    <NavLink className="forgot-link" to="/forgotpassword">{languagecontent['forgetpassword']}</NavLink>
                                                </div>
                                                <button className="btn btn-primary btn-block btn-lg login-btn" disabled={this.props.isLoginLoading} type="submit">
                                                    {this.props.isLoginLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {languagecontent['signin'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['signin']}
                                                </button>
                                                <div className="text-center dont-have">{languagecontent['sigindescription']}
                                                    {localLayout == "Nav247DCT" ?
                                                        <a className="p-10 linkcustomcolor" href="https://247dct.org/get-started/">{languagecontent['signup']}</a>
                                                        : <NavLink className="p-10 linkcustomcolor" to="/signup">{languagecontent['signup']}</NavLink>
                                                     }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        layout == "NavSanthigram" ?
                            <div className="row visible-mobile">
                                <div className="col-sm-12">
                                    <img src="client/default/img/loginresp.png" style={{ marginBottom: "-18px" }} className="img-fluid" />
                                </div>
                            </div>
                            : ""
                    }
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = { ...LoginStore.actionCreators, ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...MenuHideStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => ({ ...state.login, ...state.navDefault, ...state.siteDetail, ...state.langResources, ...state.menuHide })
export default reduxForm({
    form: 'loginForm',
})(connect(mapStateToProps, mapDispatchToProps)(Login as any))


