import * as React from 'react';
import ContentLoader from 'react-content-loader';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { ApplicationState } from '../../store';
import * as SuccessStore from '../../store/payment/Success';
import { floatingJS, mobnav, seo } from '../../helper/Script';
import { dateUTCFormat, dateFormat, setTittle, scrollTop } from '../../helper/formvalidation';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as AccountStore from '../../store/siteaccount/Account';
// At runtime, Redux will merge together...
type SuccessProps =
    SuccessStore.SuccessState & AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState // ... state we've requested from the Redux store
    & typeof SuccessStore.actionCreators & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

var languagecontent: any = "";
var preferredtimeslot: any = "";
var pickaprovider: any = "";
class Success extends React.PureComponent<SuccessProps> {
    constructor(props: any) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
        scrollTop();
        mobnav();

    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {

        this.props.getLangResources();
        this.props.requestSuccess(this.props.match.params);
    }
    private renderDoctorList() {
       // console.log(this.props.success.statusId)
        languagecontent = this.props.LanguageResoures;
        preferredtimeslot = this.props.Settings.filter((s: any) => s.name == "Client preferred time slot");
        if (preferredtimeslot.length != 0)
            preferredtimeslot = preferredtimeslot[0].customizeValue;
        else
            preferredtimeslot = "";

        pickaprovider = this.props.Settings.filter((s: any) => s.name == "Auto Provider Selection in PickaProvider");
        if (pickaprovider.length != 0)
            pickaprovider = pickaprovider[0].customizeValue;
        else
            pickaprovider = "";
        var data = window.location.pathname;
        const _array = data.split('/');
        return (
            <React.Fragment>
            <div className="content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="card success-card">
                                <div className="card-body">
                                    {this.props.isLoading == false ?
                                        <div className="success-cont">
                                        <i className="fas fa-check"></i>
                                        {
                                            this.props.success.statusId == "1" && this.props.success.rescheduleRefId == null ?
                                                <div> <h3>
                                                    {languagecontent['appointmentbooked']}
                                                </h3> <br />
                                                    <p className="success-exp">{languagecontent['appointmentbooksuccess']}</p></div> :
                                                this.props.success.statusId == "1" && this.props.success.rescheduleRefId != null ?
                                                    <div><h3>{languagecontent['appointmentrescheduled']}</h3> <br />
                                                        <p className="success-exp">{languagecontent['appointmentreschedulesuccess']} </p>
                                                    </div> :


                                                    this.props.success.statusId == "9" && this.props.success.rescheduleRefId == null ? <div> <h3>
                                                        {languagecontent['appointmentrequest']}
                                                    </h3>
                                                        <br /> <p className="success-exp"> {languagecontent['appointmentrequestsuccess']} </p>
                                                    </div>
                                                        : <div> <h3>
                                                            {languagecontent['appointmentrescheduled']}
                                                        </h3>
                                                            <br /> <p className="success-exp"> {languagecontent['appointmentreschedulerequest']} </p>
                                                        </div>
                                        }

                                        {/*//this.props.success.statusId == "9" && this.props.success.rescheduleRefId == null?*/}
                                        {/*//    <div> <h3>*/}
                                        {/*//        {languagecontent['appointmentrequest']}  */}
                                        {/*//            </h3>*/}
                                        {/*//                <br /> <p className="success-exp"> {languagecontent['appointmentrequestsuccess']} </p>*/}
                                        {/*//    </div>*/}

                                        {/*//    : <div> <h3>*/}
                                        {/*//        {languagecontent['appointmentrescheduled']}*/}
                                        {/*//    </h3>*/}
                                        {/*//        <br /> <p className="success-exp"> {languagecontent['appointmentreschedulerequest']} </p>*/}
                                        {/*//    </div>*/}




                                        <p>{languagecontent['referenceno']}:
                                            <strong> {this.props.success.referenceNo}</strong><br />

                                            {
                                                this.props.success.statusId == "1" && this.props.success.rescheduleRefId == null ?
                                                    <label>
                                                        {
                                                            <label>{languagecontent['appointmentbookedwithprovider']} <strong>{this.props.success.name}</strong></label>
                                                        }
                                                    </label> :

                                                    this.props.success.statusId == "1" && this.props.success.rescheduleRefId != null ?
                                                        <label>
                                                            {
                                                                <label>{languagecontent['appointmentreschedulewithprovider']} <strong>{this.props.success.name}</strong></label>
                                                            }
                                                        </label> :
                                                        <label>
                                                            {
                                                                this.props.success.statusId == "9" && this.props.success.rescheduleRefId != null ? languagecontent['appointmentreschedule'] : languagecontent['appointmentrequested_withoutprovider']
                                                            }

                                                        </label>




                                            }

                                            {
                                                preferredtimeslot == "Yes" && pickaprovider == "No" ?
                                                    <span>
                                                        <br /><strong> {languagecontent['on']}
                                                            <span className="ml-1">{dateFormat(this.props.success.scheduleDate)} </span>
                                                            {languagecontent['at']} <Moment format="LT">{this.props.success.scheduleDate}</Moment> </strong>
                                                        {
                                                            this.props.success.secondaryAppointmentOn != "0001-01-01T00:00:00" && this.props.success.secondaryAppointmentOn != null ?
                                                                <span><br />
                                                                    <strong>&</strong><br />
                                                                    <strong><span className="ml-1">{dateFormat(this.props.success.secondaryAppointmentOn)} </span>
                                                                        {languagecontent['at']} <Moment format="LT">{this.props.success.secondaryAppointmentOn}</Moment></strong>
                                                                   
                                                                </span>
                                                                : ""
                                                        }
                                                    </span>
                                                    :
                                                    <span>
                                                        <br /><strong> {languagecontent['on']}
                                                            <span className="ml-1">{dateFormat(this.props.success.scheduleDate)} </span>
                                                            {languagecontent['at']} <Moment format="LT">{this.props.success.scheduleDate}</Moment> </strong><br />
                                                    </span>
                                            }
                                        </p>

                                        <NavLink className="btn btn-primary" to="/dashboard"><span>{languagecontent['dashboardbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['dashboardbutton']}</span></NavLink>
                                    </div> : ''}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.success, ...state.account, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...SuccessStore.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(Success as any);
