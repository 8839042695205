import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import Moment from 'moment';
import { errorHandling } from '../../helper/ErrorHandling';
import { actionCreators as _paymentProcess } from '../payment/PaymentProcess';
import { actionCreators as _contactRequest } from '../patient/Contact';

const urlParams = new URLSearchParams(window.location.search);
const childid = urlParams.get('childid');
const childname = urlParams.get('childname');

export interface PharmacyListState {
    isLoadingPharmacyList: boolean,
    pharmacyList: PharmacyList;
}

export interface PharmacyList {
    PharmacyModel: PharmacyModel[]
    TotalRecords: any;
}
export interface PharmacyModel {
    Modified: any;
    ModifiedBy: any;
    ModifiedOn: any;
    Pharmacy: Pharmacy;
}
export interface Pharmacy {
    Address1: string;
    Address2: string;
    City: string;
    Coordinates: string;
    ExtRefId: string;
    Id: string;
    Name: string;
    PhoneNo: string;
    State: string;
    TotalRecords: string;
    Zipcode: string;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestPharmacyListAction { type: 'REQUEST_PHARMACY_LIST', pharmacyList: PharmacyList, isLoading: true }
export interface RecivePharmacyListAction { type: 'RECEIVE_PHARMACY_LIST', pharmacyList: PharmacyList, isLoading: false}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestPharmacyListAction | RecivePharmacyListAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestPharmacyList: (state: any, city: any, zipcode: any, pagesize: any, pageindex: any, patientid:any ): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.pharmacyLists) {
            axios.get('/pharmacy/Searchpharmacy/' + state + "/" + city + "/" + zipcode + "/" + pagesize + "/" + pageindex + "/" + "-")
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_PHARMACY_LIST', pharmacyList: result, isLoading: false });
                   
                })
                .catch(function (error) {
                    if (error.response.status == 400) {
                        dispatch({ type: 'RECEIVE_PHARMACY_LIST', pharmacyList: unloadedState.pharmacyList, isLoading: false });
                        if (error.response.data == "Please select State or City or Postal code") {
                            var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            toastr.error('', langdata['pharmacypostalcode'])
                        } else {
                            toastr.error('', error.response.data);
                        }
                    } else {
                        errorHandling(error.response.status);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_PHARMACY_LIST', isLoading:true });
        }
    },
    requestPharmacyPost: (memberid:any, pharmacyid:any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState:any = getState();
        if (appState && appState.pharmacyLists) {
            var data = { MemberId: memberid, PharmacyId: pharmacyid}
            axios.post('pharmacy',data)
                .then(function (response) {
                    var patientLocalStroage: any = localStorage.getItem('Patient');
                    var patientstore: any = JSON.parse(patientLocalStroage);
                    const urlParams = new URLSearchParams(window.location.search);
                    const methodid = urlParams.get('methodid');
                    const type = urlParams.get('types') || urlParams.get('type') ;
                    const appointmentid = urlParams.get('appointmentid');
                    const doctorid = urlParams.get('doctorid');
                    var paidAmount = localStorage.getItem("paidAmount");
                    dispatch(_contactRequest.requestPatient());
                   
                    if (type == "appointment") {
                        if ((paidAmount == "0") || (paidAmount == "0.00") || (paidAmount == null)) {
                            dispatch(_paymentProcess.requestScheduleUpdateFree(appointmentid), _paymentProcess)
                        } else {
                            var online: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Online)")[0];
                            var inperson: any = appState.account.Settings.filter((se: any) => se.name == "Self-pay (Inperson)")[0];
                            var insurance: any = appState.account.Settings.filter((se: any) => se.name == "Insurance")[0];
                            if ((online.customizeValue == "Yes") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/insurancelist?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "Yes")) {
                                dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                            } else if ((online.customizeValue == "No") && (inperson.customizeValue == "Yes") && (insurance.customizeValue == "No")) {
                                //dispatch(push("/paymethod?doctorid=" + doctorid + "&appointmentid=" + appointmentid + "&name=" + patientstore.FirstName + " " + patientstore.LastName + "&email=" + patientstore.Email + "&methodid=" + methodid + "&childid=" + childid + "&childname=" + childname))
                            } else if ((online.customizeValue == "Yes") && (inperson.customizeValue == "No") && (insurance.customizeValue == "No")) {
                                dispatch(push("/paymentprocess/" + doctorid + "/" + appointmentid + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                            } else {
                                dispatch(push("/paymentprocess/" + doctorid + "/" + appointmentid + "/" + patientstore.FirstName + " " + patientstore.LastName + "/" + patientstore.Email));
                            }
                        }
                    } else if (type == "profile") {
                        return dispatch(push("/profilesettings/" + patientstore.memberId))
                    } else {
                        return;
                    }
                    dispatch({ type: 'RECEIVE_PHARMACY_LIST', pharmacyList: unloadedState.pharmacyList, isPhaLoading: true });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_PHARMACY_LIST', isLoadingPharmacyList: true });
        }
    },
    requestReset: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        dispatch(reset('searchPharmacyList'))
        setTimeout(function () {
            dispatch({ type: 'RECEIVE_PHARMACY_LIST', pharmacyList: unloadedState.pharmacyList });
        }, 1);
        dispatch({ type: 'REQUEST_PHARMACY_LIST' });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: PharmacyListState = {
    pharmacyList: {
        PharmacyModel: [],
        TotalRecords: 0
    },
    isLoadingPharmacyList: false
};
export const reducer: Reducer<PharmacyListState> = (state: PharmacyListState | undefined, incomingAction: Action): PharmacyListState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PHARMACY_LIST':
            return {
                pharmacyList: state.pharmacyList,
                isLoadingPharmacyList: true
            };
        case 'RECEIVE_PHARMACY_LIST':
            return {
                pharmacyList: action.pharmacyList,
                isLoadingPharmacyList: false
            };
        default:
            return state;
    }
};
