import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';


const myappointments = () => (
	<React.Fragment>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
				<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
					<div className="profile-sidebar">
						<div className="widget-profile pro-widget-content">
							<div className="profile-info-widget">
								<a href="#" className="booking-doc-img">
									<img src="client/logo/pro.jpg" alt="User Image" />
								</a>
								<div className="profile-det-info">
									<h3>Richard Wilson</h3>
									<div className="patient-details">
										<h5><i className="fas fa-birthday-cake"></i> 24 Jul 1983, 38 years</h5>
										<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Newyork, USA</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboard-widget">
							<nav className="dashboard-menu">
								<ul>
									<li>
										<NavLink  className="nav-link header-login log_btn" to="/dashboard"><i className="fas fa-columns"></i>
											<span>Dashboard</span></NavLink>
									</li>
									<li className="active">
										<NavLink  className="nav-link header-login log_btn" to="/myappointments"><i className="fas fa-user-cog"></i>
											<span>My Appointments</span></NavLink>
									</li>

									<li>
										<NavLink  className="nav-link header-login log_btn" to="/message"><i className="fas fa-comments"></i>
											<span>Message</span><small className="unread-msg">23</small></NavLink>

									</li>
									<li>
										<NavLink  className="nav-link header-login log_btn" to="/profilesettings"><i className="fas fa-user-cog"></i>
											<span>Profile Settings</span></NavLink>
									</li>
									<li>
										<NavLink  className="nav-link header-login log_btn" to="/changepassword"><i className="fas fa-lock"></i>
											<span>Change Password</span></NavLink>
									</li>
									<li>
										<NavLink  className="nav-link header-login log_btn" to="/login"><i className="fas fa-sign-out-alt"></i>
											<span>Logout</span></NavLink>

									</li>
								</ul>
							</nav>
						</div>

					</div>

				</div>


				<div className="col-md-7 col-lg-8 col-xl-9">
					<div className="row"><div className="col-md-12">
						<div className="pb-5">
							<h4 className="widget-title pat-widget-title ng-binding">My Appointments</h4>
							<div id="pat_appointments" className="tab-pane fade show active">
								<div className="card card-table mb-0">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-hover table-center mb-0">
												<thead></thead>
												<tbody>
													<tr>
														<td>
															<h2 className="table-avatar">
																<a href="doctor-profile.html" className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src="client/logo/pro.jpg" alt="User Image" />
																</a>
																<NavLink className="header-login log_btn" to="/DoctorProfile">Dr. Ruby Perrin Dental</NavLink><br />
																<span>Dental</span>
															</h2>
														</td>
														<td>14 Nov 2019 <span className="d-block text-info">10.00 AM</span></td>
														<td>12 Nov 2019</td>
														<td>$160</td>
														<td>16 Nov 2019</td>
														<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
														<td className="text-right">
															<div className="table-action">
																<a href="/" className="btn btn-sm bg-primary-light">
																	<i className="fas fa-print"></i> Print
																			</a>
																<a href="/" className="btn btn-sm bg-info-light">
																	<i className="far fa-eye"></i> View
																			</a>
															</div>
														</td>
													</tr>
													<tr>
														<td>
															<h2 className="table-avatar">
																<a href="doctor-profile.html" className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src="client/logo/pro.jpg" alt="User Image" />
																</a>
																<a href="doctor-profile.html">Dr. Deborah Angel <span>Cardiology</span></a>
															</h2>
														</td>
														<td>11 Nov 2019 <span className="d-block text-info">11.00 AM</span></td>
														<td>10 Nov 2019</td>
														<td>$400</td>
														<td>13 Nov 2019</td>
														<td><span className="badge badge-pill bg-danger-light">Cancelled</span></td>
														<td className="text-right">
															<div className="table-action">
																<a href="/" className="btn btn-sm bg-primary-light">
																	<i className="fas fa-print"></i> Print
																			</a>
																<a href="/" className="btn btn-sm bg-info-light">
																	<i className="far fa-eye"></i> View
																			</a>
															</div>
														</td>
													</tr>

													<tr>
														<td>
															<h2 className="table-avatar">
																<a href="doctor-profile.html" className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src="client/logo/pro.jpg" alt="User Image" />
																</a>
																<a href="doctor-profile.html">Dr. Marvin Campbell <span>Ophthalmology</span></a>
															</h2>
														</td>
														<td>9 Nov 2019 <span className="d-block text-info">7.00 PM</span></td>
														<td>8 Nov 2019</td>
														<td>$75</td>
														<td>11 Nov 2019</td>
														<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
														<td className="text-right">
															<div className="table-action">
																<a href="/" className="btn btn-sm bg-primary-light">
																	<i className="fas fa-print"></i> Print
																			</a>
																<a href="/" className="btn btn-sm bg-info-light">
																	<i className="far fa-eye"></i> View
																			</a>
															</div>
														</td>
													</tr>
													<tr>
														<td>
															<h2 className="table-avatar">
																<a href="doctor-profile.html" className="avatar avatar-sm mr-2">
																	<img className="avatar-img rounded-circle" src="client/logo/pro.jpg" alt="User Image" />
																</a>
																<NavLink className="header-login log_btn" to="/DoctorProfile">Dr. Ruby Perrin Dental</NavLink><br />
																<span>Dental</span>
															</h2>
														</td>
														<td>14 Nov 2019 <span className="d-block text-info">10.00 AM</span></td>
														<td>12 Nov 2019</td>
														<td>$160</td>
														<td>16 Nov 2019</td>
														<td><span className="badge badge-pill bg-success-light">Confirm</span></td>
														<td className="text-right">
															<div className="table-action">
																<a href="/" className="btn btn-sm bg-primary-light">
																	<i className="fas fa-print"></i> Print
																			</a>
																<a href="/" className="btn btn-sm bg-info-light">
																	<i className="far fa-eye"></i> View
																			</a>
															</div>
														</td>
													</tr>


												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-md-5 col-lg-5 col-12">
								<p className="recod-showing">Showing 1 to 5 of 5 records</p>
							</div>
							<div className="col-md-7 col-lg-7 col-12">
								<div>
									<ul className="pagination">
										<li className="page-item">
											<a className="page-link" href="#" aria-label="Previous">
												<span aria-hidden="true">&laquo;</span>
												<span className="sr-only">Previous</span>
											</a>
										</li>
										<li className="page-item">
											<a className="page-link" href="#" aria-label="Previous">
												<span aria-hidden="true">&lt;</span>
												<span className="sr-only">Previous</span>
											</a>
										</li>
										<li className="page-item"><a className="page-link" href="#">1</a></li>
										<li className="page-item"><a className="page-link" href="#">2</a></li>
										<li className="page-item"><a className="page-link" href="#">3</a></li>
										<li className="page-item">
											<a className="page-link" href="#" aria-label="Next">
												<span aria-hidden="true">&gt;</span>
												<span className="sr-only">Next</span>
											</a>
										</li>
										<li className="page-item">
											<a className="page-link" href="#" aria-label="Next">
												<span aria-hidden="true">&raquo;</span>
												<span className="sr-only">Next</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>


						

					</div></div>
				</div>
            </div>
        </div>
    </div>
	</React.Fragment>


);

export default connect()(myappointments);