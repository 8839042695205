import axios from 'axios';
import { Action, Reducer } from 'redux';
import { errorHandling } from '../helper/ErrorHandling';
import { AppThunkAction } from './index';


export interface PublicContentState {
    isLoading: boolean;
    publicContent: PublicContent;
}

export interface PublicContent {
    ID: any;
    Content: any;
    PageTitle: any;
    SiteName: any;
    SiteURL: any;
}

interface RequestPublicContentAction {
    type: 'REQUEST_PUBLIC_CONTENT';
}

interface ReceivePublicContentAction {
    type: 'RECEIVE_PUBLIC_CONTENT';
    publicContent: PublicContent;
}

type KnownAction = RequestPublicContentAction | ReceivePublicContentAction;

export const actionCreators = {
    requestPublicContent: (keyword: any): AppThunkAction<KnownAction> => (dispatch:any, getState:any) => {
        const appState = getState();
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        if (appState && appState.publicContent) {
            axios.get('/site/getpubliccontent/' + keyword + '/' + 2 + '/' + languagecode)
                .then(function (response) {
                    const data = response.data;
                    if (data.Contents.length > 0) {
                        dispatch({
                            type: 'RECEIVE_PUBLIC_CONTENT', publicContent: data.Contents[0], isLoading: false
                        });
                    }
                    else {
                        return window.location.href = "/comingSoon";
                    }
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_PUBLIC_CONTENT', isLoading:true });
        }
    }
};


const UnloadedState: PublicContentState = {
    publicContent: { ID: "", Content: "", PageTitle: "", SiteName: "", SiteURL:""}, isLoading: false
};


export const reducer: Reducer<PublicContentState> = (state: PublicContentState | undefined, incomingAction: Action): PublicContentState => {
    if (state === undefined) {
        return UnloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PUBLIC_CONTENT':
            return {
                publicContent: state.publicContent,
                isLoading: true
            };
        case 'RECEIVE_PUBLIC_CONTENT':
            return {
                publicContent: action.publicContent,
                isLoading: false
            };
        default:
            return state;
    }
};
