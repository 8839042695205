import { title } from 'process';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { scrollTop } from '../../../helper/formvalidation';
import { seo } from '../../../helper/Script';
import Questionnaire from '../questionnaire/SpecialityQuestion';
import HeaderDrProfile from '../../widget/HeaderDrProfile';
type CounterProps = RouteComponentProps<{}>;

class Questions extends React.PureComponent<CounterProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {

        seo({
            title: 'AUD',
            pageTitle: 'AUD',
            description: ''
        });
        scrollTop();
    }
    componentDidUpdate() {
        seo({
            title: 'AUD',
            pageTitle: 'AUD',
            description: '',

        });
       scrollTop();
    }
   
    public render() {
        return (
            //var formList: any = [];

            <React.Fragment>
                <div className="content">
                    <div className="container">
                        <HeaderDrProfile />
                        <div >

                            <Questionnaire />

                        </div>
                    </div>


                </div>
            </React.Fragment>
        );
    }
};
export default connect(

)(Questions);
