import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import * as NavDefaultStore from '../../../store/menu/NavDefault';
import * as LoginStore from '../../../store/account/Login';
import * as NotifyBarStore from '../../../store/library/topbar/notify';

var authLocalStroage: any = localStorage.getItem('isAuth');

type TopBarNotifyProps = NavDefaultStore.NavDefaultState & LoginStore.LoginState & NotifyBarStore.NotifyBarState & typeof NotifyBarStore.actionCreators & RouteComponentProps

class NotifyBar extends React.PureComponent<TopBarNotifyProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps: TopBarNotifyProps) {
        if (this.props.location !== prevProps.location) {
            this.ensureDataFetched();
        }
    }

    private ensureDataFetched() {
        var isAuthenticated: any = localStorage.getItem("isAuth");
        if (isAuthenticated == 'true') {
            this.props.getNotify();
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderNotify()}
            </React.Fragment>
        );
    }

    public renderNotify() {
        return (
            <React.Fragment>
                {this.props.navDefault.isAuth == false || authLocalStroage == false ?
                    '' :
                    <div className={this.props.message == '' || this.props.message == null ? 'd-none' : 'top-bar-notification'}>
                        {this.props.message}
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default withRouter(connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.login, ...state.notifyBar }), // Selects which state properties are merged into the component's props
    ({ ...NotifyBarStore.actionCreators }) // Selects which action creators are merged into the component's props
)(NotifyBar as any));
