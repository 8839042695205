import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { email, required, setTittle } from '../../helper/formvalidation';
import { renderField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as ForgotPasswordStore from '../../store/account/ForgotPassword';
import * as HandleRequest from '../../helper/Request';
import { floatJS, mobnav,seo } from '../../helper/Script';
import { NavLink } from 'react-router-dom';
import * as MenuHideStore from '../../store/menuHide';
import * as SiteDetailStore from '../../store/SiteDetail';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { TextBoxLoader } from '../../helper/fbloader';
type ForgotPasswordProps =
    ForgotPasswordStore.ForgotPasswordState & LanguageResourcesStore.LanguageResouresState & LanguageResourcesStore.LanguageResouresState & SiteDetailStore.SiteDetailState & MenuHideStore.MenuHideState & HandleRequest.Request &
    typeof ForgotPasswordStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof MenuHideStore.actionCreators &
    RouteComponentProps<{}>;
var languagecontent: any = "";
var layout: any = "";
class ForgotPassword extends React.PureComponent<ForgotPasswordProps> {
    constructor(props: any) {
        super(props);
    }

	onSubmit = (formValues: any, dispatch: any, props: any) => {
		this.props.requesForgetPassword(formValues)
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderChangePassword()}
			</React.Fragment>
		);
	}

    public componentDidMount() {
        floatJS();
        mobnav();
        this.ensureDataFetched();
    }
   componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    private ensureDataFetched() {
        this.props.requestMenuHide(false);
        this.props.getLangResources();
    }
    public renderChangePassword() {
        var localLayout: any = localStorage.getItem("siteThemeColor");
        languagecontent = this.props.LanguageResoures;
        layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
        if (layout == undefined)
            layout = "";
        else
            layout = layout.Value;
		return (
			<React.Fragment>
                <div className="content forgotbackgroundimage">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <div className="account-content">
                                    <div className="row align-items-center justify-content-center ">

                                        <div className="col-md-12 col-lg-8 login-right">
                                            <div className="login-header">
                                                <h3 className="mb-3"> </h3>
                                                <p className="text-left">{languagecontent['emaildescription']}</p>
                                            </div>
                                            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                                {languagecontent['forgotdescription']?
                                                    <Field name="Email" component={renderField} label={languagecontent['email']} maxLength={50} validate={[required, email]} type="email" /> : <TextBoxLoader />}
                                                <div className="text-right">
                                                    <NavLink className="forgot-link" to="/login">{languagecontent['rememberpassword']}</NavLink>
                                                </div>
                                                <button className="btn btn-primary btn-block btn-lg login-btn" disabled={this.props.isFPLoading} type="submit">
                                                    {this.props.isFPLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['submitbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['submitbutton']}
                                                </button>
                                                {
                                                    languagecontent['forgotdescription'] == undefined ? "" :
                                                        <React.Fragment>
                                                            <div className="text-center dont-have">{languagecontent['forgotdescription']}
                                                                {localLayout == "Nav247DCT" ?
                                                                    <a className="p-10 linkcustomcolor" href="https://247dct.org/get-started/">{languagecontent['clickhere']}</a> :
                                                                    <NavLink className="nav-link header-login log_btn p-10 pr-0 pl-1 linkcustomcolor" to="/signup">{languagecontent['clickhere']}</NavLink>} {languagecontent['tosignup'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['tosignup']}
                                                            </div>
                                                        </React.Fragment>
                                                }
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        layout == "NavSanthigram" ?
                            <div className="row visible-mobile">
                                <div className="col-sm-12">
                                    <img src="client/default/img/forgotpasswordresp.png" style={{ marginBottom: "-18px" }} className="img-fluid" />
                                </div>
                            </div>
                            : ""
                    }
                </div>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = { ...ForgotPasswordStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...SiteDetailStore.actionCreators, ...MenuHideStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => ({ ...state.forgotPassword, ...state.langResources, ...state.menuHide, ...state.siteDetail })
export default reduxForm({
    form: 'forgotPasswordForm',
})(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword as any))


