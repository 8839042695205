
import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../index';
import { errorHandling } from '../../helper/ErrorHandling';

export interface AddMessageState {
    isCPLoading: boolean;
    addMessage: AddMessage;
    

}

export interface ReciptentListState {
    isLoading: boolean,
    reciptentLists: ReciptentList[]
}

export interface AddMessage {
    reciptent: string;
    subject: string;
    message: string;
}

export interface ReciptentList {
    name: string;
    value: string;

}


interface RequestAddMessage {
    type: 'REQUEST_ADD_MESSAGE';
    addMessage: AddMessage;
}

interface ReceiveAddMessage {
    addMessage: AddMessage;
    type: 'RECEIVE_ADD_MESSAGE';


}



export interface RequestReciptentListAction { type: 'REQUEST_RECIPTENT_LIST', reciptentLists: ReciptentList[] }
export interface ReciveReciptentListAction { type: 'RECEIVE_RECIPTENT_LIST', reciptentLists: ReciptentList[] }



type KnownAction = RequestAddMessage | ReceiveAddMessage |RequestReciptentListAction | ReciveReciptentListAction;

export const actionCreators = {

    postMessage: (formvalue: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        var accountid: any = localStorage.getItem("AccountId");
        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");

        dispatch(showLoading('sectionBar'))
        if (appState && appState.addMessage) {
            var data = {
                FromMemberId: patient.memberId,
                ToMemberId: formvalue.reciptent ? formvalue.reciptent : null,
                /*ToMemberId: null,*/
                Subject: formvalue.Subject,
                Content: formvalue.Message,
                RecordId: patient.id,
                RecordTypeId: 1,
                AccountId: accountid,
            };

            axios.post('/Message/PostMessage', data)
                .then(function (response) {
                    const data = response.data;
                    dispatch(hideLoading('sectionBar'))
                    var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    toastr.success('', langdata['messagesubmittedsuccesfully'])
                    

                    dispatch({ type: 'RECEIVE_ADD_MESSAGE', isCPLoading: false });

                    dispatch({ ...hideLoading('sectionBar') })
                    dispatch(reset("addMessageForm"));
                    dispatch(push("/message" ));
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.error_description) {
                            toastr.error('', errorObject.error_description);
                        } errorHandling(error.response.data.statusCode);

                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ type: 'RECEIVE_ADD_MESSAGE', isCPLoading: false });
                    dispatch({ ...hideLoading('sectionBar') })
                })
                .then(function () {
                });

            dispatch({ type: 'REQUEST_ADD_MESSAGE', isCPLoading: true });
        }
    },
     getReciptentList: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {

        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var langcode = localStorage.getItem("DefaultLangCode");

        var specialityid = '-'
        var Selectdate = '-'
        var doctorName = '-'


        if (appState && accountid != "" && appState.reciptentList) {
            axios.get('/doctor/GetSearchDoctor/' + accountid + "/" + specialityid + "/" + Selectdate + "/" + doctorName + "/" + '-' + "/" + langcode)
                .then(function (response) {
                    const users = response.data;

                    const temp: any = []

                    users.map((list: any) => {
                        temp.push({ value: list.doctorId, name: list.doctorName })
                    })

                    dispatch({ type: 'RECEIVE_RECIPTENT_LIST', reciptentLists: temp });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_RECIPTENT_LIST' });
        }
    },
};

const UnloadedState: AddMessageState = {
    addMessage: {
        reciptent: "",
        subject: "",
        message: "",
    },
    isCPLoading: false,

};

const UnloadedListState: ReciptentListState = {
    reciptentLists: [],
    isLoading: false

};

export const addMessagereducer: Reducer<AddMessageState> = (state: AddMessageState | undefined, incomingAction: Action): AddMessageState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ADD_MESSAGE':
            return {
                addMessage: state.addMessage,
                isCPLoading: true,

            };
        case 'RECEIVE_ADD_MESSAGE':
            return {
                addMessage: action.addMessage,
                isCPLoading: false,


            };

        default:
            return state;
    }
};

export const reducer: Reducer<ReciptentListState> = (state: ReciptentListState | undefined, incomingAction: Action): ReciptentListState => {
    if (state === undefined) {
        return UnloadedListState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_RECIPTENT_LIST':
            return {
                reciptentLists: state.reciptentLists,
                isLoading: true
            };
        case 'RECEIVE_RECIPTENT_LIST':
            return {
                reciptentLists: action.reciptentLists,
                isLoading: false
            };
        default:
            return state;
    }
};

