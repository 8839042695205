import axios from "axios";
import { push } from "connected-react-router";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { toastr } from "react-redux-toastr";
import { reset } from "redux-form";
import { errorHandling } from "../../helper/ErrorHandling";
/*const toastrConfirmOptions = {
    onOk: () => { dispatch(push("/forgotpassword")) }
};*/

//const toastrConfirmOptions = {
//    okText: 'Get Link',
//    onOk: () => window.location.href = "/forgotpassword",
//};

export const resetPassword = (userObj: any) => {
    var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    return (dispatch: any) => {
        dispatch(showLoading('sectionBar'))
        axios.post('account/resetPassword', userObj)
            .then(response => {
                toastr.success('', langdata['successfuly'])
                dispatch({
                    type: 'RESET_PASSWORD',
                    payload: response.data
                })
                dispatch(reset("resetPasswordForm"));
                dispatch(hideLoading('sectionBar'))
                setTimeout(() => {
                    dispatch(push("/login"))
                }, 3000)
            })
            .catch(error => {
                if (error.response.data.statusCode == 400) {
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    if (errorObject.ModelState != undefined) {
                            toastr.error('', errorObject.ModelState[""][0]);
                    } else {
                        toastr.error('', errorObject.Message);
                    }
                } else {
                    if (error.response.data.statusCode == 500) {
                        toastr.success('', langdata['successfuly']);
                        /*  toastr.confirm("Link has expired. Please get a new link", toastrConfirmOptions);*/
                        setTimeout(() => {
                            dispatch(push("/login"))
                        }, 3000)
                    }
                    else {
                        errorHandling(error.response.data.statusCode);
                    }  
                }
                
                dispatch(hideLoading('sectionBar'))
        });
    }
}