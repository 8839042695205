import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface InsuranceAutoCompleteState {
    isinsuranceLoading: boolean,
    InsuranceAutoCompletes: InsuranceAutoComplete[]
}

export interface InsuranceAutoComplete {
    Id: string;
    Name: string;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestInsuranceAutoCompleteAction { type: 'REQUEST_INSURANCE_AUTO', InsuranceAutoCompletes: InsuranceAutoComplete[] }
export interface ReciveInsuranceAutoCompleteAction { type: 'RECEIVE_INSURANCE_AUTO', InsuranceAutoCompletes: InsuranceAutoComplete[] }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestInsuranceAutoCompleteAction | ReciveInsuranceAutoCompleteAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestInsurance: (keyword: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.insuranceAutoCompletes) {
            var accountid = localStorage.getItem("AccountId");
            axios.get('/autocomplete/SearchInsurance/' + keyword + "/" + accountid)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_INSURANCE_AUTO', InsuranceAutoCompletes: result });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_INSURANCE_AUTO' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: InsuranceAutoCompleteState = {
    InsuranceAutoCompletes: [],
    isinsuranceLoading: false
};
export const reducer: Reducer<InsuranceAutoCompleteState> = (state: InsuranceAutoCompleteState | undefined, incomingAction: Action): InsuranceAutoCompleteState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_INSURANCE_AUTO':
            return {
                InsuranceAutoCompletes: state.InsuranceAutoCompletes,
                isinsuranceLoading: true
            };
        case 'RECEIVE_INSURANCE_AUTO':
            return {
                InsuranceAutoCompletes: action.InsuranceAutoCompletes,
                isinsuranceLoading: false
            };
        default:
            return state;
    }
};
