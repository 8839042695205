import * as React from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import { ApplicationState } from '../../../../store/index';
import Moment from 'moment';
type FooterStoreProps = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & RouteComponentProps;
class FtrVirtuvets extends React.PureComponent<FooterStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        //this.props.requestSiteDetail();
    }

    public renderFooter() {
        loadChatJS();
        return (
            <React.Fragment>
                <footer className="footer vr_footer">
                <div className="footer-top">
                        <div className="container vr_container">
                            <div className="row">
                                <div className="col-lg-1 col-xs-12 tab-none">
                                    <div className="author-img-wrap">
                                        <a href="https://www.virtuvets.com/"><img className="vrimg-fluid" alt="" src="client/logo/VrFooterLogo.png" /></a>
                                    </div>
                                    </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget footer-about">
                                                <div className="footer-about-content">
                                            <div className="details">
                                                <div className="tab-block">
                                                    <a href="https://www.virtuvets.com/"><img className="vrimg-fluid" alt="" src="https://www.virtuvets.com/wp-content/uploads/2021/03/MicrosoftTeams-image-14-232x300.png" /></a>
                                                </div>
                                                        <p className="mb-0">
                                                            VirtuVets instantly connects pet parents with a licensed veterinarian on a video or voice call. Available anywhere, anytime for a flat $39.99 fee.
                                                        </p>
                                                        </div>
                                            </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6">
                                <div className="footer-widget footer-menu">

                                    <ul>
                                            <li><a href="https://www.virtuvets.com/">Home</a></li>
                                            <li><a href="https://www.virtuvets.com/about-us/">About Us</a></li>
                                            <li><a href="https://www.virtuvets.com/faq">FAQ Page</a></li>
                                            <li><a href="https://www.virtuvets.com/for-vets/">For Vets</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget footer-menu mob-mb-30">
                                    <ul>
                                            <li><a href="https://www.virtuvets.com/blog/">Our Blog</a></li>
                                            <li><a href="https://www.virtuvets.com/terms-conditions/">Terms & Conditions</a></li>
                                            <li><a href="https://www.virtuvets.com/privacy-policy/">Privacy Policy</a></li>
                                            <li><a href="https://www.virtuvets.com/refund-policy/">Refund Policy</a></li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="footer-widget footer-contact">

                                    <div className="footer-contact-info">
                                        <div className="social-icon">
                                            <p> Follow Us on Social </p>
                                            <ul>
                                                <li>
                                                      <a href="https://www.instagram.com/virtuvets/" target="_blank"><i className="fab fa-instagram"></i></a>
                                                </li>
                                                <li>
                                                      <a href="https://www.facebook.com/virtuvets" target="_blank"><i className="fab fa-facebook"></i> </a>
                                                </li>
                                                <li>
                                                      <a href="https://www.linkedin.com/company/virtuvets/" target="_blank"><i className="fab fa-linkedin"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <br />

                                        <div>
                                            <p>We Accept</p>
                                                <ul className="footer vrfooter nav">
                                                <li>
                                                    <span><i aria-hidden="true" className="fab fa-cc-visa"></i>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span><i aria-hidden="true" className="fab fa-cc-mastercard"></i>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span><i aria-hidden="true" className="fab fa-cc-amex"></i>
                                                    </span>
                                                </li>
                                                <li>
                                                    <span><i aria-hidden="true" className="fab fa-cc-discover"></i>
                                                    </span>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                        <div className="container  vr_container">
                        <div className="copyright">
                            <div className="row">
                                <div className="col-md-6 col-lg-6">
                                    <div className="copyright-text">
                                            <p className="mb-0">© {Moment().format('YYYY')} VirtuVets LLC</p>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="copyright-text text-right">
                                        <div className="trustedsite-trustmark" data-type="202" data-width="120" data-height="50"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.siteDetail }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators }) // Selects which action creators are merged into the component's props
)(FtrVirtuvets as any);

function loadChatJS() {
    var s: any = document.createElement("script");
    s.type = "text/javascript";
    s.src = "//fw-cdn.com/10947674/3705331.js";
    s.chat = true;
    $("head").append(s);
}
