import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const HealthProfile = () => (
	<React.Fragment>
	<div className="content">
		<div className="container">

			<div className="card bg_color">
				<div className="card-body">
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<div className="card-body">
								<div className="doctor-widget">
									<div className="doc-info-left">
										<div className="doctor-img">
											<img src="client/logo/drr.png" className="img-fluid" alt="User Image" />
										</div>
										<div className="doc-info-cont">
											<h4 className="doc-name">Dr. Darren Elder</h4>
											<p className="doc-speciality">Oral & Maxillofacial Surgery</p>

											<div className="clinic-details">
												<p className="doc-location"><i className="fas fa-map-marker-alt"></i> Newyork, USA</p>

											</div>

										</div>
									</div>
									<div className="doc-info-right">
										<div className="clini-infos">
											<ul>

												<li><i className="far fa-calendar-alt"></i> 18/09/2020 </li>
												<li><i className="far fa-comment-alt"></i> Chat Consultation </li>
											</ul>
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 col-lg-12">
							<h5>Vitals</h5>
							<form className="p-15" autoComplete={"off"}>
								<div className="col-md-12">
									<div className="row">
									<div className="col-md-6">
										<div className="row">
												<div className="col-md-6 col-6">
											<div className="form-group form-focus"><input name="Height" type="password" className="form-control floating" value="" />
											<label className="focus-label">Height</label></div>
										</div>
												<div className="col-md-6 col-6">
												<div className="form-group form-focus"><select className="form-control select">
													<option>Cm</option>
												</select>
												<label className="focus-label">Select</label></div>
										</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
												<div className="col-md-6 col-6">
												<div className="form-group form-focus"><input name="weight" type="password" className="form-control floating" value="" />
													<label className="focus-label">Weight</label></div>
											</div>
												<div className="col-md-6 col-6">
												<div className="form-group form-focus"><select className="form-control select">
													<option>Kg</option>
												</select>
													<label className="focus-label">Select</label></div>
											</div>
										</div>
									</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-6 col-6">
													<div className="form-group form-focus"><input name="Height" type="password" className="form-control floating" value="" />
														<label className="focus-label">Temperature</label></div>
												</div>
												<div className="col-md-6 col-6">
													<div className="form-group form-focus"><select className="form-control select">
														<option>F</option>
													</select>
														<label className="focus-label">Select</label></div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-4 col-4">
													<div className="form-group form-focus"><input name="weight" type="password" className="form-control floating" value="" />
														<label className="focus-label">BP</label></div>
												</div>
												<div className="col-md-4 col-4">
													<div className="form-group form-focus"><input name="weight" type="password" className="form-control floating" value="" />
														<label className="focus-label">BP</label></div>
												</div>
												<div className="col-md-4 col-4">
													<div className="form-group form-focus"><select className="form-control select">
														<option>mmHg</option>
													</select>
														<label className="focus-label">Select</label></div>
												</div>
											</div>
										</div>
									</div>
								</div>


								<div className="col-md-12">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-6 col-6">
													<div className="form-group form-focus"><input name="Height" type="password" className="form-control floating" value="" />
														<label className="focus-label">SpO2</label></div>
												</div>
												<div className="col-md-6 col-6">
													<div className="form-group form-focus"><select className="form-control select">
														<option>%</option>
													</select>
														<label className="focus-label">Select</label></div>
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-12 pad-15">
													<div className="form-group form-focus"><input name="weight" type="password" className="form-control floating" value="" />
														<label className="focus-label">BCM</label></div>
												</div>
												
											</div>
										</div>
									</div>
								</div>


								<div className="col-md-12">
									<div className="row">
										<div className="col-md-6">
											<div className="row">
												<div className="col-md-6 col-6">
													<div className="form-group form-focus"><input name="Height" type="password" className="form-control floating" value="" />
														<label className="focus-label">ECG BPM</label></div>
												</div>
												<div className="col-md-6 col-6">
													<div className="form-group form-focus"><select className="form-control select">
														<option>BPM</option>
													</select>
														<label className="focus-label">Select</label></div>
												</div>
											</div>
										</div>
										
									</div>
								</div>



								<h5>Allergy</h5>
								<div className="form-group form-focus">
									<input name="Height" type="password" className="form-control floating" value="" />
									<label className="focus-label">Are you allergic to any of the following?</label>
								</div>

								<h5>Medical History</h5>
								<div className="form-group form-focus">
									<textarea className="form-control" style={{ height: "70px", marginBottom: "10px" }} />
									<label className="focus-label">Enter medical conditions</label>
								</div>



								<div className="text-left dont-have mb-20">(Please list all your current & past medical conditions. For examples:Diablets, Asthma, etc..)</div>
								<div className="row">
									<div className="submit-section text-left col-md-6">
									<button type="submit" className="btn btn-primary submit-btn">Back</button>
								</div>
									<div className="submit-section text-right col-md-6">
									<button type="submit" className="btn btn-primary submit-btn">Continue</button>
								</div>
								</div>
							</form>

						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
		</React.Fragment>


);

export default connect()(HealthProfile);