import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as ContactStore from '../../store/patient/Contact';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import { seo } from '../../helper/Script';
import ContentLoader from 'react-content-loader';
import Moment from 'moment';
import * as LanguageResourcesStore from '../../store/LanguageResoures';

import { dateFormat, dateUTCFormat, dateFormatConvert, timeFormatConvert, dateTimeFormat, setTittle, scrollTop } from '../../helper/formvalidation';
// At runtime, Redux will merge together...
type DocumentListProps =
    FlexiFormStore.FlexiListsState & ContactStore.ContactState & LanguageResourcesStore.LanguageResouresState // ... state we've requested from the Redux store
    & typeof FlexiFormStore.actionCreators & typeof ContactStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var patientLocalStroage: any = localStorage.getItem('Patient');
var pat = JSON.parse(patientLocalStroage);
var languagecontent: any = "";
var langdata: any = "";
class DynamicForms extends React.PureComponent<DocumentListProps> {
    constructor(props: any) {
        super(props);
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
        scrollTop();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestModuleForm("4", pat['id']);
        this.props.getLangResources();
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }

    loadForm(modulename: any, id: any, formClientTitle: any) {
        this.props.requestFormDisplay(modulename, this.props.contact.id, this.props.contact.id, id, formClientTitle);
    }
    convertDate(time: any) {

        return Moment.utc(time).utcOffset(+parseInt(localStorage.getItem('timeOffset') || "")).format('D MMM YYYY')
    }
    convertTime(time: any) {

        return Moment.utc(time).utcOffset(+parseInt(localStorage.getItem('timeOffset') || "")).format('LT')
    }

    private renderDoctorList() {
        languagecontent = this.props.LanguageResoures;
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <DashboardNavMenu />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div id="pat_appointments" className="tab-pane fade show active">
                                <div className="card card-table mb-0">
                                    <div className="card-body" style={{ padding: "10px" }}>
                                        {
                                            this.props.isFormListLoading == true ? <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
                                                <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                                                <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                                                <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                                            </ContentLoader> : <div className="table-responsive doc-module">
                                                {
                                                    this.props.moduleLists.length == 0 ?
                                                        <div className="p-4 center">{languagecontent['noformsfound']}</div> :
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>{languagecontent['formnamelabel']}</th>
                                                                    <th>{languagecontent['modifiedon']}</th>
                                                                    <th>{languagecontent['modifiedby']}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.moduleLists.map((module: FlexiFormStore.ModuleList) =>
                                                                    <tr>
                                                                        <td><a className="text-info" onClick={e => this.loadForm(module.moduleName, module.id, module.formClientTitle)}>{module.formClientTitle}</a></td>
                                                                        <td>{module.modifiedOn ? dateTimeFormat(module.modifiedOn) : <span className="center">-</span>}</td>

                                                                        <td>{module.modifiedBy ? module.modifiedBy : <span className="center">-</span>}</td>

                                                                    </tr>
                                                                )
                                                                }

                                                            </tbody>
                                                        </table>
                                                }
                                            </div>
                                        }
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.flexiform, ...state.contact, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...FlexiFormStore.actionCreators, ...ContactStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(DynamicForms as any);
