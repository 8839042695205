import axios from 'axios';
import Moment from 'moment';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { actionCreators as _navdefault } from '../menu/NavDefault';

//import { Router, Route, browserHistory } from 'react-router';

import { createBrowserHistory } from 'history';
import { getParameterByName } from '../../helper/formvalidation';
const history = createBrowserHistory();
export interface LoginState {
    isLoginLoading: boolean;
    login: Login;
    patient: Patient;
}
export interface Meta {
    touched: string
    error: string
    warning: string
}
export interface Login {
    UserName: string;
    Password: string;
}
export interface Patient {
    id: string;
    memberId: string;
    patientNo: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    gender: string;
    address1: string;
    zipcode: string;
    cellPhone: string;
    email: string;
    bloodGroup: string;
    locationName: string;
    locationId: string;
}
interface RequestLogin {
    type: 'REQUEST_ADD_USER';
    login: Login;
    patient: Patient;
}

interface ReceiveLogin {
    type: 'RECEIVE_ADD_USER';
    login: Login;
    patient: Patient;
}

type KnownAction = RequestLogin | ReceiveLogin;

export const actionCreators = {
    requestLoginSave: (userObj: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        // window.history.pushState({ prevUrl: window.location.href }, "", "")
        // console.log(window.location.href, "window.location.href")
        var tt = window.location.href;
        var arr = tt.split("/")[3];
        // console.log(tt.split("/"), "window.location.href")
        const appState = getState();
        dispatch(showLoading('sectionBar'))
        if (appState && appState.login) {
            axios.post('account/login', userObj)
                .then(function (response) {
                    dispatch(hideLoading('sectionBar'))
                    dispatch(_navdefault.requestNavDefault())
                    if (localStorage.getItem("isAuth") == "true") {
                        //if (window.history.state.prevUrl.split("/"))
                        if (arr == "login") {
                            document.location.href = "/dashboard";
                        }

                        //dispatch(actionCreators.getFormModule());
                    }
                    else {
                        const data = response.data;

                        dispatch({ type: 'RECEIVE_ADD_USER', login: data });
                        //dispatch(reset("loginForm"));
                        localStorage.setItem("isAuth", "true");
                        dispatch(actionCreators.getFormModule());                        
                        window.history.replaceState({}, "", "/")
                    }

                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.error_description == "The username or password is incorrect.")
                            toastr.error('', langdata['theusernameorpasswordisincorrect']);
                        else if (errorObject.error_description =="Your account has been locked. Please contact administrator.") {
                            toastr.error('', langdata['accountlocked']);
                        }else
                            toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ type: 'RECEIVE_ADD_USER', isLoginLoading: false });
                    dispatch(hideLoading('sectionBar'))
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_ADD_USER', isLoginLoading: true });
        }
    },
    getUser: (dispatch: any, getState: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        axios.get('/patient/getpatient')
            .then(function (response) {
                const result = response.data;
                if (result.preferredLanguage && result.preferredLanguage !== "") {
                    localStorage.setItem("DefaultLangCode", result.preferredLanguage)
                } else {
                    localStorage.setItem("DefaultLangCode", "en-us")
                }
                localStorage.setItem("Patient", JSON.stringify({ id: result.id, memberId: result.memberId, userId: result.userId, name: result.firstName + " " + result.lastName, FirstName: result.firstName, LastName: result.lastName, Email: result.email, Address1: result.address1, Address2: result.address2, locationName: result.locationName, zipcode: result.zipcode, locationId: result.locationId, dob: result.dob, cellPhone: result.cellPhone, gender: result.gender, timeZone: result.timeZone, offset: result.offset}));
                var currentTime = new Date();
                var selfpay = localStorage.getItem("selfpayurl");

                var currentOffset = currentTime.getTimezoneOffset();

                var SettingOffset = parseInt(localStorage.getItem('timeOffset') || "");   // IST offset UTC +5:30

                var ConvertedTime = new Date(currentTime.getTime() + (SettingOffset + currentOffset) * 60000);
                var patient = JSON.parse(localStorage.getItem("Patient") || "");

                var today = Moment(ConvertedTime).format('YYYY/MM/DD hh:mm A');
                localStorage.setItem("today", today);

                var url: any = localStorage.getItem("redirectUrl");
                const appState = getState();
                var newArray: any = "";
                if (appState != undefined) {
                    newArray = appState.account.Settings.filter(function (el: any) {
                        return (el.name == "Intake Forms");
                    });
                }
                var timezonetimesloturl = localStorage.getItem("TimeZoneTimeSlot");
                if (timezonetimesloturl != null) {
                    return window.location.href = "/profilesettings/" + patient.memberId;
                }
                if (newArray[0].name == "Intake Forms" && newArray[0].customizeValue == "Yes") {
                    var formArray: any = appState.flexiform.formLists.filter(function (el: any) {
                        return ((el.id == null) && (el.showOnce == true));
                    });
                    if (formArray != 0) {
                        if (localStorage.getItem("pathName") == "conditions") {
                            if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                var doctorid = localStorage.getItem("DoctorId");
                                var _url: any = "/contact/" + doctorid + "/" + result.memberId;
                                localStorage.setItem("contactUrl", _url);
                                return window.location.href = "/initialpageforms/" + formArray[0].moduleName + "/" + result.id + "/" + result.id + "/" + null;
                            }
                        } else {
                            return window.location.href = "/initialpageforms/" + formArray[0].moduleName + "/" + result.id + "/" + result.id + "/" + null;
                        }
                    }
                }
                //var url :any = "";
                var appointment_date_time = Moment(localStorage.getItem("AppointmentOn")).format('YYYY/MM/DD') + " " + localStorage.getItem("AppointmentTime");

                if ((selfpay != null) || (selfpay != undefined)) {
                    //var u = new URL(selfpay)
                    //u.hash = ''
                    //u.search = ''
                    //localStorage.removeItem("selfpayurl");
                    return window.location.href = selfpay;
                }

               // console.log(new Date(today).getTime());
               // console.log((appointment_date_time));
                //if (new Date(today).getTime() < new Date(appointment_date_time).getTime()) {

                //    if (url == null)
                //        url = "";
                //    else
                //        url = localStorage.getItem("redirectUrl") || null;
                //}
                //else {
                //    const appState = getState();
                //    var enablefamilymember: any = appState.account.Settings.filter((se: any) => se.name == "Enable Family Member")[0];
                //    var doctorappointment: any = appState.account.Settings.filter((se: any) => se.name == "Doctor Appointment")[0];
                //    var requestqueue: any = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
                //    var anonymous: any = appState.account.Settings.filter((se: any) => se.name == "Anonymous")[0];
                //    if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                //        url = "/dashboard";
                //    }
                //    else if (localStorage.getItem("speaktovet") == "true") {
                //        localStorage.removeItem("speaktovet");
                //        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                //        url = queueurl + result.id;
                //    }
                //    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                //        url = "/dashboard";
                //    }
                //    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                //        url = "/consultationrequest?memberid=" + result.memberId;
                //    }
                //    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                //        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                //        if (queueurl == null) {
                //            url = "/consultationrequest?memberid=" + result.memberId;
                //        } else {
                //            if (localStorage.getItem("pathName") == "RequestQueue") {
                //                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                //                    url = "/contact/" + null + "/" + result.memberId;
                //                } else {
                //                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                //                    url = queueurl + "&patientId=" + result.id;
                //                }
                //            }
                //        }
                //    }
                //    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                //        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                //        if (queueurl == null) {
                //            url = "/dashboard";
                //        } else {
                //            if (localStorage.getItem("pathName") == "RequestQueue") {
                //                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                //                    url = "/contact/" + null + "/" + result.memberId;
                //                } else {
                //                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                //                    url = queueurl + "&patientId=" + result.id;
                //                }
                //            }
                //        }
                //    }
                //    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                //        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                //        if (queueurl == null) {
                //            url = "/consultationrequest?memberid=" + result.memberId;
                //        } else {
                //            if (localStorage.getItem("pathName") == "RequestQueue") {
                //                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                //                    url = "/contact/" + null + "/" + result.memberId;
                //                } else {
                //                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                //                    url = queueurl + "&patientId=" + result.id;
                //                }
                //            }
                //        }
                //    }
                //    else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                //        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                //        if (queueurl == null) {
                //            url = "/consultationrequest?memberid=" + result.memberId;
                //        } else {
                //            if (localStorage.getItem("pathName") == "RequestQueue") {
                //                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                //                    url = "/contact/" + null + "/" + result.memberId;
                //                } else {
                //                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                //                    url = queueurl + "&patientId=" + result.id;
                //                }
                //            }
                //        }
                //    }
                //    else if ((enablefamilymember.customizeValue == "Yes") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                //        var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                //        if (queueurl == null) {
                //            url = "/consultationrequest?memberid=" + result.memberId;
                //        } else {
                //            if (localStorage.getItem("pathName") == "RequestQueue") {
                //                if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                //                    url = "/contact/" + null + "/" + result.memberId;
                //                } else {
                //                    var patient = JSON.parse(localStorage.getItem("Patient") || "");
                //                    url = queueurl + "&patientId=" + result.id;
                //                }
                //            }
                //        }
                //    }
                //    else if (enablefamilymember.customizeValue == "Yes") {
                //        url = "/consultationrequest?memberid=" + result.memberId;
                //    }
                  
                //    else {
                //        url = "/dashboard";
                //    }

                //    /*localStorage.removeItem("pathName");*/
                //    /* localStorage.removeItem("redirectUrl");*/
                //    /*  localStorage.removeItem("redirectQequestQueueUrl");*/
                //}




                if (localStorage.getItem("pathName") == "conditions") {
                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                        var doctorid = localStorage.getItem("DoctorId");
                        if (localStorage.getItem("EmailId") != result.email) {
                            var _url: any = "/contact/" + doctorid + "/" + result.memberId;
                            localStorage.setItem("contactUrl", _url);
                            document.location.href = "/contact/" + doctorid + "/" + result.memberId;                           
                        } else if (localStorage.getItem("pathName") == "conditions") {
                            if (url != null)
                                document.location.href = updateQueryStringParameter(url, "date", "08/01/2020") + "&patientId=" + result.id;
                        }
                        else {
                            document.location.href = "/dashboard";
                        }
                    } else {
                        if (url != null) {
                            var urldate = getParameterByName("date", url);
                            var urltime = getParameterByName("time", url);
                            var accountoffset:any = localStorage.getItem("timeOffset");
                            if (result.offset == parseInt(accountoffset)) {
                                document.location.href = url + "&patientId=" + result.id;
                            } else {
                                //var datetime = convertUTCDateToLocalDate(urldate, urltime, result.offset);
                                var datetime: any = new Date(urldate + " " + urltime);
                                if (Math.sign(parseInt(accountoffset)) == -1)
                                    accountoffset = Math.abs(parseInt(accountoffset));
                                else
                                    accountoffset = -Math.abs(parseInt(accountoffset))

                                var CSToffSet = accountoffset; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
                                var offset = CSToffSet * 60 * 1000;
                                var CSTTime = new Date(datetime.getTime() + offset);
                                CSTTime.setTime(CSTTime.getTime() + (result.offset * 60000));
                                var date = Moment(CSTTime).format('YYYY-MM-DD');
                                var setdateurl = updateQueryStringParameter(url, "date", date) + "&patientId=" + result.id;
                                var time = Moment(CSTTime).format('hh:mm A');
                                document.location.href = updateQueryStringParameter(setdateurl, "time", time);
                            }
                          
                        }
                    }
                } else {
                    if (url == "" || url == null) {
                        const appState = getState();
                        var enablefamilymember: any = appState.account.Settings.filter((se: any) => se.name == "Enable Family Member")[0];
                        var doctorappointment: any = appState.account.Settings.filter((se: any) => se.name == "Doctor Appointment")[0];
                        var requestqueue: any = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
                        var anonymous: any = appState.account.Settings.filter((se: any) => se.name == "Anonymous")[0];
                        if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                            document.location.href = "/dashboard";
                        }
                        else if (localStorage.getItem("speaktovet") == "true") {
                            localStorage.removeItem("speaktovet");
                            var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                            document.location.href = queueurl + result.id;
                        }
                        else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                            document.location.href = "/consultationrequest?memberid=" + result.memberId;
                        }
                        else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                            var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                            if (queueurl == null) {
                                document.location.href = "/consultationrequest?memberid=" + result.memberId;
                            } else {
                                if (localStorage.getItem("pathName") == "RequestQueue") {
                                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "")
                                    {
                                        document.location.href = "/contact/" + null + "/" + result.memberId;
                                    } else {
                                        var patient = JSON.parse(localStorage.getItem("Patient") || "");
                                        document.location.href = queueurl + "&patientId=" + result.id;
                                    }
                                }                                

                            }
                        }
                        else if ((enablefamilymember.customizeValue == "Yes") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                            var queueurl: any = localStorage.getItem("redirectQequestQueueUrl");
                            if (queueurl == null) {
                                document.location.href = "/consultationrequest?memberid=" + result.memberId;
                            } else {
                                if (localStorage.getItem("pathName") == "RequestQueue") {
                                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                                        document.location.href = "/contact/" + null + "/" + result.memberId;
                                    } else {

                                        document.location.href = "/consultationrequest?memberid=" + result.memberId;

                                    }
                                }
                            }
                        }
                        else if (enablefamilymember.customizeValue == "Yes") {
                            document.location.href = "/consultationrequest?memberid=" + result.memberId;
                        }

                        else {
                            document.location.href = "/dashboard";
                        }
                    } else {
                        document.location.href = url;
                    }
                }
                localStorage.removeItem("speaktovet");
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                dispatch(hideLoading('sectionBar'))
            })
            .then(function () {
                // always executed
            });
    },
    getPtaient: (): AppThunkAction<KnownAction> => (dispatch: any) => {
        axios.get('/patient/getpatient')
            .then(function (response) {
                const result = response.data;
                if (result.preferredLanguage && result.preferredLanguage !== "") {
                    localStorage.setItem("DefaultLangCode", result.preferredLanguage)
                } else {
                    localStorage.setItem("DefaultLangCode", "en-us")
                }
                dispatch({ type: 'RECEIVE_ADD_USER', patient: result });
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                dispatch(hideLoading('sectionBar'))
            })
            .then(function () {
                // always executed
            });
        dispatch({ type: 'REQUEST_ADD_USER' });
    },
    getFormModule: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var accountid: any = localStorage.getItem("AccountId");
        const appState = getState();
        axios.get('/patient/GetFormModule/' + accountid + "/" + "6")
            .then(function (response) {
                const result = response.data;
                appState.flexiform.formLists = result;
                dispatch(actionCreators.getAppointmentOn());
                dispatch(actionCreators.getUser(dispatch, getState));
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                dispatch(hideLoading('sectionBar'))
            })
            .then(function () {
                // always executed
            });
    },
    getAppointmentOn: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var accountid: any = localStorage.getItem("AccountId");
        var appointmenton: any = null;
        if (localStorage.getItem("AppointmentUTCTime"))
            appointmenton = Moment(localStorage.getItem("AppointmentUTCTime")).format("YYYY-MM-DDTHH:mm:ss");
        var secondaryappointmenton: any = null;
        if (localStorage.getItem("AppointmentUTCTime1"))
            secondaryappointmenton = Moment(localStorage.getItem("AppointmentUTCTime1")).format("YYYY-MM-DDTHH:mm:ss");
        if (appointmenton != null) {
            axios.get('/appointment/GetLocalAppointmentOn/' + accountid + "/" + appointmenton + "/" + secondaryappointmenton)
                .then(function (response) {
                    const result = response.data;
                    if (result) {
                        if (result.appointmentOn) {
                            var apptdate: any = (result.appointmentOn).split('T')[0];
                            var appttime: any = Moment(result.appointmentOn).format('LT')
                            localStorage.setItem("AppointmentOn", apptdate);
                            localStorage.setItem("AppointmentTime", appttime);
                        }
                        if (result.secondaryAppointmentOn) {
                            var apptdate1: any = (result.secondaryAppointmentOn).split('T')[0];
                            var appttime1: any = Moment(result.secondaryAppointmentOn).format('LT')
                            localStorage.setItem("AppointmentOn1", apptdate1);
                            localStorage.setItem("AppointmentTime1", appttime1);
                        }
                    }
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                    dispatch(hideLoading('sectionBar'))
                })
                .then(function () {
                    // always executed
                });
        }
    },
};

const UnloadedState: LoginState = {
    login: {
        UserName: "",
        Password: "",
    },
    patient: {
        id: "",
        memberId: "",
        patientNo: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        address1: "",
        zipcode: "",
        cellPhone: "",
        email: "",
        bloodGroup: "",
        locationName: "",
        locationId: ""
    },
    isLoginLoading: false
};

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ADD_USER':
            return {
                login: state.login,
                patient: state.patient,
                isLoginLoading: true
            };
        case 'RECEIVE_ADD_USER':
            return {
                login: action.login,
                patient: action.patient,
                isLoginLoading: false
            };
        default:
            return state;
    }
};
function updateQueryStringParameter(uri:any, key:any, value:any) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
}

