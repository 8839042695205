import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { reset } from 'redux-form';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { actionCreators as _navAction } from '../../store/menu/NavDefault';

export interface SpecialityState {
    isLoadings: boolean;
    startDateIndex?: number;
    speciality: Specialities[];
}

export interface MemberSpecialitiesState { 
    memberSpecialitiesState: Specialities[];
}


export interface Specialities {
    id: string;
    name: string;
}

interface RequestOurDoctorsNavAction {
    type: 'REQUEST_OURDOCTORS_NAV';
    startDateIndex: number;
}

interface ReceiveDoctorListsAction {
    type: 'RECEIVE_OURDOCTORS_NAV';
    startDateIndex: number;
    speciality: Specialities[];
}

interface RequestMemberNavAction {
    type: 'REQUEST_MEMBER_NAV';
    memberSpecialitiesState: Specialities[];

    
}

interface ReceiveMemberAction {
    type: 'RECEIVE_MEMBER_NAV';    
    memberSpecialitiesState: Specialities[];
}


type KnownAction = RequestOurDoctorsNavAction | ReceiveDoctorListsAction | RequestMemberNavAction | ReceiveMemberAction ;

export const actionCreators = {
    requestSpecialityLists: (): AppThunkAction<KnownAction> => async (dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        if (appState && appState.speciality  && accountid != "") {
            axios.get('/doctor/GetSpeciality/' + accountid +"/" + languagecode)
                .then(function (response) {
                    const users = response.data;
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_OURDOCTORS_NAV', speciality: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_OURDOCTORS_NAV' });
        }
    },
    requestMemberSpecialityLists: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var languagecode: any = localStorage.getItem("DefaultLangCode");

        if (appState && appState.memberSpecialitiesState && accountid != "") {
            axios.get('/doctor/GetMemberSpeciality/' + accountid + "/" + languagecode)
                .then(function (response) {
                    const users = response.data;
                    dispatch({ type: 'RECEIVE_MEMBER_NAV', memberSpecialitiesState: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_MEMBER_NAV' });
        }
    },
    requestNASearchDoctorLists: (formvalues: any): AppThunkAction<KnownAction> => async(dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        const urlParams = new URLSearchParams(window.location.search);
        var specialitid: any = urlParams.get('specialityid');
        if (formvalues.specialityid != undefined)
            var specialityid: any = formvalues.specialityid == "" ? null : formvalues.specialityid;
        else
            var specialityid: any = formvalues.specialityid == "" ? null : specialitid;
        var Selectdate = formvalues.Selectdate == "" || formvalues.Selectdate == undefined ? null : formvalues.Selectdate;
        var doctorName = formvalues.doctorName == "" || formvalues.doctorName == undefined ? "-" : formvalues.doctorName;
        var languagecode: any = localStorage.getItem("DefaultLangCode");

        var gender = formvalues.gender == "" || formvalues.gender == undefined ? "-" : formvalues.gender;
        if (appState && appState.doctorLists && accountid != "") {
            axios.get('/doctor/GetSearchDoctor/' + accountid + "/" + specialityid + "/" + Selectdate + "/" + doctorName + "/" + gender + "/" + languagecode, { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const users = response.data;
                    localStorage.removeItem("AppointmentOn");
                    localStorage.removeItem("AppointmentTime");
                    localStorage.removeItem("AppointmentTime1");
                    localStorage.removeItem("AppointmentOn1");
                    localStorage.removeItem("AppointmentUTCTime")
                    localStorage.removeItem("AppointmentUTCTime1")
                    localStorage.removeItem("AppointmentBookedOn");
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_DOCTORLIST_FORECASTS', doctorlists: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTORLIST_FORECASTS' });
        }
    },
    requestReset: (): AppThunkAction<KnownAction> => async(dispatch: any, getState) => {
        dispatch(reset('dformsearchdoctor'))
        const urlParams = new URLSearchParams(window.location.search);
        var specialitid: any = urlParams.get('specialityid');
        dispatch(actionCreators.requestNASearchDoctorLists(specialitid))
    }
};


const unloadedState: SpecialityState = {
    speciality: [],   
    isLoadings: false
};

const unloadedMemberState: MemberSpecialitiesState = {
    memberSpecialitiesState: [],
    
};

export const memberreducer: Reducer<MemberSpecialitiesState> = (state: MemberSpecialitiesState | undefined, incomingAction: Action): MemberSpecialitiesState => {
    if (state === undefined) {
        return unloadedMemberState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MEMBER_NAV':
            return {
                memberSpecialitiesState: state.memberSpecialitiesState,

            };

        case 'RECEIVE_MEMBER_NAV':
            return {
                memberSpecialitiesState: action.memberSpecialitiesState,

            };
        default:
            return state;
    }
};

export const reducer: Reducer<SpecialityState> = (state: SpecialityState | undefined, incomingAction: Action): SpecialityState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_OURDOCTORS_NAV':
            return {               
                speciality: state.speciality,
                
                isLoadings: true
            };

        case 'RECEIVE_OURDOCTORS_NAV':            
                return {                    
                    speciality: action.speciality,
                    
                    isLoadings: false
                };           
        default:
            return state;
    }
};
