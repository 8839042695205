import * as React from 'react';
import axios from 'axios';
import { errorHandling } from '../../helper/ErrorHandling';


export default class LogoutPage extends React.PureComponent<{}, { children?: React.ReactNode }> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.logoutPage()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {
        const urlParams: any = new URLSearchParams(window.location.search);
        const url: any = urlParams.get('url');
        axios.get('account/LogoutSingleSignOn')
            .then(function (response) {
                window.location.href = url;
        })
        .catch(function (error) {
            errorHandling(error.response.data.statusCode);
        })
        .then(function () {
        });
    }
    public logoutPage() {
        return (
            <React.Fragment>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="main-wrapper">
                                            <div className="error-box">
                                                <h2><i className="fa fa-spinner fa-spin"></i> Logging out, Please wait...</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
