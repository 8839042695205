import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface CategoryState {
    categories: Category[]
}

export interface Category {
    id: string;
    value: string;
    name: string;
    group: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestCategoryAction { type: 'REQUEST_CATEGORY', categories: Category[] }
export interface ReciveCategoryAction { type: 'RECEIVE_CATEGORY', categories: Category[] }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestCategoryAction | ReciveCategoryAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getCategory: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var langcode: any = localStorage.getItem("DefaultLangCode");
        if (appState && appState.category) {
            axios.get('/site/Category/' + accountid + "/" + langcode)
                .then(function (response) {
                    const result = response.data;
                   /* console.log(result)*/

                   /* console.log(result.filter((ca: any) => ca.group == "Insurance Type"))*/

                    dispatch({ type: 'RECEIVE_CATEGORY', categories: result });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_CATEGORY' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: CategoryState = {
    categories: []
};
export const reducer: Reducer<CategoryState> = (state: CategoryState | undefined, incomingAction: Action): CategoryState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CATEGORY':
            return {
                categories: state.categories,
            };
        case 'RECEIVE_CATEGORY':
            return {
                categories: action.categories,
            };
        default:
            return state;
    }
};
