import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import { seo } from '../helper/Script';
import { ApplicationState } from '../store';
import * as NavDefaultStore from '../store/menu/NavDefault';
import * as SiteDetailStore from '../store/SiteDetail';
import * as LanguageResourcesStore from '../store/LanguageResoures';
import * as MemberSpecialityStore from '../store/menu/speciality';
import * as MenuHideStore from '../store/menuHide';
import * as SiteMenusStore from '../store/siteaccount/siteMenu';

var layout: any = "";
var siteMenu: any = [];
type Props = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & LanguageResourcesStore.LanguageResouresState & MemberSpecialityStore.MemberSpecialityState & MenuHideStore.MenuHideState & SiteMenusStore.SiteMenuState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof MemberSpecialityStore.actionCreators & typeof MenuHideStore.actionCreators & typeof SiteMenusStore.actionCreators & RouteComponentProps;
class Home extends React.PureComponent<Props> {
    constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        console.log("Home componentDidMount")
        const { history } = this.props;       
        if (localStorage.getItem('isExternal') == 'true') {
            if (localStorage.getItem("isAuth") == "true")
                history.push('/dashboard')       
        }
       
       

        this.ensureDataFetched();
    }
    public componentDidUpdate() {
        seo({
            title: 'Telemedicine Platform',
            pageTitle: '',
            description: ''
        })
        

       
    }
    
    public render() {
        return (
            <React.Fragment>
                {this.home()}
            </React.Fragment>
        );
    }
    bookNow = () => {
        this.props.goNextPage()
    }
    private ensureDataFetched() {
        //this.props.getSiteMenu();
        this.props.requestMenuHide(false);
        this.props.getLangResources();
        this.props.requestMemberSpecialityLists();
    }
    public home() {
        //siteMenu = this.props.siteMenus || []
        //const { history } = this.props;

        //if (siteMenu.length > 0) {

        //    var homepage: any = this.props.siteMenus.filter((menu: any) => menu.Title == 'Home' && menu.TypeId == "1") || []

        //    const isExternal = homepage.length > 0 ? homepage[0].Src.startsWith('http://') || homepage[0].Src.startsWith('https://') : '';

        //    if (isExternal) {
        //        if (localStorage.getItem("isAuth") == "true")
        //            history.push('/dashboard')
        //        else
        //            history.push('/login')
        //        console.log(homepage, isExternal)
        //    }
        //}
        console.log("Home")
        layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
        if (layout == undefined)
            layout = "";
        else
            layout = layout.Value;

        var languagecontent: any = this.props.LanguageResoures;

        return (
            <React.Fragment>
                {
                     this.props.siteMenus.length != 0 ?
                    layout == "NavPrime" ?
                        <div>
                            <div className="content ">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-4  col-sm-12">
                                            <h2 style={{ color: '#15558d', fontSize: "26px" }}> Welcome to Prime Life MD</h2>
                                            <br />
                                            <p className="h5 font-weight-normal">Congratulations on your journey to a</p>
                                            <p className="h5 font-weight-normal"> better life!</p>
                                            <br />
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                        <div className="col-lg-8 col-sm-12">
                                            <img src="client/default/img/metacarebanner.jpg" style={{ height: "auto", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : layout == "NavDefault" ?
                            <div>
                                <div className="content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6  col-sm-12">
                                                <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                <br />
                                                <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                <br />
                                                <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                <br />
                                                <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                :layout == "NavVirtualCare" ?
                <div>
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6  col-sm-12">
                                    <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                    <br />
                                    <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                    <br />
                                    <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                    <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                    <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                    <br />
                                    <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                            : layout == "NavVirtuvets" ?
                                <div>
                                    <div className="content">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-6  col-sm-12">
                                                    <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                    <br />
                                                    <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                    <br />
                                                    <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                    <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                    <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                    <br />
                                                    <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> : layout == "NavTheraque" ?

                                    <div>
                                        <div className="content">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-6  col-sm-12">
                                                        <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                        <br />
                                                        <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                        <br />
                                                        <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                        <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                        <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                        <br />
                                                        <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                    </div>
                                                    <div className="col-lg-6 col-sm-12">
                                                        <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : layout == "NavGoodoc" ?

                                        <div>
                                            <div className="content">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-lg-6  col-sm-12">
                                                            <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                            <br />
                                                            <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                            <br />
                                                            <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                            <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                            <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                            <br />
                                                            <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-12">
                                                            <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :

                                        layout == "NavABC" ?

                                            <div>
                                                <div className="content">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-lg-6  col-sm-12">
                                                                <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                                <br />
                                                                <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                                <br />
                                                                <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                                <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                                <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                                <br />
                                                                <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                            </div>
                                                            <div className="col-lg-6 col-sm-12">
                                                                <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> :
                                            layout == "NavWelshdale" ?

                                                <div>
                                                    <div className="content">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-6  col-sm-12">
                                                                    <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                                    <br />
                                                                    <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                                    <br />
                                                                    <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                                    <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                                    <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                                    <br />
                                                                    <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-12">
                                                                    <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :

                                                layout == "NavMetaCare" ?

                                                    <div>
                                                        <div className="content">
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-lg-6  col-sm-12">
                                                                        <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                                        <br />
                                                                        <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                                        <br />
                                                                        <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                                        <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                                        <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                                        <br />
                                                                        <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']}</a>
                                                                    </div>
                                                                    <div className="col-lg-6 col-sm-12">
                                                                        <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div> :
                                                        layout == "Nav247DCT" ?
                                                            <div>
                                                                <div className="content">
                                                                    <div className="container">
                                                                        <div className="row">
                                                                            <div className="col-lg-6  col-sm-12">
                                                                                <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                                                <br />
                                                                                <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                                                <br />
                                                                                <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                                                <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                                                <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                                                <br />
                                                                                <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                                                            </div>
                                                                            <div className="col-lg-6 col-sm-12">
                                                                                <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div> :
                                                                layout == "NavSmarthealthtec" ?
                                                                    <div>
                                                                    
                                                                        <div className="homeBackground">
                                                                            <div className='container-fluid ' >
                                                                                <div>
                                                                                    <div className="pt-5 col-md-12 col-sm-12" >                                                                     
                                                                                        <div>
                                                                                            <div className="row">
                                                                                                <div className="col-lg-6  col-sm-12">
                                                                                                    <h2 style={{color: '#15558d', paddingLeft: '60px', paddingTop: '40px', fontSize: '2.5rem'}} > {languagecontent['homepagecontent']} </h2>
                                                                                                    <br />
                                                                                                    {/*<h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>*/}
                                                                                                    {/*<br />*/}
                                                                                                    <p className="h5 font-weight-normal" style={{ paddingLeft: '60px', fontSize: '1rem'}}> {languagecontent['reasonablewait']} </p>
                                                                                                    {/*<p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>*/}
                                                                                                    {/*<p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>*/}
                                                                                                    <br />
                                                                                                    <p style={{ paddingLeft: '60px' }}><a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a></p>
                                                                                                </div>
                                                                                                <div className="col-lg-6 col-sm-12">
                                                                                                    <img src="client/default/img/shthomepage.png" style={{ height: "auto", width: "100%" }} className="homelogo" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div> :
                                                                     layout == "NavAmarac" ?
                            <div>
                                <div className="content">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6  col-sm-12">
                                                <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                <br />
                                                <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                <br />
                                                <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                <br />
                                                <a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']} </a>
                                            </div>
                                            <div className="col-lg-6 col-sm-12">
                                                <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>:
                                                                    layout == "NavSanthigram" ?

                                                        <div>
                                                            <div className="content">
                                                                <div className="container">
                                                                    <div className="row">
                                                                        <div className="col-lg-6  col-sm-12">
                                                                            <h2 style={{ color: '#15558d' }}> {languagecontent['homepagecontent']}</h2>
                                                                            <br />
                                                                            <h3 className="font-weight-normal">{languagecontent['primedoctor']}</h3>
                                                                            <br />
                                                                            <p className="h5 font-weight-normal">{languagecontent['reasonablewait']}</p>
                                                                            <p className="h5 font-weight-normal">{languagecontent['fixedconsultationfee']}</p>
                                                                            <p className="h5 font-weight-normal">{languagecontent['consultwithpreferreddoctor']}</p>
                                                                            <br />
                                                                            {/*<a className="btn btn-lg btn-primary float-left" style={{ color: "#ffffff" }} type="button" onClick={() => this.bookNow()}> {languagecontent['booknow']}</a>*/}
                                                                        </div>
                                                                        <div className="col-lg-6 col-sm-12">
                                                                            <img src="client/default/img/doc-slide-11.png" style={{ height: "auto", width: "100%" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ""
                    : ''
                }



            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.siteDetail, ...state.siteMenus, ...state.langResources, ...state.menuHide, ...state.speciality }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...MenuHideStore.actionCreators, ...MemberSpecialityStore.actionCreators, ...SiteMenusStore.actionCreators }) // Selects which action creators are merged into the component's props
)(Home as any);

