import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { NavLink, Link, useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import * as ContactStore from '../../../store/patient/Contact';
import * as HandleRequest from '../../../helper/Request';
import * as SiteDetailStore from '../../../store/SiteDetail';
import * as SiteMenusStore from '../../../store/siteaccount/siteMenu';
import { renderDob, renderExpDob, renderDoctorDob, renderField, renderInsuranceautoComplete, renderInsuranceSelectField, renderInsurenceDob, renderSelectField } from '../../../helper/renderfield';
import { datepickerJS, floatingJS, floatJS, dobcheck, ltrim } from '../../../helper/Script';
import { ApplicationState } from '../../../store';
import Moment from 'moment';
import * as AccountStore from '../../../store/siteaccount/Account';
import * as DoctorListStore from '../../../store/doctor/OurDoctors';
import { TextBoxLoader } from '../../../helper/fbloader';
import * as CategoryStore from '../../../store/siteaccount/Category';
import { dateFormat, dateFormatSetting, datePostFormat, getAge, required } from '../../../helper/formvalidation';
import * as SpecialityStore from '../../../store/menu/OurDoctorsNav';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';

type SpecialityStoreProps = SpecialityStore.SpecialityState & SpecialityStore.MemberSpecialitiesState & DoctorListStore.DoctorListsState & HandleRequest.Request & CategoryStore.CategoryState & AccountStore.AccountState & ContactStore.ContactState & SiteDetailStore.SiteDetailState & SiteMenusStore.SiteMenuState & LanguageResourcesStore.LanguageResouresState
    & typeof SiteDetailStore.actionCreators & typeof DoctorListStore.actionCreators  & typeof SpecialityStore.actionCreators & typeof CategoryStore.actionCreators & typeof AccountStore.actionCreators & typeof ContactStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof LanguageResourcesStore.actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;
var Gender: any = "";
var Genders: any = "";
var specialityid: any = "";
var specialityname: any = "";
var genderid: any = "";
var gendername: any = "";
var langdata: any = "";
class OurDoctorssNavMenu extends React.PureComponent<SpecialityStoreProps> {
    constructor(props: any) {
        super(props);
    }

    selectEncType = (value: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('status');
        localStorage.setItem("SpecDetail", JSON.stringify({ Id: value.Id, Name: value.Name }))
        specialityid = value.id;
        specialityname = value.name;


    }
    selectGender = (value: any) => {
        localStorage.setItem("GenderDetail", JSON.stringify({ Id: value.id, Name: value.name }))
        genderid = value.id;
        gendername = value.name;


    }
    reset = () => {
        specialityid = "";        
        genderid = "";
        localStorage.removeItem("SpecialityId");        
        this.props.requestReset();
        this.setState({
            selectdate: "",
            doctorName: "",
            specialityid: "",
            genderid: "",
            isShow: true,
            ishowPreferred: false
        });

    }

    onSubmit = (formValues: any) => {
       // console.log(formValues);
        var myForm: any = document.getElementById("dformsearchdoctor");
        if (myForm.elements.namedItem("selectdate") != null) {
            formValues.Selectdate = myForm.elements.namedItem("selectdate").value;
        }
        if (myForm.elements.namedItem("doctorName") != null) {
            formValues.doctorName = myForm.elements.namedItem("doctorName").value;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const specialtid = urlParams.get('specialityid');
        if (specialityid == "")
            formValues.specialityid = specialtid;
        else
            formValues.specialityid = specialityid;
        localStorage.setItem("SpecialityId", formValues.specialityid);
        formValues.gender = genderid;

        if (formValues.Selectdate != null && formValues.Selectdate != "") {
            var insurancedate: any = datePostFormat(formValues.Selectdate);
            var _datetime = Moment(new Date(insurancedate)).format('YYYY-MM-DD');
            formValues.Selectdate = _datetime;
        }
        this.props.requestNASearchDoctorLists(formValues);
    }
 
    public componentDidMount() {
        this.ensureDataFetched();
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        datepickerJS();
        floatingJS();
    }
  
    private ensureDataFetched() {
        this.props.getSiteMenu();
        this.props.getCategory();
        this.props.requestSpecialityLists();
        this.props.requestMemberSpecialityLists();

        
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        const urlParams = new URLSearchParams(window.location.search);
        //const speciality_id = urlParams.get('specialityid');
        //specialityid = speciality_id;

    }
    public render() {
        return (
            <React.Fragment>
                {this.renderOurDoctorsNav()}
            </React.Fragment>
        );
    }



    public renderOurDoctorsNav() {
        var dateformat: any = localStorage.getItem("DateFormat") == null ? "" : localStorage.getItem("DateFormat");
        var languagecontent: any = this.props.LanguageResoures;
        var specialitylist: any = this.props.speciality;
        var data = window.location.pathname;
        const array = data.split('/');
        var publicMenus: any = [];
        var gender: any = [];
        gender = this.props.categories.filter((ca: any) => ca.group == "Gender");
        if (gender.length != 0)
            gender = gender.filter((ca: any) => ca.name != "Other" && ca.name != "Otra" && ca.name != "I don't want to disclose" && ca.name != "no quiero revelar");
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "3");
        const urlParams = new URLSearchParams(window.location.search);
        const specialtyid = localStorage.getItem("SpecialityId") != null ? localStorage.getItem("SpecialityId") : urlParams.get('specialityid');


        //console.log(this.props.speciality, this.props.memberSpecialitiesState)

        return (
            <React.Fragment>
                <div className="col-lg-4 col-xl-3 theiaStickySidebar">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)} id="dformsearchdoctor" noValidate autoComplete={"off"}>
                        <div className="profile-sidebar">
                            <h4 className="pl-3 pt-3 pr-3">{languagecontent['searchfilter']}</h4>
                            <hr />
                            <label className="pl-3">{languagecontent['selectspecialty']}</label>
                            <ul className="list-group list-group-flush benifits-col" style={{ maxHeight: "27vh", overflowX: this.props.memberSpecialitiesState.length > 3 ? "hidden" : "unset" }}>
                                {this.props.isLoadings == true ? <ContentLoader
                                    title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                    speed={2}
                                    width={350}
                                    height={160}
                                    viewBox="0 0 400 160"
                                >
                                    <rect x="50" y="6" rx="4" ry="4" width="343" height="38" />
                                    <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
                                    <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
                                    <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
                                    <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
                                    <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />
                                </ContentLoader> :

                                    this.props.memberSpecialitiesState != undefined ?
                                        this.props.memberSpecialitiesState.map((speciality: SpecialityStore.Specialities, index) =>
                                            <React.Fragment>
                                                <li className="list-group-item d-flex align-items" style={{ marginBottom: "-27px" }}>
                                                    <div className="avatar">
                                                        <label className="custom_radio">
                                                            <div className="cp_visit-width">
                                                                <input type="radio" name="speciality" onClick={() => this.selectEncType(speciality)} defaultChecked={speciality.id == specialtyid ? true : false} value="Ccon" /><span className="checkmark"></span> {speciality.name}
                                                            </div>
                                                        </label>
                                                    </div>
                                                </li>


                                            </React.Fragment>
                                        )
                                        : ""
                                }

                            </ul>

                            <React.Fragment>
                                <div className="col-12  pt-3">
                                    {
                                        languagecontent['selectdate'] == undefined ? <TextBoxLoader /> : ""
                                    }
                                    <div className={languagecontent['selectdate'] == undefined ? "form-group form-focus focused hide" : "form-group form-focus focused"}>
                                        <div className="cal-icon">
                                            {this.props.memberSpecialitiesState ?
                                                <Field name="selectdate" component={renderDoctorDob} label={languagecontent['selectdate'] == undefined ? "" : languagecontent['selectdate'] + " " + "(" + dateformat + ")"} maxLength={15} type="text" />
                                                : <Field name="selectdate" component={renderField} label={languagecontent['selectdate'] == undefined ? "" : languagecontent['selectdate']} maxLength={15} type="text" />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 pt-3 pl-3">
                                    <label>{languagecontent['searchdoctor']}</label>
                                    <div className={this.props.contact.firstName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
                                        <Field name="doctorName" component={renderField} label={languagecontent['enterprovidername']} maxLength={50} type="text" />
                                    </div>
                                </div>
                            </React.Fragment>
                            <label className="col-12 pt-3 pl-3">{languagecontent['selectgender']}</label>
                            <ul className="list-group list-group-flush benifits-col">

                                {this.props.isLoadings == true ? <ContentLoader
                                    title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                    speed={2}
                                    width={350}
                                    height={160}
                                    viewBox="0 0 400 160"
                                >
                                    <rect x="50" y="6" rx="4" ry="4" width="343" height="38" />
                                    <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
                                    <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
                                    <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
                                    <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
                                    <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />
                                </ContentLoader> :
                                    gender != undefined ?
                                        gender.map((gender: CategoryStore.Category, index: any) =>
                                            <React.Fragment>
                                                <li className="list-group-item d-flex align-items " style={{ marginBottom: "-27px" }}>
                                                    <div className="avatar">
                                                        <label className="custom_radio">
                                                            <div className="cp_visit-width">
                                                                <input type="radio" name="gender" onClick={() => this.selectGender(gender)} value="CGen" /><span className="checkmark"></span> {gender.name}
                                                            </div>
                                                        </label>
                                                    </div>
                                                </li>


                                            </React.Fragment>
                                        )
                                        : ""
                                }

                            </ul>

                            <div className="row m-2 ">
                                <div className="col-sm-12 p-2">

                                    <div className="clinic-booking">
                                        <div className='speciality-page-btn'>
                                            <button className="btn btn-primary apt-btn " style={{ width: "100%" }} type="submit">{languagecontent['search']}</button>
                                            {/*<a className="btn btn-primary apt-btn" style={{ cursor: "pointer" }} role="button" onClick={() => this.reset()} type="submit">{languagecontent['search']}</a>*/}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 p-2">
                                    {/*<div className="doc-info-right">*/}
                                    <div className="clinic-booking">

                                        <div className='speciality-page-btn'>
                                            {/*<a className=" btn btn-primary apt-btn" style={{ cursor: "pointer" }} role="button" onClick={() => this.reset()} type="reset"> {languagecontent['reset']}</a>*/}

                                            <button className="btn btn-primary apt-btn " style={{ width: "100%" }} onClick={() => this.reset()} type="reset"> {languagecontent['reset']}</button>
                                        </div>
                                    </div>
                                    {/*</div>*/}
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = ({ ...SpecialityStore.actionCreators, ...CategoryStore.actionCreators, ...AccountStore.actionCreators, ...ContactStore.actionCreators, ...SiteDetailStore.actionCreators, ...SiteMenusStore.actionCreators, ...LanguageResourcesStore.actionCreators });
const mapStateToProps = (state: ApplicationState) => {
    let values = {
        ...state.speciality, ...state.memberSpecialitiesState, ...state.contact, ...state.siteDetail, ...state.siteMenus, ...state.langResources, ...state.category
    };
    return values;

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'dformsearchdoctor',
    enableReinitialize: true,
})(OurDoctorssNavMenu as any))




