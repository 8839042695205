import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as MemberSpecialityStore from '../../store/menu/speciality';

// At runtime, Redux will merge together...
type ScheduleCallProps =
    MemberSpecialityStore.MemberSpecialityState // ... state we've requested from the Redux store
    & typeof MemberSpecialityStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

class ScheduleCall extends React.PureComponent<ScheduleCallProps> {
    constructor(props: any) {
        super(props);
    }
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }
    

    public render() {
        return (
            <React.Fragment>
                {this.scheduleCall()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestMemberSpecialityLists()
      
    }
    private scheduleCall() {
        const specialities = this.props.speciality || []

        setTimeout(() => {
            if (specialities.length == 1 ) {
                window.location.href = "/ourdoctors?specialityid=" + specialities[0].id;
            }
            else {
                window.location.href = "/memberspeciality?name=ourdoctors";
            }
        },1500)
            
   

        return (
            <React.Fragment>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="main-wrapper">
                                            <div className="error-box">
                                                <h2><i className="fa fa-spinner fa-spin"></i>  Please wait...</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.speciality }), // Selects which state properties are merged into the component's props
    ({ ...MemberSpecialityStore.actionCreators })// Selects which action creators are merged into the component's props
)(ScheduleCall as any);

