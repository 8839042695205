import * as React from 'react';
import ContentLoader, { Facebook } from 'react-content-loader';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store';
import * as LoginStore from '../../../store/account/Login';
import * as DocumentListStore from '../../../store/library/document/DocumentList';
import { formatSizeUnits } from '../../../helper/Script';
import Moment from 'moment';
import { dateUTCFormat, timeFormatConvert } from '../../../helper/formvalidation';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';
// At runtime, Redux will merge together...
type DocumentListProps =
	LoginStore.LoginState & DocumentListStore.DocumentListsState & LanguageResourcesStore.LanguageResouresState // ... state we've requested from the Redux store
	& typeof LoginStore.actionCreators & typeof DocumentListStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var languagecontent: any;
class DocumentListClass extends React.PureComponent<DocumentListProps> {
	constructor(props: any) {
		super(props);
	}

	onDelete(formValues: any) {
		var langdata: any;
		if (localStorage.getItem("LangResourcesValidations") != null)
			langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
		var result = window.confirm(langdata['areyousuretodelete']);
		if (result) {
			this.props.requestDocumentDelete(this.props.patient.memberId, "Patient Document", formValues.id);
		}
	}
	onDownload(formValues: any,name:any) {
		this.props.requestDocumentDownload(formValues,name);
	}
	onView(formValues: any, contenttype:any,name:any) {
		 var filename = encodeURIComponent(formValues);
		this.props.requestViewDownload(filename, contenttype, name);
    }
	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.ensureDataFetched();
		this.props.getPtaient();
	}

	public render() {
		return (
			<React.Fragment>
				{this.renderDoctorList()}
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		localStorage.removeItem("DocIds");
		this.props.requestDocumentLists(this.props.patient.memberId, "Patient Document");
	}

	private renderDoctorList() {
		languagecontent = this.props.LanguageResoures;
		return (
			<React.Fragment>
			<div className="card-body">
				<div id="pat_appointments" className="tab-pane fade show active">
					<div className="card card-table mb-0">

						{
							this.props.documentLists.length == 0 ? <div>
								<div className="p-4">{languagecontent['norecordsfound']}</div>
							</div> :
								<div className="card-body">
									<div className="table-responsive doc-module">


										<table className="table table-hover table-center mb-0 ">
											<thead >
												<tr>
													<th></th>
													<th className='font-weight-normal' >{languagecontent['filename'] == undefined ? languagecontent['name'] : languagecontent['filename']}</th>
													<th></th>
													<th className='font-weight-normal'>{languagecontent['documenttype']}</th>
													<th className='font-weight-normal' >{languagecontent['modifiedon']}</th>
													<th className='font-weight-normal' >{languagecontent['modifiedby']}</th>
													<th className='font-weight-normal' >{languagecontent['filesize']}</th>
												</tr>
											</thead>
											<tbody>
												{this.props.documentLists.map((documentlist: DocumentListStore.DocumentList) =>
													<tr>														
														<td><i className="fas fa-2x fa-file" style={{ color: "#15558d" }} aria-hidden="true"></i></td>
														<td ><span className="float-left">{documentlist.fileName}</span></td>
														<td><span className="float-right">
															<ul className="nav user-menu">
																<li className="nav-item dropdown has-arrow">
																	<a href="#" className="dropdown-toggle nav-link document" data-toggle="dropdown">
																		<i className="fas fa-ellipsis-v"></i></a>
																	<div className="dropdown-menu">
																		<a className="dropdown-item" onClick={() => this.onDownload(documentlist.downloadUrl, documentlist.fileName)}>{languagecontent['download']}</a>
																		<a className="dropdown-item" onClick={() => this.onDelete(documentlist)}>{languagecontent['delete']}</a>
																		<a className="dropdown-item" onClick={() => this.onView(documentlist.filePath, documentlist.contentType, documentlist.fileName)}>{languagecontent['view']}</a>
																	</div>
																</li>
															</ul></span></td>
														<td>{documentlist.name}</td>
														<td>{dateUTCFormat(documentlist.modifiedOn)} {Moment(documentlist.modifiedOn).format('LT')}</td>
														<td>{documentlist.modifiedBy}</td>
														<td>{formatSizeUnits(documentlist.size)}</td>
													</tr>
												)
												}

											</tbody>
										</table>

									</div>
								</div>
						}

					</div>
				</div>
				</div>
			</React.Fragment>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({ ...state.login, ...state.documentlist, ...state.langResources }), // Selects which state properties are merged into the component's props
	({ ...LoginStore.actionCreators, ...DocumentListStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(DocumentListClass as any);
