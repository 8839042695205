import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import * as CategoryStore from '../../store/siteaccount/Category';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as AccountStore from '../../store/siteaccount/Account';
import { ApplicationState } from '../../store/index';
import * as SurveyFormStore from '../../store/survey/SurveyForm';
import * as HandleRequest from '../../helper/Request';
import { renderPublicRadioField, renderSelectField, renderSurveyTextareaField } from '../../helper/renderfield';
import { required, setTittle } from '../../helper/formvalidation';
import {seo } from '../../helper/Script';
import { scrollTop } from '../../helper/formvalidation';


type SurveyFormProps = SurveyFormStore.SurveyFormState & AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState & CategoryStore.CategoryState & HandleRequest.Request
	& typeof SurveyFormStore.actionCreators & typeof AccountStore.actionCreators  & typeof LanguageResourcesStore.actionCreators  & typeof CategoryStore.actionCreators &
    RouteComponentProps<{}>;

var languagecontent: any = [];
var isLoading: any = "";
var sessionSatisfactionId: any = "";
class SurveyForm extends React.PureComponent<SurveyFormProps>{
	constructor(props: any) {
		super(props);
	}
	selectSessionSatisfaction = (value: any) => {
		sessionSatisfactionId = value.id;
	}
	handleChange = (value: any) => {
		console.log(value);
    }
	onSubmit = (formValues: any) => {
		const urlParams = new URLSearchParams(window.location.search);
		formValues.encounterId = urlParams.get('encounterid');
		/*formValues.sessionSatisfactionId = sessionSatisfactionId;*/
		this.props.postSurveyForm(formValues);
	}
	componentDidMount() {
		this.ensureDataFetched();
		scrollTop();
    }
	componentDidUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
	}
	private ensureDataFetched() {
		this.props.getLangResources();
		this.props.getCategory();
		const urlParams = new URLSearchParams(window.location.search);
		const encounterId = urlParams.get('encounterid');
		this.props.requestSurveyForm(encounterId)
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderSurveyForm() }
			</React.Fragment>
		);
	}

	public renderSurveyForm() {
		const urlParams = new URLSearchParams(window.location.search);
		const encounterId = urlParams.get('encounterid');
		languagecontent= this.props.LanguageResoures;
		var sessionSatisfaction: any = [];
		isLoading = this.props.isFormLoading;
		sessionSatisfaction = this.props.categories.filter((ca: any) => ca.group == "Session Satisfaction");
		return (
			<React.Fragment>
				{
					this.props.surveyform.encounterId == null ?
						<React.Fragment>
							<div id="dform">
							<div className="content" >
								<div className="container">
									<form onSubmit={this.props.handleSubmit(this.onSubmit)} autoComplete="off">
										<div className="card">
											<div className="card-body">

												<div>
													<ul style={{ listStyle: "none", }}><li><label>{languagecontent['satisfaction']}</label>
														{sessionSatisfaction.length != 0 ?
															<ul>
																<div className="avatar">
																	<label className="custom_radio">
																		<div className="cp_visit-width">
																			{/*<Field name="sessionSatisfactionId" options={sessionSatisfactionId} select={languagecontent['select']} component={renderSelectField} validate={[required]} className="form-control" />*/}

																			<Field component={renderPublicRadioField}
																				name="sessionSatisfactionId"
																				label=""
																				required={true}
																				onChange={(e: any) => this.handleChange(e)}
																				options={sessionSatisfaction}
																				validate={[required]}
																			/>
																		</div>
																	</label>
																</div>
															</ul>

															: ""}
													</li>
														<li>
															<Field name="suggestion" component={renderSurveyTextareaField} label={languagecontent['suggestions']} maxLength={500} type="text" validate={[required]} />

														</li>
														<li>
															<Field name="recommendation" component={renderSurveyTextareaField} label={languagecontent['recommendations']} maxLength={500} type="text" validate={[required]} />

														</li>
													</ul>
												</div>
											</div>

										</div>
										<div className="submit-section text-right">
												<button className="btn btn-primary submit-btn" disabled={isLoading} type="submit"> {isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {languagecontent['submit'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['submit']} </button>
										</div>
									</form>
								</div>
								</div>
							</div>
							<div id="dmessage" style={{ display: "none" }}>
							<div className="content"  >
								<div className="container-fluid">
									<div className="row justify-content-center">
										<div className="col-lg-6">
											<div className="card success-card">
												<div className="card-body text-center">
													{languagecontent['formsubmitmessage']}											</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							</div>
						</React.Fragment>
						:
						<React.Fragment>
							<div className="content">
								<div className="container-fluid">
									<div className="row justify-content-center">
										<div className="col-lg-6">
											<div className="card success-card">
												<div className="card-body text-center">
													{languagecontent['participationmessage']}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
                }
				
			
			</React.Fragment>
		);
	}
	
	
}

const mapDispatchToProps = { ...SurveyFormStore.actionCreators, ...AccountStore.actionCreators,  ...CategoryStore.actionCreators, ...LanguageResourcesStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => {

	let values = {
		initialValues: { sessionSatisfactionId: "", suggestion: "", recommendation: "" },
		...state.surveyform, ...state.account,  ...state.category, ...state.langResources
	};
	return values;

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'surveyForm',
	enableReinitialize: true,
})(SurveyForm as any))
