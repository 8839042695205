import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { renderSignUpcheckBox, renderField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as SignUpStore from '../../store/account/Signup';
import * as AccountStore from '../../store/siteaccount/Account';
import * as HandleRequest from '../../helper/Request';
import { NavLink } from 'react-router-dom';
import { floatingJS, mobnav, seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as SiteDetailStore from '../../store/SiteDetail';
import * as SiteMenusStore from '../../store/siteaccount/siteMenu';
import * as MenuHideStore from '../../store/menuHide';
import { email, matchInput, password,  scrollTop, setTittle } from '../../helper/formvalidation';
type ContactProps =
    SignUpStore.SignUpState & LanguageResourcesStore.LanguageResouresState & SiteDetailStore.SiteDetailState & SiteMenusStore.SiteMenuState & AccountStore.AccountState & HandleRequest.Request & MenuHideStore.MenuHideState &
    typeof SignUpStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof AccountStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof MenuHideStore.actionCreators &
    RouteComponentProps<{}>;
var languagecontent: any;
var layout: any = "";
var required_val: any = "";
var localLayout: any = "";
var langdata: any;
interface State {
    passwordVisible: boolean;
  
}
class SignUp extends React.PureComponent<ContactProps, State> {
    constructor(props: any) {
        super(props);
    }
    state = {
        passwordVisible: false,
       
    };


    handleToggle = () => {
        this.setState((prevState) => ({
            passwordVisible: !prevState.passwordVisible
        }));
    };

    onSubmit = (formValues: any, dispatch: any, props: any) => {
        formValues.FirstName = formValues.FirstName.trim();
        formValues.LastName = formValues.LastName.trim();
        this.props.requestSignUpSave(formValues)
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderSignUp()}
            </React.Fragment>
        );
    }

    public componentDidMount() {
        var localLayout: any = localStorage.getItem("siteThemeColor");
        if (localLayout == "Nav247DCT") {
            window.location.href = "https://247dct.org/get-started/"
        }
        floatingJS()
        mobnav()
        this.ensureDataFetched();
        scrollTop();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
        var defaultLangCode = localStorage.getItem("DefaultLangCode")

        required_val = defaultLangCode == 'en-us' ? 'Required' : 'Requerido'
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.requestMenuHide(true);
        this.props.getSiteSetting(null);
        var defaultLangCode = localStorage.getItem("DefaultLangCode")

        required_val = defaultLangCode == 'es-cr' ? 'Requerido' : 'Required'
    }

    required = (value: string) => {
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        return value || typeof value === 'number' ? undefined :  required_val ;

    };
    public renderSignUp() {
         localLayout= localStorage.getItem("siteThemeColor");
        languagecontent = this.props.LanguageResoures;
        if (this.props.Settings.length != 0) {
            var invitecode: any = this.props.Settings.filter((s: any) => s.name == "Subaccount signup through primary account using invite code")[0].customizeValue;
        }
        var publicMenus: any = [];
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "2");
        var termssrc: any = "";
        var privacysrc: any = "";
        if (publicMenus.length != 0) {
            if (publicMenus.filter((ca: any) => ca.FriendlyUrl == "Terms & Conditions")[0] != null)
                termssrc = publicMenus.filter((ca: any) => ca.FriendlyUrl == "Terms & Conditions")[0].Src;
            if (publicMenus.filter((ca: any) => ca.FriendlyUrl == "Privacy Policy")[0] != null)
                privacysrc = publicMenus.filter((ca: any) => ca.FriendlyUrl == "Privacy Policy")[0].Src;

        }
        layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
        if (layout == undefined)
            layout = "";
        else
            layout = layout.Value;
        const passwordVisible: any = this.state.passwordVisible;

        return (          
            <React.Fragment>
                {localLayout != "Nav247DCT" ?
                    <div className="content signupbackgroundimage">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1  col-md-10 offset-md-1 col-sm-12 col-xs-12">
                                    <div className="account-content">
                                        <div className="row  justify-content-center ">
                                            <div className="col-lg-6 login-right">
                                                <div className="login-header">
                                                    <h3> </h3>
                                                </div>
                                                <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                                    <div className="row">
                                                        <div className="form-group form-focus col-md-6">
                                                            <Field name="FirstName" component={renderField} label={languagecontent['firstname']} maxLength={50} validate={[this.required]} type="text" />
                                                        </div>
                                                        <div className="form-group form-focus col-md-6">
                                                            <Field name="LastName" component={renderField} label={languagecontent['lastname']} maxLength={50} validate={[this.required]} type="text" />
                                                        </div>
                                                    </div>
                                                    <Field name="Email" component={renderField} label={languagecontent['email']} maxLength={50} validate={[this.required, email]} type="email" />
                                                    <Field name="Password" component={renderField} label={languagecontent['password']} validate={[this.required, password]} type="password" />
                                                    <div className='position-relative'>

                                                        <Field name="ConfirmPassword" component={renderField} label={languagecontent['confirmpassword']} validate={[this.required, password, matchInput]} type={passwordVisible ? "text" : "password"}/>

                                                        <i onClick={this.handleToggle} className={passwordVisible ? "password-icon fas fa-eye-slash" : "password-icon fas fa-eye"}></i>
                                                    </div>
                                                    {/*<Field name="ConfirmPassword" component={renderField} label={languagecontent['confirmpassword']} validate={[this.required, password, matchInput]} type="password" />*/}
                                                    <p className="small text-muted" style={{ marginTop: "-5px" }}>{languagecontent['passwordrestrictions']}</p>


                                                    {
                                                        this.props.Settings.length == 0 ? "" :

                                                            invitecode == 'No' ? "" :
                                                                <p className="small text-muted">{languagecontent['invitationcodedesc']}
                                                                    <Field name="InvitiationCode" component={renderField} label={languagecontent['invitationcode']} maxLength={5} type="text" /></p>


                                                    }

                                                    {
                                                        termssrc != "" && privacysrc != "" ?
                                                            languagecontent['privacypolicysignuppage'] == undefined ? "" :
                                                                <Field name="PravicyPolicy" component={renderSignUpcheckBox} label={" " + languagecontent['privacypolicysignuppage'] + " " + '<a href=' + termssrc + ' className="text-info" target="_blank">' + languagecontent['termsandcondition'] + '</a> ' + languagecontent['and'] + ' <a href=' + privacysrc + ' className="text-info" target="_blank">' + languagecontent['privacypolicy'] + '</a>'} validate={[this.required]} type="checkbox" />
                                                            : ""
                                                    }
                                                    {
                                                        termssrc == "" && privacysrc == "" ? "" : ""

                                                    }
                                                    {
                                                        termssrc == "" && privacysrc != "" ? languagecontent['privacypolicysignuppage'] == undefined ? "" :
                                                            <Field name="PravicyPolicy" component={renderSignUpcheckBox} label={" " + languagecontent['privacypolicysignuppage'] + " " + ' <a href=' + privacysrc + ' className="text-info" target="_blank">' + languagecontent['privacypolicy'] + '</a>'} validate={[this.required]} type="checkbox" />
                                                            :
                                                            ""
                                                    }
                                                    {
                                                        privacysrc == "" && termssrc != "" ? languagecontent['privacypolicysignuppage'] == undefined ? "" :
                                                            <Field name="PravicyPolicy" component={renderSignUpcheckBox} label={" " + languagecontent['privacypolicysignuppage'] + " " + '<a href=' + termssrc + ' className="text-info" target="_blank">' + languagecontent['termsandcondition'] + '</a> '} validate={[this.required]} type="checkbox" />
                                                            : ""

                                                    }
                                                    <button className="btn btn-primary btn-block btn-lg login-btn" disabled={this.props.isSignUpLoading} type="submit" >
                                                        {
                                                            languagecontent['signupbutton'] == undefined ? <img src="../../../buttonloader.svg" /> :
                                                                <React.Fragment>
                                                                    {this.props.isSignUpLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['signupbutton']}
                                                                </React.Fragment>
                                                        }
                                                    </button>
                                                    <div className="text-center dont-have">{languagecontent['signupfooter']}
                                                        <NavLink className="linkcustomcolor " to="/login">  {languagecontent['signin'] == undefined ? "" : languagecontent['signin']}</NavLink>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            layout == "NavSanthigram" ?
                                <div className="row visible-mobile">
                                    <div className="col-sm-12">
                                        <img src="client/default/img/signuprep.png" style={{ marginBottom: "-18px" }} className="img-fluid" />
                                    </div>
                                </div>
                                : ""
                        }
                    </div> : <div></div>}
                </React.Fragment> 
        );
    }
}

const mapDispatchToProps = { ...SignUpStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...SiteMenusStore.actionCreators, ...AccountStore.actionCreators, ...SiteDetailStore.actionCreators, ...MenuHideStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => ({ ...state.signup, ...state.langResources, ...state.navDefault, ...state.siteMenus, ...state.account, ...state.siteDetail, ...state.menuHide })
export default reduxForm({
    form: 'SignUp',
})(connect(mapStateToProps, mapDispatchToProps)(SignUp as any))


