import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import Moment from 'moment';
import { errorHandling } from '../../helper/ErrorHandling';
import { convertYeartoDate, dateFormatSetting, datePostFormat, getParameterByName } from '../../helper/formvalidation';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface ContactState {
    isContactLoading: boolean;
    contact: Contact;
}
export interface Contact {
    id: string;
    memberId: string;
    patientNo: string;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string;
    gender: string;
    address1: string;
    address2: string;
    zipcode: string;
    cellPhone: string;
    email: string;
    bloodGroup: string;
    locationName: string;
    locationId: string;
    photo: string;
    pharmacyAddress: string;
    pharmacyCity: string;
    pharmacyName: string;
    pharmacyPhoneNo: string;
    pharmacyState: string;
    pharmacyZipCode: string;
    pharmacyId: string;
    timeZone: string;
    dateofbirth: string;
    accountName: string;
}
// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestContactAction { type: 'REQUEST_PATIENT_CONTACT', Contact: Contact }
export interface ReciveContactAction { type: 'RECEIVE_PATIENT_CONTACT', Contact: Contact }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestContactAction | ReciveContactAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestSaveContact: (data: any, patient: any, loactionid: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState: any = getState();
        if (appState && appState.contact) {
            var locationdata = window.location.pathname;
            const _locationarray = locationdata.split('/');
            data.MemberId = patient.memberId;
           var LocationId = loactionid == "" ? patient.locationId : loactionid;
            if (data.dob != null) {
                data.patientdate = convertYeartoDate(data.dob);
            }
            dispatch(showLoading('sectionBar'));
            var patientdata = {
                Id: data.id,
                MemberId: data.memberId,
                FirstName: data.firstName.trim(),
                MiddleName: data.middleName,
                LastName: data.lastName.trim(),
                UserId: data.userId,
                PharmacyId: data.pharmacyId,
                DOB: data.patientdate,
                PatientNo: data.patientNo,
                Gender: data.gender,
                Address1: data.address1,
                Address2: data.address2,
                LocationId: LocationId,
                LocationName: data.locationName,
                Zipcode: data.zipcode,
                CellPhone: data.cellPhone,
                Email: data.email,
                BloodGroup: data.bloodGroup,
                IsEmail: data.isEmail,
                IsSMS: data.isSMS,
                Photo: data.photo,
                PharmacyName: data.pharmacyName,
                PharmacyAddress: data.pharmacyAddress,
                PharmacyCity: data.pharmacyCity,
                PharmacyState: data.pharmacyState,
                PharmacyZipCode: data.pharmacyZipCode,
                PharmacyPhoneNo: data.pharmacyPhoneNo,
                TimeZone: data.timeZone,
                ModifiedBy: data.modifiedBy,
                ModifiedOn: data.modifiedOn,
                Modified: data.modified
            }
            axios.post('patient/profileupdate', patientdata)
                .then(response => {
                    dispatch(reset("contactForm"));
                    dispatch(hideLoading('sectionBar'))
                    const result = response.data;
                    dispatch(actionCreators.requestPatient())
                    result["dateofbirth"] = result.dob;
                    result.dob = dateFormatSetting(result.dob)
                    var data = window.location.pathname;
                    const _array = data.split('/');
                    localStorage.removeItem("SelectedLocation");
                    localStorage.removeItem("ProfileData");
                    var oldpatient: any = JSON.parse(localStorage.getItem("Patient") || "");
                    var offset: any = oldpatient.offset;
                    localStorage.setItem("Patient", JSON.stringify({ id: result.id, memberId: result.memberId, userId: result.userId, Name: result.firstName + " " + result.lastName, FirstName: result.firstName, LastName: result.lastName, Address1: result.address1, Address2: result.address2, Email: result.email, locationName: result.locationName, zipcode: result.zipcode, locationId: result.locationId, dob: result.dob, cellPhone: result.cellPhone, gender: result.gender, timeZone: result.timeZone  }));
                    var url = localStorage.getItem("redirectUrl");
                    if (url == null)
                        url = "";
                    else
                        url = localStorage.getItem("redirectUrl");

                    dispatch({ type: 'RECEIVE_PATIENT_CONTACT', Contact: result });
                    var timezonetimesloturl = localStorage.getItem("TimeZoneTimeSlot");
                    if (timezonetimesloturl != null) {
                        return window.location.href = timezonetimesloturl;
                    }
                    if (localStorage.getItem("pathName") == "conditions" && _array[1] == "contact") {
                        var newArray: any = "";
                        url = localStorage.getItem("redirectUrl") + "&patientId=" + result.id;
                        if (appState != undefined) {
                            newArray = appState.account.Settings.filter(function (el: any) {
                                return (el.name == "Reason for Visit");
                            });
                        }
                        if (url != null) {
                            var accountoffset: any = localStorage.getItem("timeOffset");
                            if (newArray[0].name == "Reason for Visit" && newArray[0].customizeValue == "Yes") {
                                var urldate = getParameterByName("date", url);
                                var urltime = getParameterByName("time", url);
                                if (offset == parseInt(accountoffset)) {
                                    document.location.href = url
                                } else {
                                    var datetime: any = new Date(urldate + " " + urltime);
                                    //datetime.setTime(datetime.getTime() + -330 * 60000);
                                    //datetime.setTime(datetime.getTime() + offset * 60000);
                                    if (Math.sign(parseInt(accountoffset)) == -1)
                                        accountoffset = Math.abs(parseInt(accountoffset));
                                    else
                                        accountoffset = -Math.abs(parseInt(accountoffset))

                                    var CSToffSet = accountoffset; //CST is -6:00 of UTC; i.e. 60*6 = -360 in minutes
                                    var _offset = CSToffSet * 60 * 1000;
                                    var CSTTime = new Date(datetime.getTime() + _offset);
                                    CSTTime.setTime(CSTTime.getTime() + (offset * 60000));

                                    var date = Moment(CSTTime).format('YYYY-MM-DD');
                                    var setdateurl = updateQueryStringParameter(url, "date", date) + "&patientId=" + result.id;
                                    var time = Moment(CSTTime).format('hh:mm A');
                                    document.location.href = updateQueryStringParameter(setdateurl, "time", time);
                                }
                               
                            }
                        }
                    } else if (localStorage.getItem("pathName") == "RequestQueue" && _array[1] == "contact"){
                        var patient = JSON.parse(localStorage.getItem("Patient") || "");
                        var _url: any = localStorage.getItem("redirectQequestQueueUrl") + "&patientId=" + patient.id;
                        window.location.href = _url;
                    } else {
                        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                        toastr.success('', langdata['successfuly'])
                    }
                })
                .catch(function (error) {
                    var patientLocalStroage: any = localStorage.getItem('Patient');
                    var patient = JSON.parse(patientLocalStroage);
                    localStorage.removeItem("SelectedLocation");
                    localStorage.removeItem("ProfileData");
                    var dateformat: any = localStorage.getItem("DateFormat");
                    const result = {
                        "id": patient.id,
                        "memberId": patient.memberId,
                        "firstName": patient.FirstName,
                        "middleName": patient.LastName,
                        "lastName": patient.LastName,
                        "userId": patient.userId,
                        "dob": dateFormatSetting(data.dob),
                        "gender": patient.gender,
                        "address1": patient.Address1,
                        "address2": patient.Address2,
                        "locationId": patient.locationId,
                        "locationName": patient.locationName,
                        "zipcode": patient.zipcode,
                        "cellPhone": patient.cellPhone,
                        "email": patient.Email,
                        "dateofbirth": patient.dob
                    };

                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.ModelState != undefined) {
                            toastr.error('', errorObject.ModelState[""][0]);
                        } else {
                            var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            if (errorObject.Message == "No Changes") {
                                if (_locationarray[1] == "contact") {
                                    var url = localStorage.getItem("redirectUrl");
                                    if (url == null)
                                        url = "";
                                    else
                                        url = localStorage.getItem("redirectUrl");
                                    if (localStorage.getItem("pathName") == "conditions") {
                                        var newArray: any = "";
                                        url = localStorage.getItem("redirectUrl") + "&patientId=" + patient.id;
                                        if (appState != undefined) {
                                            newArray = appState.account.Settings.filter(function (el: any) {
                                                return (el.name == "Reason for Visit");
                                            });
                                        }
                                        if (url != null) {
                                            if (newArray[0].name == "Reason for Visit" && newArray[0].customizeValue == "Yes") {
                                                window.location.href = url;
                                            }
                                        }
                                    } else if (localStorage.getItem("pathName") == "RequestQueue") {
                                        var newArray: any = "";
                                        var _url = localStorage.getItem("redirectQequestQueueUrl");
                                        if (appState != undefined) {
                                            newArray = appState.account.Settings.filter(function (el: any) {
                                                return (el.name == "Reason for Visit");
                                            });
                                        }
                                        if (_url != null) {
                                            if (newArray[0].name == "Reason for Visit" && newArray[0].customizeValue == "Yes") {
                                                window.location.href = _url;
                                            }
                                        }
                                    }
                                }
                                else {
                                    var timezonetimesloturl = localStorage.getItem("TimeZoneTimeSlot");
                                    if (timezonetimesloturl != null) {
                                        return window.location.href = timezonetimesloturl;
                                    }
                                    toastr.success('', errorObject.Message == "No Changes" ? langdata['nochanges'] : errorObject.Message);
                                }
                                dispatch(actionCreators.requestPatient());
                                
                            }
                            else {
                                if (errorObject.Message == "SqlDateTime overflow. Must be between 1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM.") {
                                    toastr.error('', langdata['invaliddate']);
                                    dispatch(actionCreators.requestPatient());
                                } else {
                                    toastr.error('', errorObject.Message);
                                }
                            }
                        }

                    } else {
                        localStorage.removeItem("ProfileData");
                        //errorHandling(error.response.data.statusCode);
                        if (error.response.data == "Please select valid timezone") {
                            var langdata: any;
                            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            toastr.error('', langdata['pleaseselectvalidtimezone']);
                            dispatch(actionCreators.requestPatient());
                        }
                        //else if (dob == "undefined")
                        //{ toastr.error('', langdata['invaliddate']); }
                            else {
                             toastr.error('', langdata['somethingwentwrong']);
                        }
                    }
                    dispatch(actionCreators.requestPatient());
                    dispatch({ type: 'RECEIVE_PATIENT_CONTACT', Contact: result, isContactLoading: false });
                    dispatch(reset("contactForm"));

                    dispatch({ ...hideLoading('sectionBar') })
                })
                .then(function () {
                });
        }

    },
    requestPatient: (): AppThunkAction<KnownAction> => (dispatch: any, getState:any) => {
        const appState = getState();
        if (appState && appState.contact) {
            axios.get('/patient/getpatient')
                .then(function (response) {
                    const result = response.data;
                    if (result.preferredLanguage && result.preferredLanguage !== "") {
                        localStorage.setItem("DefaultLangCode", result.preferredLanguage)
                    } else {
                        localStorage.setItem("DefaultLangCode", "en-us")
                    }
                    localStorage.setItem("Patient", JSON.stringify({ id: result.id, memberId: result.memberId, userId: result.userId, Name: result.firstName + " " + result.lastName, FirstName: result.firstName, LastName: result.lastName, Address1: result.address1, Address2: result.address2, Email: result.email, locationName: result.locationName, zipcode: result.zipcode, locationId: result.locationId, dob: result.dob, cellPhone: result.cellPhone, gender: result.gender, timeZone: result.timeZone }));

                    const patient_local_storage:any = localStorage.getItem('Patient');

                    const patient:any = JSON.parse(patient_local_storage);

                    patient.offset = result.offset;

                    const updatedPatient:any = JSON.stringify(patient);

                   // localStorage.setItem('Patient', updatedPatient);

                    result.photo = response.data.photo == null ? "client/default/img/blankimg.jpg" : response.data.photo;
                    result["dateofbirth"] = result.dob;
                    result.dob = dateFormatSetting(result.dob)

                    dispatch({ type: 'RECEIVE_PATIENT_CONTACT', Contact: result });
                })
                .catch(function (error) {
                    console.log(error)
                    if (error.response != undefined) {

                        if (error.response.data.statusCode == 401) {
                            localStorage.setItem("isAuth", "false");
                            console.log("contact ts")
                            localStorage.removeItem("Patient");
                            //localStorage.removeItem("isAuth");
                            localStorage.removeItem("pathName");
                            localStorage.removeItem("redirectUrl");
                            localStorage.removeItem("EmailId");
                            localStorage.removeItem("redirectQequestQueueUrl");
                            localStorage.removeItem("Appointment");
                            localStorage.removeItem("ProfileData");
                            window.location.href = "/login";
                        } else {
                            //  errorHandling(error);
                            console.log(error)

                        }
                    } else {
                        return;
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_PATIENT_CONTACT' });
        }
    },
    requestSavePhoto: (patientid: any, base64data: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.contact) {
            var data = { PatientId: patientid, Picture: base64data }
            axios.post('patient/postphoto', data)
                .then(response => {
                    var langdata: any;
                    if (localStorage.getItem("LangResourcesValidations") != null)
                        langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    toastr.success('', langdata['profilepictureupdate']);
                    dispatch(actionCreators.requestPatient());
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
        }
    },
    goPharmacyNav: (data: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.contact) {
            var locationdata = window.location.pathname;
            const _locationarray = locationdata.split('/');
            setTimeout(() => {
                dispatch({ type: 'RECEIVE_PATIENT_CONTACT', Contact: data });
                dispatch(push("/pharmacylist?memberid=" + _locationarray[2] + "&type=profile"))
            }, 0)
            dispatch({ type: 'REQUEST_PATIENT_CONTACT' });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: ContactState = {
    contact: {
        id: "",
        memberId: "",
        patientNo: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        address1: "",
        address2:"",
        zipcode: "",
        cellPhone: "",
        email: "",
        bloodGroup: "",
        locationName: "",
        locationId: "",
        photo: "client/default/img/blankimg.jpg",
        pharmacyAddress: "",
        pharmacyCity: "",
        pharmacyName: "",
        pharmacyPhoneNo: "",
        pharmacyState: "",
        pharmacyZipCode: "",
        pharmacyId: "",
        timeZone: "",
        dateofbirth: "",
        accountName: "",
    },
    isContactLoading: false
};
export const reducer: Reducer<ContactState> = (state: ContactState | undefined, incomingAction: Action): ContactState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PATIENT_CONTACT':
            return {
                contact: state.contact,
                isContactLoading: true
            };
        case 'RECEIVE_PATIENT_CONTACT':
            return {
                contact: action.Contact,
                isContactLoading: false
            };
        default:
            return state;
    }
};
function toDate(date: any) {
    if (date != null) {
        var currentDateTime = new Date(date);
        return currentDateTime
    }
}
function DisplayCurrentTime(datetime: Date) {
    var date = datetime;
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return (hours + ":" + minutes + ":" + seconds);
};
function updateQueryStringParameter(uri: any, key: any, value: any) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
        return uri + separator + key + "=" + value;
    }
};