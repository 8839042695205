import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as ContactStore from '../../store/patient/Contact';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import FlexiFormClass from '../../components/library/flexiform/FlexiForm'
import * as LoginStore from '../../store/account/Login';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { setTittle } from '../../helper/formvalidation';
import { seo } from '../../helper/Script';

// At runtime, Redux will merge together...
type FormsProps =
	FlexiFormStore.FlexiListsState & ContactStore.ContactState & LoginStore.LoginState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
	& typeof FlexiFormStore.actionCreators & typeof ContactStore.actionCreators & typeof LoginStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var languagecontent: any = "";
class Forms extends React.PureComponent<FormsProps> {
	constructor(props: any) {
		super(props);
	}

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.ensureDataFetched();
	}
	//public componentDidUpdate() {
	//	seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
	//}
	public render() {
		return (
			<React.Fragment>
				{this.renderDoctorList()}
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		//this.props.getPtaient();
		//this.props.getLangResources();
		//this.props.requestFlexiForm(this.props.contact.id, "", "", "", this.props.contact.id, "");
	}

	private renderDoctorList() {
		return (
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<DashboardNavMenu />
						<div className="col-md-7 col-lg-8 col-xl-9">
							<div className="card">
								<div className="card-body">
									<FlexiFormClass />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({ ...state.flexiform, ...state.contact, ...state.login, ...state.langResources }), // Selects which state properties are merged into the component's props
	({ ...FlexiFormStore.actionCreators, ...ContactStore.actionCreators, ...LoginStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(Forms as any);
