import axios from 'axios';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling, errorMessage } from '../../helper/ErrorHandling';
import { toastr } from 'react-redux-toastr';
import Moment from 'moment';
import { toDate } from '../../helper/formvalidation';

export interface RescheduleState {
    isResceduleLoading: boolean;
}

interface RequestRescheduleAction {
    type: 'REQUEST_APPOINTMENT_RESCHEDULE';
    isResceduleLoading: boolean;
}

interface ReceiveRescheduleAction {
    type: 'RECEIVE_APPOINTMENT_RESCHEDULE';
    isResceduleLoading: boolean;
}

type KnownAction = RequestRescheduleAction | ReceiveRescheduleAction;

export const actionCreators = {
    requestReScheduleUpdate: (formvalue: any, date: any, time: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState: any = getState();
        const urlParams: any = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        var noappointment = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
        if (date != null) {
            if (window.location.hostname == "localhost") {
                if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
                    var appointmenton = Moment.utc(new Date()).format('YYYY/MM/DD'); //Current datetime
                    formvalue.AppointmentOn = Moment.utc(toDate(appointmenton + " " + formatAMPM(new Date()))).format("YYYY-MM-DDTHH:mm:ss");
                } else {
                    formvalue.AppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime")).format("YYYY-MM-DDTHH:mm:ss");
                    if (localStorage.getItem("AppointmentUTCTime1"))
                        formvalue.SecondaryAppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime1")).format("YYYY-MM-DDTHH:mm:ss");
                    //var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD'); //Current datetime
                    //var totalMinuteOffset: any = 0;
                    //if (formvalue.Offset == "") {
                    //    var timezone = appState.account.Settings.filter((se: any) => se.name == "Time Zone")[0]
                    //    var _timezone = timezone.displayCode.split("|");
                    //    totalMinuteOffset = _timezone[1];
                    //    var d = new Date(appointmenton + " " + time);
                    //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                    //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                    //} else {
                    //    const tzOffset = formvalue.Offset;
                    //    const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
                    //    const totalMinuteOffset = hourOffset * 60 + minuteOffset;
                    //    var d = new Date(appointmenton + " " + time);
                    //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                    //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                    //}
                }
            } else {
                if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
                    var appointmenton = Moment(new Date()).format('YYYY/MM/DD');
                    var datetime: any = toDate(appointmenton + " " + formatAMPM(new Date()));
                    var appointmenttime = DisplayCurrentTime(datetime);
                    appointmenton = Moment(new Date()).format('YYYY-MM-DD');
                    var _date = new Date(appointmenton + "T" + appointmenttime);
                    formvalue.AppointmentOn = Moment.utc(_date).format("YYYY-MM-DDTHH:mm:ss");
                } else {
                    formvalue.AppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime")).format("YYYY-MM-DDTHH:mm:ss");
                    if (localStorage.getItem("AppointmentUTCTime1"))
                        formvalue.SecondaryAppointmentOn = Moment(localStorage.getItem("AppointmentUTCTime1")).format("YYYY-MM-DDTHH:mm:ss");
                    //var totalMinuteOffset: any = 0;
                    //if (formvalue.Offset == "") {
                    //    var timezone = appState.account.Settings.filter((se: any) => se.name == "Time Zone")[0]
                    //    var _timezone = timezone.displayCode.split("|");
                    //    var offset = _timezone[1];
                    //    var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD');
                    //    var datetime: any = toDate(appointmenton + " " + time);
                    //    var d = new Date(appointmenton + " " + time); // get current date
                    //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(offset)), 0, 0);
                    //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                    //} else {
                    //    const tzOffset = formvalue.Offset;
                    //    const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
                    //    const totalMinuteOffset = hourOffset * 60 + minuteOffset;
                    //    var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD');
                    //    var datetime: any = toDate(appointmenton + " " + time);
                    //    var d = new Date(appointmenton + " " + time); // get current date
                    //    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
                    //    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
                    //}
                }

            }
        }
        var data: any = {
            Id: formvalue.appointmentid,
            AppointmentOn: formvalue.AppointmentOn,
            SecondaryAppointmentOn: formvalue.SecondaryAppointmentOn
        }
        axios.post('/appointment/ReSchedulePost', data)
            .then(function (response) {
                dispatch({ type: 'RECEIVE_APPOINTMENT_RESCHEDULE', isResceduleLoading: false });
                dispatch(push("/success/" + formvalue.appointmentid))
            })
            .catch(function (error) {
                dispatch({ type: 'RECEIVE_APPOINTMENT_RESCHEDULE', isResceduleLoading: false });
                var errorObject = JSON.parse(error.response.data.errorMessage);
                if (errorObject.Message == "No Changes") {
                    dispatch(push("/success/" + formvalue.appointmentid))
                } else {
                    errorHandling(error.response.data.statusCode);
                }
            })
            .then(function () {
            });
        dispatch({ type: 'REQUEST_APPOINTMENT_RESCHEDULE', isResceduleLoading: true});

    }
};

const UnloadedState: RescheduleState = {
    isResceduleLoading: false
};


export const reducer: Reducer<RescheduleState> = (state: RescheduleState | undefined, incomingAction: Action): RescheduleState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_RESCHEDULE':
            return {
                isResceduleLoading: true
            };
        case 'RECEIVE_APPOINTMENT_RESCHEDULE':
            return {
                isResceduleLoading: false
            };
        default:
            return state;
    }
};
const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};
function DisplayCurrentTime(datetime: Date) {
    var date = datetime;
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return (hours + ":" + minutes + ":" + seconds);
};