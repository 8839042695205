import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';


export interface DoctorBioState {
    isLoading: boolean;
    startDateIndex?: number;
    doctorBio: DoctorBio;
}

export interface DoctorTimingState {
    isLoading: boolean;
    startDateIndex?: number;
    timeZone: any;
    doctorTimings: DoctorTiming[]
}


export interface DoctorBio {
    doctorId: string;
    bioData: string;
}

export interface DoctorTiming {
    id : string;
    day: string;
    time: string;
    userId : string;
    modifiedOn: string;
    modifiedBy: string;
}

interface RequestDoctorBio {
    type: 'REQUEST_DOCTOR_PROFILE';
    doctorBio: DoctorBio;
}

interface ReceiveDoctorBio {
    type: 'RECEIVE_DOCTOR_PROFILE';
    doctorBio: DoctorBio;
}

interface RequestDoctorTiming {
    type: 'REQUEST_DOCTOR_TIMING';
    doctorTiming: DoctorTiming[];
    timeZone: "";
}

interface ReceiveDoctorTiming {
    type: 'RECEIVE_DOCTOR_TIMING';
    doctorTiming: DoctorTiming[];
    timeZone: "";
}

type KnownAction = RequestDoctorBio | ReceiveDoctorBio | RequestDoctorTiming | ReceiveDoctorTiming ;

export const actionCreators = {
    requestDoctorProfile: (doctor: any): AppThunkAction<KnownAction> => (dispatch:any, getState) => {
        const appState = getState();
        if (appState && appState.headerDrProfile) {
            axios.get('/doctor/GetDoctorBioData/' + doctor.doctorid)
                .then(function (response) {
                    const data = response.data;
                    dispatch({ type: 'RECEIVE_DOCTOR_PROFILE', doctorBio: data });
                })
                .catch(function (error) {
                    errorHandling(error.response.status);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTOR_PROFILE' });
        }
        if (appState && appState.headerDrProfile) {
            var accountid = localStorage.getItem("AccountId");
            axios.get('/doctor/GetDoctorTiming/' + doctor.doctorid + "/" + accountid)
                .then(function (response) {
                    const data = response.data.doctorTiming;                  
                    dispatch({ type: 'RECEIVE_DOCTOR_TIMING', doctorTiming: data, isLoading: false, timeZone: response.data.timeZone});
                })
                .catch(function (error) {
                    errorHandling(error.response.status);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTOR_TIMING', doctorTimings:[] });
        }
    },

     requestDoctorTiming: (doctor: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
       
        if (appState && appState.headerDrProfile) {
            var accountid = localStorage.getItem("AccountId");
            var doctorid = localStorage.getItem("doctorid");
            axios.get('/doctor/GetDoctorTiming/' + doctorid + "/" + accountid)
                .then(function (response) {
                    const data = response.data.doctorTiming;
                    dispatch({ type: 'RECEIVE_DOCTOR_TIMING', doctorTiming: data, isLoading: false, timeZone: response.data.timeZone });
                })
                .catch(function (error) {
                    errorHandling(error.response.status);
                })
                .then(function () {
                });
            //dispatch({ type: 'REQUEST_DOCTOR_TIMING', doctorTimings: [] });
        }
    }
};


const bioUnloadedState: DoctorBioState = {
    doctorBio: { doctorId: "", bioData: "" }, isLoading: false
};

const timingUnloadedState: DoctorTimingState = {
    doctorTimings: [], isLoading: false, timeZone: ""
};

export const bioreducer: Reducer<DoctorBioState> = (state: DoctorBioState | undefined, incomingAction: Action): DoctorBioState => {
    if (state === undefined) {
        return bioUnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DOCTOR_PROFILE':
            return {
                doctorBio: state.doctorBio,
               
                isLoading: true
            };
        case 'RECEIVE_DOCTOR_PROFILE':
                return {
                    doctorBio: action.doctorBio,
                   
                    isLoading: false
            };
        default:
            return state;
    }
};

export const timingreducer: Reducer<DoctorTimingState> = (state: DoctorTimingState | undefined, incomingAction: Action): DoctorTimingState => {
    if (state === undefined) {
        return timingUnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DOCTOR_TIMING':
            return {
                doctorTimings: state.doctorTimings,
                timeZone: state.timeZone,
                isLoading: true
            };
        case 'RECEIVE_DOCTOR_TIMING':
            return {
                doctorTimings: action.doctorTiming,
                timeZone: action.timeZone,
                isLoading: false
            };
        default:
            return state;
    }
};
