import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';

export interface ForgotPasswordState {
    isFPLoading: boolean;
    forgotPassword: ForgotPassword;
}

export interface ForgotPassword {
    email: string;
}

interface RequestForgortPassword {
    type: 'REQUEST_FORGOT_PASSWORD';
    forgotPassword: ForgotPassword;
}

interface ReceiveForgortPassword {
    type: 'RECEIVE_FORGOT_PASSWORD';
    forgotPassword: ForgotPassword;
}

type KnownAction = RequestForgortPassword | ReceiveForgortPassword;

export const actionCreators = {
    requesForgetPassword: (userObj: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        userObj.AccountId= localStorage.getItem("AccountId")
        dispatch(showLoading('sectionBar'))
        var langdata: any = "";
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

        if (appState && appState.changePassword) {
            axios.post('account/forgotpassword', userObj)
                .then(function (response) {
                    dispatch(hideLoading('sectionBar'))
                    dispatch(reset("forgotPasswordForm"));
                    dispatch({ type: 'RECEIVE_FORGOT_PASSWORD', isFPLoading: false });
                    setTimeout(() => {
                        dispatch(push("/emailactivation"))
                    }, 1000)
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.ModelState != undefined) {
                            toastr.error('', errorObject.ModelState[""][0]);
                        } else {
                            if (errorObject.Message == "Enter Valid UserName or Email") {
                                toastr.error('', langdata['entervalidemail']);
                            } else {
                                toastr.error('', errorObject.Message);
                            }
                         
                        }
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ type: 'RECEIVE_FORGOT_PASSWORD', isFPLoading: false });
                    dispatch({ ...hideLoading('sectionBar') })
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_FORGOT_PASSWORD' });
        }
    },
};

const UnloadedState: ForgotPasswordState = {
    forgotPassword: {
        email: "",
    },
    isFPLoading: false
};

export const reducer: Reducer<ForgotPasswordState> = (state: ForgotPasswordState | undefined, incomingAction: Action): ForgotPasswordState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FORGOT_PASSWORD':
            return {
                forgotPassword: state.forgotPassword,
                isFPLoading: true
            };
        case 'RECEIVE_FORGOT_PASSWORD':
            return {
                forgotPassword: action.forgotPassword,
                isFPLoading: false
            };
        default:
            return state;
    }
};
