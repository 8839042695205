import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Action, Reducer } from 'redux';
import { reset, initialize } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { convertYeartoDate, datePostFormat, toDate } from '../../helper/formvalidation';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface FamilyMemberState {
    isLoading: boolean,
    isPostLoading: boolean,
    familyMembers: FamilyMember[]
}

export interface FamilyMember{
    Id: string;
    FirstName: string,
    LastName: string,
    Gender: string,
    DOB: string,
    RelationShipId: any,
    ContactNumber: string,
    Email: string,
    TypeId: any,
    ParentId: string,
    PatientId: string,
    AccountId: string,
    Relationship: string
    Url: string;
    ContactId: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestFamilyMemberAction { type: 'REQUEST_FAMILY_MEMBER', familyMembers: FamilyMember[] }
export interface ReciveFamilyMemberAction { type: 'RECEIVE_FAMILY_MEMBER', familyMembers: FamilyMember[] }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestFamilyMemberAction | ReciveFamilyMemberAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    clearFamilyForm: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        //setTimeout(function () {
        //    dispatch(initialize("familyMemberForm", ""));
        //}, 0);
        dispatch(reset("familyMemberForm"));
    },
    clearForm: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        setTimeout(function () {
            dispatch(initialize("familyMemberForm", ""));
        }, 0);
       // dispatch(reset("familyMemberForm"));
    },
    postFamilyMember: (result: any, familydetail:any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
    const urlParams = new URLSearchParams(window.location.search);
    const memberid = urlParams.get('memberid');
        var accountid: any = localStorage.getItem("AccountId");
        var dateofbirth = convertYeartoDate(result.dob);
        var data = {
            Id: result.Id,
            FirstName: result.firstName.trim(),
            LastName: result.lastName.trim(),
            Gender: result.gender,
            DOB: dateofbirth,
            RelationShipId: result.RelationShipId,
            ContactNumber: result.cellPhone,
            Email: result.email,
            StatusId: result.Id != undefined ? "1" : result.Id,
            TypeId: "11",
            ParentId: memberid,
            AccountId: accountid,
            ContactId: result.ContactId,
            Photo: result.Photo
    };
    axios.post('familymember', data)
            .then(response => {
                dispatch(reset("familyMemberForm"));
                dispatch(hideLoading('sectionBar'))
                const result = response.data;
                var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                if (result == "No Changes") {
                    toastr.success('', langdata['nochanges'])
                }
                else {
                    if (data.Id == familydetail.Id && data.FirstName == familydetail.FirstName && data.LastName == familydetail.LastName && data.Gender == familydetail.Gender && data.DOB == Moment(familydetail.DOB).format('YYYY-MM-DD') && data.ContactNumber == familydetail.ContactNumber && data.Email == familydetail.Email && data.RelationShipId == familydetail.RelationShipId ) {
                        toastr.success('', langdata['nochanges'])
                    } else {
                        toastr.success('', langdata['successfuly'])
                    }
                }
                let element: HTMLElement = document.getElementsByClassName('close')[0] as HTMLElement;
                element.click();
                dispatch(actionCreators.getFamilyMemberList(memberid))
            })
          .catch(function (error) {
              dispatch(hideLoading('sectionBar'))
              var errorObject = JSON.parse(error.response.data.errorMessage);
              dispatch({ type: 'RECIVE_FAMILY_MEMBER', isPostLoading: false });
              var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
              if (errorObject.Message == "SqlDateTime overflow. Must be between 1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM.") {
                  toastr.error('', langdata['invaliddate']);
                    dispatch(actionCreators.getFamilyMemberList(memberid))
              } else {
                  errorHandling(error.response.data.statusCode);
              }
        });
        dispatch({ type: 'REQUEST_FAMILY_MEMBER', isPostLoading: true });
    },
    getFamilyMemberList: (memberid: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.familyMembers) {
            var langcode: any = localStorage.getItem("DefaultLangCode");
            axios.get('/familymember/getfamily/' + memberid + "/" + langcode)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_FAMILY_MEMBER', familyMembers: result, isLoading: false, isPostLoading: true });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_FAMILY_MEMBER', isLoading: true });
        }
    },
    requestSavePhoto: (memberid: any, base64data: any, currentfile:any): AppThunkAction<KnownAction> => async (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.contact) {
            const formData = new FormData();
            formData.append("file", currentfile);
            formData.append("Id", memberid);
            formData.append("Picture", base64data);
            formData.append("FileName", currentfile.name);
            formData.append("ContentType", "image/png");
            try {
                const res = await axios.post("/familymember/ImportFile", formData);
                if (res.status == 200) {
                    toastr.success('', 'Updated successfully')
                    const urlParams = new URLSearchParams(window.location.search);
                    const memid = urlParams.get('memberid');
                    dispatch(actionCreators.getFamilyMemberList(memid))
                    let element: HTMLElement = document.getElementsByClassName('close')[0] as HTMLElement;
                    element.click();
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: FamilyMemberState = {
    familyMembers: [],
    isLoading: false,
    isPostLoading: false
};
export const reducer: Reducer<FamilyMemberState> = (state: FamilyMemberState | undefined, incomingAction: Action): FamilyMemberState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_FAMILY_MEMBER':
            return {
                familyMembers: state.familyMembers,
                isLoading: true,
                isPostLoading: true
            };
        case 'RECEIVE_FAMILY_MEMBER':
            return {
                familyMembers: action.familyMembers,
                isLoading: false,
                isPostLoading: false
            };
        default:
            return state;
    }
};
