import * as React from 'react'
import { Meta } from '../store/account/Login';
import * as Contact from '../components/Patient/Contact';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import ContentLoader from 'react-content-loader';
import { TextBoxLoader } from './fbloader';
import { useEffect, useState } from 'react';

export const renderField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    isContentLoading,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    maxLength: number;
    bindvalue: string;
    isContentLoading: string;
    meta: Meta;


}) => (
    <React.Fragment>
        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className="form-group form-focus">
            <input  {...input} type={type} maxLength={maxLength} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} />
            <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderQuestionField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    isContentLoading,
    meta: { touched, error, warning },
    placeholder
}: {
    input: string;
    type: string;
    label: string;
    maxLength: number;
    bindvalue: string;
    isContentLoading: string;
    meta: Meta;
    placeholder: any;


}) => (
    <React.Fragment>
        <div hidden={label != undefined}><TextBoxLoader /></div>
        <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
        <div hidden={label == undefined} className="form-group form-focus">
            <input  {...input} type={type} maxLength={maxLength} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} style={{height:"52px"}} placeholder={placeholder} />            
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderDisabledField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    maxLength: number;
    bindvalue: string;
    meta: Meta;


}) => (
    <div className="form-group form-focus"  >
        <input  {...input} type={type} maxLength={maxLength} disabled={true} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} />
        <label className="focus-label" style={{ backgroundColor: "#e9ecef" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </div>
)
export const renderFieldHidden = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    maxLength: number;
    bindvalue: string;
    meta: Meta;


}) => (
    <div className=""  >
        <input  {...input} type="hidden" maxLength={maxLength} />

    </div>
)

export const renderHidden = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    maxLength: number;
    bindvalue: string;
    meta: Meta;


}) => (
    <div className=""  >
        <input  {...input} type="hidden" value={bindvalue} maxLength={maxLength} />

    </div>
)

export const renderDatePicker = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;

}) => (
    <div className="form-group form-focus ">
        <input  {...input} type={type} className={touched && error ? 'form-control datetimepicker is-invalid' : 'datetimepicker form-control floating'} />
        <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </div>
)

export const renderDob = ({
    input,
    type,
    label,
    bindvalue,
    class1,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    class1: string;

}) => (
    <React.Fragment>
        {
            label == undefined ? <TextBoxLoader /> :
                <div className="form-group form-focus">
                    <input  {...input} type={type} className={touched && error ? 'form-control dob is-invalid ' + class1 : ' dob form-control  floating ' + class1} style={{ backgroundPosition: "right 1.0% bottom 9%" }} />
                    <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
                    {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
                </div>
        }
    </React.Fragment>
)
export const renderDoctorDob = ({
    input,
    type,
    label,
    bindvalue,
    class1,
    meta: { touched, error, warning }
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    class1: string;

}) => (
    <React.Fragment>
        {
            label == undefined ? <TextBoxLoader /> :
                <div className="form-group form-focus">
                    <input  {...input} type={type} className={touched && error ? 'form-control searchdob is-invalid ' + class1 : ' searchdob form-control  floating ' + class1} />
                    <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
                    {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
                </div>
        }
    </React.Fragment>
)
export const renderInsurenceDob = ({
    input,
    type,
    label,
    bindvalue,
    class1,
    loading,
    meta: { touched, error, warning }
}: {
    input: any;
    loading: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    class1: string;

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={loading ? "form-group form-focus focused" : input.value == "" ? "form-group form-focus" : "form-group form-focus focused"} >
            <input  {...input} type={type} className={touched && error ? 'form-control insurencedob is-invalid ' + class1 : ' insurencedob form-control  floating ' + class1} />
            <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>

)

export const renderExpDob = ({
    input,
    type,
    label,
    bindvalue,
    class1,
    loading,
    meta: { touched, error, warning }
}: {
    input: any;
    loading: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    class1: string;

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={loading ? "form-group form-focus focused" : input.value == "" ? "form-group form-focus" : "form-group form-focus focused"} >
            <input  {...input} type={type} className={touched && error ? 'form-control insuranceexp  is-invalid ' + class1 : ' insuranceexp  form-control  floating ' + class1} />
            <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>

)

//export const renderExpDob = ({
//    input,
//    type,
//    label,
//    bindvalue,
//    class1,
//    loading,
//    meta: { touched, error, warning }
//}: {
//        input: any;
//        loading: any;
//    type: string;
//    label: string;
//    bindvalue: string;
//    meta: Meta;
//    class1: string;

//    }) => (
//    <React.Fragment>
//        <div  className={loading ? "form-group form-focus focused" : input.value == "" ? "form-group form-focus" : "form-group form-focus focused"}>
//            <input  {...input} type={type} className={touched && error ? 'form-control insurencedob is-invalid ' + class1 : 'insurencedob form-control  floating ' + class1} />
//        <label className="focus-label">{label}</label>
//        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
//        </div>
//        </React.Fragment>
//)

export const renderSelectField = ({
    input,
    type,
    label,
    bindvalue,
    select,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    select: string;
    meta: Meta;
    options: any;
}) => (
    <React.Fragment>
        <select {...input} className={touched && error ? 'form-control is-invalid ' : 'form-control floating'} style={{ backgroundPosition: "Right calc(0.975em + 0.1875rem) center" }} id="dSeleted">
            <option value={""}>{select}</option>)
            {
                options.map((option: any) => <option value={option.value}>{option.name}</option>)

            }
        </select>
        <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)

export const renderSelectFieldEncounter = ({
    input,
    type,
    label,
    bindvalue,
    select,
    meta: { touched, error, warning },
    options,
    disabled,
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    select: string;
    meta: Meta;
        options: any;
        disabled: any;
}) => (
    <React.Fragment>
        {options.length != 0 ?
            
            <select {...input} className={touched && error ? 'form-control is-invalid ' : 'form-control floating'} style={{ backgroundPosition: "Right calc(0.975em + 0.1875rem) center" }} disabled={disabled} id="dSeleted">
               
                <option value={""}>{select}</option>)
                {
                    
                    options.map((option: any) => <option value={option.encounterId}>{option.name}</option>)
                    
                }
            </select> : ""
        }
        <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)

export const renderInsuranceSelectField = ({
    input,
    type,
    label,
    bindvalue,
    select,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    select: string,
    meta: Meta;
    options: any;
}) => (
    <React.Fragment>
        <select {...input} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} style={{ backgroundPosition: "Right calc(0.975em + 0.1875rem) center" }} id="dInsuranceSeleted">
            <option value={""}>{select}</option>)
            {
                options.map((option: any) => <option value={option.id}>{option.name}</option>)

            }
        </select>
        <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)
export const renderStateSelectField = ({
    input,
    type,
    label,
    select,
    bindvalue,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    select: any;
}) => (
    <React.Fragment>
        <select {...input} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} id="dSeleted">
            <option value={""}>{select}</option>)
            {
                options.map((option: any) => <option value={option.State}>{option.State}</option>)

            }
        </select>
        <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)
export const renderSelectOptionField = ({
    input,
    type,
    label,
    bindvalue,
    onChange,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    onChange: any,
    options: any;
}) => (
    <React.Fragment>
        <select {...input} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} id="dSeleted" style={{ backgroundPosition: "Right calc(0.975em + 0.1875rem) center" }}>
            <option value={""}>Select</option>)
            {
                options.map((option: any) => <option value={option.value}>{option.name}</option>)

            }
        </select>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)

export const renderTextSelectOptionField = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
}) => (
    <React.Fragment>
        <select {...input} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} id="dSeleted">
            <option value={""}>Select</option>)
            {
                options.map((option: any) => <option value={option.value}>{option.name}</option>)

            }
        </select>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)
export const renderRequiredSelect = ({
    input,
    type,
    label,
    bindvalue,
    select,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    select: string,
    meta: Meta;
    options: any;
}) => (
    <React.Fragment>
        <select {...input} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} id="dSeleted" style={{ backgroundPosition: "Right calc(0.775em + 0.1875rem) center" }}>
            <option value="">{select}</option>
            {
                options.map((option: any) => <option value={option.value}>{option.name}</option>)

            }
        </select>
        <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)
export const renderIdSelectField = ({
    input,
    type,
    label,
    bindvalue,
    onChange,
    meta: { touched, error, warning },
    options
}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    onChange: any;
}) => (
    <React.Fragment>
        <select {...input} onClick={onChange} className={touched && error ? 'form-control is-invalid' : 'form-control floating'} id="dSeleted">
            {
                options.map((option: any) => <option value={option.id}>{option.name}</option>)

            }
        </select>
        <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)

var langdata: any = "";
if (localStorage.getItem("LangResourcesValidations") != null)
    langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

var languagecode: any = localStorage.getItem("DefaultLangCode");
export const renderAutoComplete = ({
    input,
    type,
    label,
    meta: { touched, error, warning },
    Locations,
    isLoading,
    onSearch,
    onChangeMethod,
    value,
    autoCompleteData,
    ...props
}: {
    input: string;
    type: string;
    label: string;
    meta: Meta;
    Locations: any;
    isLoading: boolean,
    Location: any,
    onSearch: any,
    onChangeMethod: any,
    value: any,
    autoCompleteData: any,
}) => (
    <React.Fragment>
        {
            label == undefined ? <TextBoxLoader /> :
                <div className="form-group form-focus">
                    <AsyncTypeahead {...input}
                        id="basic-example"
                        inputProps={{
                            className: 'form-control floating',
                            required: true
                        }}
                        highlightClassName="rbt-highlight-text"
                        labelKey={(option: any) => `${option.Name}` + `, ` + `${option.Description != null ? `\n` : ""}` + `${option.Description != null ? option.Description : ""}`}
                        options={props.Location}
                        filterBy={() => true}
                        useCache={false}
                        isLoading={isLoading}
                        onSearch={onSearch}
                        paginate={true}
                        emptyLabel={languagecode == "en-us" ? "No matches found." : "No se encontraron coincidencias."}
                        searchText={languagecode == "en-us" ? "Type to search..." : "Escribe para buscar..."}
                        promptText={languagecode == "en-us" ? "Searching..." : "Buscando..."}
                        defaultInputValue={autoCompleteData}
                        onChange={onChangeMethod}
                        selectHintOnEnter
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
                            <div className="form-group form-focus focused">
                                <input {...inputProps} name="Location"
                                    className={touched && error ? 'form-control is-invalid' : 'form-control floating'}
                                    ref={(inputNode) => {
                                        inputRef(inputNode);
                                        referenceElementRef(inputNode);
                                    }}
                                />
                                <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
                                {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
                            </div>
                        )}
                    />
                </div>
        }
    </React.Fragment>
)

export const renderTimeZoneAutoComplete = ({
    input,
    type,
    label,
    meta: { touched, error, warning },
    TimeZones,
    isTimeZoneLoading,
    onTimeZoneSearch,
    onChangeTimeZoneMethod,
    value,
    autoCompleteData,
    ...props
}: {
    input: any;
    type: string;
    label: string;
    meta: Meta;
    TimeZones: any;
    isTimeZoneLoading: boolean,
    TimeZone: any,
    onTimeZoneSearch: any,
    onChangeTimeZoneMethod: any,
    value: any,
    autoCompleteData: any,
}) => (
    <React.Fragment>
        {
            label == undefined ? <TextBoxLoader /> :
                <div className="form-group form-focus">
                    <AsyncTypeahead {...input}
                        id="basic-example"
                        inputProps={{
                            className: 'form-control floating',
                            required: true
                        }}
                        highlightClassName="rbt-highlight-text"
                        labelKey={(option: any) => `${option.Name}`}
                        options={props.TimeZone}
                        isLoading={isTimeZoneLoading}
                        onSearch={onTimeZoneSearch}
                        paginate={true}                                                
                        emptyLabel={languagecode == "en-us" ? "No matches found." : "No se encontraron coincidencias."}
                        searchText={languagecode == "en-us" ? "Type to search..." : "Escribe para buscar..."}
                        promptText={languagecode == "en-us" ? "Searching..." : "Buscando..."}
                        defaultInputValue={autoCompleteData}
                        onChange={onChangeTimeZoneMethod}
                        selectHintOnEnter
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
                            <div className="form-group form-focus focused">
                                <input {...inputProps} name="timeZone"
                                    className={touched && error ? 'form-control is-invalid' : 'form-control floating'}
                                    ref={(inputNode) => {
                                        inputRef(inputNode);
                                        referenceElementRef(inputNode);
                                    }}
                                />
                                <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
                                {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
                            </div>
                        )}
                    />
                </div>
        }
    </React.Fragment>
)

export const renderReasonAutoComplete = ({
    input,
    type,
    label,
    meta: { touched, error, warning },
    reasons,
    isLoading,
    onSearch,
    onChangeMethod,
    localReason,
    disabled,
    required,
    ...props
}: {
    input: any;
    type: string;
    label: string;
    meta: Meta;
    reasons: any;
    isLoading: boolean,
    Reason: any,
    onSearch: any,
    onChangeMethod: any,
        localReason: any,
        disabled: any,
        required: any,
}) => (

    <React.Fragment>
        {
            label == undefined ? <TextBoxLoader /> :
                <div className={input.value == "" ? "form-group form-focus" : "form-group form-focus focused"}>
                    <AsyncTypeahead {...input}
                        id="basic-example"
                        inputProps={{
                            className: 'form-control floating',
                        }}
                        highlightClassName="rbt-highlight-text"
                        labelKey={(option: any) => `${option.Name}`}
                        options={props.Reason}
                        isLoading={isLoading}
                        minLength={1}
                        onSearch={onSearch}
                        paginate={true}
                        emptyLabel={languagecode == "en-us" ? "No matches found." : "No se encontraron coincidencias."}
                        searchText={languagecode == "en-us" ? "Type to search..." : "Escribe para buscar..."}
                        promptText={languagecode == "en-us" ? "Searching..." : "Buscando..."}
                        defaultInputValue={localReason}
                        onChange={onChangeMethod}
                        disabled={disabled}
                        required={required}
                     
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
                            <div className="form-group">
                                <input {...inputProps} maxLength={50} name="Reason"
                                    className={touched && error && inputProps.value == "" ? 'form-control is-invalid' : 'form-control floating'}
                                    ref={(inputNode) => {
                                        inputRef(inputNode);
                                        referenceElementRef(inputNode);
                                    }}
                                    style={{ backgroundColor: "transparent" }}/>
                                <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
                                {touched && inputProps.value=="" && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
                            </div>
                        )}
                    />
                </div>
        }
    </React.Fragment>

)
export const renderInsuranceautoComplete = ({
    maxLength,
    input,
    type,
    label,
    meta: { touched, error, warning },
    Locations,
    isLoading,
    onSearch,
    onChangeMethod,
    value,
    autoCompleteData,
    ...props
}: {
    maxLength: number,
    input: string;
    type: string;
    label: string;
    meta: Meta;
    Locations: any;
    isLoading: boolean,
    Location: any,
    onSearch: any,
    onChangeMethod: any,
    value: any,
    autoCompleteData: any,
}) => (
    <React.Fragment>
        {
            label == undefined ? <TextBoxLoader /> :
                <div className="form-group form-focus">
                    <AsyncTypeahead {...input}
                        id="basic-example"
                        inputProps={{
                            className: 'form-control floating',
                            required: true
                        }}
                        highlightClassName="rbt-highlight-text"
                        labelKey={(option: any) => `${option.Name}`}
                        options={props.Location}
                        isLoading={isLoading}
                        onSearch={onSearch}
                        paginate={true}
                       
                        emptyLabel={languagecode == "en-us" ? "No matches found." : "No se encontraron coincidencias."}
                        searchText={languagecode == "en-us" ? "Type to search..." : "Escribe para buscar..."}
                        promptText={languagecode == "en-us" ? "Searching..." : "Buscando..."}
                        defaultInputValue={autoCompleteData}
                        onChange={onChangeMethod}
                        selectHintOnEnter
                        renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
                            <div className="form-group form-focus focused">
                                <input {...inputProps} name="Insurance Name" id="dInsurenceName"
                                    className={touched && error ? 'form-control is-invalid' : 'form-control floating'}
                                    ref={(inputNode) => {
                                        inputRef(inputNode);
                                        referenceElementRef(inputNode);
                                    }}
                                    maxLength={maxLength}
                                />
                                <label className="focus-label" style={{ background:"transparent" }}>{label}</label>
                                {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
                            </div>
                        )}
                    />
                </div>
        }
    </React.Fragment>
)
//export const renderInsuranceautoComplete = ({
//    input,
//    type,
//    label,
//    meta: { touched, error, warning },
//    reasons,
//    isLoading,
//    onSearch,
//    onChangeMethod,
//    ...props
//}: {
//    input: string;
//    type: string;
//    label: string;
//    meta: Meta;
//    reasons: any;
//    isLoading: boolean,
//    Reason: any,
//    onSearch: any,
//    onChangeMethod: any
//}) => (
//    <div className="form-group form-focus">
//        <AsyncTypeahead {...input}
//            id="basic-example"
//            inputProps={{
//                className: 'form-control floating',
//            }}
//            highlightClassName="rbt-highlight-text"
//            labelKey={(option: any) => `${option.Name}`}
//            options={props.Reason}
//            isLoading={isLoading}
//            minLength={1}
//            onSearch={onSearch}
//            paginate={true}
//            onChange={onChangeMethod}
//            renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
//                <div className="form-group">
//                    <input {...inputProps} maxLength={50} name="Insurance Name"
//                        className={touched && error ? 'form-control is-invalid' : 'form-control floating'}
//                        ref={(inputNode) => {
//                            inputRef(inputNode);
//                            referenceElementRef(inputNode);
//                        }}
//                    />
//                    <label className="focus-label">Insurance Name</label>
//                    {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
//                </div>
//            )}
//        />
//        <div style={{ color: "#dc3545", fontSize: "12px" }}>Required</div>
//    </div>
//)
export const renderTextareaField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={"form-group form-textarea form-focus " + addClass} >
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "70px"}} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            <label className="focus-label" style={{ background: "transparent"}}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderDiscountTextareaField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={" form-textarea form-focus " + addClass} >
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength}  data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderFormTextareaField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
       

        <div hidden={label == undefined} className={"form-group form-textarea form-focus " + addClass} >
            <label className="focus-label" style={{ width: "95%" }}>{label} </label>
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "70px" }} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating' + dataClass} />
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderTextareaHideField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={"form-group form-textarea form-focus " + addClass} style={{ display: "none" } }>
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "70px"}} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            <label className="focus-label" style={{ background: "transparent"}}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderSurveyTextareaField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <label className="focus-label" style={{ background: "transparent", marginTop:"10px" }}>{label}</label>
        <div hidden={label == undefined} className={"form-group form-textarea form-focus " + addClass}>
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "70px", }} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
    )

export const renderTextareaQuestionField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
        <div hidden={label == undefined} className={"form-group form-textarea form-focus " + addClass}>
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "70px"}} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderTextareaFooterField = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any;
    placeholder: any

}) => (
    <React.Fragment>

        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={"form-group form-textarea form-focus " + addClass}>
            <textarea  {...input} id={id} type={type} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "100px", backgroundPosition: "top calc(0.975em + 0.1875rem) right calc(0.375em + 0.1875rem)"  }} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            <label className="focus-label" style={{ background: "transparent" }}>{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)
export const renderRadioField = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,
    fielddisabled,

}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
    fielddisabled: any,
}) => (


    <div className="form-group form-focus drop-list drop-list-custom">
        <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
        {options.map((o: any) => <li className="list-group-item d-flex align-items list-group-item_custom">
            <div className="avatar avatar-sm">
                <label className="custom_radio">
                    <div className="cp_visit-width">
                        <input type="radio"  {...input} value={o.value} disabled={fielddisabled} checked={o.value === input.value} />
                        <span className="checkmark"></span> {o.title}
                    </div>
                </label>
            </div>
        </li>)}


        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}

        {/*options.map((o: any) => <div className="radio col-lg-12 "><label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> {o.title}</label></div>)}
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>)) */}

    </div>

);
export const renderPublicRadioField = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,
    fielddisabled,
    dataClass,
}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
    fielddisabled: any,
    dataClass:any
}) => (
    <div className="form-group form-focus drop-list drop-list-custom">
        <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
        {options.map((o: any) => <li className="list-group-item d-flex align-items list-group-item_custom">
            <div className="avatar avatar-sm">
                <label className="custom_radio">
                    <div className="cp_visit-width">
                        <input type="radio"  {...input} value={o.id}  className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass}/>
                        <span className="checkmark"></span> {o.name}
                    </div>
                </label>
            </div>
        </li>)}
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </div>
);

export const renderCheckSingle = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,
    classes

}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
    classes: any
}) => (


    <div className="form-group form-focus drop-list drop-list-custom">
        <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
        {options.map((o: any) => <li className="list-group-item d-flex align-items list-group-item_custom">
            <div className="avatar avatar-sm">
                <label className="custom_radio">
                    <div className="cp_visit-width">
                        <input type="checkbox" id = {o.key} {...input} value={o.value} defaultChecked={checkData(o.value, input.value)} />
                        <span className="checkmark CheckSingle"></span> {o.title}
                    </div>
                </label>
            </div>
        </li>)}


        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}

        {/*options.map((o: any) => <div className="radio col-lg-12 "><label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> {o.title}</label></div>)}
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>)) */}

    </div>

)
export const rendersCheckSingle = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,
    classes

}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
    classes: any
}) => (


    <div className="form-group form-focus drop-list drop-list-custom">
        <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
        {options.map((o: any) => <li className="list-group-item d-flex align-items list-group-item_custom">
            <div className="avatar avatar-sm">
                <label className="custom_radio">
                    <div className="cp_visit-width">
                        <input type="checkbox" id={o.value} {...input} value={o.value} defaultChecked={checkData(o.value, input.value)} />
                        <span className="checkmark CheckSingle"></span> {o.title}
                    </div>
                </label>
            </div>
        </li>)}


        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}

        {/*options.map((o: any) => <div className="radio col-lg-12 "><label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> {o.title}</label></div>)}
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>)) */}

    </div>

)

//export const renderCheckSingle = ({
//    input,
//    type,
//    label,
//    bindvalue,
//    meta: { touched, error, warning },
//    options,
//    name,
//    optionName,
//    id,
//    classes

//}: {
//    input: any;
//    type: string;
//    label: string;
//    bindvalue: string;
//    meta: Meta;
//    options: any;
//    name: any,
//    optionName: any,
//    id: any,
//    classes: any
//}) => (


//    <div className="form-group form-focus drop-list drop-list-custom">
//        <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
//        {options.map((o: any) => <li className="list-group-item d-flex align-items list-group-item_custom">
//            <div className="avatar avatar-sm">
//                <label className="custom_radio">
//                    <div className="cp_visit-width">
//                        <input type="checkbox"  {...input} value={o.value} defaultChecked={checkData(o.value, input.value)} />
//                        <span className="checkmark CheckSingle"></span> {o.title}
//                    </div>
//                </label>
//            </div>
//        </li>)}


//        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}

//        {/*options.map((o: any) => <div className="radio col-lg-12 "><label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> {o.title}</label></div>)}
//            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>)) */}

//    </div>

//)
//export const RenderCheckSingle = ({
//    input,
//    label,
//    meta: { touched, error, warning },
//    options,
//    selectedOptionSet,
//    handleOptionChange
//}: {
//    input: any;
//    type: string;
//    label: string;
//    bindvalue: string;
//    meta: Meta;
//        options: any;
//        selectedOptionSet: any;
//        handleOptionChange: any;
//    selectedOption: any,
//    handleChange: any,
//    name: any,
//    optionName: any,
//    id: any,
//    classes: any
//    //    }) =>
//    }) => (
////    const { selectedOptionSet } = this.state;
////const isDisabled = (optionSet: string) => selectedOptionSet && selectedOptionSet !== optionSet;
//    <div className="form-group form-focus drop-list drop-list-custom">
//        <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
//        {options.map((o: any) => (
//            <li className="list-group-item d-flex align-items list-group-item_custom" key={o.key}>
//                <div className="avatar avatar-sm">
//                    <label className="custom_radio">
//                        <div className="cp_visit-width">
//                            <input
//                                type="checkbox"
//                                {...input}
//                                value={o.value}
//                                checked={input.value.includes(o.value)}
//                                onChange={(e) => handleOptionChange(e, o.key.split(' ')[0])}
//                                disabled={selectedOptionSet && selectedOptionSet !== o.key.split(' ')[0]}
//                            />
//                            <span className="checkmark CheckSingle"></span> {o.title}
//                        </div>
//                    </label>
//                </div>
//            </li>
//        ))}
//        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}>{error}</div>) || (warning && <span>{warning}</span>))}
//    </div>
//);
//export const RenderCheckSingle = ({
//    input,
//    type,
//    label,
//    bindvalue,
//    meta: { touched, error, warning },
//    options,
//    selectedOption,
//    handleChange,
//    name,
//    optionName,
//    id,
//    classes

//}: {
//    input: any;
//    type: string;
//    label: string;
//    bindvalue: string;
//    meta: Meta;
//    options: any;
//        selectedOption: any,
//        handleChange: any,
//    name: any,
//    optionName: any,
//    id: any,
//    classes: any
//    }) =>

////}) => {
////    const [selectedOption, setSelectedOption] = useState(null);

////    useEffect(() => {
////        // Initialize selectedOption based on input value if needed
////        if (input.value) {
////            setSelectedOption(input.value);
////        }
////    }, [input.value]);

////    const handleChange = (value: any) => {
////        input.onChange(value);
////        setSelectedOption(value);
////    };
//     (
//        <div className="form-group form-focus drop-list drop-list-custom">
//            <label className="col-form-label" style={{ background: "transparent" }}>{label}</label>
//            <ul className="list-group">
//                {options.map((o:any) => (
//                    <li key={o.key} className="list-group-item d-flex align-items list-group-item_custom">
//                        <div className="avatar avatar-sm">
//                            <label className="custom_radio">
//                                <div className="cp_visit-width">
//                                    <input
//                                        type="checkbox"
//                                        {...input}
//                                        value={o.value}
//                                        checked={checkData(o.value, input.value)}
//                                        onChange={() => handleChange(o.value)}
//                                        disabled={selectedOption && selectedOption !== o.value && selectedOption !== "None" && o.value !== "None"}
//                                    />
//                                    <span className="checkmark CheckSingle"></span> {o.title}
//                                </div>
//                            </label>
//                        </div>
//                    </li>
//                ))}
//            </ul>
//            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}>{error}</div>) || (warning && <span>{warning}</span>))}
//        </div>


//    );

export const renderCheckboxFieldNew = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,

}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
}) => (


    <React.Fragment>
        <div>

            {options.map((o: any, index: any) => <label key={o.value}><input type="checkbox"
                {...input}
                name={`${input.name}[${index}]`}
                value={o.value} /> {o.title}</label>)}
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>


    </React.Fragment>
)
export const renderCheckboxField = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,

}: {
    input: string;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
}) => (
    <React.Fragment>

        <div className="form-group row">
            <label className="col-lg-12 col-form-label">{label}</label>
            <div className="col-lg-9">
                <div className="form-check form-check-inline">
                    {
                        options.map((option: any, index: any) =>

                            <label key={optionName + '_' + option}>
                                <input key={optionName + '_' + option}
                                    {...input} type={type}
                                    className={touched && error ? 'form-check-input is-invalid' : 'form-check-input floating'}
                                    name={`${option}[${index}]`}
                                    id={optionName + '_' + option} value={option} />
                                {option}</label>

                        )
                    }
                </div>
            </div>
        </div>

        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>
)


export const renderCheckField = ({
    input,
    type,
    label,
    maxLength,
    checkedTrue,
    bindvalue,
    meta: { touched, error, warning },
    optionName,
    id,
    onChange
}: {
    input: any;
    type: string;
    label: string;
    maxLength: number;
    checkedTrue: string | boolean;
    bindvalue: string;
    meta: Meta;
    optionName: any;
    id: any;
    onChange: any;

}) => (

    <React.Fragment>
        <div className="form-check form-check-inline" {...input}>

            <label >  <input style={{ marginTop: "15px" }} {...input} type={type} onChange={onChange} id={id} className={touched && error ? 'form-check-input is-invalid' : 'form-check-input floating'} />
                <span dangerouslySetInnerHTML={{ __html: optionName }}></span></label>

        </div>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px", marginTop:"-10px", marginLeft:"25px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </React.Fragment>


)


export const rendercheckField = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,
    checked,

}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
    checked: any
}) => (


    <div className="form-group form-focus form-check form-check-inline">

        {options.map((o: any) => <li className="list-group-item align-items list-group-item_custom">


            <div className="cp_visit-width">
                <input type="checkbox"  {...input} name={o.name} checked={checked} />
                <span className="checkmark"></span> {o.title}
            </div>


        </li>)}


        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}

        {/*options.map((o: any) => <div className="radio col-lg-12 "><label key={o.value}><input type="radio" {...input} value={o.value} checked={o.value === input.value} /> {o.title}</label></div>)}
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>)) */}

    </div>


)

export const renderSignUpcheckBox = ({
    input,
    type,
    label,
    bindvalue,
    meta: { touched, error, warning },
    options,
    name,
    optionName,
    id,
    checked,

}: {
    input: any;
    type: string;
    label: string;
    bindvalue: string;
    meta: Meta;
    options: any;
    name: any,
    optionName: any,
    id: any,
    checked: any
}) => (
    <div className="form-group form-focus drop-list drop-list-custom">
        <li className="list-group-item d-flex align-items list-group-item_custom">
            <div className="avatar avatar-sm">
                <label className="custom_radio">
                    <div className="cp_visit-width">
                        <input type="checkbox"  {...input} name={name} />
                        <span className="checkmark CheckSingle"></span> <div dangerouslySetInnerHTML={{ __html: label }} />
                    </div>
                </label>
            </div>
        </li>
        {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px", position: "absolute" }}> {error}</div>) || (warning && <span>{warning}</span>))}
    </div>
)



export const MultiEntryTextArea = ({
    input,
    type,
    label,
    maxLength,
    bindvalue,
    id,
    dataClass,
    addClass,
    dataRule,
    placeholder,
    
    meta: { touched, error, warning }
}: {
    input: any;
    type: string;
    label: string;
    id: any;
    dataRule: any;
    maxLength: number;
    bindvalue: string;
    meta: Meta;
    dataClass: any;
    addClass: any; 
        placeholder: any;
       

}) => (
    <React.Fragment>

        
        <div hidden={label != undefined}><TextBoxLoader /></div>
        <div hidden={label == undefined} className={input.value == "" ? "form-group form-textarea form-focus" : "form-group form-textarea form-focus focused" + addClass}>
            <textarea   {...input} id={id} type={type} disabled={true} placeholder={label == "" ? placeholder : ""} maxLength={maxLength} style={{ height: "70px", marginBottom: "10px"}} data-rule={dataRule} className={touched && error ? 'form-control is-invalid ' + dataClass : 'form-control floating ' + dataClass} />
            <label style={{ background:'transparent' }} className="focus-label">{label}</label>
            {touched && ((error && <div style={{ color: "#dc3545", fontSize: "12px" }}> {error}</div>) || (warning && <span>{warning}</span>))}
        </div>
    </React.Fragment>
)

var array: any = [];
var currentarray: any[];


function checkData(currentvalue: any, updatedvalue: any) {
    if (updatedvalue == "") {
        return;
    } else if (updatedvalue == undefined) {
        return;
    }
    else if (updatedvalue == true) {
        return;
    }
    else if (updatedvalue == false) {
        return;
    } else {
        array = updatedvalue.split('~');
        currentarray = array.filter((value: any) => value == currentvalue);
        if (currentarray.length == 0) {
            return false;
        } else {
            return true;
        }
    }
}



export const NewsLoader = () => (



    <ContentLoader
        title={langdata['loading'] == undefined ? "" : langdata['loading']}
        speed={1}
        width={340}
        height={100}
        viewBox="0 0 340 84"
        backgroundColor="#f6f6ef"
        foregroundColor="#e8e8e3"
    >
        <rect x="9" y="4" rx="0" ry="0" width="320" height="22" />
        <rect x="18" y="14" rx="0" ry="0" width="303" height="11" />
        <rect x="11" y="33" rx="0" ry="0" width="108" height="22" />
        <rect x="129" y="33" rx="0" ry="0" width="60" height="22" />
        <rect x="196" y="33" rx="0" ry="0" width="60" height="22" />
    </ContentLoader>
)








