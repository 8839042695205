import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../index';
import { errorHandling } from '../../helper/ErrorHandling';
import { dateFormat } from "../../../src/helper/formvalidation"
import Moment from 'moment';


export interface ReplyMessageState {
    isReplyLoading: boolean;
    replyMessage: ReplyMessage;
}


export interface EditMessageState {
    isListLoading: boolean,
    messageLists: MessageLists[],
    list: List
}

export interface List {

    Subject: string;
    ToMemberId: string;
    FromMemberId: string;
    ModifiedTime: string;
    ModifiedDate: string;
    ModifiedOn: string;

}

export interface ReplyMessage {
  
    message: string;

}

export interface MessageLists {
    Content: string;
    FromMemberId: string;
    HealthCareProviderName: string;
    Id: string;
    IsRead: boolean;
    ModifiedOn: string;
    MsgType: string;
    Name: string;
    NotificationCount: number;
    OrderNo: number;
    ParentId: string;
    PhotoUrl: string;
    Subject: string;      
    ToMemberId: string;
    TotalRecords: number;
 
}


export interface RequestReplyMessage {
    type: 'REQUEST_REPLY_MESSAGE';
    /*replyMessage: ReplyMessage;*/
}

export interface ReceiveReplyMessage {
    replyMessage: ReplyMessage;
    type: 'RECEIVE_REPLY_MESSAGE';
}




export interface RequestMessagesList {
    type: 'REQUEST_MESSAGE_LIST',
    messageLists: MessageLists[],
    list: List
}

export interface ReceiveMessagesList {
    type: 'RECEIVE_MESSAGE_LIST',
    messageLists: MessageLists[],
    list: List
}




type KnownAction = RequestReplyMessage | ReceiveReplyMessage | RequestMessagesList | ReceiveMessagesList;

export const actionCreators = {

    postReplyMessage: (formvalue: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        var accountid: any = localStorage.getItem("AccountId");
        if (localStorage.getItem("TomemberId"))
        var tomemberid: any = localStorage.getItem("TomemberId");
        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
       
       
        dispatch(showLoading('sectionBar'))
        if (appState && appState.replyMessage) {
            var data = {
                FromMemberId: patient.memberId,
                ToMemberId: tomemberid ? tomemberid : null,
                ParentId: id,
                Content: formvalue.message,
                RecordId: patient.id,
                RecordTypeId: 1,
                AccountId: accountid,
            };

            axios.post('/Message/PostMessage', data)
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_REPLY_MESSAGE' });
                    var locationdata = window.location.pathname;
                    //var _modulename: any = locationdata.split('/')[1];                   
                    //if (_modulename == 'replymessage') {
                    //    dispatch(actionCreators.requestListMessage())
                    //}
                    var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    localStorage.removeItem("TomemberId");
                    dispatch(hideLoading('sectionBar'))
                    toastr.success('', langdata['messagesubmittedsuccesfully'])
                    dispatch(reset("editMessageForm"));
                    /*dispatch(push("/message"));*/
                   // dispatch(actionCreators.requestReplyMessage())
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.error_description) {
                            toastr.error('', errorObject.error_description);
                        } errorHandling(error.response.data.statusCode);

                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                    dispatch({ type: 'RECEIVE_REPLY_MESSAGE', isReplyLoading: false });
                    dispatch({ ...hideLoading('sectionBar') })
                })
                .then(function () {
                });

            dispatch({ type: 'REQUEST_REPLY_MESSAGE', isReplyLoading: true });
        }
    },
    requestReplyMessage: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {

        const appState = getState();
        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
        dispatch(showLoading('sectionBar'));
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');    
        if (appState && appState.editMesssage && id !== null ) {
       axios.get('/message/GetDetailView/' + id + '/' + patient.userId )
           .then(function (response) {
                    const message_list = response.data.Messages;
                    const info_list:any = {
                    }
                    if (message_list.length > 0) {
                    /*    var list = response.data.Messages;*/
                        const list = []
                        for (let i = message_list.length - 1; i >= 0; i--) {
                            const valueAtIndex = message_list[i]
                            list.push(valueAtIndex)
                        }                       
                        const modifiedOn = Moment(list[0].ModifiedOn);
                        info_list.ModifiedTime = modifiedOn.format('hh.mm a').replace(/^0+/, '');
                        info_list.ModifiedDate = dateFormat(list[0].ModifiedOn)
                        info_list.ToMemberId = list[0].ToMemberId
                        info_list.FromMemberId = list[0].FromMemberId
                        info_list.Subject = list[0].Subject
                        info_list.ModifiedOn = list[0].ModifiedOn
                        dispatch({ type: 'RECEIVE_MESSAGE_LIST', messageLists: message_list, list: info_list });
                    }                   
             
               dispatch(hideLoading('sectionBar'));
                })
                .catch(error => {                  
                    if (error.response && error.response.data) {
                        if (error.response.data.statusCode == 400) {
                            var errorObject = JSON.parse(error.response.data.errorMessage);
                            toastr.error('', errorObject.error_description);
                        } else {
                            errorHandling(error.response.data.statusCode);
                        }
                    } else {
                        console.log(error)
                    }
                })
                .then(function () {
                });
        }
        dispatch({ type: 'REQUEST_MESSAGE_LIST' });
    },
 
};

const UnloadedState: ReplyMessageState = {
    replyMessage: {
       
        message: "",
    },
    isReplyLoading: false,

};

const UnloadedListState: EditMessageState = {
    messageLists: [],
    isListLoading: false,
    list: {
        Subject: '',
        ToMemberId: '',
        FromMemberId: '',
        ModifiedTime: '',
        ModifiedDate: '',
        ModifiedOn:''
    }
};

export const replyMessagereducer: Reducer<ReplyMessageState> = (state: ReplyMessageState | undefined, incomingAction: Action): ReplyMessageState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_REPLY_MESSAGE':
            return {
                replyMessage: state.replyMessage,
                isReplyLoading: true,
            };
        case 'RECEIVE_REPLY_MESSAGE':
            return {
                replyMessage: action.replyMessage,
                isReplyLoading: false,
            };

        default:
            return state;
    }
};

export const listreducer: Reducer<EditMessageState> = (state: EditMessageState | undefined, incomingAction: Action): EditMessageState => {
    if (state === undefined) {
        return UnloadedListState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MESSAGE_LIST':
            return {
                messageLists: state.messageLists,
                isListLoading: true,
                list: state.list,
            };
        case 'RECEIVE_MESSAGE_LIST':
            return {
                messageLists: action.messageLists,
                list: action.list,
                isListLoading: false,

            };
        default:
            return state;
    }
};


