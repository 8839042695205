import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';


export interface SuccessState {
    isLoading: boolean;
    success: Success;
}

export interface Success {    
    name: string;
    appointmentDate: string;
    scheduleDate: string;
    time: string
    referenceNo: string,
    statusId: any;
    rescheduleRefId: any;
   secondaryAppointmentOn: string;
}


interface RequestSuccessAction {
    type: 'REQUEST_APPOINTMENT_SUCCESS';
}

interface ReceiveSuccessAction {
    type: 'RECEIVE_APPOINTMENT_SUCCESS';
    success: Success;
}

type KnownAction = RequestSuccessAction | ReceiveSuccessAction;

export const actionCreators = {
    requestSuccess: (route:any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.success) {
            var accountid: any = localStorage.getItem("AccountId");
            axios.get('/appointment/getsuccess/' + route.appointmentid + "/" + accountid)
                .then(function (response) {
                    const data = response.data;
                    localStorage.removeItem("PaymentRetry");
                    localStorage.removeItem('Appointment');
                    dispatch({ type: 'RECEIVE_APPOINTMENT_SUCCESS', success: data });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_APPOINTMENT_SUCCESS' });
        }
    }
};


const UnloadedState: SuccessState = {
    success: { name: "", appointmentDate: "", scheduleDate: "", time: "", referenceNo: "", statusId: "", rescheduleRefId: "", secondaryAppointmentOn:"" }, isLoading: false
};


export const reducer: Reducer<SuccessState> = (state: SuccessState | undefined, incomingAction: Action): SuccessState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_SUCCESS':
            return {
                success: state.success,
                isLoading: true
            };
        case 'RECEIVE_APPOINTMENT_SUCCESS':
            return {
                success: action.success,
                isLoading: false
            };
        default:
            return state;
    }
};
