import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset, initialize } from 'redux-form';
import { AppThunkAction } from '../';
import Form from '../../components/Insurence/Form';
import { actionCreators as _DashboardList } from '../../store/patient/Dashboard';
export interface CancelAppointmentState {
    cancelAppointment: Appointment;
    isCancelLoading: boolean;
}

export interface Appointment {
    appointmentId: string;
    appointmentDate: string;
    appointmentOn: string;
    appointmentUTCDate: string;
}


interface RequestCancelAction {
    type: 'REQUEST_APPOINTMENT_CANCEL';
    cancelAppointment: Appointment;
    isCancelLoading: boolean;
}

interface ReceiveCancelAction {
    type: 'RECEIVE_APPOINTMENT_CANCEL';
    cancelAppointment: Appointment;
    isCancelLoading: boolean;
}

type KnownAction = RequestCancelAction | ReceiveCancelAction;

export const actionCreators = {
    setCancelAppointment: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        setTimeout(function () {
            dispatch({ type: 'RECEIVE_APPOINTMENT_CANCEL', cancelAppointment: data, isCancelLoading: false });
        }, 0);
    },
    clearForm: (): AppThunkAction<KnownAction> => (dispatch:any, getState:any) => {
        setTimeout(function () {  
            dispatch(initialize("cancelappointmentform", ""));            
        }, 0);
    },
    requestScheduleCancel: (result:any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var langdata: any;
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        if (appState && appState.cancelAppointment) {
            var data: any = {
                Id: appState.cancelAppointment.cancelAppointment.appointmentId,
                StatusId: "6",
                Reason: result.reason
            }
            axios.post('/appointment/ScheduleUpdate', data)
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_APPOINTMENT_CANCEL', cancelAppointment: appState.cancelAppointment, isCancelLoading: false });
                    let element: HTMLElement = document.getElementsByClassName('close')[0] as HTMLElement;
                    element.click();
                    dispatch(reset("cancelappointmentform"));
                    toastr.success('', langdata['appointmentcancelledsuccesfully']);

                    const _array: any = window.location.pathname.split('/');
                    if (_array[1] == "dashboard") {
                        dispatch(_DashboardList.requestAppointmentList());
                    }
                })
                .catch(function (error) {
                    dispatch({ type: 'RECEIVE_APPOINTMENT_CANCEL', cancelAppointment: appState.cancelAppointment, isCancelLoading: false });
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    toastr.error('', errorObject.error_description);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_APPOINTMENT_CANCEL', isCancelLoading: true });
        }
    },
};


const UnloadedState: CancelAppointmentState = {
    cancelAppointment: {
        appointmentId: "",
        appointmentDate: "",
        appointmentOn: "",
        appointmentUTCDate:""
    },
    isCancelLoading: false
};


export const reducer: Reducer<CancelAppointmentState> = (state: CancelAppointmentState | undefined, incomingAction: Action): CancelAppointmentState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_CANCEL':
            return {
                cancelAppointment: state.cancelAppointment,
                isCancelLoading: true
            };
        case 'RECEIVE_APPOINTMENT_CANCEL':
            return {
                cancelAppointment: action.cancelAppointment,
                isCancelLoading: false
            };
        default:
            return state;
    }
};
