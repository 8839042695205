import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

const dashboardnorecordsfound = () => (
	<React.Fragment>
	<div className="content">
		<div className="container-fluid">
			<div className="row">
				<div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
					<div className="profile-sidebar">
						<div className="widget-profile pro-widget-content">
							<div className="profile-info-widget">
								<a href="#" className="booking-doc-img">
									<img src="client/logo/pro.jpg" alt="User Image" />
								</a>
								<div className="profile-det-info">
									<h3>Richard Wilson</h3>
									<div className="patient-details">
										<h5><i className="fas fa-birthday-cake"></i> 24 Jul 1983, 38 years</h5>
										<h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Newyork, USA</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="dashboard-widget">
							<nav className="dashboard-menu">
								<ul>
									<li className="active">
										<NavLink tag={Link} className="nav-link header-login btn-white2 log_btn" to="/patientdashboard"><i className="fas fa-columns"></i>
											<span>Dashboard</span></NavLink>
									</li>
									<li>
										<NavLink tag={Link} className="nav-link header-login btn-white2 log_btn" to="/patientappointments"><i className="fas fa-user-cog"></i>
											<span>Patient Appointments</span></NavLink>
									</li>

									<li>
										<NavLink tag={Link} className="nav-link header-login btn-white2 log_btn" to="/message"><i className="fas fa-comments"></i>
											<span>Message</span><small className="unread-msg">23</small></NavLink>

									</li>
									<li>
										<NavLink tag={Link} className="nav-link header-login btn-white2 log_btn" to="/profilesettings"><i className="fas fa-user-cog"></i>
											<span>Profile Settings</span></NavLink>
									</li>
									<li>
										<NavLink tag={Link} className="nav-link header-login btn-white2 log_btn" to="/changepassword"><i className="fas fa-lock"></i>
											<span>Change Password</span></NavLink>
									</li>
									<li>
										<NavLink tag={Link} className="nav-link header-login btn-white2 log_btn" to="/login"><i className="fas fa-sign-out-alt"></i>
											<span>Logout</span></NavLink>

									</li>
								</ul>
							</nav>
						</div>

					</div>

				</div>

				<div className="col-md-7 col-lg-8 col-xl-9">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-12 col-lg-12">
									
									<div className="text-center p-150">
										<img src="client/logo/norecordfound.png" className="img-fluid no-records" alt="User Image" />
										<p>No records found</p></div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	</React.Fragment>
);

export default connect()(dashboardnorecordsfound);