import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const calendar = () => (
	<React.Fragment>
    <div className="content">
        <div className="container">
			<div className="row">
				<div className="col-12 mb-4">
					<div className="terms-content">
						<div className="terms-text">
							<div style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}><img width="170" height="110" src="../../client/default/img/integral.png" /></div>
							<br />
							<h5 className="text-center">COPAYMENT – SELF PAY POLICY</h5>
							<p>Effective 05/18/2022</p>
							<p>All clients whose insurance requires a co-payment, are covering a deductible or that that are paying out of pocket for services must start making payments at least 24hours before their scheduled appointment.</p>
							<p>Our office has an 8:00PM cut-off time for processing payments.</p>
							<ul>
								<li>If a payment is not received by 8:00PM the day before, your appointment will be cancelled.</li>
								<li>If we receive payment after 8:00PM on the day of the appointment</li>
									<ul>
									<li>o	We are unable to guarantee availability to reschedule/establish an appointment after it has been cancelled due to a late or missed payment</li>
									</ul>
								<li>A late payment will be considered if sent/received after 8:00PM or on the day of the appointment (regardless of the time)</li>
								<li>All payments should be made via Zelle, PayPal, or in office via cash.</li>
							</ul>
							<p>Zelle: <a href="mailto:INTEGRALREGISTRY@GMAIL.COM">INTEGRALREGISTRY@GMAIL.COM</a></p>
							<p>PayPal: <a href="mailto:INTEGRALREGISTRY@GMAIL.COM">INTEGRALREGISTRY@GMAIL.COM</a></p>
							<p>If you prefer you can also walk into any of our locations to make a payment in advance.</p>
							<p>90-09 40TH ROAD CORONA, NY 11368 – 929-522-0631 | Mon – Friday 10:00am-8:00pm</p>
							<p>103-20 CORONA AVE CORONA, NY 11368 – 929-522-0136 | Mon – Sat10:00am-5:00pm</p>
							<p>103-24 CORONA AVE CORONA, NY 11368 – 929-522-0995 | Mon – Friday 10:00am-8:00pm& Saturday 10:00am to 5:00pm</p>
						</div>
					</div>
				</div>
			</div>
        </div>
    </div>
    </React.Fragment>


);

export default connect()(calendar);