import * as React from 'react';
import ContentLoader, { Facebook } from 'react-content-loader';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { cursorTo } from 'readline';
import { seo } from '../../helper/Script';
import { ApplicationState } from '../../store';
import OurDoctorsNavMenu from '../../components/layout/menu/OurDoctorsNav';
import * as DoctorListStore from '../../store/doctor/OurDoctors';
import * as AccountStore from '../../store/siteaccount/Account';
import * as  HeaderDrProfileStore from '../../store/widget/HeaderDrProfile';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as MenuHideStore from '../../store/menuHide';
import { setTittle, checkFamilyMember, scrollTop} from '../../helper/formvalidation'
import { getDiscountPrice } from '../Patient/Booking';
import * as  BookingStore from '../../store/patient/Booking';
// At runtime, Redux will merge together...
type DoctorListProps =
    DoctorListStore.DoctorListsState & AccountStore.AccountState & HeaderDrProfileStore.HeaderDrProfileState & LanguageResourcesStore.LanguageResouresState & MenuHideStore.MenuHideState & BookingStore.BookingState // ... state we've requested from the Redux store
    & typeof DoctorListStore.actionCreators & typeof AccountStore.actionCreators & typeof HeaderDrProfileStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof MenuHideStore.actionCreators & typeof BookingStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var currency: any = [];
var DoctorSearchFilter: any = [];
var languagecontent: any = "";
var doctorlistlength: any = [];
var langdata: any = "";
class FetchData extends React.PureComponent<DoctorListProps> {
    constructor(props: any) {
        super(props);
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.props.getDefaultAccount();
        this.ensureDataFetched();
        localStorage.removeItem("AppointmentOn");
        localStorage.removeItem("AppointmentTime");
        localStorage.removeItem("AppointmentTime1");
        localStorage.removeItem("AppointmentOn1");
        localStorage.removeItem("AppointmentBookedOn");
        localStorage.removeItem("EncDetail");
        localStorage.removeItem("Appointment");
        localStorage.removeItem("AppointmentUTCTime")
        localStorage.removeItem("AppointmentUTCTime1")
        scrollTop();
        checkFamilyMember();
    }

    // This method is called when the route parameters change
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderDoctorList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.requestMenuHide(false);
        this.props.getLangResources();
        const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //this.props.requestDoctorLists(startDateIndex);
        this.props.requestSearchDoctorLists(startDateIndex);
        this.props.getEncounterTypes();
        this.props.getDefaultAccount();


        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }

    handleClick(id: any, accountid: any, price: any) {
        this.props.requestHeaderDrProfile(id);
        localStorage.setItem("AccountId", accountid);
        localStorage.setItem("Price", price);
        localStorage.setItem("doctorid", id);
        localStorage.removeItem("patientReferrralId");
        this.props.NavigationUrl(id);
    }
    handleClickBA(id: any, accountid: any, price: any) {
        this.props.requestHeaderDrProfile(id);
        localStorage.setItem("AccountId", accountid);
        localStorage.setItem("Price", price);
        localStorage.removeItem("patientReferrralId");
        localStorage.removeItem("ServiceEncounterTypeId");
        localStorage.removeItem("FinalEncounterName");
        setTimeout(() => {
            this.props.NavigationUrlBA(id);
        }, 500);
        this.props.requestHeaderDrProfile(id)
        
    }
    private renderDoctorList() {
        languagecontent = this.props.LanguageResoures;
        currency = this.props.Settings.filter((s: any) => s.name == "Currency");
        DoctorSearchFilter = this.props.Settings.filter((s: any) => s.name == "Doctor Search Filter")[0]
        if (DoctorSearchFilter != undefined) {
            DoctorSearchFilter = this.props.Settings.filter((s: any) => s.name == "Doctor Search Filter")[0].customizeValue;
        }


        //__currency = this.props.Settings.filter((s: any) => s.name == "Currency");
        var _currency: any = "";
        var value: any;
        if (currency.length == 0) {
            _currency = "";
        }
        else {
            if (currency[0].value == "") {
                _currency = currency[0].displayCode;
                localStorage.setItem("currency", _currency);
            }
            else {
                _currency = currency[0].customizeValue;
                localStorage.setItem("currency", _currency);
            }


        }

        return (

            <div>
                {
                    this.props.Settings.length != 0 ?
                        <div>
                            {
                                DoctorSearchFilter == "Yes" ?
                                    <div className="content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                {/* <div className="col-lg-4 col-xl-3">*/}
                                                <OurDoctorsNavMenu />
                                                {/* </div>*/}

                                                <div className="col-lg-8 col-xl-9">
                                                    {
                                                        this.props.doctorlists.length != 0 ?
                                                            <div>
                                                                {
                                                                    this.props.isLoading == true ?
                                                                        <React.Fragment>
                                                                            <div className="card">
                                                                                <div className="card-body">
                                                                                    {window.innerWidth <= 760 ?
                                                                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                                                                            <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                            <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                                                            <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                                                                            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                            <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                                                            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                                                        </ContentLoader>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="card">
                                                                                <div className="card-body">
                                                                                    {window.innerWidth <= 760 ?
                                                                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                                                                            <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                            <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                                                            <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                                                                            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                            <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                                                            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                                                        </ContentLoader>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                        :
                                                                        this.props.doctorlists.map((doctorlist: DoctorListStore.DoctorList) =>
                                                                            <div className="card cp_card">
                                                                                <div className="card-body cp_card-body">
                                                                                    <div className="doctor-widget">
                                                                                        {/*<div className='row'>*/}
                                                                                        {/*    <div className='col-5'>*/}
                                                                                        <div className="doc-info-left">
                                                                                            <div className="doctor-img">
                                                                                                {
                                                                                                    doctorlist.photo != null ? <img src={"patient/ImageUrl/member/" + doctorlist.doctorId} className="img-fluid" alt="User Image" />
                                                                                                        : <img src="client/default/img/blankimg.jpg" className="img-fluid" alt="User Image" />
                                                                                                }

                                                                                            </div>
                                                                                            <div className="doc-info-cont">
                                                                                                <h4 className="doc-name h4color"><a className="view-pro-btn" onClick={e => this.handleClick(doctorlist.doctorId, doctorlist.accountId, doctorlist.price)}>{doctorlist.doctorName}</a></h4>
                                                                                                {doctorlist.speciality.length != 0 ?
                                                                                                    <p style={{ width: 'auto', maxWidth: 'none' }} className="doc-speciality">{doctorlist.speciality.map((spec: DoctorListStore.Specialities) =>
                                                                                                        spec.name
                                                                                                    ).join(', ')}
                                                                                                    </p> : ''
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                        {/*</div>*/}
                                                                                        {/* <div className='col-7'>*/}
                                                                                        <div style={{ maxWidth: '240px' }} className="doc-info-right">
                                                                                            <div className="clini-infos">
                                                                                                {
                                                                                                    doctorlist.minPrice == 0 ? <span className="h3" style={{ color: "#15558d" }}>{languagecontent['free']}</span> :

                                                                                                        doctorlist.doctorServiceDetails.length == 2 ?
                                                                                                            <ul>
                                                                                                                <li>{doctorlist.flag == true ? <span> {languagecontent['feestartat']} <span className="h3" style={{ color: "#15558d" }}>{_currency}{getDiscountPrice(doctorlist.minPrice, 0)}</span> </span> : <span>Fee starts at <span style={{ color: "#15558d" }} className="h3">{_currency}{getDiscountPrice(doctorlist.minPrice, 0)}</span></span>}</li>

                                                                                                            </ul> :
                                                                                                            <ul>
                                                                                                                <li>{doctorlist.flag == true ? <span className="h3" style={{ color: "#15558d" }}>{_currency}{getDiscountPrice(doctorlist.minPrice, 0)}</span> : <span style={{ color: "#15558d" }} className="h3">{_currency}{getDiscountPrice(doctorlist.minPrice, 0)}</span>}</li>

                                                                                                            </ul>


                                                                                                }

                                                                                            </div>
                                                                                            <div className="doctor-action">



                                                                                                {
                                                                                                    doctorlist.doctorServiceDetails.map((spec: any) =>
                                                                                                        spec.modeCode == "PHN" ?
                                                                                                            <React.Fragment>
                                                                                                                <a className="btn btn-white call-btn" data-toggle="tooltip" title={languagecontent['phone'] == undefined ? "Phone" : languagecontent['phone']} data-target="#video_call">
                                                                                                                    <i className="fas fa-phone fa-flip-horizontal"></i>

                                                                                                                </a>
                                                                                                            </React.Fragment>
                                                                                                        : spec.modeCode == "INP" ?
                                                                                                                <React.Fragment>
                                                                                                                    <a className="btn btn-white call-btn" data-toggle="tooltip" title={languagecontent['inperson'] == undefined ? "In Person" : languagecontent['inperson']} data-target="#video_call">
                                                                                                                        <i className="fas fa-user"></i>
                                                                                                                    </a>
                                                                                                                </React.Fragment> : spec.modeCode == "TEC" ?
                                                                                                                    <React.Fragment>
                                                                                                                        <a className="btn btn-white call-btn" data-toggle="tooltip" title={languagecontent['teleconsulation'] == undefined ? "Teleconsultation" : languagecontent['teleconsulation']} data-target="#video_call">
                                                                                                                            <i className="fas fa-video"></i>
                                                                                                                        </a>
                                                                                                                    </React.Fragment>:""


                                                                                                    )
                                                                                                }
                                                                                            </div>


                                                                                            <div className="clinic-booking">
                                                                                                <a className="apt-btn" style={{ cursor: "pointer" }} onClick={e => this.handleClickBA(doctorlist.doctorId, doctorlist.accountId, doctorlist.price)}>{languagecontent['booknow']}</a>
                                                                                            </div>
                                                                                            {/* </div>*/}

                                                                                            {/*</div>*/}
                                                                                        </div>


                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                }
                                                            </div>
                                                            :
                                                            //<div className="col-md-12 col-lg-12">
                                                            //    <div className="text-center">
                                                            //{/*        <img src="client/default/img/nofound.png" className="img-fluid no-records" alt="User Image" />*/}
                                                            //        <div className="text-center p-4">{languagecontent['norecordsfound'] == undefined ? "No records found" : languagecontent['norecordsfound']}</div>
                                                            //   </div>
                                                            //</div>
                                                            <div className="col-md-12 col-lg-12">
                                                                {this.props.doctorlists.length != 0 ?
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            {window.innerWidth <= 760 ?
                                                                                <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                                                                    <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                    <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                                                    <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                                                </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                                                                    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                    <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                                                    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                                                </ContentLoader>
                                                                            }
                                                                        </div>
                                                                    </div> :

                                                                    <div className="text-center">


                                                                        {this.props.doctorlists.length == 0 && this.props.isLoading == false ? <div className="text-center p-4">{languagecontent['norecordsfound'] == undefined ? "No records found" : languagecontent['norecordsfound']}</div> : ''}
                                                                    </div>
                                                                }
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div>
                                        {this.props.doctorlists.length != 0 ?
                                            <div className="content">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12 col-xl-12">
                                                            {
                                                                this.props.isLoading == true ?
                                                                    <React.Fragment>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                {window.innerWidth <= 760 ?
                                                                                    <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                                                                        <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                        <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                                                        <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                                                    </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                                                                        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                        <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                                                        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                                                    </ContentLoader>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                {window.innerWidth <= 760 ?
                                                                                    <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']}  >
                                                                                        <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                        <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                                                        <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                                                    </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                                                                        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                                                        <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                                                        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                                                    </ContentLoader>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                    :
                                                                    this.props.doctorlists.map((doctorlist: DoctorListStore.DoctorList) =>
                                                                        <div className="card cp_card">
                                                                            <div className="card-body cp_card-body">
                                                                                <div className="doctor-widget">
                                                                                    <div className="doc-info-left">
                                                                                        <div className="doctor-img">
                                                                                            {
                                                                                                doctorlist.photo != null ? <img src={"patient/ImageUrl/member/" + doctorlist.doctorId} className="img-fluid" alt="User Image" />
                                                                                                    : <img src="client/default/img/blankimg.jpg" className="img-fluid" alt="User Image" />
                                                                                            }

                                                                                        </div>
                                                                                        <div className="doc-info-cont">
                                                                                            <h4 className="doc-name h4color"><a className="view-pro-btn" onClick={e => this.handleClick(doctorlist.doctorId, doctorlist.accountId, doctorlist.price)}>{doctorlist.doctorName} {/*{doctorlist.suffix}*/}</a></h4>
                                                                                            {doctorlist.speciality.length != 0 ?
                                                                                                <p className="doc-speciality">{doctorlist.speciality.map((spec: DoctorListStore.Specialities) =>
                                                                                                    spec.name
                                                                                                ).join(', ')}
                                                                                                </p> : ''
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="doc-info-right">
                                                                                        <div className="clini-infos">
                                                                                            {
                                                                                                doctorlist.minPrice == 0 ? <span className="h3" style={{ color: "#15558d" }}>{languagecontent['free']}</span> :

                                                                                                    doctorlist.doctorServiceDetails.length == 2 ?
                                                                                                        <ul>
                                                                                                            <li>{doctorlist.flag == true ? <span> {languagecontent['feestartat']} <span className="h3" style={{ color: "#15558d" }}>{_currency}{doctorlist.minPrice}</span> </span> : <span>Fee starts at <span style={{ color: "#15558d" }} className="h3">{_currency}{doctorlist.minPrice}</span></span>}</li>

                                                                                                        </ul> :
                                                                                                        <ul>
                                                                                                            <li>{doctorlist.flag == true ? <span className="h3" style={{ color: "#15558d" }}>{_currency}{doctorlist.minPrice}</span> : <span style={{ color: "#15558d" }} className="h3">{_currency}{doctorlist.minPrice}</span>}</li>

                                                                                                        </ul>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="doctor-action">
                                                                                            {
                                                                                                doctorlist.doctorServiceDetails.map((spec: any) =>

                                                                                                    spec.modeCode == "TEC" ?
                                                                                                        <React.Fragment>
                                                                                                            <a className="btn btn-white call-btn" data-toggle="tooltip" title={languagecontent['teleconsulation'] == undefined ? "Teleconsultation" : languagecontent['teleconsulation']} data-target="#video_call">
                                                                                                                <i className="fas fa-video"></i>
                                                                                                            </a>
                                                                                                        </React.Fragment> :
                                                                                                        spec.modeCode == "INP" ?
                                                                                                            <React.Fragment>
                                                                                                                <a className="btn btn-white call-btn" data-toggle="tooltip" title={languagecontent['inperson'] == undefined ? "In Person" : languagecontent['inperson']} data-target="#video_call">
                                                                                                                    <i className="fas fa-user"></i>
                                                                                                                </a>
                                                                                                            </React.Fragment> :
                                                                                                            spec.modeCode == "PHN" ?
                                                                                                                <React.Fragment>
                                                                                                                    <a className="btn btn-white call-btn" data-toggle="tooltip" title={languagecontent['phone'] == undefined ? "Phone" : languagecontent['phone']} data-target="#video_call">
                                                                                                                        <i className="fas fa-phone fa-flip-horizontal"></i>

                                                                                                                    </a>
                                                                                                                </React.Fragment>:""
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                        <div className="clinic-booking">
                                                                                            <a className="apt-btn" style={{ cursor: "pointer" }} onClick={e => this.handleClickBA(doctorlist.doctorId, doctorlist.accountId, doctorlist.price)}>{languagecontent['booknow']}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            :
                                            //<div className="col-md-12 col-lg-12">
                                            //                    <div className="text-center">
                                            //                {/*        <img src="client/default/img/nofound.png" className="img-fluid no-records" alt="User Image" />*/}
                                            //                        <div className="text-center p-4">{languagecontent['norecordsfound'] == undefined ? "No records found" : languagecontent['norecordsfound']}</div>
                                            //                   </div>
                                            //                </div>
                                            <div className="col-md-12 col-lg-12">
                                                {this.props.doctorlists.length != 0 ?
                                                    <div className="card">
                                                        <div className="card-body">
                                                            {window.innerWidth <= 760 ?
                                                                <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                                                    <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                                                    <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                                                    <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                                                </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                                                    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                                                    <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                                                    <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                                                </ContentLoader>
                                                            }
                                                        </div>
                                                    </div> :

                                                    <div className="text-center">
                                                        {this.props.doctorlists.length == 0 && this.props.isLoading == false ? <div className="text-center p-4">{languagecontent['norecordsfound'] == undefined ? "No records found" : languagecontent['norecordsfound']}</div> : ''}
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                            }
                        </div>
                        :
                        <React.Fragment>
                            <div className="card">
                                <div className="card-body">
                                    {window.innerWidth <= 760 ?
                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                            <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                            <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                            <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                            <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                        </ContentLoader>
                                    }
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    {window.innerWidth <= 760 ?
                                        <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} >
                                            <rect x="100" y="0" rx="5" ry="5" width="70" height="70" />
                                            <rect x="0" y="80" rx="5" ry="5" width="300" height="13" />
                                            <rect x="0" y="110" rx="5" ry="5" width="300" height="10" />
                                        </ContentLoader> : <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
                                            <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                                            <rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
                                            <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
                                        </ContentLoader>
                                    }

                                </div>
                            </div>
                        </React.Fragment>
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.doctorLists, ...state.account, ...state.headerDrProfile, ...state.langResources, ...state.menuHide, ...state.booking }), // Selects which state properties are merged into the component's props
    ({ ...DoctorListStore.actionCreators, ...AccountStore.actionCreators, ...HeaderDrProfileStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...MenuHideStore.actionCreators, ...BookingStore.actionCreators })// Selects which action creators are merged into the component's props
)(FetchData as any);
