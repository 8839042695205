import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { errorHandling } from '../helper/ErrorHandling';
import { actionCreators as _LangResources } from '../store/LanguageResoures';
export interface ClientDataState {
    clientData: ClientData;
}

export interface ClientData {
    FavIcon: string,
}

interface RequestClientData {
    type: 'REQUEST_CLIENT_DATA';
}

interface ReceiveClientData {
    type: 'RECEIVE_CLIENT_DATA';
    clientData: ClientData;
}

type KnownAction = RequestClientData | ReceiveClientData;

export const actionCreators = {
    requestClientData: (code:any, hostname:any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.clientData) {
            axios.get('site/GetClientData/' + code + "/" + hostname)
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_CLIENT_DATA', clientData: response.data });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_CLIENT_DATA' });
        }
    }
};


const unloadedState: ClientDataState = {
    clientData :{ FavIcon:"" }
};

export const reducer: Reducer<ClientDataState> = (state: ClientDataState | undefined, incomingAction: Action): ClientDataState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_CLIENT_DATA':
            return {
                clientData: state.clientData,
            };
        case 'RECEIVE_CLIENT_DATA':
            if (action.clientData !== state.clientData) {
                return {
                    clientData: action.clientData,
                };
            }
            break;
    }
    return state;
};
