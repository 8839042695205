import * as React from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form'
import { required, password, passwordsMustMatch, setTittle } from '../../helper/formvalidation'
import { renderField } from '../../helper/renderfield'
import { floatJS, mobnav, seo } from '../../helper/Script';
import { resetPassword } from '../../store/account/ResetPassword';
import * as HandleRequest from '../../helper/Request';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { ApplicationState } from '../../store';
import * as MenuHideStore from '../../store/menuHide';
type ResetPasswordProps = HandleRequest.Request & LanguageResourcesStore.LanguageResouresState & MenuHideStore.MenuHideState & typeof LanguageResourcesStore.actionCreators & typeof MenuHideStore.actionCreators & RouteComponentProps<{}>;
var languagecontent: any = "";
class ResetPassword extends React.PureComponent<ResetPasswordProps> {
    constructor(props: any) {
        super(props);
    }
     onSubmit = (formValues: any, dispatch: any, props: any) => {
        const values = { UserId: props.match.params.userid, NewPassword: formValues.NewPassword, ConfirmPassword: formValues.ConfirmPassword }
       props.dispatch(resetPassword(values));
    }

    public render() {
        return (
            <React.Fragment>
                {this.ResetPasswordForm()}
            </React.Fragment>
        );
    }

    public componentDidMount() {
        floatJS();
        mobnav();
        this.ensureDataFetched();
    }
 

    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }

    private ensureDataFetched() {
        this.props.requestMenuHide(false);
        this.props.getLangResources();
    }

    public ResetPasswordForm() {
        languagecontent = this.props.LanguageResoures;
        return (
            <React.Fragment>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center ">


                                    <div className="col-lg-8 login-right">
                                        <div className="login-header">
                                            <h3> </h3>
                                        </div>
                                            <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                                <Field name="NewPassword" component={renderField} label={languagecontent['password']} validate={[required, password]} type="password" />
                                                <Field name="ConfirmPassword" component={renderField} label={languagecontent['confirmpassword']} validate={[required, password, passwordsMustMatch]} type="password" />
                                                <div className="text-left dont-have mb-20">* {languagecontent['passwordrestrictions']}</div>
                                            <div className="row">
                                                    <div className="col-lg-6 col-xs-12">
                                                        <button className="btn btn-primary btn-block btn-lg login-btn" type="submit">{languagecontent['update']}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = { ...LanguageResourcesStore.actionCreators, ...MenuHideStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => ({...state.langResources, ...state.menuHide })
export default reduxForm({
    form: 'resetPasswordForm',
})(connect(mapStateToProps, mapDispatchToProps)(ResetPassword as any))