import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import { mobnav, seo } from '../../helper/Script';
import { dateFormatConversion, timeFormatConversion } from '../../helper/formvalidation';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as NavDefaultStore from '../../store/menu/NavDefault';
import * as AccountStore from '../../store/siteaccount/Account';
import * as SiteDetailStore from '../../store/SiteDetail';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as MessageStore from '../../store/patient/Message';
import * as  EditMesssageStore from '../../store/message/editMessage';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav';
import { setTittle, scrollTop } from '../../helper/formvalidation';
import { NavLink } from 'react-router-dom';


// At runtime, Redux will merge together...

type MessageProps =
    SiteDetailStore.SiteDetailState & EditMesssageStore.EditMessageState & AccountStore.AccountState & NavDefaultStore.NavDefaultState & LanguageResourcesStore.LanguageResouresState & TimeZoneStore.TimeZoneState & MessageStore.MessageState // ... state we've requested from the Redux store
    & typeof AccountStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof EditMesssageStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof MessageStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

var langdata: any = ""
var languagecontent: any = "";
var offset: any = "";
//var Currenttimezone: any = "";
//var message: any = [];
//var message_List: any = [];


class Message extends React.PureComponent<MessageProps> {
    constructor(props: any) {
        super(props);
    }
   
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
       // this.props.requestMessageList();
        scrollTop();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderMessage()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        const patientLocalStroage: any = localStorage.getItem('Patient');

        const patient = JSON.parse(patientLocalStroage);
        if (patient.timeZone != "") {
            this.props.requestTimeZone(patient.timeZone);
        }
        this.props.getLangResources();
        //this.props.getSignalRCredential();
        this.props.requestMessageList();     
    }


    navigatelistPage(id: any) {
        //console.log(id)
        this.props.requestMessageDisplay(id)
    }




    private renderMessage() {
        languagecontent = this.props.LanguageResoures;
        if (this.props.messages != undefined) {
            //message = this.props.messagelists || [];
            if (this.props.messages.length > 0) {
                this.props.messages.sort(Date_sortFunction);
            }
            const patientLocalStroage: any = localStorage.getItem('Patient');
            const patient = JSON.parse(patientLocalStroage);
            offset = patient.offset
           // console.log(this.props.messages);
        }

        function Date_sortFunction(a: any, b: any) {
            var dateA = new Date(a.modifiedOn).getTime();
            var dateB = new Date(b.modifiedOn).getTime();
            return dateB > dateA ? 1 : -1;
        };
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <DashboardNavMenu />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div>
                                {languagecontent['newmessage'] && this.props.messages != undefined?
                                    <NavLink className="btn btn-sm btn-primary" to={'/newmessage'}>
                                        <button disabled={languagecontent['newmessage'] == undefined} className="btn btn-sm btn-primary" type="button">{languagecontent && languagecontent['newmessage']  ? languagecontent['newmessage'] : <img src="../../../buttonloader.svg" />}</button>
                                    </NavLink> : ''}
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-12">
                                <div className="pb-5">
                                    <div id="pat_appointments" className="tab-pane fade show active">
                                        <div className="card card-table mb-0">
                                            <div className="card-body">
                                                    {
                                                        languagecontent['newmessage'] && this.props.messages != undefined && this.props.isListLoading == false && this.props.isMessageLoading == false?
                                                        <div className="table-responsive">
                                                            {
                                                                <div>
                                                                    {
                                                                        this.props.messages.length != 0 ?
                                                                            <table className="table table-hover table-center mb-0">
                                                                                {this.props.messages.map((messagelist: MessageStore.MessageList) =>
                                                                                    <React.Fragment>
                                                                                        <tbody>
                                                                                            <td style={{ width: "100%" }}>
                                                                                                {
                                                                                                    <h2 className="table-avatar">
                                                                                                        <a className="avatar avatar-sm mr-2">
                                                                                                            {messagelist.healthCareProviderPhoto == null || messagelist.healthCareProviderPhoto == "" ? <img className="avatar-img rounded-circle" src="client/default/img/blankimg.jpg" alt="User Image" /> : <img className="avatar-img rounded-circle" src={"patient/ImageUrl/member/" + messagelist.healthCareProviderMemberId} alt="User Image" />}

                                                                                                            {messagelist.notificationCount == "0" ? "" : <span className="message-count"><span className="count" >{messagelist.notificationCount}</span></span>}
                                                                                                        </a>

                                                                                                        <span style={{ cursor: 'pointer', fontSize: '14px', marginLeft: "15px" }} onClick={e => this.navigatelistPage(messagelist.id)}> {messagelist.name == "Not Assigned" ? languagecontent['notassigned'] : messagelist.healthCareProviderName} </span>
                                                                                                    </h2>
                                                                                                }
                                                                                            </td>
                                                                                            <td style={{ width: "200px", textAlign: "left" }}><span className="d-block message-ellipsis" style={{ fontSize: '14px', color: 'var(--primary-color)' }} >{messagelist.subject}</span>
                                                                                                <span className="d-block text-info message-ellipsis">
                                                                                                    <span style={{ fontSize: '12px', color: "#272b41" }}>{messagelist.content}</span></span>
                                                                                            </td>
                                                                                            <td style={{ width: "200px", fontSize: '14px' }}>{dateFormatConversion(messagelist.modifiedOn, offset)} <span className="">{timeFormatConversion(messagelist.modifiedOn, offset) /* messagelist.modified_time */}</span>
                                                                                            </td>
                                                                                            <td style={{ width: "200px" }}>
                                                                                                <span className="d-block" style={{ float: "right" }}>
                                                                                                    {messagelist.msgType == 'I' ? <i className="fa fa-arrow-down"></i> : <i className="fa fa-arrow-up"></i>
                                                                                                    }</span>

                                                                                            </td>
                                                                                        </tbody>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </table> :
                                                                            <div className="col-md-12 col-lg-12">
                                                                                <div className="text-center">
                                                                                    <div className="text-center p-4">{languagecontent['nomessage'] == undefined ? "No message" : languagecontent['nomessage']}</div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div> :

                                                        <React.Fragment>
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader> <br />
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader><br />
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader><br />
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader><br />
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader><br />
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader>
                                                            <br />
                                                            <ContentLoader
                                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                height={54}
                                                                width={320}
                                                                viewBox="0 0 320 54"
                                                                backgroundColor="#f3f3f3"
                                                                foregroundColor="#ecebeb"
                                                            >
                                                                <circle cx="27" cy="27" r="18" />
                                                                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                                                                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                                                                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                                                                <circle cx="305" cy="27" r="8" />
                                                                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                                                                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
                                                            </ContentLoader>
                                                        </React.Fragment>
                                                }
                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.dashboard, ...state.appointmenttypes, ...state.navDefault, ...state.editMesssage, ...state.account, ...state.siteDetail, ...state.langResources, ...state.cancelAppointment, ...state.timeZones, ...state.speciality, ...state.messages }), // Selects which state properties are merged into the component's props
    ({ ...AccountStore.actionCreators, ...SiteDetailStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...EditMesssageStore.actionCreators, ...TimeZoneStore.actionCreators, ...MessageStore.actionCreators }) // Selects which action creators are merged into the component's props
)(Message as any);




