import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';
import { actionCreators as _NavDefault } from '../../store/menu/NavDefault';
import { listConvertParentChild } from '../../helper/formvalidation';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);

export interface DashboardState {
    isLoading: boolean;
    startDateIndex?: number;
    appointmentlists: AppointmentList[];
}

export interface AppointmentList {
    appointmentId: string;
    doctorId: string;
    doctorName: string;
    appointmentDate: string;
    reason: string;
    type: string;
    appointmentStatus: string;
    scheduleDate: string;
    hummyURL: string;
    referredBy: string;
    referredTo: string;
    isRefButtonShow: boolean;
    photo: string;
    patientReferrralId: string;
    referredDoctorId: string;
    accountId: string
    duration: string;
    showReschedule: boolean;
    doctorSpeciality: any;
    appointmentType: string;
    note: string;
    referenceNo: string
    encounterId: string
    payConceptId: any,
    paymentStatusId: any,
    relationship: any,
    memberName: any,
    isShowPayBtn: any;
    parentId: any;
    familyMemberId: any;
    rescheduleRefId: any;
    methodId: any;
    statusId: any;
    specialityId: any;
    children: AppointmentList[];
    patientId: any;
    secondaryAppointmentOn: string;
    rescheduleLimit: any;
    followupduration: string;
    visitType: string;
    visitTypeId: any;
}



// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAppointmentListAction {
    type: 'REQUEST_APPOINTMENT_LIST';
    startDateIndex: number;
    appointmentlists: AppointmentList[]
}

interface ReceiveAppointmentListAction {
    type: 'RECEIVE_APPOINTMENT_LIST';
    startDateIndex: number;
    appointmentlists: AppointmentList[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAppointmentListAction | ReceiveAppointmentListAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAppointmentList: (): AppThunkAction<KnownAction> => async (dispatch: any, getState:()=> any) => {
        const appState = getState();
        dispatch(showLoading('sectionBar'));
        if (appState && appState.dashboard) {
            //var accountid = null;
            var alldata: any = [];
            var accountid: any = localStorage.getItem("AccountId");
            var langcode: any = localStorage.getItem("DefaultLangCode");
            dispatch(hideLoading('sectionBar'));
            await axios.get('/appointment/getappointmentLists/' + patient.id + "/" + accountid + "/" + "-" + "/" + langcode)
            
            .then(function (response) {
                    
                    //dispatch(hideLoading('sectionBar'));
                    
                    const result = response.data;
                    var _result: any = listConvertParentChild(result);
                    
                    _result.forEach((value:any) => {
                        if (value.appointmentId == "00000000-0000-0000-0000-000000000000" && value.statusId == 7) {
                            value.statusId = 8;
                        }
                    })
                    //_result.forEach((value: any) => {
                    //    if (value.children.length > 0) {
                    //        alldata = _result.concat(value.children);
                    //    } 
                    //})

                    _result.forEach((value: any) => {
                        alldata.push(value); // Add the current element to the flattened array
                        alldata.push(...value.children); // Add the children elements to the flattened array
                    });


                    if (alldata.length == 0)
                        alldata = _result;
                    else
                        alldata = alldata;
                    /*setTimeout(() => {*/
                    //console.log(alldata);
                        dispatch({ type: 'RECEIVE_APPOINTMENT_LIST', appointmentlists: alldata });
                    /*}, 100)*/
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 401) {
                        localStorage.setItem("isAuth", "false");
                        console.log("dashboard ts")
                        localStorage.removeItem("Patient");
                        //localStorage.removeItem("isAuth");
                        localStorage.removeItem("pathName");
                        localStorage.removeItem("redirectUrl");
                        localStorage.removeItem("EmailId");
                        localStorage.removeItem("redirectQequestQueueUrl");
                        localStorage.removeItem("Appointment");
                        localStorage.removeItem("ProfileData");
                        window.location.href = "/login";
                    } else {
                        errorHandling(error);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_APPOINTMENT_LIST'});
        }
    },
    redirectURL: (data: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        dispatch(push("/booking/" + data.referredDoctorId + "?referrralid=" + data.patientReferrralId + "&specialityid=" + data.referredSpecialityId + "&childid=" + data.familyMemberId + "&childname=" + data.memberName ));
    },
    requestVisitDetailURL: (id: any,hummyurl: any, patientId: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient: any = JSON.parse(patientLocalStroage);
        dispatch(push("/visitdetail/" + id + "/" + hummyurl + "?memberid=" + patient.memberId + "&patientid=" + patientId ))
    },
    redirectPayNow: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        localStorage.removeItem("DiscountId");
        localStorage.removeItem("DiscountAmount");
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient:any = JSON.parse(patientLocalStroage);
        window.location.href = "/paymentprocess/" + value.doctorId + "/" + value.appointmentId + "/" + patient.FirstName + " " + patient.LastName + "/" + patient.Email;
    },
    redirectDoctorPage: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        dispatch(_NavDefault.goNextPage());
    },
    redirectRequestQue: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        var result: any = JSON.parse(localStorage.getItem("Patient") || "");
        window.location.href = "/memberspeciality?name=consultnow&patientid=" + result.id;
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DashboardState = { appointmentlists: [], isLoading: false };

export const reducer: Reducer<DashboardState> = (state: DashboardState | undefined, incomingAction: Action): DashboardState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_LIST':
            return {
                appointmentlists: state.appointmentlists,
                isLoading: true
            };
        case 'RECEIVE_APPOINTMENT_LIST':
            return {
                appointmentlists: action.appointmentlists,
                isLoading: false
            };
        default:
            return state;
    }
};


