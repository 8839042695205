import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '..';
import { errorHandling } from '../../helper/ErrorHandling';


export interface SurveyFormState {
    isFormLoading: boolean,
    surveyform: SurveyForm,
}

export interface SurveyForm {
    encounterId: string,
    sessionSatisfactionId: string,
    suggestion: string,
    recommendation: string,
}


export interface RequestSurveyFormAction {
    type: 'REQUEST_SURVEYFORM',
    surveyform: SurveyForm,
}
export interface ReceiveSurveyFormAction {
    type: 'RECEIVE_SURVEYFORM',
    surveyform: SurveyForm,
}

export type KnownAction = RequestSurveyFormAction | ReceiveSurveyFormAction;

export const actionCreators = {
    postSurveyForm: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        /*dispatch(showLoading('sectionBar'))*/
        if (appState && appState.surveyform) {
            axios.post('survey', value)
                .then(function (response) {
                    dispatch(reset("surveyForm"));
                    var element1: any = document.getElementById('dform');
                    var element2: any = document.getElementById('dmessage');
                    element2.style.display = 'block';
                    element1.style.display = 'none';
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        if (errorObject.ModelState != undefined) {
                            toastr.error('', errorObject.ModelState[""][0]);
                        }
                    }
                    else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_SURVEYFORM', isFormLoading: false });
        }
    },
    requestSurveyForm: (encounterId: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.surveyform) {
            axios.get('/survey/GetSurvey/' + encounterId)
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_SURVEYFORM', surveyform: result, isFormLoading: false });
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_SURVEYFORM', isFormLoading: true});
        }
    },
};

const unloadedState: SurveyFormState = {
    surveyform: {
        encounterId: "",
        sessionSatisfactionId: "",
        suggestion: "",
        recommendation: "",
    },
    isFormLoading: false,

};

export const reducer: Reducer<SurveyFormState> = (state: SurveyFormState | undefined, incomingAction: Action): SurveyFormState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SURVEYFORM':
            return {
                surveyform: state.surveyform,
                isFormLoading: true
            };

        case 'RECEIVE_SURVEYFORM':
            return {
                surveyform: action.surveyform,
                isFormLoading: false
            };
        default:
            return state;
    }
};
