import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { toastr } from 'react-redux-toastr';
import { reset } from 'redux-form';


export interface Ftr247DCTState {
    isLoading: boolean;
    ftr247dct: Ftr247DCT;
}

export interface Ftr247DCT {
    Name: string;
    Email: string;
    Content: string;
}

interface RequestFtr247DCT {
    type: 'REQUEST_ADD_Ftr247DCT';
    ftr247dct: Ftr247DCT;
}

interface ReceiveFtr247DCT {
    type: 'RECEIVE_ADD_Ftr247DCT';
    ftr247dct: Ftr247DCT;
}

type KnownAction = RequestFtr247DCT | ReceiveFtr247DCT;

export const actionCreators = {
    requestFtr247DCTSave: (value: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState: any = getState();
        var accountid: any = localStorage.getItem("AccountId");
        value.accountId = accountid;
        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        if (appState && appState.ftr247dct && accountid!="") {
            axios.post('account/newsletter', value)
                .then(function (response) {
                    dispatch(reset("newsletter"));
                    var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    toastr.success('', langdata['sendsuccess'])
                    dispatch({ type: 'RECEIVE_ADD_Ftr247DCT', isLoading: false});
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_ADD_Ftr247DCT', isLoading: true })
            
        }
        },
    }

const unloadedState: Ftr247DCTState = {
    ftr247dct: {
        Name: "",
        Email: "",
        Content: ""
    },
        isLoading: false
    };
    export const reducer: Reducer<Ftr247DCTState> = (state: Ftr247DCTState | undefined, incomingAction: Action): Ftr247DCTState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ADD_Ftr247DCT':
            return {
                ftr247dct: state.ftr247dct,
                isLoading: true
            };

        case 'RECEIVE_ADD_Ftr247DCT':
            return {
                ftr247dct: action.ftr247dct,
                isLoading: false
            };
        default:
            return state;
    }
};