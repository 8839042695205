import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ContentLoader from "react-content-loader"
import { ApplicationState } from '../../store/index';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as AccountStore from '../../store/siteaccount/Account';
import * as CategoryStore from '../../store/siteaccount/Category';
import * as PayMethodStore from '../../store/payment/PayMethod';
import * as  HeaderDrProfileStore from '../../store/widget/HeaderDrProfile';
import FlexiFormClass from '../../components/library/flexiform/FlexiForm'
import HeaderDrProfile from '../widget/HeaderDrProfile';
import { seo } from '../../helper/Script'
import { toastr } from 'react-redux-toastr';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { scrollTop, setTittle } from '../../helper/formvalidation';
// At runtime, Redux will merge together...
type Props =
    FlexiFormStore.FlexiListsState & AccountStore.AccountState & CategoryStore.CategoryState & PayMethodStore.PayMethodState & HeaderDrProfileStore.HeaderDrProfileState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
    & typeof FlexiFormStore.actionCreators & typeof AccountStore.actionCreators & typeof CategoryStore.actionCreators & typeof PayMethodStore.actionCreators &  typeof HeaderDrProfileStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var paymethodid: any = "";
var languagecontent: any = "";
var pay_render: any = [];



class PayMethod extends React.PureComponent<Props> {

    constructor(props: any) {
        super(props);

    }

 





    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
        scrollTop();
    }
    public componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderPayMethod()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.getCategory();
    }
    selectPayType = (value: any) => {
        if (value.name == 'Insurance') {
            paymethodid = "1";
        } else if (value.name == 'Self-pay (Online)') {
            paymethodid = "2";
        } else if (value.name == 'Self-pay (Inperson)') {
            paymethodid = "3";
        } else {
            return;
        }
    }
    submitPayMethod = () => {
        if (paymethodid != "") {
            this.props.updatePaymethod(paymethodid);
        } else {
            var langdata: any;
            if (localStorage.getItem("LangResourcesValidations") != null)
                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
            toastr.error('', langdata['selectpaymethod']);
        }
        paymethodid = "";
    }

    redirectback = () => {
        this.props.redirectback();        
    }

    private renderPayMethod() {
        
        languagecontent = this.props.LanguageResoures;
        var encDetailStroage: any = localStorage.getItem('EncDetail');
        var encDetail: any = JSON.parse(encDetailStroage);
        var paymethod: any = [];



       
        if (encDetail != null) {

            if (encDetail.encounterTypeMode == "Teleconsultation" || encDetail.encounterTypeMode == "Videoconsulta" || localStorage.getItem("modecode") == "TEC") {
                var _paymethod = this.props.Settings.filter((se: any) => (se.typeId == 11 && se.customizeValue == "Yes" && (se.name != "Self-pay (Inperson)")))
                var paymethod = _paymethod
            }
            else {
                var _paymethod = this.props.Settings.filter((se: any) => (se.typeId == 11 && se.customizeValue == "Yes"))
                console.log(_paymethod)
                var paymethod = _paymethod
            }
        }
        paymethod.sort((a: { name: string; }, b: { name: string; }) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (nameA < nameB) {
                return -1; // nameA comes before nameB
            }
            if (nameA > nameB) {
                return 1; // nameA comes after nameB
            }
            return 0; // names are equal
        });

       // console.log(paymethod)



        return (
            <div className="content">
                <div className="container">
                    <HeaderDrProfile />
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5>{languagecontent['payforservices']}</h5>
                                    <ul className="list-group list-group-flush benifits-col">

                                        {
                                            paymethod != undefined ?
                                                paymethod.map((_paymethod: any) =>
                                                    <React.Fragment>
                                                        <li className="list-group-item d-flex align-items">
                                                            <div className="avatar avatar-sm">
                                                                <label className="custom_radio">
                                                                    <div className="cp_visit-width">
                                                                        {/*{*/}
                                                                        {/*    this.props.headerdrprofile.doctorServiceDetails != undefined ?*/}
                                                                        {/*        this.props.headerdrprofile.doctorServiceDetails.map((doctorService: HeaderDrProfileStore.DoctorServiceDetail, index) =>*/}
                                                                        {/*            <React.Fragment>*/}
                                                                        {/*                <input type="radio" name="pay" onClick={() => this.selectPayType(_paymethod)} value="Ccon" /><span className="checkmark"></span> {doctorService.modeCode == "TEC" ? {_paymethod.name == "Self-pay (Online)" ? languagecontent['sefpayonline'] : '' } : {_paymethod.name == "Insurance" ? languagecontent['insurance'] : _paymethod.name == "Self-pay (Online)" ? languagecontent['sefpayonline'] : _paymethod.name == "Self-pay (Inperson)" ? languagecontent['selfpayinperson'] : '' }}*/}
                                                                        {/*            </React.Fragment>*/}
                                                                        {/*        )*/}
                                                                        {/*        : ""*/}
                                                                        {/*}*/}
                                                                        <input type="radio" name="pay" onClick={() => this.selectPayType(_paymethod)} value="Ccon" /><span className="checkmark"></span>{_paymethod.name == "Insurance" ? languagecontent['insurance'] : _paymethod.name == "Self-pay (Online)" ? languagecontent['sefpayonline'] : _paymethod.name == "Self-pay (Inperson)" ? languagecontent['selfpayinperson'] : ''}
                                                                    </div >
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                                : ""
                                        }

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="submit-section text-right" hidden={paymethod.length == 0 ? true : false}>
                        <button className="btn btn-secondary submit-btn" onClick={this.redirectback}>{languagecontent['backbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['backbutton']}</button>
                        <button className="btn btn-primary submit-btn" disabled={this.props.isPayLoading} type="button" onClick={() => this.submitPayMethod()}>{this.props.isPayLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['proceedbutton']}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.flexiform, ...state.account, ...state.category, ...state.paymethod, ...state.headerDrProfile, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...FlexiFormStore.actionCreators, ...AccountStore.actionCreators, ...CategoryStore.actionCreators, ...PayMethodStore.actionCreators, ...HeaderDrProfileStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(PayMethod as any);
