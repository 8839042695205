import axios from 'axios';
import moment from 'moment';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling, errorMessage } from '../../helper/ErrorHandling';
import { toastr } from 'react-redux-toastr';
import Moment from 'moment';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
const search = window.location.search;
const params = new URLSearchParams(search);
export interface BookingState {
    isLoading: boolean;
    startDateIndex?: number;
    timing: Root[];
    weekdays: WeekDay[];
    weekfullday: WeekFullDay;
    provider: Provider;
    lengths: number;
    isTimeLoading: boolean;
    time: any
}
export interface Provider {
    id: string;
}
export interface time {
    time: string;
}

export interface RootEntity {
    id: number;
    n: number;
    start: string;
    end: string;
    timeslot: string;
    memberId: string;
    isAvailableSlot: boolean;
    order: number;
    TotalSlots: number;
    UsedSlots: number;
    modifiedBy?: null;
    modifiedOn?: null;
    modified?: null;
}
export type Root = (RootEntity)[] | null;

export interface TimingSlot {
    id: string;
    start: string;
    end: string;
    timeslot: string;
    memberId: string;
    usedTime: string

}
export interface WeekDay {
    date: string;
    dayno: string;
    day: string;
    month: string;
    year: string
}
export interface WeekFullDay {
    fullday: string;
    fullmonth: string;
}
export interface Calendar {
    appointmentDateTime: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestTimingAction {
    type: 'REQUEST_DOCTOR_TIMING';
    isLoading: boolean;
    isTimeLoading: boolean;
}

interface ReceiveTimingAction {
    type: 'RECEIVE_DOCTOR_TIMING';
    timing: Root[];
    weekdays: WeekDay[];
    weekfullday: WeekFullDay;
    provider: Provider;
    lengths: number;
    isLoading: boolean;
    isTimeLoading: boolean;
}
interface ReceiveStoreAction {
    type: 'RECEIVE_STORE_TIMING';

    time: string
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestTimingAction | ReceiveTimingAction | ReceiveStoreAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestTiming: (currentdate: any, doctor: any, type: any, dayNo: number = 0): AppThunkAction<KnownAction> => async (dispatch: any, getState) => {
        const appState = getState();
        if (type == "clear" || type == "centralized") {
            if (localStorage.getItem("preferredtimeslot") == "No" && localStorage.getItem("pickaprovider") == "Yes") {
                localStorage.removeItem("AppointmentTime");
                localStorage.removeItem("AppointmentUTCTime")
            }
        }

        setTimeout(() => {
            const patientLocalStroage: any = localStorage.getItem('Patient');
            const patient = JSON.parse(patientLocalStroage);
            //if (patient != null) {
            //    if (patient.timeZone != "") {
            //        this.props.requestTimeZone(patient.timeZone);
            //    }
            //}
            var currentTime = new Date();

            var currentOffset = currentTime.getTimezoneOffset();

            var SettingOffset = patient && patient.offset ? patient.offset : parseInt(localStorage.getItem('timeOffset') || "")

            var ConvertedTime = new Date(currentTime.getTime() + (SettingOffset + currentOffset) * 60000);

            const today = Moment(ConvertedTime).format('YYYY-MM-DD');


            if (type == "centralized") {
                if (appState && appState.booking) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const name = urlParams.get('status');
                    const typemode = urlParams.get('type');
                    const Code = urlParams.get('code');
                    const specialityid = urlParams.get('specialityid');
                    const childid = urlParams.get('childid');
                    if (patient == null)
                        userid = "-";
                    else
                        userid = patient.userId;
                    var ptid: any = "";
                    if (childid == null || childid == undefined)
                        ptid = null;
                    else
                        ptid = childid;

                    var specialitid: any = localStorage.getItem('SpecialityId');
                    if (specialitid == "" || specialitid == null)
                        specialitid = urlParams.get('specialityid');
                    var accountid = localStorage.getItem("AccountId");
                    var encDetailStroage: any = localStorage.getItem('EncDetail');
                    var encDetail = JSON.parse(encDetailStroage);

                    var enctypemode = "";
                    var durcode = "";
                    if (encDetail != null) {
                        enctypemode = encDetail.encounterTypeMode == undefined ? "-" : encDetail.encounterTypeMode;
                        durcode = encDetail.encounterTypeMode == undefined ? "-" : encDetail.durationCode;
                    } else {
                        enctypemode = "-";
                        durcode = "-"
                    }
                    //var patientLocalStroage: any = localStorage.getItem('Patient');
                    //var patient = JSON.parse(patientLocalStroage);

                    axios.get('/doctor/CentralizedGettiming/' + accountid + '/' + currentdate + '/' + enctypemode + '/' + durcode + '/' + userid + '/' + specialitid + "/" + null + "/" + ptid, { headers: { Authorization: "BasicAuth" } })
                        .then(function (response) {
                            const data = response.data._doctorTimeslot;
                            const days = [];
                            let daysRequired = 0;
                            if (response && response.data && response.data._doctorTimeslot.length == 0) {
                                if (!localStorage.getItem("AppointmentOn") && dayNo < 6) {

                                    dayNo++;
                                    dispatch(actionCreators.requestTiming(moment(currentdate).add(1, 'day').format('YYYY-MM-DD'), "", "centralized", dayNo))

                                }

                                else {
                                    if (dayNo == 6) {
                                        localStorage.setItem("AppointmentOn", moment(currentdate).add(-6, 'day').format('YYYY-MM-DD'))

                                    }
                                    for (let i = daysRequired; i <= 6; i++) {
                                        days.push({ date: moment(ConvertedTime).add(i, 'days').format('YYYY-MM-DD'), dayno: moment(ConvertedTime).add(i, 'days').format('D'), day: moment(ConvertedTime).add(i, 'days').format('ddd'), month: moment(ConvertedTime).add(i, 'days').format('MMM'), year: moment(ConvertedTime).add(i, 'days').format('yyyy') })
                                        daysRequired++;
                                    }
                                    const fullmonthyear = moment(ConvertedTime).format('Do MMMM YYYY');
                                    const fullday = moment(ConvertedTime).format('dddd');
                                    var getTimeSlots = getSlots(data, 7);
                                    dispatch({ type: 'RECEIVE_DOCTOR_TIMING', timing: getTimeSlots, weekdays: days, weekfullday: { fullday: fullday, fullmonth: fullmonthyear }, provider: response.data, isLoading: false });
                                }
                            }
                            else {
                                for (let i = daysRequired; i <= 6; i++) {
                                    days.push({ date: moment(ConvertedTime).add(i, 'days').format('YYYY-MM-DD'), dayno: moment(ConvertedTime).add(i, 'days').format('D'), day: moment(ConvertedTime).add(i, 'days').format('ddd'), month: moment(ConvertedTime).add(i, 'days').format('MMM'), year: moment(ConvertedTime).add(i, 'days').format('yyyy') })
                                    daysRequired++;
                                }
                                const fullmonthyear = moment(ConvertedTime).format('Do MMMM YYYY');
                                const fullday = moment(ConvertedTime).format('dddd');
                                var getTimeSlots = getSlots(data, 7);
                                if (!localStorage.getItem("AppointmentOn"))
                                    localStorage.setItem("AppointmentOn", currentdate);

                                if (!localStorage.getItem("SelectedDate"))
                                    localStorage.setItem("SelectedDate", currentdate);
                                dispatch({ type: 'RECEIVE_DOCTOR_TIMING', timing: getTimeSlots, weekdays: days, weekfullday: { fullday: fullday, fullmonth: fullmonthyear }, provider: response.data, isLoading: false });

                            }
                        })
                        .catch(function (error) {
                            errorHandling(error.response.data.statusCode);
                        })
                        .then(function () {
                            // always executed
                        });
                    dispatch({ type: 'REQUEST_DOCTOR_TIMING', isLoading: true });
                }
            }

            if (type != "centralized") {

                if (appState && appState.booking) {
                    var accountid = localStorage.getItem("AccountId");
                    var encDetailStroage: any = localStorage.getItem('EncDetail');
                    var encDetail = JSON.parse(encDetailStroage);
                    var enctypemode1: any = "";
                    var durcode1: any = "";
                    var userid: any = "";
                    var urlParams = new URLSearchParams(window.location.search);
                    var name = urlParams.get('status');
                    var typemode = urlParams.get('type');
                    var Code = urlParams.get('code');
                    var childid = urlParams.get('childid');
                    var ptid: any = "";
                    if (patient == null)
                        userid = "-";
                    else
                        userid = patient.userId;
                    if (childid == null || childid == undefined)
                        ptid = null;
                    else
                        ptid = childid
                    
                    var specialitid: any = localStorage.getItem('SpecialityId') || urlParams.get('specialityid');
                    if (specialitid == "" || specialitid == null)
                        specialitid = urlParams.get('specialityid');
                    if (name == 'reschedule' || name == 'followup') {
                        enctypemode1 = typemode;
                        durcode1 = Code;
                    }
                    //else if (name == 'followup')
                    //{
                    //    durcode1 = localStorage.getItem("followupduration");
                    //    enctypemode1 = localStorage.getItem("followuptypemode");
                    //}
                    else {
                        if (encDetail != null) {
                            enctypemode1 = encDetail.encounterTypeMode == undefined ? "-" : encDetail.encounterTypeMode;
                            durcode1 = encDetail.encounterTypeMode == undefined ? "-" : encDetail.durationCode;
                        } else {
                            enctypemode1 = "-";
                            durcode1 = "-"
                        }
                    }

                    //var patientLocalStroage: any = localStorage.getItem('Patient');
                    //console.log(currentdate)
                    //var patient = JSON.parse(patientLocalStroage);


                    axios.get('/doctor/Gettiming/' + doctor.doctorid + '/' + accountid + '/' + currentdate + '/' + enctypemode1 + '/' + durcode1 + "/" + userid + "/" + specialitid + "/" + null + "/" + ptid, { headers: { Authorization: "BasicAuth" } })
                        .then(function (response) {
                            if (response.data.length > 0) {
                                var lengths = 1;
                            }
                            else {
                                var lengths = 0;
                            }
                            if (response && response.data && response.data.length == 0) {

                                if (!localStorage.getItem("AppointmentOn") && dayNo < 6) {

                                    dayNo++;
                                    dispatch(actionCreators.requestTiming(moment(currentdate).add(1, 'day').format('YYYY-MM-DD'), doctor, "", dayNo))

                                }
                                else {
                                    if (dayNo == 6) {
                                        localStorage.setItem("AppointmentOn", moment(currentdate).add(-6, 'day').format('YYYY-MM-DD'))

                                    }
                                    const days = [];
                                    let daysRequired = 0
                                    for (let i = daysRequired; i <= 6; i++) {
                                        days.push({ date: moment(ConvertedTime).add(i, 'days').format('YYYY-MM-DD'), dayno: moment(ConvertedTime).add(i, 'days').format('D'), day: moment(ConvertedTime).add(i, 'days').format('ddd'), month: moment(ConvertedTime).add(i, 'days').format('MMM'), year: moment(ConvertedTime).add(i, 'days').format('yyyy') })
                                        daysRequired++;
                                    }
                                    const fullmonthyear = moment(ConvertedTime).format('Do MMMM YYYY');
                                    const fullday = moment(ConvertedTime).format('dddd');
                                    var getTimeSlots = getSlots([], 7);
                                    dispatch({ type: 'RECEIVE_DOCTOR_TIMING', timing: getTimeSlots, weekdays: days, weekfullday: { fullday: fullday, fullmonth: fullmonthyear }, lengths: lengths, isLoading: false });
                                }

                            }
                            else {
                                const data = response.data;



                                const days = [];
                                let daysRequired = 0
                                /* for (let i = daysRequired; i <= 6; i++) {
                                     days.push({ date: moment().add(i, 'days').format('YYYY-MM-DD'), dayno: moment().add(i, 'days').format('D'),  day: moment().add(i, 'days').format('ddd'), month: moment().add(i, 'days').format('MMM'), year: moment().add(i, 'days').format('yyyy') })
                                     daysRequired++;
                                 }
                                 const fullmonthyear = moment().format('Do MMMM YYYY');
                                 const fullday = moment().format('dddd');*/
                                for (let i = daysRequired; i <= 6; i++) {
                                    days.push({ date: moment(ConvertedTime).add(i, 'days').format('YYYY-MM-DD'), dayno: moment(ConvertedTime).add(i, 'days').format('D'), day: moment(ConvertedTime).add(i, 'days').format('ddd'), month: moment(ConvertedTime).add(i, 'days').format('MMM'), year: moment(ConvertedTime).add(i, 'days').format('yyyy') })
                                    daysRequired++;
                                }
                                const fullmonthyear = moment(ConvertedTime).format('Do MMMM YYYY');
                                const fullday = moment(ConvertedTime).format('dddd');

                                var getTimeSlots = getSlots(data, 7);

                                if (!localStorage.getItem("AppointmentOn"))
                                    localStorage.setItem("AppointmentOn", currentdate);

                                if (!localStorage.getItem("SelectedDate"))
                                    localStorage.setItem("SelectedDate", currentdate);

                                dispatch({ type: 'RECEIVE_DOCTOR_TIMING', timing: getTimeSlots, weekdays: days, weekfullday: { fullday: fullday, fullmonth: fullmonthyear }, lengths: lengths });
                            }
                        })
                        .catch(function (error) {
                            errorHandling(error.response.data.statusCode);
                            if (error.response.status == "404")
                                errorHandling(error.response.status);
                        })
                        .then(function () {
                            // always executed
                        });
                    dispatch({ type: 'REQUEST_DOCTOR_TIMING', isLoading: true });
                }
            }
        }, 1000)
    },
    getEncounterTypes: (): AppThunkAction<KnownAction> =>  (dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        if (appState && appState.booking && accountid != "") {
            axios.get('/doctor/GetEncounterType/' + accountid)
                .then(function (response) {
                    const result = response.data;
                    //const temp: any = []

                    //result.map((list: any) => {
                    //    temp.push({ value: list.encounterId, name: list.name })
                    //})
                    //var encountertype: any = temp;
                    //console.log(encountertype)
                    //dispatch({ reciptentLists: temp });
                    /*const resultset = [result]*/
                    const jsonData = JSON.stringify(result);
                    localStorage.setItem("Encountertypes", jsonData);
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
        }
    },
    redirectAppointment: (doctor: any, methodid: any, time: any, date: any, encountertypeid: any, encounterTypeMode: any, durationCode: any, encounterTypeModeDurationId: any, price: any, serviceid: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        var patientLocalStroage: any = localStorage.getItem('Patient');
        var patientdata = JSON.parse(patientLocalStroage);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        var refid = params.get("referrralid");
        var followup = params.get("followup");
        const urlParams = new URLSearchParams(window.location.search);
        const childid = urlParams.get('childid');
        const name = urlParams.get('childname');
        const childname = urlParams.get('childname');
        const status = urlParams.get('status');
        const appointmentid = urlParams.get('appointment');
        const specialityid = urlParams.get('specialityid');
        if (childid == null)
            localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + doctor.doctorid + "/appointment?time=" + time + "&date=" + date + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&serviceid=" + serviceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);
        else
            localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + doctor.doctorid + "/appointment?time=" + time + "&date=" + date + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&serviceid=" + serviceid + "&childid=" + childid + "&childname=" + childname + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + + "&specialityid=" + specialityid);
        var isfree = price == 0 ? "Free" : price;
        localStorage.setItem("pathName", "conditions");
        localStorage.setItem("DoctorId", doctor.doctorid);
        const appState: any = getState();
        var newArray: any = "";
        if (appState != undefined) {
            newArray = appState.account.Settings.filter(function (el: any) {
                return (el.typeId == 9);
            });
        }

        if (localStorage.getItem('isAuth') == "true") {
            var patient = JSON.parse(localStorage.getItem("Patient") || "");
            var memberId = patient.memberId;
            if (childid == null)
                localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + doctor.doctorid + "/appointment?time=" + time + "&date=" + date + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patientdata['id'] + "&serviceid=" + serviceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);
            else
                localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + doctor.doctorid + "/appointment?time=" + time + "&date=" + date + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patientdata['id'] + "&serviceid=" + serviceid + "&childid=" + childid + "&childname=" + childname + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);

            if (patient != null)
                localStorage.setItem("EmailId", patient.Email);
            if (patient.Address1 == "" || patient.Address1 == null) {
                dispatch(push("/contact/" + doctor.doctorid + "/" + memberId + "?childid=" + childid + " &childname=" + childname));
            }
            else {
                var reasonforvisit: any = newArray.filter((x: any) => x.name == "Reason for Visit" && x.customizeValue == "Yes");
                if (reasonforvisit.length == 1) {
                    if (childid == null)
                        dispatch(push("/conditions/" + doctor.doctorid + "/appointment?time=" + time + "&date=" + date + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patient.id + "&serviceid=" + serviceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid));
                    else
                        dispatch(push("/conditions/" + doctor.doctorid + "/appointment?time=" + time + "&date=" + date + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patient.id + "&serviceid=" + serviceid + "&childid=" + childid + "&childname=" + childname + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid));
                }
            }
        } else {
            dispatch(push("/login"));
        }
    },
    redirectCentralized: (id: any, doctor: any, methodid: any, time: any, date: any, time1: any, date1: any, encountertypeid: any, encounterTypeMode: any, durationCode: any, encounterTypeModeDurationId: any, price: any, serivceid: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        var refid = params.get("referrralid");
        var patientLocalStroage: any = localStorage.getItem('Patient');
        var patientdata = JSON.parse(patientLocalStroage);
        const urlParams = new URLSearchParams(window.location.search);
        const childid = urlParams.get('childid');
        const childname = urlParams.get('childname');
        const sitename = urlParams.get('childname');
        var followup = params.get("followup");
        const status = urlParams.get('status');
        const appointmentid = urlParams.get('appointment');
        const specialityid = urlParams.get('specialityid');
        if (patientLocalStroage) {
            if (childid == null) {
                localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + id + "/appointment?time=" + time + "&date=" + date + "&time1=" + time1 + "&date1=" + date1 + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patientdata['id'] + "&serviceid=" + serivceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);
            } else {
                localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + id + "/appointment?time=" + time + "&date=" + date + "&time1=" + time1 + "&date1=" + date1 + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patientdata['id'] + "&childid=" + childid + "&childname=" + sitename + "&serviceid=" + serivceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);
            }
            localStorage.setItem("pathName", "conditions");
            localStorage.setItem("DoctorId", id);
            var refid = params.get("referrralid");
        } else {
            if (childid == null) {
                localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + id + "/appointment?time=" + time + "&date=" + date + "&time1=" + time1 + "&date1=" + date1 + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&serviceid=" + serivceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);
            } else {
                localStorage.setItem("redirectUrl", window.location.origin + "/conditions/" + id + "/appointment?time=" + time + "&date=" + date + "&time1=" + time1 + "&date1=" + date1 + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&childid=" + childid + "&childname=" + sitename + "&serviceid=" + serivceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid);
            }
            localStorage.setItem("pathName", "conditions");
            localStorage.setItem("DoctorId", id);
            var refid = params.get("referrralid");
        }

        const appState: any = getState();
        var newArray: any = "";
        if (appState != undefined) {
            newArray = appState.account.Settings.filter(function (el: any) {
                return (el.typeId == 9);
            });
        }

        if (localStorage.getItem('isAuth') == "true") {
            var patient = JSON.parse(localStorage.getItem("Patient") || "");
            var memberId = patient.memberId;
            if (patient != null)
                localStorage.setItem("EmailId", patient.Email);
            if (patient.Address1 == "" || patient.Address1 == null) {
                dispatch(push("/contact/" + id + "/" + memberId + "?childid=" + childid + " &childname=" + childname));
            }
            else {
                var reasonforvisit: any = newArray.filter((x: any) => x.name == "Reason for Visit" && x.customizeValue == "Yes");
                if (reasonforvisit.length == 1) {
                    if (childid == null) {
                        dispatch(push("/conditions/" + id + "/appointment?time=" + time + "&date=" + date + "&time1=" + time1 + "&date1=" + date1 + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patientdata['id'] + "&serviceid=" + serivceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid));
                    } else {
                        dispatch(push("/conditions/" + id + "/appointment?time=" + time + "&date=" + date + "&time1=" + time1 + "&date1=" + date1 + "&encountertypeid=" + encountertypeid + "&encountertypemode=" + encounterTypeMode + "&durationcode=" + durationCode + "&enctypeid=" + encounterTypeModeDurationId + "&methodid=" + methodid + "&price=" + price + "&referrralid=" + refid + "&patientId=" + patientdata['id'] + "&childid=" + childid + "&childname=" + sitename + "&serviceid=" + serivceid + "&followup=" + followup + "&appointmentid=" + appointmentid + "&status=" + status + "&specialityid=" + specialityid));
                    }
                }
            }
        } else {
            dispatch(push("/login"));
        }
    },
   
    //getProviderId: (datetime: any, offset:any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
    //    const appState = getState();
    //    if (appState && appState.booking) {
    //        var encDetailStroage: any = localStorage.getItem('EncDetail');
    //        var encDetail = JSON.parse(encDetailStroage);
    //        if (localStorage.getItem("isAuth") == "true") {
    //            var patientLocalStroage: any = localStorage.getItem('Patient');
    //            var patient = JSON.parse(patientLocalStroage);
    //            if (patient.timeZone == "") {
    //                var totalMinuteOffset: any = offset;
    //                var d = new Date(datetime);
    //                d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
    //                datetime = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //            } else {
    //                const tzOffset = offset;
    //                const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
    //                var totalMinuteOffset: any = hourOffset * 60 + minuteOffset;
    //                var d = new Date(datetime);
    //                d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
    //                datetime = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //            }
    //        }
    //        else {
    //            var totalMinuteOffset: any = parseInt(offset);
    //            var d = new Date(datetime);
    //            d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
    //            datetime = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //        }
    //        axios.get('/doctor/GetProvider/' + datetime + '/' + encDetail.durationCode)
    //            .then(function (response) {
    //                localStorage.setItem("ProviderId", response.data)
    //            })
    //            .catch(function (error) {
    //                errorMessage(error.response.data);
    //            })
    //            .then(function () {
    //            });
    //    }
    //},
    storeTime: (time: any): AppThunkAction<KnownAction> => async (dispatch: any, getState) => {


        dispatch({ type: 'RECEIVE_STORE_TIMING', time: time });

    },
    //requestReScheduleUpdate: (formvalue: any,date: any,time :any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
    //    const appState: any = getState();        
    //    const urlParams: any = new URLSearchParams(window.location.search);
    //    const type = urlParams.get('type');
    //    var noappointment = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
    //    if (date != null) {
    //        if (window.location.hostname == "localhost") {
    //            if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
    //                var appointmenton = Moment.utc(new Date()).format('YYYY/MM/DD'); //Current datetime
    //                formvalue.AppointmentOn = Moment.utc(toDate(appointmenton + " " + formatAMPM(new Date()))).format("YYYY-MM-DDTHH:mm:ss");
    //            } else {
    //                var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD'); //Current datetime
    //                var totalMinuteOffset: any = 0;
    //                if (formvalue.Offset == "") {
    //                    var timezone = appState.account.Settings.filter((se: any) => se.name == "Time Zone")[0]
    //                    var _timezone = timezone.displayCode.split("|");
    //                    totalMinuteOffset = _timezone[1];
    //                    var d = new Date(appointmenton + " " + time);
    //                    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
    //                    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //                } else {
    //                    const tzOffset = formvalue.Offset;
    //                    const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
    //                    const totalMinuteOffset = hourOffset * 60 + minuteOffset;
    //                    var d = new Date(appointmenton + " " + time);
    //                    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
    //                    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //                }
    //            }
    //        } else {
    //            if ((noappointment.customizeValue == "Yes") && (type == "noappointment")) {
    //                var appointmenton = Moment(new Date()).format('YYYY/MM/DD');
    //                var datetime: any = toDate(appointmenton + " " + formatAMPM(new Date()));
    //                var appointmenttime = DisplayCurrentTime(datetime);
    //                appointmenton = Moment(new Date()).format('YYYY-MM-DD');
    //                var _date = new Date(appointmenton + "T" + appointmenttime);
    //                formvalue.AppointmentOn = Moment.utc(_date).format("YYYY-MM-DDTHH:mm:ss");
    //            } else {
    //                var totalMinuteOffset: any = 0;
    //                if (formvalue.Offset == "") {
    //                    var timezone = appState.account.Settings.filter((se: any) => se.name == "Time Zone")[0]
    //                    var _timezone = timezone.displayCode.split("|");
    //                    var offset = _timezone[1];
    //                    var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD');
    //                    var datetime: any = toDate(appointmenton + " " + time);
    //                    var d = new Date(appointmenton + " " + time); // get current date
    //                    d.setHours(d.getHours(), d.getMinutes() - (parseInt(offset)), 0, 0);
    //                    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //                } else {
    //                    const tzOffset = formvalue.Offset;
    //                    const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
    //                    const totalMinuteOffset = hourOffset * 60 + minuteOffset;
    //                    var appointmenton = Moment.utc(new Date(date)).format('YYYY/MM/DD');
    //                    var datetime: any = toDate(appointmenton + " " + time);
    //                    var d = new Date(appointmenton + " " + time); // get current date
    //                    d.setHours(d.getHours(), d.getMinutes() - (parseInt(totalMinuteOffset)), 0, 0);
    //                    formvalue.AppointmentOn = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
    //                }
    //            }

    //        }
    //    }
    //        var data: any = {
    //            Id: formvalue.appointmentid,                
    //            AppointmentOn: formvalue.AppointmentOn
    //        }
    //    axios.post('/appointment/ReSchedulePost', data)
    //            .then(function (response) {
    //                dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', isLoading: false, isPaymentProcessLoading: false });
    //                dispatch(push("/success/" + formvalue.appointmentid))
    //            })
    //            .catch(function (error) {
    //                dispatch({ type: 'RECEIVE_PAYMENTDETAIL_SUCCESS', isLoading: false, isPaymentProcessLoading: false });
    //                var errorObject = JSON.parse(error.response.data.errorMessage);
    //                if (errorObject.Message == "No Changes") {
    //                    dispatch(push("/success/" + formvalue.appointmentid))
    //                } else {
    //                    errorHandling(error.response.data.statusCode);
    //                }
    //            })
    //            .then(function () {
    //            });
    //        dispatch({ type: 'REQUEST_PAYMENTDETAIL_SUCCESS', isLoading: true, isPaymentProcessLoading: true });

    //}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: BookingState = {
    timing: [], weekdays: [], weekfullday: { fullday: "", fullmonth: "" }, provider: { id: "" }, isLoading: false, lengths: 0, isTimeLoading: false, time: ""
};

export const reducer: Reducer<BookingState> = (state: BookingState | undefined, incomingAction: Action): BookingState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DOCTOR_TIMING':
            return {
                timing: state.timing,
                weekdays: state.weekdays,
                weekfullday: state.weekfullday,
                provider: state.provider,
                isLoading: true,
                lengths: 0,
                isTimeLoading: true,
                time: state.time
            };
        case 'RECEIVE_DOCTOR_TIMING':
            return {
                timing: action.timing,
                weekdays: action.weekdays,
                weekfullday: action.weekfullday,
                provider: action.provider,
                isLoading: false,
                lengths: action.lengths,
                isTimeLoading: false,
                time: state.time
            };
        case 'RECEIVE_STORE_TIMING':
            return {
                timing: state.timing,
                weekdays: state.weekdays,
                weekfullday: state.weekfullday,
                provider: state.provider,
                isLoading: false,
                lengths: state.lengths,
                isTimeLoading: false,
                time: action.time
            };
        default:
            return state;

    }
};


function getSlots(array: any, cols: any) {
    if (cols === 1) return array;
    if (array === undefined) return;
    var size = Math.ceil(array.length / cols);
    var chunk_size = size;
    var arr = array;
    var group = arr.map(function (e: any, i: any) {
        return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
    }).filter(function (e: any) { return e; });
    return group;
}

function addDays(cuurentdate: any, days: number): Date {
    var futureDate = new Date(cuurentdate);
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
}

function toDate(date: any) {
    if (date != null) {
        var currentDateTime = new Date(date);
        return currentDateTime
    }
}
function DisplayCurrentTime(datetime: Date) {
    var date = datetime;
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return (hours + ":" + minutes + ":" + seconds);
};
const formatAMPM = (date: any) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes.toString().padStart(2, '0');
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}