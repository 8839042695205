import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const customform = () => (
    <React.Fragment>
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 offset-3 border mob-margin">
                    <h4 className="widget-title pat-widget-title mb-2">Custom  Form</h4>

                    <div className="form-group form-focus co-md-6">
                        <input type="email" className="form-control floating" />
                        <label className="focus-label">Textbox</label>
                    </div>

                    <div className="form-group form-focus co-md-6">
                        <textarea className="form-control floating" style={{ height: "65px" }}></textarea>
                        <label className="focus-label">Textarea</label>
                    </div>


                    <div className="form-group row">
                        <label className="col-form-label col-md-12"><b>Radio</b></label>
                        <div className="col-md-10">
                            <div className="radio">
								<label><input type="radio" name="radio" /> Option 1</label>
							</div>
                            <div className="radio">
								<label><input type="radio" name="radio" /> Option 2</label>
							</div>
                            <div className="radio">
								<label><input type="radio" name="radio" /> Option 3</label>
							</div>
						</div>
                    </div><br />

                    <div className="form-group row">
                        <label className="col-lg-12 col-form-label"><b>Radio Button list</b></label>
                        <div className="col-lg-9">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_male" value="option1" checked />
                                <label className="form-check-label" >One</label>
							</div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" />
                                    <label className="form-check-label">Two</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="gender" id="gender_female" value="option2" />
                                <label className="form-check-label">Three</label>
                            </div>
                        </div>
                    </div><br />


                    <div className="form-group row">
                        <label className="col-form-label col-md-12"><b>Checkbox</b></label>
                        <div className="col-md-10">
                            <div className="checkbox">
                                <label><input type="checkbox" name="checkbox" /> Option 1</label>
							</div>
                            <div className="checkbox">
                                 <label><input type="checkbox" name="checkbox" /> Option 2</label>
							</div>
                            <div className="checkbox">
                                 <label><input type="checkbox" name="checkbox" /> Option 3</label>
							</div>
                        </div>
                    </div>



                    <div className="form-group row">
                        <label className="col-form-label col-md-12"><b>Checkbox</b></label>
                        <div className="col-md-10">
                            <div className="form-check form-check-inline">
                                <label><input type="checkbox" name="checkbox" /> Option 1</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <label><input type="checkbox" name="checkbox" /> Option 2</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <label><input type="checkbox" name="checkbox" /> Option 3</label>
                            </div>
                        </div>
                    </div>


                    <div className="form-group">
                        <div className="dropdown">
                            <a className="dropdown-toggle btn btn-secondary" href="#" role="button" data-toggle="dropdown" aria-expanded="false"> Dropdown </a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Event Date <span className="text-danger">*</span></label>
                        <div className="cal-icon">
                            <input className="form-control datetimepicker" type="text" />
						</div>
                        </div>
                   


                </div>
            </div>
        </div>
    </div>
    </React.Fragment>


);

export default connect()(customform);