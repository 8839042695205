import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';
import * as DashboardStore from '../../store/patient/Dashboard';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav';
import CancelAppointmentModel from '../../components/appointment/CancelAppointmentModel';
import Moment from 'moment';
import { mobnav, seo } from '../../helper/Script';
import { statusColor, dateFormat } from '../../helper/formvalidation';
import * as AppointmentTypeStore from '../../store/patient/AppointmentType';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as NavDefaultStore from '../../store/menu/NavDefault';
import * as AccountStore from '../../store/siteaccount/Account';
import * as SiteDetailStore from '../../store/SiteDetail';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as CancelAppointmentStore from '../../store/appointment/CancelAppointmentModel';
import * as MemberSpecialityStore from '../../store/menu/speciality';
import { setTittle, scrollTop } from '../../helper/formvalidation';
import { toastr } from 'react-redux-toastr';
import { DashBoardContentLoading, DashBoardPageLoading } from '../../helper/fbloader';
var enablefamilymember: any = "";
var enablefollowup: any = "";
var enableappcancel: any = "";
var enableappreschedule: any = "";
var enablerescheduleduration: any = "";
var cancelduration: any = "";
var doctorappointment: any = "";
var anonymous: any = "";
var requestqueue: any = "";
var preferredtimeslot: any = "";
var pickaprovider: any = "";
declare var bootstrap: any;
var langdata: any = "";
var enablevisittype: any = "";

// At runtime, Redux will merge together...
type DashboardProps =
    DashboardStore.DashboardState & AppointmentTypeStore.AppointmentTypeState & SiteDetailStore.SiteDetailState & AccountStore.AccountState & NavDefaultStore.NavDefaultState & LanguageResourcesStore.LanguageResouresState & CancelAppointmentStore.CancelAppointmentState & TimeZoneStore.TimeZoneState & MemberSpecialityStore.MemberSpecialityState // ... state we've requested from the Redux store
    & typeof DashboardStore.actionCreators & typeof AppointmentTypeStore.actionCreators & typeof AccountStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof CancelAppointmentStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof MemberSpecialityStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var languagecontent: any = "";
var Currenttimezone: any = [];

class FetchData extends React.PureComponent<DashboardProps> {
    constructor(props: any) {
        super(props);
    }
    state = {
        isShow: true
    }
    routeNav(value: any) {
        localStorage.setItem("patientReferrralId", value.patientReferrralId);
        this.props.redirectURL(value);
    }
    routeNavDetail(value: any, hummyurl: any, patientId: any) {
        if (value.appointmentStatus == "In Progress" || value.appointmentStatus == "En Progreso")
            this.props.requestVisitDetailURL(value.appointmentId, hummyurl, patientId);
    }
    routeNavVisitDetail(value: any, hummyurl: any, patientId: any) {
        //console.log(value,"value")
        this.props.requestVisitDetailURL(value, hummyurl, patientId);
    }
    
    // This method is called when the component is first added to the document
    public componentDidMount() {
        scrollTop();
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        this.setState({
            isShow: true
        })
        this.ensureDataFetched();
        //seo({
        //    title: 'Consultation List',
        //    description: 'We can search our favourite doctor. If click the doctor name should show the profile page'
        //});
        localStorage.removeItem("paidAmount");
        localStorage.removeItem("TimeIndex");
        localStorage.removeItem("DoctorId");
        localStorage.removeItem("EncDetail");
        localStorage.removeItem("AppointmentId");
        localStorage.removeItem("AppointmentTime");
        localStorage.removeItem("followup");
        localStorage.removeItem("modecode");
        localStorage.removeItem("followupduration");
        localStorage.removeItem("followuptypemode");
        localStorage.removeItem("enctypemode");
        localStorage.removeItem("AppointmentOn");
        localStorage.removeItem("AppointmentTime1");
        localStorage.removeItem("AppointmentOn1");
        localStorage.removeItem("AppointmentBookedOn");
        localStorage.removeItem("SelectedDate");
        localStorage.removeItem("Price");
        localStorage.removeItem("Appointment");
        localStorage.removeItem("SpecialityId");
        localStorage.removeItem("prescriptionprint");
        localStorage.removeItem("SpecailityReasonName");
        localStorage.removeItem("SpecailityReasonId");
        localStorage.removeItem("AppointmentUTCTime")
        localStorage.removeItem("AppointmentUTCTime1")
    }
    componentDidUpdate() {
        //var langResources = JSON.parse(localStorage.getItem("LangResources") || "");
        //var dashboard: any = langResources.filter((la: any) => la.Name == "dashboard");
        //seo({
        //    title: 'Consultation List',
        //    pageTitle: "Dashboard",
        //    description: 'We can search our favourite doctor. If click the doctor name should show the profile page'
        //});
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderDashboard()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
       // this.props.getSignalRCredential();
        this.props.getLangResources();
        this.props.requestAppointmentType();
        this.props.requestAppointmentList();
        this.props.requestMemberSpecialityLists();
        mobnav();
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (patient.timeZone != "") {
            this.props.requestTimeZone(patient.timeZone);
        }

    }
    payNow = (value: any) => {
        this.props.redirectPayNow(value);
    }
    goNextPage = () => {
        this.props.redirectDoctorPage();
    }
    goRequestQue = () => {
        this.props.redirectRequestQue();
    }

    chooseRequestQueue = () => {
        if (localStorage.getItem('isAuth') == "true") {
            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                var patientid = patient.id;
                if (this.props.speciality.length == 1) {
                    localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id );
                    localStorage.setItem("pathName", "RequestQueue");
                    window.location.href = "/contact/" + null + "/" + patient.memberId;
                }
                else
                    window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;

            } else {
                var patientid = patient.id;

                if (this.props.speciality.length == 1) {
                    localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                    localStorage.setItem("pathName", "RequestQueue");
                    this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id );
                }
                else
                    window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;
            }
        } else {
            localStorage.removeItem("AppointmentOn");
            localStorage.removeItem("AppointmentOn1");
            localStorage.removeItem("AppointmentTime");
            localStorage.removeItem("AppointmentTime1");
            localStorage.removeItem("AppointmentUTCTime")
            localStorage.removeItem("AppointmentUTCTime1")      
            localStorage.removeItem("followup");
            localStorage.removeItem("modecode")
            localStorage.removeItem("followupduration");
            localStorage.removeItem("followuptypemode");
            localStorage.removeItem("enctypemode");
            localStorage.removeItem("SelectedDate");
            localStorage.removeItem("DoctorId");
            localStorage.removeItem("EncDetail");
            localStorage.removeItem("Appointment");
            localStorage.removeItem("SpecialityId");
            localStorage.removeItem("EncounterTypeId");
            localStorage.removeItem("currency");
            localStorage.setItem("redirectQequestQueueUrl", "/consultationrequest")
            localStorage.setItem("pathName", "RequestQueue");
            this.props.history.push("/login");
        }
    }
    providerAppointment = () => {
        if (this.props.speciality.length == 1)
            window.location.href = "/ourdoctors?specialityid=" + this.props.speciality[0].id;
        else
            window.location.href = "/memberspeciality?name=ourdoctors";
    }
    chooseproviderAppointment = () => {
        if (this.props.speciality.length == 1)
            window.location.href = "/centralized?specialityid=" + this.props.speciality[0].id;
        else
            window.location.href = "/memberspeciality?name=centralized";

        
    }
    addFollowUp = (value: any) => {
        enablevisittype = this.props.Settings.filter((se: any) => se.name == "Enable Visit Type")[0];
        if (enablevisittype != undefined) {
            enablevisittype = this.props.Settings.filter((se: any) => se.name == "Enable Visit Type")[0].customizeValue;
        }
        if (enablevisittype == "Yes") {
            if (value.familyMemberId == null) {
                window.location.href = "/booking/" + value.doctorId + "?followup=" + value.appointmentId + "&status=followup" + "&statuses=reschedulefollowup" + "&specialityid=" + value.specialityId + "&type=" + value.appointmentType + "&code=" + value.followupduration + "&price=" + value.price + "&visittype=" + value.visitType + "&visitTypeId=" + value.visitTypeId
            } else {
                window.location.href = "/booking/" + value.doctorId + "?followup=" + value.appointmentId + "&status=followup" + "&statuses=reschedulefollowup" + "&childid=" + value.familyMemberId + "&childname=" + value.memberName + "&specialityid=" + value.specialityId + "&type=" + value.appointmentType + "&code=" + value.followupduration + "&price=" + value.price + "&visittype=" + value.visitType + "&visitTypeId=" + value.visitTypeId
            }
        }
        else {
            if (value.familyMemberId == null) {
                window.location.href = "/booking/" + value.doctorId + "?followup=" + value.appointmentId + "&status=followup" + "&statuses=reschedulefollowup" + "&specialityid=" + value.specialityId + "&type=" + value.appointmentType + "&code=" + value.followupduration + "&price=" + value.price 
            } else {
                window.location.href = "/booking/" + value.doctorId + "?followup=" + value.appointmentId + "&status=followup" + "&statuses=reschedulefollowup" + "&childid=" + value.familyMemberId + "&childname=" + value.memberName + "&specialityid=" + value.specialityId + "&type=" + value.appointmentType + "&code=" + value.followupduration + "&price=" + value.price 
            }
        }
    }
    cancelAppointment = (value: any) => {
        var myModal = new bootstrap.Modal(document.getElementById('dCancelModal'), {
            backdrop: 'static',
            keyboard: false
        })
        myModal.show();
        this.props.setCancelAppointment(value);
    }
    rescheduleAppointment = (value: any) => {
        //console.log(value)

        enablerescheduleduration = this.props.Settings.filter((se: any) => se.name == "Appointment Reschedule Duration")[0];
        if (enablerescheduleduration != undefined) {
            enablerescheduleduration = this.props.Settings.filter((se: any) => se.name == "Appointment Reschedule Duration")[0].customizeValue;
        }
        enablevisittype = this.props.Settings.filter((se: any) => se.name == "Enable Visit Type")[0];
        if (enablevisittype != undefined) {
            enablevisittype = this.props.Settings.filter((se: any) => se.name == "Enable Visit Type")[0].customizeValue;
        }
        //if (value.rescheduleRefId == null && value.doctorName == "Not Assigned") {
        //    value.doctorId = "";
        //}
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (this.props.TimeZones.length != 0) {
            Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
        }
        const tzOffset = Currenttimezone[0].Offset;
        const [hourOffset, minuteOffset] = tzOffset.split(':').map(Number);
        const totalMinuteOffset = hourOffset * 60 + minuteOffset;

        var d = new Date();
        var currentdate: any = Moment(d).format("YYYY-MM-DDTHH:mm:ss");
        var app_Date: any = value.appointmentUTCDate;
        var ad = new Date(app_Date);
        var appDate = Moment(ad).format("YYYY-MM-DDTHH:mm:ss");
        let utcMoment = Moment().utc();

        let formattedUtcString = utcMoment.format("YYYY-MM-DDTHH:mm:ss");

        // Display the formatted UTC time

        var isvalue = checkDatetime(appDate, enablerescheduleduration, formattedUtcString);




        //var currentDate = new Date(); // Get the current date

        //var offset = totalMinuteOffset; // Offset in minutes (e.g., -480 for GMT-8)

        //var utcTime = currentDate.getTime() + (currentDate.getTimezoneOffset() * 60000); // Convert current date to UTC timestamp

        //var targetTime = utcTime + (offset * 60000); // Apply the desired offset to the UTC timestamp

        //var utcDate = new Date(targetTime); // Create a new Date object with the UTC timestamp

        //var utc_Date = Moment(utcDate).format("YYYY-MM-DDTHH:mm:ss")



        //var is_check_value = utc_Date < appDate



        if (isvalue == true) {
            if (value.doctorName == "Not Assigned") {
                window.location.href = "/centralized/" + null + "?appointment=" + value.appointmentId + "&status=reschedule" + "&statuses=reschedulefollowup" + "&childid=" + value.familyMemberId + "&childname=" + value.memberName + "&type=" + value.appointmentType + "&code=" + value.duration + "&price=" + value.price + "&specialityid=" + value.specialityId + "&patientid=" + patient.id;
            } else {
                if (enablevisittype == "Yes") {
                    window.location.href = "/booking/" + value.doctorId + "?appointment=" + value.appointmentId + "&status=reschedule" + "&statuses=reschedulefollowup" + "&childid=" + value.familyMemberId + "&childname=" + value.memberName + "&type=" + value.appointmentType + "&code=" + value.duration + "&price=" + value.price + "&specialityid=" + value.specialityId + "&patientid=" + patient.id + "&visittype=" + value.visitType + "&visitTypeId=" + value.visitTypeId;
                }
                else {
                    window.location.href = "/booking/" + value.doctorId + "?appointment=" + value.appointmentId + "&status=reschedule" + "&statuses=reschedulefollowup" + "&childid=" + value.familyMemberId + "&childname=" + value.memberName + "&type=" + value.appointmentType + "&code=" + value.duration + "&price=" + value.price + "&specialityid=" + value.specialityId + "&patientid=" + patient.id ;

                }
                }
        }
        else
            toastr.error('', languagecontent['rescheduleappointment'] + ' ' + enablerescheduleduration + ' ' + languagecontent['mins'] + ' ' + languagecontent['priortoappoint']);
    }

    listFollowUp = (value: any) => {
        //var x = document.getElementById(value.appointmentId);
        //if (x != null) {
        //	x.classList.remove("hide");
        //	x.classList.add("show");
        //}
    }



    private renderDashboard() {
        languagecontent = this.props.LanguageResoures;
        enablefamilymember = this.props.Settings.filter((se: any) => se.name == "Enable Family Member")[0];
        if (enablefamilymember != undefined) {
            enablefamilymember = this.props.Settings.filter((se: any) => se.name == "Enable Family Member")[0].customizeValue;
        }
        /*var langResources = JSON.parse(localStorage.getItem("LangResources") || "");*/
        //var consultmethod: any = langResources.filter((la: any) => (la.Group == "familymember"));
        //var chooseaprovider: any = consultmethod.filter((cm: any) => cm.Name == "chooseaprovider");
        //var consultnow: any = consultmethod.filter((cm: any) => cm.Name == "consultnow");
        //var providerappointment: any = consultmethod.filter((cm: any) => cm.Name == "providerappointment");
        setTimeout(() => {
            var checkArray = String(this.props.navDefault.isAuth) == String(localStorage.getItem("isAuth"));
            //console.log(checkArray, "-", localStorage.getItem("isAuth"))
            if (this.props.navDefault.isAuth == false) {
                document.location.href = "/login"
            }
        }, 15000);
        const Appontment = this.props.appointmentlists;
        //console.log(this.props.appointmentlists);
        //localStorage.setItem("patientId", Appontment.PatientId);
        Appontment.sort(Date_sortFunction);
        function Date_sortFunction(a: any, b: any) {
            var dateA = new Date(a.appointmentDate).getTime();
            var dateB = new Date(b.appointmentDate).getTime();
            return dateB > dateA ? 1 : -1;
        };


        enablefollowup = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "followup consultation")[0];
        if (enablefollowup != undefined) {
            enablefollowup = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "followup consultation")[0].customizeValue;
        }
        enableappcancel = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "appointment cancel by patient")[0];
        if (enableappcancel != undefined) {
            enableappcancel = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "appointment cancel by patient")[0].customizeValue;
        }

        enableappreschedule = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "appointment reschedule by patient")[0];
        if (enableappreschedule != undefined) {
            enableappreschedule = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "appointment reschedule by patient")[0].customizeValue;
        }

        cancelduration = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "cancel duration prior to appointment")[0];
        if (cancelduration != undefined) {
            cancelduration = this.props.Settings.filter((se: any) => se.name.toLowerCase() == "cancel duration prior to appointment")[0].customizeValue;
        }

        doctorappointment = this.props.appointmenttypes.filter((se: any) => se.name == "Doctor Appointment")[0];
        if (doctorappointment != undefined) {
            doctorappointment = this.props.appointmenttypes.filter((se: any) => se.name == "Doctor Appointment")[0].value;
        }
        anonymous = this.props.appointmenttypes.filter((se: any) => se.name == "Anonymous")[0];
        if (anonymous != undefined) {
            anonymous = this.props.appointmenttypes.filter((se: any) => se.name == "Anonymous")[0].value;
        }
        requestqueue = this.props.appointmenttypes.filter((se: any) => se.name == "Request Queue")[0];
        if (requestqueue != undefined) {
            requestqueue = this.props.appointmenttypes.filter((se: any) => se.name == "Request Queue")[0].value;
        }
        preferredtimeslot = this.props.Settings.filter((s: any) => s.name == "Client preferred time slot");
        if (preferredtimeslot.length != 0)
            preferredtimeslot = preferredtimeslot[0].customizeValue;
        else
            preferredtimeslot = "";

        pickaprovider = this.props.Settings.filter((s: any) => s.name == "Auto Provider Selection in PickaProvider");
        if (pickaprovider.length != 0)
            pickaprovider = pickaprovider[0].customizeValue;
        else
            pickaprovider = "";

        
        /*var langResources = JSON.parse(localStorage.getItem("LangResources") || "");*/
        //var doctorbooknowdesc: any = langResources.filter((la: any) => (la.Name == "doctorbooknowdesc"))[0].Content;
        //var centralizebooknowdesc: any = langResources.filter((la: any) => (la.Name == "centralizebooknowdesc"))[0].Content;
        //var consultnowdesc: any = langResources.filter((la: any) => (la.Name == "consultnowdesc"))[0].Content;
        //let humsplit = (appointmentlist.hummyURL).split("/")
       // console.log("Dashboard");
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">

                        <DashboardNavMenu />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="row">
                                <div className="col-md-12">
                                <div className="pb-5">
                                    <div>
                                        {this.props.isLoading === true ?
                                            <div>
                                                {
                                                    this.props.Settings.length == 0 ? "" :
                                                        <div className="row">
                                                            {
                                                                enablefamilymember == "Yes" ?
                                                                    <div className="col-lg-4">
                                                                        {
                                                                            languagecontent['doctorbooknowdesc'] == undefined ? 
                                                                                    <DashBoardContentLoading />
                                                                                :
                                                                                <div className="card widget-profile pat-widget-profile">
                                                                                    <div className="card-body">
                                                                                        <div className="dashboard-widget-mr">
                                                                                            <small>{languagecontent['doctorbooknowdesc']}</small>
                                                                                        </div>
                                                                                        <br />
                                                                                        <div className="row text-center">
                                                                                            <div className="col-sm-12">
                                                                                                <a className="btn btn-md btn-primary mr-10" onClick={() => this.goNextPage()} style={{ width: "100%" }}>{languagecontent['booknow']}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <React.Fragment>
                                                                        {
                                                                            doctorappointment == "No" ? "" :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        this.props.siteDetail.Code == "VTS" ?

                                                                                            <div className="col-md-6 col-lg-6 col-xl-6 text-center">
                                                                                               <div>
                                                                                                    <p className="widget-title pat-widget-title" style={{ marginBottom: "15px !important" }}>Book An Appointment On Your Preferred  Date and Time</p>
                                                                                                    <a type="button" href="/memberspeciality?name=ourdoctors" className="btn btn-outline-primary vrbtnhover">Schedule A Call Later</a>
                                                                                                </div>
                                                                                                <br /><br />
                                                                                            </div>
                                                                                            :
                                                                                            <div className="col-lg-4">
                                                                                                {
                                                                                                    languagecontent['doctorbooknowdesc'] == undefined ?
                                                                                                            <DashBoardContentLoading />
                                                                                                        :

                                                                                                        <div className="card widget-profile pat-widget-profile">
                                                                                                            <div className="card-body">
                                                                                                                <div className="dashboard-widget-mr">
                                                                                                                    <small>{languagecontent['doctorbooknowdesc']}</small>
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <div className="row text-center">
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <a className="btn btn-md btn-primary mr-10" onClick={() => this.providerAppointment()} style={{ width: "100%" }}>{languagecontent['booknow']}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>
                                                                                    }

                                                                                </React.Fragment>
                                                                        }
                                                                        {
                                                                            anonymous == "No" ? "" :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        this.props.siteDetail.Code == "VTS" ? ""
                                                                                            :
                                                                                            <div className="col-lg-4">
                                                                                                {
                                                                                                    languagecontent['centralizebooknowdesc'] == undefined ?
                                                                                                            <DashBoardContentLoading />
                                                                                                        :
                                                                                                        <div className="card widget-profile pat-widget-profile">
                                                                                                            <div className="card-body">
                                                                                                                <div className="dashboard-widget-mr">
                                                                                                                    <small>{languagecontent['centralizebooknowdesc']}</small>
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <div className="row text-center">
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <a className="btn btn-md btn-primary mr-10" onClick={() => this.chooseproviderAppointment()} style={{ width: "100%" }}>{languagecontent['centerlized']}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            </div>
                                                                                                    }
                                                                                            </div>
                                                                                    }

                                                                                </React.Fragment>
                                                                        }
                                                                        {
                                                                            requestqueue == "No" ? "" :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        this.props.siteDetail.Code == "VTS" ?
                                                                                            <div className="col-md-6 col-lg-6 col-xl-6 text-center">
                                                                                                <div>
                                                                                                    <p className="widget-title pat-widget-title" style={{ marginBottom: "15px !important" }}>Does Your Pet Need Immediate Care?</p>
                                                                                                    <button onClick={() => this.goRequestQue()} type="button" className="btn btn-outline-primary vrbtnhover">Speak To A Vet Now</button>
                                                                                                </div>
                                                                                                <br /><br />
                                                                                            </div>
                                                                                            :
                                                                                            <div className="col-lg-4">
                                                                                                {
                                                                                                    languagecontent['consultnowdesc'] == undefined ?
                                                                                                            <DashBoardContentLoading />
                                                                                                        :
                                                                                                        <div className="card widget-profile pat-widget-profile">
                                                                                                            <div className="card-body">
                                                                                                                <div className="dashboard-widget-mr">
                                                                                                                    <small> {languagecontent['consultnowdesc']} </small>
                                                                                                                </div>
                                                                                                                <br />
                                                                                                                <div className="row text-center">
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <a className="btn btn-md btn-primary mr-10" onClick={() => this.chooseRequestQueue()} style={{ width: "100%" }}>{languagecontent['insantappointment']}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                }
                                                                                            </div>

                                                                                    }
                                                                                </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                            :
                                                <DashBoardContentLoading/>
                                        }
                                        </div>
                                        {
                                            this.props.isLoading === true && languagecontent['consultation'] != undefined ?
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-8 col-12"><h4 className="widget-title pat-widget-title ng-binding">{languagecontent['consultation']}</h4></div>
                                                </div> : ""}
                                    <div id="pat_appointments" className="tab-pane fade show active">
                                        <div className="card card-table mb-0">
                                            <div className="card-body">
                                                    {
                                                        this.props.isLoading === true && languagecontent['consultation'] != undefined ?
                                                        <div className="table-responsive">
                                                            {
                                                                <div>

                                                                    {
                                                                        Appontment.length != 0 ?
                                                                            <table className="table table-hover table-center mb-0">
                                                                                {Appontment.map((appointmentlist: DashboardStore.AppointmentList) =>
                                                                                    <React.Fragment>
                                                                                        <tbody>
                                                                                            <td style={{ width: "200px" }}>
                                                                                                {
                                                                                                    appointmentlist.statusId == 11 || appointmentlist.statusId == 9 ?
                                                                                                        <div>
                                                                                                            {appointmentlist.referenceNo ? "" ? "" : appointmentlist.referenceNo : ""}
                                                                                                        </div>
                                                                                                        :
                                                                                                        <React.Fragment><strong><a className="text-info" onClick={() => this.routeNavVisitDetail(appointmentlist.encounterId, (appointmentlist.hummyURL).split("/")[3], appointmentlist.patientId)}>
                                                                                                            {appointmentlist.referenceNo ? appointmentlist.referenceNo : ""}
                                                                                                        </a></strong><br /></React.Fragment>
                                                                                                }
                                                                                                {
                                                                                                    appointmentlist.statusId == "11" && appointmentlist.isShowPayBtn == false ? <span className={statusColor(appointmentlist.statusId)}>{languagecontent['paymentexpired']}</span> :
                                                                                                        <span className={statusColor(appointmentlist.statusId)}>{appointmentlist.statusId == 11 ? languagecontent['paymentpending'] : appointmentlist.appointmentStatus}</span>

                                                                                                }
                                                                                                <React.Fragment><br /></React.Fragment>
                                                                                                {
                                                                                                    appointmentlist.rescheduleRefId == null ? "" :
                                                                                                        <span className="text-info">{languagecontent['rescheduled'] == undefined ? "Rescheduled" : languagecontent['rescheduled']}</span>
                                                                                                }
                                                                                            </td>
                                                                                            {
                                                                                                preferredtimeslot == "Yes" && pickaprovider =="No" ?
                                                                                                <td style={{ width: "200px" }}>{dateFormat(appointmentlist.scheduleDate)}<span className=" text-info" style={{ marginLeft: "5px" }}>{Moment(appointmentlist.scheduleDate).format('LT')} <span className="duration-info">({appointmentlist.duration} {languagecontent['mins']})</span></span>
                                                                                                    {
                                                                                                        appointmentlist.secondaryAppointmentOn ?

                                                                                                            <span><br />{dateFormat(appointmentlist.secondaryAppointmentOn)}<span className="text-info" style={{ marginLeft: "5px" }}>{Moment(appointmentlist.secondaryAppointmentOn).format('LT')} <span className="duration-info">({appointmentlist.duration} {languagecontent['mins']})</span></span>
                                                                                                            </span>


                                                                                                            : ""
                                                                                                    }
                                                                                                    {
                                                                                                        appointmentlist.isRefButtonShow == true ?
                                                                                                            <div><button className="btn btn-sm bg-primary-light" onClick={() => this.routeNav(appointmentlist)}>{languagecontent['bookanappointment']}</button>
                                                                                                            </div>: ""

                                                                                                    }
                                                                                                    </td>
                                                                                                    : <td style={{ width: "200px" }}>{dateFormat(appointmentlist.scheduleDate)}<span className="d-block text-info">{Moment(appointmentlist.scheduleDate).format('LT')} <span className="duration-info">({appointmentlist.duration} {languagecontent['mins']})</span></span>
                                                                                                        {
                                                                                                            appointmentlist.isRefButtonShow == true ?
                                                                                                               <div> <button className="btn btn-sm bg-primary-light" onClick={() => this.routeNav(appointmentlist)}>{languagecontent['bookanappointment']}</button>
                                                                                                                </div>: ""

                                                                                                        }
                                                                                                    </td>
                                                                                            }
                                                                                            <td style={{ width: "200px" }}>
                                                                                                <h2 className="table-avatar">
                                                                                                    <a className="avatar avatar-sm mr-2">
                                                                                                        {appointmentlist.photo == null ? <img className="avatar-img rounded-circle" src="client/default/img/blankimg.jpg" alt="User Image" /> : <img className="avatar-img rounded-circle" src={"patient/ImageUrl/member/" + appointmentlist.doctorId} alt="User Image" />}
                                                                                                    </a>
                                                                                                    {appointmentlist.doctorName == "Not Assigned" ? languagecontent['notassigned'] : appointmentlist.doctorName}
                                                                                                </h2>
                                                                                                {
                                                                                                    appointmentlist.isRefButtonShow == true ?
                                                                                                        <div>
                                                                                                            <small>{languagecontent['referedto']}</small> <br />
                                                                                                            <h2>{appointmentlist.referredTo}</h2>
                                                                                                        </div> : ""

                                                                                                }
                                                                                            </td>
                                                                                            <td style={{ width: "200px" }}><label className="dashboard-ellipsis">{languagecontent['reasonforappointment']}: {appointmentlist.reason}</label> <br /> {appointmentlist.type.split("-", 1)}</td>

                                                                                            {
                                                                                                this.props.Settings.length == 0 ? "" :
                                                                                                    enablefamilymember == "No" ? "" :
                                                                                                        <td style={{ width: "200px" }}>
                                                                                                            {
                                                                                                                languagecontent['self'] == undefined ? "" :
                                                                                                                    <label className="dashboard-ellipsis dashboard_label">{appointmentlist.relationship == "" ? languagecontent['self'] : appointmentlist.memberName} <br />{appointmentlist.relationship == "" ? "" : '(' + appointmentlist.relationship + ')'}</label>
                                                                                                            }
                                                                                                        </td>

                                                                                            }
                                                                                            {
                                                                                                enablefollowup == "No" ? "" :
                                                                                                    <td style={{ width: "200px" }}>
                                                                                                        {
                                                                                                            appointmentlist.statusId != 8 ? "" :
                                                                                                                <div className="btn-group ml-2 mb-2">
                                                                                                                    {appointmentlist.parentId == null ? <button type="button" className="btn btn-sm bg-primary-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">{languagecontent['followup']}</button> : ""}
                                                                                                                    <div className="dropdown-menu" x-placement="top-start">
                                                                                                                        <a className="dropdown-item" onClick={() => this.addFollowUp(appointmentlist)}>{languagecontent['add']}</a>
                                                                                                                        <a className="dropdown-item" onClick={() => this.listFollowUp(appointmentlist)} hidden={appointmentlist.children.length == 0} data-toggle="collapse" href={"#collapseExample" + appointmentlist.appointmentId} role="button" aria-expanded="false" aria-controls={"collapseExample" + appointmentlist.appointmentId}>{languagecontent['list']}</a>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                        }
                                                                                                    </td>
                                                                                            }
                                                                                            <td className="text-right" style={{ width: "200px" }}>
                                                                                                <div className="table-action">{
                                                                                                     ((appointmentlist.statusId === 1) || (appointmentlist.statusId === 2) || (appointmentlist.statusId === 3) || (appointmentlist.statusId === 4) ||(appointmentlist.statusId === 5)|| appointmentlist.appointmentStatus === "In Progress" || appointmentlist.appointmentStatus === "En Progreso" || (appointmentlist.statusId === 7)) && appointmentlist.type.split("-")[0].trim() == languagecontent['vm_teleconsultation'] ?
                                                                                                        <a href={appointmentlist.hummyURL} target="_blank" className="btn btn-sm bg-primary-light">
                                                                                                            {languagecontent['joincall']}
                                                                                                        </a> : ""
                                                                                                }
                                                                                                    {
                                                                                                        appointmentlist.isShowPayBtn == true ?
                                                                                                            <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.payNow(appointmentlist)}>
                                                                                                                {languagecontent['pay'] == undefined ? "Pay" : languagecontent['pay']}
                                                                                                            </button> : ""
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                            {
                                                                                                enableappcancel == "Yes" || enableappreschedule == "Yes" ?
                                                                                                    <td className="text-right" style={{ width: "50px" }}>
                                                                                                        <React.Fragment>
                                                                                                            {
                                                                                                                ((appointmentlist.statusId === 1) || (appointmentlist.statusId === 9) || (appointmentlist.statusId === 7)) ?
                                                                                                                    <React.Fragment>
                                                                                                                        <div className="btn-group ml-2 mb-2">
                                                                                                                            {

                                                                                                                                (appointmentlist.methodId != 3) || (appointmentlist.methodId == 3 && appointmentlist.statusId == 1) && (appointmentlist.statusId === 1) || (appointmentlist.statusId === 9) ?

                                                                                                                                    appointmentlist.doctorName != 'Not Assigned' || appointmentlist.doctorName == 'Not Assigned' && appointmentlist.methodId == 2 || appointmentlist.doctorName == 'Not Assigned' && enableappcancel == "Yes" ?


                                                                                                                                        (appointmentlist.rescheduleRefId == null && enableappcancel == "Yes" && enableappreschedule == "Yes") ||
                                                                                                                                            (appointmentlist.rescheduleRefId == null && enableappcancel == "No" && enableappreschedule == "Yes") ||
                                                                                                                                            (appointmentlist.rescheduleRefId == null && enableappcancel == "Yes" && enableappreschedule == "No") ||
                                                                                                                                            (appointmentlist.rescheduleRefId != null && enableappcancel == "Yes") ?


                                                                                                                                            <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style={{ overflow: "auto", width: "50px", height: "50px" }}><i className="fa fa-ellipsis-v" aria-hidden="true" ></i></a> : "" : "" : ""

                                                                                                                            }
                                                                                                                            <div className="dropdown-menu" x-placement="top-start">
                                                                                                                                {
                                                                                                                                    enableappcancel == "Yes" ?
                                                                                                                                        <a className="dropdown-item" onClick={() => this.cancelAppointment(appointmentlist)}>{languagecontent['cancel'] == undefined ? "Cancel" : languagecontent['cancel']}</a>
                                                                                                                                        : ""
                                                                                                                                }

                                                                                                                                {


                                                                                                                                    appointmentlist.doctorName != 'Not Assigned' && appointmentlist.rescheduleLimit == true ?
                                                                                                                                        enableappreschedule == "Yes" && appointmentlist.methodId != 3 && ((appointmentlist.statusId === 1) || (appointmentlist.statusId === 9)) ?



                                                                                                                                            <a className="dropdown-item" onClick={() => this.rescheduleAppointment(appointmentlist)}>{languagecontent['reschedule'] == undefined ? "Reschedule" : languagecontent['reschedule']}</a>
                                                                                                                                            : "" : ""
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    appointmentlist.doctorName == 'Not Assigned' && appointmentlist.rescheduleLimit == true ?
                                                                                                                                        enableappreschedule == "Yes" && (appointmentlist.methodId != 3 && appointmentlist.statusId === 9)   && (appointmentlist.statusId === 9) ?
                                                                                                                                            <a className="dropdown-item" onClick={() => this.rescheduleAppointment(appointmentlist)}>{languagecontent['reschedule'] == undefined ? "Reschedule" : languagecontent['reschedule']}</a>
                                                                                                                                            : "" : ""

                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </React.Fragment> : ""
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    </td> : ""

                                                                                            }
                                                                                        </tbody>
                                                                                        <tbody className="collapse" id={"collapseExample" + appointmentlist.appointmentId}>
                                                                                            {

                                                                                                appointmentlist.children.map((child: DashboardStore.AppointmentList) =>
                                                                                                    <React.Fragment>
                                                                                                        <tr style={{ borderLeft: "7px solid rgb(194 184 184)" }}>
                                                                                                            <td>

                                                                                                                {
                                                                                                                    child.statusId == 11 || child.statusId == 9 ?
                                                                                                                        <div>
                                                                                                                            {child.referenceNo ? "" ? "" : child.referenceNo : ""}
                                                                                                                        </div>
                                                                                                                        :
                                                                                                                        <React.Fragment><strong><a className="text-info" onClick={() => this.routeNavVisitDetail(child.encounterId, (child.hummyURL).split("/")[3], child.patientId)}>
                                                                                                                            {child.referenceNo ? child.referenceNo : ""}
                                                                                                                        </a></strong><br /></React.Fragment>
                                                                                                                }
                                                                                                                {
                                                                                                                    child.statusId == 11 && child.isShowPayBtn == false ? <span className={statusColor(child.statusId)}>{languagecontent['paymentexpired']}{/*"Payment Expired"*/}</span> :
                                                                                                                        <span className={statusColor(child.statusId)}>{child.statusId == 11 ? languagecontent['paymentpending'] : child.appointmentStatus}</span>
                                                                                                                }
                                                                                                                <React.Fragment><br /></React.Fragment>

                                                                                                                {
                                                                                                                    child.rescheduleRefId == null ? "" :
                                                                                                                        <span className="text-info">{languagecontent['rescheduled'] == undefined ? "Rescheduled" : languagecontent['rescheduled']}</span>
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>{dateFormat(child.scheduleDate)}<span className="d-block text-info">{Moment(child.scheduleDate).format('LT')} <span className="duration-info">({child.duration} {languagecontent['mins']})</span></span>
                                                                                                                {
                                                                                                                    child.isRefButtonShow == true ?
                                                                                                                        <button className="btn btn-sm bg-primary-light" onClick={() => this.routeNav(child)}>{languagecontent['bookanappointment']}</button>
                                                                                                                        : ""

                                                                                                                }
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <h2 className="table-avatar">
                                                                                                                    <a className="avatar avatar-sm mr-2">
                                                                                                                        {child.photo == null ? <img className="avatar-img rounded-circle" src="client/default/img/blankimg.jpg" alt="User Image" /> : <img className="avatar-img rounded-circle" src={"patient/ImageUrl/member/" + child.doctorId} alt="User Image" />}
                                                                                                                    </a>
                                                                                                                    {child.doctorName == "Not Assigned" ? languagecontent['notassigned'] : child.doctorName}
                                                                                                                </h2>
                                                                                                                {
                                                                                                                    child.isRefButtonShow == true ?
                                                                                                                        <div>
                                                                                                                            <small>{languagecontent['referedto']}</small> <br />
                                                                                                                            <h2>{child.referredTo}</h2>
                                                                                                                        </div> : ""

                                                                                                                }
                                                                                                            </td>
                                                                                                            <td><label className="dashboard-ellipsis">{languagecontent['reasonforappointment']}: {child.reason}</label> <br /> {child.type.split("-", 1)}</td>
                                                                                                            {
                                                                                                                enablefamilymember == "No" ? "" :
                                                                                                                    <td>


                                                                                                                        <label className="dashboard-ellipsis dashboard_label">
                                                                                                                            {
                                                                                                                                languagecontent['self'] == undefined ? <img src="../../../buttonloader.svg" /> :
                                                                                                                                    <React.Fragment>
                                                                                                                                        {child.relationship == "" ? languagecontent['self'] : child.memberName} <br />{child.relationship == "" ? "" : '(' + child.relationship + ')'}
                                                                                                                                    </React.Fragment>
                                                                                                                            }
                                                                                                                        </label>

                                                                                                                    </td>
                                                                                                            }
                                                                                                            <td className="text-right">
                                                                                                                <div className="table-action">{
                                                                                                                    ((child.statusId === 1) || (child.statusId === 2) || (child.statusId === 5) || child.statusId === "In Progress" || child.statusId === "En Progreso" || (appointmentlist.statusId === 7)) && child.type.split("-")[0].trim() == languagecontent['vm_teleconsultation'] ?
                                                                                                                        <a href={child.hummyURL} target="_blank" className="btn btn-sm bg-primary-light">
                                                                                                                            {languagecontent['joincall']}
                                                                                                                        </a> : ""
                                                                                                                }
                                                                                                                    {
                                                                                                                        child.isShowPayBtn == true ?
                                                                                                                            <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.payNow(child)}>
                                                                                                                                {languagecontent['pay'] == undefined ? "Pay" : languagecontent['pay']}
                                                                                                                            </button> : ""
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td className="text-right" style={{ width: "200px" }}></td>
                                                                                                            <td className="text-right" style={{ width: "50px" }}>
                                                                                                                <React.Fragment>
                                                                                                                    {
                                                                                                                        ((child.statusId === 1) || (child.statusId === 9) || (child.statusId === 7)) ?
                                                                                                                            <React.Fragment>
                                                                                                                                <div className="btn-group ml-2 mb-2">
                                                                                                                                    <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fa fa-ellipsis-v" aria-hidden="true"></i></a>

                                                                                                                                    <div className="dropdown-menu" x-placement="top-start">
                                                                                                                                        {
                                                                                                                                            enableappcancel == "Yes" ?
                                                                                                                                                <a className="dropdown-item" onClick={() => this.cancelAppointment(child)}>{languagecontent['cancel'] == undefined ? "Cancel" : languagecontent['cancel']}</a>
                                                                                                                                                : ""
                                                                                                                                        }
                                                                                                                                        {
                                                                                                                                            child.doctorName != 'Not Assigned' && child.rescheduleLimit == true ?
                                                                                                                                                enableappreschedule == "Yes" && child.methodId != 3 && (child.statusId === 1) || (child.statusId === 9) ?
                                                                                                                                                    <a className="dropdown-item" onClick={() => this.rescheduleAppointment(child)}>{languagecontent['reschedule'] == undefined ? "Reschedule" : languagecontent['reschedule']}</a>
                                                                                                                                                    : "" : ""
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </React.Fragment> : ""
                                                                                                                    }
                                                                                                                </React.Fragment>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </React.Fragment>
                                                                                                )

                                                                                            }
                                                                                        </tbody>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </table> :
                                                                            <div className="col-md-12 col-lg-12">
                                                                                <div className="text-center">
                                                                                    <img src="client/default/img/nofound.png" className="img-fluid no-records" alt="User Image" />
                                                                                    <div className="text-center p-4">{languagecontent['norecordsfound'] == undefined ? "No Records Found" : languagecontent['norecordsfound']}</div>
                                                                                </div>
                                                                            </div>

                                                                    }
                                                                </div>
                                                            }
                                                        </div> :
                                                            <DashBoardPageLoading/>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                            </div> 
                                    </div>
                                </div>
                        </div>
                        <CancelAppointmentModel />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.dashboard, ...state.appointmenttypes, ...state.navDefault, ...state.account, ...state.siteDetail, ...state.langResources, ...state.cancelAppointment, ...state.timeZones, ...state.speciality }), // Selects which state properties are merged into the component's props
    ({ ...DashboardStore.actionCreators, ...AppointmentTypeStore.actionCreators, ...AccountStore.actionCreators, ...SiteDetailStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...CancelAppointmentStore.actionCreators, ...TimeZoneStore.actionCreators, ...MemberSpecialityStore.actionCreators }) // Selects which action creators are merged into the component's props
)(FetchData as any);



//function checkDatetime(datetime: any, duration: any) {
//    if (duration == "") {
//        return true;
//    }    
//    else {
//        var appointmentdate: any = add_minutes(new Date(datetime), duration);
//        var currentdate: any = new Date();
//        if (appointmentdate < currentdate)
//            return false;
//        else
//            return true;
//    }
//}
//var add_minutes = function (dt: any, minutes: any) {
//    return new Date(dt.getTime() + minutes * 60000);
//}


function checkDatetime(datetime: any, duration: any, currentdate: any) {

    if (duration == "0") {
        if (datetime >= currentdate) {
            return true
        } else {
            return false
        }
    } else if (duration == "-1") {
        return true
    } else {
        const appointmentDate: any = new Date(datetime);
        const currentDate: any = new Date(currentdate);
        const timeDifferenceInMilliseconds: any = appointmentDate.getTime() - currentDate.getTime();
        const timeDifferenceInMinutes: any = timeDifferenceInMilliseconds / (1000 * 60);
        if (timeDifferenceInMinutes >= duration) {
            return true
        } else {
            return false
        }
    }

    //if (duration == "") {
    //    return true;
    //}
    //else if (duration === "0") {
    //    var appointmentdate: any = add_minutes(new Date(datetime), duration);
    //    var currentdatetime: any = new Date(currentdate);
    //    if (appointmentdate == currentdatetime)
    //        return true;
    //    else
    //        return false;
    //}
    //else if (duration === "-1") {
    //    return true;
    //} else {
    //    var appointmentdate: any = add_minutes(new Date(datetime), duration);
    //    var currentdatetime: any = new Date(currentdate);
    //    if (appointmentdate < currentdatetime) {
    //        return false;
    //    }
    //    else {
    //        return true;
    //    }           
    //}
}
var add_minutes = function (dt: any, minutes: any) {
    return new Date(dt.getTime() - (minutes) * 60000);
}
