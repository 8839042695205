import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { phoneNumber, required, scrollTop, zipCode } from '../../helper/formvalidation';
import { renderAutoComplete, renderDatePicker, renderField, renderSelectField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as ContactStore from '../../store/patient/Contact';
import * as ProfileStore from '../../store/patient/ProfileSettings';
import * as LoginStore from '../../store/account/Login';
import * as HandleRequest from '../../helper/Request';
import { datepickerJS, mobnav, seo } from'../../helper/Script'
import ContactForm from '../widget/ContactForm';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import Moment from 'moment';
import HeaderDrProfile from '../widget/HeaderDrProfile';

type ContactProps =
	ProfileStore.ProfileState & LoginStore.LoginState & ContactStore.ContactState & HandleRequest.Request & DashboardNavStore.DashboardNavState  & typeof DashboardNavStore.actionCreators & typeof LoginStore.actionCreators & typeof ProfileStore.actionCreators & typeof ContactStore.actionCreators &
	RouteComponentProps<{}>;;
class Contact extends React.PureComponent<ContactProps> {
	constructor(props: any) {
		super(props);
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderContact()}
			</React.Fragment>
		);
	}
	componentDidUpdate() {
		seo({
			title: 'My Profile',
			pageTitle: 'Profile',
			description: ''
		});
	
	}
	componentDidMount() {
		datepickerJS();
		mobnav();
		scrollTop();
	}
	public renderContact() {
		return (
			<React.Fragment>
				<div className="content">
					<div className="container">
						<HeaderDrProfile />
						<div className="row">
							<div className="col-md-12">
								<ContactForm />
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}



export default connect(
	(state: ApplicationState) => ({ ...state.dashboardNav, ...state.login, ...state.profile, ...state.contact }), // Selects which state properties are merged into the component's props
	({ ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ProfileStore.actionCreators }) // Selects which action creators are merged into the component's props
)(Contact as any);

