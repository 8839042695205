import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { errorHandling } from '../helper/ErrorHandling';
import { actionCreators as _LangResources } from '../store/LanguageResoures';
export interface MenuHideState {
    isShow: boolean,
}

interface RequestMenuHide {
    type: 'REQUEST_MENU_HIDE';
    isShow: boolean
}

interface ReceiveMenuHide {
    type: 'RECEIVE_MENU_HIDE';
    isShow: boolean
}

type KnownAction = RequestMenuHide | ReceiveMenuHide;

export const actionCreators = {
    requestMenuHide: (data:any): AppThunkAction<KnownAction> => async(dispatch: any, getState: any) => {
        setTimeout(function () {
            dispatch({ type: 'RECEIVE_MENU_HIDE', isShow: data });
        }, 500);
        dispatch({ type: 'REQUEST_MENU_HIDE'});
    }
};

const unloadedState: MenuHideState = {
    isShow: false
};

export const reducer: Reducer<MenuHideState> = (state: MenuHideState | undefined, incomingAction: Action): MenuHideState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MENU_HIDE':
            return {
                isShow: state.isShow,
            };
        case 'RECEIVE_MENU_HIDE':
            if (action.isShow !== state.isShow) {
                return {
                    isShow: action.isShow,
                };
            }
            break;
    }
    return state;
};
