import * as React from 'react';
import {  RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import { ApplicationState } from '../../../../store/index';
import * as SiteMenusStore from '../../../../store/siteaccount/siteMenu';
import * as LanguageResourcesStore from '../../../../store/LanguageResoures';
import Moment from 'moment';
import ExternalLink from "../../../../helper/ExternalLink";

var languagecontent: any = "";
type FooterStoreProps = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & SiteMenusStore.SiteMenuState & LanguageResourcesStore.LanguageResouresState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps;
class FtrDefault extends React.PureComponent<FooterStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        //this.props.requestSiteDetail();
    }
    goNextPage = (url: any) => {
         window.location.href = url;  
    }
    public renderFooter() {
        languagecontent = this.props.LanguageResoures;
        var publicMenus: any = [];
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "2");
        //console.log(publicMenus);
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="footer-bottom">
                        <div className="container-fluid">
                            <div className="copyright">
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="copyright-text">
                                            <p className="mb-0" style={{ wordWrap: "break-word" }}>&copy; {Moment().format('YYYY')} {this.props.siteDetail.Name}, {languagecontent['poweredby']} <a style={{ color: "white"}} href="https://www.drcare247.com/" target="_blank" >DrCare247</a></p>
                                        </div>

                                    </div>
                                    <div className="col-md-6 col-lg-8">
                                        <div className="copyright-text text-right">
                                        {
                                                publicMenus.map((sitemenu: SiteMenusStore.siteMenu, i: any, arr: any) =>
                                                    <React.Fragment>
                                                        {
                                                            <ExternalLink to={sitemenu.Src} key={i}>
                                                                {sitemenu.Title} {i != (arr.length - 1) ? '|' : ''}
                                                            </ExternalLink>
                                                        }
                                                    </React.Fragment>
                                                )
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.siteDetail, ...state.siteMenus, ...state.langResources}), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators, ...SiteMenusStore.actionCreators, ...LanguageResourcesStore.actionCreators  }) // Selects which action creators are merged into the component's props
)(FtrDefault as any);

