import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import { ApplicationState } from '../../../../store/index';
import * as SiteMenusStore from '../../../../store/siteaccount/siteMenu';
import * as LanguageResourcesStore from '../../../../store/LanguageResoures';
import * as HandleRequest from '../../../../helper/Request';
import Moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderTextareaFooterField } from '../../../../helper/renderfield';
import { email, required } from '../../../../helper/formvalidation';
import * as Ftr247DCTStore from'../../../../store/menu/Ftr247DCT'
import { floatingJS, mobnav } from '../../../../helper/Script';


var languagecontent: any = "";
var langdata: any;
var localLayout: any;
type FooterStoreProps = NavDefaultStore.NavDefaultState & Ftr247DCTStore.Ftr247DCTState & HandleRequest.Request & SiteDetailStore.SiteDetailState & SiteMenusStore.SiteMenuState & LanguageResourcesStore.LanguageResouresState & typeof NavDefaultStore.actionCreators & typeof Ftr247DCTStore.actionCreators & typeof SiteDetailStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps;
class Ftr247DCT extends React.PureComponent<FooterStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        mobnav();
        floatingJS();
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        //this.props.requestSiteDetail();
    }
    goNextPage = (url: any) => {
        window.location.href = url;
    }
    goOurDoctor = () => {
        this.props.goNextPage();
    }
    onSubmit = (formValues: any, dispatch: any, props: any) => {
         this.props.requestFtr247DCTSave(formValues)
        console.log(formValues);
    }
    required = (value: string) => {
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        return value || typeof value === 'number' ? undefined : 'required';

    };
    public renderFooter() {
        languagecontent = this.props.LanguageResoures;
        var publicMenus: any = [];
        var formArray: any = [];
        var data: any = window.location.pathname;
        const _array = data.split('/');
        localLayout = localStorage.getItem("siteThemeColor");
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "2");
        //console.log(publicMenus);
        return (
            <React.Fragment>
                {_array[1] != "signup"  ?
                    <footer className="footer pr-footer">
                        <div className="footer-top">
                            <div className="container pr_container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">

                                        <a href="https://247dct.org">
                                            <img width="208" src={this.props.siteDetail.LogoPath} alt="Logo" /></a>
                                        <br /><br /><br/>
                                        <div className="content_lets" style={{ fontSize: "24px", paddingLeft: "5px", color: "#7A7A7A" }}> {languagecontent['letsstayconnected']}</div>
                                        <br/>
                                        <div className="social-icons">
                                            <a href="https://www.facebook.com/247DCT" className="" target="_blank"><i className="fab fa-facebook social-icon"></i></a>
                                            <a>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#5d7580" style={{ marginBottom: "10px",paddingRight:"0px", paddingLeft:"25px"}} height="1.5em" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg>
                                            </a>
                                            <a href=" https://twitter.com/247DCT" target="_blank"><i className="fab fa-twitter social-icon"></i></a>
                                            <a href="https://www.instagram.com/247dct/" target="_blank"><i className="fab fa-instagram social-icon"></i></a>
                                            <a href=" https://www.linkedin.com/company/247dct/" target="_blank"><i className="fab fa-linkedin social-icon"></i></a>
                                        </div>

                                    </div>

                                    <div className="footer-widget footer-menu col-lg-4 col-md-6 col-sm-12">

                                        <span className="useful_links">USEFUL LINKS</span>
                                        <div className="newsletter" style={{ width: "56px", border: "1.4px solid", marginTop: "10px", color:"#40AD76" }}></div>
                                        <nav className="menulinks">

                                            <ul className="links">
                                                {
                                                    publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                                        <li>
                                                            {
                                                                sitemenu.Src == "/ourdoctors" ? <React.Fragment>{formArray.length == 0 ? <a onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : ""}</React.Fragment> : sitemenu.Src == "/centralized" ? <a onClick={() => this.goOurDoctor()}>{sitemenu.Title}</a> : <a href={sitemenu.Src}><i className="fa fa-caret-right" aria-hidden="true"></i> {sitemenu.Title}</a>
                                                            }
                                                        </li>
                                                    )
                                                }
                                            </ul>

                                        </nav>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <span className="newsletter">NEWSLETTER</span>
                                        <div className="newsletter" style={{ width: "56px", border: "1.4px solid", marginTop: "10px", color: "#40AD76"}}></div>
                                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete="off"><div className="fa-ul tarea">
                                            <Field name="Name" className="form-control tarea" component={renderField} label={languagecontent['enteryourname']} maxLength={100} validate={[required]} style={{ borderColor: "#dd74ad" }} type="text" />
                                            <Field name="Email" className="form-control tarea" component={renderField} label={languagecontent['enteryouremail']} maxLength={100} validate={[required, email]} style={{ borderColor: "#dd74ad" }} type="email" />
                                            <Field name="Content" className="form-control tarea" component={renderTextareaFooterField} label={languagecontent['reasonofcontact']} validate={[required]} maxLength={10000} style={{ borderColor: "#dd74ad" }} type="text" />
                                            <br />
                                            <div className="submit-section text-left">
                                                <button type="submit" style={{ marginTop:"30px" }} className="btn btn-secondary sendbutton" disabled={this.props.isLoading}>{this.props.isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['send']}     <span className="fas fa-arrow-right"></span></button>
                                            </div>
                                        </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="footer-bottom">


                            <div className="container-fluid">
                                <div className="copyright">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <div className="copyright-text">
                                                <p className="mb-0 poweredby" style={{ wordWrap: "break-word" }}>&copy; {Moment().format('YYYY')} {this.props.siteDetail.Name}, {languagecontent['poweredby']} <a style={{ color: "#000000" }} href="https://www.drcare247.com/" target="_blank" >DrCare247</a></p>
                                            </div>

                                        </div>
                                        {/*<div className="col-md-6 col-lg-8">*/}
                                        {/*    <div className="copyright-text text-right">*/}
                                        {/*        {*/}
                                        {/*            publicMenus.map((sitemenu: SiteMenusStore.siteMenu, i: any, arr: any) =>*/}
                                        {/*                <React.Fragment>*/}
                                        {/*                    {*/}
                                        {/*                        <ExternalLink to={sitemenu.Src} key={i}>*/}
                                        {/*                            {sitemenu.Title} {i != (arr.length - 1) ? '|' : ''}*/}
                                        {/*                        </ExternalLink>*/}
                                        {/*                    }*/}
                                        {/*                </React.Fragment>*/}
                                        {/*            )*/}
                                        {/*        }*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer> : ""}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({ ...state.navDefault, ...state.ftr247dct, ...state.siteDetail, ...state.siteMenus, ...state.langResources }); // Selects which state properties are merged into the component's props
const mapDispatchToProps = ({ ...NavDefaultStore.actionCreators, ...Ftr247DCTStore.actionCreators, ...SiteDetailStore.actionCreators, ...SiteMenusStore.actionCreators, ...LanguageResourcesStore.actionCreators }) // Selects which action creators are merged into the component's props
export default reduxForm({
    form: 'newsletter',
})(connect(mapStateToProps, mapDispatchToProps)(Ftr247DCT as any))

