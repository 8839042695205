import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { Patient } from '../appointment/Condition';
import * as HandleRequest from '../../helper/Request';
import { push } from 'connected-react-router';
import { actionCreators as _NavDefault } from '../../store/menu/NavDefault';
import { listConvertParentChild } from '../../helper/formvalidation';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);
export interface MessageState {
    isMessageLoading: boolean;
    startDateIndex?: number;
    messages: MessageList[];
}

export interface MessageList {
    id: string;
    name: string;
    subject: string;
    content: string;
    modifiedOn: string;
    fromMemberId: string;
    toMemberId: string;    
    photoUrl: string;    
    msgType: string;
    isRead: boolean;
    notificationCount: string;
    healthCareProviderPhoto: string;
    healthCareProviderName: any;
    healthCareProviderMemberId: any;
}



// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestMessageListAction {
    type: 'REQUEST_MESSAGE_LIST';
    startDateIndex: number;
    messages: MessageList[]
}

interface ReceiveMessageListAction {
    type: 'RECEIVE_MESSAGE_LIST';
    startDateIndex: number;
    messages: MessageList[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestMessageListAction | ReceiveMessageListAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestMessageList: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        dispatch(showLoading('sectionBar'));
        if (appState && appState.messages) {
            var accountid: any = localStorage.getItem("AccountId");           
              axios.get('/message/GetMessageLists/' + accountid + "/" + patient.memberId)
                 .then(function (response) {
                     const result = response.data;
                     dispatch({ type: 'RECEIVE_MESSAGE_LIST', messages: result });
                     dispatch(hideLoading('sectionBar'));
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 401) {
                        localStorage.setItem("isAuth", "false");
                        console.log("message ts")
                        localStorage.removeItem("Patient");
                        //localStorage.removeItem("isAuth");
                        localStorage.removeItem("pathName");
                        localStorage.removeItem("redirectUrl");
                        localStorage.removeItem("EmailId");
                        localStorage.removeItem("redirectQequestQueueUrl");
                        localStorage.removeItem("Appointment");
                        localStorage.removeItem("ProfileData");
                        window.location.href = "/login";
                    } else {
                        errorHandling(error);
                    }
                })
                .then(function () {
                    // always executed
                });
        }
        dispatch({ type: 'REQUEST_MESSAGE_LIST' });
    },

    requestMessageDisplay: (id: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        dispatch(push("/replymessage/" + "?id=" + id))

       /* window.location.reload()*/
    },
    
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MessageState = { messages: [], isMessageLoading: false };

export const reducer: Reducer<MessageState> = (state: MessageState | undefined, incomingAction: Action): MessageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_MESSAGE_LIST':
            return {
                messages: state.messages,
                isMessageLoading: true
            };
        case 'RECEIVE_MESSAGE_LIST':
            return {
                messages: action.messages,
                isMessageLoading: false
            };
        default:
            return state;
    }
};


