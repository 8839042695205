import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { dateFormat, phoneNumber, required, zipCode } from '../../helper/formvalidation';
import { renderAutoComplete, renderDatePicker, renderField, renderSelectField, renderDob } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import ReactCrop from 'react-image-crop';
import * as ContactStore from '../../store/patient/Contact';
import * as HandleRequest from '../../helper/Request';
import * as LoginStore from '../../store/account/Login';
import * as LocationStore from '../../store/location/location';
import * as DashboardNavMenuStore from '../../store/menu/DashboardNav';
import { datepickerJS, floatingJS, floatJS, dobcheck } from '../../helper/Script';
import * as DashboardNavStore from '../../store/menu/DashboardNav';
import 'react-image-crop/dist/ReactCrop.css';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
var patientLocalStroage: any = localStorage.getItem('Patient');
var patientdata = JSON.parse(patientLocalStroage);


type ContactProps =
	ContactStore.ContactState & LoginStore.LoginState & HandleRequest.Request & LoginStore.LoginState & DashboardNavMenuStore.DashboardNavState & LocationStore.LocationState &
	typeof ContactStore.actionCreators & typeof LoginStore.actionCreators & typeof DashboardNavMenuStore.actionCreators & typeof LocationStore.actionCreators &
	RouteComponentProps<{}>;
var locationid = "";
var reader = new FileReader();
var patient = {};
var locationCheck = "";
var dobchk = "";
class MemberForm extends React.PureComponent<ContactProps> {
	constructor(props: any) {
		super(props);
	}
	onSubmit = (formValues: any, dispatch: any, props: any) => {
		/*if (locationid == "") {
			return toastr.error('', 'Please select valid city/state');
		}*/


		if (this.props.Locations.length > 0) {
			const filetypeFilter = this.props.Locations.filter(function (value) {
				if (value.Description != null || value.Description == "") {
					var test = value.Name + "," + value.Description
				}
				else {
					var test = value.Name
				}


				return test.replace(/,| /g, '') === formValues.locationName.replace(/,| /g, '')
			});
			filetypeFilter.forEach(value => {
				locationCheck = value.Name;
			})
		}
		else {
			this.props.requestLocation(formValues.locationName);
			if (locationid != "") {
				if (locationid == patientdata.locationId) {
					if (patientdata.locationName.replace(/,| /g, '') == formValues.locationName.replace(/,| /g, ''))
						locationCheck = locationid;
					else
						locationCheck = "";
				}

				else if (patientdata.locationName.replace(/,| /g, '') == formValues.locationName.replace(/,| /g, '')) {
					locationCheck = patientdata.locationName;
				}

				else if (locationid != patientdata.locationId) {
					locationCheck = locationid;
				}

				else {
					locationCheck = "";
				}


			}
			else {
				locationCheck = "";
			}

		}

		let dob: HTMLElement = document.getElementsByName('dob')[0] as HTMLElement;
		dob.style.color = "white";
		if (locationCheck == "") {
			return toastr.error('', 'Please select valid city/state');
		}
		else {
			this.props.requestSaveContact(formValues, this.props.contact, locationid);

		}
		this.props.requestPatient();
		setTimeout(function () {
			dob.style.color = "#333";
		}, 1500);
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderContact()}
			</React.Fragment>
		);
	}

	public _handleSearch = (query: any) => {
		this.props.requestLocation(query);
	}
	public _handleClickSearch = (query: any) => {
		this.props.requestLocation(query);
	}
	public _handleOnFocus = (event: Event) => {
		var data: any = event.target;
		this.props.requestLocation(data.defaultValue.substring(0, 4))
	}
	public _handleOnChange = (selected: any) => {

		selected.map((value: any) => {
			locationid = value.Id;
			this.props.requestLocation(value.Name.substring(0, 3));
		})
		if (selected) {
			let zipcode: HTMLElement = document.getElementsByName('zipcode')[0] as HTMLElement;
			zipcode.focus();
		}
	}

	
	private ensureDataFetched() {
		this.props.requestPatient();
		dobcheck(dateFormat(this.props.contact.dob), dobchk);
		if (patientdata.locationName != "")
			this.props.requestLocation(patientdata.locationName.substring(0, 4))
	}
	componentDidMount() {
		this.ensureDataFetched();
		const script = document.createElement("script");
		script.src = "/Theme/Doccure/admin/assets/js/script.js";
		script.async = true;
		document.body.appendChild(script);
		this.props.requestPatient();
		datepickerJS();
		floatingJS();
		dobchk = "";
		dobcheck(dateFormat(this.props.contact.dob), dobchk);
		if (patientdata.locationName != "")
			this.props.requestLocation(patientdata.locationName.substring(0, 4))
	}
	componentDidUpdate() {
		floatJS();

		if (patientdata['dob'] != null && dobchk == "") {
			dobcheck(dateFormat(this.props.contact.dob), dobchk);
			dobchk = "checked";
		}

		//this.props.requestLocation(this.props.contact.locationName);
	}
	public renderContact() {
		var langResources = JSON.parse(localStorage.getItem("LangResources") || "");
		var signup: any = langResources.filter((la: any) => (la.Group == "signup"));
		var profile: any = langResources.filter((la: any) => (la.Group == "profile"));
		var profile_settings: any = langResources.filter((la: any) => (la.Group == "profile_settings"));
		var data = window.location.pathname;
		const _array = data.split('/');
		const Locations = this.props.Locations;

		const isLoading = this.props.isLoading;
		const onSearch = this._handleSearch;
		const onClickMethod = this._handleClickSearch;
		const onChangeMethod = this._handleOnChange;
		const onFocusMethod = this._handleOnFocus;
		const bloodgroup = [{ value: '', name: 'Select' }, { value: 'A-', name: 'A-' }, { value: 'A+', name: 'A+' }, { value: 'B-', name: 'B+' }, { value: 'AB-', name: 'AB+' }, { value: 'O-', name: 'O+' },];
		const gender = [{ value: '', name: 'Select' }, { value: 'Male', name: 'Male' }, { value: 'Female', name: 'Female' }];
		if (this.props.contact.gender == "M")
			this.props.contact.gender = "Male";
		else if (this.props.contact.gender == "F")
			this.props.contact.gender = "Female";

		if (Moment(this.props.contact.dob).format('MM/DD/YYYY') == "Invalid date")
			this.props.contact.dob = "";
		else
			this.props.contact.dob = dateFormat(this.props.contact.dob);
		const localLocation = this.props.contact.locationName == "" || this.props.contact.locationName == null ? patientdata.locationName : this.props.contact.locationName;
		patient = this.props.contact;
		return (
			<React.Fragment>
				<form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>

					<div className="row form-row">
						{
							_array[1] == "profilesettings" ?
								<React.Fragment>
									<div className="col-12 col-md-6">
										<div className={this.props.contact.firstName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
											<Field name="firstName" component={renderField} label={signup[0].Content} maxLength={50} validate={[required]} type="text" />
										</div>
									</div>
									<div className="col-12 col-md-6">
										<div className={this.props.contact.lastName.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
											<Field name="lastName" component={renderField} label={signup[1].Content} maxLength={50} validate={[required]} type="text" />
										</div>
									</div>
								</React.Fragment> : ""

						}
						<div className="col-12 col-md-6">
							<div className={this.props.contact.gender.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
								<Field name="gender" options={gender} component={renderSelectField} validate={[required]} className="form-control" />
								<label className="focus-label">{profile[0].Content}</label>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className={this.props.contact.dob.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
								<div className="cal-icon">

									{this.props.contact ?
										<Field name="dob" class1={this.props.contact.dob.length ? "dval" : ""} component={renderDob} label={profile[1].Content} maxLength={15} validate={[required]} type="text" />
										: <Field name="dob" component={renderField} label="Date of Birth" maxLength={15} validate={[required]} type="text" />
									}
								</div>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className={this.props.contact.cellPhone.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
								<Field name="cellPhone" component={renderField} label="Cell Phone" validate={[required, phoneNumber]} maxLength={10} minLength={10} type="text" />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className={this.props.contact.address1.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
								<Field name="address1" component={renderField} label="Address" validate={[required]} maxLength={100} type="text" />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<Field name="locationName" Location={this.props.Locations} autoCompleteData={localLocation} isLoading={isLoading} onSearch={onSearch} onChangeMethod={onChangeMethod} onClickMethod={this._handleClickSearch} component={renderAutoComplete} label="City/State" validate={[required]} type="text" />
						</div>



						<div className="col-12 col-md-6">
							<div className={this.props.contact.zipcode.length > 0 ? "form-group form-focus focused" : "form-group form-focus"}>
								<Field name="zipcode" component={renderField} label="Postal Code" maxLength={7} validate={[required, zipCode]} type="text" />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-12">
							<div className="form-group form-focus">
								<button className={_array[1] == "profilesettings" ? "btn btn-lg btn-primary float-left" : "btn btn-lg btn-primary float-right"} type="submit" disabled={this.props.isContactLoading} >  {_array[1] == "profilesettings" ? "Submit" : "Proceed"} </button>
							</div>
						</div>
					</div>
				</form>
			</React.Fragment>
		);
	}
}
const mapDispatchToProps = { ...DashboardNavStore.actionCreators, ...LoginStore.actionCreators, ...ContactStore.actionCreators, ...DashboardNavMenuStore.actionCreators, ...LocationStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => {

	let values = {
		initialValues: patient,
		...state.contact, ...state.login, ...state.dashboardNav, ...state.dashboardNav, ...state.location
	};
	return values;

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
	form: 'memberForm',
	enableReinitialize: true,
})(MemberForm as any))





