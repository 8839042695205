import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store/index';
import * as  documentStore from '../../../store/library/document/DocumentUpload';
import { renderIdSelectField, renderTextareaField } from '../../../helper/renderfield';
import { required } from '../../../helper/formvalidation';
import * as  BookingStore from '../../../store/patient/Booking';
import * as ConditionStore from '../../../store/appointment/Condition';
import * as DocumentListStore from '../../../store/library/document/DocumentList';
import * as LoginStore from '../../../store/account/Login';
import * as HandleRequest from '../../../helper/Request';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toastr } from 'react-redux-toastr';
import { formatBytes, focus, ModalClose, displayControl, handleReset } from '../../../helper/Script';
import * as  CategoryStore from '../../../store/siteaccount/Category';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';
import LoadingBar from 'react-redux-loading-bar';
// At runtime, Redux will merge together...
type DocumentUploadProps =
	ConditionStore.ConditionState & LoginStore.LoginState & documentStore.DocumentUploadState & BookingStore.BookingState & DocumentListStore.DocumentListsState & HandleRequest.Request & DocumentListStore.DocumentListsState & CategoryStore.CategoryState & LanguageResourcesStore.LanguageResouresState
	& typeof ConditionStore.actionCreators & typeof LoginStore.actionCreators & typeof documentStore.actionCreators & typeof BookingStore.actionCreators & typeof DocumentListStore.actionCreators & typeof CategoryStore.actionCreators & typeof LanguageResourcesStore.actionCreators & RouteComponentProps<{}>; // ... plus action creators we've requested
var el = window.document.getElementsByClassName("thumblist");
var dropzoneFile: any = [];
const initialState: any = { type: "", Note: "" }
var filesize: any = {};
var filetype: any = "";
var filetypecheck: any = [];
var languagecontent: any;
const maxSize = 1e+7;
const baseStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	transition: 'border .3s ease-in-out',
	innerHeight: '500px',
};

const activeStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};
const previewImage = {
	width: '50px',
	height: '50px',
};
const uploaddoc_btn = {
	width: '200px',

};

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
};

const thumbs1 = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginTop: 8,
	marginBottom: 8,
	width: 40,
	height: 40,
	padding: 4,
	verticalAlign: 'middle'

};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};
const btn = {
	borderRadius: '10px',
	backgroundColor: 'transparent',
	color: '#8f1919',
	borderColor: 'red',
	marginLeft: 10,
	fontWeight: 'bolder',
	padding: 'revert'

} as React.CSSProperties;
const docPreview = {
	color: "rgb(21, 85, 141)"
} as React.CSSProperties;
setTimeout(function () {
	var myForm: any = window.document.getElementById("dInsurance");
	if (myForm != null)
		myForm.style.display = "none";
}, 2000);
const DropzoneComponent = (props: any) => {

	const [files, setFiles] = useState(dropzoneFile);
	const ToBase64 = (file: any) => new Promise((resolve, reject) => {
		const reader = new FileReader;
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(file);
	});
	const onDrop = useCallback(acceptedFiles => {
		var myLoading: any = window.document.getElementById("dLoading");
		var dProgressbar: any = window.document.getElementById("dProgressbar");
		if (myLoading != null)
			myLoading.style.display = "block";
		if (dProgressbar != null) {
			dProgressbar.style.width = "50%";
			dProgressbar.style.height = "2px";
			dProgressbar.style.backgroundColor = "var(--primary-color)"
			displayControl(el, 'block');
		}
		setTimeout(function () {
			if (dProgressbar != null) {
				dProgressbar.style.width = "75%";
				dProgressbar.style.height = "2px";
				dProgressbar.style.backgroundColor = "var(--primary-color)"
			}
		}, 1000);
		setTimeout(function () {
			if (dProgressbar != null) {
				dProgressbar.style.width = "100%";
				dProgressbar.style.height = "2px";
				dProgressbar.style.backgroundColor = "var(--primary-color)"
			}
			setTimeout(function () {
				if (myLoading != null)
					myLoading.style.display = "none";
			}, 1000);
		}, 2000);
		acceptedFiles.map((file: any) => (Object as any).assign(file, dropzoneFile.unshift(file)))

		setFiles(dropzoneFile.map((file: any) => (Object as any).assign(file, {

			content: ToBase64(file),
			preview: URL.createObjectURL(file)
		}))

		);
	}, []);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		open,
		acceptedFiles,

	} = useDropzone({
		onDrop,
		//accept: "image/*, application/pdf, application/msword, application/zip, application/x-zip-compressed, multipart/x-zip, application/docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel",
		/*minSize: 0,*/
		/*maxSize: maxSize,*/

	});


	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject,
		isDragAccept
	]) as React.CSSProperties;



	const removeFile = (file: never) => () => {
		const newFiles = [...files]
		newFiles.splice(newFiles.indexOf(file), 1)
		setFiles(newFiles)
		dropzoneFile = newFiles;
	}

	const thumbs = files.map((file: any) => (


		<div key={file.name} className="thumblist" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
			<div style={thumbs1}>
				<div style={thumbInner}>

					{(file.type == "image/jpeg" || file.type == "image/gif" || file.type == "image/png" || file.type == "image/jpg" || file.type == "image/pdf") && props.isLoading != true ? <img
						src={file.preview}
						style={img}
					/> : <i className="fas fa-2x fa-file" aria-hidden="true" style={docPreview} ></i>}
				</div>

			</div>
			<div className="text-truncate">
			{file.name}- {formatBytes(file.size)}
			</div>
			<button style={btn} type="button" onClick={removeFile(file as never)}  ><span >X</span></button>

		</div>

	));

	/*useEffect(() => () => {
		files.forEach((file: any) => URL.revokeObjectURL(file.preview));
	}, [files],
	);*/

	return (
		<section>
			<div className="upload-img d-flex boder m-3">
				<p style={{ padding: "10px", paddingBottom: "0px", marginBottom: "0px", paddingTop: "0px" }}>{languagecontent['draganddrop']}</p>
				<div className="form-group" style={{ marginBottom: "0px" }} >
					<button type="button" className="form-control uploadform-btn " style={uploaddoc_btn} onClick={open}>
						{languagecontent['choosefiles']}
					</button>
				</div>
			</div>
			<div className="form-group doc-module-upload">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />

					{isDragReject ?
						<div className="text-danger mt-2">
							File not supported.
						</div> : isDragActive && !isDragReject ? <div>Uplooding.</div> : <div>{languagecontent['draganddropimage']}</div>
					}
				</div>
				<aside >
					{thumbs}
				</aside>

			</div>
		</section>
	)
};
class document extends React.PureComponent<DocumentUploadProps> {
	// This method is called when the component is first added to the document
	constructor(props: any) {
		super(props);
		this.escFunction = this.escFunction.bind(this);

	}
	escFunction(event: any) {
		if (event.keyCode === 27) {
			let element: HTMLElement = window.document.getElementsByClassName('close')[0] as HTMLElement;
			element.click();
		}
	}
	closeModal = () => {
		dropzoneFile = [];
		var el = window.document.getElementsByClassName("thumblist");
		displayControl(el, 'none');
		handleReset();
		ModalClose();
	}
	onSubmit = (formValues: any) => {

		const filesizeFilter = this.props.DocumentSettings.filter(function (value) {
			return value.name === "FileSize"
		});
		filesizeFilter.forEach(value => {
			filesize = value.value
		})
		const filetypeFilter = this.props.DocumentSettings.filter(function (value) {
			return value.name === "FileType"
		});
		filetypeFilter.forEach(value => {
			filetype = value.value;
			
		})		
		let data: string = filetype;
		let stringArray: string[] = data.split(',');

		let elementsToRemove: string[] = ['mp4', 'mp3', 'wav'];
		let filteredArray: string[] = stringArray.filter((element: string) => {

			for (let i = 0; i < elementsToRemove.length; i++) {
				if (element === elementsToRemove[i]) {
					return false;
				}
			}

			return true;
		});

		let result: string = filteredArray.join(',');

		const maxSize = filesize;
		
		let filetypes: any = [];

		let answ = result.split(',');
		answ.forEach(function (obj: any) {
			filetypes.push(obj);
		});		
		filetypecheck = [];
		if (dropzoneFile.length > 0) {
			(this.refs.btn as HTMLButtonElement).setAttribute("disabled", "disabled");
			dropzoneFile.forEach((file: any, index: any) => {

				if (filetypes.some((filetype: any) => filetype === (file.name).split('.').pop().toLowerCase())) {
					filetypecheck.push("true")
				}
				else {
					filetypecheck.push("false")
				}

				if (filetypecheck.indexOf("false") >= 0) {
					localStorage.setItem("Format", "false")
				}
				/*if (file.type == "image/gif" || file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf" || file.type == "application/msword" || file.type == "text/plain" || file.type == "application/docx") {
					localStorage.setItem("Format", "true");
				}*/
				else {
					localStorage.setItem("Format", "true");
				}
				if (file.size > maxSize) {
					localStorage.setItem("Exceed", "true")
				}
				else {
					localStorage.setItem("Accepted", "true")
				}
			});

			if (localStorage.getItem("Exceed") && localStorage.getItem("Exceed") == "true") {
				langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
				toastr.error('', langdata['maximumfilesizeexceed']);
				(this.refs.btn as HTMLButtonElement).removeAttribute("disabled");
				localStorage.removeItem("Exceed")

			}
			else if (localStorage.getItem("Format") && localStorage.getItem("Format") == "false") {
				langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
				toastr.error('', langdata['allowedfiletypes'] + ' ' + 'png, jpg, jpeg, gif, pdf' + '. ' + langdata['pleasecheckthefiletypeandupload']);
				(this.refs.btn as HTMLButtonElement).removeAttribute("disabled");
				localStorage.removeItem("Format")
			}
			else {
				this.props.requestSaveDocument(formValues, this.props.patient, dropzoneFile, "Patient Document");
				setTimeout(() => {
					
					/*this.props.requestDocumentLists(this.props.patient.memberId, "Patient Document");*/

					dropzoneFile = [];
					localStorage.removeItem("Exceed");
					localStorage.removeItem("Accepted");
					localStorage.removeItem("Format");
				}, 5000);

			}
		}
		else {
			var langdata: any;
			if (localStorage.getItem("LangResourcesValidations") != null)
				langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
			toastr.error('', langdata['pleaseselctfile']);
			(this.refs.btn as HTMLButtonElement).removeAttribute("disabled");
		}
	}
	public componentDidMount() {
		const script = window.document.createElement("script");
		script.src = "/Theme/Doccure/assets/js/script.js";
		script.async = true;
		window.document.body.appendChild(script);
		this.ensureDataFetched();
		focus();
		this.props.getCategory();
		this.props.requestSettingDocument("Patient Document")
		window.document.addEventListener("keydown", this.escFunction, false);

	}

	public render() {

		return (
			<React.Fragment>
				{this.uploadDocument()}
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		var data = window.location.pathname;
		const _array = data.split('/');
		//this.props.requestSettingDocument("Patient Document")
	}


	private uploadDocument() {
		languagecontent = this.props.LanguageResoures;
		var data: any = window.location.pathname;
		var type: any = [];
		const _array = data.split('/');
		if (_array[1] == "addinsurance") {
			type = this.props.categories.filter(c => c.group == "Insurance Doc Type");
		}
		else if (_array[1] == "insurance") {
			type = this.props.categories.filter(c => c.group == "Insurance Doc Type");
		}
		else {
			type = this.props.categories.filter(c => c.group == "Patient Doc Type");
		}
		
		const array = {
			"id": "",
			"value": "",
			"name": languagecontent['documentselect'],
			"group": " "
		};
		type.unshift(array);
		if (this.props.DocumentSettings) {
			const filetypeFilter = this.props.DocumentSettings.filter(function (value) {
				return value.name === "FileType"
			});
			filetypeFilter.forEach(value => {
				filetype = value.value
			})
			const filesizeFilter = this.props.DocumentSettings.filter(function (value) {
				return value.name === "FileSize"
			});
			filesizeFilter.forEach(value => {
				filesize = value.value
			})
		}
		return (
			<React.Fragment>
			<div className="modal fade" data-backdrop="static" id="myModal" aria-hidden="true" role="dialog" data-keyboard="true">
				<div className="modal-dialog modal-dialog-centered" role="document" >
					<div className="modal-content">

						<div className="modal-header">
							<h5 className="modal-title">{languagecontent['documentupload']}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate id="document_upload" autoComplete={"off"}>
								<div className="typefield form-group form-focus">

									<Field name="type" id="type" options={type} component={renderIdSelectField} label={languagecontent['documenttype']} className="form-control" />

								</div>
								<div className="form-group form-focus">
									<Field name="Note" component={renderTextareaField} label={languagecontent['documentnote']} maxLength={500} type="text" id="document_uploadNote" />
								</div>
								<LoadingBar style={{ backgroundColor: 'var(--primary-color)', height: '2px' }} className="loading" updateTime={100} maxProgress={90} progressIncrease={10} scope="sectionBar" />

								<div className="doc-upload-body">
									<div className="progress" id="dLoading" style={{ height:"2px" }}>
										<div className="progress-bar" id="dProgressbar" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={ 100}></div>
									</div>
									<DropzoneComponent />

								</div>
								<small className="text-secondary">{languagecontent['allowedfile']} png, jpg, jpeg, gif, pdf{/*{filetype}*/}</small><br />
								<small className="text-secondary">{languagecontent['maximumfile']} {formatBytes(filesize)}</small>
								<div className="submit-section text-right">
									<button ref="btn" className="btn btn-primary submit-btn" disabled={this.props.isLoading} >{this.props.isLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['upload']}</button>
								</div>
							</form>
						</div>

					</div>
				</div>
				</div>
			</React.Fragment>
		);
	}
}


const mapDispatchToProps = ({ ...documentStore.actionCreators, ...ConditionStore.actionCreators, ...LoginStore.actionCreators, ...DocumentListStore.actionCreators, ...CategoryStore.actionCreators, ...LanguageResourcesStore.actionCreators });

const mapStateToProps = (state: ApplicationState) => ({ ...state.condition, ...state.login, ...state.documentupload, ...state.documentlist, ...state.category, ...state.langResources });
export default reduxForm({
	form: 'document',
})(connect(mapStateToProps, mapDispatchToProps)(document as any))



