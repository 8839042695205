import * as React from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Account from '../../../store/siteaccount/Account';
import { connect } from 'react-redux';
import ReactGA from "react-ga4";
import { ApplicationState } from '../../../store/index';

type Props = Account.AccountState & typeof Account.actionCreators& RouteComponentProps;
var googleanalyticscode: any = "";
class GoogleAnalytics extends React.PureComponent<Props> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }

    public componentDidUpdate(prevProps: Props) {
        this.ensureDataFetched();
    }

    private ensureDataFetched() {
      
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderGoogleAnalytics()}
            </React.Fragment>
        );
    }
    public renderGoogleAnalytics() {
        if (this.props.Settings.length != 0) {
            googleanalyticscode = this.props.Settings.filter((se: any) => se.name.toLowerCase() == ("Google Analytics Code").toLowerCase())[0].customizeValue;

            if (googleanalyticscode != null) {
                ReactGA.initialize(googleanalyticscode);
                ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname });
                ReactGA.event({
                    category: 'Patient',
                    action: 'View',

                });
            }
        }
           
        
        //console.log(googleanalyticscode)
        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = { ...Account.actionCreators };
const mapStateToProps = (state: ApplicationState) => {

    let values = {
        
        ...state.account
    };
    return values;

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleAnalytics as any));
