import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { actionCreators as _insuranceListAction } from '../../store/insurance/insuranceList';
import { actionCreators as _documentAction } from '../library/document/DocumentList';
import { convertYeartoDate, datePostFormat, dateFormatSetting } from '../../helper/formvalidation';
import { platform } from 'process';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface AddInsuranceState {
    isPostLoading: boolean,
    isListLoading: boolean,
    addInsurance: AddInsurance,
  
}



export interface AddInsurance {
    Id: string;
    MemberId: string,
    Level: string,
    InsuranceId: string,
    InsuredName: string,
    InsuredDOB: string,
    PolicyNo: string,
    GroupNo: string,
    PlanName: any,
    PlanCode: string,
    ExpiryDate: string,
    StatusId: string
    DocIds: string;
    InsuranceName: string;
    VerificationStatusId: string;
    VerificationStatus: string;
    PlatformType: string;
    PatientId: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestAddInsuranceAction { type: 'REQUEST_ADD_INSURANCE', addInsurance: AddInsurance, isPostLoading: boolean, isListLoading: boolean}
export interface ReciveAddInsuranceAction { type: 'RECEIVE_ADD_INSURANCE', addInsurance: AddInsurance, isPostLoading: boolean, isListLoading: boolean }

 
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestAddInsuranceAction | ReciveAddInsuranceAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    postInsurance: (result: any, type:any,statusId:any): AppThunkAction<KnownAction> => (dispatch: any, getState:any) => {
        const urlParams = new URLSearchParams(window.location.search);
        var memberid:any = urlParams.get('memberid');
        var patientid: any = "";
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient:any = JSON.parse(patientLocalStroage);
        if (memberid == null) {
            memberid = patient.memberId;
        }
        patientid = patient.id;

        var accountid: any = localStorage.getItem("AccountId");
        var dob = null;
        var _expirydate = null;
       
        var data: any = "";
        if (type == "delete") {
            data = {
                Id: result.Id == "" ? null : result.Id,
                MemberId: memberid,
                Level: result.Level,
                InsuranceId: result.InsuranceId == "" ? null : result.InsuranceId,
                InsuredName: result.InsuredName,
                InsuranceName: result.InsuranceName,
                InsuredDOB: result.InsuredDOB,
                PolicyNo: result.PolicyNo,
                GroupNo: result.GroupNo,
                PlanName: result.PlanName,
                PlanCode: result.PlanCode,
                ExpiryDate: result.ExpiryDate,
                StatusId: statusId ? statusId : result.StatusId,                       
                DocIds: result.DocIds,
                AccountId: accountid,              
                VerificationStatusId: result.VerificationStatusId,
                VerificationStatus: result.VerificationStatus,
                PlatformType: result.PlatformType,
                PatientId: patientid,
            };
        } else {
            if (result.InsuredDOB != null) {
                if (result.InsuredDOB != "") {
                    dob = convertYeartoDate(result.InsuredDOB);
                }
            } else {
                dob = null;
            }
            if (result.ExpiryDate != null) {
                if (result.ExpiryDate != "") {
                    _expirydate = convertYeartoDate(result.ExpiryDate);
                }
            } else {
                _expirydate = null;
            }
            data = {
                Id: result.Id == "" ? null : result.Id,
                MemberId: memberid,
                Level: result.Level,
                InsuranceId: result.InsuranceId == "" ? null : result.InsuranceId,
                InsuredName: result.InsuredName,
                InsuranceName: result.InsuranceName,
                InsuredDOB: dob,
                PolicyNo: result.PolicyNo,
                GroupNo: result.GroupNo,
                PlanName: result.PlanName,
                PlanCode: result.PlanCode,
                ExpiryDate: _expirydate,
                StatusId: statusId ? statusId : result.StatusId,
                DocIds: result.DocIds,
                AccountId: accountid,              
                VerificationStatusId: result.VerificationStatusId,
                VerificationStatus: result.VerificationStatus,
                PlatformType: result.PlatformType,
                PatientId: patientid,
            };
        }
        
        axios.post('Insurance/InsuranceSave', data)
            .then(response => {
                const result = response.data;
                dispatch(reset("addinsurence"));
                dispatch(hideLoading('sectionBar'))
                const urlParams: any = new URLSearchParams(window.location.search);
                var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                
                var insurancepath = localStorage.getItem("InsurancePath");
                 toastr.success('', langdata['successfuly'])
             
                dispatch(reset("addInsurance"));
                setTimeout(function () {
                    var data: any = window.location.pathname;
                    const _array = data.split('/');
                    if (_array[1] == "addinsurance" && insurancepath != null) {
                        window.location.href = insurancepath
                        //dispatch(push(insurancepath));
                        //dispatch(push("/insurancelist" + window.location.search));
                    }
                    else if (_array[1] == "addinsurance" && insurancepath == null) {
                        //window.location.href = insurancepath
                        //dispatch(push(insurancepath));
                        dispatch(push("/insurancelist" + window.location.search));
                    }
                    else if (_array[1] == "insurancelist") {
                        dispatch(push("/insurancelist" + window.location.search));
                    }
                    else {
                        dispatch(push("/insurance/list?memberid=" + memberid));
                    }
                }, 10);

                //dispatch(actionCreators.getSingleInsurance(response.data))
                dispatch(_insuranceListAction.getInsuranceList(memberid))
                //dispatch(_documentAction.requestDocumentLists(memberid, "Patient Document"))
            })
            .catch(error => {
                if (error.response.data.statusCode == 400) {
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    if (errorObject.Message == "SqlDateTime overflow. Must be between 1/1/1753 12:00:00 AM and 12/31/9999 11:59:59 PM.") {
                        toastr.error('', "Invalid date");
                    }
                    else {
                        setTimeout(function () {
                            const queryParams = new URLSearchParams(window.location.search);
                            var data: any = window.location.pathname;
                            var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                            var insurancepath = localStorage.getItem("InsurancePath");
                            const _array = data.split('/');
                            if (_array[1] == "addinsurance" && insurancepath != null) {
                                window.location.href = insurancepath
                                //dispatch(push(insurancepath));
                                //dispatch(push("/insurancelist" + window.location.search))
                                if (errorObject.Message == "No Changes") {
                                    toastr.success('', langdata['nochanges'])
                                } else {
                                    toastr.error('', errorObject.Message);
                                }
                            }
                            else if (_array[1] == "addinsurance" && insurancepath == null) {
                                //window.location.href = insurancepath
                                //dispatch(push(insurancepath));
                                dispatch(push("/insurancelist" + window.location.search));
                                if (errorObject.Message == "Existing insurance policy is already active")
                                    toastr.error('', langdata['existinginsurancepolicyisalreadyactive']);
                            }else if (_array[1] == "insurancelist") {
                                dispatch(push("/insurancelist" + window.location.search));
                            } else {
                                dispatch(push("/insurance/list?memberid=" + memberid));
                                if (errorObject.Message == "Existing insurance policy is already active")
                                    toastr.error('', langdata['existinginsurancepolicyisalreadyactive']);
                                else if (errorObject.Message == "No Changes") {
                                    toastr.success('', langdata['nochanges'])                                   
                                }
                                else
                                toastr.error('', errorObject.Message);
                            }
                        }, 1000);
                    }
                } else {
                    errorHandling(error.response.data.statusCode);
                }
                dispatch(hideLoading('sectionBar'))
            });
        dispatch({ type: 'REQUEST_ADD_INSURANCE', isPostLoading: true });
    },
    getSingleInsurance: (id: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.addInsurance) {
            axios.get('/Insurance/GetSingleInsurance/' + id)
                .then(function (response) {
                    const result = response.data;
                    //console.log(response.data)

                   /* setTimeout(() => {*/
                    result.InsuredDOB = dateFormatSetting(result.InsuredDOB)
                    result.ExpiryDate = dateFormatSetting(result.ExpiryDate)
                /*    })*/
                
                   

                    /*console.log(result)*/
                    dispatch({ type: 'RECEIVE_ADD_INSURANCE', addInsurance: result, isListLoading: false });
                })
                .catch(function (error) {
                    if (error.response == undefined) {
                        return;
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_ADD_INSURANCE', isListLoading: true });
        }
    },
    goBackInsurance: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        setTimeout(function () {
            const queryParams = new URLSearchParams(window.location.search);
            //var data: any = window.location.pathname;
           // const _array = data.split('/');
            var memberid = queryParams.get('memberid');
            var insurancepath = localStorage.getItem("InsurancePath");
            if (insurancepath != null) {
                window.location.href = insurancepath;
                //dispatch(push(insurancepath));
            }
            //else if (_array[1] == "insurancelist") {
            //    dispatch(push("/insurancelist" + window.location.search));
            //}
                else {
                dispatch(push("/insurance/list?memberid=" + memberid));
            }
        }, 10);
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: AddInsuranceState = {
    isPostLoading: false,
    isListLoading: false,
    addInsurance: {
        Id: "",
        MemberId: "",
        Level: "",
        InsuranceId: "",
        InsuredName: "",
        InsuredDOB: "",
        PolicyNo: "",
        GroupNo: "",
        PlanName: "",
        PlanCode: "",
        ExpiryDate: "",
        StatusId: "",
        DocIds: "",
        InsuranceName: "",
        VerificationStatusId: "",
        VerificationStatus: "",
        PlatformType: "",
        PatientId: "",
    }

};
export const reducer: Reducer<AddInsuranceState> = (state: AddInsuranceState | undefined, incomingAction: Action): AddInsuranceState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ADD_INSURANCE':
            return {
                addInsurance: state.addInsurance,
                isPostLoading: true,
                isListLoading: true
            };
        case 'RECEIVE_ADD_INSURANCE':
            return {
                addInsurance: action.addInsurance,
                isPostLoading: false,
                isListLoading: false

                
            };
        default:
            return state;
    }
};
function toDate(date: any) {
    if (date != null) {
        var currentDateTime = new Date(date);
        return currentDateTime
    }
}
function DisplayCurrentTime(datetime: Date) {
    var date = datetime;
    var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return (hours + ":" + minutes + ":" + seconds);
};