import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { required, scrollTop, setTittle } from '../../helper/formvalidation';
import { renderDiscountTextareaField, renderReasonAutoComplete, renderTextareaField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import ContentLoader from "react-content-loader"
import Moment from 'moment';
import * as ConditionStore from '../../store/appointment/Condition';
import * as LoginStore from '../../store/account/Login';
import * as ContactStore from '../../store/patient/Contact';
import * as Account from '../../store/siteaccount/Account';
import * as  HeaderDrProfileStore from '../../store/widget/HeaderDrProfile';
import * as HandleRequest from '../../helper/Request';
import HeaderDrProfile from '../widget/HeaderDrProfile';
import { floatingJS, seo, mobnav, ltrim } from '../../helper/Script';
import DocumentUpload from '../library/document/DocumentUpload';
import DocumentList from '../library/document/DocumentList';
import * as  documentStore from '../../store/library/document/DocumentList';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as PaymentProcessStore from '../../store/payment/PaymentProcess';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
/*import * as SpecialityQuestionStore from '../../store/library/questionnaire/SpecialityQuestion';*/
import { toastr } from 'react-redux-toastr';
import { TextBoxLoader } from '../../helper/fbloader';
import { useCallback, useEffect, useMemo, useState } from 'react';

type ConditionProps =
    Account.AccountState & FlexiFormStore.FlexiListsState & ConditionStore.ConditionState & LoginStore.LoginState & ContactStore.ContactState & HandleRequest.Request & HeaderDrProfileStore.HeaderDrProfileState & documentStore.DocumentListsState & TimeZoneStore.TimeZoneState & PaymentProcessStore.PaymentProcessState & LanguageResourcesStore.LanguageResouresState /*& SpecialityQuestionStore.QuestionnaireListsState*/ &
    typeof ConditionStore.actionCreators & typeof LoginStore.actionCreators & typeof documentStore.actionCreators & typeof FlexiFormStore.actionCreators & typeof HeaderDrProfileStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof PaymentProcessStore.actionCreators & typeof LanguageResourcesStore.actionCreators /*& typeof SpecialityQuestionStore.actionCreators*/ &
    RouteComponentProps<{}>;
var reason = "";
var seletedday = "";
var timeSeleted = "";
var encountertypeid = "";
var indexdata = 0;
var weekindex = 0;
var TimeIndex: any = "";
var serviceid: any = "";
var discountcode: any = "";
var discounttext: any = "";
var discountnumber: any = "";
var removediscount: any = "";
var totalValue: any = "";
var currentprice: any = "";
var Currenttimezone: any = [];
var __currency: any = [];
var _currentoffset: any = "";
var languagecontent: any = "";
let discountvalue: any = "";
var langdata: any = "";
var Reaso: any = "";
var Note: any = "";
var Discount: any = "";
var appointmentreasonfieldvisibility: any = "";
var appointment_reasonfieldvisibility: any = "";
var discountsetup: any = "";
var reasonfielddisable: any = "";
var reasonrequired: any = "";
class Condition extends React.PureComponent<ConditionProps> {
    constructor(props: any) {
        super(props);
    }
    onSubmit = (formValues: any, dispatch: any, props: any) => {

        //let accElements: any = document.getElementsByName('Discount')[0] as HTMLElement;
        //if (accElements != undefined) {
        //    var accElement: any = accElements.value;
        //    var discount: any = [];
        //    discount = this.props.Settings.filter((ca: any) => ca.name == "Client Discount Code");
        //    if (discount.length != 0) {
        //        const myArray = discount[0].customizeValue.split("|");
        //        discountcode = myArray[0];
        //    }
        //    if (accElement != discountcode) {
        //        localStorage.removeItem("Discount");
        //    }
        //}

        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (this.props.TimeZones.length != 0) {
            Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
        }
        if (Currenttimezone.length == 0) {
            formValues.Offset = "";
        } else {
            formValues.Offset = Currenttimezone[0].Offset;
        }
        var AppointmentReasonFieldVisibility: any = this.props.Settings.filter((se: any) => se.name == "Appointment Reason Field Visibility")[0];

        if (AppointmentReasonFieldVisibility.customizeValue == "Yes") {
            if (ltrim(formValues.Reason) == "") {
                return toastr.error('', languagecontent['reasonfieldempty']);
            }
            else {
                formValues.Reason = formValues.Reason;
            }
            
        }
        else {
            formValues.Reason = langdata['consultantwilldecide']
        }
        
        var modulename = "";
        var moduleid = "";
        const urlParams: any = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        localStorage.setItem("SpecialityId", urlParams.get('specialityid'));
        /*var enctype: any = urlParams.get('encountertypemode');*/
        var requestqueue = this.props.Settings.filter((se: any) => se.name == "Request Queue")[0];

        if ((requestqueue.customizeValue == "Yes") && (type == "noappointment")) {
            if (serviceid == "") {
                var enctype = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == "TEC")[0];
                this.selectEncType(enctype, "");
            } else {
                var enctype = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == "TEC")[0];
                this.selectEncType(enctype, "");
            }
        }

        if (this.props.moduleLists.length > 0) {
            modulename = this.props.moduleLists[0].moduleName;
            moduleid = this.props.moduleLists[0].id;
        }
        var DocIds: any = [];
        this.props.documentLists.forEach((element: any) => {
            //console.log(element);
            DocIds.push(element.id);
        });
        if (DocIds.length == 0) {
            formValues.DocIds = "";

        } else {
            formValues.DocIds = DocIds.toString();
        }
        if (localStorage.getItem("Discount") != null)
        this.props.headerdrprofile.minPrice = localStorage.getItem("Discount");
        if (!modulename && !moduleid) {
            if (this.props.Settings) {
                this.props.Settings.forEach((key: any, index: any) => {
                    if (key['name'] == "CP-Custom Form Flow") {
                        key['customizeValue'] = 'No'
                    }
                })
            }
        }

        if (this.props.Settings) {
            //console.log(this.props.Settings)
            this.props.Settings.forEach((key: any, index: any) => {
                if (key['name'] == "CP-Custom Form Flow") {

                    this.props.requestSaveAppointment(formValues, this.props.patient, this.props.match.params, key['customizeValue'], this.props.Account.id, modulename, moduleid, serviceid, this.props.headerdrprofile.encounterTypeModeDurationId, this.props.headerdrprofile.minPrice)

                }
            })
        }
        //console.log(this.props.Settings)

    }

    redirectprevious = () => {
        window.history.go(-1);
    }

    public _handleSearch = (query: any) => {
        this.props.requestCalendarEvent(query);
    }
    public _handleOnChange = (selected: any) => {
        selected.map((value: any) => {
            reason = value.Name;
        })
    }
    public componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const patientId = urlParams.get('patientId');
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        /*  this.ensureDataFetched();*/
        if (patientId == patient.id) {
            this.ensureDataFetched();
        }
        else {
            //browserHistory.push("/bhmn@mailinator.comhboard");
            window.location.href = "/dashboard";
        }
        
        floatingJS();
        scrollTop();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderCondition()}
            </React.Fragment>
        );
    }

   


    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.getPtaient();

        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        const moduleName = "Patient";
        const memberId = patient.memberId;
        var areaID = "5";
        /*const code: any = localStorage.getItem('Code');*/
        //let accElements: any = document.getElementsByName('Discount')[0] as HTMLElement;
        //var code: any = accElements.value.trim();


        //this.props.requestSpecialityModuleForm(areaID, patient['id']);
        this.props.requestModuleForm(areaID, patient['id']);
        //this.props.getFormModule();
        this.props.requestDocumentLists(memberId, moduleName);
        /*this.props.requestDiscountCode(code);*/
        //console.log(patient.timeZone);
        if (patient.timeZone != "") {
            this.props.requestTimeZone(patient.timeZone);
        }
        localStorage.removeItem("Discount");
        localStorage.removeItem("DiscountId");
        localStorage.removeItem("DiscountAmount");
        localStorage.removeItem("TotalAmount");
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        const appointmentLocalStroage: any = localStorage.getItem('Appointment');
        const Appointment = JSON.parse(appointmentLocalStroage);
        if (Appointment != null) {
            Reaso = Appointment.Reason.trim();
            Note = Appointment.Note;
            //if (localStorage.getItem("DiscountId") != "" || localStorage.getItem("DiscountId") != null || localStorage.getItem("DiscountId") != undefined )
            //    Discount = Appointment.Discount.trim();
            //else
            //    Discount = "";
        }
        else if (localStorage.getItem("SpecailityReasonName") != "" || localStorage.getItem("SpecailityReasonName") != null || localStorage.getItem("SpecailityReasonName") != undefined) {
            Reaso = localStorage.getItem("SpecailityReasonName");
            //if (localStorage.getItem("DiscountId") != null)
            //    Discount = Appointment.Discount.trim();
            //else
            //    Discount = "";
        }
        else {
            Reaso = "".trim();
            Note = "";
            /*Discount = "".trim();*/
        }       
    }    
    state = {
        isOpenOrder: true,
        discountAmount: "",
        totalAmount: ""
    };
   
    selectEncType = (value: any, e: any) => {
        localStorage.setItem("EncDetail", JSON.stringify({ durationCode: value.durationCode, encounterTypeMode: value.encounterTypeMode, minPrice: value.price, encounterTypeModeDurationId: value.encounterTypeModeDurationId, serviceId: value.serviceId }))
        const today = Moment(new Date()).format('YYYY-MM-DD');
        seletedday = Moment(new Date()).format('ddd');
        //localStorage.removeItem("AppointmentOn");
        serviceid = value.serviceId;
        //localStorage.removeItem("AppointmentTime");
        currentprice = value.price;
        if (this.state.totalAmount == "0" || this.state.totalAmount != "") {
            this.props.headerdrprofile.minPrice = this.state.totalAmount;
        } else {
            this.props.headerdrprofile.minPrice = value.price;
        }
        this.props.headerdrprofile.encounterTypeMode = value.encounterTypeMode;
        this.props.headerdrprofile.durationCode = value.durationCode;
        this.props.headerdrprofile.encounterTypeModeDurationId = value.encounterTypeModeDurationId;
        //const urlParams = new URLSearchParams(window.location.search);
        //var name = urlParams.get('status')
        //if(name=="followup")
      
        //    localStorage.setItem("enctypemode", value.encounterTypeMode);
        this.renderCondition();
        mobnav();
    }
    //calculateDiscount = (value: any) => {
    //    var langdata: any = "";
    //    if (localStorage.getItem("LangResourcesValidations") != null)
    //        langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    //    const search = window.location.search;
    //    const params = new URLSearchParams(search);
    //    let accElements: any = document.getElementsByName('Discount')[0] as HTMLElement;
    //    if (accElements != undefined) {
    //        var accElement: any = accElements.value;
    //        var methodid: any = params.get('methodid');
    //        if (methodid == "5") {
    //            var enctype: any = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == "TEC")[0];
    //            if (enctype != undefined) {
    //                this.props.headerdrprofile.minPrice = enctype.price;
    //            }
    //        }
    //        if (accElement == value && discountnumber != undefined) {
    //            if (discountnumber != undefined)
    //                var number: any = discountnumber.replace(/\D/g, '');

    //            totalValue = this.props.headerdrprofile.minPrice * (100 - number) / 100
    //            this.setState({
    //                isOpenOrder: false,
    //                discountAmount: this.props.headerdrprofile.minPrice,
    //                totalAmount: totalValue
    //            });
    //            this.props.headerdrprofile.minPrice = totalValue;
    //            localStorage.setItem("Discount", totalValue);
    //        }
    //        else {
    //            var setvalue: any = document.getElementsByName('Discount')[0];
    //            if (setvalue != null) {
    //                setvalue.value = "";
    //            }
    //            localStorage.removeItem("Discount");
    //            return toastr.error('', langdata['invalidcode']);
    //        }
    //        discountvalue = this.state.totalAmount;
    //    }
    //}

    calculateDiscount = () => {
       // const [discountAmount, setDiscountAmount] = useState<string | null>(null);
        let accElements: any = document.getElementsByName('Discount')[0] as HTMLElement;
        var code: any = accElements.value.trim();
        //localStorage.setItem("Code", code)
        localStorage.setItem("Amount", getDiscountPrice(this.props.headerdrprofile.minPrice,0));
        if (code)
        {
            var dcode: any = code;
            if (dcode)
                this.props.requestDiscountCode(code);
            else {
                if (accElements)
                    accElements.value = "";
                return toastr.error('', langdata['invalidcode']);
            }
        }
        else
            return toastr.error('', langdata['emptyfield']);
    }

    removeCode = (price: any) => {
        

        var setvalue: any = document.getElementsByName('Discount')[0] as HTMLElement;
        if (setvalue != null) {
            setvalue.value = "";
        }
        this.setState({
            isOpenOrder: true,
            discountAmount: "",
            totalAmount: price
        });
        this.props.headerdrprofile.minPrice = price;
        localStorage.removeItem("Discount");
        localStorage.removeItem("DiscountId");
        localStorage.removeItem("DiscountAmount");
        localStorage.removeItem("TotalAmount");

    }
    showDiscount = (value: any, price: any) => {
        let accElements: any = document.getElementsByName('Discount')[0] as HTMLElement;
        if (accElements != undefined) {
            var accElement: any = accElements.value;
            if (accElement == value) {
                return;
            } else {
                var setvalue: any = document.getElementsByName('Discount')[0];
                if (setvalue != null) {
                    setvalue.value = "";
                }
                this.setState({
                    isOpenOrder: true,
                    discountAmount: "",
                    totalAmount: price
                });
                localStorage.removeItem("Discount");
                localStorage.removeItem("DiscountId");
                localStorage.removeItem("DiscountAmount");
                localStorage.removeItem("TotalAmount");

            }
        }
    }
    public renderCondition() {
        languagecontent = this.props.LanguageResoures;
        var isconditionLoading: any = this.props.isconditionLoading;
        /*var langResources:any = JSON.parse(localStorage.getItem("LangResources") || "");*/
        /*var language: any = langResources.filter((la: any) => la.Group == "conditions");*/
        /*var healthinfo: any = language.filter((la: any) => la.Name == "morehealthissueinfo");*/
        const urlParams: any = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        var requestqueue = this.props.Settings.filter((se: any) => se.name == "Request Queue")[0];
        if (requestqueue != null) {
            if ((requestqueue.customizeValue == "Yes") && (type == "noappointment")) {
                if (serviceid == "") {
                    var enctype: any = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == "TEC")[0];
                    if (enctype != undefined) {
                        currentprice = enctype.price;
                    }
                }
            } else {
                currentprice = urlParams.get('price');
            }
        }
        if (this.props.isPaymentProcessLoading == true) {
            isconditionLoading = true;
        }
        const reasons = this.props.reasons;
        const isLoading = this.props.isreasonLoading;
        const onSearch = this._handleSearch;
        const onChangeMethod = this._handleOnChange;
        const appointmentLocalStroage: any = localStorage.getItem('Appointment');
        const Appointment = JSON.parse(appointmentLocalStroage);
        var conditionReason: any = localStorage.getItem("SpecailityReasonName");
        if (Appointment != null) {
            Reaso = Appointment.Reason.trim();
            Note = Appointment.Note;
            //if (localStorage.getItem("DiscountId") != null)
            //    Discount = Appointment.Discount.trim();
            //else
            //    Discount = "";
        }
        else if (conditionReason != null) {
            Reaso = localStorage.getItem("SpecailityReasonName");
            //if (localStorage.getItem("DiscountId") != null)
            //    Discount = Appointment.Discount.trim();
            //else
            //    Discount = "";
        }
        else {
            Reaso = "".trim();
            Note = "";
           /* Discount = "".trim();*/
        }
        let dynamicFormData: any = [];
        var _currency: any = "";

        __currency = this.props.Settings.filter((s: any) => s.name == "Currency");

        var value: any;
        if (__currency.length == 0) {
            _currency = "";
        }
        else {
            if (__currency[0].value == "") {
                _currency = __currency[0].displayCode;
                localStorage.setItem("currency", _currency);
            }
            else {
                _currency = __currency[0].customizeValue;
                localStorage.setItem("currency", _currency);
            }


        }
        var currency: any = _currency;
        //var discount: any = [];
        //discount = this.props.Settings.filter((ca: any) => ca.name == "Client Discount Code");
        //if (discount.length != 0) {
        //    const myArray = discount[0].customizeValue.split("|");
        //    discountcode = myArray[0];
        //    discountnumber = myArray[1];
        //    discounttext = myArray[2];
        //} 
        var discount: any = [];
        discount = this.props.Settings.filter((ca: any) => ca.name == "Client Discount Setup");
        if (discount.length != 0) {
            discountsetup = discount[0].customizeValue;

        }
        appointmentreasonfieldvisibility = this.props.Settings.filter((s: any) => s.name == "Appointment Reason Field Visibility")[0];
        if (appointmentreasonfieldvisibility != undefined) {
            appointment_reasonfieldvisibility = appointmentreasonfieldvisibility.customizeValue;
            //console.log(appointment_reasonfieldvisibility)
        }
        if (localStorage.getItem("SpecailityReasonName") != null){
            reasonfielddisable = true;
            reasonrequired = false;
        }
        else {
            reasonfielddisable = false;
            reasonrequired = true;
        }
        
       // console.log(localStorage.getItem("SpecailityReasonName"))     
        document.addEventListener('keypress', function (e) {
            if (e.keyCode === 13 || e.which === 13) {
                e.preventDefault();
                return false;
            }

        });
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container">
                        <HeaderDrProfile />

                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} className="condition" noValidate autoComplete={"off"}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        {
                                            type == "noappointment" ? <div className="col-lg-12 col-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <h5>{languagecontent['appointmenttype']}</h5>
                                                        <div className="card">
                                                            <ul className="list-group list-group-flush benifits-col">

                                                                {this.props.bioIsLoading == true ? <ContentLoader
                                                                    title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                                    speed={2}
                                                                    width={350}
                                                                    height={160}
                                                                    viewBox="0 0 400 160"
                                                                >
                                                                    <rect x="50" y="6" rx="4" ry="4" width="343" height="38" />
                                                                    <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
                                                                    <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
                                                                    <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
                                                                    <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
                                                                    <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />
                                                                </ContentLoader> :
                                                                    this.props.headerdrprofile.doctorServiceDetails != undefined ?
                                                                        this.props.headerdrprofile.doctorServiceDetails.map((doctorService: HeaderDrProfileStore.DoctorServiceDetail, index) =>
                                                                            <React.Fragment>
                                                                                {
                                                                                    doctorService.modeCode == "TEC" ?
                                                                                        <li className="list-group-item d-flex align-items">
                                                                                            <div className="avatar avatar-sm">
                                                                                                <label className="custom_radio">
                                                                                                    <div className="cp_visit-width">
                                                                                                        <input type="radio" name="calltype" onClick={(e: any) => this.selectEncType(doctorService, e)} value="Ccon" checked={doctorService.modeCode == "TEC" ? true : false} /><span className="checkmark"></span> {doctorService.encounterTypeMode} <h4 className="float-right text-info">{doctorService.price == "0" ? languagecontent['free'] : currency + getDiscountPrice(doctorService.price, 0)}</h4><p>{doctorService.price == "0" ? doctorService.duration : doctorService.duration} {languagecontent['mins']}</p>
                                                                                                    </div>
                                                                                                </label>
                                                                                            </div>
                                                                                        </li> : ""
                                                                                }
                                                                            </React.Fragment>
                                                                        )
                                                                        : ""
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                        {appointmentreasonfieldvisibility != undefined ?
                                            <div className="col-md-12 col-lg-7">
                                                <h5>{languagecontent['consultationinformation']}</h5>
                                                <div>
                                                    {languagecontent['appointmentreason'] == undefined ? "" :
                                                        appointment_reasonfieldvisibility == "Yes" && localStorage.getItem("SpecailityReasonName") == null ?
                                                            <Field name="Reason" Reason={reasons} isLoading={isLoading} onSearch={onSearch} onChangeMethod={onChangeMethod} localReason={Reaso} disabled={reasonfielddisable} component={renderReasonAutoComplete} label={languagecontent['appointmentreason']} validate={required} required={reasonrequired} type="text" />
                                                            : appointment_reasonfieldvisibility == "Yes" && localStorage.getItem("SpecailityReasonName") != null ? <Field name="Reason" Reason={reasons} isLoading={isLoading} onSearch={onSearch} onChangeMethod={onChangeMethod} localReason={Reaso} disabled={reasonfielddisable} component={renderReasonAutoComplete} label={languagecontent['appointmentreason']} required={reasonrequired} type="text" /> : ""}</div>
                                                <Field name="Note" component={renderTextareaField} label={languagecontent['note']} maxLength={500} type="text" />
                                                {
                                                    languagecontent['morehealthissueinfo'] == undefined ? "" :
                                                        <div className="text-left dont-have text-muted small">({languagecontent['morehealthissueinfo']})</div>
                                                }
                                            </div> :
                                            <div style={{ marginLeft :"20px", width:"50%" }}><br />
                                                <TextBoxLoader />
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="mt-3 mr-3 row">
                                    <div className="mob-col1 col-md-7 col-lg-7"><h5 className="ml-4">{languagecontent['document']}</h5></div>
                                    <div className="mob-col2 col-md-5 col-lg-5 text-right">
                                        {
                                            this.props.isdocLoading == true ? "" :
                                                <button type="button" className="btn btn-primary submit-btn" data-toggle="modal" data-target="#myModal"> {languagecontent['documentaddbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['documentaddbutton']}</button>
                                        }
                                    </div>
                                </div>
                                <DocumentList />
                            </div>
                            {
                                discountsetup == "Yes" && currentprice != 0  ?
                                    <div>
                                        <div className="card">
                                            <div className="mt-3 mr-3 row">
                                                <div className="mob-col1 col-md-7 col-lg-7"><h5 className="ml-4">{languagecontent['applydiscount']}</h5></div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="form-group form-focus focused" style={{ marginBottom: "1.5rem" }}>
                                                            <input type="text" id="discountCode" name="Discount" className="form-control floating" maxLength={10} />
                                                            <label className="focus-label" style={{ marginTop: "0px", background: "transparent" }}>{languagecontent['discountplaceholder']}</label>
                                                            {/*<Field type="text" id="discountCode" label={languagecontent['discountplaceholder']} component={renderDiscountTextareaField} name="Discount" className="form-control floating" maxLength={10} />*/}
                                                            {/*<label className="focus-label" style={{ marginTop: "0px", background:"transparent" }}>{languagecontent['discountplaceholder']}</label>*/}
                                                            {
                                                                localStorage.getItem("DiscountAmount") == null ? "" :
                                                                    <a className="float-right text-primary" onClick={() => this.removeCode(currentprice)}>{languagecontent['remove']}</a>
                                                            }
                                                        </div>
                                                        <div><button className="btn btn-primary submit-btn" type="button" onClick={() => this.calculateDiscount()}>{languagecontent['apply'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['apply']}</button></div> <br />
                                                        {/*<small>{discounttext}</small>*/}
                                                    </div>
                                                </div>
                                                <br />
                                                {
                                                    localStorage.getItem("DiscountAmount") == null ? "" :
                                                        <div className="row" id="dHideOrder">
                                                            <div className="col-sm-4">
                                                                <h5>{languagecontent['ordersummary'] == undefined ? "Order Summary" : languagecontent['ordersummary']}</h5>
                                                                <div className="discount">
                                                                    <p> {languagecontent['orderamount'] == undefined ? "Order Amount" : languagecontent['orderamount']} <strong>{currency}{localStorage.getItem("Amount")}</strong></p>
                                                                    <p> {languagecontent['discountamount'] == undefined ? "Discount Amount" : languagecontent['discountamount']} <strong>{currency}{localStorage.getItem("DiscountAmount")}</strong></p>
                                                                    <p> {languagecontent['totalamount'] == undefined ? "Total Amount" : languagecontent['totalamount']} <strong>{currency}{localStorage.getItem("TotalAmount")}</strong></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                    : ""
                            }
                            {this.props.bioIsLoading == true ? <TextBoxLoader />:
                            <div className="submit-section text-right">
                                <button className="btn btn-secondary submit-btn" type="button" onClick={this.redirectprevious}>{languagecontent['backbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['backbutton']}</button>
                                <button className="btn btn-primary submit-btn" disabled={isconditionLoading} > {isconditionLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {appointmentreasonfieldvisibility == undefined &&  languagecontent['proceedbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['proceedbutton']}</button>
                            </div>}
                        </form>
                        <DocumentUpload />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
//const mapDispatchToProps = ({ ...ConditionStore.actionCreators, ...FlexiFormStore.actionCreators, ...LoginStore.actionCreators, ...documentStore.actionCreators, ...ContactStore.actionCreators, ...Account.actionCreators, ...HeaderDrProfileStore.actionCreators, ...TimeZoneStore.actionCreators, ...PaymentProcessStore.actionCreators, ...LanguageResourcesStore.actionCreators });
//const mapStateToProps = (state: ApplicationState) => ({ ...state.condition, ...state.flexiform, ...state.login, ...state.documentlist, ...state.contact, ...state.account, ...state.headerDrProfile, ...state.timeZones, ...state.paymentProcess, ...state.langResources });

const mapDispatchToProps = { ...ConditionStore.actionCreators, ...FlexiFormStore.actionCreators, ...LoginStore.actionCreators, ...documentStore.actionCreators, ...ContactStore.actionCreators, ...Account.actionCreators, ...HeaderDrProfileStore.actionCreators, ...TimeZoneStore.actionCreators, ...PaymentProcessStore.actionCreators, ...LanguageResourcesStore.actionCreators /*...SpecialityQuestionStore.actionCreators*/ };
const mapStateToProps = (state: ApplicationState) => {

    let values = {
        initialValues: { Reason: Reaso, Note: Note/*, Discount: Discount*/ },
        ...state.condition, ...state.flexiform, ...state.login, ...state.documentlist, ...state.contact, ...state.account, ...state.headerDrProfile, ...state.timeZones, ...state.paymentProcess, ...state.langResources/*, ...state.questionnaire*/
    };
    return values;

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'customForm',
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        //console.log('failed')
        if (document.getElementsByClassName("is-invalid")[0]) {
            document.getElementsByClassName("is-invalid")[0].scrollIntoView({ block: 'start' });
        }
        else {
            document.getElementsByClassName("condition")[0].scrollIntoView({ block: 'start' });
        }

    }
})(Condition as any))


export const getDiscountPrice = (currentprice: any, discountprice: any) => {
    var price: any = (currentprice - discountprice);
    return parseFloat(price).toFixed(2);
}

