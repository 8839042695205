import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as ContactStore from '../../store/patient/Contact';
import { datepickerJS, floatingJS, ModalClose, seo, extdobcheck } from '../../helper/Script';
import * as FamilyMemberStore from '../../store/family/familyMember';
import * as CategoryStore from '../../store/siteaccount/Category';
import * as AccountStore from '../../store/siteaccount/Account';
import * as HandleRequest from '../../helper/Request';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as MemberSpecialityStore from '../../store/menu/speciality';
import { Field, reduxForm } from 'redux-form';
import { NewsLoader, renderDob, renderField, renderRequiredSelect, renderSelectField } from '../../helper/renderfield';
import { dateFormat, dateFormatSetting, email, getAge, phoneNumber, required, scrollTop, setTittle, checkFamilyMember } from '../../helper/formvalidation';
import ReactCrop from 'react-image-crop';
import { toastr } from 'react-redux-toastr';
import ContentLoader from 'react-content-loader';
var reader = new FileReader();
var relationship: any = [];
var familydetail: any = "";
var enablefamilymember: any = [];
var doctorappointment: any = [];
var anonymous: any = [];
var requestqueue: any = [];
var rel: any = "";
var parentid: any = "";
var childpatientid: any = "";
var name: any = "";
var childname: any = "";
var childid: any = "";
var currentfile: any;
var profilephoto: any = "";
var dateofbirth: any = "";
var photo: any = "";
var languagecontent: any;
var speciality: any = []
var langdata: any = "";
var closepopup: any = "";
type Props = FamilyMemberStore.FamilyMemberState & AccountStore.AccountState & CategoryStore.CategoryState & LanguageResourcesStore.LanguageResouresState & MemberSpecialityStore.MemberSpecialityState & typeof FamilyMemberStore.actionCreators & typeof AccountStore.actionCreators & typeof CategoryStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof MemberSpecialityStore.actionCreators & HandleRequest.Request & RouteComponentProps<{}>; // ... plus incoming routing parameters
class FamilyMember extends React.PureComponent<Props> {
    inputRef: any = React.createRef<HTMLInputElement>();
    state = {
        src: "",
        isOpen: true,
        isOpenConsult: true,
        isFamily: false,
        isSelected: "",
        primaryClass: "btn-primary",
        secondaryClass: "btn-secondary",
        crop: {
            x: 130,
            y: 50,
            width: 200,
            height: 200,
            aspect: 9 / 9,

        },
        croppedImageUrl: "",
        croppedImageUrlrevert: ""
    };
    constructor(props: any) {
        super(props);
    }    
    onSubmit = async (formValues: any) => {
            var age: any = getAge(formValues.dob);
            var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
            var firstname: any = document.querySelector('[name="firstName"]');
            var lastname: any = document.querySelector('[name="lastName"]');
            var gender: any = document.querySelector('[name="gender"]');
            var dob: any = document.querySelector('[name="dob"]');
            var email: any = document.querySelector('[name="email"]');
            var cellPhone: any = document.querySelector('[name="cellPhone"]');
            var _rel: any = document.querySelector('[name="relationship"]');

            formValues.firstName = firstname.value;
            formValues.lastName = lastname.value;
            formValues.gender = gender.value;
            formValues.dob = dob.value;
            formValues.email = email.value;
            formValues.cellPhone = cellPhone.value;
            var date: any = (formValues.dob);
            const dmyear = date.split("/");
            const year = dmyear[2];
            //console.log(year);
            if (year == ("0000" || "000" || "00" || "0")) {
                var langdata: any;
                langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                return toastr.error('', langdata['invaliddate']);
            }
            if (doblimit.customizeValue > age) {
                //console.log(age, doblimit);
                var langdata: any;
                if (localStorage.getItem("LangResourcesValidations") != null)
                    //console.log(age);
                    langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                return toastr.error('', langdata['userdobrange'] + ' ' + doblimit.customizeValue + ' ' + langdata['years']);
            }
            var relId = relationship.filter((ca: any) => ca.value == _rel.value);
            if (familydetail.Id != null) {
                var relation = relationship.filter((ca: any) => ca.name == familydetail.Relationship);
                familydetail.RelationShipId = relation[0].id;
            }
            formValues.RelationShipId = relId[0].id;
            formValues.Id = familydetail.Id;
            formValues.ContactId = familydetail.ContactId;
            if (await isBase64UrlImage(photo) == true)
                formValues.Photo = photo;
            else
                formValues.Photo = "";

            this.props.postFamilyMember(formValues, familydetail);
            //window.history.pushState({ prevUrl: window.location.href }, null, "/new/path/in/your/app")
            //var url: any = window.history.state.prevUrl;
            //console.log(url);        
    }

    onSelectFile = (e: any) => {
        var croppedImageUrl = "";
        var croppedImageUrlrevert = "";
        this.setState({ croppedImageUrl, croppedImageUrlrevert });
        var x = document.getElementById("showCrop");
        if (x != null) {
            x.style.display = "block";
        }
        if (e.target.files && e.target.files.length > 0) {
            const fileSize = e.target.files[0].size / 1024 / 1024;
            const fileType = e.target.files[0].type;

            if (fileSize > 2) {
                return toastr.error('', langdata['pleaseselectimagesize']);
            }

            if (fileType !== "image/png" && fileType !== "image/jpg" && fileType !== "image/gif" && fileType !== "image/jpeg") {
                return toastr.error('', 'Please select JPG, GIF or PNG');
            }

            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);

            currentfile = e.target.files[0];
        }
    };
    onImageLoaded = (image: any) => {

        var x = document.getElementById("showCrop");
        if ((image.naturalHeight >= 150 && image.naturalWidth >= 150)) {
            if (x != null) {
                if (x.style.display === "none") {
                    x.style.display = "block";
                } else {
                    x.style.display = "block";
                }
            }
            this.imageRef = image;
        } else {
            var croppedImageUrl = "";
            var src = "";
            var croppedImageUrlrevert = "";
            this.setState({ croppedImageUrl, src });
            if (x != null)
                x.style.display = "none";
            return toastr.error('', langdata['pleaseselectafilewithaspectratio']);
        }
    };
    onCropComplete = (crop: any) => {
        this.makeClientCrop(crop);
    };
    onCropChange = (crop: any, percentCrop: any) => {
        this.setState({ crop });
    };
    imageRef: any;
    async makeClientCrop(crop: any) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }
    getCroppedImg(image: any, crop: any, fileName: any) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;
        if (ctx != null) {
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = 'high';

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width * scaleX,
                crop.height * scaleY
            );
        }
        return new Promise((resolve, reject) => {
            var fileUrl = "";
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    window.URL.revokeObjectURL(fileUrl);
                    fileUrl = window.URL.createObjectURL(blob);
                    resolve(fileUrl);
                    reader.readAsDataURL(blob);
                    reader.onload = function () {
                        return reader.result;
                    };
                },
                'image/png',
                1
            );
        });
    }
   
    public componentDidMount() {
        localStorage.removeItem("Appointment");
        localStorage.removeItem("SpecialityId");
        localStorage.removeItem("AppointmentTime1");
        localStorage.removeItem("AppointmentOn1");
        localStorage.removeItem("AppointmentOn");
        localStorage.removeItem("AppointmentTime");
        localStorage.removeItem("AppointmentUTCTime")
        localStorage.removeItem("AppointmentUTCTime1")
        localStorage.removeItem("SelectedDate");
        localStorage.removeItem("SpecailityReasonName");
        localStorage.removeItem("SpecailityReasonId");
        localStorage.removeItem("modecode");
        localStorage.setItem("SpecialityId","");
        localStorage.removeItem("SpecReason");
        localStorage.removeItem("followup");
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        datepickerJS();
        floatingJS();
        scrollTop();
        checkFamilyMember();
        //var dobchk = "";
        //if (familydetail.DOB != null && dobchk == "") {
        //	extdobcheck(dateFormat(familydetail.DOB), dobchk);
        //	dobchk = "checked";
        //}

        //if (localStorage.getItem('isAuth') == "true") {
        //    window.history.pushState(null, null);
        //    window.onpopstate = function (event) {
        //        window.history.go(1);
        //    };
        //}
        //window.addEventListener('popstate', function (event) {
        //    this.window.history.forward();
        //}, false);
        //this.ensureDataFetched();
        //var prevpageurl: any = window.history.go(-1);
        //if (prevpageurl == " / initialpageforms / ") {
        //    window.addEventListener('popstate', function (event) {
        //        this.window.history.forward();
        //    }, true);
        //   this.ensureDataFetched();}
        //function confirmLeavePage(e) {
        //    e.preventDefault();
        //    e.returnValue = ''; // This is required for older browsers
        //}

        //// Attach the event listener when the page loads
        //window.addEventListener('load', function () {
        //    // Attach the beforeunload event listener
        //    window.addEventListener('beforeunload', confirmLeavePage);
        //});
        this.ensureDataFetched();
        this.setState({
            primaryClass: "btn-primary",
            secondaryClass: "btn-secondary"
        });              
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" || languagecontent == undefined || languagecontent == null ? "" : languagecontent['breadcrumb']));
    }
 
    public render() {
        return (
            <React.Fragment>
                {this.renderFamilyMember()}
            </React.Fragment>
        );
    }
    closeModal = () => {
        this.setState({ src: "" });
        if (closepopup == "addnew") {
            this.props.clearForm();
        } else {
            this.props.clearFamilyForm();
        }
        ModalClose();
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        this.props.requestMemberSpecialityLists();
        var dobchk = "";
        extdobcheck(dateFormatSetting(dateofbirth), dobchk);
        const urlParams = new URLSearchParams(window.location.search);
        const memberid = urlParams.get('memberid');
        if (memberid != undefined) {
            this.props.getCategory();
            this.props.getFamilyMemberList(memberid);
        }
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }
    onRemove() {
        var result = window.confirm("Are you sure to want delete?");
        if (result) {
            //this.props.requestSavePhoto(this.props.contact.id, null);
        }
    }
    onBlockFile() {

        var x = document.getElementById("showCrop");
        if (x != null) {
            x.style.display = "hide";
        }

    }
    onUpdate() {
        var x = document.getElementById("showCrop");
        this.setState({
            croppedImageUrl: "",
            croppedImageUrlrevert: ""
        });
        if (x != null) {
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        }
        this.setState({ src: "" });
        this.inputRef.current.value = null;
        this.props.requestSavePhoto(childid, reader.result, currentfile);
    }
    onCancel() {
        var x = document.getElementById("showCrop");
        var croppedImageUrl = "";
        var croppedImageUrlrevert = "test";
        const src = "";
        const crop = "";
        if (x != null) {
            if (x.style.display === "block") {
                x.style.display = "none";
            } else {
                x.style.display = "block";
            }
        }
        this.setState({ src: "" });
        this.inputRef.current.value = null;
        this.setState({ croppedImageUrl, croppedImageUrlrevert });
    }
    addnew = () => {
        this.componentDidMount();
        closepopup = "addnew";
        var dobchk = "";
        dateofbirth = '';
        extdobcheck(dateFormatSetting(dateofbirth), dobchk);
        rel = '';
        familydetail = { firstName: "", lastName: "", gender: "", relationship: "", cellPhone: "", email: "" };
        childid = null;
        photo = "";
        this.setState({
            primaryClass: "btn-secondary",
            secondaryClass: "btn-primary"
        });
    }
    editFamilymember = (value: any) => {
        closepopup = "editFamilymember";
        familydetail = value;
        childid = value.Id;
        var croppedImageUrl = "";
        var croppedImageUrlrevert = "";
        this.setState({ croppedImageUrl, croppedImageUrlrevert });
        profilephoto = value.Url == null ? "client/default/img/blankimg.jpg" : value.Url;
        if (value.Url != null) {
            photo = value.Photo;
        }
        if (value.Relationship != "") {

            var rel_grp = this.props.categories.filter((ca: any) => ca.group == "Relationship");
            rel = rel_grp.filter((ca: any) => ca.name == value.Relationship)[0]


        }
        
        dateofbirth = value.DOB;
        this.componentDidMount();
        this.setState({
            primaryClass: "btn-secondary",
            secondaryClass: "btn-primary"
        });
    }
    goMySelf = () => {
        doctorappointment = this.props.Settings.filter((s: any) => s.name == "Doctor Appointment");
        if (doctorappointment.length == 0)
            doctorappointment = null;
        else
            doctorappointment = doctorappointment[0].customizeValue

        anonymous = this.props.Settings.filter((s: any) => s.name == "Anonymous");
        if (anonymous.length == 0)
            anonymous = null;
        else
            anonymous = anonymous[0].customizeValue

        requestqueue = this.props.Settings.filter((s: any) => s.name == "Request Queue");
        if (requestqueue.length == 0)
            requestqueue = null;
        else
            requestqueue = requestqueue[0].customizeValue;
        if (doctorappointment == "Yes" && anonymous == "No" && requestqueue == "No") {
            if (this.props.speciality.length == 1)
                window.location.href = "/ourdoctors?specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=ourdoctors";
        }
        else if (doctorappointment == "No" && anonymous == "Yes" && requestqueue == "No") {
            if (this.props.speciality.length == 1)
                window.location.href = "/centralized?specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=centralized";
        } else if (doctorappointment == "No" && anonymous == "No" && requestqueue == "Yes") {
            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
            var patientid = patient.id;
            if (this.props.speciality.length == 1) {
                localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                localStorage.setItem("pathName", "RequestQueue");
                this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
            }
            else
                window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;
            localStorage.setItem("methodId","5")
            
        }
        else {
            this.setState({
                isOpenConsult: false,
                isOpen: true,
                isFamily: false,
                primaryClass: "btn-primary",
                secondaryClass: "btn-secondary"
            });
            parentid = "";
            name = "";

            localStorage.removeItem("redirectUrl");
            localStorage.removeItem("redirectQequestQueueUrl");
            localStorage.removeItem("pathName");
            localStorage.removeItem('AppointmentOn');
        }
    }
    goFamily = () => {
        this.setState({
            isOpen: false,
            isOpenConsult: true,
            isFamily: true,
            primaryClass: "btn-secondary",
            secondaryClass: "btn-primary"
        });
        localStorage.removeItem("redirectUrl");
        localStorage.removeItem("redirectQequestQueueUrl");
        localStorage.removeItem("pathName");
        localStorage.removeItem('AppointmentOn');
    }
    goConsult = (value: any) => {
        parentid = value.Id;
        childpatientid = value.PatientId;
        localStorage.setItem("childpatientid", childpatientid);
        var prf = value.Prefix == null ? "" : value.Prefix
        name = prf + " " + value.FirstName + " " + value.LastName;
        doctorappointment = this.props.Settings.filter((s: any) => s.name == "Doctor Appointment");
        if (doctorappointment.length == 0)
            doctorappointment = null;
        else
            doctorappointment = doctorappointment[0].customizeValue

        anonymous = this.props.Settings.filter((s: any) => s.name == "Anonymous");
        if (anonymous.length == 0)
            anonymous = null;
        else
            anonymous = anonymous[0].customizeValue

        requestqueue = this.props.Settings.filter((s: any) => s.name == "Request Queue");
        if (requestqueue.length == 0)
            requestqueue = null;
        else
            requestqueue = requestqueue[0].customizeValue;
        if (doctorappointment == "Yes" && anonymous == "No" && requestqueue == "No") {
            if (this.props.speciality.length == 1)
                window.location.href = "/ourdoctors?childid=" + parentid + "&childname=" + name + "&specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=ourdoctors&childid=" + parentid + "&childname=" + name;
        }
        else if (doctorappointment == "No" && anonymous == "Yes" && requestqueue == "No") {
            if (this.props.speciality.length == 1)
                window.location.href = "/centralized?childid=" + parentid + "&childname=" + name + "&specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=centralized&childid=" + parentid + "&childname=" + name;
        } else if (doctorappointment == "No" && anonymous == "No" && requestqueue == "Yes") {
            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
            var patientid = patient.id;
            if (this.props.speciality.length == 1) {
                localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
                localStorage.setItem("pathName", "RequestQueue");
                this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
            }
            else
                window.location.href = "/memberspeciality?name=consultnow&childid=" + parentid + "&childname=" + name + "&patientid=" + patientid;
        }
        else {
            this.setState({
                isOpenConsult: false,
                isSelected: value.Id
            });
        }
    }
    providerAppointment = () => {
        if (this.state.isFamily == false) {
            if (this.props.speciality.length == 1)
                window.location.href = "/ourdoctors?specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=ourdoctors";
            /*this.props.history.push("/ourdoctors");*/
        } else {
            if (this.props.speciality.length == 1)
                window.location.href = "/ourdoctors?childid=" + parentid + "&childname=" + name + "&specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=ourdoctors&childid=" + parentid + "&childname=" + name;
            //window.location.href = "/ourdoctors?childid=" + parentid + "&childname=" + name;
            //this.props.history.push("/ourdoctors?childid=" + parentid + "&name=" + name);
        }
    }
    chooseproviderAppointment = () => {
        if (this.state.isFamily == false) {
            if (this.props.speciality.length == 1)
                window.location.href = "/centralized?specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=centralized";
            /*window.location.href = "/centralized";*/
            //this.props.history.push("/centralized");
        } else {
            if (this.props.speciality.length == 1)
                window.location.href = "/centralized?childid=" + parentid + "&childname=" + name + "&specialityid=" + this.props.speciality[0].id;
            else
                window.location.href = "/memberspeciality?name=centralized&childid=" + parentid + "&childname=" + name;
            //window.location.href = "/centralized?childid=" + parentid + "&childname=" + name;
            //this.props.history.push("/centralized?childid=" + parentid + "&name=" + name);
        }
    }
    consultNow = (doctorappointment: any, anonymous: any) => {
        if (doctorappointment == "Yes" && anonymous == "Yes")
            return;
        else if (doctorappointment == "Yes") {
            enablefamilymember = this.props.Settings.filter((s: any) => s.name == "Enable Family Member")[0];
            if ((enablefamilymember.customizeValue == "Yes") && (this.state.isFamily == true)) {
                if (this.props.speciality.length == 1)
                    this.props.history.push("/ourdoctors?childid=" + parentid + "&childname=" + name + "&specialityid=" + this.props.speciality[0].id);
                else
                    window.location.href = "/memberspeciality?name=ourdoctors";
            } else {
                if (this.props.speciality.length == 1)
                    window.location.href = "/ourdoctors?specialityid=" + this.props.speciality[0].id;
                else
                    window.location.href = "/memberspeciality?name=ourdoctors";
                //this.props.history.push("/ourdoctors");
            }
        }
        else if (anonymous == "Yes") {
            enablefamilymember = this.props.Settings.filter((s: any) => s.name == "Enable Family Member")[0];
            if ((enablefamilymember.customizeValue == "Yes") && (this.state.isFamily == true)) {
                if (this.props.speciality.length == 1)
                    this.props.history.push("/centralized?childid=" + parentid + "&childname=" + name + "&specialityid=" + this.props.speciality[0].id);
                else
                    window.location.href = "/memberspeciality?name=centralized";
            } else {
                if (this.props.speciality.length == 1)
                    window.location.href = "/centralized?specialityid=" + this.props.speciality[0].id;
                else
                    window.location.href = "/memberspeciality?name=centralized";
                //this.props.history.push("/centralized");
            }
        }
        else
            return;
    }
    requestQueue = () => {
        if (localStorage.getItem('isAuth') == "true") {
            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                this.props.history.push("/contact/" + null + "/" + patient.memberId);
            } else {
                var patientid = patient.id;
                if (parentid == "") {
                    if (this.props.speciality.length == 1) {
                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                        localStorage.setItem("pathName", "RequestQueue");
                        this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                    }
                    else
                        window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;
                } else {
                    if (this.props.speciality.length == 1) {
                        localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
                        localStorage.setItem("pathName", "RequestQueue");
                        this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
                    }
                    else
                        window.location.href = "/memberspeciality?name=consultnow&childid=" + parentid + "&childname=" + name + "&patientid=" + patientid;

                }
            }
        } else {
            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=&serviceid=&type=noappointment")
            localStorage.setItem("pathName", "RequestQueue");
            this.props.history.push("/login");
        }
    }
    chooseRequestQueue = () => {
        if (localStorage.getItem('isAuth') == "true") {
            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                var patientid = patient.id;
                if (parentid == "") {
                    if (this.props.speciality.length == 1) {
                        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
                        if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                            localStorage.setItem("pathName", "RequestQueue");
                            this.props.history.push("/contact/" + null + "/" + patient.memberId);
                        } else {
                            this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                        }
                    }
                    else
                        window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;
                } else {
                    if (this.props.speciality.length == 1) {
                        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
                        if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
                            localStorage.setItem("pathName", "RequestQueue");
                            this.props.history.push("/contact/" + null + "/" + patient.memberId);
                        } else {
                            this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
                        }
                    }
                    else
                        window.location.href = "/memberspeciality?name=consultnow&childid=" + parentid + "&childname=" + name + "&patientid=" + patientid;
                }
            } else {
                var patientid = patient.id;
                if (parentid == "") {
                    if (this.props.speciality.length == 1) {
                        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
                        if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                            localStorage.setItem("pathName", "RequestQueue");
                            this.props.history.push("/contact/" + null + "/" + patient.memberId);
                        } else {
                            this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                        }
                    }
                    else
                        window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;
                } else {
                    if (this.props.speciality.length == 1) {
                        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
                        if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id)
                            localStorage.setItem("pathName", "RequestQueue");
                            this.props.history.push("/contact/" + null + "/" + patient.memberId);
                        } else {
                            this.props.history.push("/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&patientId=" + patientid + "&serviceid=&childid=" + parentid + "&childname=" + name + "&type=noappointment&specialityid=" + this.props.speciality[0].id);
                        }
                    }
                    else
                        window.location.href = "/memberspeciality?name=consultnow&childid=" + parentid + "&childname=" + name + "&patientid=" + patientid;
                }
            }
        } else {
            localStorage.removeItem("AppointmentOn");
            localStorage.removeItem("AppointmentTime");
            localStorage.removeItem("AppointmentTime1");
            localStorage.removeItem("AppointmentOn1");
            localStorage.removeItem("AppointmentUTCTime")
            localStorage.removeItem("AppointmentUTCTime1")
            localStorage.removeItem("SelectedDate");
            localStorage.removeItem("DoctorId");
            localStorage.removeItem("EncDetail");
            localStorage.removeItem("Appointment");
            localStorage.removeItem("SpecialityId");
            localStorage.removeItem("EncounterTypeId");
            localStorage.removeItem("currency");
            if (this.props.speciality.length == 1) {
                    localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&serviceid=&type=noappointment&specialityid=" + this.props.speciality[0].id);
                    localStorage.setItem("pathName", "RequestQueue")              
                    window.location.href = "/login";               
            }
            else
                window.location.href = "/memberspeciality?name=consultnow";
        }
    }
    private renderFamilyMember() {
        var dateformat: any = localStorage.getItem("DateFormat") == null ? "" : localStorage.getItem("DateFormat");
        languagecontent = this.props.LanguageResoures;
        const gender = this.props.categories.filter((ca: any) => ca.group == "Gender");
        relationship = this.props.categories.filter((ca: any) => ca.group == "Relationship");
        enablefamilymember = this.props.Settings.filter((s: any) => s.name == "Enable Family Member");
        if (enablefamilymember.length == 0)
            enablefamilymember = null
        else
            enablefamilymember = enablefamilymember[0].customizeValue

        doctorappointment = this.props.Settings.filter((s: any) => s.name == "Doctor Appointment");
        if (doctorappointment.length == 0)
            doctorappointment = null;
        else
            doctorappointment = doctorappointment[0].customizeValue

        anonymous = this.props.Settings.filter((s: any) => s.name == "Anonymous");
        if (anonymous.length == 0)
            anonymous = null;
        else
            anonymous = anonymous[0].customizeValue

        requestqueue = this.props.Settings.filter((s: any) => s.name == "Request Queue");
        if (requestqueue.length == 0)
            requestqueue = null;
        else
            requestqueue = requestqueue[0].customizeValue;
        childid = childid == null ? "" : childid;

        var doblimit: any = this.props.Settings.filter((s: any) => s.name == "Allowed User DOB Range")[0];
        var _doblimit: any = 0;
        if (doblimit != undefined) {
            _doblimit = doblimit.customizeValue;
        } else {
            _doblimit = 0;
        }
        var IsLoading: any = this.props.IsLoading;
        return (
            <div className="content">
                <div className="container">
                    <div className="row">
                        <React.Fragment>
                            {
                                enablefamilymember == "Yes" ?
                                    <div className="col-sm-12">
                                        <div className="card">
                                            <div className="card-body">
                                                {
                                                    languagecontent['whoisconsultationfor'] == undefined ? <NewsLoader /> :
                                                        <React.Fragment>
                                                            <h4 className="widget-title pat-widget-title">{languagecontent['whoisconsultationfor']}</h4>
                                                            <button type="button" className={"btn btn-md mr-10 " + this.state.primaryClass} style={{ marginRight: "30px", paddingLeft: "50px", paddingRight: "50px", marginBottom: "15px" }} onClick={() => this.goMySelf()}> {IsLoading == true ? <img src="../../../buttonloader.svg" /> : languagecontent['myself']}</button>
                                                            <button type="button" className={"btn btn-md " + this.state.secondaryClass} style={{ paddingLeft: "50px", paddingRight: "50px", marginBottom: "15px" }} onClick={() => this.goFamily()}> {IsLoading == true ? <img src="../../../buttonloader.svg" /> : languagecontent['family']}</button>
                                                        </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div> : ""
                            }

                            <div className="col-sm-12" hidden={this.state.isOpen}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="widget-title pat-widget-title">{languagecontent['selectamember']}</h4>
                                        <button type="button" className="btn btn-md btn-primary mr-10" style={{ marginRight: "30px", paddingLeft: "50px", paddingRight: "50px" }} data-toggle="modal" data-target="#myModal" onClick={() => this.addnew()}>{languagecontent["addnew"]}</button>
                                        <br /><br />
                                        {
                                            this.props.isLoading == true ? <ProfileLoader /> :
                                                <div className="row">
                                                    {
                                                        this.props.familyMembers.map((familymember: FamilyMemberStore.FamilyMember) =>
                                                            <div className="col-sm-4" >
                                                                <a className="float-right edit_icon" onClick={() => this.editFamilymember(familymember)} data-toggle="modal" data-target="#myModal"><i className="far fa-edit"></i></a>
                                                                <div className={familymember.Id == this.state.isSelected ? "card select-member" : "card"} onClick={() => this.goConsult(familymember)}>
                                                                    <div className="profile-info-widget ml-3 mr-3 mt-3 mb-3">
                                                                        <a className="avatar avatar-sm mr-3">
                                                                            <img src={familymember.Url == null ? "client/default/img/blankimg.jpg" : familymember.Url} className="avatar-img rounded-circle" alt="User Image" />
                                                                        </a>
                                                                        <div className="profile-det-info">
                                                                            <h3 className="mb-0 family-ellipsis"><a>{familymember.FirstName + " " + familymember.LastName}</a> </h3>
                                                                            <div className="patient-details">
                                                                                <small>{familymember.Relationship}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>

                        </React.Fragment>

                        <div className="col-sm-12" hidden={enablefamilymember == "No" ? false : this.state.isOpenConsult}>
                            {
                                ((doctorappointment == "Yes") && (anonymous == "No") && (requestqueue == "No") && (enablefamilymember == "Yes")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ marginRight: "30px", paddingLeft: "50px", paddingRight: "50px" }} onClick={() => this.consultNow(doctorappointment, anonymous)}>{languagecontent['providerappointment']}</button>
                                            <br /><small>{languagecontent['providerappointmentdesc']}</small>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                ((doctorappointment == "No") && (anonymous == "Yes") && (requestqueue == "No") && (enablefamilymember == "Yes")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ marginRight: "30px", paddingLeft: "50px", paddingRight: "50px" }} onClick={() => this.consultNow(doctorappointment, anonymous)}>{languagecontent['chooseaprovider']}</button>
                                            <br /><small>{languagecontent['chooseaproviderdesc']}</small>
                                        </div>
                                    </div>
                                    : ""
                            }
                            {
                                ((doctorappointment == "No") && (anonymous == "No") && (requestqueue == "Yes") && (enablefamilymember == "Yes")) ?
                                    <React.Fragment>
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                                <button type="button" className="btn btn-md btn-primary mr-10" onClick={() => this.chooseRequestQueue()} style={{ width: "250px" }}>{languagecontent['consultnow']}</button><br /><small>{languagecontent['consultnowdescription']}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    : ""
                            }
                            {
                                ((doctorappointment == "No") && (anonymous == "No") && (requestqueue == "Yes") && (enablefamilymember == "No")) ?
                                    <React.Fragment>
                                        <div className="card">
                                            <div className="card-body">
                                                <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                                <button type="button" className="btn btn-md btn-primary mr-10" onClick={() => this.requestQueue()} style={{ width: "250px" }} >{languagecontent['consultnow']}</button><br /><small>{languagecontent['consultnowdescription']}</small>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    : ""
                            }
                            {
                                ((doctorappointment == "Yes") && (anonymous == "No") && (requestqueue == "Yes") && (enablefamilymember == "Yes")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.providerAppointment()}>{languagecontent['providerappointment']}</button><br />
                                            <small>{languagecontent['providerappointmentdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" onClick={() => this.chooseRequestQueue()} style={{ width: "250px" }} >{languagecontent['consultnow']}</button>
                                            <br /><small>{languagecontent['consultnowdescription']}</small>
                                        </div> </div>
                                    : ""
                            }
                            {
                                ((doctorappointment == "Yes") && (anonymous == "No") && (requestqueue == "Yes") && (enablefamilymember == "No")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.providerAppointment()}>{languagecontent['providerappointment']}</button><br />
                                            <small>{languagecontent['providerappointmentdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" onClick={() => this.chooseRequestQueue()} style={{ width: "250px" }}>{languagecontent['consultnow']}</button>
                                            <br /><small>{languagecontent['consultnowdescription']}</small>
                                        </div>  </div> : ""
                            }
                            {
                                ((doctorappointment == "No") && (anonymous == "Yes") && (requestqueue == "Yes") && (enablefamilymember == "No")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseproviderAppointment()}>{languagecontent['chooseaprovider']}</button><br />
                                            <small>{languagecontent['chooseaproviderdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseRequestQueue()}>{languagecontent['consultnow']}</button><br />
                                            <small>{languagecontent['providerappointmentdesc']}</small>
                                        </div></div> : ""
                            }
                            {
                                ((doctorappointment == "No") && (anonymous == "Yes") && (requestqueue == "Yes") && (enablefamilymember == "Yes")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseproviderAppointment()}>{languagecontent['chooseaprovider']}</button><br />
                                            <small>{languagecontent['chooseaproviderdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseRequestQueue()}>{languagecontent['consultnow']}</button><br />
                                            <small>{languagecontent['consultnowdescription']}</small>
                                        </div></div> : ""
                            }
                            {
                                ((doctorappointment == "Yes") && (anonymous == "Yes") && (requestqueue == "No") && (enablefamilymember == "No")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseproviderAppointment()}>{languagecontent['chooseaprovider']}</button><br />
                                            <small>{languagecontent['chooseaproviderdesc']}</small><br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.providerAppointment()}>{languagecontent['providerappointment']}</button><br />
                                            <small>{languagecontent['providerappointmentdesc']}</small>
                                        </div></div> : ""
                            }
                            {
                                ((doctorappointment == "Yes") && (anonymous == "Yes") && (requestqueue == "No") && (enablefamilymember == "Yes")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseproviderAppointment()}>{languagecontent['chooseaprovider']}</button><br />
                                            <small>{languagecontent['chooseaproviderdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.providerAppointment()}>{languagecontent['providerappointment']}</button><br />
                                            <small>{languagecontent['providerappointmentdesc']}</small>
                                        </div></div> : ""
                            }
                            {
                                ((doctorappointment == "Yes") && (anonymous == "Yes") && (requestqueue == "Yes") && (enablefamilymember == "Yes")) ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseproviderAppointment()}>{languagecontent['chooseaprovider']}</button><br />
                                            <small>{languagecontent['chooseaproviderdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.providerAppointment()}>{languagecontent['providerappointment']}</button><br />
                                            <small>{languagecontent['providerappointmentdesc']}</small>
                                            <br />
                                            <button type="button" className="btn btn-md btn-primary mr-10" onClick={() => this.chooseRequestQueue()} style={{ width: "250px" }}>{languagecontent['consultnow']}</button>
                                            <br /><small>{languagecontent['consultnowdescription']}</small>

                                        </div></div> : ""
                            }

                        </div>
                        <div className="col-sm-12">
                            {
                                (doctorappointment == "Yes" && anonymous == "Yes" && requestqueue == "Yes" && enablefamilymember == "No") ?
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="widget-title pat-widget-title">{languagecontent['howwouldconsult']}</h4>
                                            <div>
                                                <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseproviderAppointment()}>{languagecontent['chooseaprovider']}</button><br />
                                                <small>{languagecontent['chooseaproviderdesc']}</small>
                                                <br />
                                                <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.providerAppointment()}>{languagecontent['providerappointment']}</button><br />
                                                <small>{languagecontent['providerappointmentdesc']}</small><br />
                                                <button type="button" className="btn btn-md btn-primary mr-10" style={{ width: "250px" }} onClick={() => this.chooseRequestQueue()} >{languagecontent['consultnow']}</button>
                                                <br /><small>{languagecontent['consultnowdescription']}</small>
                                            </div>
                                        </div>
                                    </div> : ""
                            }

                        </div>
                    </div>
                    <div className="modal fade" data-backdrop="static" id="myModal" aria-hidden="true" role="dialog" data-keyboard="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{languagecontent['familymember']}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {
                                        childid == "" ? "" :
                                            <div className="row">
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <div className="change-avatar">
                                                            <div className="profile-img">
                                                                <img alt="Crop" style={{ maxWidth: '100%' }} src={profilephoto == "" ? "client/default/img/blankimg.jpg" : profilephoto} />
                                                            </div>
                                                            <React.Fragment>
                                                                <button type="button" hidden={true} className="btn btn-rounded btn-danger" style={{ top: "-15px", marginRight: "10px", position: "relative" }} onClick={() => this.onRemove()}><i className="fa fa-trash" aria-hidden="true"></i></button>
                                                            </React.Fragment>
                                                            <div className="upload-img">
                                                                <div className="change-photo-btn" onClick={this.onBlockFile}>
                                                                    <span><i className="fa fa-upload"></i> {languagecontent['uploadphoto']}</span>
                                                                    <input type="file" accept="image/*" onChange={this.onSelectFile} className="upload" ref={this.inputRef}/>
                                                                </div>
                                                                <small className="form-text text-muted">{languagecontent['allowedfilesize']}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    }

                                    <div className="row" id="showCrop">
                                        <div className="col-sm-12">
                                            {this.state.src && (
                                                <ReactCrop
                                                    src={this.state.src}
                                                    crop={this.state.crop}
                                                    ruleOfThirds
                                                    onImageLoaded={this.onImageLoaded}
                                                    onComplete={this.onCropComplete}
                                                    onChange={this.onCropChange}
                                                />

                                            )}
                                            {
                                                this.state.croppedImageUrl == "" && this.state.croppedImageUrlrevert == "" && this.state.src == "" ? "" :
                                                    <div className="row">
                                                        <div className="col-sm-12"><button className="btn btn-md btn-primary float-left" onClick={e => this.onUpdate()}> Update </button>
                                                            <button className="btn btn-md btn-primary float-right" onClick={e => this.onCancel()}> Cancel </button>
                                                            <br /><br /> <br /></div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                    <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                        <div className="row form-row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group form-focus">
                                                    <Field name="firstName" component={renderField} label={languagecontent['firstname']} maxLength={50} validate={[required]} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group form-focus">
                                                    <Field name="lastName" component={renderField} label={languagecontent['lastname']} maxLength={50} validate={[required]} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group form-focus focused">
                                                    <Field name="gender" select={languagecontent['select']} options={gender} component={renderRequiredSelect} validate={[required]} className="form-control" />
                                                    <label className="focus-label" style={{ background: "transparent" }}>{languagecontent['gender']}</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <input hidden={true} id="dagelimit" value={_doblimit}></input>

                                                <div className={dateofbirth == "" ? "form-group form-focus focused" : "form-group form-focus focused"}>
                                                    <div className="cal-icon">
                                                        {dateofbirth == "" ?
                                                            <Field name="dob" component={renderDob} label={languagecontent['dob'] == undefined ? "" : languagecontent['dob'] + " " + "(" + dateformat + ")"} maxLength={15} validate={[required]} type="text" />
                                                            : <Field name="dob" component={renderDob} label={languagecontent['dob'] == undefined ? "" : languagecontent['dob']} maxLength={15} validate={[required]} type="text" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group form-focus focused">
                                                    <Field name="relationship" select={languagecontent['select']} options={relationship} component={renderRequiredSelect} validate={[required]} className="form-control" />
                                                    <label className="focus-label" style={{ background: "transparent" }}>{languagecontent['relationship']}</label>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group form-focus ">
                                                    <Field name="cellPhone" component={renderField} label={languagecontent['cellphone']} validate={[phoneNumber]} maxLength={15} minLength={15} type="text" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group form-focus">
                                                    <Field name="email" component={renderField} label={languagecontent['email']} validate={[email]} maxLength={50} minLength={10} type="email" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group form-focus">
                                                    <button ref="btn" disabled={this.props.isPostLoading} className="btn btn-lg btn-primary float-left" type="submit"> {languagecontent['save'] == undefined ? <i className="fa fa-spinner fa-spin"></i> : languagecontent['save']}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
const mapDispatchToProps = { ...FamilyMemberStore.actionCreators, ...CategoryStore.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...MemberSpecialityStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => {
    let values = {
        initialValues: { firstName: familydetail.FirstName, lastName: familydetail.LastName, gender: familydetail.Gender, dob: (familydetail.DOB == null) || (familydetail.DOB == "") ? "" : dateFormatSetting(familydetail.DOB), relationship: rel.value, cellPhone: familydetail.ContactNumber, email: familydetail.Email },
        ...state.familyMembers, ...state.category, ...state.account, ...state.langResources, ...state.speciality,
    };
    return values;   
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'familyMemberForm',
    enableReinitialize: true,
})(FamilyMember as any))

export const ProfileLoader = (props: any) => (
    <div className="row">
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
        <div className="col-sm-3">
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={60}
                width={300}
                viewBox="0 5 300 60"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
            </ContentLoader>
        </div>
    </div>
)

function toDate(date: any) {
    if (date != null) {
        var currentDateTime = new Date(date);
        return currentDateTime
    }
}
async function isBase64UrlImage(base64String: any) {
    let image = new Image()
    image.src = base64String
    return await (new Promise((resolve) => {
        image.onload = function () {
            if (image.height === 0 || image.width === 0) {
                resolve(false);
                return;
            }
            resolve(true)
        }
        image.onerror = () => {
            resolve(false)
        }
    }))
}