import React from "react";
import { Redirect, Route } from "react-router-dom";
import axios from 'axios';
export const PublicRoute = ({ component, path, ...restOfProps }: { component: any; path: any }) =>
{
    
    const Component = component;
  
    var isAuthenticated = localStorage.getItem("isAuth") || "false";
    //console.log(isAuthenticated, "isAuthenticated -login")
    return (
        <Route path={path}
            {...restOfProps}
            render={(props) =>
                
                !isAuthenticated ? <Redirect to="/login" path='/login' /> : isAuthenticated == "false" ? <Component {...props} /> : isAuthenticated == "true" ? <Redirect to="/dashboard" path='/dashboard'/> : ""
               
                }
        />
                );

}

export const ProtectedRoute= ({ component, path, ...restOfProps }: { component: any; path: any }) => {
    const Component = component;

        var isAuthenticated = localStorage.getItem("isAuth") || "false";
    
    return (
        <Route path={path}
            {...restOfProps}
            render={(props) =>
               
                !isAuthenticated ? pageredirect() : isAuthenticated == "true" ? < Component {...props} /> : isAuthenticated == "false" ? pageredirect():""
                
                }
        />
        );
 
}

function pageredirect() {
    axios.get('account/logout')
        .then(function (response:any) {
            localStorage.setItem("isAuth", "false");
            console.log("custom route tsx")
            localStorage.removeItem("Patient");
            //localStorage.removeItem("isAuth");
            localStorage.removeItem("pathName");
            localStorage.removeItem("redirectUrl");
            localStorage.removeItem("redirectQequestQueueUrl");
        })
        .catch(function (error:any) {
        })
        .then(function () {
      });
    return window.location.href = "/login";
}




