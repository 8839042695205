import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as FlexiFormStore from '../../store/library/flexiform/FlexiForm';
import * as ContactStore from '../../store/patient/Contact';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import * as LoginStore from '../../store/account/Login';
import FlexiFormClass from '../../components/library/flexiform/FlexiForm'
import { scrollTop } from '../../helper/formvalidation';
// At runtime, Redux will merge together...
type FormsProps =
	FlexiFormStore.FlexiListsState & ContactStore.ContactState & LoginStore.LoginState // ... state we've requested from the Redux store
	& typeof FlexiFormStore.actionCreators & typeof ContactStore.actionCreators & typeof LoginStore.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters

class InitialPageForm extends React.PureComponent<FormsProps> {
	constructor(props: any) {
		super(props);
	}

	// This method is called when the component is first added to the document
	public componentDidMount() {
		window.addEventListener('popstate', function (event) {
			this.window.history.forward();
		}, false);
		this.ensureDataFetched();
		scrollTop();
	}


	public render() {
		return (
			<React.Fragment>
				{this.renderDoctorList()}
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		this.props.getPtaient();
		//this.props.requestFlexiForm(this.props.contact.id, "", "", "", this.props.contact.id, "");
	}

	private renderDoctorList() {
		//console.log(this.props.patient);
		return (
			<div className="content">
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-lg-12 col-xl-12">
							<div className="card">
								<div className="card-body">
									<FlexiFormClass />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({ ...state.flexiform, ...state.contact, ...state.login }), // Selects which state properties are merged into the component's props
	({ ...FlexiFormStore.actionCreators, ...ContactStore.actionCreators, ...LoginStore.actionCreators })// Selects which action creators are merged into the component's props
)(InitialPageForm as any);
