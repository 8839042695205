import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface AccountState {
    Account: Account,
    Settings: any,
    isSettingsLoading:boolean
}

export interface Account {
    id: string;
}
export interface Settings {
    Setting: [];
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestAccountAction { type: 'REQUEST_ACCOUNT', Account: Account }
export interface ReciveAccountAction { type: 'RECEIVE_ACCOUNT', Account: Account }
export interface ReciveSiteSettingAction { type: 'RECEIVE_SITE_SETTING', Settings: any }
export interface RequestSiteSettingAction { type: 'REQUEST_SITE_SETTING', Settings: any }


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestAccountAction | ReciveAccountAction | ReciveSiteSettingAction | RequestSiteSettingAction;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    getDefaultAccount: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.location) {
            axios.get('/site/getdefaultaccount', { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const result = response.data;
                                     
                    localStorage.setItem("AccountId", result.id);
                    dispatch({ type: 'RECEIVE_ACCOUNT', Account: result });
                    dispatch(actionCreators.getSiteSetting(null));
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_ACCOUNT' });
        }
    },

    getSiteSetting: (name: any): AppThunkAction<KnownAction> => async(dispatch: any, getState) => {
        const appState = getState();
        if (name == null)
            name = "-";
        const accountId = localStorage.getItem("AccountId");
        if (appState && appState.location) {
            axios.get('/site/sitesettings/' + name + "/" + accountId, { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const result = response.data;
                    /* console.log(result)*/

                    var enable_familymember:any =""
                    var enablefamilymember: any = result.filter((s: any) => s.name == "Enable Family Member")
                    
                    if (enablefamilymember.length == 0)
                        enable_familymember = null
                    else
                        enable_familymember = enablefamilymember[0].customizeValue

                    localStorage.setItem("familymember", enable_familymember);
                    
                    const filetypeFilter = response.data.filter(function (value:any) {
                        return value.name === "Time Zone"
                    });
                    const currency = response.data.filter(function (value: any) {
                        return value.name === "Currency"
                    });
                    currency.forEach((value: any) => {
                        var displaycode_currency = value.displayCode;
                        
                        localStorage.setItem("currency", displaycode_currency);
                    })
                    filetypeFilter.forEach((value: any) => {
                        var displaycode = value.displayCode;
                        const myArray = displaycode.split("|");
                        localStorage.setItem("timeOffset", myArray[1]);
                    })

                    var preferredtimeslot: any = [];
                    var pickaprovider: any = [];

                    preferredtimeslot = response.data.filter((s: any) => s.name == "Client preferred time slot");
                    if (preferredtimeslot.length != 0)
                        preferredtimeslot = preferredtimeslot[0].customizeValue;
                    else
                        preferredtimeslot = null;
                    localStorage.setItem("preferredtimeslot", preferredtimeslot);

                    pickaprovider = response.data.filter((s: any) => s.name == "Auto Provider Selection in PickaProvider");
                    if (pickaprovider.length != 0)
                        pickaprovider = pickaprovider[0].customizeValue;
                    else
                        pickaprovider = null;
                    localStorage.setItem("pickaprovider", pickaprovider);

                    // localStorage.setItem("AccountId", result.id);
                    dispatch({ type: 'RECEIVE_SITE_SETTING', Settings: result, isSettingsLoading: false });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_ACCOUNT', isSettingsLoading:true});
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: AccountState = {
    Account: { id: "" },
    Settings: [],
    isSettingsLoading:false
};
export const reducer: Reducer<AccountState> = (state: AccountState | undefined, incomingAction: Action): AccountState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ACCOUNT':
            return {
                Account: state.Account,
                Settings: state.Settings,
                isSettingsLoading: true
            };
        case 'RECEIVE_ACCOUNT':
            return {
                Account: action.Account,
                Settings: state.Settings,
                isSettingsLoading: false

            };
        case 'RECEIVE_SITE_SETTING':
            return {
                Account: state.Account,
                Settings: action.Settings,
                isSettingsLoading: false

            };
        case 'REQUEST_SITE_SETTING':
            return {
                Account: state.Account,
                Settings: action.Settings,
                isSettingsLoading: true

            };
        default:
            return state;
    }
};
