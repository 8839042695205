import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';

export interface FailState {
    isFailLoading: boolean;
    fail: Fail;
}

export interface Fail {
    name: string;
    appointmentDate: string;
    scheduleDate: string;
    time: string
    referenceNo: string,
    statusId: any;
}


interface RequestFailAction {
    type: 'REQUEST_APPOINTMENT_FAIL';
    isFailLoading: boolean;
}
interface ReceiveFailAction {
    type: 'RECEIVE_APPOINTMENT_FAIL';
    fail: Fail;
    isFailLoading: boolean;

}

type KnownAction = RequestFailAction | ReceiveFailAction

export const actionCreators = {
    requestFail: (route: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.success) {
            var accountid: any = localStorage.getItem("AccountId");
            axios.get('/appointment/getfailed')
                .then(function (response) {
                    const data = response.data;
                    dispatch({
                        type: 'RECEIVE_APPOINTMENT_FAIL', fail: UnloadedState.fail, isFailLoading: false
                    });
                })
                .catch(function (error) {
                    dispatch({
                        type: 'RECEIVE_APPOINTMENT_FAIL', fail: UnloadedState.fail, isFailLoading: false
                    });
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({
                type: 'REQUEST_APPOINTMENT_FAIL', isFailLoading: true
            });
        }
    },
    requestSaveAppointment: (url: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        setTimeout(function () {
            const myArray = url.split("/");
            dispatch(push("/paymentprocess/" + myArray[4] + "/" + myArray[5] + "/" + myArray[6] + "/" + myArray[7]));
        }, 1000);
    },
};


const UnloadedState: FailState = {
    fail: { name: "", appointmentDate: "", scheduleDate: "", time: "", referenceNo: "", statusId: "" }, isFailLoading: false
};


export const reducer: Reducer<FailState> = (state: FailState | undefined, incomingAction: Action): FailState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENT_FAIL':
            return {
                fail: state.fail,
                isFailLoading: true,

            };
        case 'RECEIVE_APPOINTMENT_FAIL':
            return {
                fail: action.fail,
                isFailLoading: false,

            };
        default:
            return state;
    }
};

