import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { setTittle } from '../../helper/formvalidation'
import { seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { reduxForm } from 'redux-form';
import { ApplicationState } from '../../store/index';
import * as MenuHideStore from '../../store/menuHide';
type EmailActivationProps =
    LanguageResourcesStore.LanguageResouresState & MenuHideStore.MenuHideState &
    typeof LanguageResourcesStore.actionCreators & typeof MenuHideStore.actionCreators &
    RouteComponentProps<{}>;
var languagecontent: any = "";
class EmailActivation extends React.PureComponent<EmailActivationProps> {
    constructor(props: any) {
        super(props);
    }
   public  componentDidMount() {
        //seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
        this.ensureDataFetched();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderPaymentDetail()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {
        this.props.requestMenuHide(false);
        this.props.getLangResources();
    }
    private renderPaymentDetail() {
        languagecontent = this.props.LanguageResoures;
        return (
            <React.Fragment>
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center ">

                                    <div className="col-md-12 col-lg-8 login-right">
                                        <p><i className="fa fa-check-circle fa-2" style={{ color: 'green' }} aria-hidden="true"></i> {languagecontent['verificationlink']}</p>
                                        <hr />
                                        <p>{languagecontent['retrivepassword']}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
            )
    }
}


const mapDispatchToProps = { ...LanguageResourcesStore.actionCreators, ...MenuHideStore.actionCreators  };
const mapStateToProps = (state: ApplicationState) => ({ ...state.langResources, ...state.menuHide })
export default reduxForm({
    form: 'emailactivation',
})(connect(mapStateToProps, mapDispatchToProps)(EmailActivation as any))

