import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { errorHandling } from '../helper/ErrorHandling';
import { actionCreators as _LangResources } from '../store/LanguageResoures';
import { actionCreators as _clientData } from './ClientData';
import { actionCreators as _dashboard } from './patient/Dashboard';
import { actionCreators as _message } from './patient/Message';
import { actionCreators as _editmessage } from './message/editMessage';
import { actionCreators as _visitdetail } from './appointment/VisitDetail';
import { actionCreators as _documentListAction } from './library/document/DocumentList';
import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel
} from '@microsoft/signalr';
import { data } from 'jquery';
export interface SiteDetailState {
    IsLoading: boolean;
    IsSiteLoading: boolean;
    siteDetail: SiteDetail;
}

export interface SiteDetail {
    Id: string,
    PageGroupCode: string,
    CPHostName: string,
    Code: string,
    Name: string,
    Logo: string,
    LogoPath: string;
    CPStyle: string,
    FavIconPath: string,
    FavIconType: string,
    LayoutSettings: any,
}



interface RequestHeaderDrProfile {
    type: 'REQUEST_SITE_DETAIL';
}

interface ReceiveHeaderDrProfile {
    type: 'RECEIVE_SITE_DETAIL';
    siteDetail: SiteDetail;
}

type KnownAction = RequestHeaderDrProfile | ReceiveHeaderDrProfile;

export const actionCreators = {
    requestSiteDetail: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.siteDetail) {
            axios.get('site/getsitedetail', { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const data = response.data;
                    window.name = data.Name;
                    localStorage.setItem("siteName", data.Name);
                    var layout = data.LayoutSettings.filter((ca: any) => ca.Name == "CPHeader")[0];
                    localStorage.removeItem("siteThemeColor");
                    if (layout.Value == "NavDefault") {
                        localStorage.setItem("siteThemeColor", "NavDefault");
                    } else if (layout.Value == "NavVirtuvets") {
                        localStorage.setItem("siteThemeColor", "NavVirtuvets");
                    } else if (layout.Value == "NavTheraque") {
                        localStorage.setItem("siteThemeColor", "NavTheraque");
                    } else if (layout.Value == "NavPrime") {
                        localStorage.setItem("siteThemeColor", "NavPrime");
                    } else if (layout.Value == "NavGoodoc") {
                        localStorage.setItem("siteThemeColor", "NavGoodoc");
                    } else if (layout.Value == "NavABC") {
                        localStorage.setItem("siteThemeColor", "NavABC");
                    } else if (layout.Value == "NavWelshdale") {
                        localStorage.setItem("siteThemeColor", "NavWelshdale");
                    } else if (layout.Value == "NavWelshdale") {
                        localStorage.setItem("siteThemeColor", "NavMetaCare");
                    } else if (layout.Value == "NavSanthigram") {
                        localStorage.setItem("siteThemeColor", "NavSanthigram");
                    }
                    else if (layout.Value == "NavSmarthealthtec") {
                        localStorage.setItem("siteThemeColor", "NavSmarthealthtec");
                    }
                    else if (layout.Value == "Nav247DCT") {
                        localStorage.setItem("siteThemeColor", "Nav247DCT");
                    }
                    else if (layout.Value == "NavVirtualCare") {
                        localStorage.setItem("siteThemeColor", "NavVirtualCare");
                    }
                    else if (layout.Value == "NavAmarac") {
                        localStorage.setItem("siteThemeColor", "NavAmarac");
                    }
                    else {
                        localStorage.setItem("siteThemeColor", "NavDefault");
                    }
                    if (data.CPStyle != "" && data.CPStyle != null) {
                        var styles = JSON.parse(data.CPStyle);
                        localStorage.setItem("siteTheme", data.CPStyle);
                    }
                    else {
                        styles = {
                            "primary-color": "#15558d",
                            "secondary-color": "#15558d",
                            "link-color": "#272b41",
                            "title-color": "#272b41",
                            "subtitle-color": "#272b41",
                            "lightDescription-color": "#757575",
                            "fillText-color": "#fff",
                            "bodyText-color": "#272b41"
                        };

                        localStorage.setItem("siteTheme", JSON.stringify(styles));
                    }



                    localStorage.setItem("SiteCode", data.Code)

                    Object.keys(styles).forEach(function (key) {
                        document.documentElement.style.setProperty('--' + key, styles[key]);
                    })
                    dispatch({ type: 'RECEIVE_SITE_DETAIL', siteDetail: data, IsSiteLoading: false });
                    dispatch(_clientData.requestClientData(data.Code, data.HostName));
                    dispatch(actionCreators.getAccount());
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_SITE_DETAIL', IsSiteLoading: true });
        }
    },
    getAccount: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.location) {
            axios.get('/site/getdefaultaccount', { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const result = response.data;
                    localStorage.setItem("AccountId", result.id);
                    dispatch(actionCreators.requestSiteDefaultLanguage(result.id));
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
        }
    },


    requestSiteDefaultLanguage: (accountid: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.siteDetail) {
            var accountid: any = localStorage.getItem("AccountId");
            axios.get('/site/GetSiteDefaultLanguage/' + accountid)
                .then(function (response) {
                    const data = response.data;
                    var array: any = [];
                    if (data.Default != undefined) {
                        array = data.Default.split('|');
                        var langcode: any = localStorage.getItem("DefaultLangCode");
                        if (langcode == null)
                            localStorage.setItem("DefaultLangCode", array[0])
                        else
                            localStorage.setItem("DefaultLangCode", langcode)

                    } else {
                        localStorage.setItem("DefaultLangCode", "en-us")
                    }
                    dispatch(_LangResources.getLangResources());

                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
        }
    },
    getSignalRCredential: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.siteDetail) {
            axios.get('/site/GetSignalCredential', { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    dispatch(actionCreators.signalR(response.data.key, response.data.url));
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
        }
    },
    signalR: (key: any, url: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();

        if (localStorage.getItem("isAuth") == "false" || localStorage.getItem("isAuth") == null) {
            return;
        } else {           
                var patient = JSON.parse(localStorage.getItem("Patient") || "");
                if (appState && appState.siteDetail) {
                    let connection = new HubConnectionBuilder()
                        .withUrl(url)
                        .build();
                    connection.on("broadcastMessage", data => {
                        // console.log(data);
                        const _array: any = window.location.pathname.split('/');
                        if (data.Type == "Success" || data.Type == "Message" )  {
                            if (_array[1] == "dashboard") {
                                dispatch(_dashboard.requestAppointmentList());
                            }
                            if (_array[1] == "visitdetail") {
                                var appointment: any = { appointmentid: _array[2] }
                                dispatch(_visitdetail.requestVisitDetail(appointment));
                                const urlParams = new URLSearchParams(window.location.search);
                                const memberid = urlParams.get('memberid');
                                dispatch(_documentListAction.requestDocumentLists(memberid, "Patient"));
                            }
                            if (_array[1] == "message") {
                                dispatch(_message.requestMessageList());
                                }
                            if (_array[1] == "replymessage") {
                                    dispatch(_editmessage.requestReplyMessage());
                                }
                            }
                        
                    });
                    var azureFucURL = url;
                    connection.start()
                        .then(() => {
                            var groupAPI = azureFucURL + "/addToGroup/" + patient.userId + "/" + connection.connectionId;
                            const options: any = {
                                url: groupAPI,
                                method: 'POST',
                                timeout: 0,
                                headers: { "x-functions-key": key },
                            };
                            axios(options).then(function (response) {

                            }).catch(error => {
                                console.log(error);
                            })
                                .then(function () {
                                });;
                        });
                }
            }
        
    },
};


const unloadedState: SiteDetailState = {
    siteDetail: {
        Id: "",
        PageGroupCode: "",
        Code: "",
        CPHostName:"",
        Name: "",
        Logo: "",
        LogoPath: "",
        CPStyle: "",
        FavIconPath: "",
        FavIconType: "",
        LayoutSettings: [],
    }, IsLoading: false,
    IsSiteLoading: false,

};

export const reducer: Reducer<SiteDetailState> = (state: SiteDetailState | undefined, incomingAction: Action): SiteDetailState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SITE_DETAIL':
            return {
                siteDetail: state.siteDetail,
                IsLoading: true,
                IsSiteLoading: true
            };
        case 'RECEIVE_SITE_DETAIL':
            if (action.siteDetail !== state.siteDetail) {
                return {
                    siteDetail: action.siteDetail,
                    IsLoading: false,
                    IsSiteLoading: false
                };
            }
            break;
    }
    return state;
};
