import axios from 'axios';
import { push } from 'connected-react-router';
import { Action, Reducer } from 'redux';
import { reset } from 'redux-form';
import { AppThunkAction } from '../../';
import { errorHandling } from '../../../helper/ErrorHandling';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { actionCreators as _documentAction } from '../../library/document/DocumentList';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
var recordtypeid: any = null;
var statusid: any = null;
var moduleId: any = null;


export interface DocumentUploadState {
    isLoading: boolean;
    docIds: string;
    documentLists: DocumentList[];
}
export interface DocumentList {
    id: string;
    patientId: string;
    memberId: string;
    encounterId: string;
    categoryId: number;
    note: string;
    fileName: string;
    contentType: string;
    filePath: string;
    thumbNail: string;
    moduleName: string;
    content: string;
    size: number;
    statusId: number;
    recordId: string;
    recordTypeId: number;
    modifiedBy: string;
    modifiedOn: string;
}


interface RequestDocument {
    type: 'REQUEST_DOCUMENT';
}

interface ReceiveDocument {
    type: 'RECEIVE_DOCUMENT';
    documentLists: DocumentList[];
    docIds: string;
}


type KnownAction = RequestDocument | ReceiveDocument;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
var _resultIds: any = [];
export const actionCreators = {
    requestSaveDocument: (formvalue: any, patient: any, files: any, module: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        var data = window.location.pathname;
        var patientId: any = "";
        const _array = data.split('/');        
        var recordid:any = "";
        if (_array[1] == "visitdetail") {
            var encounterid: any = _array[2];
            recordid = encounterid;
            moduleId = 36;
            statusid = 1;
            var urlParams:any = new URLSearchParams(window.location.search);
            var patientId: any = urlParams.get('patientid');
            var memberId: any = patientId
        }
        else {
            var encounterid = null;
            recordid = null;
            moduleId = null;
        }
        var locationdata = window.location.pathname;
        var _modulename: any = locationdata.split('/')[1];

       // console.log(_modulename)


        if (_modulename == "insurance") {
            recordtypeid = "124";
            patientId = null;
            moduleId = 35;
            var memberId: any = patient.id
            var urlParams: any = new URLSearchParams(window.location.search);
            var memberId: any = urlParams.get('memberid');
        }
        if (_modulename == "addinsurance") {
            recordtypeid = "124";
            patientId = null;
            moduleId = 35;
            var urlParams: any = new URLSearchParams(window.location.search);
            var memberId: any = urlParams.get('memberid');
        }

        if (_modulename == "conditions") {
            statusid = 3
            moduleId = 36
            var urlParams:any = new URLSearchParams(window.location.search);
            var patientId:any = urlParams.get('childid') === null || urlParams.get('childid') === "null" ? urlParams.get('patientid') || urlParams.get('patientId') : urlParams.get('childid')
            var memberId: any = patientId
        }
        


        let documentValues: any = [];
        files.forEach((file: any, index: any) => {
            let bas64 = "";
            file.content.then((bs: any) => { bas64 = bs })
            setTimeout(() => {
                let object = {
                    FileName: file.name, size: file.size, ContentType: file.type == "" ? "dcm" : file.type,
                    Note: formvalue.Note, CategoryId: formvalue.type, PatientId: patientId, MemberId: memberId, ModuleName: module, ModuleId: moduleId, EncounterId: encounterid, recordTypeId: recordtypeid, recordId: recordid,
                    Content: bas64, StatusId: statusid
                }
                documentValues[index] = object
            }, 2)
        });
        setTimeout(() => {
            // console.clear()
            dispatch(showLoading('sectionBar'));
            if (appState && appState.documentupload) {
                axios.post('document/DocumentUpload', documentValues)
                    .then(response => {
                        var result = response.data;
                        localStorage.removeItem("Documents");
                        var locationdata = window.location.pathname;
                        var _modulename: any = locationdata.split('/')[1];
                        if (_modulename == "insurance") {
                            var arrays: any = result.split(",");
                            arrays.forEach((id: any, index: any) => {
                                _resultIds.push(id);
                            });
                        }
                        if (_modulename == "addinsurance") {
                            var arrays: any = result.split(",");
                            arrays.forEach((id: any, index: any) => {
                                _resultIds.push(id);
                            });
                        }
                        dispatch(reset("document"));
                        dispatch(hideLoading('sectionBar'))
                        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                        toastr.success('', langdata['uploadsuccessfully'])
                        localStorage.removeItem("DocIds");
                        let element: HTMLElement = document.getElementsByClassName('close')[0] as HTMLElement;
                        element.click();
                        if (recordtypeid == "124") {
                            localStorage.setItem("DocIds", JSON.stringify(_resultIds));
                            dispatch({ type: 'RECEIVE_DOCUMENT', documentLists: result, docIds: result });
                            dispatch(_documentAction.requestDocumentLists(patient.memberId, "Patient"));
                        } else {
                            dispatch({ type: 'RECEIVE_DOCUMENT', documentLists: result });
                            dispatch(_documentAction.requestDocumentLists(patient.memberId, "Patient"));
                        }
                    })
                    .catch(error => {
                        if (error.response.data.statusCode == 400) {
                            var errorObject = JSON.parse(error.response.data.errorMessage);
                            dispatch(hideLoading('sectionBar'))
                            toastr.error('', errorObject.Message);
                        } else {
                            errorHandling(error.response.data.statusCode);
                        }
                        localStorage.removeItem("Documents");
                        dispatch({ ...hideLoading('sectionBar') })
                    });
                dispatch({ type: 'REQUEST_DOCUMENT' });
            }
        }, 2000)

    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
const unloadedState: DocumentUploadState = {
    documentLists: [],
    docIds: "",
    isLoading: false
};
export const reducer: Reducer<DocumentUploadState> = (state: DocumentUploadState | undefined, incomingAction: Action): DocumentUploadState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DOCUMENT':
            return {
                documentLists: state.documentLists,
                docIds: state.docIds,
                isLoading: true
            };
        case 'RECEIVE_DOCUMENT':
            return {
                documentLists: action.documentLists,
                docIds: action.docIds,
                isLoading: false
            };
        default:
            return state;
    }
};


