import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { errorHandling } from '../helper/ErrorHandling';
import { getPageGroup } from '../helper/formvalidation';


export interface LanguageResouresState {
    IsLoading: boolean;
    LanguageResoures: any;
}

export interface LanguageResoures {
    Id: string,
    PageGroupCode: string,
    Code: string,
    Name: string,
    Logo: string,
    CPStyle: string,
    FavIconPath: string,
    FavIconType: string,
    LayoutSettings: any,
}

interface RequestHeaderDrProfile {
    type: 'REQUEST_LANG_RESOURCES';
    LanguageResoures: any;
}

interface ReceiveHeaderDrProfile {
    type: 'RECEIVE_LANG_RESOURCES';
    LanguageResoures: any;
}

type KnownAction = RequestHeaderDrProfile | ReceiveHeaderDrProfile;

export const actionCreators = {
    getLangResources: (): AppThunkAction<KnownAction> => async (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.langResources) {
            var sitecode: any = localStorage.getItem("SiteCode");
            var languagecode: any = localStorage.getItem("DefaultLangCode");
            var data: any = window.location.pathname;
            var group: any = "";
            const _array = data.split('/');
            if (_array.length >= 1)
                group = _array[1];
            var groups: any = getPageGroup(group);
            axios.get('LangResources/GetLangResources/' + "1" + "/" + languagecode + "/" + sitecode + "/" + groups, { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const data = response.data;
                    localStorage.setItem("LangResources", JSON.stringify(data));
                    dispatch({ type: 'RECEIVE_LANG_RESOURCES', LanguageResoures: data.LANG_VALUES });
                    dispatch(actionCreators.getLangResourcesValidations());
                })
                .catch(function (error) {
                    //errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_LANG_RESOURCES' });
        }
    },
    getLangResourcesValidations: (): AppThunkAction<KnownAction> => async (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.langResources) {
            var sitecode: any = localStorage.getItem("SiteCode");
            var languagecode: any = localStorage.getItem("DefaultLangCode");
            var data: any = window.location.pathname;
            var group: any = "";
            const _array = data.split('/');
            if (_array.length >= 1)
                group = _array[1];
            axios.get('LangResources/GetLangResources/' + "1" + "/" + languagecode + "/" + sitecode + "/" + "validation", { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const data = response.data;
                    localStorage.setItem("LangResourcesValidations", JSON.stringify(data.LANG_VALUES));
                })
                .catch(function (error) {
                    //errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
        }
    },
    prefferedLanguage: (): AppThunkAction<KnownAction> =>  (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.langResources) {
            var languagecode: any = localStorage.getItem("DefaultLangCode");
            const patientLocalStroage: any = localStorage.getItem('Patient');
            const patient: any = JSON.parse(patientLocalStroage);


             axios.post('/familymember/PreferredLanguageUpdate', { Id: patient.memberId, languagecode: languagecode })
                .then(function (response) {
                    const data = response.data;
                  
 
                })
                 .catch(function (error) {

                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
        }
    }
};


const unloadedState: LanguageResouresState = {
    LanguageResoures: [],
    IsLoading: false,
};

export const reducer: Reducer<LanguageResouresState> = (state: LanguageResouresState | undefined, incomingAction: Action): LanguageResouresState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LANG_RESOURCES':
            return {
                LanguageResoures: state.LanguageResoures,
                IsLoading: true,
            };
        case 'RECEIVE_LANG_RESOURCES':
            if (action.LanguageResoures !== state.LanguageResoures) {
                return {
                    LanguageResoures: action.LanguageResoures,
                    IsLoading: false,
                };
            }
            break;
    }
    return state;
};


export const filterLanguagedata = (name: any, language: any) => {
    return language[name];
}