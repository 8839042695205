import * as React from 'react';
import { connect } from 'react-redux';

const norecordsfound = () => (
	<React.Fragment>
	<div className="content">
		<div className="container-fluid">
			<div className="row">
				<div className=" col-xl-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-12">
									
									<div className="text-center p-3">
										<img src="client/logo/norecordfound.png" className="img-fluid no-records" alt="User Image" />
										<p>No records found</p>
									</div>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
	</React.Fragment>
);

export default connect()(norecordsfound);