import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import {  setTittle } from '../../helper/formvalidation';
import { ApplicationState } from '../../store/index';
import * as PaymentStore from '../../store/payment/Payment';
import * as LoginStore from '../../store/account/Login';
import * as HandleRequest from '../../helper/Request';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import {  mobnav, seo } from '../../helper/Script';

type ConditionProps =
	PaymentStore.PaymentState & LoginStore.LoginState & HandleRequest.Request & LanguageResourcesStore.LanguageResouresState &
	typeof PaymentStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof LoginStore.actionCreators &
	RouteComponentProps<{}>;
var languagecontent: any = "";
class Payment extends React.PureComponent<ConditionProps> {
	constructor(props: any) {
		super(props);
	}
	public componentDidMount() {
		this.ensureDataFetched();
		this.props.requestPostPayment(this.props.match.params);
		mobnav();
		
	}
	componentDidUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderPayment()}
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		this.props.getLangResources();
		this.props.getPtaient();
	}
	
	public renderPayment() {
		languagecontent = this.props.LanguageResoures;
		return (
			<React.Fragment>
				<div className="content">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-lg-6">
								<div className="card success-card">
									<div className="card-body">
										<h3 className="text-center"><i className="fa fa-spinner fa-spin"></i> { languagecontent['pleasewait']}</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
const mapDispatchToProps = ({ ...LoginStore.actionCreators, ...PaymentStore.actionCreators, ...LanguageResourcesStore.actionCreators });
const mapStateToProps = (state: ApplicationState) => ({ ...state.condition, ...state.login, ...state.Payment , ...state.langResources });
export default reduxForm({
	form: 'conditionForm',
})(connect(mapStateToProps, mapDispatchToProps)(Payment as any))


