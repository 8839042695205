import * as React from 'react';
export default class AccessDenied extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderPageNotFound()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {

    }
    public renderPageNotFound() {
        return (
            <React.Fragment>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="main-wrapper">
                                            <div className="error-box">
                                                <h1></h1>
                                                <h3 className="h2 mb-3"><i className="fa fa-warning"></i> Access Denied</h3>
                                                <p className="h4 font-weight-normal">You do not have permission to view this page.</p>
                                                <a href="/dashboard" className="btn btn-lg btn-primary">Back to Dashboard</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
