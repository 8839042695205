import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as ContactStore from '../../store/patient/Contact';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import { seo } from '../../helper/Script';
import ContentLoader from 'react-content-loader';
import Moment from 'moment';
import * as TransactionStore from '../../store/patient/Transaction';
import * as InsuranceListStore from '../../store/insurance/insuranceList';
import * as AddInsuranceStore from '../../store/insurance/addInsurance';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { dateFormat, dateUTCFormat, dateFormatConvert, timeFormatConvert, dateTimeFormat, setTittle, insuranceVerificationStatusColor} from '../../helper/formvalidation';
import { Button } from 'reactstrap';
// At runtime, Redux will merge together...
type DocumentListProps =
    ContactStore.ContactState & TransactionStore.TransactionDetailState & InsuranceListStore.InsuranceListState & AddInsuranceStore.AddInsuranceState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
    & typeof ContactStore.actionCreators & typeof TransactionStore.actionCreators & typeof InsuranceListStore.actionCreators & typeof AddInsuranceStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var patientLocalStroage: any = localStorage.getItem('Patient');
var pat = JSON.parse(patientLocalStroage);
var activeInsurances: any = [];
var inActiveInsurances: any = [];
var languagecontent: any = "";
var langdata: any = "";
class InsurenceList extends React.PureComponent<DocumentListProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        localStorage.removeItem("InsuranceName");
        localStorage.removeItem("InsuredDOB");
        localStorage.removeItem("ExpiryDate");
        this.ensureDataFetched();
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderTransactionList()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {
        this.props.getLangResources();
        const queryParams = new URLSearchParams(window.location.search);
        var memberid = queryParams.get('memberid');
        this.props.getInsuranceList(memberid);
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }
    convertDate(time: any) {

        return Moment.utc(time).utcOffset(+parseInt(localStorage.getItem('timeOffset') || "")).format('D MMM YYYY')
    }
    convertTime(time: any) {

        return Moment.utc(time).utcOffset(+parseInt(localStorage.getItem('timeOffset') || "")).format('LT')
    }
    InsuranceInActive = (value: any) => {
        //var langResources: any = JSON.parse(localStorage.getItem("LangResources") || "");
        //var language: any = langResources.filter((la: any) => la.Group == "insurancelistview");
        //var inauranceinactive: any = language.filter((la: any) => la.Name == "insurancinactive");
        //console.log(value)
        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        if (value.PlatformType == null) {
            value.PlatformType = "CP";
        }
        var r = window.confirm(langdata['insurancinactive']);
        if (r == true) {
            //value.StatusId = "2";
            this.props.postInsurance(value, "delete","2");
        } else {
            return;
        }
    }
    InsuranceActive = (value: any) => {
        //var langResources: any = JSON.parse(localStorage.getItem("LangResources") || "");
        //var language: any = langResources.filter((la: any) => la.Group == "insurancelistview");
        //var inauranceactive: any = language.filter((la: any) => la.Name == "insuranceactive");
        //console.log(value)
        var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        var r = window.confirm(langdata['insuranceactive']);
        if (value.PlatformType == null) {
            value.PlatformType = "CP";
        }
        if (r == true) {
            //value.StatusId = "1";

            this.props.postInsurance(value, "delete","1");
        } else {
            return;
        }
    }
    editInsurance = (value: any) => {
        localStorage.setItem("InsuranceName", value.InsuranceName);
        localStorage.setItem("InsuredDOB", value.InsuredDOB);
        localStorage.setItem("ExpiryDate", value.ExpiryDate);
        this.props.editInsuranceList(value);
    }
    addInsuranceList = () => {
        this.props.addInsuranceList();
    }
    private renderTransactionList() {
        activeInsurances = this.props.InsuranceLists.filter((se: any) => se.StatusId == "1");

        inActiveInsurances = this.props.InsuranceLists.filter((se: any) => se.StatusId == "2");
        languagecontent = this.props.LanguageResoures;
        //console.log(this.props.InsuranceLists)
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <DashboardNavMenu />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div id="pat_appointments" className="tab-pane fade show active">

								{
                                    this.props.isLoading == true ? <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
										<rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
										<rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
										<rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
									</ContentLoader> :
										<div>
											{
                                                this.props.InsuranceLists.length == 0 ? <div> {languagecontent['addinsurance'] == undefined ? "" : <div className="row mb-2"><div className="col-sm-12"><button className="btn btn-sm btn-primary float-right" onClick={() => this.addInsuranceList()} type="button">{languagecontent['addinsurance']}</button></div></div>} <div className="card"><div className="card-body">{languagecontent['norecordsfound']}</div></div></div> :
													<div>
														{
															activeInsurances.length == 0 ?
																"" : <div>
                                                                    <h4 className="widget-title pat-widget-title">{languagecontent['activeinsurance']} {languagecontent['addinsurance'] == undefined ? "" : <button className="btn btn-sm btn-primary float-right" onClick={() => this.addInsuranceList()} type="button">{languagecontent['addinsurance']}</button>} </h4>

                                                                    {activeInsurances.map((insurance: InsuranceListStore.InsuranceList) =>
                                                                        <div className="card card-table my-2 p-1 p-xl-3">
                                                                            <div className="card-body" style={{ padding: "10px" }}>
                                                                                <div className="row ">
                                                                                    <div style={{ margin: '10px 5px', }} className={insurance.InsuranceType == "Primary" ? "text-rotate-primary" : insurance.InsuranceType == "Primario" ? "text-rotate-primary" : insurance.InsuranceType == "Secondary" ? "text-rotate-secondary" : insurance.InsuranceType == "Secundario" ? "text-rotate-secondary" : insurance.InsuranceType == "Tertiary" ? "text-rotate-teriorty" : insurance.InsuranceType == "Terciario" ? "text-rotate-teriorty" : ""}>{insurance.InsuranceType}</div>
                                                                                    <div className="col-md-4 col-sm-4 ml-4">
                                                                                        <h5>{insurance.InsuranceName}</h5>
                                                                                        {
                                                                                            insurance.PlanName == "" ? "" :
                                                                                                <div className="insurance-ellipsis">{languagecontent['insuranceplan']}: {insurance.PlanName}</div>

                                                                                        }
                                                                                        {
                                                                                            insurance.PolicyNo == "" ? "" :
                                                                                                <div className="insurance-ellipsis"> {languagecontent['insuranceorpolicy']}: {insurance.PolicyNo}</div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="col-md-4 col-sm-4 ml-4">
                                                                                        <div><br /></div>
                                                                                        <div className="insurance-ellipsis">{languagecontent['insuredname']}: {insurance.InsuredName}</div>
                                                                                        <div> {(insurance.ExpiryDate == "") || (insurance.ExpiryDate == null) ? "" : languagecontent['expirydate'] === undefined ? "" : (languagecontent['expirydate'] ? `${languagecontent['expirydate'].trim()}:` : "") + " " + dateFormat(insurance.ExpiryDate)}</div>
                                                                                    {/*    {insurance.VerificationStatus == "" ? "" : <div className="insurance-ellipsis">{languagecontent['verificationstatus']}: <span className={insuranceVerificationStatusColor(insurance.VerificationStatusId)}>{insurance.VerificationStatus}</span></div>}*/}
                                                                                    </div>
                                                                                    
                                                                                    <div className="col-md-3 col-sm-3 pl-0 ml-2">
                                                                                        <div>
                                                                                            <div className="custom-control custom-switch float-right">
                                                                                                {insurance.DocCount == 0 ? "" : <i className="fa fa-file mr-5" aria-hidden="true"></i>}
                                                                                                <input type="checkbox" className="custom-control-input" checked={insurance.StatusId == "1" ? true : false} id={insurance.Id} onChange={() => this.InsuranceInActive(insurance)} />
                                                                                                <label className="custom-control-label" htmlFor={insurance.Id}></label>
                                                                                                <a className="edit-link" data-toggle="modal" onClick={() => this.editInsurance(insurance)}><i className="fa fa-edit mr-1"></i></a>

                                                                                            </div>

                                                                                        </div>
                                                                                        <div><br /><br /></div>
                                                                                        <div>
                                                                                            <small className="float-right text-muted ml-2">{languagecontent['updatedby']} {insurance.ModifiedName} {languagecontent['on']} {dateTimeFormat(insurance.ModifiedOn)}</small>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    )}

                                                                </div>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                }

                                <br />
                                <div>
                                    {
                                        this.props.isLoading == true ? <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
                                            <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                                            <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                                            <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                                        </ContentLoader> : <div>
                                            {
                                                inActiveInsurances.length == 0 ?
                                                    "" : <div>
                                                        <h4 className="widget-title pat-widget-title">{languagecontent['inactiveinsurance']}
                                                            {
                                                                activeInsurances.length == 0 ?
                                                                    <button className="btn btn-sm btn-primary float-right" onClick={() => this.addInsuranceList()} type="button">{languagecontent['addinsurance']}</button>
                                                                    : ""
                                                            }
                                                        </h4>
                                                        {inActiveInsurances.map((insurance: InsuranceListStore.InsuranceList) =>
                                                            <div className="card card-table my-2 p-1 p-xl-3">
                                                                <div className="card-body" style={{ padding: "10px" }}>
                                                                    <div className="row">
                                                                        <div style={{ margin: '10px 5px', }} className={insurance.InsuranceType == "Primary" ? "text-rotate-primary" : insurance.InsuranceType == "Primario" ? "text-rotate-primary" : insurance.InsuranceType == "Secondary" ? "text-rotate-secondary" : insurance.InsuranceType == "Secundario" ? "text-rotate-secondary" : insurance.InsuranceType == "Tertiary" ? "text-rotate-teriorty" : insurance.InsuranceType == "Terciario" ? "text-rotate-teriorty" : ""}>{insurance.InsuranceType}</div>
                                                                        <div className="col-md-4 col-sm-4 ml-4">
                                                                            <h5>{insurance.InsuranceName}</h5>
                                                                            {
                                                                                insurance.PlanName == "" ? "" :
                                                                                    <div className="insurance-ellipsis">{languagecontent['insuranceplan']}: {insurance.PlanName}</div>

                                                                            }
                                                                            {
                                                                                insurance.PolicyNo == "" ? "" :
                                                                                    <div className="insurance-ellipsis"> {languagecontent['insuranceorpolicy']}: {insurance.PolicyNo}</div>
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-4 col-sm-4 ml-4">
                                                                            <div><br /></div>
                                                                            <div className="insurance-ellipsis">{languagecontent['insuredname']}: {insurance.InsuredName}</div>
                                                                            <div>{(insurance.ExpiryDate == "") || (insurance.ExpiryDate == null) ? "" : languagecontent['expirydate'] === undefined ? "" : (languagecontent['expirydate'] ? `${languagecontent['expirydate'].trim()}:` : "") + " " + dateFormat(insurance.ExpiryDate)}</div>
                                                                            {/*{insurance.VerificationStatus == "" ? "" : <div className="insurance-ellipsis">{languagecontent['verificationstatus']}: <span className={insuranceVerificationStatusColor(insurance.VerificationStatusId)}>{insurance.VerificationStatus}</span></div>}*/}

                                                                        </div>
                                                                        <div className="col-md-3 col-sm-3 pl-0">
                                                                            <div>
                                                                                <div className="custom-control custom-switch float-right">
                                                                                    {insurance.DocCount == 0 ? "" : <i className="fa fa-file mr-5" aria-hidden="true"></i>}
                                                                                    <input type="checkbox" className="custom-control-input" checked={insurance.StatusId == "1" ? true : false} id={insurance.Id} onChange={() => this.InsuranceActive(insurance)} />
                                                                                    <label className="custom-control-label" htmlFor={insurance.Id}></label>
                                                                                    <a className="edit-link" data-toggle="modal" onClick={() => this.editInsurance(insurance)}><i className="fa fa-edit mr-1"></i></a>
                                                                                </div>
                                                                            </div>
                                                                            <div><br /><br /></div>
                                                                            <div>
                                                                                <small className="float-right text-muted">{languagecontent['updatedby']} {insurance.ModifiedName} {languagecontent['on']} {dateTimeFormat(insurance.ModifiedOn)}</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.flexiform, ...state.contact, ...state.transactionDetail, ...state.insuranceLists, ...state.addInsurance, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...ContactStore.actionCreators, ...TransactionStore.actionCreators, ...InsuranceListStore.actionCreators, ...AddInsuranceStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(InsurenceList as any);
