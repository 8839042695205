import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as TimingStore from '../../store/patient/Booking';
import * as ConditionStore from '../../store/appointment/Condition';
import * as LoginStore from '../../store/account/Login';
import * as NavMenuStore from '../../store/menu/NavDefault';
import * as  HeaderDrProfileStore from '../../store/widget/HeaderDrProfile';
import * as AccountStore from '../../store/siteaccount/Account';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as GetProviderId from '../../store/patient/getProviderId';
import ContentLoader, { Facebook } from 'react-content-loader';
import Moment, { locale } from 'moment';
import { toastr } from 'react-redux-toastr';
import HeaderDrProfile from '../widget/HeaderDrProfile';
import { mobnav, seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as MenuHideStore from '../../store/menuHide';
import * as RescheduleState from '../../store/appointment/Reschedule';
import { dateUTCFormat} from '../../helper/formvalidation';
import { scrollTop, setTittle, checkFamilyMember } from '../../helper/formvalidation';
type TimingProps =
    TimingStore.BookingState & ConditionStore.ConditionState & LoginStore.LoginState & NavMenuStore.NavDefaultState & HeaderDrProfileStore.HeaderDrProfileState & AccountStore.AccountState & TimeZoneStore.TimeZoneState & GetProviderId.GetProviderIdState & LanguageResourcesStore.LanguageResouresState & MenuHideStore.MenuHideState & RescheduleState.RescheduleState // ... state we've requested from the Redux store
    & typeof TimingStore.actionCreators & typeof ConditionStore.actionCreators & typeof LoginStore.actionCreators & typeof NavMenuStore.actionCreators & typeof HeaderDrProfileStore.actionCreators & typeof AccountStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof GetProviderId.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof MenuHideStore.actionCreators & typeof RescheduleState.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var seletedday = "";
var timeSeleted = "";
var encountertypeid = "";
var indexdata = 0;
var weekindex = 0;
var TimeIndex: any = "";
var serviceid: any = "";
var _currentoffset: any = "";
var languagecontent: any = "";
var langdata: any = "";
var consultationfree: any = [];
var preferredtimeslot: any = [];
var pickaprovider: any = [];
var sitesetting: any = [];
var Currenttimezone: any = [];
var datepicker: any = [];
class Booking extends React.PureComponent<TimingProps> {
    
    onSubmit = () => {
        var langdata: any;
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        if (!localStorage.getItem("AppointmentTime") && localStorage.getItem("AppointmentTime1")) {
            localStorage.setItem("AppointmentOn", localStorage.getItem("AppointmentOn1") || "")
            localStorage.setItem("AppointmentTime", localStorage.getItem("AppointmentTime1") || "")
            localStorage.setItem("AppointmentUTCTime", localStorage.getItem("AppointmentUTCTime1") || "")
            localStorage.removeItem("AppointmentOn1")
            localStorage.removeItem("AppointmentTime1")
            localStorage.removeItem("AppointmentUTCTime1")
        }

        var time = localStorage.getItem("AppointmentTime");
        var date = localStorage.getItem("AppointmentOn");
        if (!localStorage.getItem("AppointmentTime1"))
            localStorage.removeItem("AppointmentOn1")
        var time1 = localStorage.getItem("AppointmentTime1");
        var date1 = localStorage.getItem("AppointmentOn1");
        var encDetailStroage: any = localStorage.getItem('EncDetail');
        var encDetail = JSON.parse(encDetailStroage);
        if (encDetail == null) {
            return toastr.error('', "Please select appointment type");
        }
        if ((time == null) || (date == null))
            return toastr.error('', langdata['selectdateandtime']);
        this.props.encounterTypes.filter(function (value) {
            return value.name === "PPT"
        });
        this.props.encounterTypes.forEach(value => {
            encountertypeid = value.id
        })
        localStorage.setItem("EncounterTypeId", encountertypeid);
        this.props.redirectCentralized(localStorage.getItem('ProviderId'), this.props.match.params, "2", time, date, time1, date1, encountertypeid, encDetail.encounterTypeMode, encDetail.durationCode, encDetail.encounterTypeModeDurationId, encDetail.minPrice, serviceid);
    }
    selectEncType = (value: any) => {
        localStorage.setItem("EncDetail", JSON.stringify({ durationCode: value.durationCode, encounterTypeMode: value.encounterTypeMode, minPrice: value.price, encounterTypeModeDurationId: value.encounterTypeModeDurationId, serviceId: value.serviceId, modeCode: value.modeCode }))
        const today = Moment(new Date()).format('YYYY-MM-DD');
        seletedday = Moment(new Date()).format('ddd');
        //localStorage.removeItem("AppointmentOn");
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('status');
        serviceid = value.serviceId;
        localStorage.removeItem("AppointmentTime");
        localStorage.removeItem("AppointmentUTCTime");
        window.document.getElementsByClassName("time")[0].innerHTML = "";
        localStorage.removeItem("AppointmentTime1");
        localStorage.removeItem("AppointmentUTCTime1");
        localStorage.removeItem("AppointmentOn1");
        localStorage.removeItem("SelectedDate");
        if (preferredtimeslot == "Yes" && pickaprovider == "No") {
            window.document.getElementsByClassName("time1")[0].innerHTML = "";
        }

        //this.props.headerdrprofile.minPrice = value.minPrice;
        //this.props.headerdrprofile.encounterTypeMode = value.encounterTypeMode;
        //this.props.headerdrprofile.modeCode = value.modeCode;
        //if (localStorage.getItem('AppointmentOn'))
        //    this.props.requestTiming(localStorage.getItem('AppointmentOn') || today, this.props.match.params, "centralized");
        //else
        //    this.props.requestTiming(today, "-", "centralized");
        if (name != 'reschedule') {
            localStorage.setItem("EncDetail", JSON.stringify({ durationCode: value.durationCode, encounterTypeMode: value.encounterTypeMode, minPrice: value.price, encounterTypeModeDurationId: value.encounterTypeModeDurationId, serviceId: value.serviceId, modeCode: value.modeCode }))
            const today = Moment(new Date()).format('YYYY-MM-DD');
            seletedday = Moment(new Date()).format('ddd');
            //localStorage.removeItem("AppointmentOn");
            serviceid = value.serviceId;
            //localStorage.removeItem("AppointmentTime");
            window.document.getElementsByClassName("time")[0].innerHTML = "";
            this.props.headerdrprofile.minPrice = value.Price;
            this.props.headerdrprofile.encounterTypeMode = value.encounterTypeMode;
            this.props.headerdrprofile.modeCode = value.modeCode;
            this.props.headerdrprofile.durationCode = value.durationCode;
            this.props.headerdrprofile.encounterTypeModeDurationId = value.encounterTypeModeDurationId;
            if (localStorage.getItem('AppointmentOn'))
                this.props.requestTiming(localStorage.getItem('AppointmentOn') || today, this.props.match.params, "centralized");
            else
                this.props.requestTiming(today, "-", "centralized");
            mobnav();
        }
    }
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            isShowTZNotification: false    
        };
    }
    state = {
        isLoading: true,
        isShowTZNotification: false
    };
    public componentDidMount() {
        this.ensureDataFetched();
        //this.onDateload();
        scrollTop();
        checkFamilyMember();

        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (patient != null) {
            if (patient.timeZone != "") {
                this.props.requestTimeZone(patient.timeZone);
            }
        }
        if (preferredtimeslot == "Yes" && pickaprovider == "No") {
            localStorage.removeItem("Appointment");
            localStorage.removeItem("Discount");
            if (localStorage.getItem('AppointmentBookedOn') != localStorage.getItem('AppointmentOn')) {
                localStorage.removeItem('AppointmentOn')
                localStorage.removeItem('AppointmentOn1')
            }
        }
        else {
            localStorage.removeItem("Appointment");
            localStorage.removeItem("Discount");            
            if (localStorage.getItem('AppointmentBookedOn') != localStorage.getItem('AppointmentOn'))
                localStorage.removeItem('AppointmentOn')
        }
    }
    componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }

    
    public render() {
       return (
            <React.Fragment>
                {this.renderTiming()}
            </React.Fragment>
        );
    }
    pageprevious = () => {
        window.history.go(-1);
    }
    private ensureDataFetched() {
        var isAuthenticated: any = localStorage.getItem("isAuth");
        if (isAuthenticated == null) {
            window.location.href = "/login";
        }
        this.props.getSiteSetting(null);
        this.props.requestMenuHide(false);
        this.props.getLangResources();
        var currentTime = new Date();
        this.props.requestHeaderDrProfile("-");
        localStorage.removeItem("Discount");
        localStorage.removeItem("DiscountId");
        localStorage.removeItem("DiscountAmount");
        localStorage.removeItem("TotalAmount");
        
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);

        var currentOffset = currentTime.getTimezoneOffset();

        var SettingOffset = patient && patient.offset ? patient.offset : parseInt(localStorage.getItem('timeOffset') || "");   // IST offset UTC +5:30

        var ConvertedTime = new Date(currentTime.getTime() + (SettingOffset + currentOffset) * 60000);

        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

        const today = Moment(ConvertedTime).format('YYYY-MM-DD');
        //const today = Moment(new Date()).format('YYYY-MM-DD');
        var langcode: any = localStorage.getItem('DefaultLangCode');
        seletedday = Moment(new Date()).format('ddd'); locale(langcode.split("-")[0]);
        if (localStorage.getItem('AppointmentBookedOn')) {

            this.props.requestTiming(localStorage.getItem('AppointmentBookedOn') || today, "-", "centralized");
            this.props.storeTime(localStorage.getItem('AppointmentTime'))

        } else {
            this.props.requestTiming(today, "-", "centralized");
        }
        this.props.requestEncounterType();
        mobnav();
        this.setState({
            isShowTZNotification: false
        });
        localStorage.removeItem("TimeZoneTimeSlot");
    }

    closeTZ = () => {
        this.setState({
            isShowTZNotification: true
        })
    }

    updateTimeZone = () => {
        localStorage.setItem("TimeZoneTimeSlot", window.location.href);
        if (localStorage.getItem("isAuth") == "true") {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patient = JSON.parse(patientLocalStroage);
            window.location.href = "/profilesettings/" + patient.memberId;
        } else {
            window.location.href = "/login";
        }
    }

    openDatePicker = () => {
        var element1: any = document.getElementById("datePicker");
        if (element1) {
            localStorage.setItem("AppointmentOn", element1.value);
            const day = Moment.utc(new Date(element1.value)).format('YYYY-MM-DD');
            this.props.requestTiming(day, "-", "centralized");
        }
    };

    public onDate(value: any, index: any) {

        if (preferredtimeslot == "Yes" && pickaprovider == "No") {

            //if (localStorage.getItem("AppointmentTime") && localStorage.getItem("AppointmentTime1"))
            //    return toastr.error('', "You are allowed to select only two preferred time slots");

            if (weekindex != null) {
                var element1 = document.getElementById("dWeekList0");
                var element = document.getElementById("dWeekList" + weekindex);
                if (element != null) {
                    if (element1 != null)
                        element1.classList.remove("active");
                    element.classList.remove("active");
                }

            }
            var element = document.getElementById("dWeekList" + index);
            if (element != null)
                element.classList.add("active");
            var elems = document.querySelectorAll(".selected");
            if (elems != undefined) {
                [].forEach.call(elems, function (el: any) {
                    if (el != undefined) {
                        el.classList.remove("selected");
                    }
                });
            }

            weekindex = index;
            localStorage.setItem("SelectedDate", value.date);
            if (localStorage.getItem("AppointmentOn") == null) {
                localStorage.setItem("AppointmentOn", value.date);
                //localStorage.removeItem("AppointmentTime");
            }
            if (localStorage.getItem("AppointmentOn") == value.date) {
                var element = document.getElementById("dWeekList" + index);
                if (element != null)
                    element.classList.add("active");
                var elems = document.querySelectorAll(".selected");
                //localStorage.removeItem("AppointmentTime");
                //localStorage.removeItem("AppointmentOn");
            }
            if (localStorage.getItem("AppointmentOn") && !localStorage.getItem("AppointmentTime")) {
                localStorage.setItem("AppointmentOn", value.date);
            }
            if (!localStorage.getItem("AppointmentOn1") && !localStorage.getItem("AppointmentTime1")) {
                localStorage.setItem("AppointmentOn1", value.date);
                //localStorage.removeItem("AppointmentTime1");
            }
            //if (localStorage.getItem("AppointmentOn") == value.date || localStorage.getItem("AppointmentOn") == value.date && !localStorage.getItem("AppointmentOn"))
            //window.document.getElementsByClassName("time")[0].innerHTML = "";
            const day = Moment.utc(new Date(value.date)).format('YYYY-MM-DD');
            this.props.requestTiming(day, "-", "centralized");
        }
        else {
            if (weekindex != null) {
                var element1 = document.getElementById("dWeekList0");
                var element = document.getElementById("dWeekList" + weekindex);
                if (element != null) {
                    if (element1 != null)
                        element1.classList.remove("active");
                    element.classList.remove("active");
                }

            }
            var element = document.getElementById("dWeekList" + index);
            if (element != null)
                element.classList.add("active");
            var elems = document.querySelectorAll(".selected");
            if (elems != undefined) {
                [].forEach.call(elems, function (el: any) {
                    if (el != undefined) {
                        el.classList.remove("selected");
                    }
                });
            }

            weekindex = index;
            localStorage.setItem("AppointmentOn", value.date);
            localStorage.removeItem("AppointmentTime");
            localStorage.removeItem("AppointmentUTCTime");
            window.document.getElementsByClassName("time")[0].innerHTML = "";
            const day = Moment.utc(new Date(value.date)).format('YYYY-MM-DD');
            this.props.requestTiming(day, "-", "centralized");
            //console.log(day);
            //console.log(value.date);
        }
    }

    public onTime(value: any, index: number) {

        var langdata: any = "";
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

        if (value.isAvailableSlot == false)
            return toastr.error('', langdata['appointmentalreadybooked ']);
        if (preferredtimeslot == "Yes" && pickaprovider == "No") {
            if (!localStorage.getItem("SelectedDate"))
                localStorage.setItem("SelectedDate", localStorage.getItem("AppointmentOn") || "");

            if (indexdata != null) {
                //var element = document.getElementById("timeslot" + indexdata);
                var element = document.getElementById("timeslot" + (localStorage.getItem('TimeIndex') || 0));
                //if (element != null)
                //    element.classList.remove("selected");
            }
            if (localStorage.getItem("AppointmentTime") == null || localStorage.getItem("AppointmentTime1") == null) {
                var element = document.getElementById("timeslot" + index);
                if (element != null)
                    element.classList.add("selected");
            }
            
            if (localStorage.getItem("AppointmentTime") == null) {
                localStorage.setItem("AppointmentTime", value.timeslot);
                localStorage.setItem("AppointmentUTCTime", value.utcTimeSlot);
                window.document.getElementsByClassName("time")[0].innerHTML = value.timeslot;
                if (localStorage.getItem("AppointmentOn1") != null && localStorage.getItem("AppointmentTime1") != null)
                    //window.document.getElementById("date").hidden = false;
                    window.document.getElementsByClassName("time")[0].innerHTML = value.timeslot;
            }

            else if (localStorage.getItem("AppointmentTime") == value.timeslot && localStorage.getItem("AppointmentOn") == localStorage.getItem("SelectedDate")) {
                var element = document.getElementById("timeslot" + index);
                if (element != null)
                    element.classList.remove("selected");
                localStorage.removeItem("AppointmentTime");
                localStorage.removeItem("AppointmentUTCTime");
                window.document.getElementsByClassName("time")[0].innerHTML = "";
                if (localStorage.getItem("AppointmentOn1") && localStorage.getItem("AppointmentTime1"))
                    window.document.getElementsByClassName("time")[0].innerHTML = "";
                if (!localStorage.getItem("AppointmentTime") && localStorage.getItem("AppointmentTime1")) {
                    localStorage.setItem("AppointmentOn", localStorage.getItem("AppointmentOn1") || "")
                    localStorage.setItem("AppointmentTime", localStorage.getItem("AppointmentTime1") || "")
                    localStorage.setItem("AppointmentUTCTime", localStorage.getItem("AppointmentUTCTime1") || "");
                    localStorage.removeItem("AppointmentOn1")
                    localStorage.removeItem("AppointmentTime1")
                    localStorage.removeItem("AppointmentUTCTime1")
                    window.document.getElementsByClassName("time1")[0].innerHTML = "";
                    value.time = localStorage.getItem("AppointmentTime");
                    window.document.getElementsByClassName("time")[0].innerHTML = value.time;
                    //window.document.getElementById("date").hidden = false;

                }
               //localStorage.removeItem("AppointmentOn");
            }
            else if (localStorage.getItem("AppointmentTime1") == null) {
                localStorage.setItem("AppointmentTime1", value.timeslot);
                localStorage.setItem("AppointmentUTCTime1", value.utcTimeSlot);

                /*if (localStorage.getItem("AppointmentOn1") == null || localStorage.getItem("AppointmentOn1") == "")*/
                localStorage.setItem("AppointmentOn1", (localStorage.getItem("SelectedDate") || localStorage.getItem("AppointmentOn") || ""));

                if (localStorage.getItem("AppointmentOn1") != null && localStorage.getItem("AppointmentTime1") != null)
                    //window.document.getElementById("date").hidden = false;
                    var calIcon: any = '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><style>svg{fill:#051023}</style><path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/></svg>';

                var divstart: any = "<div>"
                var divend : any= "</div>"
                window.document.getElementsByClassName("time1")[0].innerHTML = divstart + calIcon +" "+ dateUTCFormat(localStorage.getItem("AppointmentOn1")) +" " + value.timeslot + divend;
                //window.document.getElementById("date1").hidden = false;
                //if (localStorage.getItem("AppointmentOn") && localStorage.getItem("AppointmentTime"))
                //    window.document.getElementsByClassName("time1")[0].innerHTML = "";

            }

            else if (localStorage.getItem("AppointmentTime1") == value.timeslot && localStorage.getItem("AppointmentOn1") == localStorage.getItem("SelectedDate")) {
                var element = document.getElementById("timeslot" + index);
                if (element != null)
                    element.classList.remove("selected");
                localStorage.removeItem("AppointmentTime1");
                localStorage.removeItem("AppointmentUTCTime1");
                localStorage.setItem("AppointmentOn1", (localStorage.getItem("SelectedDate") || ""));
                // window.document.getElementById("date1").hidden = true;                
                //if (localStorage.getItem("AppointmentTime") && localStorage.getItem("AppointmentOn")) {
                //    localStorage.removeItem("AppointmentOn1")
                //    localStorage.removeItem("AppointmentTime1")                 
                //    window.document.getElementsByClassName("time1")[0].innerHTML = "";
                //    //window.document.getElementsByClassName("time2")[0].innerHTML = "";
                //}
                if (localStorage.getItem("AppointmentTime") && !localStorage.getItem("AppointmentTime1")) {
                    localStorage.removeItem("AppointmentOn1")
                    localStorage.removeItem("AppointmentTime1")
                    localStorage.removeItem("AppointmentUTCTime1")
                    window.document.getElementsByClassName("time1")[0].innerHTML = "";
                    value.time = localStorage.getItem("AppointmentTime");
                    window.document.getElementsByClassName("time")[0].innerHTML = value.time;
                }
            }
            else
                return toastr.error('', langdata['allowedslots']);

            this.props.storeTime(value.timeslot)
            localStorage.setItem("AppointmentBookedOn", localStorage.getItem('SelectedDate') || "");
            timeSeleted = value;
            indexdata = index;
            TimeIndex = index;
            localStorage.setItem("TimeIndex", TimeIndex);
            if (localStorage.getItem("AppointmentOn") != null) {
                var dateformat = Moment(localStorage.getItem("AppointmentOn")).format('YYYY/MM/DD');

                if (preferredtimeslot == "Yes" && pickaprovider == "No") {
                    if ((localStorage.getItem("AppointmentOn") && localStorage.getItem("AppointmentTime")) && (localStorage.getItem("AppointmentOn1") && localStorage.getItem("AppointmentTime1"))) {
                        var timeformat = (localStorage.getItem("AppointmentTime"));
                        var dateformat1 = Moment(localStorage.getItem("AppointmentOn1")).format('YYYY/MM/DD')
                        this.props.getProviderId(dateformat + " " + timeformat, _currentoffset, dateformat1 + " " + value.timeslot);
                    }
                    else {
                        this.props.getProviderId(dateformat + " " + value.timeslot, _currentoffset, null);
                    }

                }
                else
                    this.props.getProviderId(dateformat + " " + value.timeslot, _currentoffset, null);
            }
            else
                return toastr.error('', "Please select appointment date");



            //else
            //window.document.getElementsByClassName("time")[0].innerHTML = "";
        }
        else {
            if (indexdata != null) {
                //var element = document.getElementById("timeslot" + indexdata);
                var element = document.getElementById("timeslot" + (localStorage.getItem('TimeIndex') || 0));
                if (element != null)
                    element.classList.remove("selected");
            }
            var element = document.getElementById("timeslot" + index);
            if (element != null)
                element.classList.add("selected");
            localStorage.setItem("AppointmentTime", value.timeslot);
            localStorage.setItem("AppointmentUTCTime", value.utcTimeSlot);
            this.props.storeTime(value.timeslot)
            localStorage.setItem("AppointmentBookedOn", localStorage.getItem('AppointmentOn') || "");
            timeSeleted = value;
            indexdata = index;
            TimeIndex = index;
            localStorage.setItem("TimeIndex", TimeIndex);
            if (localStorage.getItem("AppointmentOn") != null) {
                var dateformat = Moment(localStorage.getItem("AppointmentOn")).format('YYYY/MM/DD');
                this.props.getProviderId(dateformat + " " + value.timeslot, _currentoffset, null);

            }
            else
                return toastr.error('', "Please select appointment date");

            window.document.getElementsByClassName("time")[0].innerHTML = value.timeslot;
        }

    }
   
    onrescheduleSubmit = (formValues: any) => {
        var langdata: any;
        const urlParams = new URLSearchParams(window.location.search);
        const appointmentid = urlParams.get('appointment');
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        var time = localStorage.getItem("AppointmentTime");
        var date = localStorage.getItem("AppointmentOn");
        var encDetailStroage: any = localStorage.getItem('EncDetail');
        var encDetail = JSON.parse(encDetailStroage);
        if (encDetail == null) {
            return toastr.error('', "Please select appointment type");
        }
        if ((time == null) || (date == null))
            return toastr.error('', langdata['selectdateandtime']);
        this.props.encounterTypes.filter(function (value) {
            return value.name === "PPT"
        });
        this.props.encounterTypes.forEach(value => {
            encountertypeid = value.id
        })
        localStorage.setItem("EncounterTypeId", encountertypeid);
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);

        if (this.props.TimeZones.length != 0) {
            Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
            if (patient.timeZone == "India Standard Time")
                formValues.Offset = 330;
            else
                formValues.Offset = Currenttimezone[0].Offset;
        }
        if (Currenttimezone.length == 0) {
            formValues.Offset = "";
        } else {
            formValues.Offset = Currenttimezone[0].Offset;
        }
        formValues.appointmentid = appointmentid;
        formValues.date = date;
        formValues.time = time;
        this.props.requestReScheduleUpdate(formValues, date, time);
    }
    private renderTiming() {
        languagecontent = this.props.LanguageResoures;        
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('status');
        const patientid = urlParams.get('patientid');
        var appointmenttypemode = urlParams.get('type');
        if (appointmenttypemode == null)
            appointmenttypemode = urlParams.get('encountertypemode');
        const qureystatus = urlParams.get('status');
        if (name == 'reschedule') {

            var modeCode: any
            if (appointmenttypemode == "Teleconsultation" || appointmenttypemode == "Videoconsulta") {
                modeCode = "TEC";

            }
            else if (appointmenttypemode == "In Person" || appointmenttypemode == "En persona")        {
                modeCode = "INP";
            }
                else {
                modeCode = "PHN";
            }
            var typemode: any = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == modeCode);


            if (typemode.length != 0) {
                var encDetailStroage: any = localStorage.getItem('EncDetail');
                var encDetail = JSON.parse(encDetailStroage);
                this.props.headerdrprofile.encounterTypeMode = typemode[0].encounterTypeMode;
                this.props.headerdrprofile.durationCode = typemode[0].durationCode;
                this.props.headerdrprofile.encounterTypeModeDurationId = typemode[0].encounterTypeModeDurationId;
                this.props.headerdrprofile.modeCode = typemode[0].modeCode;
                this.props.headerdrprofile.price = typemode[0].minPrice;
                serviceid = typemode[0].serviceId;
            }


        }
        else {
            if (localStorage.getItem("EncDetail")) {
                var encDetailStroage: any = localStorage.getItem('EncDetail');
                var encDetail = JSON.parse(encDetailStroage);
                this.props.headerdrprofile.encounterTypeMode = encDetail.encounterTypeMode;
                this.props.headerdrprofile.durationCode = encDetail.durationCode;
                this.props.headerdrprofile.encounterTypeModeDurationId = encDetail.encounterTypeModeDurationId;
                this.props.headerdrprofile.modeCode = encDetail.modeCode;
                serviceid = encDetail.serviceId;
            }
        }

        //currency
        var __currency: any = [];
        __currency = this.props.Settings.filter((s: any) => s.name == "Currency");
        var _currency: any = "";
        
        if (__currency.length == 0) {
            _currency = "";
        }
        else {
            if (__currency[0].value == "") {
                _currency = __currency[0].displayCode;
                localStorage.setItem("currency", _currency);
            }
            else {
                _currency = __currency[0].customizeValue;
                localStorage.setItem("currency", _currency);
            }


        }
         var currency: any = _currency;
        
        preferredtimeslot = this.props.Settings.filter((s: any) => s.name == "Client preferred time slot");
        if (preferredtimeslot.length != 0)
            preferredtimeslot = preferredtimeslot[0].customizeValue;
        else
            preferredtimeslot = "";

        pickaprovider = this.props.Settings.filter((s: any) => s.name == "Auto Provider Selection in PickaProvider");
        if (pickaprovider.length != 0)
            pickaprovider = pickaprovider[0].customizeValue;
        else
            pickaprovider = "";

        //if(preferredtimeslot == "Yes" && pickaprovider == "No") {
        //    var currentTime = new Date();           

        //    const patientLocalStroage: any = localStorage.getItem('Patient');
        //    const patient = JSON.parse(patientLocalStroage);

        //    var currentOffset = currentTime.getTimezoneOffset();

        //    var SettingOffset = patient && patient.offset ? patient.offset : parseInt(localStorage.getItem('timeOffset') || "");   // IST offset UTC +5:30

        //    var ConvertedTime = new Date(currentTime.getTime() + (SettingOffset + currentOffset) * 60000);


        //    const today = Moment(ConvertedTime).format('YYYY-MM-DD');
        //    if (!localStorage.getItem("AppointmentOn"))
        //     this.props.requestTiming(today, "-", "centralized");


        //}


        consultationfree = this.props.Settings.filter((s: any) => s.name == "Show the consultation fee as Free");
        var free: any = "";
        if (consultationfree.length != 0)
            free = consultationfree[0].customizeValue
        else
            free = "";

        datepicker = this.props.Settings.filter((s: any) => s.name == "CP Test Date Picker");
        var testdatepicker: any = "";
        if (datepicker.length != 0)
            testdatepicker = datepicker[0].customizeValue
        else
            testdatepicker = "";

        var currenttimezone: any = "";

        if (localStorage.getItem("isAuth") == "true") {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patient = JSON.parse(patientLocalStroage);
            if (patient.timeZone == "") {
                var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
                if (timezone != undefined) {
                    var _timezone = timezone.displayCode.split("|");
                    currenttimezone = _timezone[0];
                    _currentoffset = _timezone[1];
                }
            } else {
                currenttimezone = patient.timeZone;
                if (this.props.TimeZones.length != 0) {
                    _currentoffset = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
                    _currentoffset = _currentoffset[0].Offset;
                }
            }
        } else {
            if (this.props.Settings.length != 0) {
                var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
                if (timezone != undefined) {
                    var _timezone = timezone.displayCode.split("|");
                    currenttimezone = timezone.customizeValue;
                    _currentoffset = _timezone[1];
                }
            }
        }
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container">
                        <HeaderDrProfile />
                        <div className="row">
                            <div className="col-lg-5 col-12">
                                {
                                    this.props.headerdrprofile.doctorServiceDetails.length == 1 ?
                                        <React.Fragment>
                                            <h5>{languagecontent['appointmenttype']}</h5>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <h5>{languagecontent['appointmenttype']}</h5>
                                            <small>{languagecontent['appointmentdescription']}</small> <br /><br />
                                        </React.Fragment>
                                }

                                <div className="card">
                                    <ul className="list-group list-group-flush benifits-col">

                                        {this.props.bioIsLoading == true ? <ContentLoader
                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                            speed={2}
                                            width={350}
                                            height={160}
                                            viewBox="0 0 400 160"
                                        >
                                            <rect x="50" y="6" rx="4" ry="4" width="343" height="38" />
                                            <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
                                            <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
                                            <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
                                            <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
                                            <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />
                                        </ContentLoader> :
                                            this.props.headerdrprofile.doctorServiceDetails != undefined ?
                                                this.props.headerdrprofile.doctorServiceDetails.map((doctorService: HeaderDrProfileStore.DoctorServiceDetail, index) =>
                                                    <React.Fragment>
                                                        <li className="list-group-item d-flex align-items">
                                                            <div className="avatar avatar-sm">
                                                                <label className="custom_radio">
                                                                    <div className="cp_visit-width">
                                                                        <input type="radio" name="calltype" onClick={() => this.selectEncType(doctorService)} value="Ccon" checked={doctorService.modeCode == this.props.headerdrprofile.modeCode ? true : false} /><span className="checkmark"></span> {doctorService.encounterTypeMode} <h4 className="float-right text-info">{doctorService.price == "0" ? free == "Yes" ? languagecontent['free'] : "" : currency + getDiscountPrice(doctorService.price, 0)}</h4> <p>{doctorService.duration} {languagecontent['mins']}</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                                : ""
                                        }

                                    </ul>
                                </div>
                                <div>
                                    {
                                        testdatepicker == "Yes" ?
                                            <input id="datePicker" onChange={this.openDatePicker} maxLength={15} type="date" />
                                            : ""
                                    }

                                </div>
                            </div>
                            <div className="col-12 col-lg-7">
                                <div className="mb-4"><h4 className="widget-title pat-widget-title mb-2 pl-0">{languagecontent['doctorselection']}</h4><p>{preferredtimeslot == "Yes" && pickaprovider == "No" ? languagecontent['twoslts'] : languagecontent['doctor_selection']}</p></div>
                                <div className="card booking-schedule schedule-widget">
                                    <div className="schedule-header">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="day-slot">
                                                    <div className="timezone-notification" hidden={this.state.isShowTZNotification}><a className="float-right" onClick={() => this.closeTZ()}><img width="10" src="client/default/img/icon-cross.svg" /></a><div style={{ wordWrap: "break-word" }}>{languagecontent['timezonechange']} {currenttimezone}. {languagecontent['tochangethetime']} <a className="text-info" onClick={() => this.updateTimeZone()}>{languagecontent['clickhere']}</a> </div></div>
                                                    <br />
                                                    {
                                                        testdatepicker != "Yes" ?
                                                        this.props.weekdays != undefined ?
                                                            <ul>
                                                                {
                                                                    preferredtimeslot == "Yes" && pickaprovider == "No" ?
                                                                        this.props.weekdays.map((weekday: TimingStore.WeekDay, index) =>
                                                                            <li id={"dWeekList" + index} className={localStorage.getItem('SelectedDate') == weekday.date ? 'active' : ''} >
                                                                                <a onClick={() => this.onDate(weekday, index)}>
                                                                                    <span>{weekday.day}</span>
                                                                                    <span className="slot-date"><small>{weekday.dayno} {weekday.month}</small></span> <small className="slot-year"> {weekday.year} </small>
                                                                                </a>
                                                                            </li>,
                                                                        )
                                                                        :
                                                                        this.props.weekdays.map((weekday: TimingStore.WeekDay, index) =>
                                                                            <li id={"dWeekList" + index} className={localStorage.getItem('AppointmentOn') == weekday.date ? 'active' : ''} >
                                                                                <a onClick={() => this.onDate(weekday, index)}>
                                                                                    <span>{weekday.day}</span>
                                                                                    <span className="slot-date"><small>{weekday.dayno} {weekday.month}</small></span> <small className="slot-year"> {weekday.year} </small>
                                                                                </a>
                                                                            </li>,
                                                                        )
                                                                }
                                                            </ul> : '': ''
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-cont">
                                        <div className="row">
                                            {
                                                this.props.isLoading ? <ContentLoader
                                                    title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                    width={1100}
                                                    height={200}
                                                    viewBox="0 0 1200 200"
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#ecebeb">
                                                    <rect x="27" y="10" rx="4" ry="4" width="20" height="20" />
                                                    <rect x="67" y="10" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="188" y="10" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="402" y="10" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="523" y="10" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="731" y="10" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="852" y="10" rx="10" ry="10" width="85" height="19" />

                                                    <rect x="26" y="61" rx="4" ry="4" width="20" height="20" />
                                                    <rect x="66" y="61" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="187" y="61" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="401" y="61" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="522" y="61" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="730" y="61" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="851" y="61" rx="10" ry="10" width="85" height="19" />

                                                    <rect x="26" y="123" rx="4" ry="4" width="20" height="20" />
                                                    <rect x="66" y="123" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="187" y="123" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="401" y="123" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="522" y="123" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="730" y="123" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="851" y="123" rx="10" ry="10" width="85" height="19" />

                                                    <rect x="26" y="181" rx="4" ry="4" width="20" height="20" />
                                                    <rect x="66" y="181" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="187" y="181" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="401" y="181" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="522" y="181" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="730" y="181" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="851" y="181" rx="10" ry="10" width="85" height="19" />

                                                    <rect x="26" y="241" rx="4" ry="4" width="20" height="20" />
                                                    <rect x="66" y="241" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="187" y="241" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="401" y="241" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="522" y="241" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="730" y="241" rx="10" ry="10" width="85" height="19" />
                                                    <rect x="851" y="241" rx="10" ry="10" width="85" height="19" />

                                                    <rect x="978" y="10" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="977" y="61" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="977" y="123" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="977" y="181" rx="10" ry="10" width="169" height="19" />
                                                    <rect x="977" y="241" rx="10" ry="10" width="169" height="19" />

                                                </ContentLoader> : <div className="col-md-12">
                                                    {
                                                        this.props.timing != null ?
                                                            <div className="time-slot">
                                                                <ul className="clearfix">
                                                                    {
                                                                        preferredtimeslot == "Yes" && pickaprovider == "No" ?
                                                                            this.props.timing.map((times: TimingStore.Root) =>
                                                                                times == null ? '' :
                                                                                    times.map((root: TimingStore.RootEntity) =>
                                                                                        <li style={{ paddingBottom: '10px' }}>
                                                                                            <a onClick={() => this.onTime(root, root.order)} id={"timeslot" + root.order} className={(localStorage.getItem('AppointmentOn') == localStorage.getItem('SelectedDate') && localStorage.getItem('AppointmentTime') == root.timeslot) || (localStorage.getItem('AppointmentOn1') == localStorage.getItem('SelectedDate') && localStorage.getItem('AppointmentTime1') == root.timeslot) ? 'timing selected' : 'timing'}>
                                                                                                {root.isAvailableSlot == true ? root.timeslot : <s>{root.timeslot}</s>}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                            )
                                                                            : this.props.timing.map((times: TimingStore.Root) =>
                                                                                times == null ? '' :
                                                                                    times.map((root: TimingStore.RootEntity) =>
                                                                                        <li style={{ paddingBottom: '10px' }}>
                                                                                            <a onClick={() => this.onTime(root, root.order)} id={"timeslot" + root.order} className={localStorage.getItem('AppointmentTime') == root.timeslot ? 'timing selected' : 'timing'}>
                                                                                                {root.isAvailableSlot == true ? root.timeslot : <s>{root.timeslot}</s>}
                                                                                            </a>
                                                                                        </li>
                                                                                    )
                                                                            )
                                                                    }
                                                                </ul>
                                                                {
                                                                    this.props.timing != undefined ? this.props.timing.length == 0 ?
                                                                        <div>
                                                                            <div className="p-4">No records found</div>
                                                                        </div> : "" : ''
                                                                }
                                                            </div> : ''
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section proceed-btn text-right">
                                    <button className="btn btn-secondary submit-btn" onClick={this.pageprevious}>{languagecontent['backbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['backbutton']}</button>
                                    {
                                        qureystatus == 'reschedule' ?
                                            <button className="btn btn-primary submit-btn" onClick={this.onrescheduleSubmit} disabled={this.props.isResceduleLoading}> {this.props.isResceduleLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['proceedbutton']}</button>
                                            :
                                            <button className="btn btn-primary submit-btn" onClick={this.onSubmit}>{languagecontent['proceedbutton']}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ ...state.booking, ...state.condition, ...state.login, ...state.navDefault, ...state.headerDrProfile, ...state.account, ...state.timeZones, ...state.getProviderId, ...state.langResources, ...state.menuHide, ...state.reschedule}),
    ({ ...TimingStore.actionCreators, ...ConditionStore.actionCreators, ...LoginStore.actionCreators, ...NavMenuStore.actionCreators, ...HeaderDrProfileStore.actionCreators, ...AccountStore.actionCreators, ...TimeZoneStore.actionCreators, ...GetProviderId.actionCreators, ...LanguageResourcesStore.actionCreators, ...MenuHideStore.actionCreators, ...RescheduleState.actionCreators }),// Selects which state properties are merged into the component's props
)(Booking as any);

export const getDiscountPrice = (currentprice: any, discountprice: any) => {
    var price: any = (currentprice - discountprice);
    return parseFloat(price).toFixed(2);
}
