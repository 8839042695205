import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import * as DashboardNavStore from '../../../store/menu/DashboardNav';
import ContentLoader from 'react-content-loader';
import * as ContactStore from '../../../store/patient/Contact';
import * as SiteDetailStore from '../../../store/SiteDetail';
import * as SiteMenusStore from '../../../store/siteaccount/siteMenu';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { dateFormat, scrollTop } from '../../../helper/formvalidation';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';
var langdata: any = "";
type DashboardNavStoreProps = DashboardNavStore.DashboardNavState & ContactStore.ContactState & SiteDetailStore.SiteDetailState & SiteMenusStore.SiteMenuState & LanguageResourcesStore.LanguageResouresState & typeof SiteDetailStore.actionCreators & typeof DashboardNavStore.actionCreators & typeof ContactStore.actionCreators & typeof SiteMenusStore.actionCreators & typeof LanguageResourcesStore.actionCreators;
class DashboardNavMenu extends React.PureComponent<DashboardNavStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
        scrollTop();
    }
   
    private ensureDataFetched() {
        this.props.getSiteMenu();
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderDashboardNav()}
            </React.Fragment>
        );
    }

    public logOut() {
        this.props.requestLogout();
        localStorage.removeItem("isAuth");
    }

    public renderDashboardNav() {
        var languagecontent: any = this.props.LanguageResoures;
        var data = window.location.pathname;
        const array = data.split('/');
        var publicMenus: any = [];
        publicMenus = this.props.siteMenus.filter((ca: any) => ca.TypeId == "3");
        return (
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                <div className="profile-sidebar">
                    <div className="widget-profile pro-widget-content">
                        <div className="profile-info-widget">
                            <a className="booking-doc-img">
                                <img alt="Crop" style={{ maxWidth: '100%' }} src={this.props.contact.photo == "" || this.props.contact.photo == undefined ? "client/default/img/blankimg.jpg" : this.props.contact.photo} />
                            </a>
                            {this.props.contact != undefined ? <div className="profile-det-info">
                                <h3>{this.props.contact.firstName + " " + this.props.contact.lastName}</h3>
                                <div className="patient-details">
                                    {

                                        this.props.contact.dob == null || this.props.contact.dob == "" ? "" :
                                            <h5><i className="fas fa-birthday-cake"></i> {this.props.contact.dateofbirth == "" ? "" : dateFormat(this.props.contact.dateofbirth)} </h5>
                                    }

                                </div>
                            </div> :
                                <ContentLoader
                                    title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                    speed={2}
                                    width={300}
                                    height={170}
                                    viewBox="0 0 400 170"
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#ecebeb"
                                >
                                    <circle cx="248" cy="59" r="49" />
                                    <circle cx="263" cy="66" r="8" />
                                    <rect x="175" y="120" rx="0" ry="0" width="156" height="8" />
                                    <rect x="204" y="137" rx="0" ry="0" width="100" height="8" />
                                    <rect x="248" y="128" rx="0" ry="0" width="0" height="1" />
                                    <rect x="247" y="126" rx="0" ry="0" width="1" height="8" />
                                    <rect x="252" y="166" rx="0" ry="0" width="1" height="0" />
                                </ContentLoader>}

                        </div>
                    </div>
                    <div className="dashboard-widget">
                        <nav className="dashboard-menu">
                            <ul>
                                {
                                    publicMenus.map((sitemenu: SiteMenusStore.siteMenu) =>
                                        <li>
                                            {
                                                sitemenu.PageName == "profile" ?
                                                    <NavLink className="nav-link header-login log_btn" to={this.props.isContactLoading == false ? "/profilesettings/" + this.props.contact.memberId : "#"}><i className={setMenuIcon(sitemenu.Src)}></i>
                                                        <span>{sitemenu.Title}</span></NavLink>
                                                    : sitemenu.PageName == "insurance" ?
                                                        <NavLink className="nav-link header-login log_btn" to={this.props.isContactLoading == false ? "/insurance/list?memberid=" + this.props.contact.memberId : "#"}><i className={setMenuIcon(sitemenu.Src)}></i>
                                                            <span>{sitemenu.Title}</span></NavLink>
                                                        : sitemenu.PageName == "transactions" ?
                                                            <NavLink className="nav-link header-login log_btn" to={this.props.isContactLoading == false ? "/transactions/" + this.props.contact.memberId : "#"}><i className={setMenuIcon(sitemenu.Src)}></i>
                                                                <span>{this.props.siteDetail.Code == "VTS" ? "Billing" : sitemenu.Title}</span></NavLink>
                                                            :
                                                            <NavLink className="nav-link header-login log_btn" to={sitemenu.Src}><i className={setMenuIcon(sitemenu.Src)}></i>
                                                                <span>{sitemenu.Title}</span></NavLink>
                                            }
                                        </li>
                                    )
                                }
                                <li>
                                    <a className="nav-link header-login log_btn" onClick={() => this.logOut()}><i className="fas fa-sign-out-alt"></i>
                                        <span>{languagecontent['logoutmenu']}</span></a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>

            </div>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.dashboardNav, ...state.contact, ...state.siteDetail, ...state.siteMenus, ...state.langResources }), // Selects which state properties are merged into the component's props
    ({ ...DashboardNavStore.actionCreators, ...ContactStore.actionCreators, ...SiteDetailStore.actionCreators, ...SiteMenusStore.actionCreators, ...LanguageResourcesStore.actionCreators }) // Selects which action creators are merged into the component's props
)(DashboardNavMenu as any);


function setMenuIcon(value: any) {
    if (value == "/dashboard") {
        return "fas fa-columns";
    } else if (value == "/profilesettings") {
        return "fas fa-user-cog";
    }
    else if (value == "/insurance/list?memberid=") {
        return "fas fa-umbrella";
    }
    else if (value == "/changepassword") {
        return "fas fa-lock";
    }
    else if (value == "/transactions") {
        return "fas fa-lock";
    }
    else if (value == "/formlist") {
        return "fas fa-file-invoice";
    } else if (value == "/message") {
        return "fas fa-file-invoice";
    }
}
