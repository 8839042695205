import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { errorHandling } from '../../helper/ErrorHandling';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { actionCreators as _navAction } from '../../store/menu/NavDefault';

export interface MemberSpecialityState {
    isLoading: boolean;
    startDateIndex?: number;
    speciality: Specialities[];
}
export interface Specialities {
    id: string;
    name: string;
}
interface RequestOurDoctorsNavAction {
    type: 'REQUEST_OURDOCTORS_NAV';
    startDateIndex: number;
}

interface ReceiveDoctorListsAction {
    type: 'RECEIVE_OURDOCTORS_NAV';
    startDateIndex: number;
    speciality: Specialities[];
}

type KnownAction = RequestOurDoctorsNavAction | ReceiveDoctorListsAction;

export const actionCreators = {
    requestMemberSpecialityLists: (): AppThunkAction<KnownAction> => (dispatch:any, getState:any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var languagecode: any = localStorage.getItem("DefaultLangCode");

        if (appState && appState.speciality && accountid != "") {
            axios.get('/doctor/GetMemberSpeciality/' + accountid + "/" + languagecode )
                .then(function (response) {
                    const users = response.data;
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_OURDOCTORS_NAV', speciality: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_OURDOCTORS_NAV' });
        }
    },
    requestSearchDoctorLists: (formvalues: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var accountid = localStorage.getItem("AccountId");
        var specialityid = formvalues.specialityid;
        var Selectdate = formvalues.Selectdate;
        //if (Selectdate == undefined)
        //    Selectdate = "";
        var doctorName = formvalues.doctorName;
        if (doctorName == "")
            doctorName = "";
        var gender = formvalues.gender;
        if (gender == "" || gender == undefined)
            gender = "";
        if (appState && appState.doctorLists && accountid != "") {
            axios.get('/doctor/GetSearchDoctor/' + accountid + "/" + specialityid + "/" + Selectdate + "/" + doctorName + "/" + 0)
                .then(function (response) {
                    const users = response.data;
                    localStorage.removeItem("AppointmentOn");
                    localStorage.removeItem("AppointmentTime");
                    localStorage.removeItem("AppointmentTime1");
                    localStorage.removeItem("AppointmentOn1");
                    localStorage.removeItem("AppointmentUTCTime")
                    localStorage.removeItem("AppointmentUTCTime1")
                    localStorage.removeItem("AppointmentBookedOn");
                    //localStorage.setItem("AccountId", users[0].user.accountId)
                    dispatch({ type: 'RECEIVE_DOCTORLIST_FORECASTS', doctorlists: users });
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCTORLIST_FORECASTS' });
        }
    },
};


const unloadedState: MemberSpecialityState = {
    speciality: [],
    isLoading: false
};

export const reducer: Reducer<MemberSpecialityState> = (state: MemberSpecialityState | undefined, incomingAction: Action): MemberSpecialityState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_OURDOCTORS_NAV':
            return {
                speciality: state.speciality,
                isLoading: true
            };

        case 'RECEIVE_OURDOCTORS_NAV':
            return {
                speciality: action.speciality,
                isLoading: false
            };
        default:
            return state;
    }
};
