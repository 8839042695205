
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../index';
import { errorHandling } from '../../../helper/ErrorHandling';


export interface NotifyBarState {
    isLoading: boolean;
    message: string;
}


interface RequestNotifyBarAction {
    type: 'REQUEST_TOPBARNOTIFY_SUCCESS';
  
}

interface ReceiveNotifyBarAction {
    type: 'RECEIVE_TOPBARNOTIFY_SUCCESS';
    message: string;
}

type KnownAction = RequestNotifyBarAction | ReceiveNotifyBarAction;

export const actionCreators = {
    getNotify: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        var languagecode: any = localStorage.getItem("DefaultLangCode");
        if (appState && appState.location) {
            axios.get('/site/GetSiteUpgrade/' + "2" + '/' + languagecode)
                .then(function (response) {
                    const result = response.data;
                    
                    dispatch({ type: 'RECEIVE_TOPBARNOTIFY_SUCCESS', message: result });
                })
                .catch(function (error) {
                       errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                  
                });
            dispatch({ type: 'REQUEST_TOPBARNOTIFY_SUCCESS' });
        }
    },
};


const UnloadedState: NotifyBarState = {
    message: '', isLoading: false
};


export const reducer: Reducer<NotifyBarState> = (state: NotifyBarState | undefined, incomingAction: Action): NotifyBarState => {
    if (state === undefined) {
        return UnloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_TOPBARNOTIFY_SUCCESS':
            return {
                message: state.message,
                isLoading: true
            };
        case 'RECEIVE_TOPBARNOTIFY_SUCCESS':
            return {
                message: action.message,
                isLoading: false
            };
        default:
            return state;
    }
};
