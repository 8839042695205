import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as NavDefaultStore from '../../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as SiteDetailStore from '../../../../store/SiteDetail';
import { ApplicationState } from '../../../../store/index';
type FooterStoreProps = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & RouteComponentProps;
const scriptToInject = ` <script type="text/javascript" language="javascript">var ANS_customer_id="4e0b86ff-987f-444d-bab1-4d2ad5d66086";</script> <script type="text/javascript" language="javascript" src="//verify.authorize.net:443/anetseal/seal.js" ></script>`
class FtrPrime extends React.PureComponent<FooterStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
        var ANS_customer_id = "4e0b86ff-987f-444d-bab1-4d2ad5d66086"
        var sealHtml = '';
      
        var ANSVerificationURL = "https://verify.authorize.net/anetseal/";  
        var AuthorizeNetSeal =
        {
            verification_parameters: "",
            id_parameter_name: "pid",
            url_parameter_name: "rurl",
            seal_image_file: (ANSVerificationURL + "images/secure90x72.gif"),
            seal_width: "90",
            seal_height: "72",
            seal_alt_text: "Authorize.Net Merchant - Click to Verify",
            display_url: "http://www.authorize.net/",
            text_color: "black",
            text_size: "9px",
            line_spacing: "10px",
            new_window_height: "430",
            new_window_width: "600",
            current_url: "",
            display_location: true,
            no_click: false,
            debug: false
        };

        sealHtml += '<style type="text/css">';
        sealHtml += 'div.AuthorizeNetSeal{text-align:center;margin:0;padding:0;width:' + AuthorizeNetSeal.seal_width + 'px;font:normal ' + AuthorizeNetSeal.text_size + ' arial,helvetica,san-serif;line-height:' + AuthorizeNetSeal.line_spacing + ';}';
        sealHtml += 'div.AuthorizeNetSeal a{text-decoration:none;color:' + AuthorizeNetSeal.text_color + ';}';
        sealHtml += 'div.AuthorizeNetSeal a:visited{color:' + AuthorizeNetSeal.text_color + ';}';
        sealHtml += 'div.AuthorizeNetSeal a:active{color:' + AuthorizeNetSeal.text_color + ';}';
        sealHtml += 'div.AuthorizeNetSeal a:hover{text-decoration:underline;color:' + AuthorizeNetSeal.text_color + ';}';
        sealHtml += 'div.AuthorizeNetSeal a img{border:0px;margin:0px;text-decoration:none;}';
        sealHtml += '</style>';

        
        AuthorizeNetSeal.verification_parameters = '?' + AuthorizeNetSeal.id_parameter_name + '=' + escape(ANS_customer_id);
            if (window.location.href) {
                AuthorizeNetSeal.current_url = window.location.href;
            }
            else if (document.URL) {
                AuthorizeNetSeal.current_url = document.URL;
            }

            if (AuthorizeNetSeal.current_url) {
                AuthorizeNetSeal.verification_parameters += '&' + AuthorizeNetSeal.url_parameter_name + '=' + escape(AuthorizeNetSeal.current_url);
            }

            if (!AuthorizeNetSeal.no_click) {
                sealHtml += '<a ';
                sealHtml += 'href="' + ANSVerificationURL + AuthorizeNetSeal.verification_parameters + '" ';
                if (!AuthorizeNetSeal.debug) {
                    sealHtml += 'onMouseOver="window.status=\'' + AuthorizeNetSeal.display_url + '\'; return true;" ';
                    sealHtml += 'onMouseOut="window.status=\'\'; return true;" ';
                    sealHtml += 'onClick="window.open(\'' + ANSVerificationURL + AuthorizeNetSeal.verification_parameters + '\',\'AuthorizeNetVerification\',\'';
                    sealHtml += 'width=' + AuthorizeNetSeal.new_window_width;
                    sealHtml += ',height=' + AuthorizeNetSeal.new_window_height;
                    sealHtml += ',dependent=yes,resizable=yes,scrollbars=yes';
                    sealHtml += ',menubar=no,toolbar=no,status=no,directories=no';
                    if (AuthorizeNetSeal.display_location) {
                        sealHtml += ',location=yes';
                    }
                    sealHtml += '\'); return false;" ';
                }
                sealHtml += 'target="_blank"';
                sealHtml += '>';
            }

            sealHtml += '<img src="' + AuthorizeNetSeal.seal_image_file + '" width="' + AuthorizeNetSeal.seal_width + '" height="' + AuthorizeNetSeal.seal_height + '" border="0" alt="' + AuthorizeNetSeal.seal_alt_text + '">';

            if (!AuthorizeNetSeal.no_click) {
                sealHtml += '</a>';
            }

            //document.getElementById('sealPlaceholder').innerHTML = sealHtml;
        const seal = window.document.getElementById('sealPlaceholder');

        if (seal !== null) {
            seal.innerHTML = sealHtml;
        }
        

    }
    public render() {
        return (
            <React.Fragment>
                {this.renderFooter()}
            </React.Fragment>
        );
    }
    
    private ensureDataFetched() {
        //this.props.requestSiteDetail();
    }
    public renderFooter() {
        
        const data = '<div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="4e0b86ff-987f-444d-bab1-4d2ad5d66086";</script> <script type="text/javascript" language="javascript" src="//verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
        return (
            <React.Fragment>
                <footer className="footer pr-footer">
                    <div className="footer-top">
                        <div className="container pr_container">
                            <div className="row logo-row">
                                <div className="col-lg-4 col-xs-12 ">
                                    <div className="author-img-wrap">
                                        <a href="https://primelifemd.com/"><img className="primg-fluid" style={{maxWidth:"210px"}} alt="" src="client/logo/Primefooterlogo.png" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-2 col-md-6">
                                    <div className="footer-widget footer-menu">

                                        <ul>
                                            <li><a href="https://primelifemd.com/">Home</a></li>
                                            <li><a href="https://primelifemd.com/frequently-asked-questions/">FAQ Page</a></li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget footer-menu mob-mb-30">
                                        <ul>
                                            <li><a href="https://primelifemd.com/privacy-policy/">Privacy Policy</a></li>
                                            <li><a href="https://primelifemd.com/terms-and-conditions/">Terms & Conditions</a></li>
                                            <li><a href="https://primelifemd.com/shipping-policy/">Shipping Policy</a></li>

                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6">
                                    <div className="footer-widget footer-contact">

                                        <div className="footer-contact-info">
                                            
                                         
                                            <ul className="fa-ul">
                                                <li><span className="fa-li"><i className="fas fa-map-marker-alt"></i></span> Prime Life MD Anti - Aging and Wellness Center, 772 Maddox Dr Suite 116 B, East Ellijay, GA 30540</li>
                                                <li><span className="fa-li"><i className="fas fa-phone"></i></span><a href="tel:706-760-2046" style={{ color: "#ffffff" }}>706-760-2046</a></li>
                                                <li><span className="fa-li"><i className="fas fa-envelope" ></i></span><a href="mailto:info@primelifemd.com" style={{ color:"#ffffff"}}>info@primelifemd.com</a></li>
                                               
                                            </ul>
                                           
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-widget footer-contact">
                                        
                                        <div className="footer-contact-info">
                                            <p>We Accept</p>
                                            <img className="primg-fluid" alt="" src="https://www.drcare247.com/wp-content/uploads/2020/07/cards.png" />

                                            <br />



                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        <div className="container  pr_container">
                            <div className="copyright ">
                                <div className="row pr-copyright">
                                    <div className="col-md-6 col-lg-6 wrap">
                                        <div className="copyright-text ">
                                            <p className="">Copyright © Prime Life MD 2022 </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 ">
                                        <div className="row">
                                            <div className="col-md-6 col-lg-9 wrap">
                                                <div className="copyright-text text-right ">
                                                    <p>Follow us on <a className="icon-link" href="https://www.facebook.com/PrimeLifeMD" target="_blank"><i className="fab fa-facebook"></i> </a> </p>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-lg-3 wrap">
                                                <div className="copyright-text text-left wrap">
                                                      <div id="sealPlaceholder"></div>
                                                 </div>
                                            </div>
                                         </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.siteDetail }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators }) // Selects which action creators are merged into the component's props
)(FtrPrime as any);



