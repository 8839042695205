import * as React from 'react'
import ContentLoader from "react-content-loader"


var langdata: any = "";

if (localStorage.getItem("LangResourcesValidations") != null)
	langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

export const searchLoader = () => (
	<React.Fragment>
		<div className="card">
			<div className="card-body">
				<ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
					<rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
					<rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
					<rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
				</ContentLoader>
			</div>
		</div>
		<div className="card">
			<div className="card-body">
				<ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 380 70">
					<rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
					<rect x="80" y="17" rx="4" ry="4" width="250" height="13" />
					<rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
				</ContentLoader>
			</div>
		</div>
	</React.Fragment>
);

export const TextLoader = () => {
	return (
		<ContentLoader
			title={langdata['loading'] == undefined ? "" : langdata['loading']}
			speed={3}
			width={150}
			height={20}
			viewBox="0 0 150 20"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
		
		>
			<rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
			<rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
			<rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
			<rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
			<rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
			<rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
			<rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
			<rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
		</ContentLoader>

	)
}

export const SingleTextLoader = () => {
	return (
		<ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="200 0 99% 50" width={"99%"} height={50}>
			<rect x="15" y="5" rx="0" ry="0" width="100" height="14" />
		</ContentLoader>

	)
}

export const TextBoxLoader = () => {
	return (
		<div style={{ border: "1px solid #dcdcdc", borderRadius: "5px", marginBottom: "1.5rem" }}>
			<ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="200 0 99% 50" speed={5} width={"99%"} height={50}>
				<rect x="5" y="5" rx="0" ry="0" width="100" height="14" />
				<rect x="5" y="25" rx="0" ry="0" width="465" height="16" />
			</ContentLoader>
		</div>
	)
}

export const BoxLoader = () => {
	return (
		<ContentLoader
			title={langdata['loading'] == undefined ? "" : langdata['loading']}
			width={500}
			height={500}
			viewBox="0 0 265 230"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"

		>
				<rect x="10" y="15" rx="4" ry="4" width="350" height="25" />
				<rect x="10" y="50" rx="2" ry="2" width="350" height="150" />
				<rect x="10" y="230" rx="2" ry="2" width="170" height="20" />
				<rect x="60" y="230" rx="2" ry="2" width="170" height="20" />
			</ContentLoader>
		

	)
}
export const DashBoardContentLoading = () => {
	return (
		<React.Fragment>
			<ContentLoader
				title={langdata['loading'] == undefined ? "" : langdata['loading']}
				height={54}
				width={320}
				viewBox="0 0 320 54"
				backgroundColor="#f3f3f3"
				foregroundColor="#ecebeb"
			>
				<circle cx="27" cy="27" r="18" />
				<rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
				<rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
				<rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
				<circle cx="305" cy="27" r="8" />
				<rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
				<rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
			</ContentLoader>
		</React.Fragment>
		

	)
}
export const DashBoardPageLoading = () => {
	return (
        <React.Fragment>
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader> <br />
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader><br />
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader><br />
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader><br />
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader><br />
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader>
            <br />
            <ContentLoader
                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                height={54}
                width={320}
                viewBox="0 0 320 54"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <circle cx="27" cy="27" r="18" />
                <rect x="53" y="14" rx="3" ry="3" width="180" height="13" />
                <rect x="53" y="30" rx="3" ry="3" width="10" height="10" />
                <rect x="67" y="30" rx="3" ry="3" width="74" height="10" />
                <circle cx="305" cy="27" r="8" />
                <rect x="0" y="53" rx="0" ry="0" width="320" height="1" />
                <rect x="219" y="146" rx="0" ry="0" width="0" height="0" />
            </ContentLoader>
        </React.Fragment>


	)
}