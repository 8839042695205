import axios from 'axios';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../';
import { errorHandling } from '../../../helper/ErrorHandling';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface DocumentListsState {
    isdocLoading: boolean;
    documentLists: DocumentList[];
    DocumentSettings: DocumentSetting[];
}

export interface DocumentList {
    id: string;
    patientId: string;
    memberId: string;
    encounterId: string;
    categoryId: number;
    note: string;
    fileName: string;
    contentType: string;
    filePath: string;
    thumbNail: string;
    moduleName: string;
    content: string;
    size: number;
    statusId: number;
    recordId: string;
    recordTypeId: number;
    modifiedBy: string;
    modifiedOn: string;
    downloadUrl: string;
    name: string;
}
export interface DocumentSetting {
    name: string;
    value: string;
    operator: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestDocumentListsAction {
    type: 'REQUEST_DOCUMENT_LIST';
}

interface ReceiveDocumentListsAction {
    type: 'RECEIVE_DOCUMENT_LIST';
    documentLists: DocumentList[]
}

interface ReceiveDocumentSettingAction {
    type: 'RECEIVE_DOCUMENT_SETTING';
    DocumentSettings: DocumentSetting[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestDocumentListsAction | ReceiveDocumentListsAction | ReceiveDocumentSettingAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestDocumentLists: (memberid: any, modulename: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        //dispatch(actionCreators.requestSettingDocument(modulename));
        var data = window.location.pathname;
        const _array = data.split('/');
        if (_array[1] == "visitdetail") {
            var encounterid: any = _array[2];
            var urlParams:any = new URLSearchParams(window.location.search);
            var memberId:any = urlParams.get('patientid');
        }
        else {
            var encounterid = null;
        }
        if (_array[1] === "insurance" || _array[1] === "addinsurance") {
            var urlParams: any = new URLSearchParams(window.location.search);
            var memberId: any = urlParams.get('memberid');      
        }
        /*var encounterid = null;*/
        var statusid:any  = null;
        var appointmentid: any = localStorage.getItem('AppointmentId');

        if (_array[1] == "conditions") {
            //if (appointmentid) {
            //    statusid = 1;
            //} else {
            //    statusid = 3;
            //}
            statusid = 3
            var urlParams:any = new URLSearchParams(window.location.search);
            var memberId:any = urlParams.get('childid') == null || urlParams.get('childid') === "null" ? urlParams.get('patientId') || urlParams.get('patientid') : urlParams.get('childid')
        } else {
            statusid = 1;
        }
        
        if (appState && appState.doctorLists && memberid != "") {
            var langcode: any = localStorage.getItem("DefaultLangCode");
            axios.get('/document/GetDocumentList/' + memberId + "/" + modulename + "/" + encounterid + "/" + langcode +"/"+ statusid)
                .then(function (response) {
                    const result = response.data;
                    if (_array[1] == "insurance") {
                        var urlParams: any = new URLSearchParams(window.location.search);
                        var recordid: any = urlParams.get('id');
                        var doids: any = localStorage.getItem("DocIds");
                        var array: any = JSON.parse(doids);
                        var currentdoument: any = [];
                        if (array != null) {
                            var arrays: any = array;
                            arrays.forEach((id: any, index: any) => {
                                var id: any = id.toLowerCase();
                                if (result.length == 0)
                                    currentdoument.push(_data[0])
                                else
                                    var _data = result.filter((se: any) => (se.id == id));
                                if (_data.length != 0)
                                    currentdoument.push(_data[0]);
                            });
                        }
                        var insuranceDocs: any = [];
                        var _insuranceDocs: any = [];
                        if (recordid != undefined) {
                            if (recordid != null) {
                                insuranceDocs = result.filter((se: any) => (se.recordId == recordid));
                            }
                        }
                      
                        _insuranceDocs = currentdoument.concat(insuranceDocs);
                        const seenIds: any = new Set<number>();
                        _insuranceDocs = _insuranceDocs.filter((s: any) => {
                            if (seenIds.has(s.id)) {
                                return false;
                            }else {
                                seenIds.add(s.id);
                                return true
                            }
                        });
                            
                           
                        dispatch({ type: 'RECEIVE_DOCUMENT_LIST', documentLists: _insuranceDocs });

                    }
                    else if (_array[1] == "addinsurance") {
                        const params = new Proxy(new URLSearchParams(window.location.search), {
                            get: (searchParams: any, prop: any) => searchParams.get(prop),
                        });
                        var recordid = params.id; // "some_value"
                        var doids: any = localStorage.getItem("DocIds");
                        var array: any = JSON.parse(doids);
                        var currentdoument: any = [];
                        if (array != null) {
                            var arrays: any = array;
                            arrays.forEach((id: any, index: any) => {
                                var id: any = id.toLowerCase();
                                if (result.length == 0)
                                    currentdoument.push(_data[0])
                                else
                                    var _data = result.filter((se: any) => (se.id == id));
                                if (_data.length != 0)
                                    currentdoument.push(_data[0]);
                            });
                        }
                        var insuranceDocs: any = [];
                        var _insuranceDocs: any = [];
                        if (recordid != undefined) {
                            if (recordid != null) {
                                insuranceDocs = result.filter((se: any) => (se.recordId == recordid));
                            }
                        }

                        _insuranceDocs = currentdoument.concat(insuranceDocs);
                        dispatch({ type: 'RECEIVE_DOCUMENT_LIST', documentLists: _insuranceDocs });
                    }
                    else if (_array[1] == "visitdetail") {
                        var _Docs: any = result.filter((se: any) => (se.recordId != null ? se.recordId : se.encounterId == _array[2]));
                        dispatch({ type: 'RECEIVE_DOCUMENT_LIST', documentLists: _Docs });
                    }
                    else if (_array[1] == "conditions") {
                       var appointmentid: any = localStorage.getItem('AppointmentId');                        
                        var _Docs: any = result.filter((se: any) => ((se.recordId == null || se.recordId == appointmentid || se.statusId == 3 ) && se.recordTypeId != "124"));
                        dispatch({ type: 'RECEIVE_DOCUMENT_LIST', documentLists: _Docs });
                    }
                    else {
                        var docs: any = result.filter((se: any) => se.recordTypeId != "124");
                        dispatch({ type: 'RECEIVE_DOCUMENT_LIST', documentLists: docs });
                    }
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCUMENT_LIST' });
        }
    },
    requestDocumentDelete: (memberid: any, modulename: any, docid: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        if (appState && appState.doctorLists) {
            axios.get('/document/DocumentDelete/' + docid)
                .then(function (response) {
                    const result = response.data;
                    var langdata: any = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
                    toastr.success('', langdata['deletedsuccessfully']);
                    var doids: any = localStorage.getItem("DocIds");
                    var array: any = JSON.parse(doids);
                    if (array != null) {
                        var setvalue = array.filter((id: any) => (id.toLowerCase() != docid));
                        localStorage.setItem("DocIds", JSON.stringify(setvalue));
                    }
                    dispatch(actionCreators.requestDocumentLists(memberid, modulename));
                })
                .catch(error => {
                    if (error.response.data.statusCode == 400) {
                        var errorObject = JSON.parse(error.response.data.errorMessage);
                        toastr.error('', errorObject.error_description);
                    } else {
                        errorHandling(error.response.data.statusCode);
                    }
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_DOCUMENT_LIST' });
        }
    },
    requestDocumentDownload: (path: any, name: any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var url: any = path
        var file_name: any = name
        const link = document.createElement("a");
        link.href = url
        link.download = file_name;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
    },
    requestViewDownload: (filePath: any, contenttype: any,name:any): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        axios.get('patient/FileUrl?filepath=' + filePath + "&contenttype=" + contenttype)
            .then(function (response) {
                var base64data = response.data;
                var objecturl = URL.createObjectURL(dataURItoBlob(base64data));
                var w: any = window.open(objecturl, "_blank");
                w.onload = function () {
                    w.document.title = name;
                };            
            })
            .catch(error => {
                if (error.response.data.statusCode == 400) {
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    toastr.error('', errorObject.error_description);
                } else {
                    errorHandling(error.response.data.statusCode);
                }
            })
            .then(function () {
            });
        //axios({
        //    method: 'get',
        //    url:'patient/FileUrl?filepath=' + filePath + "&contenttype=" + contenttype, // blob url eg. blob:http://127.0.0.1:8000/e89c5d87-a634-4540-974c-30dc476825cc
        //    responseType: 'blob'
        //}).then(function (response) {
        //    var reader = new FileReader();
        //    reader.readAsDataURL(response.data);
        //    reader.onloadend = function () {
        //        var base64data = reader.result;
        //        var objecturl = URL.createObjectURL(dataURItoBlob(base64data));
        //        window.open(objecturl, '_blank');
        //    }
        //})
    },
    requestSettingDocument: (modulename: any): AppThunkAction<KnownAction> => (dispatch: any, getState) => {

        const libraryname = "DocumentUpload";
        axios.get('/document/GetDocumentSetting/' + libraryname + "/" + modulename)
            .then(function (response) {
                const result = response.data;
                dispatch({ type: 'RECEIVE_DOCUMENT_SETTING', DocumentSettings: result });
            })
            .catch(error => {
                if (error.response.data.statusCode == 400) {
                    var errorObject = JSON.parse(error.response.data.errorMessage);
                    toastr.error('', errorObject.error_description);
                } else {
                    errorHandling(error.response.data.statusCode);
                }
            })
            .then(function () {
            });
        dispatch({ type: 'RECEIVE_DOCUMENT_SETTING' });
    }






};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: DocumentListsState = { documentLists: [], isdocLoading: false, DocumentSettings: [] };

export const reducer: Reducer<DocumentListsState> = (state: DocumentListsState | undefined, incomingAction: Action): DocumentListsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_DOCUMENT_LIST':
            return {
                documentLists: state.documentLists,
                isdocLoading: true,
                DocumentSettings: state.DocumentSettings
            };
        case 'RECEIVE_DOCUMENT_LIST':
            return {
                documentLists: action.documentLists,
                isdocLoading: false,
                DocumentSettings: state.DocumentSettings
            };
        case 'RECEIVE_DOCUMENT_SETTING':
            return {
                documentLists: state.documentLists,
                isdocLoading: false,
                DocumentSettings: action.DocumentSettings
            };
        default:
            return state;
    }
};
function dataURItoBlob(dataURI: any) {
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
}



