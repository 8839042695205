import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';



export interface HeaderDrProfileState {
    bioIsLoading: boolean;
    startDateIndex?: number;
    headerdrprofile: HeaderDrProfile;
}

export interface HeaderDrProfile {
    doctorId: string;
    prefix: string;
    suffix: string;
    doctorName: string;
    speciality: Speciality[];
    doctorServiceDetails: DoctorServiceDetail[];
    gender: string;
    accountName: string;
    price: number;
    minPrice: any;
    encounterTypeMode: string;
    specialityName: string;
    facilityName: string;
    photo: string;
    flag: boolean;
    encounterTypeModeDurationId: string;
    durationCode: string;
    modeCode: string;
}
export interface Speciality {
    id: string;
    name: string;
}
export interface DoctorServiceDetail {
    serviceId: string;
    price: string;
    modeCode: string,
    encounterTypeMode: string;
    durationCode: string;
    duration: string,
    encounterTypeId: string;
}
interface RequestHeaderDrProfile {
    type: 'REQUEST_HEADER_DRPROFILE';
}

interface ReceiveHeaderDrProfile {
    type: 'RECEIVE_HEADER_DRPROFILE';
    headerdrprofile: HeaderDrProfile;
}

type KnownAction = RequestHeaderDrProfile | ReceiveHeaderDrProfile;

export const actionCreators = {
    requestHeaderDrProfile: (doctorid:any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState: any = getState();
        
        if (appState && appState.headerDrProfile) {
            const urlParams = new URLSearchParams(window.location.search);
            var visitTypeId = urlParams.get('visitTypeId')
            var langcode: any = localStorage.getItem("DefaultLangCode");
            //const specialityid = urlParams.get('specialityid');
            var specialityid: any = localStorage.getItem("SpecialityId") || urlParams.get('specialityid');
            //var _enableVisitType: any = appState.account.Settings.filter((se: any) => se.name == "Enable Visit Type")[0];
            //var enableVisitType: any = _enableVisitType.customizeValue;
            if (appState.account.Settings.length != 0) {
                var _headerenableVisitType: any = appState.account.Settings.filter((se: any) => se.name == "Enable Visit Type")[0];
            var headerenableVisitType = _headerenableVisitType.customizeValue
        }
            var consultationtypeid : any = ""
            /*var encountertypeid: any = "";*/
            const status = urlParams.get('status');
            if (status == "followup" || localStorage.getItem("followup") == "followup" )
                consultationtypeid = 2
            else
                consultationtypeid = 1
            var encountertypeid: any= ""
            if (headerenableVisitType == "Yes" && (visitTypeId == "" || visitTypeId == null || visitTypeId == undefined))
            encountertypeid = localStorage.getItem("ServiceEncounterTypeId");
            else if(visitTypeId != "" || null || undefined)
                encountertypeid = visitTypeId
            else
                encountertypeid = null
            axios.get('/doctor/GetDoctorDetails/' + doctorid + "/" + langcode + "/" + specialityid + "/" + consultationtypeid + "/" + encountertypeid, { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const value = response.data;
                    var data = window.location.pathname;
                    const _array = data.split('/');
                    let array2 = value.doctorServiceDetails
                    //    .sort((a: any, b: any) => {
                    //    if (b.duration === a.duration) {
                    //        // If two elements have same number, then the one who has larger rating.average wins
                    //        return a.duration - b.duration;
                    //    } else {
                    //        // If two elements have different number, then the one who has larger number wins
                    //        return a.duration - b.duration;
                    //    }
                    //});
                    //array2.filter((element: any, index: any) => {
                    //    //return element;
                    //    return index === 0 || element.duration !== array2[index - 1].duration;
                    //});
                    if (_array[1] == "ourdoctors" && !localStorage.getItem("EncDetail")) {
                        localStorage.setItem("EncDetail", JSON.stringify({ durationCode: array2[0].durationCode, encounterTypeMode: array2[0].encounterTypeMode, minPrice: array2[0].price, encounterTypeModeDurationId: array2[0].encounterTypeModeDurationId, serviceId: array2[0].serviceId, modeCode: array2[0].modeCode }))
                    }
                    if (_array[1] == "booking" && !localStorage.getItem("EncDetail")) {
                        localStorage.setItem("EncDetail", JSON.stringify({ durationCode: array2[0].durationCode, encounterTypeMode: array2[0].encounterTypeMode, minPrice: array2[0].price, encounterTypeModeDurationId: array2[0].encounterTypeModeDurationId, serviceId: array2[0].serviceId, modeCode: array2[0].modeCode }))
                    }
                    if (_array[1] == "centralized" && !localStorage.getItem("EncDetail")) {
                        localStorage.setItem("EncDetail", JSON.stringify({ durationCode: array2[0].durationCode, encounterTypeMode: array2[0].encounterTypeMode, minPrice: array2[0].price, encounterTypeModeDurationId: array2[0].encounterTypeModeDurationId, serviceId: array2[0].serviceId, modeCode: array2[0].modeCode }))
                    }
                    dispatch({ type: 'RECEIVE_HEADER_DRPROFILE', headerdrprofile: value});
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_HEADER_DRPROFILE' });
        }
    }
};


const unloadedState: HeaderDrProfileState = {
    headerdrprofile: { doctorId: "", prefix: "", suffix: "", doctorName: "", speciality: [], doctorServiceDetails: [], gender: "", accountName: "", price: 0, encounterTypeMode: "", specialityName: "", facilityName: "", photo: "", flag: false, minPrice: 0, encounterTypeModeDurationId: "", durationCode: "", modeCode:""}, bioIsLoading: false
};

export const reducer: Reducer<HeaderDrProfileState> = (state: HeaderDrProfileState | undefined, incomingAction: Action): HeaderDrProfileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_HEADER_DRPROFILE':
            return {
                headerdrprofile: state.headerdrprofile,
                bioIsLoading: true
            };
        case 'RECEIVE_HEADER_DRPROFILE':
            if (action.headerdrprofile !== state.headerdrprofile) {
                return {
                    headerdrprofile: action.headerdrprofile,
                    bioIsLoading: false
                };
            }
            break;
    }
    return state;
};
