import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { password, required, passwordsMustMatch, setTittle, scrollTop } from '../../helper/formvalidation';
import { renderField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as ChangePasswordStore from '../../store/account/ChangePassword';
import * as HandleRequest from '../../helper/Request';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { floatingJS, mobnav, seo } from '../../helper/Script';
type ChangePasswordProps =
	ChangePasswordStore.ChangePasswordState & LanguageResourcesStore.LanguageResouresState & HandleRequest.Request &
	typeof ChangePasswordStore.actionCreators & typeof LanguageResourcesStore.actionCreators &
	RouteComponentProps<{}>;
var languagecontent: any = "";

interface State {
	OldPassword: boolean;
	NewPassword: boolean;
	ConfirmPassword: boolean;

}
class ChangePassword extends React.PureComponent<ChangePasswordProps, State> {
	constructor(props: any) {
		super(props);
	}
	state = {
		OldPassword: false,
		NewPassword: false,
		ConfirmPassword: false,
	};

	handleOldPassword = () => {
		this.setState((prevState) => ({
			OldPassword: !prevState.OldPassword
		}));
	};

	handleNewPassword = () => {
		this.setState((prevState) => ({
			NewPassword: !prevState.NewPassword
		}));
	};

	handleConfirmPassword = () => {
		this.setState((prevState) => ({
			ConfirmPassword: !prevState.ConfirmPassword
		}));
	};

    onSubmit = (formValues: any, dispatch: any, props: any) => {
		this.props.requeschangePassword(formValues)
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderChangePassword()}
            </React.Fragment>
        );
	}
	componentDidUpdate(){
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }
   
	public componentDidMount() {
		this.ensureDataFetched();
		floatingJS()
		mobnav()
		scrollTop();
		/*seo({
			title: 'DrCare247-Change Password',
			pageTitle: 'Change Password',
			description: ''
		});*/
	}
	private ensureDataFetched() {
		this.props.getLangResources();
	}

	public renderChangePassword() {
		languagecontent = this.props.LanguageResoures;
		const OldPassword: any = this.state.OldPassword;
		const NewPassword: any = this.state.NewPassword;
		const ConfirmPassword: any = this.state.ConfirmPassword;


        return (
            <React.Fragment>
				<div className="content">
					<div className="container-fluid">
						<div className="row">
							<DashboardNavMenu />
							<div className="col-md-7 col-lg-8 col-xl-9">
                                <div className="card">
									<div className="card-body">
										<form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={ "off"}>
											<div className="row">
												<div className="col-md-12 col-lg-6">
													<div className='position-relative'>

														<Field name="OldPassword" component={renderField} label={languagecontent['currentpassword']} validate={[required, password]} type={OldPassword ? "text" : "password"} />

														<i onClick={this.handleOldPassword} className={OldPassword ? "password-icon fas fa-eye-slash" : "password-icon fas fa-eye"}></i>
													</div>
													<div className='position-relative'>

														<Field name="NewPassword" component={renderField} label={languagecontent['newpassword']} validate={[required, password]} type={NewPassword ? "text" : "password"} />

														<i onClick={this.handleNewPassword} className={NewPassword ? "password-icon fas fa-eye-slash" : "password-icon fas fa-eye"}></i>
													</div>
													<div className='position-relative'>

														<Field name="ConfirmPassword" component={renderField} label={languagecontent['confirmpassword']} validate={[required, password, passwordsMustMatch]} type={ConfirmPassword ? "text" : "password"} />

														<i onClick={this.handleConfirmPassword} className={ConfirmPassword ? "password-icon fas fa-eye-slash" : "password-icon fas fa-eye"}></i>
													</div>

													{/*<Field name="OldPassword" component={renderField} label={languagecontent['currentpassword']} validate={[required, password]} type="password" />*/}
													{/*<Field name="NewPassword" component={renderField} label={languagecontent['newpassword']} validate={[required, password]} type="password" />*/}
													{/*<Field name="ConfirmPassword" component={renderField} label={languagecontent['confirmpassword']} validate={[required, password, passwordsMustMatch]} type="password" />*/}


												</div>
												<div className="col-md-12 col-lg-6">
													{
														languagecontent['specialcharacters'] == undefined ? "" :
															<div className="card">
																<div className="card-body p-16">
																	<ul className="mb-0 chg_pwd_instruction">
																		<li>{languagecontent['specialcharacters']}</li>

																	</ul>
																</div>
															</div>
                                                    }
												</div>
												<div className="col-md-12">
													<div className="submit-section">
														<button type="submit" className="btn btn-primary submit-btn"> {this.props.isCPLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {languagecontent['save'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['save']}</button>
													</div>
												</div>
											</div>
                                        </form>
                                    </div>
								</div>
							</div>
						</div>
					</div>

				</div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = { ...ChangePasswordStore.actionCreators, ...LanguageResourcesStore.actionCreators };
const mapStateToProps = (state: ApplicationState) => ({ ...state.changePassword, ...state.langResources })
export default reduxForm({
	form: 'changePasswordForm',
})(connect(mapStateToProps, mapDispatchToProps)(ChangePassword as any))

