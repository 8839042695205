import axios from 'axios';
import { push } from 'connected-react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { toastr } from 'react-redux-toastr';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { actionCreators as _contactAction } from '../../store/patient/Contact';
import { actionCreators as _siteMenu } from '../../store/siteaccount/siteMenu';
import { actionCreators as _getSignalRCredential } from '../SiteDetail';

export interface NavDefaultState {
    navisLoading: boolean;
    startDateIndex?: number;
    navDefault: NavDefault;
}

export interface NavDefault {
    isAuth: boolean;
}

interface RequestNavDefault {
    type: 'REQUEST_HEADER_NAVDEFAULT';
}

interface ReceiveNavDefault {
    type: 'RECEIVE_HEADER_NAVDEFAULT';
    navDefault: NavDefault;
}

type KnownAction = RequestNavDefault | ReceiveNavDefault;

export const actionCreators = {
    requestNavDefault: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        // localStorage.setItem("isAuth", "false");
        var pathname = window.location.pathname;
        var page = pathname.split('/')[1];
        const appState = getState();
        if (appState && appState.navDefault) {
            axios.get('account/index')
                .then(function (response) {
                    const data = response.data;                    
                    dispatch({ type: 'RECEIVE_HEADER_NAVDEFAULT', navDefault: data, navisLoading: false });
                    if (data.isAuth == true) {
                        localStorage.setItem("isAuth", "true");
                        dispatch(_getSignalRCredential.getSignalRCredential())
                        dispatch(actionCreators.getFormModule());
                        dispatch(_contactAction.requestPatient());
                        dispatch(_siteMenu.getSiteMenu());
                        return;
                    } else if (data.isAuth == false) {
                        localStorage.setItem("isAuth", "false");

                    } else {

                    }
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_HEADER_NAVDEFAULT' });
        }
    },
    requestRefeshToken: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        // localStorage.setItem("isAuth", "false");
        var pathname = window.location.pathname;
        var page = pathname.split('/')[1];
        const appState = getState();
        if (appState && appState.navDefault) {
            axios.get('account/refreshtoken')
                .then(function (response) {
                    const data = response.data;
                    dispatch(actionCreators.requestNavDefault());
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
        }
    },
    requestLogout: (): AppThunkAction<KnownAction> => (dispatch: any, getState:any) => {
        const appState = getState();

        dispatch(showLoading('sectionBar'));
        if (appState && appState.navDefault) {
            axios.get('account/logout')
                .then(function (response) {
                    dispatch({ type: 'RECEIVE_HEADER_NAVDEFAULT', navDefault: { isAuth: false } });
                    localStorage.setItem("isAuth", "false");
                    var logoutUrl: any = appState.account.Settings.filter((se: any) => se.name == "Client Portal Logout Url")[0];
                    localStorage.removeItem("Patient");
                    //localStorage.removeItem("isAuth");
                    localStorage.removeItem("pathName");
                    localStorage.removeItem("redirectUrl");
                    localStorage.removeItem("EmailId");
                    localStorage.removeItem("TimeZoneTimeSlot");
                    localStorage.removeItem("redirectQequestQueueUrl");
                    localStorage.removeItem("Appointment");
                    localStorage.removeItem("ProfileData");
                    localStorage.removeItem("contactUrl");
                    //dispatch(push("/login"));
                    dispatch(hideLoading('sectionBar'));
                    
                    if (logoutUrl && logoutUrl.customizeValue !== undefined) {
                        if (logoutUrl.customizeValue != "") {
                            window.location.href = logoutUrl.customizeValue
                            dispatch(actionCreators.requestNavDefault());
                        } else {
                            document.location.reload();
                        }
                    }
                    else {                     
                        document.location.reload();
                    }                   
                    
                })
                .catch(function (error) {
                    dispatch(hideLoading('sectionBar'));
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                });
            dispatch({ type: 'REQUEST_HEADER_NAVDEFAULT' });
        }
    },
    goNextPage: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        const appState = getState();
        var enablefamilymember: any = appState.account.Settings.filter((se: any) => se.name == "Enable Family Member")[0];
        var doctorappointment: any = appState.account.Settings.filter((se: any) => se.name == "Doctor Appointment")[0];
        var requestqueue: any = appState.account.Settings.filter((se: any) => se.name == "Request Queue")[0];
        var anonymous: any = appState.account.Settings.filter((se: any) => se.name == "Anonymous")[0];
        if (enablefamilymember != undefined) {
            if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "No")) {
                if (appState.memberSpeciality != undefined) {
                    if (appState.memberSpeciality.speciality.length == 1)
                        window.location.href = "/ourdoctors?specialityid=" + appState.memberSpeciality.speciality[0].id;
                    else
                        dispatch(push("/memberspeciality?name=ourdoctors"));
                }
                else
                    dispatch(push("/memberspeciality?name=ourdoctors"));

            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                if (appState.memberSpeciality != undefined) {
                    if (appState.memberSpeciality.speciality.length == 1)
                        window.location.href = "/centralized?specialityid=" + appState.memberSpeciality.speciality[0].id;
                    else
                        dispatch(push("/memberspeciality?name=centralized"));
                }
                else
                    dispatch(push("/memberspeciality?name=centralized"));
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                var auth: any = localStorage.getItem("isAuth");
                if (auth == "true") {
                    var result: any = JSON.parse(localStorage.getItem("Patient") || "");
                    if (result.dob == null || result.gender == "" || result.cellPhone == "" || result.address1 == "" || result.zipcode == "" || result.locationName == "") {
                        window.location.href = "/contact/" + null + "/" + result.memberId;
                    } else {
                        if (appState.memberSpeciality != undefined) {
                            if (appState.memberSpeciality.speciality.length == 1)
                                window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + result.id + "&serviceid=&type=noappointment&specialityid=" + appState.memberSpeciality.speciality[0].id;
                            else
                                window.location.href = "/memberspeciality?name=consultnow&patientid=" + result.id;
                        }
                        else
                            window.location.href = "/memberspeciality?name=consultnow&patientid=" + result.id;
                        //window.location.href = "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=&patientId=" + result.id + "&serviceid=&type=noappointment";
                    }
                } else {
                    if (appState.memberSpeciality != undefined) {
                        if (appState.memberSpeciality.speciality.length == 1) {
                            localStorage.setItem("pathName", "RequestQueue")
                            localStorage.setItem("redirectQequestQueueUrl", "/conditions/null/appointment?time=&date=&encountertypeid=&encountertypemode=&durationcode=&enctypeid=&methodid=5&price=0&referrralid=null&serviceid=&type=noappointment&specialityid=" + appState.memberSpeciality.speciality[0].id);
                            window.location.href = "/login";
                        }                           
                        else
                            window.location.href = "/memberspeciality?name=consultnow";
                    }
                    else                        
                    window.location.href = "/memberspeciality?name=consultnow";
                }
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                dispatch(push("/consultationrequest"));
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                dispatch(push("/consultationrequest"));
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "Yes") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                dispatch(push("/consultationrequest"));
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "No") && (anonymous.customizeValue == "Yes")) {
                dispatch(push("/consultationrequest"));
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "No")) {
                dispatch(push("/consultationrequest"));
            }
            else if ((enablefamilymember.customizeValue == "No") && (doctorappointment.customizeValue == "No") && (requestqueue.customizeValue == "Yes") && (anonymous.customizeValue == "Yes")) {
                dispatch(push("/consultationrequest"));
            }
            else if (enablefamilymember.customizeValue == "Yes") {
                var auth: any = localStorage.getItem("isAuth");
                if (auth == "true") {
                    dispatch(push("/consultationrequest?memberid=" + appState.contact.contact.memberId));
                } else {
                    window.location.href = "/login";
                }
            }
            else {
                if (appState.memberSpeciality != undefined) {
                    if (appState.memberSpeciality.speciality.length == 1)
                        window.location.href = "/ourdoctors?specialityid=" + appState.memberSpeciality.speciality[0].id;
                    else
                        dispatch(push("/memberspeciality?name=ourdoctors"));
                }
                else
                    dispatch(push("/memberspeciality?name=ourdoctors"));
            }
        }
    },
    getFormModule: (): AppThunkAction<KnownAction> => (dispatch: any, getState: any) => {
        var accountid: any = localStorage.getItem("AccountId");
        axios.get('/patient/GetFormModule/' + accountid + "/" + "6")
            .then(function (response) {
                const result = response.data;
                var formArray: any = [];
                formArray = result.filter(function (el: any) {
                    return ((el.id == null) && (el.showOnce == true) && (el.moduleId != 71));
                });
                if (formArray.length != 0) {
                    var patientLocalStroage: any = localStorage.getItem('Patient');
                    var patient = JSON.parse(patientLocalStroage);
                    dispatch(push("/initialpageforms/" + formArray[0].moduleName + "/" + patient.id + "/" + patient.id + "/" + null))
                }
            })
            .catch(function (error) {
                errorHandling(error.response.data.statusCode);
                dispatch(hideLoading('sectionBar'))
            })
            .then(function () {
                // always executed
            });
    },
};


const unloadedState: NavDefaultState = {
    navDefault: { isAuth: false }, navisLoading: true
};

export const reducer: Reducer<NavDefaultState> = (state: NavDefaultState | undefined, incomingAction: Action): NavDefaultState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_HEADER_NAVDEFAULT':
            return {
                navDefault: state.navDefault,
                navisLoading: true
            };
        case 'RECEIVE_HEADER_NAVDEFAULT':
            if (action.navDefault !== state.navDefault) {
                return {
                    navDefault: action.navDefault,
                    navisLoading: false
                };
            }
            break;
    }
    return state;
};

function getPathname() {
    var pathname = window.location.pathname;
    var appId = pathname.split('/')[1];
}


