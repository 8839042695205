import { title } from 'process';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { scrollTop } from '../../helper/formvalidation';
import { seo } from '../../helper/Script';
import Flexiform from '../library/flexiform/FlexiForm';
import HeaderDrProfile from '../widget/HeaderDrProfile';
type CounterProps = RouteComponentProps<{}>;

class Questions extends React.PureComponent<CounterProps> {
    
    public componentDidMount() {

        seo({
            title: 'Appointments Questionnaire',
            pageTitle: 'Intake Questionnaire',
            description: ''
        });
        scrollTop();
    }
    componentDidUpdate() {
        seo({
            title: 'Appointments Questionnaire',
            pageTitle: 'Intake Questionnaire',
            description: '',

        });
        scrollTop();
    }
    public render() {
        return (
            //var formList: any = [];

            <React.Fragment>
                <div className="content">
                    <div className="container">
                        <HeaderDrProfile />
                        <div >
                            
                                    <Flexiform />  
                           
                        </div>
                    </div>
                    
                    
                </div>
            </React.Fragment>
        );
    }
};
export default connect(
   
)(Questions);
