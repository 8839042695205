import axios from 'axios';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EncounterTypeState {
    encountertypes: EncounterType[];
}

export interface EncounterType {
    id: string;
    name: string;
    description: string;
    icon: string;
    parentIcon: string;
    encounterTypeModeId: string;
    accountId: string;
}


// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface RequestEncounterType { type: 'REQUEST_ENC_TYPE', encountertypes: EncounterType[] }
export interface ReciveEncounterType { type: 'RECEIVE_ENC_TYPE', encountertypes: EncounterType[] }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestEncounterType | ReciveEncounterType;
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestEncounterType: (): AppThunkAction<KnownAction> => (dispatch: any, getState) => {
        const appState = getState();
        if (appState && appState.encountertypes) {
            axios.get('/appointment/encountertype', { headers: { Authorization: "BasicAuth" } })
                .then(function (response) {
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_ENC_TYPE', encounterTypes: result });
                })
                .catch(function (error) {
                    errorHandling(error.response.data.statusCode);
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_ENC_TYPE' });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: EncounterTypeState = {
    encountertypes: [],
};
export const reducer: Reducer<EncounterTypeState> = (state: EncounterTypeState | undefined, incomingAction: Action): EncounterTypeState => {
    if (state === undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ENC_TYPE':
            return {
                encountertypes: state.encountertypes,
            };
        case 'RECEIVE_ENC_TYPE':
            return {
                encountertypes: action.encountertypes,
            };
        default:
            return state;
    }
};

