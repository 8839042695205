import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { errorHandling } from '../../helper/ErrorHandling';
import { Patient } from '../appointment/Condition';
import * as HandleRequest from '../../helper/Request';
import { push } from 'connected-react-router';
import { actionCreators as _NavDefault } from '../../store/menu/NavDefault';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
var patientLocalStroage: any = localStorage.getItem('Patient');
var patient = JSON.parse(patientLocalStroage);
export interface AppointmentChildState {
    isChildLoading: boolean;
    startDateIndex?: number;
    appointmentchildlists: AppointmentChildList[];
}

export interface AppointmentChildList {
    appointmentId: string;
    doctorId: string;
    doctorName: string;
    appointmentDate: string;
    reason: string;
    type: string;
    appointmentStatus: string;
    scheduleDate: string;
    hummyURL: string;
    referredBy: string;
    referredTo: string;
    isRefButtonShow: boolean;
    photo: string;
    patientReferrralId: string;
    referredDoctorId: string;
    accountId: string
    duration: string;
    showReschedule: boolean;
    doctorSpeciality: any;
    appointmentType: string;
    note: string;
    referenceNo: string
    encounterId: string
    payConceptId: any,
    paymentStatusId: any,
    relationship: any,
    memberName: any,
    isShowPayBtn: any;
}



// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestAppointmentChildListAction {
    type: 'REQUEST_APPOINTMENTCHILD_LIST';
    startDateIndex: number;
    isChildLoading: boolean;
    appointmentchildlists: AppointmentChildList[]
}

interface ReceiveAppointmentChildListAction {
    type: 'RECEIVE_APPOINTMENTCHILD_LIST';
    startDateIndex: number;
    isChildLoading: boolean;
    appointmentchildlists: AppointmentChildList[]
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestAppointmentChildListAction | ReceiveAppointmentChildListAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestAppointmentChildList: (value:any): AppThunkAction<KnownAction> => async (dispatch: any, getState: () => any) => {
        const appState = getState();
        dispatch(showLoading('sectionBar'));
        if (appState && appState.appointmentChildLists) {
            var accountid: any = localStorage.getItem("AccountId");
            var langcode: any = localStorage.getItem("DefaultLangCode");
            await axios.get('/appointment/getappointmentLists/' + patient.id + "/" + accountid + "/" + value.appointmentId + "/" + langcode)
                .then(function (response) {
                    dispatch(hideLoading('sectionBar'));
                    const result = response.data;
                    dispatch({ type: 'RECEIVE_APPOINTMENTCHILD_LIST', appointmentchildlists: result, isChildLoading:false });
                })
                .catch(function (error) {
                    if (error.response.data.statusCode == 401) {
                        window.location.href = "/login";
                    } else {
                        errorHandling(error);
                    }
                })
                .then(function () {
                    // always executed
                });
            dispatch({ type: 'REQUEST_APPOINTMENTCHILD_LIST', isChildLoading:true });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AppointmentChildState = { appointmentchildlists: [], isChildLoading: false };

export const reducer: Reducer<AppointmentChildState> = (state: AppointmentChildState | undefined, incomingAction: Action): AppointmentChildState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_APPOINTMENTCHILD_LIST':
            return {
                appointmentchildlists: state.appointmentchildlists,
                isChildLoading: true
            };
        case 'RECEIVE_APPOINTMENTCHILD_LIST':
            return {
                appointmentchildlists: action.appointmentchildlists,
                isChildLoading: false
            };
        default:
            return state;
    }
};


