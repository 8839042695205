import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as TimingStore from '../../store/patient/Booking';
import * as ConditionStore from '../../store/appointment/Condition';
import * as  HeaderDrProfileStore from '../../store/widget/HeaderDrProfile';
import * as LoginStore from '../../store/account/Login';
import * as NavMenuStore from '../../store/menu/NavDefault';
import * as AccountStore from '../../store/siteaccount/Account';
import * as PayMethodStore from '../../store/payment/PayMethod';
import HeaderDrProfile from '../widget/HeaderDrProfile';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as RescheduleState from '../../store/appointment/Reschedule';
import ContentLoader from "react-content-loader"
import Moment, { locale } from 'moment';
import 'moment-timezone';
import { toastr } from 'react-redux-toastr';
import { mobnav, seo } from '../../helper/Script';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { scrollTop, setTittle, checkFamilyMember, required } from '../../helper/formvalidation';
import { Field, reduxForm } from 'redux-form';
import { renderSelectField, renderSelectFieldEncounter } from '../../helper/renderfield';
type TimingProps =
    TimingStore.BookingState & ConditionStore.ConditionState & LoginStore.LoginState & TimeZoneStore.TimeZoneState & PayMethodStore.PayMethodState & NavMenuStore.NavDefaultState & HeaderDrProfileStore.HeaderDrProfileState & AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState & RescheduleState.RescheduleState // ... state we've requested from the Redux store
    & typeof TimingStore.actionCreators & typeof ConditionStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof LoginStore.actionCreators & typeof PayMethodStore.actionCreators & typeof NavMenuStore.actionCreators & typeof HeaderDrProfileStore.actionCreators & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators & typeof RescheduleState.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var seletedday = "";
var timeSeleted = "";
var encountertypeid = "";
var indexdata = 0;
var weekindex = 0;
var TimeIndex: any = "";
var serviceid: any = "";
var __currency: any = [];
var Currenttimezone: any = [];
var languagecontent: any = "";
var langdata: any = "";
var consultationfree: any = [];
var datepicker: any = [];
var finalencountername: any;
var storedData: any = "";
var encountertype: any = "";
var reasonvisittypedisable: any = "";

class Booking extends React.PureComponent<TimingProps> {
    constructor(props: any) {
        super(props);
        this.state = {
            isLoading: true,
            isShowTZNotification: false
        };
    }
    state = {
        isLoading: true,
        isShowTZNotification: false
    };
    closeTZ = () => {
        this.setState({
            isShowTZNotification: true
        })
    }
    onSubmit = () => {
        var langdata: any;
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        var time = localStorage.getItem("AppointmentTime");
        var date = localStorage.getItem("AppointmentOn");
        var encDetailStroage: any = localStorage.getItem('EncDetail');
        var encDetail = JSON.parse(encDetailStroage);
        if (encDetail == null) {
            return toastr.error('', "Please select appointment type");
        }
        if ((time == null) || (date == null))
            return toastr.error('', langdata['selectdateandtime']);
        this.props.encounterTypes.filter(function (value) {
            return value.name === "PPT"
        });
        this.props.encounterTypes.forEach(value => {
            encountertypeid = value.id
        })
        localStorage.setItem("EncounterTypeId", encountertypeid);
        var serviceencounterid: any = localStorage.getItem("ServiceEncounterTypeId")
        if (this.props.Settings.length != 0) {
            var _enableVisitType = this.props.Settings.filter((se: any) => se.name == "Enable Visit Type")[0];
            var enableVisitType = _enableVisitType.customizeValue
        }
        const urlParams = new URLSearchParams(window.location.search);

        var visitTypeId = urlParams.get('visitTypeId')
        
        if (enableVisitType == "Yes" && visitTypeId  == null) {
            if (serviceencounterid == null || "" || undefined) {
                return toastr.error('', langdata['selectvisittype']);
            }
        }
        this.props.redirectAppointment(this.props.match.params, "1", time, date, encountertypeid, this.props.headerdrprofile.encounterTypeMode, this.props.headerdrprofile.durationCode, this.props.headerdrprofile.encounterTypeModeDurationId, this.props.headerdrprofile.minPrice, serviceid);
    }

    onrescheduleSubmit = (formValues: any) => {
        var langdata: any;
        const urlParams = new URLSearchParams(window.location.search);
        const appointmentid = urlParams.get('appointment');
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
        var time = localStorage.getItem("AppointmentTime");
        var date = localStorage.getItem("AppointmentOn");
        var encDetailStroage: any = localStorage.getItem('EncDetail');
        var encDetail = JSON.parse(encDetailStroage);
        if (encDetail == null) {
            return toastr.error('', "Please select appointment type");
        }
        if ((time == null) || (date == null))
            return toastr.error('', langdata['selectdateandtime']);
        this.props.encounterTypes.filter(function (value) {
            return value.name === "PPT"
        });
        this.props.encounterTypes.forEach(value => {
            encountertypeid = value.id
        })
        localStorage.setItem("EncounterTypeId", encountertypeid);
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);

        if (this.props.TimeZones.length != 0) {
            Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
            if (patient.timeZone == "India Standard Time")
                formValues.Offset = 330;
            else
                formValues.Offset = Currenttimezone[0].Offset;
        }
        if (Currenttimezone.length == 0) {
            formValues.Offset = "";
        } else {
            formValues.Offset = Currenttimezone[0].Offset;
        }
        formValues.appointmentid = appointmentid;
        formValues.date = date;
        formValues.time = time;
        this.props.requestReScheduleUpdate(formValues, date, time);

    }
    selectEncType = (value: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('statuses');
        if (name != 'reschedulefollowup') {
            localStorage.setItem("EncDetail", JSON.stringify({ durationCode: value.durationCode, encounterTypeMode: value.encounterTypeMode, minPrice: value.price, encounterTypeModeDurationId: value.encounterTypeModeDurationId, serviceId: value.serviceId, modeCode: value.modeCode }))
            const today = Moment(new Date()).format('YYYY-MM-DD');
            seletedday = Moment(new Date()).format('ddd');
            //localStorage.removeItem("AppointmentOn");
            serviceid = value.serviceId;
            //localStorage.removeItem("AppointmentTime");
            window.document.getElementsByClassName("time")[0].innerHTML = "";
            localStorage.removeItem("AppointmentTime");
            this.props.headerdrprofile.minPrice = value.Price;
            this.props.headerdrprofile.encounterTypeMode = value.encounterTypeMode;
            this.props.headerdrprofile.modeCode = value.modeCode;
            this.props.headerdrprofile.durationCode = value.durationCode;
            this.props.headerdrprofile.encounterTypeModeDurationId = value.encounterTypeModeDurationId;
            if (localStorage.getItem('AppointmentOn'))
                this.props.requestTiming(localStorage.getItem('AppointmentOn') || today, this.props.match.params, "clear");
            else
                this.props.requestTiming(today, this.props.match.params, "-");
            mobnav();
        }
    }

    

    updateTimeZone = () => {
        localStorage.setItem("TimeZoneTimeSlot", window.location.href);
        if (localStorage.getItem("isAuth") == "true") {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patient = JSON.parse(patientLocalStroage);
            window.location.href = "/profilesettings/" + patient.memberId;
        } else {
            window.location.href = "/login";
        }
    }
    public componentDidMount() {
        this.props.getEncounterTypes();
        storedData = localStorage.getItem('Encountertypes');
        encountertype = JSON.parse(storedData);
        this.ensureDataFetched();
        scrollTop();
        checkFamilyMember();
        localStorage.removeItem("Discount");
        localStorage.removeItem("Appointment");
        localStorage.removeItem("AppointmentTime1");
        localStorage.removeItem("AppointmentOn1");
        localStorage.removeItem("AppointmentUTCTime1");
        localStorage.removeItem("Discount");
        localStorage.removeItem("DiscountId");
        localStorage.removeItem("DiscountAmount");
        localStorage.removeItem("TotalAmount"); 
        localStorage.removeItem("ServiceEncounterTypeId");
        if (localStorage.getItem('AppointmentBookedOn') != localStorage.getItem('AppointmentOn'))
            localStorage.removeItem('AppointmentOn')
    }
    componentDidUpdate() {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('status');
        if (name == 'reschedule')
            seo(setTittle(languagecontent == "" ? "Reschedule Appointment" : languagecontent['rescheduledappointment']));
        else {
            seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
        }

        const patientid = urlParams.get('patientid');
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);

        if (name == 'reschedule') {
            if (patientid != patient.id) {
                //{ this.renderPageNotFound() }
                localStorage.setItem("reschedule", "true")
                //window.location.href = '/dashboard'
                //window.history.go(-1)
            }
        }
    }
    

    public render() {
        return (
            <React.Fragment>
                {this.renderTiming()}
            </React.Fragment>
        );
    }

    previousredirectpage = () => {
        window.history.go(-1);
    }
    private ensureDataFetched() {
        var isAuthenticated: any = localStorage.getItem("isAuth");
        if (isAuthenticated == null) {
            window.location.href = "/login";
        }
        this.props.getEncounterTypes();
        this.props.getLangResources();
        var langcode: any = localStorage.getItem('DefaultLangCode');
        var lancode: any = "";
        if (langcode == "es-cr")
            lancode = "es-us";
        else
            lancode = langcode.split("-")[0];
        seletedday = Moment(new Date()).format('ddd'); locale(lancode);
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (patient != null) {
            if (patient.timeZone != "") {
                this.props.requestTimeZone(patient.timeZone);
            }
        }
        var currentTime = new Date();

        var currentOffset = currentTime.getTimezoneOffset();

        var SettingOffset = patient && patient.offset ? patient.offset : parseInt(localStorage.getItem('timeOffset') || "")  // IST offset UTC +5:30



        var ConvertedTime = new Date(currentTime.getTime() + (SettingOffset + currentOffset) * 60000);

        const today = Moment(ConvertedTime).format('YYYY-MM-DD');
        if (localStorage.getItem('AppointmentBookedOn')) {
            this.props.requestTiming(localStorage.getItem('AppointmentBookedOn') || today, this.props.match.params, "-");
            this.props.storeTime(localStorage.getItem('AppointmentTime'))
        } else {
            this.props.requestTiming(today, this.props.match.params, "-");
            this.props.storeTime(localStorage.getItem(''))
        }
        //this.props.getEncounterTypes();
        storedData = localStorage.getItem('Encountertypes');
        encountertype = JSON.parse(storedData);
        this.props.requestEncounterType();
        mobnav();
        localStorage.removeItem("TimeZoneTimeSlot");
        //localStorage.removeItem("AppointmentTime");

        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");
       
    }

    addDays(days: number): Date {
        var futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + days);
        return futureDate;
    }

    openDatePicker = () => {
        var element1: any = document.getElementById("datePicker");
        if (element1) {
            localStorage.setItem("AppointmentOn", element1.value);
            const day = Moment.utc(new Date(element1.value)).format('YYYY-MM-DD');
            this.props.requestTiming(day, this.props.match.params, "clear");
        }
    };



    public onDate(value: any, index: any) {
        if (weekindex != null) {
            var element1 = document.getElementById("dWeekList0");
            var element = document.getElementById("dWeekList" + weekindex);
            if (element != null) {
                if (element1 != null)
                    element1.classList.remove("active");
                element.classList.remove("active");
            }

        }
        var element = document.getElementById("dWeekList" + index);
        if (element != null)
            element.classList.add("active");
        var elems = document.querySelectorAll(".selected");
        if (elems != undefined) {
            [].forEach.call(elems, function (el: any) {
                if (el != undefined) {
                    el.classList.remove("selected");
                }
            });
        }

        weekindex = index;
        localStorage.setItem("AppointmentOn", value.date);
        localStorage.removeItem("AppointmentTime");
        window.document.getElementsByClassName("time")[0].innerHTML = "";
        const day = Moment.utc(new Date(value.date)).format('YYYY-MM-DD');
        this.props.requestTiming(day, this.props.match.params, "clear");
    }

    public onTime(value: any, index: number) {

        var langdata: any;
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");


        if (value.isAvailableSlot == false)
            return toastr.error('', langdata['appointmentalreadybooked ']);
        if (indexdata != null) {
            var element = document.getElementById("timeslot" + (localStorage.getItem('TimeIndex') || 0));
            if (element != null)
                element.classList.remove("selected");
        }
        var element = document.getElementById("timeslot" + index);
        if (element != null)
            element.classList.add("selected");
        localStorage.setItem("AppointmentTime", value.timeslot);
        localStorage.setItem("AppointmentUTCTime", value.utcTimeSlot);
        this.props.storeTime(value.timeslot)
        localStorage.setItem("AppointmentBookedOn", localStorage.getItem('AppointmentOn') || "");
        timeSeleted = value;
        indexdata = index;
        TimeIndex = index;
        localStorage.setItem("TimeIndex", TimeIndex);
        //console.log(value.timeslot, "value.timeslot")

        setTimeout(() => {
            window.document.getElementsByClassName("time")[0].innerHTML = value.timeslot;
        }, 200)



    }
    selectEncTypes = (value: any) => { }
    public _handleClickSearch = (query: any) => {
        //var string = query.substring(0, 3)
        //console.log(query, "-","test")
        /*this.props.requestLocation(query);*/
        var serviceencountertypeid: any = query.target.value;
        //var serviceencountertypename: any = query.target.innerHTML;
        localStorage.setItem("ServiceEncounterTypeId", serviceencountertypeid)
        var encounterid: any = localStorage.getItem("ServiceEncounterTypeId")
        var encounter_name: any = localStorage.getItem("Encountertypes")
        var encountername: any = JSON.parse(encounter_name)
        
        var list : any = encountername.filter((value: any) => {
            return value.encounterId == encounterid
        }
        );
        finalencountername = list[0].name
        localStorage.setItem("FinalEncounterName", finalencountername)


        //var filteredList = encountername.filter(
        //    list=() => list?.encounterId == encounterid,
        //);
        localStorage.removeItem("EncDetail")
        var data = window.location.pathname;
        const _array = data.split('/');
        if (_array[2]) {
            this.props.requestHeaderDrProfile(_array[2]);
        }
        else {
            const queryParams = new URLSearchParams(window.location.search);

            const doctorid = queryParams.get('doctorid');
            this.props.requestHeaderDrProfile(doctorid);
        }
    }
    private renderTiming() {
        languagecontent = this.props.LanguageResoures;
        const urlParams = new URLSearchParams(window.location.search);
        var name: any = urlParams.get('status');
        var visitType: any = urlParams.get('visittype');
        if (name == "followup")
            localStorage.setItem("followup", name);
        const patientid = urlParams.get('patientid');
        var appointmenttypemode = urlParams.get('type');
        if (appointmenttypemode == null)
            appointmenttypemode = urlParams.get('encountertypemode');
        //console.log(this.props.headerdrprofile.doctorServiceDetails);
        //this.props.headerdrprofile.encounterTypeMode = value.encounterTypeMode;
        //this.props.headerdrprofile.durationCode = value.durationCode;
        if (name == 'reschedule' || name == "followup") {

            var modeCode: any
            if (appointmenttypemode == "Teleconsultation" || appointmenttypemode == "Videoconsulta") {
                modeCode = "TEC";

            }
            else if (appointmenttypemode == "In Person" || appointmenttypemode == "En persona")        {
                modeCode = "INP";
            }
                else {
                modeCode = "PHN";
            }
            var typemode: any = this.props.headerdrprofile.doctorServiceDetails.filter((se: any) => se.modeCode == modeCode);


            if (typemode.length != 0) {
                var encDetailStroage: any = localStorage.getItem('EncDetail');
                var encDetail = JSON.parse(encDetailStroage);
                this.props.headerdrprofile.encounterTypeMode = typemode[0].encounterTypeMode;
                this.props.headerdrprofile.durationCode = typemode[0].durationCode;
                this.props.headerdrprofile.encounterTypeModeDurationId = typemode[0].encounterTypeModeDurationId;
                this.props.headerdrprofile.modeCode = typemode[0].modeCode;
                this.props.headerdrprofile.price = typemode[0].minPrice;
                serviceid = typemode[0].serviceId;
                localStorage.setItem("followupduration", typemode[0].durationCode)
                localStorage.setItem("followuptypemode", typemode[0].encounterTypeMode)
            }

            
        }
        else {
            if (localStorage.getItem("EncDetail")) {
                var encDetailStroage: any = localStorage.getItem('EncDetail');
                var encDetail = JSON.parse(encDetailStroage);
                this.props.headerdrprofile.encounterTypeMode = encDetail.encounterTypeMode;
                this.props.headerdrprofile.durationCode = encDetail.durationCode;
                this.props.headerdrprofile.encounterTypeModeDurationId = encDetail.encounterTypeModeDurationId;
                this.props.headerdrprofile.modeCode = encDetail.modeCode;
                this.props.headerdrprofile.price = encDetail.minPrice;
                serviceid = encDetail.serviceId;
            }
        }
        if (name == "followup") {
            localStorage.setItem("followup", name);
            //localStorage.setItem("enctypemode", typemode[0].encounterTypeMode);
        }
        var _currency: any = "";
        __currency = this.props.Settings.filter((s: any) => s.name == "Currency");
        //var value: any;
        if (__currency.length == 0) {
            _currency = "";
        }
        else {
            if (__currency[0].value == "") {
                _currency = __currency[0].displayCode;
                localStorage.setItem("currency", _currency);
            }
            else {
                _currency = __currency[0].customizeValue;
                localStorage.setItem("currency", _currency);
            }


        }
        
        var currency: any = _currency;

        consultationfree = this.props.Settings.filter((s: any) => s.name == "Show the consultation fee as Free");
        var free: any = "";
        if (consultationfree.length != 0)
            free = consultationfree[0].customizeValue
        else
            free = "";

        datepicker = this.props.Settings.filter((s: any) => s.name == "CP Test Date Picker");
        var testdatepicker: any = "";
        if (datepicker.length != 0)
            testdatepicker = datepicker[0].customizeValue
        else
            testdatepicker = "";


        var currenttimezone: any = "";
        if (localStorage.getItem("isAuth") == "true") {
            var patientLocalStroage: any = localStorage.getItem('Patient');
            var patient = JSON.parse(patientLocalStroage);
            if (patientid != null) {
                if (patient.id != patientid) {
                    window.location.href = "/Page404Error";
                }
            }
            if (patient.timeZone == "") {
                var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
                var _timezone = timezone.displayCode.split("|");
                currenttimezone = _timezone[0];
            } else {
                currenttimezone = patient.timeZone;
            }
        } else {
            if (this.props.Settings.length != 0) {
                var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
                var _timezone = timezone.customizeValue;
                currenttimezone = _timezone;
            }
        }
        /*  console.log(this.props.headerdrprofile.price)*/
         storedData = localStorage.getItem('Encountertypes');
        encountertype = JSON.parse(storedData);
        //var nameencountertype = encountertype.name
        //console.log(encountertype)
        //var encountertype: any = localStorage.getItem('Encountertypes')
        if (this.props.Settings.length != 0) {
            var _enableVisitType = this.props.Settings.filter((se: any) => se.name == "Enable Visit Type")[0];
            var enableVisitType = _enableVisitType.customizeValue
        }

        if (visitType != null) {
            reasonvisittypedisable = true;
            //reasonrequired = false;
        }
        else {
            reasonvisittypedisable = false;
            //reasonrequired = true;
        }
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container">
                        <HeaderDrProfile />
                        <div className="row">
                            <div className="col-lg-5 col-12">
                                {enableVisitType == "Yes" ?

                                    <div>
                                        <h5> {languagecontent['visittype']}</h5>
                                        {visitType == null ? < Field name="encountertypename" options={encountertype} select={languagecontent['select']} onChange={(e: any) => this._handleClickSearch(e)} component={renderSelectFieldEncounter} disabled={reasonvisittypedisable} className="form-control" />

                                            : <Field name="encountertypename" options={encountertype} select={visitType} onChange={(e: any) => this._handleClickSearch(e)} component={renderSelectFieldEncounter} disabled={reasonvisittypedisable} className="form-control" />
                                        } </div> : ""}
                                
                                {
                                    this.props.headerdrprofile.doctorServiceDetails.length == 1 ?
                                        <React.Fragment>
                                            <h5>{languagecontent['appointmenttype']}</h5>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <h5>{languagecontent['appointmenttype']}</h5>
                                            <small>{languagecontent['appointmentdescription']}</small> <br /><br />
                                        </React.Fragment>
                                }
                                <div className="card">
                                    <ul className="list-group list-group-flush benifits-col">

                                        {this.props.bioIsLoading == true ? <ContentLoader
                                            title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                            speed={2}
                                            width={350}
                                            height={160}
                                            viewBox="0 0 400 160"
                                        >
                                            <rect x="50" y="6" rx="4" ry="4" width="343" height="38" />
                                            <rect x="8" y="6" rx="4" ry="4" width="35" height="38" />
                                            <rect x="50" y="55" rx="4" ry="4" width="343" height="38" />
                                            <rect x="8" y="55" rx="4" ry="4" width="35" height="38" />
                                            <rect x="50" y="104" rx="4" ry="4" width="343" height="38" />
                                            <rect x="8" y="104" rx="4" ry="4" width="35" height="38" />
                                        </ContentLoader> :
                                            this.props.headerdrprofile.doctorServiceDetails != undefined ?
                                                this.props.headerdrprofile.doctorServiceDetails.map((doctorService: HeaderDrProfileStore.DoctorServiceDetail, index) =>
                                                    <React.Fragment>
                                                        <li className="list-group-item d-flex align-items">
                                                            <div className="avatar avatar-sm">
                                                                <label className="custom_radio">
                                                                    <div className="cp_visit-width">
                                                                        <input type="radio" name="calltype" onClick={() => this.selectEncType(doctorService)} value="Ccon" checked={doctorService.modeCode == this.props.headerdrprofile.modeCode ? true : false} disabled={this.props.isTimeLoading ? name == 'reschedule' ? doctorService.encounterTypeMode == this.props.headerdrprofile.encounterTypeMode ? false : true : false : false} /><span className="checkmark"></span> {doctorService.encounterTypeMode} <h4 className="float-right text-info">{doctorService.price == "0" ? free == "Yes" ? languagecontent['free'] : "" : currency + getDiscountPrice(doctorService.price, 0)}</h4> <p>{doctorService.price == "0" ? doctorService.duration : doctorService.duration} {languagecontent['mins']}</p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </li>


                                                    </React.Fragment>
                                                )
                                                : ""
                                        }

                                    </ul>

                                </div>
                                <div>
                                    {
                                        testdatepicker == "Yes" ?
                                            <input id="datePicker" onChange={this.openDatePicker} maxLength={15} type="date" />
                                            : ""
                                    }

                                </div>
                            </div>

                            <div className="col-lg-7 col-12">
                                <div className="card booking-schedule schedule-widget">
                                    <div className="schedule-header">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="day-slot">
                                                    <div className="timezone-notification" hidden={this.state.isShowTZNotification}><a className="float-right" onClick={() => this.closeTZ()}><img width="10" src="client/default/img/icon-cross.svg" /></a><div style={{ wordWrap: "break-word" }}>{languagecontent['timezonechange']} {currenttimezone}. {languagecontent['tochangethetime']} <a className="text-info" onClick={() => this.updateTimeZone()}>{languagecontent['clickhere']}</a> </div></div>
                                                    <br />
                                                    {
                                                        testdatepicker != "Yes" ?
                                                        this.props.weekdays != undefined ?
                                                            <ul>
                                                                {
                                                                    this.props.weekdays.map((weekday: TimingStore.WeekDay, index) =>
                                                                        <li id={"dWeekList" + index} className={localStorage.getItem('AppointmentOn') == weekday.date ? 'active' : ''} >
                                                                            <a onClick={() => this.onDate(weekday, index)}>
                                                                                <span>{weekday.day}</span>
                                                                                <span className="slot-date"><small>{weekday.dayno} {weekday.month} </small></span><small className="slot-year"> {weekday.year} </small>
                                                                            </a>
                                                                        </li>,
                                                                    )
                                                                }
                                                            </ul> : '': ''
                                                    
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="schedule-cont">
                                        <div className="row">
                                            {this.props.isLoading ? <ContentLoader
                                                title={langdata['loading'] == undefined ? "" : langdata['loading']}
                                                width={1100}
                                                height={200}
                                                viewBox="0 0 1200 200"
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#ecebeb">
                                                <rect x="27" y="10" rx="4" ry="4" width="20" height="20" />
                                                <rect x="67" y="10" rx="10" ry="10" width="85" height="19" />
                                                <rect x="188" y="10" rx="10" ry="10" width="169" height="19" />
                                                <rect x="402" y="10" rx="10" ry="10" width="85" height="19" />
                                                <rect x="523" y="10" rx="10" ry="10" width="169" height="19" />
                                                <rect x="731" y="10" rx="10" ry="10" width="85" height="19" />
                                                <rect x="852" y="10" rx="10" ry="10" width="85" height="19" />

                                                <rect x="26" y="61" rx="4" ry="4" width="20" height="20" />
                                                <rect x="66" y="61" rx="10" ry="10" width="85" height="19" />
                                                <rect x="187" y="61" rx="10" ry="10" width="169" height="19" />
                                                <rect x="401" y="61" rx="10" ry="10" width="85" height="19" />
                                                <rect x="522" y="61" rx="10" ry="10" width="169" height="19" />
                                                <rect x="730" y="61" rx="10" ry="10" width="85" height="19" />
                                                <rect x="851" y="61" rx="10" ry="10" width="85" height="19" />

                                                <rect x="26" y="123" rx="4" ry="4" width="20" height="20" />
                                                <rect x="66" y="123" rx="10" ry="10" width="85" height="19" />
                                                <rect x="187" y="123" rx="10" ry="10" width="169" height="19" />
                                                <rect x="401" y="123" rx="10" ry="10" width="85" height="19" />
                                                <rect x="522" y="123" rx="10" ry="10" width="169" height="19" />
                                                <rect x="730" y="123" rx="10" ry="10" width="85" height="19" />
                                                <rect x="851" y="123" rx="10" ry="10" width="85" height="19" />

                                                <rect x="26" y="181" rx="4" ry="4" width="20" height="20" />
                                                <rect x="66" y="181" rx="10" ry="10" width="85" height="19" />
                                                <rect x="187" y="181" rx="10" ry="10" width="169" height="19" />
                                                <rect x="401" y="181" rx="10" ry="10" width="85" height="19" />
                                                <rect x="522" y="181" rx="10" ry="10" width="169" height="19" />
                                                <rect x="730" y="181" rx="10" ry="10" width="85" height="19" />
                                                <rect x="851" y="181" rx="10" ry="10" width="85" height="19" />

                                                <rect x="26" y="241" rx="4" ry="4" width="20" height="20" />
                                                <rect x="66" y="241" rx="10" ry="10" width="85" height="19" />
                                                <rect x="187" y="241" rx="10" ry="10" width="169" height="19" />
                                                <rect x="401" y="241" rx="10" ry="10" width="85" height="19" />
                                                <rect x="522" y="241" rx="10" ry="10" width="169" height="19" />
                                                <rect x="730" y="241" rx="10" ry="10" width="85" height="19" />
                                                <rect x="851" y="241" rx="10" ry="10" width="85" height="19" />

                                                <rect x="978" y="10" rx="10" ry="10" width="169" height="19" />
                                                <rect x="977" y="61" rx="10" ry="10" width="169" height="19" />
                                                <rect x="977" y="123" rx="10" ry="10" width="169" height="19" />
                                                <rect x="977" y="181" rx="10" ry="10" width="169" height="19" />
                                                <rect x="977" y="241" rx="10" ry="10" width="169" height="19" />

                                            </ContentLoader> : <div className="col-md-12">

                                                {
                                                    this.props.timing != null ?
                                                        <div className="time-slot">
                                                            <ul className="clearfix">
                                                                {
                                                                    this.props.timing.map((times: TimingStore.Root) =>
                                                                        times == null ? '' :
                                                                            times.map((root: TimingStore.RootEntity) =>
                                                                                <li style={{ paddingBottom: '10px' }} key={root.order} >
                                                                                    <a onClick={() => this.onTime(root, root.order)} id={"timeslot" + root.order} className={localStorage.getItem('AppointmentTime') == root.timeslot ? 'timing selected' : 'timing'} >
                                                                                        {root.isAvailableSlot == true ? root.timeslot : <s>{root.timeslot}</s>}
                                                                                    </a>
                                                                                </li>
                                                                            )
                                                                    )
                                                                }
                                                            </ul>
                                                            {
                                                                this.props.timing != undefined ? this.props.timing.length == 0 ?
                                                                    <div>
                                                                        <div className="p-4">{languagecontent['norecordsfound']}</div>
                                                                    </div> : "" : ''
                                                            }
                                                        </div> : ''
                                                }
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section proceed-btn text-right">
                                    <button className="btn btn-secondary submit-btn" onClick={this.previousredirectpage}>{languagecontent['backbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['backbutton']}</button>
                                    {
                                        name == 'reschedule' ?
                                            <button className="btn btn-primary submit-btn" onClick={this.onrescheduleSubmit} disabled={this.props.isResceduleLoading}> {this.props.isResceduleLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['proceedbutton']}</button>
                                            :
                                            <button className="btn btn-primary submit-btn" onClick={this.onSubmit}>{languagecontent['proceedbutton']}</button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = { ...TimingStore.actionCreators, ...ConditionStore.actionCreators, ...LoginStore.actionCreators, ...NavMenuStore.actionCreators, ...HeaderDrProfileStore.actionCreators, ...PayMethodStore.actionCreators, ...TimeZoneStore.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators, ...RescheduleState.actionCreators };// Selects which state properties are merged into the component's props
const mapStateToProps = (state: ApplicationState) => ({ ...state.booking, ...state.condition, ...state.login, ...state.navDefault, ...state.headerDrProfile, ...state.account, ...state.paymethod, ...state.timeZones, ...state.langResources, ...state.reschedule })
//export default connect(
//    (state: ApplicationState) => ({ ...state.booking, ...state.condition, ...state.login, ...state.navDefault, ...state.headerDrProfile, ...state.account, ...state.paymethod, ...state.timeZones, ...state.langResources, ...state.reschedule }),
//)
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'customForm',
})(Booking as any))

export const getDiscountPrice = (currentprice: any, discountprice: any) => {
    var price: any = (currentprice - discountprice);
    return parseFloat(price).toFixed(2);
}
