import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Field, reduxForm } from 'redux-form';
import { required, setTittle, scrollTop, dateFormat, timeFormatConversion, dateFormatConversion } from '../../helper/formvalidation';
import { renderField } from '../../helper/renderfield';
import { ApplicationState } from '../../store/index';
import * as HandleRequest from '../../helper/Request';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import * as  EditMesssageStore from '../../store/message/editMessage';
import * as NewMessageStore from '../../store/message/addMessage';
import * as TimeZoneStore from '../../store/autoComplete/timeZone';
import * as AccountStore from '../../store/siteaccount/Account';
import * as SiteDetailStore from '../../store/SiteDetail';
import { toastr } from 'react-redux-toastr';
import { floatingJS, mobnav, seo } from '../../helper/Script';
import { TextBoxLoader } from '../../helper/fbloader';
import ContentLoader from 'react-content-loader';

type ReplyMessageProps =
    SiteDetailStore.SiteDetailState & AccountStore.AccountState & TimeZoneStore.TimeZoneState & LanguageResourcesStore.LanguageResouresState & HandleRequest.Request & EditMesssageStore.EditMessageState & NewMessageStore.ReciptentListState & EditMesssageStore.ReplyMessageState &
    typeof LanguageResourcesStore.actionCreators & typeof EditMesssageStore.actionCreators & typeof NewMessageStore.actionCreators & typeof TimeZoneStore.actionCreators & typeof AccountStore.actionCreators & typeof SiteDetailStore.actionCreators&
    RouteComponentProps<{}>;
var languagecontent: any = "";
//var reciptent_name: any = ''
var reciptent_name: any;
var offset: any = "";
//var Currenttimezone: any = "";
var langdata: any = "";
var messages: any = [];

class EditMessage extends React.PureComponent<ReplyMessageProps> {
    constructor(props: any) {
        super(props);
    }
    onSubmit = (formValues: any, dispatch: any, props: any) => {
        if (formValues.message.trimStart() == "") {
            toastr.error('', languagecontent['entermessage']);
        } else {
            this.props.postReplyMessage(formValues)  
        }
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderAddMessage()}
            </React.Fragment>
        );
    }
    public componentDidUpdate() {
        seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
    }

 
    public componentDidMount() {
        scrollTop();
        this.ensureDataFetched();
        floatingJS()
        mobnav()
    }
    private ensureDataFetched() {
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
        if (patient.timeZone != "") {
            this.props.requestTimeZone(patient.timeZone);
        }
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

      // this.props.getSignalRCredential();
        this.props.getLangResources();
        this.props.getReciptentList();
        this.props.requestReplyMessage();
    }


    public renderAddMessage() {
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);
       // var message_List: any = [];
       // var reciptent_name: any;
        languagecontent = this.props.LanguageResoures;       
        messages = this.props.messageLists || [];
        //const messages: any = [];
        //const reciptent_list = this.props.reciptentLists || []
        //const tomember_id: any = this.props.list.ToMemberId != undefined ? this.props.list.ToMemberId :""
        const tomember_id = this.props.list && this.props.list.ToMemberId ? this.props.list.ToMemberId : "";
        const from_member_id = this.props.list && this.props.list.FromMemberId ? this.props.list.FromMemberId : "";


        const reciptent = this.props.reciptentLists.filter((doctor: any) => doctor.value == tomember_id || doctor.value == from_member_id)
        if (reciptent.length > 0) {
            localStorage.setItem("TomemberId", reciptent[0].value);
}
        else {
            var tomemberid = ""
            if (patient.memberId == from_member_id ) 
                tomemberid = tomember_id
            else
                tomemberid = from_member_id
            localStorage.setItem("TomemberId", tomemberid);
            if (tomemberid == null || tomemberid == "")
            localStorage.removeItem("TomemberId")
        }
            
        reciptent_name = reciptent.length > 0 ? reciptent[0].name : ''
     
        offset = patient.offset
        //const time_zones: any = this.props.TimeZones || [];
        //if (localStorage.getItem("isAuth") == "true") {
        //    const patientLocalStroage: any = localStorage.getItem('Patient');
        //    const patient = JSON.parse(patientLocalStroage);         
        //    if (time_zones.length != 0) {
        //        Currenttimezone = this.props.TimeZones.filter((tz: any) => tz.Name == patient.timeZone);
        //        if (patient.timeZone == "India Standard Time")
        //            offset = 330;
        //        else
        //            offset = Currenttimezone[0].Offset;
        //    }
        //    else {
        //        if (this.props.Settings.length != 0) {
        //            var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
        //            var _timezone: any = timezone.displayCode.split("|");
        //            offset = _timezone[1];
        //        }
        //    }
           
        //}
        //else {
        //    if (this.props.Settings.length != 0) {
        //        var timezone = this.props.Settings.filter((se: any) => se.name == "Time Zone")[0]
        //        var _timezone: any = timezone.displayCode.split("|");
        //        offset = _timezone[1];
        //    }
        //}
        
      
        //message_List.map((list: any) => {
        //    var Modified_Date:any = dateFormatConversion(list.ModifiedOn, offset)
        //    var Modified_Time:any = timeFormatConversion(list.ModifiedOn, offset)
        //    messages.push({ name: list.Name, content: list.Content, modified_Date: Modified_Date, modified_Time: Modified_Time, id: list.Id, fromid: list.FromMemberId, tomemberid: list.ToMemberId})
        //})
        

        //const recipientname = (fromid: any, name: any, toid: any) => {
        //    let recipientName = name;
        //    reciptent_list.forEach((list: any) => {
        //        if (list.value === fromid || list.value === toid) {
        //            recipientName = list.name;
        //        }
        //    });
        //    return recipientName;
        //};
        return (
            <React.Fragment>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row" >
                            <DashboardNavMenu />
                            <div className="col-md-7 col-lg-8 col-xl-9">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate autoComplete={"off"}>
                                            <div className="row">
                                                <div className="col-md-12 col-lg-6"> 
                                                    {
                                                        messages.length > 0 || this.props.isListLoading == false?
                                                                <div className='d-flex justify-content-between'>
                                                                {reciptent_name !== "" ?
                                                                        <div>
                                                                            <span className="text-left dont-have text-muted small">{languagecontent['recipient']}</span>
                                                                            <p className="text-muted">{reciptent_name}</p>

                                                                        </div> :
                                                                         <div>
                                                                            <span className="text-left dont-have text-muted small">{languagecontent['subject']}</span>
                                                                        {this.props.list && this.props.list.Subject  ? <p className="text-muted">{this.props.list.Subject}</p> : ''}

                                                                        </div>
                                                                }
                                                                
                                                                {this.props.isListLoading == false && messages.length > 0 ?
                                                                    <div className='d-flex align-items-end'>
                                                                        <p className='text-muted'>{dateFormatConversion(this.props.list.ModifiedOn, offset)} <span className='mx-1'>{timeFormatConversion(this.props.list.ModifiedOn, offset)}</span></p>
                                                                    </div> : ""}
                                                            </div> :  ''
                                                    }
                                                    {languagecontent['subject'] == undefined || this.props.isListLoading == true ? <TextBoxLoader /> :
                                                        <div className={reciptent_name !== "" ? 'd-block' : 'd-none'}>
                                                            <span className="text-left dont-have text-muted small">{languagecontent['subject']}</span>
                                                            {this.props.list && this.props.list.Subject ? <p className="text-muted">{this.props.list.Subject}</p> : ''}

                                                        </div>
                                                    }
                                                   
                                                        <Field name="message" component={renderField} label={languagecontent['message']} maxLength={1500} validate={[required]} type="text" /> 
                                                </div>
                                                
                                                    <div className="col-md-12">
                                                        <div className="submit-section">
                                                            <button type="submit" disabled={this.props.isReplyLoading || this.props.isListLoading} className="btn btn-primary submit-btn"> {this.props.isReplyLoading ? <i className="fa fa-spinner fa-spin"></i> : ""}  {languagecontent['sendmessage'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['sendmessage']}</button>

                                                        </div>
                                                    </div>
                                                {this.props.isListLoading == true && messages.length < 0  ?
                                                    <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
                                                        <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                                                        <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                                                        <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                                                    </ContentLoader> :
                                                    <div className='col mt-3 message-list-container'>
                                                        {messages.map((list: EditMesssageStore.MessageLists) => (
                                                            <div className='border-top'>
                                                                <div className='d-flex justify-content-between my-2 '>
                                                                    <div className="text-left dont-have text-muted small ">{/*recipientname (list.fromid, list.name, list.tomemberid)*/ list.Name}</div>
                                                                    <div className="text-left dont-have text-muted small mr-3">{dateFormatConversion(list.ModifiedOn, offset) /* list.modified_Date */} <span className="mx-1"> {timeFormatConversion(list.ModifiedOn, offset) /*list.modified_Time */ }</span></div>
                                                                </div>
                                                                <div>
                                                                    <p>{list.Content}</p>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapDispatchToProps = { ...LanguageResourcesStore.actionCreators, ...EditMesssageStore.actionCreators, ...NewMessageStore.actionCreators, ...AccountStore.actionCreators, ...SiteDetailStore.actionCreators,  ...TimeZoneStore.actionCreators, };
const mapStateToProps = (state: ApplicationState) => ({ ...state.editMesssage, ...state.replyMessage, ...state.reciptentList, ...state.account, ...state.siteDetail, ...state.langResources, ...state.cancelAppointment, ...state.timeZones})
export default reduxForm({
    form: 'editMessageForm',
})(connect(mapStateToProps, mapDispatchToProps)(EditMessage as any))

