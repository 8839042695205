import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import './FtrDefault.css';
import * as NavDefaultStore from '../../../store/menu/NavDefault';
import { connect } from 'react-redux';
import * as SiteDetailStore from '../../../store/SiteDetail';
import { ApplicationState } from '../../../store/index';
import FtrVirtuvets from '../../../components/layout/footer/client/FtrVirtuvets';
import FtrDefault from '../../../components/layout/footer/client/FtrDefault';
import FtrTheraque from '../../../components/layout/footer/client/FtrTheraque';
import FtrPrime from '../../../components/layout/footer/client/FtrPrime';
import FtrGoodoc from '../../../components/layout/footer/client/FtrGoodoc';
import FtrABC from '../../../components/layout/footer/client/FtrABC';
import FtrWelshdale from '../../../components/layout/footer/client/FtrWelshdale';
import Ftr247DCT from '../../../components/layout/footer/client/Ftr247DCT';
import FtrMetaCare from './client/FtrMetaCare';
import FtrSanthigram from './client/FtrSanthigram';
import FtrVirtualCare from '../../../components/layout/footer/client/FtrVirtualCare';
import FtrSmarthealthtec from './client/FtrSmarthealthtec';
import FtrAmarac from './client/FtrAmarac';

type FooterStoreProps = NavDefaultStore.NavDefaultState & SiteDetailStore.SiteDetailState & typeof NavDefaultStore.actionCreators & typeof SiteDetailStore.actionCreators & RouteComponentProps;
var layout: any = "";
class Footer extends React.PureComponent<FooterStoreProps> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderFooter()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
    }
 
    public renderFooter() {
        if (this.props.siteDetail.Code == "VTS") {
            //loadJS("https://cdn.ywxi.net/js/1.js", true);
            //loadChat();
        }
        layout = this.props.siteDetail.LayoutSettings.filter((ca: any) => ca.Name == "CPFooter")[0];
        if (layout == undefined)
            layout = "";
        if (layout.Value == "FtrDefault") {
            return (
                <React.Fragment>
                    <FtrDefault />
                </React.Fragment>
            );
        } else if (layout.Value == "FtrVirtuvets") {
            return (
                <React.Fragment >
                    <FtrVirtuvets />
                </React.Fragment>
            );
        } else if (layout.Value == "FtrTheraque") {
            return (
                <React.Fragment >
                    <FtrTheraque />
                </React.Fragment>
            );
        } else if (layout.Value == "FtrPrime") {
            return (
                <React.Fragment >
                    <FtrPrime />
                </React.Fragment>
            );
        }
        else if (layout.Value == "FtrGoodoc") {
            return (
                <React.Fragment >
                    <FtrGoodoc />
                </React.Fragment>
            );
        } else if (layout.Value == "FtrABC") {
            return (
                <React.Fragment >
                    <FtrABC />
                </React.Fragment>
            );
        }
        else if (layout.Value == "FtrWelshdale") {
            return (
                <React.Fragment >
                    <FtrWelshdale />
                </React.Fragment>
            );
        } else if (layout.Value == "FtrMetaCare") {
            return (
                <React.Fragment >
                    <FtrMetaCare />
                </React.Fragment>
            );
        }else if (layout.Value == "FtrSanthigram") {
            return (
                <React.Fragment >
                    <FtrSanthigram />
                </React.Fragment>
            );
        }
        else if (layout.Value == "Ftr247DCT") {
            return (
                <React.Fragment >
                    <Ftr247DCT />
                </React.Fragment>
            );
        }
        else if (layout.Value == "FtrVirtualCare") {
            return (
                <React.Fragment >
                    <FtrVirtualCare />
                </React.Fragment>
            );
        }
        else if (layout.Value == "FtrSmarthealthtec") {
            return (
                <React.Fragment >
                    <FtrSmarthealthtec />
                </React.Fragment>
            );
        }
        else if (layout.Value == "FtrAmarac") {
            return (
                <React.Fragment >
                    <FtrAmarac />
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                </React.Fragment>
            );
        }
    }
}
export default connect(
    (state: ApplicationState) => ({ ...state.navDefault, ...state.siteDetail }), // Selects which state properties are merged into the component's props
    ({ ...NavDefaultStore.actionCreators, ...SiteDetailStore.actionCreators }) // Selects which action creators are merged into the component's props
)(Footer as any);

function loadJS(FILE_URL:any, async:any = true) {
    let scriptEle = document.createElement("script");

    scriptEle.setAttribute("src", FILE_URL);
    scriptEle.setAttribute("type", "text/javascript");
    scriptEle.setAttribute("async", async);

    document.body.appendChild(scriptEle);

    // success event 
    scriptEle.addEventListener("load", () => {
        //console.log("File loaded")
    });
    // error event
    scriptEle.addEventListener("error", (ev) => {
        console.log("Error on loading file", ev);
    });
}
