import * as React from 'react';

export default class Pagenotfound extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.renderPageNotFound()}
            </React.Fragment>
        );
    }
//    if(localStorage.getItem ("reschedule" )== "true") {
//    { this.renderPageNotFound() }
//    window.location.href = '/dashboard';

    

//}
    redirectpreviouspage = () => {
        if (localStorage.getItem("reschedule") == "true") {
            console.log(localStorage.getItem("reschedule"));
            window.location.href = '/dashboard';
        }
        else {
            window.history.go(-1);
        }
    }
    private ensureDataFetched() {

    }
    public renderPageNotFound() {
        return (
            <React.Fragment>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="main-wrapper">
                                            <div className="error-box">
                                                <h1>404 Error</h1>                                               
                                                <p className="h4 font-weight-normal">The requested resource is not available, please try again.</p>
                                                <a onClick={() => this.redirectpreviouspage()} className="btn btn-lg btn-primary">Back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           </div>
                        </div>
                    </div>
               
            </React.Fragment>
        );
    }
}
