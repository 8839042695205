import * as React from 'react';
var parentid: any = "";
var name: any = "";
export default class SpeakToVet extends React.PureComponent<{}, { children?: React.ReactNode }> {
    constructor(props: any) {
        super(props);
    }
    public componentDidMount() {
        this.ensureDataFetched();
    }
    public render() {
        return (
            <React.Fragment>
                {this.speakToVet()}
            </React.Fragment>
        );
    }
    private ensureDataFetched() {     
        if (localStorage.getItem('isAuth') == "true") {
            localStorage.removeItem("speaktovet");
            var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
            if (patient.dob == null || patient.gender == "" || patient.cellPhone == "" || patient.address1 == "" || patient.zipcode == "" || patient.locationName == "") {
            } else {
                var patientid = patient.id;
                if (parentid == "") {
                    window.location.href = "/memberspeciality?name=consultnow&patientid=" + patientid;
                } else {
                    window.location.href = "/memberspeciality?name=consultnow&childid=" + parentid + "&childname=" + name + "&patientid=" + patientid;
                }
            }
        } else {
            localStorage.setItem("redirectQequestQueueUrl", "/memberspeciality?name=consultnow&patientid=")
            localStorage.setItem("pathName", "RequestQueue");
            localStorage.setItem("speaktovet", "true");

            window.location.href = '/login'

        }
        }

    public speakToVet() {
        return (
            <React.Fragment>
                <div className="content success-page-cont">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="card success-card">
                                    <div className="card-body">
                                        <div className="main-wrapper">
                                            <div className="error-box">
                                                <h2><i className="fa fa-spinner fa-spin"></i>  Please wait...</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
