import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../../store/index';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as FlexiFormStore from '../../../store/library/flexiform/FlexiForm';
import { renderAutoComplete, renderDatePicker, renderField, renderQuestionField, renderSelectField, renderDob, renderRadioField, renderTextareaField, renderCheckboxField, renderCheckField, renderCheckboxFieldNew, renderFieldHidden, renderDisabledField, renderSelectOptionField, renderTextSelectOptionField, rendercheckField, renderCheckSingle, MultiEntryTextArea, renderFormTextareaField } from '../../../helper/renderfield';
import { phoneNumber, required, zipCode, email, number, dateFormat, dateFormatSetting, datePostFormat, convertYeartoDate, scrollTop, dateFormatConversion, timeFormatConversion, dateTimeFormat, datemonthFormatSetting } from '../../../helper/formvalidation';
import * as HandleRequest from '../../../helper/Request';
import { datepickerJS, floatingJS, floatJS, seo, floatJSBack, ltrim } from '../../../helper/Script';
import { useCallback, useEffect, useMemo, useState, createRef, RefObject } from 'react';
import { toastr } from 'react-redux-toastr';
import ContentLoader, { Facebook } from 'react-content-loader';
import LoadingBar from 'react-redux-loading-bar';
import * as LoginStore from '../../../store/account/Login';
import Moment from 'moment';
import * as Account from '../../../store/siteaccount/Account';
import * as ContactStore from '../../../store/patient/Contact';
import * as LanguageResourcesStore from '../../../store/LanguageResoures';
import PieChart from '../chart/PieChart';
import { setTimeout } from 'timers';



var patientLocalStroage: any = localStorage.getItem('Patient');
var pat = JSON.parse(patientLocalStroage);
let IsRequiredSkip = "";
var formList: any = [];
var activeFields: any = [];
var inactiveFields: any = [];
var checked: any = false;
var checkboxServerData: any = [];
var checkboxArray: any = [];
var _checkboxArray: any = [];
var emptyValue: any = [];
var checkedValues: any = [];
var _checkedValues: any = [];
var checkboxresult: any = [];
var noteText: any = '';
var noteTextArray: any = [];
var editNoteIndex: any = '';
var langdata: any = "";
var jsonInitial: any = {};
var checkCheckbox: any = [];
var languagecontent: any = "";
var offset: any = "";
var textvalue: any = "textboxwithdropdown";
var radiowithtextbox: any = [];


// At runtime, Redux will merge together...
type FlexiListProps =
    Account.AccountState & LoginStore.LoginState & FlexiFormStore.FlexiListsState & HandleRequest.Request & ContactStore.ContactState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
    & typeof LoginStore.actionCreators & typeof FlexiFormStore.actionCreators & typeof ContactStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
    & RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters


class FlexiFormClass extends React.PureComponent<FlexiListProps> {

    constructor(props: any) {
        super(props);
    }
    state = {
       modulename: "",
        status: "",
    }

    handlecheckboxchange = (e: any, id: any) => {
        if (checkCheckbox[id] == "true") {
            checkCheckbox[id] = "false"
            checked = false;
        }
        else {
            checkCheckbox[id] = "true"
            checked = true;
        }
    }



    handlecheckboxchange1 = (e: any, id: any, index: any) => {
        //console.log(id + " - " + index)      
        if (checkCheckbox[id] == "true") {
            checkCheckbox[id] = "false"
            checked = false;
        }
        else {
            checkCheckbox[id] = "true"
            checked = true;
        }
    }



    onSubmit = (formValues: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        var data: any = window.location.pathname;


        const _array = data.split('/');
        if (_array[1] == "forms") {
            var areaId = "4";
            var modulename = decodeURIComponent(_array[2]);
            var patientid = _array[3];
            var recordid = _array[5];
        }
        else if (_array[1] == "initialpageforms") {
            var areaId = "6";
            var modulename = decodeURIComponent(_array[2]);
            var patientid = _array[3];
            var recordid = _array[4];
        }
        else {
            var areaId = "5";
            var modulename = decodeURIComponent(_array[3]);
            var patientid = _array[4];
            var recordid = _array[5];
        }
        
        function processObjectValues(data: { [key: string]: string[] }) {
            for (let key in data) {
                let array = data[key];
                for (let i = 0; i < array.length; i++) {
                    let splitArray = array[i].split("~");
                    let uniqueArray = Array.from(new Set(splitArray));
                    array[i] = uniqueArray.join("~");
                }
            }
            return data;
        }
       
       
            var data: any = groupBy(checkedValues, "Id");
            var datas: any = processObjectValues(data);

            const objectArray = Object.entries(datas);
            objectArray.forEach(([key, value]: any) => {
                formValues[key] = value.join('~');
            });            
        // changes for radiowithtextbox
        var obj: { [key: string]: string | null } = {};
        if (modulename == ("HIPAA Privacy Notice") || (modulename == "Consent Form")) {
        } else { 
        var formList = this.props.formLists;

        formList.forEach((list) => {
            var formListValue = Object.keys(formValues).filter((mergelist) => {
                return list.customFormFieldsId === mergelist;
            });

            if (formListValue.length === 1) {
                obj[list.customFormFieldsId] = formValues[list.customFormFieldsId];
            } else {
                obj[list.customFormFieldsId] = "";
            }
            if (radiowithtextbox.includes(list.customFormFieldsId)) {
                /*radiowithtextbox.pop(el.name)*/
                obj[list.customFormFieldsId] = "";
            }
        });
    }
        // end
        let DisplayCurrentTime: any = (datetime: Date) => {
            var date = datetime;
            var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
            var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return (hours + ":" + minutes + ":" + seconds);
        };
        let toDate: any = (date: any) => {
            if (date != null) {
                var currentDateTime = new Date(date);
                return currentDateTime
            }
        };
        this.props.formLists.filter(function (value) {
            if (value.controls === "Checkbox" && value.customFormName.toLowerCase() != ("Medical_Assessment_Form").toLowerCase() && value.isAllowMultiple != true) {
                Object.keys(formValues).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {
                        formValues[fieldIndex] = value.option1
                    }
                })
            }
            if (value.controls === "Date") {
                Object.keys(formValues).forEach(function (fieldIndex) {
                    if (value.customFormFieldsId === fieldIndex) {
                        var dateformat: any = localStorage.getItem("DateFormat")
                        var __dateformat: any = dateFormatSetting(formValues[fieldIndex]);
                        if (__dateformat == "Invalid date") {

                        } else {
                            //if (dateformat == "DD/MM/YYYY") {
                            //    var parts: any = __dateformat.split('/');
                            //    var __dateformat: any = parts[1] + '/' + parts[0] + '/' + parts[2];
                            //    formValues[fieldIndex] = __dateformat;
                            //}
                            //else
                            formValues[fieldIndex] = convertYeartoDate(formValues[fieldIndex]);
                        }
                        if (formValues[fieldIndex] == null) {
                            formValues[fieldIndex] = "";
                        }
                    }
                })
            }
        });

        
        this.props.formLists.filter(function (value) {
            //if (value.controls === "TextboxwithDropdown") {
            //    Object.keys(formValues).forEach((fieldIndex) => {
            //        var result = fieldIndex.split("|");
            //        if (result.length > 1) {
            //            formValues[result[0]] = value.option1 + "~" + formValues[fieldIndex]
            //            delete formValues[fieldIndex];
            //        }
            //    })
            //}
            if (value.controls === "TextboxwithDropdown") {
                Object.keys(formValues).forEach((fieldIndex) => {
                    var result = fieldIndex.split("|");
                   // console.log(result)
                  //  console.log(fieldIndex)
                    if (result.length > 1) {
                        var Value: any = document.getElementsByName(result[0] + '|' + result[1]);
                        var textbox: any = Value[0].value;
                        var Value1: any = document.getElementsByName(result[0]);
                        var dropdown: any = Value1[0].value;
                        if (textbox != null) {
                            formValues[result[0]] = textbox + "|" + dropdown;
                            delete formValues[fieldIndex];
                        }
                    }
                })
            }      
            //if (value.controls === "TextboxwithDropdown" && value.customFormName == "Common_condition_questionnaire") {
            //    Object.keys(formValues).forEach((fieldIndex) => {
            //        var result = fieldIndex.split("|");
            //        if (result.length > 1) {
            //            var textbox: any = document.getElementsByName(result[0] + '|textboxwithdropdown')[0].value;
            //            var dropdown: any = document.getElementsByName(result[0])[0].value;
            //            if (textbox != null) {
            //                formValues[result[0]] = dropdown + "~" + textbox
            //                delete formValues[fieldIndex];
            //            }
            //        }
            //    })
            //}
        });

        this.props.formLists.filter(function (value) {
            if (value.controls === "MultiEntryTextArea") {
                let notes = [...noteTextArray]
                if (editNoteIndex === '') {
                    if (noteText !== '') {
                        notes.push({ Note: noteText })
                    }
                } else {
                    notes[editNoteIndex].Note = noteText
                }
                formValues[value.customFormFieldsId] = noteText !== '' ? JSON.stringify(notes) : ''
            }
        });



       

        var pos = this.props.moduleLists.map(function (e) {
            return e.moduleName;

        }).indexOf(modulename);

        
            var formlist: any = JSON.parse(localStorage.getItem('customId') || "")
            var poss = formlist.map(function (e: any) {
                return e.moduleName;

            }).indexOf(modulename);

        var nextModule: any;
        var nextId: any; 
        if (this.props.moduleLists[pos] == undefined && formlist[poss] != undefined) {

             nextModule = formlist[poss + 1].moduleName;
             nextId = formlist[poss + 1].id;

        } else if (pos !== (this.props.moduleLists.length - 1)) {

          nextModule = this.props.moduleLists[pos + 1].moduleName;
          nextId = this.props.moduleLists[pos + 1].id;

         }
            else {
             nextModule = "";
             nextId = "";
        }
        // changes for radiowithtextbox
        var obj: { [key: string]: string | null } = {};
        if (modulename == ("HIPAA Privacy Notice") || (modulename == "Consent Form")) {
        } else {
            var formList = this.props.formLists;

            formList.forEach((list) => {
                var formListValue = Object.keys(formValues).filter((mergelist) => {
                    return list.customFormFieldsId === mergelist;
                });

                if (formListValue.length === 1) {
                    obj[list.customFormFieldsId] = formValues[list.customFormFieldsId];
                } else {
                    obj[list.customFormFieldsId] = "";
                }
                if (radiowithtextbox.includes(list.customFormFieldsId)) {
                    /*radiowithtextbox.pop(el.name)*/
                    obj[list.customFormFieldsId] = "";
                }
            });
        }
        // end
       // console.log(this.props.formLists)
       // var recordid: any = this.props.patient.id;
        var formid: any
        if (modulename == ("HIPAA Privacy Notice") || (modulename == "Consent Form")) {
            formid = formValues;
        } else {
            formid = obj;
        }
        var formList = this.props.formLists;
        let formListValue: any = [];
            if (!(modulename == ("HIPAA Privacy Notice") || (modulename == "Consent Form") || (modulename == "PHQ-9 Questionnaire") || (modulename == "DAST - 10"))) {

               

                for (const mergelist of Object.keys(formValues)) {
                    const list = formList.filter((value: any) => {
                        return value.customFormFieldsId === mergelist;
                    });

                    const mergelistvalue = formValues[mergelist];

                    if (list.length > 0 && list[0].isRequired && mergelistvalue === "") {
                        // Handle the error here, e.g., using toastr
                        toastr.error('', langdata['requirederrormessage']);

                        // Exit the function if the condition is met
                        return; // Exit the function or handle the error appropriately
                    }

                    // Add to formListValue if needed
                    // formListValue.push(someValue);
                }
            }
        var recordTypeId = 1;
        checkedValues = [];
        _checkedValues = [];
        var patient: any = JSON.parse(localStorage.getItem("Patient") || "");
        var localpatientId: any = patient.id;
        if (_array[1] == "initialpageforms" && localpatientId != patientid) {
            window.location.reload();
        } else {
            if (this.props.Settings) {
                this.props.Settings.forEach((key: any, index: any) => {
                    if (key['name'] == "CP-Custom Form Flow") {
                        if (this.props.formLists[pos] == undefined && modulename == "Prakriti self assessment") {
                            window.history.go(+1);
                        }
                        else if (this.props.formLists[pos] == undefined) {
                            this.props.requestSaveQuestions(formid, this.props.formLists[0], this.props.patient, this.props.patient.id, recordTypeId, modulename, formlist[poss].moduleId, key['customizeValue'], nextModule, areaId, nextId, this.props.formLists, recordid);

                        }
                        else {
                            this.props.requestSaveQuestions(formid, this.props.formLists[pos], this.props.patient, this.props.patient.id, recordTypeId, this.props.moduleLists[pos].moduleName, this.props.formLists[pos].moduleId, key['customizeValue'], nextModule, areaId, nextId, this.props.formLists, recordid);
                        }
                    }
                })

            }
        }
      
       // console.log(formValues);
        noteText = '';
        editNoteIndex = '';
        noteTextArray = [];

    }

    downloadForm = () => {
        //this.props.formLists.forEach((value: any) => {
        //    console.log(value);
        //})
        //window.jsPDF = window.jspdf.jsPDF;

        var doc: any = new jsPDF();
        var elementHTML = document.querySelector("#content");
        doc.html(elementHTML, {
            callback: function (doc:any) {
                doc.save('Wellness Plan.pdf');
            },
            x: 15,
            y: 15,
            width: 170, 
            windowWidth: 650 
        });
    }
    public handleClick() {
        const queryParams = new URLSearchParams(window.location.search);
        //const modulename = queryParams.get('modulename');
        floatJS();
       datepickerJS();
       floatingJS();
        floatJSBack();
        var data = window.location.pathname;
        const _array = data.split('/');
        if (_array[1] == "forms") {
            var areaId = "4";
            var modulename = decodeURIComponent(_array[2]);
            var patientid = _array[3];
            var recordid = _array[4];
        }
        else if (_array[1] == "initialpageforms") {
            var areaId = "6";
            var modulename = decodeURIComponent(_array[2]);
            var patientid = _array[3];
            var recordid = _array[4];
        }
        else {
            var areaId = "5";
            var modulename = decodeURIComponent(_array[3]);
            var patientid = _array[4];
            var recordid = _array[5];
        }
        var pos = this.props.moduleLists.map(function (e) {
            return e.moduleName;
        }).indexOf(modulename);
        if (pos !== (this.props.moduleLists.length - 1)) {
            var nextModule = this.props.moduleLists[pos + 1].moduleName;
            var nextId = this.props.moduleLists[pos + 1].id;

        }
        else {
            var nextModule = "";
            var nextId = ""
        }
        if (this.props.Settings) {
            this.props.Settings.forEach((key: any, index: any) => {
                if (key['name'] == "CP-Custom Form Flow") {
                    //floatJS();
                    datepickerJS();
                    floatingJS();
                    floatJSBack();
                    this.props.requestSkipQuestions(nextModule, key['customizeValue'], nextId);
                }
            })
        }

    }
    componentDidMount() {
        checkboxServerData = [];
        checkboxArray = [];
        _checkboxArray = [];
        editNoteIndex = ''
        var data_current = window.location.pathname;
        const _array_current = data_current.split('/');
        this.Pageonload();
        if (_array_current[1] != "forms") {
            if (_array_current[1] != "initialpageforms") {
                var Email = _array_current[6];
                if (Email != pat.Email) {
                    window.location.href = "/dashboard"
                }
                else {

                }
                window.addEventListener('popstate', (event) => {
                    //floatJS();
                    datepickerJS();
                    floatingJS();
                    floatJSBack();
                    this.ensureDataFetched();
                    this.props.getPtaient();
                    floatJS();
                });
            } else {
                var patientid = _array_current[3];
                var recordid = _array_current[4];
                if (patientid != pat.id) {
                    window.location.href = "/dashboard"
                }
                else {

                }
            }
        }
        else {
            var patientid = _array_current[3];
            var recordid = _array_current[4];
            if (patientid != pat.id) {
                window.location.href = "/dashboard"
            }
            else {

            }
        }

        this.ensureDataFetched();
        floatingJS();
       const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
       script.async = true;
       document.body.appendChild(script);
       floatJSBack();
        datepickerJS();
        floatingJS();
        floatJS();
        scrollTop();
        //this.forceUpdate();
    }


    componentDidUpdate() {
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        floatJS();
        //datepickerJS(); 
            var data_current = window.location.pathname;
            const _array_current = data_current.split('/');


            if (_array_current[1] == "forms") {

                const urlParams = new URLSearchParams(window.location.search);
                const formClientTitle = urlParams.get('formclientTitle');
                if (this.props.formLists.length != 0) {
                    seo({
                        title: formClientTitle,
                        pageTitle: formClientTitle,
                        description: ''
                    });
                } else {
                    seo({
                        title: '',
                        pageTitle: '',
                        description: ''
                    });
                }
            }   
             else if (_array_current[1] == "initialpageforms") {
                if (this.props.formLists.length != 0) {
                    seo({
                        title: this.props.formLists[0].formClientTitle,
                        pageTitle: this.props.formLists[0].formClientTitle,
                        description: ''
                    });
                } else {
                    seo({
                        title: '',
                        pageTitle: '',
                        description: ''
                    });
                }
            }
            else {
                if (this.props.formLists.length != 0) {
                    seo({
                        title: this.props.formLists[0].formClientTitle,
                        pageTitle: this.props.formLists[0].formClientTitle,
                        description: ''
                    });
                } else {
                    seo({
                        title: '',
                        pageTitle: '',
                        description: ''
                    });
                }
            }
      

    }

    previouspage = () => {
        window.history.go(-1);
    }


    public render() {
        return (
            <React.Fragment>
                {this.renderFlexiFormList()}
            </React.Fragment>
        );
    }

    private ensureDataFetched() {
        this.props.getLangResources();
        const script = document.createElement("script");
        script.src = "/Theme/Doccure/admin/assets/js/script.js";
        script.async = true;
        document.body.appendChild(script);
        var data = window.location.pathname;

        const _array = data.split('/');
        if (_array[1] == "dashboard") {
            return;
        }
        if (_array[1] == "forms") {
            var areaId = "4";
            var modulename = _array[2];
            var patientid = _array[3];
            var recordid = _array[4];
            var id = _array[5];
        } else if (_array[1] == "initialpageforms") {
            var areaId = "6";
            var modulename = _array[2];
            var patientid = _array[3];
            var recordid = _array[4];
            var id = _array[5];
        }
        else {
            var areaId = "5";
            var modulename = _array[3];
            var patientid = _array[4];
            var recordid = _array[5];
            var id = _array[7];
        }
        this.props.requestModuleForm(areaId, pat['id']);
        //floatJSBack();
        floatingJS();
        this.props.getPtaient();
        this.props.requestFlexiForm(pat['id'], modulename, "", id, pat['id'], localStorage.getItem("EncounterTypeId"), areaId);
        if (localStorage.getItem("LangResourcesValidations") != null)
            langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

    }
    handlechange1 = (e: any, id: any, checkboxvalue: any) => {      
        if (e.target.checked == undefined)
            return;
        if (e.target.checked == true) {
            var data1: any = [];
            var data2: any = [];            
            var checkvalue: any = [];          
            data1 = checkedValues.filter((value: any) => (value.Id == id));
            data1.forEach((e2: any) => {
                 checkvalue = e.target.value + "~" + e2.Value;
                data2.push({ Id: id, Value: checkvalue.replace(/^~|~$/g, '') });
            })
            _checkedValues = checkedValues.filter((value: any) => (value.Id != id));
            _checkedValues.push(data2[0]);
            checkedValues = _checkedValues;                      
        } else {
            var data1: any = [];
            var data2: any = [];            
            var checkvalue: any = [];           
            data1 = checkedValues.filter((value: any) => (value.Id == id));            
            //data1.forEach((e2: any) => {
            //   var checkvalue = e2.Value.replace(e.target.value, "")               
            //   data2.push({ Id: id, Value: checkvalue.replace(/^~|~$/g, '') });
            //})
            data1.forEach((e2: any) => {
                var regex = new RegExp(e.target.value, 'g');
                var checkvalue = e2.Value.replace(regex, "");
                checkvalue = checkvalue.replace(/^~|~$/g, '');
                data2.push({ Id: id, Value: checkvalue });
            });
            _checkedValues = checkedValues.filter((value: any) => (value.Id != id));            
            _checkedValues.push(data2[0]);
            checkedValues = _checkedValues;           
        }
    }
    selectValue = (e: any, id: any) => {
        var data: any = document.getElementById(id);
        if (e.target.value == "Number of days") {
            if (data != null) {
                data.style.display = "block";
            }
        } else {
            data.style.display = "none";
        }
    }
    addValue = (e: any, value: any) => {
        var data: any = document.getElementById(value);
        if (data != null) {
            data.value = "Number of days" + "~" + e.target.value;
        }
    }

    downloadFile = (value: any) => {
        this.props.downloadFile(value);
    }

    valueCheck = (value: any, currentid: any) => {

        jsonInitial = JSON.parse(JSON.stringify(Object.assign({}, this.props.dynamicForm)));
        let checks: any = this.props.formLists.filter((val) => val.customFormFieldsId == value && val.controls != "Textarea")
        let checks1: any = this.props.formLists.filter((val: any) => val.customFormFieldsId == currentid && val.controls == "Textarea")
        if (checks1.length > 0) {
            var check: any = Object.keys(jsonInitial).filter((fieldIndex) => value == fieldIndex && !(checks1[0].rule).split("~").includes(jsonInitial[fieldIndex]))

        }
        else {
            var check: any = Object.keys(jsonInitial).filter((fieldIndex) => value == fieldIndex && jsonInitial[fieldIndex] == "")

        }



        if (checks.length > 0) {

            return check[0] == checks[0].customFormFieldsId;
        }

        return false;

    }


    handleChange(e: any) {

        const name: any = e.target.name;
        var element: any = document.getElementsByClassName(name);
        var divelement: any = document.getElementsByClassName(name + "-hide");
        var status: any = false;
        this.setState({ status: true })
        Array.from(element).forEach((el: any) => {
            // Do stuff here
            if (el.tagName != "DIV") {
                var rule: any = el.getAttribute('data-rule')
                //el.style.display = "block";
                var ruleSplit: any = rule.split("~");
                if (ruleSplit.includes(e.target.value)) {
                    el.style.display = "block";
                    if (radiowithtextbox.includes(el.name)) {
                        radiowithtextbox.pop(el.name)
                    }
                    //divelement.style.display = "block"
                    status = true;
                    Array.from(divelement).forEach((e2: any) => {
                        e2.style.display = "block";
                    })
                }
                else if (!ruleSplit.includes(e.target.value)) {
                    el.style.display = "none";
                    el.value = "";
                    //divelement.style.display = "none"
                    status = false;
                    if (!radiowithtextbox.includes(el.name)) {
                        radiowithtextbox.push(el.name)
                    }
                    Array.from(divelement).forEach((e2: any) => {
                        e2.style.display = "none";
                    })
                }
            }           
        });
    }

 

    Pageonload = () => {
        let parrent: any = this.props.formLists;
        let childElements = this.props.formLists.filter(function (value) {
            if (value.parentId != null && value.parentId != "") {

                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.parentId == fieldIndex && jsonInitial[fieldIndex] != "") {
                        activeFields.push(value.parentId)
                    }
                    else {
                        inactiveFields.push(value.parentId)
                    }
                    //console.log(fieldIndex, "fieldIndex", jsonInitial[fieldIndex])

                })
                //return value.parentId;
            }
        })
        let active: Set<string> = new Set(activeFields);
        let inactive: Set<string> = new Set(inactiveFields);


        inactive.forEach((value: any) => {

            var elementtype: any = document.querySelector('input[name="' + value + '"]');

            var element: any = document.getElementsByClassName(value);
            var divelement: any = document.getElementsByClassName(value + "-hide");


            Array.from(divelement).forEach((e2: any) => {

                e2.style.display = "none";
            })
            Array.from(element).forEach((el: any) => {

                el.style.display = "none";
                Array.from(divelement).forEach((e2: any) => {
                    e2.style.display = "none";
                })
            })
        })
        active.forEach((value: any) => {
            var element: any = document.getElementsByClassName(value);
            //console.log(this.valueCheck(value), "active")
            var divelement: any = document.getElementsByClassName(value + "-hide");
            Array.from(divelement).forEach((e2: any) => {
                e2.style.display = "none";
            })
            Array.from(element).forEach((el: any) => {
                let rule: any = el.getAttribute('data-rule')
                let ruleSplit: any = rule.split("~");

                if (ruleSplit.includes(jsonInitial[value])) {
                    el.style.display = "block";
                    Array.from(divelement).forEach((e2: any) => {
                        e2.style.display = "block";
                    })
                }
                else {
                    el.style.display = "none";
                    Array.from(divelement).forEach((e2: any) => {
                        e2.style.display = "none";
                    })

                }
                //el.style.display = "block";
            })

        })
        this.props.formLists.filter(function (value) {

            if (value.controls === "Date") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {


                    }
                })

            }



            if (value.controls === "Checkbox") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {
                        var element1: any = document.getElementsByClassName('eb8221f1-da35-4ec7-8b7d-95988f923cf3[0]');
                        element1.checked = true;


                    }
                })

            }

            if (value.controls === "Checkboxlist") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {
                        var element1: any = document.getElementsByClassName('eb8221f1-da35-4ec7-8b7d-95988f923cf3[0]');
                        element1.checked = true;


                    }
                })

            }

        })

    }
    private renderFlexiFormList() {
        languagecontent = this.props.LanguageResoures;
        const vv1 = this.props.formLists[0];
        const patientLocalStroage: any = localStorage.getItem('Patient');
        const patient = JSON.parse(patientLocalStroage);



        this.setState({
            isShowTextBox: true,
        })


        var data = window.location.pathname;
        const _array = data.split('/');




        jsonInitial = JSON.parse(JSON.stringify(Object.assign({}, this.props.dynamicForm)));
        this.props.formLists.filter(function (value) {
            if (value.controls === "Checkbox") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {
                        if (checkCheckbox[value.customFormFieldsId] == undefined) {
                            if (jsonInitial[fieldIndex] == value.option1) {
                                checkCheckbox[value.customFormFieldsId] = 'true';
                            }
                            else {
                                checkCheckbox[value.customFormFieldsId] = 'false';
                            }
                        }

                    }
                })

            }
            if (value.controls === "Checkboxlist") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {
                        if (checkCheckbox[value.customFormFieldsId] == undefined) {
                            if (jsonInitial[fieldIndex] == value.option1) {
                                checkCheckbox[value.customFormFieldsId] = 'true';
                            }
                            else {
                                checkCheckbox[value.customFormFieldsId] = 'false';
                            }
                        }

                    }
                })

            }
            /*if (value.controls === "Date")*/ 
            //    {Object.keys(jsonInitial).forEach((fieldIndex) => {
            //        if (value.customFormFieldsId === fieldIndex) {



            //            if (jsonInitial[fieldIndex] == "") {
            //                jsonInitial[fieldIndex] = "";
            //            }
            //            else {
            //                var __dateformat: any = dateFormatSetting(jsonInitial[fieldIndex]);
            //                if (__dateformat == "Invalid date") {
            //                    jsonInitial[fieldIndex] = ""
            //                } else {
            //                    setTimeout(function () {
            //                        var els: any = document.getElementsByName(fieldIndex);
            //                        for (var i = 0; i < els.length; i++) {
            //                            var format: any = localStorage.getItem("DateFormat") || "";
            //                            els[i].value = Moment(jsonInitial[fieldIndex]).format(format);
            //                        }
            //                    }, 1000);
            //                }

            //            }
            //        }
            //    })

            //}
            if (value.controls === "Date") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    if (value.customFormFieldsId === fieldIndex) {



                        if (jsonInitial[fieldIndex] == "") {
                            jsonInitial[fieldIndex] = "";
                        }
                        else {
                            var __dateformat: any = datemonthFormatSetting(jsonInitial[fieldIndex]);
                            if (__dateformat == "Invalid date") {
                                jsonInitial[fieldIndex] = ""
                            } else {
                                setTimeout(function () {
                                    var els: any = document.getElementsByName(fieldIndex);
                                    for (var i = 0; i < els.length; i++) {
                                        //var inputDate: any = __dateformat;
                                        //var parts: any = inputDate.split('/');
                                        //var outputDate: any = parts[1] + '/' + parts[0] + '/' + parts[2];
                                        // var format: any = localStorage.getItem("DateFormat") || "";
                                        els[i].value = __dateformat; /* Moment(jsonInitial[fieldIndex]).format(format);*/
                                    }
                                }, 1000);
                            }

                        }
                    }
                })

            }
        })


        this.props.formLists.filter(function (value) {
            if (value.controls === "TextboxwithDropdown") {
                Object.keys(jsonInitial).forEach((fieldIndex) => {
                    var result: any = jsonInitial[fieldIndex].split("|");
                    if (result.length > 1) {
                        delete jsonInitial[fieldIndex];
                        jsonInitial[fieldIndex] = result[1];
                        jsonInitial[fieldIndex + "|" + result[0]] = result[0];
                        textvalue = result[0];
                        var data: any = document.getElementById(fieldIndex);
                        if (data != null) {
                            data.style.display = "block";
                        }
                    }
                })

            }
        })
        if (_array[1] == "forms") {
            var areaId = "4";
        }
        else if (_array[1] == "initialpageforms") {
            var areaId = "6";
        }
        else {
            var areaId = "5";
        }

        this.props.CustomFormSetting.filter(function (value) {
            return value.name === "Required"
        });

        this.props.CustomFormSetting.forEach(value => {
            IsRequiredSkip = value.value
        })

        formList = this.props.formLists.filter(function (value: any) {
            return value.controls === "DownloadLink"
        });


        var pieChart = this.props.formLists.filter((e) => e.controls == "PieChart")

        var gender: any = pat["gender"].charAt(0);
        if (_checkedValues.length == 0) {
            checkedValues = [];
            this.props.formLists.forEach((documentlist: any) => {
                if (documentlist.controlId == 4) {
                    checkedValues.push({ Id: documentlist.customFormFieldsId, Value: documentlist.value });
                }
                if (documentlist.controlId == 21) {
                    checkedValues.push({ Id: documentlist.customFormFieldsId, Value: documentlist.value });
                }
            })
        }
        var prakriti = [];
        prakriti = this.props.formLists.filter((value: any) => (value.moduleName == "Prakriti Analysis" && value.id != null));
        const multipleNotes: any = this.props.formLists.filter((value: any) => (value.controls.toLowerCase() == "multientrytextarea"));



        var hidesaveBtn: any = [];
        hidesaveBtn = this.props.formLists.filter(function (value: any) {
            return ((value.id == null) && (value.moduleName == "Prakriti self assessment"));
        });

        // console.log(hidesaveBtn)

        if (multipleNotes.length > 0) {

            if (multipleNotes[0].value !== '') {
                noteTextArray = JSON.parse(multipleNotes[0].value)
            }

            jsonInitial[multipleNotes[0].customFormFieldsId] = ''

        }

        //console.log(noteTextArray)



        const editnote = (id: any, value: any, index: any) => {

            editNoteIndex = index
            var field: any = document.getElementById(id);
            if (field && field.name == id) {

                field.value = value;
                field.classList.add("focused");
                field.addEventListener("focusin", () => {
                    field.value = value;
                });
            }
        }

        var url: any = window.location.pathname;
        const urldata = url.split('/');
        offset = patient.offset

       // console.log(jsonInitial);
        return (

            this.props.isdocformLoading == false ?
                <div>
                    <React.Fragment >

                        {
                            areaId == "4" && this.props.ResultHtml.length > 0 ? <div dangerouslySetInnerHTML={{ __html: this.props.ResultHtml }} /> : ""
                        }


                        <form onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate className="flexiform" autoComplete={"off"}>
                            <div className="card">
                                <div className="card-body">
                                    {
                                        vv1 ? <React.Fragment >
                                            <h4 className="widget-title pat-widget-title mb-2">{vv1.formClientTitle}</h4>

                                            <p>{vv1.subTitle.toLowerCase()}</p> </React.Fragment > : ""
                                    }

                                    {
                                        this.props.formLists.map((documentlist: FlexiFormStore.FormList) =>

                                            documentlist.controls.toLowerCase() == "header3" ?
                                                documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                    < h3 key={documentlist.customFormFieldsId} className="widget-title pat-widget-title mb-2" > {documentlist.clientTitle}</h3> :
                                                    "" :
                                                    < h3 key={documentlist.customFormFieldsId} className="widget-title pat-widget-title mb-2" >{documentlist.clientTitle}</h3> :
                                                    < h3 key={documentlist.customFormFieldsId} className="widget-title pat-widget-title mb-2" >{documentlist.clientTitle}</h3>
                                                : documentlist.controls.toLowerCase() == "header5" ?
                                                    documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                        <h5 key={documentlist.customFormFieldsId} className="widget-title pat-widget-title mb-2">{documentlist.clientTitle}</h5> :
                                                        "" :
                                                        <h5 key={documentlist.customFormFieldsId} className="widget-title pat-widget-title mb-2">{documentlist.clientTitle}</h5> :
                                                        <h5 key={documentlist.customFormFieldsId} className="widget-title pat-widget-title mb-2">{documentlist.clientTitle}</h5>

                                                    : documentlist.controls.toLowerCase() == "text" ?
                                                        documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                            <Field key={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? required : ""} type="text" autofocus="autoFocus"/> :
                                                            "" :
                                                            <Field key={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? required : ""} type="text" autofocus="autoFocus"/> :
                                                            <Field key={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? required : ""} type="text" autofocus="autoFocus"/>

                                                        : documentlist.controls.toLowerCase() == "readonlytext" ?
                                                            documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                <Field key={documentlist.customFormFieldsId} component={renderDisabledField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} validate={""} type="text" autofocus="autoFocus"/> :
                                                                "" :
                                                                <Field key={documentlist.customFormFieldsId} component={renderDisabledField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} validate={""} type="text" autofocus="autoFocus" /> :
                                                                <Field key={documentlist.customFormFieldsId} component={renderDisabledField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} validate={""} type="text" autofocus="autoFocus"/>
                                                            : documentlist.controls.toLowerCase() == "textarea" && documentlist.customFormName.toLowerCase() != ("Recommendation_(Wellness_care_plan)").toLowerCase()?

                                                                documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                    <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} component={renderTextareaField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} validate={documentlist.isRequired == true ? [required] : ""} type="text" autofocus="autoFocus" id={documentlist.parentId != null ? documentlist.customFormFieldsId + '/' + documentlist.parentId : ""} dataRule={documentlist.rule} addClass={documentlist.parentId != null ? this.valueCheck(documentlist.parentId, documentlist.customFormFieldsId) ? documentlist.parentId + "-hide hide" : documentlist.parentId + "-hide" : ""} dataClass={documentlist.parentId != null ? documentlist.parentId : ""} /> :
                                                                    "" :
                                                                    <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength} component={renderTextareaField} label={documentlist.clientTitle} placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""} validate={documentlist.isRequired == true ? [required] : ""} type="text" autofocus="autoFocus" id={documentlist.parentId != null ? documentlist.customFormFieldsId + '/' + documentlist.parentId : ""} dataRule={documentlist.rule} addClass={documentlist.parentId != null ? this.valueCheck(documentlist.parentId, documentlist.customFormFieldsId) ? documentlist.parentId + "-hide hide" : documentlist.parentId + "-hide" : ""} dataClass={documentlist.parentId != null ? documentlist.parentId : ""} /> :
                                                                    <Field
                                                                        key={documentlist.customFormFieldsId}
                                                                        name={documentlist.customFormFieldsId}
                                                                        maxLength={documentlist.maxLength}
                                                                        component={renderFormTextareaField}
                                                                        label={documentlist.clientTitle}
                                                                        placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""}
                                                                        validate={documentlist.isRequired == true ? [required] : ""}
                                                                        type="text"                                                                       
                                                                        id={documentlist.parentId != null ? documentlist.customFormFieldsId + '/' + documentlist.parentId : ""}
                                                                        dataRule={documentlist.rule}
                                                                        
                                                                        addClass={documentlist.parentId != null ? this.valueCheck(documentlist.parentId, documentlist.customFormFieldsId) ? documentlist.parentId + "-hide hide" : documentlist.parentId + "-hide" : ""}
                                                                        dataClass={documentlist.parentId != null ? documentlist.parentId : ""} />
                                                                : documentlist.controls.toLowerCase() == "multientrytextarea" && documentlist.value !== "" ? <div>


                                                                    <Field
                                                                        onChange={(e: any) => {
                                                                            noteText = e.target.value
                                                                        }}
                                                                        key={documentlist.customFormFieldsId}
                                                                        name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength}
                                                                        component={MultiEntryTextArea} label={documentlist.clientTitle}
                                                                        placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""}
                                                                        //validate={documentlist.isRequired == true ? [required] : ""}
                                                                        validate={""}
                                                                        type="text"
                                                                        autofocus="autoFocus"
                                                                        id={documentlist.customFormFieldsId}
                                                                        dataRule={documentlist.rule}
                                                                        //disabled={true}
                                                                        addClass={documentlist.parentId != null ? this.valueCheck(documentlist.parentId, documentlist.customFormFieldsId) ? documentlist.parentId + "-hide hide" : documentlist.parentId + "-hide" : ""}
                                                                        dataClass={documentlist.parentId != null ? documentlist.parentId : ""}

                                                                    />
                                                                    {noteTextArray.length > 0 ? noteTextArray.map((list: any, index: any) => (
                                                                        <div style={{ margin: '10px 10px' }}>
                                                                            <p>{list.Note}<span><button hidden={true}
                                                                                onClick={() => editnote(documentlist.customFormFieldsId, list.Note, index)} type="button" className="multiEntry-textarea-btn float-right mr20">  <i className="multiEntry-textarea-btn-icon fas fa-pencil-alt"></i> </button></span> </p>
                                                                            <p className='text-muted'><span>{`Updated by ${list.Updated}`}</span><span className='ml-2'>{`on ${dateTimeFormat(list.UpdatedOn)}`}</span></p>
                                                                        </div>
                                                                    )) : ""}


                                                                </div>
                                                                    : documentlist.controls.toLowerCase() == "multientrytextarea" && documentlist.value === "" ?
                                                                        <Field
                                                                            onChange={(e: any) => {
                                                                                noteText = e.target.value
                                                                            }}
                                                                            key={documentlist.customFormFieldsId}
                                                                            name={documentlist.customFormFieldsId} maxLength={documentlist.maxLength}
                                                                            component={MultiEntryTextArea} label={documentlist.clientTitle}
                                                                            placeholder={documentlist.clientTitle == "" ? documentlist.option1 : ""}
                                                                            //validate={documentlist.isRequired == true ? [required] : ""}
                                                                            validate={""}
                                                                            type="text"
                                                                            autofocus="autoFocus"
                                                                            id={documentlist.customFormFieldsId}
                                                                            dataRule={documentlist.rule}
                                                                            addClass={documentlist.parentId != null ? this.valueCheck(documentlist.parentId, documentlist.customFormFieldsId) ? documentlist.parentId + "-hide hide" : documentlist.parentId + "-hide" : ""}
                                                                            dataClass={documentlist.parentId != null ? documentlist.parentId : ""}

                                                                        />

                                                                        :
                                                                        documentlist.controls.toLowerCase() == "checkbox" && documentlist.customFormName.toLowerCase() != ("Medical_Assessment_Form").toLowerCase()?
                                                                            documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                <div className="form-group form-focus  co-md-6" key={documentlist.customFormFieldsId + "parrentdiv"}>
                                                                                    <label className="col-lg-12 col-form-label">{documentlist.clientTitle}</label>
                                                                                    <div className="col-lg-12" key={documentlist.customFormFieldsId + "innerparrentdiv"}>
                                                                                        {(documentlist.option1).split("~").map((options, i) =>
                                                                                            <Field key={documentlist.customFormFieldsId + i}
                                                                                                name={documentlist.customFormFieldsId}
                                                                                                id={documentlist.customFormFieldsId + i}
                                                                                                component={renderCheckField}
                                                                                                type="checkbox"
                                                                                                value={ltrim(options)} optionName={ltrim(options)}
                                                                                                checkedTrue={checkCheckbox[documentlist.customFormFieldsId]}
                                                                                                onChange={(e: any) => this.handlecheckboxchange(e, documentlist.customFormFieldsId)}
                                                                                                checked={ltrim(options) == ltrim(documentlist.value) && documentlist.name != "consentagree" ? true : false}
                                                                                                validate={documentlist.isRequired == true ? [required] : ""} />
                                                                                        )}
                                                                                    </div>
                                                                                </div> :
                                                                                "" :
                                                                                <div className="form-group form-focus  co-md-6" key={documentlist.customFormFieldsId + "parrentdiv"}>
                                                                                    <label className="col-lg-12 col-form-label">{documentlist.clientTitle}</label>
                                                                                    <div className="col-lg-12" key={documentlist.customFormFieldsId + "innerparrentdiv"}>
                                                                                        {(documentlist.option1).split("~").map((options, i) =>
                                                                                            <Field key={documentlist.customFormFieldsId + i}
                                                                                                name={documentlist.customFormFieldsId}
                                                                                                id={documentlist.customFormFieldsId + i}
                                                                                                component={renderCheckField}
                                                                                                type="checkbox"
                                                                                                value={ltrim(options)} optionName={ltrim(options)}
                                                                                                checkedTrue={checkCheckbox[documentlist.customFormFieldsId]}
                                                                                                onChange={(e: any) => this.handlecheckboxchange(e, documentlist.customFormFieldsId)}
                                                                                                checked={ltrim(options) == ltrim(documentlist.value) && documentlist.name != "consentagree" ? true : false}
                                                                                                validate={documentlist.isRequired == true ? [required] : ""} />
                                                                                        )}
                                                                                    </div>
                                                                                </div> :
                                                                                <div className="form-group form-focus  co-md-6" key={documentlist.customFormFieldsId + "parrentdiv"}>
                                                                                    <label className="col-lg-12 col-form-label">{documentlist.clientTitle}</label>
                                                                                    <div className="col-lg-12" style={{ marginTop: "-40px" }} key={documentlist.customFormFieldsId + "innerparrentdiv"}>
                                                                                        {(documentlist.option1).split("~").map((options, i) =>
                                                                                            <Field key={documentlist.customFormFieldsId + i}
                                                                                                name={documentlist.customFormFieldsId}
                                                                                                id={documentlist.customFormFieldsId + i}
                                                                                                component={renderCheckField}
                                                                                                type="checkbox"
                                                                                                value={ltrim(options)} optionName={ltrim(options)}
                                                                                                checkedTrue={checkCheckbox[documentlist.customFormFieldsId]}
                                                                                                onChange={(e: any) => this.handlecheckboxchange(e, documentlist.customFormFieldsId)}
                                                                                                checked={ltrim(options) == ltrim(documentlist.value) && documentlist.name != "consentagree" ? true : false}
                                                                                                validate={documentlist.isRequired == true ? [required] : ""} />
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            : documentlist.controls.toLowerCase() == "checkbox" && documentlist.customFormName.toLowerCase() == ("Medical_Assessment_Form").toLowerCase() && documentlist.isAllowMultiple != true ?
                                                                                documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                    <div id="1" onClick={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}>
                                                                                    <Field key={documentlist.customFormFieldsId} component={renderCheckSingle}
                                                                                        name={documentlist.customFormFieldsId}
                                                                                        label={documentlist.clientTitle}
                                                                                        required={true}
                                                                                        classes="CheckSingle"
                                                                                        onChange={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}
                                                                                        /*onClick={(e: any) => this.handleChange(e, documentlist.customFormFieldsId)}*/
                                                                                        options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                        validate={documentlist.isRequired == true ? [required] : ""} /></div> :
                                                                                    "" :
                                                                                    <div id="1" onClick={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}>
                                                                                    <Field key={documentlist.customFormFieldsId} component={renderCheckSingle}
                                                                                        name={documentlist.customFormFieldsId}
                                                                                        label={documentlist.clientTitle}
                                                                                        required={true}
                                                                                        classes="CheckSingle"
                                                                                        onChange={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}
                                                                                        /*onClick={(e: any) => this.handleChange(e, documentlist.customFormFieldsId)}*/
                                                                                        options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                        validate={documentlist.isRequired == true ? [required] : ""} /></div> :
                                                                                    <div id="1" onClick={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}>
                                                                                    <Field key={documentlist.customFormFieldsId} component={renderCheckSingle}
                                                                                        name={documentlist.customFormFieldsId}
                                                                                        label={documentlist.clientTitle}
                                                                                        required={true}
                                                                                        classes="CheckSingle"
                                                                                        onChange={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}
                                                                                        /*onClick={(e: any) => this.handleChange(e, documentlist.customFormFieldsId)}*/
                                                                                        options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                        validate={documentlist.isRequired == true ? [required] : ""} /></div>
                                                                                :
                                                                                documentlist.controls.toLowerCase() == "checkboxlist" && documentlist.isAllowMultiple != true ?
                                                                                    documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                        <div id="1" onClick={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}>
                                                                                        <Field key={documentlist.customFormFieldsId} component={renderCheckSingle}
                                                                                            name={documentlist.customFormFieldsId}
                                                                                            label={documentlist.clientTitle}
                                                                                            required={true}
                                                                                            classes="CheckSingle"
                                                                                            onChange={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}
                                                                                            /*onClick={(e: any) => this.handleChange(e, documentlist.customFormFieldsId)}*/
                                                                                            options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                             /></div> :
                                                                                        "" :
                                                                                        <div id="1" onClick={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}>
                                                                                        <Field key={documentlist.customFormFieldsId} component={renderCheckSingle}
                                                                                            name={documentlist.customFormFieldsId}
                                                                                            label={documentlist.clientTitle}
                                                                                            required={true}
                                                                                            classes="CheckSingle"
                                                                                            onChange={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}
                                                                                            /*onClick={(e: any) => this.handleChange(e, documentlist.customFormFieldsId)}*/
                                                                                            options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                             /></div> :
                                                                                        <div id="1" onClick={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}>
                                                                                            <Field key={documentlist.customFormFieldsId} component={renderCheckSingle}
                                                                                            name={documentlist.customFormFieldsId}
                                                                                            label={documentlist.clientTitle}
                                                                                            required={true}
                                                                                            classes="CheckSingle"
                                                                                            onChange={(e: any) => this.handlechange1(e, documentlist.customFormFieldsId, documentlist)}
                                                                                            /*onClick={(e: any) => this.handleChange(e, documentlist.customFormFieldsId)}*/
                                                                                            options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                             /></div>
                                                                                    :
                                                                                    documentlist.controls.toLowerCase() == "radio" && documentlist.customFormName.toLowerCase() != ("Recommendation_(Wellness_care_plan)").toLowerCase() || documentlist.controls.toLowerCase() == "radiobuttonlist" && documentlist.customFormName.toLowerCase() != ("Recommendation_(Wellness_care_plan)").toLowerCase() ?
                                                                                        documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                            <Field key={documentlist.customFormFieldsId} component={renderRadioField}
                                                                                                name={documentlist.customFormFieldsId}
                                                                                                label={documentlist.clientTitle}
                                                                                                required={true}
                                                                                                onChange={(e: any) => this.handleChange(e)}
                                                                                                /*onClick={(e: any) => this.handleChange(e)}*/
                                                                                                options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                                validate={documentlist.isRequired == true ? [required] : ""}
                                                                                                fielddisabled={documentlist.moduleName == "Prakriti self assessment" ? documentlist.id == null ? false : true : false}
                                                                                            /> :
                                                                                            "" :
                                                                                            <Field key={documentlist.customFormFieldsId} component={renderRadioField}
                                                                                                name={documentlist.customFormFieldsId}
                                                                                                label={documentlist.clientTitle}
                                                                                                required={true}
                                                                                                onChange={(e: any) => this.handleChange(e)}

                                                                                                options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}
                                                                                                validate={documentlist.isRequired == true ? [required] : ""}
                                                                                                fielddisabled={documentlist.moduleName == "Prakriti self assessment" ? documentlist.id == null ? false : true : false} /> :
                                                                                            <Field key={documentlist.customFormFieldsId} component={renderRadioField}
                                                                                                name={documentlist.customFormFieldsId}
                                                                                                label={documentlist.clientTitle}
                                                                                                required={true}
                                                                                                onChange={(e: any) => this.handleChange(e)}
                                                                                                options={(documentlist.option1).split("~").map((val) => { return { key: ltrim(val) + documentlist.customFormFieldsId, title: ltrim(val), value: ltrim(val) } })}

                                                                                                validate={documentlist.isRequired == true ? [required] : ""}
                                                                                                fielddisabled={documentlist.moduleName == "Prakriti self assessment" ? documentlist.id == null ? false : true : false} />


                                                                                        :
                                                                                        (documentlist.controls.toLowerCase() == "radio" && documentlist.customFormName.toLowerCase() == ("Recommendation_(Wellness_care_plan)").toLowerCase()) || (documentlist.controls.toLowerCase() == "radiobuttonlist" && documentlist.customFormName.toLowerCase() == ("Recommendation_(Wellness_care_plan)").toLowerCase()) ?
                                                                                            documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                                "":
                                                                                                "" :
                                                                                                "" :
                                                                                                <h5><strong>{documentlist.value}</strong><hr /></h5>
                                                                                            :
                                                                                        documentlist.controls.toLowerCase() == "date" ?

                                                                                            documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                                <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderDatePicker} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [required] : ""} type="text" /> :
                                                                                                ""
                                                                                                : <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderDatePicker} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [required] : ""} type="text" /> :
                                                                                                <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderDatePicker} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [required] : ""} type="text" />


                                                                                            : documentlist.controls.toLowerCase() == "email" ?
                                                                                                documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                                    <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [{ required, email }] : [email]} type="text" />
                                                                                                    : ""
                                                                                                    : <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [{ required, email }] : [email]} type="text" />
                                                                                                    : <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [{ required, email }] : [email]} type="text" />


                                                                                                : documentlist.controls.toLowerCase() == "number" ?
                                                                                                    documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?

                                                                                                        <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [{ required, email }] : [number]} type="text" />
                                                                                                        : ""
                                                                                                        : <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [{ required, email }] : [number]} type="text" />
                                                                                                        : <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} component={renderField} label={documentlist.clientTitle} maxLength={documentlist.maxLength} validate={documentlist.isRequired == true ? [{ required, email }] : [number]} type="text" />
                                                                                                        : documentlist.controls.toLowerCase() == "textboxwithdropdown" ?
                                                                                                            <div className="form-group mb-2">
                                                                                                                <label></label>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-12" >
                                                                                                                        <Field key={documentlist.customFormFieldsId} id={documentlist.customFormFieldsId} component={renderQuestionField} label={documentlist.clientTitle} name={documentlist.customFormFieldsId + "|" + textvalue} maxLength={100} validate={documentlist.isRequired == true ? [required] : ""} onChange={(e: any) => this.addValue(e, documentlist.customFormFieldsId)} type="text" />
                                                                                                                   </div>
                                                                                                                    <div className="col-12">
                                                                                                                        <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} options={(documentlist.option1).split("~").map((val) => { return { key: val, value: val, name: val } })} component={renderTextSelectOptionField} validate={documentlist.isRequired == true ? [required] : ""} className="form-control" />                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        : documentlist.controls.toLowerCase() == "dropdown" ?
                                                                                                        documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?

                                                                                                            <div className="form-group mb-2">
                                                                                                                <label>{documentlist.clientTitle}</label>
                                                                                                                <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} onChange={(e: any) => this.handleChange(e)} options={(documentlist.option1).split("~").map((val) => { return { key: val, value: val, name: val } })} component={renderSelectOptionField} validate={documentlist.isRequired == true ? [required] : ""} className="form-control" />
                                                                                                            </div> : "" :
                                                                                                            <div className="form-group mb-2">
                                                                                                                <label>{documentlist.clientTitle}</label>
                                                                                                                <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} onChange={(e: any) => this.handleChange(e)} options={(documentlist.option1).split("~").map((val) => { return { key: val, value: val, name: val } })} component={renderSelectOptionField} validate={documentlist.isRequired == true ? [required] : ""} className="form-control" />
                                                                                                            </div> :
                                                                                                            <div className="form-group mb-2">
                                                                                                                <label>{documentlist.clientTitle}</label>
                                                                                                                <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} onChange={(e: any) => this.handleChange(e)} options={(documentlist.option1).split("~").map((val) => { return { key: val, value: val, name: val } })} component={renderSelectOptionField} validate={documentlist.isRequired == true ? [required] : ""} className="form-control" />
                                                                                                            </div>
                                                                                                                : documentlist.controls.toLowerCase() == "textboxwithdropdown" ?
                                                                                                                    documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                                                        <div className="form-group mb-2">
                                                                                                                            <label>{documentlist.clientTitle}</label>
                                                                                                                            <div className="row">
                                                                                                                                <div className="col-sm-6">
                                                                                                                                    <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} options={(documentlist.option1 + "~" + documentlist.option2).split("~").map((val) => { return { key: val, value: val, name: val } })} onChange={(e: any) => this.selectValue(e, documentlist.customFormFieldsId)} component={renderTextSelectOptionField} validate={[required]} className="form-control" />
                                                                                                                                </div>
                                                                                                                                <div className="col-sm-6" style={{ display: "none" }} id={documentlist.customFormFieldsId}>
                                                                                                                                    <Field key={documentlist.customFormFieldsId} component={renderField} label={documentlist.option1} name={documentlist.customFormFieldsId + "|" + documentlist.option1} maxLength={100} onChange={(e: any) => this.addValue(e, documentlist.option1)} type="text" />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    : ""


                                                                                                                : <div className="form-group mb-2">
                                                                                                                    <label>{documentlist.clientTitle}</label>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} options={(documentlist.option1 + "~" + documentlist.option2).split("~").map((val) => { return { key: val, value: val, name: val } })} onChange={(e: any) => this.selectValue(e, documentlist.customFormFieldsId)} component={renderTextSelectOptionField} validate={[required]} className="form-control" />
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-6" style={{ display: "none" }} id={documentlist.customFormFieldsId}>
                                                                                                                            <Field key={documentlist.customFormFieldsId} component={renderField} label={documentlist.option1} name={documentlist.customFormFieldsId + "|" + documentlist.option1} maxLength={100} onChange={(e: any) => this.addValue(e, documentlist.option1)} type="text" autofocus="autoFocus"/>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> :
                                                                                                                <div className="form-group mb-2">
                                                                                                                    <label>{documentlist.clientTitle}</label>
                                                                                                                    <div className="row">
                                                                                                                        <div className="col-sm-6">
                                                                                                                            <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} options={(documentlist.option1 + "~" + documentlist.option2).split("~").map((val) => { return { key: val, value: val, name: val } })} onChange={(e: any) => this.selectValue(e, documentlist.customFormFieldsId)} component={renderTextSelectOptionField} validate={[required]} className="form-control" />
                                                                                                                        </div>
                                                                                                                        <div className="col-sm-6" style={{ display: "none" }} id={documentlist.customFormFieldsId}>
                                                                                                                            <Field key={documentlist.customFormFieldsId} component={renderField} label={documentlist.option1} name={documentlist.customFormFieldsId + "|" + documentlist.option1} maxLength={100} onChange={(e: any) => this.addValue(e, documentlist.option1)} type="text" autofocus="autoFocus"/>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            : documentlist.controls.toLowerCase() == "template" ?
                                                                                                                documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?


                                                                                                                    <React.Fragment>
                                                                                                                        <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} bindvalue={documentlist.value != "" ? documentlist.value : documentlist.defaultValue} component={renderFieldHidden} />
                                                                                                                        <div dangerouslySetInnerHTML={{ __html: documentlist.value != "" ? documentlist.value : documentlist.defaultValue }} />
                                                                                                                    </React.Fragment> : ""
                                                                                                                    : <React.Fragment>
                                                                                                                        <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} bindvalue={documentlist.value != "" ? documentlist.value : documentlist.defaultValue} component={renderFieldHidden} />
                                                                                                                        <div dangerouslySetInnerHTML={{ __html: documentlist.value != "" ? documentlist.value : documentlist.defaultValue }} />
                                                                                                                    </React.Fragment>
                                                                                                                    : <React.Fragment>
                                                                                                                        <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} bindvalue={documentlist.value != "" ? documentlist.value : documentlist.defaultValue} component={renderFieldHidden} />
                                                                                                                        <div dangerouslySetInnerHTML={{ __html: documentlist.value != "" ? documentlist.value : documentlist.defaultValue }} />
                                                                                                                    </React.Fragment>

                                                                                                                :
                                                                                                                        documentlist.customFormName.toLowerCase() == ("Recommendation_(Wellness_care_plan)").toLowerCase() ?
                                                                                                                    documentlist.controls.toLowerCase() == "checkboxwithheader" ?
                                                                                                                        <React.Fragment>
                                                                                                                            <h5 className="pt-2 pb-2">{documentlist.clientTitle}</h5>
                                                                                                                            <div dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }} />
                                                                                                                        </React.Fragment> :
                                                                                                                   documentlist.controls.toLowerCase() == "dropdown" ?
                                                                                                                        <React.Fragment>
                                                                                                                            <div className="form-group mb-2">
                                                                                                                                <label>{documentlist.clientTitle}</label>
                                                                                                                                <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} onChange={(e: any) => this.handleChange(e)} options={(documentlist.option1).split("~").map((val) => { return { key: val, value: val, name: val } })} component={renderSelectOptionField} validate={documentlist.isRequired == true ? [required] : ""} className="form-control" />
                                                                                                                            </div>
                                                                                                                        </React.Fragment> :
                                                                                                                  documentlist.controls.toLowerCase() == "textarea" ? 
                                                                                                                        <React.Fragment>
                                                                                                                            <Field key={documentlist.customFormFieldsId} name={documentlist.customFormFieldsId} bindvalue={documentlist.value != "" ? documentlist.value : documentlist.defaultValue} component={renderFieldHidden} />
                                                                                                                            Note: <span dangerouslySetInnerHTML={{ __html: documentlist.value != "" ? documentlist.value : documentlist.defaultValue }} />
                                                                                                                          <hr />
                                                                                                                        </React.Fragment> : ""
                                                                                                                    :
                                                                                                                    documentlist.customFormName == "" ?
                                                                                                                       <p>No records found</p>
                                                                                                                     :
                                                                                                                     documentlist.controls.toLowerCase() == "piechart" ?
                                                                                                                    <div className='my-2 d-flex justify-content-center d-lg-block'>
                                                                                                                        <PieChart result={pieChart} />
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                    documentlist.controls.toLowerCase() == "downloadlink" ?
                                                                                                                        documentlist.rule != "" && documentlist.rule != null ? (documentlist.rule == "M" || documentlist.rule == "F") ? documentlist.rule == gender ?
                                                                                                                            <React.Fragment>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-sm-12 text-center">
                                                                                                                                        <button type="button" className="btn btn-lg btn-primary" onClick={() => this.downloadFile(documentlist)}>Download</button>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <br />
                                                                                                                            </React.Fragment> : ""
                                                                                                                            : <React.Fragment>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-sm-12 text-center">
                                                                                                                                        <button type="button" className="btn btn-lg btn-primary" onClick={() => this.downloadFile(documentlist)}>Download</button>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <br />
                                                                                                                            </React.Fragment>
                                                                                                                            : <React.Fragment>
                                                                                                                                <div className="row">
                                                                                                                                    <div className="col-sm-12 text-center">
                                                                                                                                        <button type="button" className="btn btn-lg btn-primary" onClick={() => this.downloadFile(documentlist)}>Download</button>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <br />
                                                                                                                            </React.Fragment> : ""
                                        )
                                    }
                                </div>
                            </div>
                            {
                                urldata[2] == "Recommendation%20(Wellness%20care%20plan)" ?
                                    <React.Fragment>
                                        {urldata[5] == "null" ? "" : <button type="button" className="btn btn-lg btn-primary float-right" onClick={() => this.downloadForm()}>Download</button>}
                                    </React.Fragment> :
                                    <React.Fragment>
                                        {
                                            urldata[1] == "forms" ?
                                                <div className="row cust-btn">
                                                    {
                                                        formList.length == 1 ? "" :
                                                            <div className="col-sm-12">
                                                                {this.props.formLists.length > 0 ?
                                                                    <button hidden={((urldata[2] == 'Prakriti%20self%20assessment' || urldata[2] == 'Prakriti self assessment') && (hidesaveBtn.length === 0))} ref="btn" type="submit" className="btn btn-lg btn-primary float-right" disabled={this.props.isdocformLoading || this.props.isskipLoading || prakriti.length != 0}> {this.props.isdocformLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {areaId == "5" ? languagecontent['proceedbutton'] : "Save"}</button> : ""}
                                                                {areaId == "5" && IsRequiredSkip == "No" ? <button type="button" className="btn btn-lg btn-secondary float-right mr20" onClick={() => this.handleClick()} disabled={this.props.isdocformLoading || this.props.isskipLoading}>{this.props.isskipLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['skip']}</button> : ""}

                                                            </div>
                                                    }
                                                </div> :
                                                <div className="row cust-btn">
                                                    {
                                                        formList.length == 1 ? "" :
                                                            <div className="col-sm-12">
                                                                {this.props.formLists.length > 0 ?
                                                                    <button ref="btn" type="submit" className="btn btn-lg btn-primary float-right" disabled={this.props.isdocformLoading || this.props.isskipLoading}> {this.props.isdocformLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {areaId == "5" ? languagecontent['proceedbutton'] : "Save"}</button> : ""}
                                                                {this.props.formLists.length > 0 && areaId == "5" ?
                                                                    <button type="button" className="btn btn-lg btn-secondary float-right mr20" onClick={this.previouspage}>{languagecontent['backbutton'] == undefined ? <img src="../../../buttonloader.svg" /> : languagecontent['backbutton']}</button>
                                                                    : ""}
                                                                {areaId == "5" && IsRequiredSkip == "No" ? <button type="button" className="btn btn-lg btn-secondary float-right mr20" onClick={() => this.handleClick()} disabled={this.props.isdocformLoading || this.props.isskipLoading}>{this.props.isskipLoading ? <i className="fa fa-spinner fa-spin"></i> : ""} {languagecontent['skip']}</button> : ""}

                                                            </div>
                                                    }
                                                </div>
                                        }
                                    </React.Fragment>
                            }
                        </form>

                    </React.Fragment>
                    <React.Fragment>
                        <br /> <br />
                        <div style={{ display:"none" }}>
                            <div className="temp-10" id="content">
                                <img
                                    className="beige-natural-botanical-skinca4"
                                    alt=""
                                    src="template/public/headerimage.png"
                                />
                                <div className="frame-parent10">
                                    <div className="temp-14">
                                        <div className="personalized-wellness-plan-parent">
                                            <div className="personalized-wellness-plan-container">
                                                <span className="personalized-wellness-plan"
                                                >Personalized wellness plan</span
                                                >
                                            </div>
                                            <b className="for-digestive-issue">For digestive issue – constipation</b>
                                        </div>
                                        <img className="group-icon" alt="" src="template/public/group.png" />

                                        <div className="frame-parent">
                                            <div className="frame-wrapper">
                                                <img className="frame-child" alt="" src="template/public/frame-1279.png" />
                                            </div>
                                            <div className="logo-wrapper">
                                                <img className="logo-icon" alt="" src="template/public/logo.png" />
                                            </div>
                                        </div>
                                        <img className="group-icon1" alt="" src="template/public/group1.png" />

                                        <img className="group-icon2" alt="" src="template/public/group2.png" />

                                        <img className="temp-14-child" alt="" src="template/public/frame-1000003866.png" />
                                    </div>
                                    {
                                        this.props.formLists.map((documentlist: FlexiFormStore.FormList) =>

                                            documentlist.clientTitle == "Aahar" ?
                                                <React.Fragment>
                                                    <div className="frame-parent11">
                                                        <div className="aahar-parent">
                                                            <div className="aahar">AAHAR</div>
                                                            <b className="lifestyle-recommendations"
                                                            >Herbal and supplement recommendation</b
                                                            >
                                                        </div>
                                                        <div className="clip-path-group-wrapper4">
                                                            <div className="clip-path-group8">
                                                                <img className="group-icon9" alt="" src="template/public/group7.png" />

                                                                <div className="aahar-refers-to-the-food-and-d-parent">
                                                                    <div className="aahar-refers-to">
                                                                        Aahar refers to the food and drink we consume, and Ayurveda
                                                                        recommends consuming  fresh, whole, and natural foods that
                                                                        suit our Dosha. It stresses mindful eating and proper
                                                                        preparation, which involves enhancing food's nutritional value
                                                                        and digestibility.
                                                                    </div>
                                                                    <img className="frame-child3" alt="" src="template/public/group-87.png" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="recommendation-parent4" style={{height:"800px"}}>
                                                        <b className="recommendation6">Recommendation</b>
                                                        <div className="increase-fiber-intake-container">
                                                            <div className="increase-fiber-intake-include" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                        </div>
                                                    </div>
                                                </React.Fragment> : documentlist.clientTitle == "Achar" ?
                                                    <React.Fragment>
                                                        <div className="frame-parent13">
                                                            <div className="aahar-parent">
                                                                <div className="aahar">Aachar</div>
                                                                <b className="lifestyle-recommendations">Lifestyle Recommendations</b>
                                                            </div>
                                                            <div className="clip-path-group-wrapper5">
                                                                <div className="clip-path-group9">
                                                                    <img className="group-icon10" alt="" src="template/public/group8.png" />

                                                                    <div className="aachar-refers-to-our-daily-rou-parent">
                                                                        <div className="aachar-refers-to">
                                                                            Aachar refers to our daily routines and Ayurveda recommends
                                                                            following healthy habits that align with the diurnal and
                                                                            seasonal rhythms of nature. This includes waking up and going
                                                                            to bed at consistent times, maintaining a regular meal
                                                                            schedule, practicing good hygiene, and engaging in regular
                                                                            physical exercise to support the body's natural rhythms and
                                                                            promote balance of mind, body, and spirit.
                                                                        </div>
                                                                        <img className="frame-child4" alt="" src="template/public/group-94.png" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="recommendation-parent4">
                                                            <b className="recommendation6">Recommendation</b>
                                                            <div className="increase-fiber-intake-container">
                                                                
                                                                <div className="increase-fiber-intake-include" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                    : documentlist.clientTitle == "Vihar" ?
                                                        <React.Fragment>
                                                            <div className="frame-parent9">
                                                                <div className="vichar-parent">
                                                                    <div className="vichar">Vihar</div>
                                                                    <b className="behavioral-recommendations">Behavioral Recommendations</b>
                                                                </div>
                                                                <div className="clip-path-group-wrapper3">
                                                                    <div className="clip-path-group6">
                                                                        <img className="group-icon8" alt="" src="template/public/group8.png" />

                                                                        <div className="vihar-involves-our-social-and-parent">
                                                                            <div className="vihar-involves-our">
                                                                                Vihar involves our social and environmental interactions,
                                                                                including creative and leisure activities that promote
                                                                                relaxation, positive social connections, and well-being.
                                                                                Ayurveda emphasizes the importance of maintaining healthy
                                                                                social interactions, engaging in enjoyable activities, and
                                                                                fostering a connection with our environment. By nurturing
                                                                                positive relationships and engaging in meaningful activities,
                                                                                we can promote our overall well-being.
                                                                            </div>
                                                                            <img
                                                                                className="clip-path-group7"
                                                                                alt=""
                                                                                src="template/public/clip-path-group1@2x.png"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="recommendation-parent2">
                                                                <b className="recommendation4">Recommendation</b>
                                                                <div className="practice-mindful-eating-container">
                                                                    <div className="practice-mindful-eating-payin" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    : documentlist.clientTitle == "Vichar" ?
                                                        <React.Fragment>
                                                            <div className="frame-parent7">
                                                                <div className="vichar-parent">
                                                                    <div className="vichar">Vichar</div>
                                                                    <b className="mindset-recommendations">Mindset Recommendations</b>
                                                                </div>
                                                                <div className="clip-path-group-wrapper2">
                                                                    <div className="clip-path-group5">
                                                                        <img className="group-icon7" alt="" src="template/public/group7.png" />

                                                                        <div className="group-parent1">
                                                                            <img className="frame-child2" alt="" src="template/public/group-93.png" />

                                                                            <div className="vichar-refers-to">
                                                                                Vichar refers to our thought patterns and mental attitudes.
                                                                                Ayurveda  encourages  cultivating a positive mindset and
                                                                                healthy emotional states, such as love, compassion, gratitude,
                                                                                and contentment that can help  in better social relationships
                                                                                and interactions and  maintain balance and harmony in our mind
                                                                                and body.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="recommendation-parent2">
                                                                <b className="recommendation4">Recommendation</b>
                                                                <div className="practice-mindful-eating-container">
                                                                        <div className="practice-mindful-eating-payin" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment> : documentlist.clientTitle == "Kriya" ?
                                                            <React.Fragment>
                                                                <div className="frame-parent5">
                                                                    <div className="oushadhi-parent">
                                                                        <div className="oushadhi">Kriya</div>
                                                                        <b className="herbal-and-supplement"
                                                                        >Purification and Detoxification Practices</b
                                                                        >
                                                                    </div>
                                                                    <div className="clip-path-group-wrapper1">
                                                                        <div className="clip-path-group4">
                                                                            <img className="group-icon6" alt="" src="template/public/group6.png" />

                                                                            <div className="kriya-practices-in-ayurveda-ai-parent">
                                                                                <div className="kriya-practices-in-container">
                                                                                    <p className="kriya-practices-in">
                                                                                        Kriya practices in Ayurveda aim to support the body's
                                                                                        natural functions of breathing, digestion, and elimination.
                                                                                    </p>
                                                                                    <p className="kriya-practices-in">
                                                                                        Incorporating Kriya practices such as cleansing rituals and
                                                                                        breathing exercises can promote the healthy functioning of
                                                                                        channels responsible for elimination. These practices
                                                                                        support physical, mental, and emotional balance, making them
                                                                                        an essential aspect of Ayurvedic self-care.
                                                                                    </p>
                                                                                </div>
                                                                                <img className="frame-icon" alt="" src="template/public/frame-92.png" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="recommendation-container">
                                                                    <b className="recommendation2">Recommendation</b>
                                                                    <div className="take-1-2-teaspoons-container">
                                                                        <ul className="take-1-2-teaspoons-of-triphala">
                                                                            Practice yoga asanas that   stimulate  digestive system and aiding
                                                                            in bowel movements -  Paschimottanasana  and Pavanamuktasana
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment> : documentlist.clientTitle == "Karma" ?
                                                                <React.Fragment>
                                                                    <div className="frame-container">
                                                                        <div className="karma-parent">
                                                                            <div className="karma">Karma</div>
                                                                            <b className="behaviour-modification">Behaviour Modification</b>
                                                                        </div>
                                                                        <div className="clip-path-group-wrapper">
                                                                            <div className="clip-path-group1">
                                                                                <img className="group-icon3" alt="" src="template/public/group3.png" />

                                                                                <div className="group-parent">
                                                                                    <img className="frame-item" alt="" src="template/public/group-95.png" />

                                                                                    <div className="karma-in-ayurveda">
                                                                                        Karma in Ayurveda relates to the concept of cause and effect,
                                                                                        where our actions have a direct impact on our health and
                                                                                        wellbeing. It emphasizes the importance of conscious choices
                                                                                        and intentional actions that align with our values and
                                                                                        purpose.
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="recommendation-parent">
                                                                        <b className="recommendation">Recommendation</b>
                                                                        <div className="keep-a-food-container">
                                                                                <div className="keep-a-food-and-bowel-movement" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment> : documentlist.clientTitle == "Aushadi" ?
                                                                    <React.Fragment>
                                                                        <div className="frame-parent3">
                                                                            <div className="oushadhi-parent">
                                                                                <div className="oushadhi">Oushadhi</div>
                                                                                <b className="herbal-and-supplement">Herbal and Supplement Recommendations</b>
                                                                            </div>
                                                                            <div className="clip-path-group-frame">
                                                                                <div className="clip-path-group3">
                                                                                    <img className="group-icon5" alt="" src="template/public/group5.png" />

                                                                                    <div className="group-container">
                                                                                        <img className="frame-child1" alt="" src="template/public/group-92.png" />

                                                                                        <div className="aushadhi-in-ayurveda">
                                                                                            Aushadhi in Ayurveda  refers to the use of herbal remedies and
                                                                                            other natural medicines  to prevent and treat illness. These
                                                                                            remedies are tailored to an individual's constitution and are
                                                                                            believed to bring balance and harmony to the body and mind. By
                                                                                            using natural medicines, Ayurveda emphasizes the importance of
                                                                                            addressing the root cause of an illness rather than just
                                                                                            treating its symptoms.
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="recommendation-container">
                                                                            <b className="recommendation2">Recommendation</b>
                                                                            <div className="take-1-2-teaspoons-container">
                                                                                    <div className="take-1-2-teaspoons-of-triphala" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment> : documentlist.clientTitle == "Vyayam" ?
                                                                        <React.Fragment>
                                                                            <div className="frame-parent1">
                                                                                <div className="karma-parent">
                                                                                    <div className="karma">Vyayama</div>
                                                                                    <b className="behaviour-modification"
                                                                                    >Physical Exercise Recommendations</b
                                                                                    >
                                                                                </div>
                                                                                <div className="clip-path-group-container">
                                                                                    <div className="clip-path-group2">
                                                                                        <img className="group-icon4" alt="" src="template/public/group4.png" />

                                                                                        <div className="vyayam-the-ayurvedic-concept-parent">
                                                                                            <div className="vyayam-the-ayurvedic">
                                                                                                Vyayam, the Ayurvedic concept of physical exercise, emphasizes
                                                                                                the importance of regular physical activity such as yoga,
                                                                                                walking, or other forms of exercise. Incorporating regular
                                                                                                physical exercise into our daily routine can help improve
                                                                                                flexibility, strength, balance, coordination, cardiovascular
                                                                                                health, and boost the immune system.
                                                                                            </div>
                                                                                            <img className="frame-inner" alt="" src="template/public/group-91.png" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                                <div className="recommendation-parent" style={{ height: "800px" }}>
                                                                                <b className="recommendation">Recommendation</b>
                                                                                <div className="keep-a-food-container">
                                                                                     <div className="keep-a-food-and-bowel-movement" dangerouslySetInnerHTML={{ __html: documentlist.defaultValue }}></div>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment> : ""
                                        )
                                    }







                                </div>
                            </div>
                        </div>
                        
                    </React.Fragment>
                </div>
                :
                <React.Fragment >
                    <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} speed={50} viewBox="0 0 400 100">
                        <rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
                        <rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
                        <rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
                    </ContentLoader>
                </React.Fragment >
        );
    }
}


const mapDispatchToProps = {
    ...LoginStore.actionCreators,
    ...FlexiFormStore.actionCreators,
    ...Account.actionCreators,
    ...ContactStore.actionCreators,
    ...LanguageResourcesStore.actionCreators
};


const mapStateToProps = (state: ApplicationState) => {

    let values = {
        initialValues: jsonInitial,
        ...state.login, ...state.flexiform, ...state.account, ...state.contact, ...state.langResources
    };

    return values;
}


export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'FlexiForm',
    enableReinitialize: true,
    onSubmitFail: (errors) => {
        setTimeout(() => {
            if (document.getElementsByClassName("is-invalid")[0]) {
                document.getElementsByClassName("is-invalid")[0].scrollIntoView({ block: 'start' });
            }
            else {
                document.getElementsByClassName("flexiform")[0].scrollIntoView({ block: 'start' });
            }
        }, 1000)
    }
})(FlexiFormClass as any))

function groupBy(checkboxServerData: any, key: any) {
    return checkboxServerData.reduce(function (rv: any, x: any) {
        (rv[x[key]] = rv[x[key]] || []).push(x.Value);
        //console.log(rv);
        return rv;
    }, {});
};


//function handlechange1(e: any, id: any, checkboxvalue: any) {
//    if (e.target.checked) {

//        var data1: any = [];
//        var data2: any = [];
//        data1 = checkedValues.filter((value: any) => (value.Id == id));
//        data1.forEach((e2: any) => {
//            var checkvalue = e.target.value + "~" + e2.Value;
//            data2.push({ Id: id, Value: checkvalue.replace(/^~|~$/g, '') });
//        })
//        _checkedValues = checkedValues.filter((value: any) => (value.Id != id));
//        _checkedValues.push(data2[0]);
//        checkedValues = _checkedValues;

//    } else {
//        var data1: any = [];
//        var data2: any = [];
//        data1 = checkedValues.filter((value: any) => (value.Id == id));
//        data1.forEach((e2: any) => {
//            var checkvalue = e2.Value.replace(e.target.value, "")
//            data2.push({ Id: id, Value: checkvalue.replace(/^~|~$/g, '') });
//        })
//        _checkedValues = checkedValues.filter((value: any) => (value.Id != id));
//        _checkedValues.push(data2[0]);
//        checkedValues = _checkedValues;

//    }
//}