import { toastr } from "react-redux-toastr";

export const errorHandling = (statuscode: number) => {
    if (statuscode === 400) {
        return toastr.error('', "BadRequest");
    }
    else if (statuscode === 404)
        return window.location.href = "/Page404Error";
    else if (statuscode === 500)
        return toastr.error('', "Internal Server Error");
    else if (statuscode === 401) {
        localStorage.setItem("isAuth", "false");
        console.log("error Handling TS")
        localStorage.removeItem("Patient");
        localStorage.removeItem("pathName");
        localStorage.removeItem("redirectUrl");
        localStorage.removeItem("EmailId");
        localStorage.removeItem("redirectQequestQueueUrl");
        localStorage.removeItem("Appointment");
        localStorage.removeItem("ProfileData");
        localStorage.removeItem("contactUrl");
        window.location.href = "/login";
    }
    else if (statuscode === 403)
        return toastr.error('', "Forbidden");
    else if (statuscode === 405)
        return toastr.error('', "Method Not Allowed");
    else if (statuscode === 406)
        return  toastr.error('', "Not Acceptable");
    else if (statuscode === 407)
        return toastr.error('', "Proxy Authentication Required");
    else if (statuscode === 408)
        return toastr.error('', "Request Timeout");
    else if (statuscode === 501)
        return toastr.error('', "Not Implemented");
    else if (statuscode === 502)
        return toastr.error('', "Bad Gateway");
    else if (statuscode === 503)
        return toastr.error('', "Service Unavailable");
    else if (statuscode === 511)
        return toastr.error('', "Network Authentication Required");
    else return ("Invalid Request")
}
export const errorMessage = (data: any) => {
    var result: any = JSON.parse(data.errorMessage);
    return toastr.error('', result.Message);
}

