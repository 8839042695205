import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store/index';
import * as ContactStore from '../../store/patient/Contact';
import DashboardNavMenu from '../../components/layout/menu/DashboardNav'
import { seo } from '../../helper/Script';
import ContentLoader from 'react-content-loader';
import Moment from 'moment';
import * as AccountStore from '../../store/siteaccount/Account';
import * as TransactionStore from '../../store/patient/Transaction';
import * as LanguageResourcesStore from '../../store/LanguageResoures';
import { scrollTop, setTittle } from '../../helper/formvalidation';
import Pagination from "react-js-pagination";
import { dateFormat, dateUTCFormat, dateFormatConvert, timeFormatConvert } from '../../helper/formvalidation';
import { getDiscountPrice } from './Booking';
// At runtime, Redux will merge together...
type DocumentListProps =
	ContactStore.ContactState & TransactionStore.TransactionDetailState &  AccountStore.AccountState & LanguageResourcesStore.LanguageResouresState// ... state we've requested from the Redux store
	& typeof ContactStore.actionCreators & typeof TransactionStore.actionCreators & typeof AccountStore.actionCreators & typeof LanguageResourcesStore.actionCreators// ... plus action creators we've requested
	& RouteComponentProps<{ startDateIndex: string }>; // ... plus incoming routing parameters
var patientLocalStroage: any = localStorage.getItem('Patient');
var pat = JSON.parse(patientLocalStroage);
var languagecontent: any = "";
var langdata: any = "";
var __currency: any = [];
class DynamicForms extends React.PureComponent<DocumentListProps> {
	constructor(props: any) {
		super(props);
		
	}
	state = {

	activePage: 1,
	activePagecount: 10,
	currentPagecount: 1,
	lists: []

}

	// This method is called when the component is first added to the document
	public componentDidMount() {
		this.ensureDataFetched();
		scrollTop();

	}
	componentDidUpdate() {
		seo(setTittle(languagecontent == "" ? "" : languagecontent['breadcrumb']));
		
	}
	public render() {
		return (
			<React.Fragment>
				{this.renderTransactionList()}
			</React.Fragment>
		);
	}

	private ensureDataFetched() {
		this.props.getLangResources();
		this.props.requestTransactionDetail(pat['id'], null);

		if (localStorage.getItem("LangResourcesValidations") != null)
			langdata = JSON.parse(localStorage.getItem("LangResourcesValidations") || "");

		

	}


	convertDate(time: any) {

		return Moment.utc(time).utcOffset(+parseInt(localStorage.getItem('timeOffset') || "")).format('D MMM YYYY')
	}
	convertTime(time: any) {

		return Moment.utc(time).utcOffset(+parseInt(localStorage.getItem('timeOffset') || "")).format('LT')
	}
	handlePageChange(pageNumber: any) {
		var page = (pageNumber * 10) - 10;
		var pagenumber = page + 1;
		//console.log(page, pageNumber * 10)
		this.setState({ activePage: pageNumber, activePagecount: pageNumber * 10, currentPagecount: pagenumber });
		this.setState({ lists: this.props.paymentDetail.slice(page, pageNumber * 10) })
		//console.log(this.props.paymentDetail.slice(5, 10), "this.props.paymentDetail.slice(page, pageNumber * 5)")
	}
	
	/*componentWillMount() {
		this.setState({ lists: this.props.paymentDetail.slice(0, 5) })
    }*/

	

	private renderTransactionList() {
		

		languagecontent = this.props.LanguageResoures;
		var _currency: any = "";
		__currency = this.props.Settings.filter((s: any) => s.name == "Currency");
		//var value: any;
		if (__currency.length == 0) {
			_currency = "";
		}
		else {
			if (__currency[0].value == "") {
				_currency = __currency[0].displayCode;
				localStorage.setItem("currency", _currency);
			}
			else {
				_currency = __currency[0].customizeValue;
				localStorage.setItem("currency", _currency);
			}


		}
		
		var currency: any = _currency;		
		return (			
			<React.Fragment>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<DashboardNavMenu />
						<div className="col-md-7 col-lg-8 col-xl-9">
							<div id="pat_appointments" className="tab-pane fade show active">
								<div className="card card-table mb-0">
									<div className="card-body" style={{ padding: "10px" }}>
										{
											this.props.paymentDetailLoading == true ? <ContentLoader title={langdata['loading'] == undefined ? "" : langdata['loading']} viewBox="0 0 400 100">
												<rect x="0" y="20" rx="4" ry="4" width="400" height="15" />
												<rect x="0" y="50" rx="4" ry="4" width="400" height="15" />
												<rect x="0" y="80" rx="4" ry="4" width="400" height="15" />
											</ContentLoader> :
												<React.Fragment>
												<div className="table-responsive doc-module">
												{
													this.props.paymentDetail.length == 0 ?
																<div className="p-4 center">{languagecontent['norecordsfound']}</div> :
																<div className="">
														<table className="table table-hover table-center mb-0 transactiontable">
															<thead>
																			<tr>
																				<th>{languagecontent['paymenthistory']}</th>
																				<th>{languagecontent['type']}</th>
																				<th>{languagecontent['transactionfor']}</th>
																				<th>{languagecontent['paymentmethod']}</th>
																				<th>{languagecontent['reference']}</th>
																</tr>
															</thead>
																			<tbody>
																			{(this.state.lists.length > 0 ? this.state.lists : this.props.paymentDetail.slice(0, 10)).map((transaction: TransactionStore.PaymentDetail) => 
																				 <tr>
																					
																					<td>{languagecontent['transactionon']} <br /> {transaction.PaidDate ? dateFormat(transaction.PaidDate) : <span className="center">-</span>}<span className="d-block text-info">{transaction.PaidDate ? Moment(transaction.PaidDate).format('LT') : ""}</span> {languagecontent['doctorfee']} <br /> {currency + getDiscountPrice(transaction.PaidAmount, 0)}</td>
																					<td><span className={transaction.PaymentStatus == "Applied" || transaction.PaymentStatus == "Aplicada" ? "text-success" : "text-danger"}>{transaction.PaymentStatus}</span></td>
																					<td>{languagecontent['appointmentreason']} <br /> {transaction.Reason}<br />{languagecontent['appointmentno']} <br /> {transaction.AppointmentNo}</td>
																					<td>{transaction.PaymentMethod}</td>
																					<td>{transaction.KarepayReference? transaction.KarepayReference : "-"}</td>
																				</tr>
																			
																)
																				}</tbody>

															
																	</table>
																	</div>
																
												}
													</div>
													
														{
														this.props.paymentDetail.length >= 15 ?
															<React.Fragment>
																	<div className="float-right">{this.state.currentPagecount} - {this.state.activePagecount} of {this.props.paymentDetail.length}</div>
																	<Pagination
																		activePage={this.state.activePage}
																		itemsCountPerPage={10}
																		totalItemsCount={this.props.paymentDetail.length} 	
																	pageRangeDisplayed={5}
																	onChange={this.handlePageChange.bind(this)} />
															</React.Fragment> : ""
                                                        }
													
												</React.Fragment>
												
										}
									</div>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</React.Fragment>
		);
	}
}

export default connect(
	(state: ApplicationState) => ({ ...state.flexiform, ...state.contact, ...state.transactionDetail, ...state.account, ...state.langResources }), // Selects which state properties are merged into the component's props
	({ ...ContactStore.actionCreators, ...TransactionStore.actionCreators, ...AccountStore.actionCreators, ...LanguageResourcesStore.actionCreators })// Selects which action creators are merged into the component's props
)(DynamicForms as any);
