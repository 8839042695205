import * as React from 'react';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';


interface ExternalLinkProps {
    to: any;
    children: ReactNode;
}

class ExternalLink extends React.Component<ExternalLinkProps> {
    render() {
        const { to, children } = this.props;
        const isExternal = to.startsWith('http://') || to.startsWith('https://');
        if (isExternal) {
            return (
                <a style={{ color: "white" }} href={to} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }
        return <NavLink style={{ color: "white" }} to={to}><span>{children}</span></NavLink>;
    }
}


export default ExternalLink;
